import Request from './Api';
//import {stringToUUID} from './Common';
// get group(s)

const publicGet = async (props) => {
    return(
        Request({
            url:"/public/group",
            data: props,
            method: "POST",
        })
    );
}


const get = async (props) => {

    // // use test data on localhost only

    // let group={};
    // //if (["localhost", "127.0.0.1", ""].includes(window.location.hostname)){

    // // gets mock data for testing - will be removed when api is ready
    // let mockdata = await test();
    // if (!props) group=mockdata;
    // else {
    //     mockdata.forEach((item,i,test) => {
    //         if (props.id) {
    //             if (item.id===parseInt(props.id)){
    //                 group=test[i];
    //                 return false;
    //             }
    //         }
    //     });
    // }

    return (
        Request({
            url: "/group/" + (!props ? "" : props.id),
            method: "GET",
            // test: {data:group} // send mock data to simulate an api call
        })
    );
}

// create
const create = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Group Name is required", "Email is required."]};
    //let mockdata = {data:1,errors:null};
    return (
        Request({
            url: "/group/create",
            data: props,
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// update
const edit = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Group Name is required", "Email is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/group/edit",
            method: "POST",
            data: props
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// invite users
// takes a group id and the array of users to be invited
const invite = async (props) => {

    return (
        Request({
            url: "/group/invite",
            data: props,
            method: "POST"
        })
    );
}

const edit_member = async (props) => {

    return (
        Request({
            url: "/group/edit_member",
            data: props,
            method: "POST"
        })
    );
}

const accept_invitation = async (props) => {

    return(
        Request({
            url: "/group/accept_invitation",
            data: props,
            method: "POST"
        })
    );
}

const delete_member = async (props) => {

    return(
        Request({
            url: "/group/delete_member",
            data: props,
            method: "DELETE"
        })
    );
}

const groupFilter=async(props)=>{
    
    return(
        Request({
            url:"/group/list",
            data: props,
            method: "POST",
        })
    );
}

const getGroupTypes=async(props)=>{

    return(
        Request({
            url: "/group_type",
            method: "GET"
        })
    )
}

// sets up mock data
const test = async() => {
    return new Promise((resolve, reject) => {
        let dummymembers=[
            {
                id: 1,
                first_name: "Tom",
                last_name: "Huerter",
                role_id: 1,
                role: "Chimney Sweep",
                status_id: 1,
                status: "Invited"
            },
            {
                id: 2,
                first_name: "Joe",
                last_name: "Gero",
                role_id: 1,
                role: "Chimney Sweep",
                status_id: 2,
                status: "Confirmed"
            },
            {
                id: 3,
                first_name: "Will",
                last_name: "Pedicone",
                role_id: 1,
                role: "Chimney Sweep",
                status_id: 3,
                status: "Suspended"
            },
            {
                id: 4,
                first_name: "David",
                last_name: "Oppenheim",
                role_id: 1,
                role: "Chimney Sweep",
                status_id: 4,
                status: "Banned"
            },
            {
                id: 5,
                first_name: "Leo",
                last_name: "Castro",
                role_id: 1,
                role: "Chimney Sweep",
                status_id: 5,
                status: "Pending"
            },
        ];
        let dummyroles=[
            {
                id: 1,
                name: "Chimney Sweep",
                description: "Performs rooftop dance routines"
            },
            {
                id: 2,
                name: "Wicked Witch",
                description: "Gobbles small children"
            },
            {
                id: 3,
                name: "Starship Janitor",
                description: "Mops hyperdrive heat sink condensation"
            },
            {
                id: 4,
                name: "Chosen One",
                description: "Lacks the high ground"
            }
        ];
        let dummydata=[
            {
                id: 123,
                parent_id: 0,
                group_status_id: 1,
                group_status: 'Active',
                group_type_id: 4,
                group_type: 'Family',
                company_id: 0,
                name: "Family",
                description: "Making a mess",
                members: dummymembers,
                roles: dummyroles
            },
            {
                id: 33,
                parent_id: 0,
                group_status_id: 1,
                group_status: 'Active',
                group_type_id: 2,
                group_type: 'Team',
                company_id: 0,
                name: "The Hornets",
                description: "Over 50 basketball league",
                members: dummymembers,
                roles: dummyroles
            },
            {
                id: 633,
                parent_id: 0,
                group_status_id: 1,
                group_status: 'Active',
                group_type_id: 2,
                group_type: 'Team',
                company_id: 0,
                name: "The Cool",
                description: "Under 80 Shuffleboard",
                members: dummymembers,
                roles: dummyroles
            },
        ];

        resolve(dummydata);
    });
}

const Groups = {
    publicGet, get, create, edit, invite, edit_member, accept_invitation, delete_member, groupFilter, getGroupTypes //, delete, etc. ...
}
  
export default Groups;
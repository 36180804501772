import Request from './Api';

const get = async (props) => {
    return(
        Request({
            url:"/giftcard",
            data: props,
            method: "POST",
        })
    );
}


// create
const create = async (props) => {
    return (
        Request({
            url: "/giftcard/create",
            data: props,
            method: "POST"
        })
    );
}

// update
const edit = async (props) => {
    return (
        Request({
            url: "/giftcard/edit",
            method: "PUT",
            data: props
        })
    );
}

const remove = async (props) => {
    return(
        Request({
            url: "/giftcard/delete",
            data: props,
            method: "DELETE"
        })
    );
}

const transactions = async (props) => {
    return(
        Request({
            url: "/giftcard/transactions",
            data: props,
            method: "POST"
        })
    );
}


const GiftCards = {
    get, create, edit, remove, transactions
}
  
export default GiftCards;
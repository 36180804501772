import * as actionTypes from './actionTypes';

export const setAllFamilyNoDupes =(allFamilyNoDupes)=>{
    return{
        type: actionTypes.SET_ALL_FAMILY_NO_DUPES,
        all_family_no_dupes: allFamilyNoDupes
    };
};

export const setAllFamilyMembers=(allFamilyMembers)=>{
    return{
        type: actionTypes.SET_ALL_FAMILY_MEMBERS,
        all_family_members: allFamilyMembers
    };
};

export const setAllFamilyGroups=(allFamilyGroups)=>{
    return{
        type: actionTypes.SET_ALL_FAMILY_GROUPS,
        all_family_groups: allFamilyGroups
    };
};

export const resetFamily=()=>{
    return{
        type: actionTypes.RESET_FAMILY
    }
}
import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux';
import * as actions from '../../../../../store/actions'

import MultiStep from '../../../../../components/common/MultiStep';
//steps
import SelectUsers from './SelectUsers';
import AssignPaymentAmounts from './AssignPaymentAmounts';
import ProcessEachPayment from './ProcessEachPayment';
import Payments from '../MultiUserMultiItems/Payments';
import MultiUserConfirmation from '../MultiUserConfirmation';

export const MultiUserSingleItem = (props) => {

    const dispatch = useDispatch();
    const currentOrder=useSelector(state=>state.pos[props.register].orderAll);
    const splitPaymentStore=useSelector(state=>state.splitPayments)

    const [alreadySplit, setAlreadySplit]=useState(false);
    const [error, setError]=useState();
    const [validated, setValidated]=useState(true);
    const [goToName, setGoToName]=useState(null);
    const [stepName, setStepName]=useState("");
    const [hideBack, setHideBack]=useState(false); //hide the back button on the last step

    useEffect(()=>{
        if(stepName==="Confirmation") setHideBack(true);
    },[stepName])

    const multiUserStepOrder=[
        'SelectUsers',
        'AssignPaymentAmounts',
        'ProcessEachPayment',
        'Confirmation'
    ];

    const goTo=(name)=>{
        setGoToName(name)
    }

    const steps=[
        {
        name: 'SelectUsers',
        component: 
            <SelectUsers 
                register={props.register}
                type="multiSingle"
                cancelSplit={props.cancelSplit}
            />
        },
        {
        name: 'AssignPaymentAmounts',
        component: 
            <AssignPaymentAmounts 
                setAlreadySplit={setAlreadySplit}
                register={props.register}
                type="multiSingle"
            />
        },
        {
        name: 'ProcessEachPayment',
        component: 
            <Payments
                alreadySplit={alreadySplit}
                onClose={props.onClose}
                register={props.register}
                terminalDeviceId={props.terminalDeviceId}
                type="multiSingle"
                splitType="byAmount"
                goTo={goTo}
            />
        },
        {
        name: 'Confirmation',
        component: 
            <MultiUserConfirmation 
                register={props.register}
                type={"spMultiSingle"}
            />
        }
    ]

    useEffect(()=>{
        if(currentOrder) dispatch(actions.setSplitPaymentRemaining(currentOrder.total_price))
    },[currentOrder, dispatch]);

    const validate=(pageName)=>{
        let error ={};
        let validated = true;
        switch(pageName){
        case "SelectUsers":
            if(splitPaymentStore.payees.length < 2 || !splitPaymentStore.payees){
                error= {...error, numberOfPayees: "You must select at least 2 payees."}
            }
            if(splitPaymentStore.payees.some(payee => {return (!payee.identifier)})){
                error = {...error, namePayees: "Each payee must have some sort of identifier, even if you just use numbers."}
            }
            break;
        case "AssignPaymentAmounts":
            if(splitPaymentStore.remainder > .01 ){
                error = {...error, leftoverRemainder: "You must assign the whole amount to the chosen payees."}
            }
            if(splitPaymentStore.remainder < -.01){
                error = {...error, negativeRemainder: "You have exceeded the total price of the order.  Please adjust accordingly."}
            }
            if(splitPaymentStore.payees.some(payee => {return (payee.amount<=0)})){
                error={...error, noAmountOnPayee: "Each payee must have an amount allotted to them.  It cannot be zero."}
            }
            break;
        case "ProcessEachPayment":
            if(splitPaymentStore.allPaid === "No" || splitPaymentStore.allPaid === "Partial"){
                error = {...error, notAllPaid: "Please complete a transaction for each person.  "}
            }
            break;
        case "Confirmation":
        default:
            //stuff!?
            break;
        }
        if(Object.keys(error).length>0) validated=false;
        dispatch(actions.setSplitPaymentErrors(error));
        setValidated(validated);
        return validated;
    }

    const multiStepSubmit=()=>{
        if(validated && splitPaymentStore.allPaid==="Yes"){
            props.onClose();
        }
    }

    return (
        <div className="multi-user-single-sp-wrapper text-center">
            <MultiStep
                showNavigation={false}
                steps={steps}
                stepOrder={multiUserStepOrder}
                onChangeStep={validate}
                onSubmit={multiStepSubmit}
                lastStepName="Close" //for the button text
                goTo={goToName}
                saveStep={(name)=>setStepName(name)}
                hideBack={hideBack}
            />
        </div>
    )
}

import React,{useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {Button as BsButton, Modal, Container} from 'react-bootstrap';

import { Frame } from '../../../Builder/Layers';
import EventDetail from '../Events/EventDetail';

import styles from './Button.module.scss';
import { randomUUID, toSlug } from '../../../../../utils/cms';

const Button = React.forwardRef((props, ref) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);

    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    let target="_self";
    let href=props?.link || "#!";
    let modalContent = null;
    if (props?.target==="New Tab") target="_blank";
    else if (props?.target==="Same Tab") target="_self";
    else if (props?.target==="Parent Frame") target="_parent";
    else if (props?.target==="Top Frame") target="_top";
    else if (props?.target==="_popup" || props?.target==="Popup"){
        const onClick = preview_click;
        target="_self";
        href ="#!";
        //if (props.is_preview)
        preview_click = (e) => {
            e.preventDefault();
            setShowModal(true);
            if (onClick) onClick(e);
        }
        
        if (props?.link){
            let src=props.link.trim();
            if (!src.startsWith("http") || !src.startsWith("/")) src = `/${src}`;
            modalContent = <Frame width="100%" height="600px" src={`/${props.link.trim()}`} />;
        } else modalContent = <Container>Nothing to show...</Container>;
    } else href=props?.link || "#!";

    if (props?.type === "Event Button" && props?.event_id){
        const onClick = preview_click;

        const handleClose = () => {
            setShowModal(false);
            history.push(`${window.location.pathname}`);
        }

        preview_click = (e) => {
            e.preventDefault();
            setShowModal(true);
            history.push(`${window.location.pathname}#event${props?.event_id}`);
            if (onClick) onClick(e);
        }
        modalContent = <EventDetail {...props} id={props?.event_id} close={handleClose} />;
    }

    const buttonProps = {
        ref: ref, 
        id: props.is_preview ? `preview-${randomUUID()}` : props.id,
        className: `button_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' '),
        style: props?.style || null,
        onClick: preview_click,
        variant: props?.type === "Link" ? "link" : (props?.variant.toLowerCase() || "primary"),
        as: "a", //props?.type === "Link" ? "a" : "button",
        target: target || "_self",
        href: href
    }        

    useEffect(() => {
        return () => {
            setShowModal(false);
        }
    }, []);

    return (
        <>
            <BsButton {...buttonProps}>{props?.text || `Click Me!`}</BsButton>
            {(props?.target==="_popup" || props?.target==="Popup" || props?.type==="Event Button") &&
                <Modal show={showModal} onHide={()=>setShowModal(false)} contentClassName={styles.popup} centered={props?.popup_centered || false} size={props?.popup_size || "m"} dialogClassName={props?.popup_fullscreen === "true" ? styles.fullscreen : undefined} className={props?.popup_fullscreen === "true" ? styles.fullscreen : undefined}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        {modalContent}
                    </Modal.Body>
                </Modal>
            }
        </>
    );
});

export default Button;
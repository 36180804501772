import React,{useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import Pos from '../../../api/Pos';
import Users from '../../../api/Users';
import {setItems} from '../Items/Utils';
import * as actions from '../../../store/actions';

import './NumberTabs.css';

export const NumberTabs = (props) => {
    const dispatch = useDispatch();

    //const selected_tab=useSelector(state => state.pos.tab);
    const active_register=useSelector(state => state.pos.register);
    const company=useSelector(state=>state.company);    
    
    const [tabs,setTabs]=useState([]);
    const [show,setShow]=useState(true);

    //const GUEST_USER_ID = 7;
    const GUEST_USER_ID = company?.config?.guest_user_id || null;


    const newClickHandler = async () => {
        let tab_number="";

        let order=await Pos.order.save({register_id: active_register, user_id: GUEST_USER_ID});
        if (order.data?.[0]){
            dispatch(actions.order(order.data[0].id));
            dispatch(actions.orderAll(order.data[0]));
            tab_number=order.data[0].id;
            //tab_number=('000'+order.data[0].id).slice(-3);
        } else {
            console.log(order.errors);
        }

        if (tab_number){       
            /*let res = await Pos.local.get(`POS-NUMTABS-${active_register}`);
            if (res){
                if (!Array.isArray(res)) res=[res];
                tab_number=res.length+1;
            }*/

            const tab={
                id:tab_number,
                name:"",
            }

            Pos.local.append(`POS-NUMTABS-${active_register}`,tab).then(a=>{
                setTabs(prev=>[...prev,tab]);
            });
        }
    }

    const clickHandler=async(e,tab)=>{
        e.preventDefault();

        dispatch(actions.activeTab({...tab}));
        dispatch(actions.selectedItems({...tab})); // backward compatibility with the location selector
        dispatch(actions.reset());

        //await Pos.local.remove(`POS-TAB-${active_register}`);

        Pos.order.get({id:tab.id}).then(async res2=>{
            dispatch(actions.order(tab.id));
            dispatch(actions.orderAll(res2.data?.[0]));

            // load the customer
            if (res2.data?.[0]?.user_id && +res2.data[0].user_id !== GUEST_USER_ID){
                await Users.get({id:res2.data[0].user_id}).then(response=>{
                    if (response.data){
                        dispatch(actions.selectCustomer(response.data[0]));
                    } 
                });
            }


            // resets redux and our local cart so we can populate them again with server data (I know there's a better way, I'm tired gimme a break)
            await Pos.local.remove(`POS-${active_register}`).then(a=>{
                dispatch(actions.resetItems());
            });

            if (res2.data?.[0]?.items){
                const items=await setItems(res2.data[0].items,active_register,res2.data[0].id,res2.data[0].user_id);
                // updates redux
                if (items){
                    items.forEach(item=>{
                        dispatch(actions.addPOSItem(item));
                        if (item.addons){
                            item.addons.forEach(addon=>{
                                dispatch(actions.addPOSItem(addon));
                            });
                        }
                    });
                }
            }
        });        
        setShow(false);
    }

	useEffect(() => {
        let mounted = true;

        if (mounted){
            Pos.local.get(`POS-NUMTABS-${active_register}`).then(res=>{
                if (res){
                    if (!Array.isArray(res)) res=[res];
                    setTabs(res);
                }
            });
        }

        return () => {
            mounted = false;
        }
	}, [active_register]);
    
    return (
        <Col className="tabselector-container" sm="12" style={{display:show?"block":"none"}}>
            <h5 className="mt-5">Tab Selector</h5>
            <Button as="a" variant="primary" onClick={newClickHandler}>New Tab</Button>
            {tabs && tabs.map((tab,i)=>(
                <Button as="a" variant="secondary" key={`tab-pos-n-${i}`} onClick={(e)=>clickHandler(e,tab)}>Tab #{('000'+tab.id).slice(-3)}<br/>{tab.user_name}</Button>
            ))}
        </Col>
    );

}
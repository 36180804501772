import React, { useState, useCallback } from 'react';
import { Typeahead } from './Typeahead';

const hardCodedOrderStatuses=[
    {id: 1, name: "Pending"},
    {id: 2, name: "Completed"},
    {id: 3, name: "Cancelled"},
    {id: 4, name: "Refunded"},
    {id: 5, name: "Partially Refunded"},
    {id: 8, name: "Ordered"},
    {id: 9, name: "In Process"},
    {id: 10, name: "Ready for Pickup"},
    {id: 11, name: "Complete"}
]

const OrderStatusesTypeahead = ({
    ids=[2,1,3,4,5],
    async = false,
    paginated = false,
    multiple = true, 
    ...props
}) =>{
    const [ loading, setLoading ] =useState(true);

    const filterItems=useCallback(()=>{
        let filtered = [];
        for(let i = 0; i < ids.length; i++){
            let match = hardCodedOrderStatuses.filter((item)=>item.id === ids[i])
            filtered.push(match[0])
        }
        return filtered;
    },[ids])

    const getOrderStatuses=useCallback(async()=>{
        let responseObject = {
            data: null,
            errors: null
        }
        try{
            //one day there will be an endpoint here.  The only endpoint right now is the custom status one and it requires a register id.  Sometimes we just want the statuses irregardless.  So manual filter for now.  
            responseObject.data = filterItems()

        }catch(ex){
            console.error(ex);
            responseObject.errors = ex;
        }
        setLoading(false);
        return responseObject;
    },[filterItems]);

    return(
        <Typeahead 
            {...props}
            id="order-statuses"
            makeRequest={getOrderStatuses}
            async={async}
            multiple={multiple}
            paginated={paginated}
            placeholder = {loading ? "Loading Statuses..." : props.placeholder || "Search Order Statuses"}
        />
    )
}

export default OrderStatusesTypeahead;
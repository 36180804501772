import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { CookiesProvider } from 'react-cookie';
import { ProSidebarProvider } from 'react-pro-sidebar';
import store from "./redux-store";
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
    <React.StrictMode>
        <ProSidebarProvider>
            <CookiesProvider>
                <Provider store={store}>
                    <Router>
                        <App />
                    </Router>
                </Provider>
            </CookiesProvider>
        </ProSidebarProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// expose store when run in Cypress
if (window.Cypress) {
    window.store = store
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { updateCart } from '../../../../../utils/thunks';

export const CancelNotification = (props) => {

    const mountedRef = useRef(false);
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(true);
    const splitPaymentStore=useSelector(state=>state.splitPayments);
    const [paidPayees, setPaidPayees]=useState([]);
    const [importedError, setImportedError]=useState(false);
    const [cashCardRefund, setCashCardRefund]=useState([]);

    useEffect(()=>{
        mountedRef.current = true

        return ()=> mountedRef.current = false
    },[])


    useEffect(()=>{
        //if cash value === 0, it would be a multi split, not cash/card
        if(splitPaymentStore.allPaid !== "No" && splitPaymentStore.cashValue === 0){
            let alreadyPaid = []
            splitPaymentStore.payees.forEach((payee)=>{
                if(payee.paid) alreadyPaid.push(payee)
            })
            if(mountedRef.current) setPaidPayees(alreadyPaid)
        }
        //for a cash card split, it will always be cash complete first.  Cannot be cancelled after they're both paid.
        if(splitPaymentStore.cashValue.hasOwnProperty("amount")){
            let temp = [];
            temp.push(splitPaymentStore.cashValue);
            setCashCardRefund(temp);
        }
    },[splitPaymentStore])

    useEffect(()=>{
        if(props.error) setImportedError(props.error)
    },[props.error]);

    const hideModal=()=>{
        setModalShow(false);
        if(props.reset) dispatch(updateCart(props.registerId))
    }

  return (
    <>
    <Modal show={modalShow} onHide={hideModal}>
        <Modal.Header closeButton>
            Please Note
        </Modal.Header>
        <Modal.Body>
            {paidPayees.length === 0 ?
                <>
                    {importedError ?            
                        <p>
                            An error has occurred with this order.  It has been cancelled.
                        </p> 
                    :
                        ""
                    }
                    {props.reset ?
                        <p>
                            The splitting of this order has been cancelled and it has been reverted to its original state.  
                        </p> 
                    :
                        ""
                    }
                </>
            :
                <div>
                    <p>This order was cancelled.</p>
                    {paidPayees.map((paidPatron, i)=>(
                        <React.Fragment key={`paidPatrons-${paidPatron.identifier}-${i}`}>
                            {paidPatron.paymentType === "cash" &&
                                <p> <i className="far fa-money-bill-alt"/> Please refund {paidPatron.order.total_price} to {paidPatron.identifier}.</p>
                            }
                            {paidPatron.paymentType === "credit card" &&
                                <p><i className="far fa-credit-card" /> {paidPatron.identifier} paid with a card.  Their bank will process their refund on the bank's end within 7 days.</p>
                            }
                        </React.Fragment>
                    ))}
                </div>
            }
            {cashCardRefund.length > 0 ?
                <p>This order has been cancelled.  Be sure to refund ${cashCardRefund[0]?.amount} to the patron.</p>
            :
                null
            }
        </Modal.Body>
    </Modal>
    </>
  )
}

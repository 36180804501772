import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Locations from '../Locations';

import Pos from '../../../api/Pos';
import * as actions from '../../../store/actions';

import './Tabs.css';

export const Tabs = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const active_register=useSelector(state => state.pos.register);
    //const selected_location=useSelector(state => state.map.items);

    const [locations,setLocations]=useState([]);
    const [modalShow, setModalShow] = useState(false);
    const modalCloseHandler = (e) => {
        setModalShow(false);
    }

    const clickHandler=(e,item)=>{
        e.preventDefault();

        // load the current table in redux
        if (item){
            dispatch(actions.selectedItems(null));
            dispatch(actions.selectedItems(
                {...item,
                    ...{booking: 
                        {
                            event_name: "",
                            event_description: "",
                            start_datetime: null,
                            end_datetime: null,
                        }
                    }
                }
            ));
            if (item?.items){
                dispatch(actions.reset());
                item.items.forEach(i=>{
                    if (i) dispatch(actions.addPOSItem(i));
                });
            }
        }
        dispatch(actions.activeOption("#preview")); // change to preview
    }

    const newClickHandler=(e)=>{        
        e.preventDefault();
        history.go(0);
        //setModalShow(true);
    }    

    useEffect(()=>{
        let mounted = true;

        if (mounted){
            Pos.local.get(`POS-TAB-${active_register}`).then(item=>{
                if (Array.isArray(item)) setLocations(item);
                else setLocations([item]);
            });
        }

        return () => {
            mounted = false;
            setModalShow(false);
        }    
    },[active_register]);

    return (
        <React.Fragment>
            <Modal show={modalShow} onHide={modalCloseHandler} className="tab-modal">
                <Modal.Body>
                    <Locations onClick={modalCloseHandler} show="1" />
                </Modal.Body>
            </Modal>
            <Button variant="light" onClick={newClickHandler} type="button">New Tab</Button>
            <Table responsive striped hover>
                <thead>
                    <tr>
                        <th className="w-100">Table #</th>
                        <th className="text-right">Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    {locations && locations.map((i,j)=>(i &&
                        <tr key={`loc-tb-${i.id}-${j}`} onClick={e=>clickHandler(e,i)}>
                            <td>{i.name}</td>
                            <td className="text-right">${i.total.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>    
        </React.Fragment>
    );
}
import * as actionTypes from './actionTypes';

export const setNewEventUserRole = (id, role_id) => {
    return {
        type: actionTypes.SET_NEW_EVENT_USER_ROLE,
        user_event_role: {id: id, role_id: role_id}
    };
};

export const setNewEventUserStatus = (id, status_id) => {
    return {
        type: actionTypes.SET_NEW_EVENT_USER_STATUS,
        user_event_status: {id: id, status_id: status_id}
    };
};
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Nav, Modal } from 'react-bootstrap';

import CancelNotification from '../Payment/SplitPayment/CancelNotification';
import Tabs from '../Tabs';
import NumberTabs from '../NumberTabs/List';
import Registers from '../Registers';
import Print from '../Print';
import POSOrders from '../../Orders/POSOrders';
import OpenOrders from '../OpenOrders';
import PosReports from '../Reports';
import CheckUser from '../CheckUser/CheckUser';
import SplitPayment from '../Payment/SplitPayment';
import * as actions from '../../../store/actions';
import Pos from '../../../api/Pos';
import { updateCart, addToCart, resetCart, saveLogging } from '../../../utils/thunks';
import { refundTypes, cancelSplit } from '../Payment/SplitPayment/SplitPaymentUtils';
import { cartToItems } from '../../../containers/POS/Items/Utils'

import './Buttons.scss';

// only buttons added here will show up
const BUTTON_OPTIONS = [
    {
        slug: "preview",
        default_text: "Preview"
    },
    {
        slug: "numtabs",
        default_text: "Open Tabs"
    },
    {
        slug: "print",
        default_text: "Print Order"
    },
    {
        slug: "orders",
        default_text: "Search Orders"
    },
    {
        slug: "reports",
        default_text: "Register Reports"
    },
    {
        slug: "split",
        default_text: "Split Payments"
    },
    {
        slug: "openOrders",
        default_text: "Open Orders"
    }
]

export const Buttons = (props) => {
    const dispatch = useDispatch();

    const register_id = props.register_id;

    const defaultPatronRegisterId =useSelector(state => state.pos.register);
    const selected_option=useSelector(state => state.pos[register_id].option);
    const order_imported=useSelector(state => state.pos[register_id].order_imported);
    const currentItems=useSelector(state=>state.pos[register_id].items);
    const customer=useSelector(state=>state.pos[register_id].user)
    const orderId=useSelector(state=>state.pos[register_id].order);
    const currentOrder=useSelector(state=>state.pos[register_id].orderAll);
    const transaction=useSelector(state=>state.pos[register_id].transaction);
    const order_status=useSelector(state => state.pos[register_id].order_status);
    const splitPaymentAllPaid=useSelector(state=>state.splitPayments.allPaid)
    const splitPaymentStore=useSelector(state=>state.splitPayments);
    const authUser=useSelector(state=>state.auth.user);

    // selected tab
    // The below line is set to preview by default now because a number of components cannot load before the full initialization of POS
    const [selectedTab, setSelectedTab]=useState("#preview"); 
    const [component, setComponent] = useState();
    const [showLookup, setShowLookup] = useState(false);
    const [showPrint, setShowPrint] = useState(false);
    const [showOrderLookup, setShowOrderLookup] = useState(false);
    const [showReportModal, setShowReportModal]=useState(false);
    const [showSplitModal, setShowSplitModal]=useState(false);
    const [showOpenOrdersModal, setShowOpenOrdersModal]=useState(false);
    const [spType, setSpType]=useState();
    const [error, setError]=useState();
    const [success, setSuccess]=useState();
    const [cancelNotice,setCancelNotice]=useState({});
    const [includedButtons, setIncludedButtons] = useState([]);
    const [previousOrderId, setPreviousOrderId]=useState(); //to save an order in the preview to resume if an open order is not selected.


    useEffect(() => {
        let btnArray = [];
        props.includes.forEach(includedItem => {
            let defaultBtn = BUTTON_OPTIONS.find(defaultBtn => defaultBtn.slug===includedItem.hash);
            if (defaultBtn) {
                btnArray.push({
                    slug: includedItem.hash,
                    text: includedItem.text || defaultBtn.default_text
                });
            }
        });
        setIncludedButtons(btnArray);
    },[props.includes]);

    const lookupModalCloseHandler = (e) => {
        setShowLookup(false);
    }

    const printCloseHandler = (e) => {
        setShowPrint(false);
    }

    const orderLookupModalCloseHandler = (e) => {
        setShowOrderLookup(false);
    }

    const reportModalCloseHandler=()=>{
        setShowReportModal(false);
    }

    const openOrdersModalCloseHandler=()=>{
        setShowOpenOrdersModal(false);
    }

//Split Payment Region
//#region

    useEffect(()=>{
        if(cancelNotice.hasOwnProperty("cancelled")){
            Pos.local.remove(`POS-${register_id}`);
            Pos.local.remove(`POS-${register_id}-order-id`);
            // dispatch(actions.reset(register_id));
            dispatch(actions.resetSplitPayments());
            //console.log("dispatch updateCart Buttons 132");
            dispatch(saveLogging(`dispatch updateCart Buttons 132`));
            dispatch(updateCart(register_id))
            setCancelNotice({})
        }
    },[cancelNotice,dispatch,register_id]);

    //completely removes the order from local storage, resets the DB, and resets the split payment store.
    const splitCleanup=()=>{
        if(register_id !== defaultPatronRegisterId) Pos.local.remove(`POS-${register_id}`);
        if(register_id !== defaultPatronRegisterId) Pos.local.remove(`POS-${register_id}-order-id`);
        dispatch(actions.reset(register_id));
        dispatch(actions.resetSplitPayments());
    };

    //initiates the cancellation of a single user split by refunding their cash portion
    const splitSingleRefund=async()=>{
        let order = splitPaymentStore.allOrders.filter(order=>(order.memo === "Cash Portion"))
        let parameters = {
            order: order[0],
            transactionId: splitPaymentStore.cashValue.transaction_id,
            paymentType: "cash"
        }
        let error = await refundTypes('byAmount', parameters, register_id);
        if(error) return error;
    }

    const splitMultiSingleRefund=async()=>{
        let type;
        let parameters;
        let errors=[];
        let ready = false;
        try {
            if(splitPaymentStore.paymentType ==="") type = "byAmount";
            else type = splitPaymentStore.paymentType
            await splitPaymentStore.payees.forEach((payee)=>{
                if(payee.paid === true){
                    parameters = {
                        userId: payee.id,
                        order: payee.order,
                        order_id: payee.order.id,
                        adminAuthId: authUser.profile.id,
                        adminFee: payee.order.admin_fee_total,
                        items: payee.order.items,
                        transaction: payee.transactionId,
                        paymentType: payee.paymentType
                    }
                    let error = refundTypes(type, parameters, register_id)
                    if(error) errors.push(error);
                }
            })
            ready=true;
        }catch(ex){
            console.error(ex)
            return error;
        }
        if (ready) return errors;
    }

    /**
     * @param {boolean} complete
     * @param {string} error - to pass a custom error
     */
    const splitModalCloseHandler=async(complete)=>{
        setError();
        setSuccess();

        if(currentOrder){
            let response;
            //Successfull split payment
            if(complete || splitPaymentAllPaid==="Yes"){
                splitCleanup();
            }
    
            //cash/card split - linear progression, never more than two child orders, cash always first
            else if(spType === "SingleCashCC" && splitPaymentStore.cashSuccess && splitPaymentAllPaid==="No"){
                response = await splitSingleRefund();
    
                //success/error are being used the same way, just for our own clarification that one is different from other
                if(response.errors) setError(<CancelNotification error={response.errors} />);
                else setSuccess(<CancelNotification />); 
                splitCleanup(); //split cannot be resumed (or fully cancelled) after being partially complete
            }
    
            //2+ patrons splitting by items OR by amount && refunds required
            else if((spType === "MultipleMany" || spType === "MultipleSingle") && splitPaymentAllPaid !== "No"){

                response = await splitMultiSingleRefund();
    
                if(response.errors) setError(<CancelNotification error={response.errors} />);
                else setSuccess(<CancelNotification />); 
                splitCleanup(); //split cannot be resumed after being partially complete
            }
            //Not currently enabled
            //multimany and just needs to be cancelled to make alterations
            // else if((spType==="multiMany") && splitPaymentAllPaid ==="No"){
            //     // cancelSplit(error, true, notification)
            // }
            //Cancelled split payment BEFORE any payments are made
            else{
                response = cancelSplit(currentOrder.id)
                if(response.errors) setError(<CancelNotification error={response.errors} reset={true} registerId={register_id}/>);
                setSuccess(<CancelNotification reset={true} registerId={register_id}/>)
            }
        }
        setShowSplitModal(false);
    }
      
//#endregion Split Payments
    
    const showSelectedTab=useCallback(hash=>{
        setSelectedTab(hash);
        switch(hash){
            case "print":
                setShowPrint(true);
                setComponent(null);
                break;
            case "tabs":
                setComponent(<Tabs key="tab-tab" {...props } />);
                break;
            case "numtabs":
                setComponent(<NumberTabs key="tab-numtab" {...props } />);
                break;
            case "registers":
                setComponent(<Registers key="tab-register" {...props } />);
                break;
            case "lookup":
                setShowLookup(true);
                setComponent(null);
                break;
            case "orders":
                setShowOrderLookup(true);
                setComponent(null);
                break;
            case "reports":
                setShowReportModal(true);
                setComponent(null);
                break;
            case "split":
                setShowSplitModal(true);
                setComponent(null);
                break;
            case "openOrders":
                setShowOpenOrdersModal(true);
                setComponent(null);
                break;
            default:
                setComponent(null);
                break;
        }
    },[props]);

    // load content based on the tab selected
    const tabClickHandler = useCallback((hash) => {
        // dispatch(actions.activeOption(hash));
        showSelectedTab(hash);
    },[showSelectedTab]);

    useEffect(() => {
        if (!component){
            // gotta do this because we dont know the order in which we want to include the tabs
            //(1/2) Some components cannot be the first to load due to needing props/data after the register loads.  They are included in the if conditional below.  
            //(2/2) If something other than those listed below is at 0 for .includes, it will load - else default to preview as it has been doing - changed 04/03/2022
            if(props.includes?.[0].hash !== "checkout" && props.includes?.[0].hash !== "print" && props.includes?.[0].hash !=="orders" && props.includes?.[0].hash !=="reports" && props.includes?.[0].hash !=="split") tabClickHandler("#"+props.includes?.[0].hash);
            else tabClickHandler("preview")
        }
    },[component,props.includes,tabClickHandler]);

    // switches to another tab
    useEffect(()=>{
        if (selected_option) showSelectedTab(selected_option);
    },[selected_option,showSelectedTab]);


    if (!props.show) return null;
    return (
        !props.patron_layout || props.checkout ? <React.Fragment>
            {error}
            {success}
            {cancelNotice?.component}
            { showPrint && currentOrder && 
                <Print 
                    register_id={register_id}
                    onAfterPrint={printCloseHandler} 
                    status={order_status} 
                    print={showPrint}
                    order={currentOrder}
                    items={currentItems}
                    customer={customer}
                    order_id={orderId}
                    //transaction={transaction}
                    //payment_type="Cash"
                />
            }
            { showLookup && <CheckUser/>}

            <Modal show={showLookup} onHide={lookupModalCloseHandler}>
                <Modal.Body>
                    <CheckUser key="tab-lookup" onClose={lookupModalCloseHandler} terminalDeviceId={props.terminal_device_id} />
                </Modal.Body>
            </Modal>

            <Modal dialogClassName="orders-modal" size="xl" show={showOrderLookup} onHide={orderLookupModalCloseHandler}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <POSOrders key="tab-order-lookup" onClose={orderLookupModalCloseHandler} setSuccess={setSuccess} setError={setError} user={authUser} {...props} />
                </Modal.Body>
            </Modal>

            <Modal onHide={reportModalCloseHandler} show={showReportModal}>
                <Modal.Header closeButton>
                    <h5>Reports</h5>
                </Modal.Header>
                <Modal.Body>
                    <PosReports key="tab-reports" />
                </Modal.Body>
            </Modal>

            <Modal dialogClassName="split-payment-modal" size="xl" backdrop="static" show={showSplitModal} onHide={splitModalCloseHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Split Order
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {currentItems.length >0 ? */}
                    <SplitPayment 
                        key="tab-split-payment" 
                        register={register_id} 
                        onClose={splitModalCloseHandler} 
                        terminalDeviceId={props.terminal_device_id} 
                        setType={setSpType}
                        cancelSplit={cancelSplit}
                        {...props} 
                    />
                </Modal.Body>
            </Modal>

            <Modal dialogClassName="open-orders-modal" size="lg" backdrop="static" show={showOpenOrdersModal} onHide={openOrdersModalCloseHandler}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Open Orders
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OpenOrders key="tab-openOrders" register_id={register_id} setPreviousOrderId={setPreviousOrderId} closeHandler={openOrdersModalCloseHandler} />
                </Modal.Body>
            </Modal>

            {!props.patron_layout ? <Card.Body className="p-0">
                <Nav className="pos-nav-tabs" defaultActiveKey={`#${props.includes?.[0].slug}`} as="ul">
                    {includedButtons.map((item,i)=> (
                        <Nav.Item key={item.slug+"-"+i} as="li">
                            <Button id={`pos-button-${item.slug}`}
                                variant="light"
                                onClick={(e)=>tabClickHandler(item.slug)}
                                disabled={+order_status!==1}
                                dangerouslySetInnerHTML={{ __html: item.text }}
                                data-cy={`pos-button-${item.slug}`}
                            />
                        </Nav.Item>
                    ))}
                </Nav>
            </Card.Body> : null}

            <div className={`px-0 pb-0${selectedTab && selectedTab!=="#preview"?" white-tab":""}`}>
                {component}
            </div>
        </React.Fragment> : <></>
    );
}
import Permissions from '../../../api/Permissions'

export const getModules = async(parameters)=>{
    const callObject = parameters ? {} : null;
    if(parameters?.id) callObject.id = parameters.id;
    if(parameters?.include_permissions) callObject.include_permissions = true;
    if(parameters?.feature_ids) callObject.feature_ids = parameters.feature_ids;
    if(parameters?.endpoint_ids) callObject.endpoint_ids = parameters.endpoint_ids;
    if(parameters?.user_id) callObject.user_id = parameters.user_id;
    if(parameters?.group_id) callObject.group_id = parameters.group_id;
    if(parameters?.module_types) callObject.module_types = parameters.module_types;
    if(parameters?.search) callObject.search = parameters.search;
    if(parameters?.hasOwnProperty("has_endpoints")) callObject.has_endpoints = parameters.has_endpoints;
    if(parameters?.hasOwnProperty("is_paginated")) callObject.is_paginated = parameters.is_paginated;
    if(parameters?.max_records) callObject.max_records = parameters.max_records;
    if(parameters?.page_no) callObject.page_no = parameters.page_no;
    if(parameters?.sort_col) callObject.sort_col = parameters.sort_col;
    if(parameters?.sort_dir) callObject.sort_dir = parameters.sort_dir;
    let modules={
        data: null,
        errors: null
    }
    try{
        let response = await Permissions.Modules.get(callObject);
        if(response.status===200 && response.data){
            modules.data = response.data
            modules.errors = null
        }
        else {
            modules.data = null;
            modules.errors = response.errors ? response.errors : "There was an issue retrieving the module list"
        }
    }catch(ex){
        console.error(ex)
        modules.data = null;
        modules.errors = ex
    }
    return modules;
}

export const assignModules = async(props) => {
    let responseData = {
        data: null,
        errors: null
    }
    try{
        let response = await Permissions.Modules.assignMany(props);
        if(response.status===200 && response.data){
            responseData.data = response.data
            responseData.errors = null
        }
        else {
            responseData.data = null;
            responseData.errors = response.errors ? response.errors : "There was an issue assigning permissions."
        }
    }catch(ex){
        console.error(ex)
        responseData.data = null;
        responseData.errors = ex
    }
    return responseData;
}

/** @param {{}} { id, moduleId, includeModules, includeVariants, searchText} */
export const getFeatures = async (parameters)=>{
    const callObject = parameters ? {} : null;
    if(parameters?.id) callObject.id = parameters?.id;
    if(parameters?.moduleId) callObject.module_id = parameters?.moduleId;
    if(parameters?.includeModules) callObject.include_modules = true;
    if(parameters?.includeVariants) callObject.include_product_variants = true;
    if(parameters?.searchText) callObject.search_text = parameters?.searchText;
    let features={
        data: null,
        errors: null
    };
    try{
        let response = await Permissions.Features.getAll(callObject);
        if(response.status === 200){
            features.data = response.data
            features.errors=null
        }else{
            features.data= null
            features.errors= response.errors ? response.errors : "There was an issue retrieving the feature list"
        }
    }
    catch(ex){
        console.error(ex)
        features.data = null
        features.errors = ex
    }
    return features;
}

export const getFeaturesByPermissions = async (id)=>{
    let features={
        data: null,
        errors: null
    }
    try{
        let response = await Permissions.Features.permissions({company_id: id});
        if(response.status === 200){
            features.data = response.data
            features.errors=null
        }else{
            features.data= null
            features.errors= response.errors ? response.errors : "There was an issue retrieving the feature list"
        }
    }
    catch(ex){
        console.error(ex)
        features.data = null
        features.errors = ex
    }
    return features;
}

export const getSingleModule = async(id)=>{
    let module={
        data: null,
        errors: null
    }
    try{
        let response = await Permissions.Modules.get({id: id});
        if(response.status===200 && response.data){
            module.data = response.data[0]
            module.errors = null
        }
        else {
            module.data = null;
            module.errors = response.errors ? response.errors : "There was an issue retrieving the module list"
        }
    }catch(ex){
        console.error(ex)
        module.data = null;
        module.errors = ex
    }
    return module;
}

/** @param {{}} { endpointId, moduleId, slug, includeModules} */
export const getEndpoints = async(parameters)=>{
    const callObject = parameters ? {} : null;
    if(parameters?.endpointId) callObject.endpoint_id = parameters?.endpointId;
    if(parameters?.moduleId) callObject.module_id = parameters?.moduleId;
    if(parameters?.slug) callObject.slug = parameters?.slug;
    if(parameters?.includeModules) callObject.include_modules = true;
    let endpoints ={
        data: null,
        errors: null
    }
    try{
        let response = await Permissions.Endpoints.getAll(callObject);
        if(response.status === 200){
            endpoints.data = response.data
            endpoints.errors=null
        }else{
            endpoints.data = null;
            endpoints.errors = response.errors ? response.errors : "There was an issue retrieving the endpoint list"
        }
    }catch(ex){
        console.error(ex);
        endpoints.data = null;
        endpoints.errors = ex;
    }
    return endpoints;
}

export const getModuleTypes=async()=>{
    let features={
        data: null,
        errors: null,
    }
    try{
        let response = await Permissions.getModuleTypes();
        if(response.status === 200){
            features.data = response.data
            features.errors=null
        }else{
            features.data= null
            features.errors= response.errors ? response.errors : "There was an issue retrieving the feature list"
        }
    }catch(ex){
        console.error(ex)
        features.data = null
        features.errors = ex
    }
    return features;
}

export const getMenuItems=async(id, moduleId)=>{
    let parameters = null;
    if(id || moduleId) parameters = {}
    if(id) parameters.menu_item_id = id;
    if(moduleId) parameters.module_id = moduleId;
    let menuItems={
        data: null,
        errors: null,
    }
    try{
        let response = await Permissions.MenuItems.get(parameters);
        if(response.status === 200){
            menuItems.data = response.data
            menuItems.errors=null
        }else{
            menuItems.data= null
            menuItems.errors= response.errors ? response.errors : "There was an issue retrieving the menu items"
        }
    }catch(ex){
        console.error(ex)
        menuItems.data = null
        menuItems.errors = ex
    }
    return menuItems;
}

export const getPermissions=async(id)=>{
    let permission={
        data: null,
        errors: null,
    }
    let props = null
    if(id) props = {id: id}
    try{
        let response = await Permissions.PermissionLevels.get(props);
        if(response.status===200 && response.data){
            permission.data = response.data
            permission.errors = null
        }else{
            permission.data=null;
            permission.errors=response.errors ? response.errors : "There was an issue retrieving the permissions"
        }
    }catch(ex){
        console.error(ex)
        permission.data = null
        permission.errors = ex
    }
    return permission;
}

export const handleFeatureChecks = (id, checked, current)=>{
    let idsAndChecks= [];
    if(current.length===0) idsAndChecks = ([{id: id, checked: checked}]);
    else{
        let alreadyHas = current.find((item)=>item.id===id);
        if(alreadyHas) {
            let index = current.indexOf(alreadyHas);
            idsAndChecks = [...current];
            idsAndChecks[index].checked = checked;
        }
        else idsAndChecks = [...current, {id: id, checked: checked}];
    }
    return idsAndChecks;
}

const ROLE_COMPANY_OWNER = 3;

const processPermissionDefaultsIncoming = (modules, columns, key_id, isCompanyRoleFormat=true, includeDefaults=true) => {
    let moduleDataObject = {};
    // loop through the modules
    modules?.forEach((module)=>{
        let moduleObject = {
            name: module.name,
            permission_levels: module.permission_levels,
        };
        if (key_id) {
            moduleObject.defaults = {};
        }

        // first thing - if this module is a company only permission it won't have any defaults in the db
        // let's just set the company admin permission to true so it can show up in the list
        if (+module.company_id===+key_id) {
            moduleObject[ROLE_COMPANY_OWNER] = {
                is_allowed: true,
                permission_levels: [],
                is_changed: false
            }
        }

        if (isCompanyRoleFormat) {
            // loop through the permissions within roles - keep in mind this could be in any order
            if (module.permissions?.roles?.length) {
                module.permissions.roles.forEach((role)=>{

                    // if there is a company permission and this is a company query, add it to the grid and overwrite any defaults that are there
                    if (+role.company_id===+key_id && role.role_id && columns.includes(role.role_id)) {
                        moduleObject[role.role_id] = {
                            is_allowed: !!role.is_allowed ? true : false,
                            permission_levels: role.permission_levels,
                            is_changed: false
                        };
                    }

                    // only for if company_id is specified - if there is a default permission, add it to the grid under defaults
                    if (key_id && role.role_id && role.company_id===null && columns.includes(role.role_id)
                    && !moduleObject.defaults[role.role_id]) {
                        let defaultValues = {
                            is_allowed: !!role.is_allowed ? true : false,
                            permission_levels: role.permission_levels,
                        };
                        moduleObject.defaults[role.role_id] = defaultValues;
                        // if there is no company permission, add the default to it for now
                        if(!moduleObject[role.role_id]) {
                            moduleObject[role.role_id] = {...defaultValues,
                                is_changed: false
                            };
                        }
                    }
                });
            }
        } else { // non-isCompanyRoleFormat 

            // loop through the permissions within role columns - keep in mind this could be in any order
            // columns.forEach(column_id => {
            for (const column_id of columns) {
                // if there is a specific permission, add it to the grid and overwrite any defaults that are there
                if (module.permissions[column_id]) {
                    let tempThing = {
                        is_allowed: !!module.permissions[column_id].is_allowed ? true : false,
                        permission_levels: module.permissions[column_id].permission_levels,
                        is_changed: false
                    };
                    moduleObject[column_id] = tempThing;
                }

                if (includeDefaults) {
                    // now check to see if there is a default value - add one for each column
                    if (moduleObject.defaults && !moduleObject.defaults[column_id] && module.permissions.default) {
                        let defaultValues = {
                            is_allowed: !!module.permissions.default.is_allowed ? true : false,
                            permission_levels: module.permissions.default.permission_levels,
                        };
                        moduleObject.defaults[column_id] = defaultValues;
                        // if there are no specific permissions yet, add the defaults to them
                        if (!moduleObject[column_id]) {
                            moduleObject[column_id] = {...defaultValues,
                                is_changed: false
                            };
                        }
                    }
                }
            }
        }

        // now go through and see what roles are missing - add them to our grid as "false"
        let isDisabled = false;
        for (const col_id of columns) {
            if (!moduleObject[col_id] || isDisabled===true) {
                // if the company owner doesn't have access and this does, should we allow it to keep existing permissions?
                moduleObject[col_id] = {
                    is_allowed: false,
                    permission_levels: [],
                    is_changed: false,
                    is_disabled: isDisabled,
                };
            }

            if (includeDefaults) {
                // if there is no default listed, add it
                if (key_id && moduleObject.defaults && !moduleObject.defaults[col_id]) {
                    moduleObject.defaults[col_id] = {
                        is_allowed: false,
                        permission_levels: [],
                        is_changed: false,
                        is_disabled: false,
                    };
                }

                // if the default and the company permission are different then set is_changed to true
                if (key_id && moduleObject.defaults && moduleObject.defaults[col_id] && moduleObject[col_id]) {
                    if (permissionsDifferentFromDefault(moduleObject, col_id)) {
                        moduleObject[col_id].is_changed = true;
                    }
                }
            }

            if (isCompanyRoleFormat) {
                // if the company_role_owner is false then disable all the rest of the roles
                // this will only work if we loop through the roles in order with the company owner first
                if (col_id === ROLE_COMPANY_OWNER && moduleObject[col_id].is_allowed===false) {
                    isDisabled = true;
                }
            }
        }
        // add the module to the data object list
        moduleDataObject[module.id] = moduleObject;
    });
    return moduleDataObject;
}

// wrapper for company/role data
export const permissionRoleIncoming = (modules, roles, company_id) => {
    return processPermissionDefaultsIncoming(modules, roles.map((role)=>+role.id), company_id);
}

// wrapper for user data
export const permissionUserGroupIncoming = (modules, key_id, type='user') => {
    let includeDefaults = type==="group" ? false : true;
    return processPermissionDefaultsIncoming(modules, [type], key_id, false, includeDefaults);
}

// there are two ways to compare permissions - one is the regular, which is to compare to default values and process appropriately
// groups however only have one set of permissions, so all we need to know is if it changed at all
const processPermissionDefaultsOutgoing = (moduleData, roles, key_id, isGroupPermission=true) => {
    
    // the api requires everything be arranged by role
    let rolePermissions = {};
    let roleDisassociate = {};
    // create an empty array for each role
    roles.forEach((role_id)=>{
        rolePermissions[role_id] = [];
        roleDisassociate[role_id] = [];
    });

    // loop through the modules
    Object.keys(moduleData)?.forEach((moduleId)=>{
        // loop through the roles within moduleData
        Object.keys(moduleData[moduleId]).forEach((roleId)=>{
            if (!isNaN(roleId)) roleId = +roleId;
            let permissionHasChanged = (moduleData[+moduleId][roleId] && moduleData[+moduleId][roleId].is_changed) || false;

            // groups are different - they have no defaults
            // if the value is_changed and is_allowed is true, then we need to add it to the rolePermissions
            // if the value is_changed and is_allowed is false, then we need to add it to the roleDisassociate
            if (isGroupPermission) {
                if (moduleData[+moduleId][roleId] && moduleData[+moduleId][roleId].is_changed) {
                    if (moduleData[+moduleId][roleId].is_allowed) {
                        rolePermissions[roleId].push({
                            id: moduleId,
                            is_allowed: 1,
                            permission_levels: moduleData[+moduleId][roleId].permission_levels
                        });
                    } else {
                        roleDisassociate[roleId].push(moduleId);
                    }
                }
            } else {
                // if this is saving for a specific company only save if the permission is different from the default
                if (moduleData[+moduleId][roleId] && rolePermissions[roleId] && (!key_id || permissionHasChanged)) {
                    rolePermissions[roleId].push({
                        id: moduleId,
                        is_allowed: !!moduleData[+moduleId][roleId].is_allowed ? 1 : 0,
                        permission_levels: moduleData[+moduleId][roleId].permission_levels
                    });
                }
                // only remove a permission if it is different from the default
                if (key_id && roleDisassociate[roleId] && !permissionHasChanged) {
                    roleDisassociate[roleId].push(moduleId);
                }
            }
        });
    });

    // now format the rolePermissions object to match the api
    let returnArray = { permissions: [] };
    roles.forEach((role_id) => {
        let params = {};
        if (isNaN(role_id)) {
            params[role_id+"_id"] = key_id;  // should be "user_id" or "group_id"
        } else {
            params.role_id = role_id;
            params.company_id = key_id;
        }
        params.modules_to_associate = rolePermissions[role_id];
        params.modules_to_disassociate = roleDisassociate[role_id];
        returnArray.permissions.push(params);
    });
    
    return returnArray;
}

// wrapper for role/company data
export const permissionRoleOutgoing = (moduleData, roles, company_id) => {
    return processPermissionDefaultsOutgoing(moduleData, roles.map((role)=>+role.id), company_id, false);
}

// wrapper for user/group data - type should be "user" or "group" for now
export const permissionUserGroupOutgoing = (moduleData, identifier_id, type="user") => {
    return processPermissionDefaultsOutgoing(moduleData, [type], identifier_id, type==="group");
}

// this will compare two permission_levels arrays and return true only if they both have the same values
export const permissionLevelsEqualsCheck = (a, b) => {
    a.length === b.length &&
    a.every((v, i) => v === b[i]);
}

export const permissionsDifferentFromDefault = (module, roleId) => {
    let defaultForModuleRole = module.defaults && module.defaults[roleId] ? module.defaults[roleId] : null;
    let permissionLevels = Array.isArray(module[roleId]?.permission_levels) ? module[roleId]?.permission_levels : [];
    return (
        (module[roleId]?.is_allowed !== defaultForModuleRole?.is_allowed) ||
        (defaultForModuleRole?.permission_levels && permissionLevels && permissionLevels.length>0 && !permissionLevelsEqualsCheck(permissionLevels, defaultForModuleRole?.permission_levels))
    )
}
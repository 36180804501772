import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { authUserHasModuleAccess } from '../../../../utils/auth';
import { ChangeStatus } from './ChangeStatus';
import OrderPage from '../../OrderPage';
import Pos from '../../../../api/Pos';
import { ExpansionButtons } from './ExpansionButtons';
import { setErrorCatcher } from '../../../../utils/validation';
import { ItemQuickList } from './ItemQuickList';

import styles from '../OnlinePickup.module.scss';

export const SinglePickupOrder=({item, large=false, handleSuccess=()=>{}, getData=null, ...props})=>{

    const ORDER_PAGE_MODULE_ID = 305;

    const [ showFullOrder, setShowFullOrder ] = useState(false);
    const [ showItemDetails, setShowItemDetails ] = useState(false);
    const [ authUserHasOrderAccess, setAuthUserHasOrderAccess ] = useState(false);
    const [ error, setError ]=useState(null)
    const [ orderItems, setOrderItems ] = useState(null);


    useEffect(()=>{

        const checkAuth = async()=>{
            try{
                let response = await authUserHasModuleAccess(ORDER_PAGE_MODULE_ID)
                setAuthUserHasOrderAccess(response)
            }catch(ex){
                console.error(ex)
            }
        }

        checkAuth();

    },[]);

    const getSingleOrderItems=async()=>{
        setError(null)
        try{
            let response = await Pos.order.getItems({id:item.id});
            console.log(response)
            if(response) setOrderItems(response.data)
            else setErrorCatcher("There was a problem getting the order items.")
    }catch(ex){
            console.error(ex)
            setErrorCatcher("There was a problem getting the order items.")
        }
    }

    const handleShowItems=()=>{
        setShowItemDetails(!showItemDetails);
        getSingleOrderItems();
        if(showFullOrder) setShowFullOrder(false)
    };

    const handleShowOrder=()=>{
        setShowFullOrder(!showFullOrder);
        if(showItemDetails) setShowItemDetails(false);
    }

    return(
        <div>
            {item && 
                <>
                    {error}
                    <h4 className="section-title">
                        #{item.id} 
                    </h4>
                    <span className={styles["pickup-id"]}>
                        Started: ({format(new Date(item?.created_at), 'h:mm a')}) for {item.user_fname} {item.user_lname}
                        <br />Last Updated At: {format(new Date(item?.updated_at), "h:mm a")}
                    </span>
                    <div>
                        <ChangeStatus item={item} status={8} statusText="Not Started" active={item?.order_status_id === 8} large={large} handleSuccess={handleSuccess}/>
                        <ChangeStatus item={item} status={9} statusText="In Progress" active={item?.order_status_id === 9} large={large} handleSuccess={handleSuccess}/>
                        <ChangeStatus item={item} status={10} statusText="Ready for Pickup" active={item?.order_status_id === 10} large={large} handleSuccess={handleSuccess}/>
                        <ChangeStatus item={item} status={11} statusText="Picked Up" active={item?.order_status_id === 11} large={large} handleSuccess={handleSuccess}/>
                        <br />
                        <ExpansionButtons
                            auth={authUserHasOrderAccess}
                            largeButtons={large}
                            showItemDetails={showItemDetails}
                            setShowItemDetails={setShowItemDetails}
                            showFullOrder={showFullOrder}
                            setShowFullOrder={setShowFullOrder}
                            handleShowItems={handleShowItems}
                            handleShowOrder={handleShowOrder}
                            getData={getData}
                        />
                    </div>
                    {showFullOrder && authUserHasOrderAccess &&
                        <div>
                            <OrderPage importedId={item.id} />
                        </div>
                    }
                    {showItemDetails &&
                        <div>
                            <ItemQuickList 
                                items={orderItems} 
                            />
                        </div>
                    }
                </>
            }
        </div>
    )
}
import React, {useEffect, useState, useRef, useCallback } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Pos from '../../../../api/Pos';
import Product from '../../../../api/Products';

import './UserOrders.scss';
import DisplayOrders from './DisplayOrders';
import Refund from '../../../Pay/Refund/';
import Wizard from '../../../Pay/Refund/Wizard';
import SimpleRefund from '../../../Pay/Refund/SimpleRefund'
import Transactions from '../../../../api/Transactions';

//This component is called in: 
//src\containers\User\Profile\Transactions\Transactions.js
//src\containers\POS\Orders\Orders.js

/** Shows a detailed view of an order, including its items and allows those with a high enough permission to issue refunds.
 * 
 * Success and Error handling is passed in so that in the case of a modal, if the messages are when closing, they still appear on the parent component
 * 
 * @param props.orderId -to get the order details
 * @param props.isAdmin -bool of whether or not viewer can issue refunds
 * @param props.isPatron -bool of whether a viewer can see memos or not
 * @param props.setError -to show error on refund
 * @param props.setSuccess -to show success on refund
 * @param props.cleanUp - to apply finishing function
 * @param props.transactionId -for modal and to check transaction type 
 * @param props.transaction -for when orders/transactions are seperate upon load
 * @param props.noTransaction -for when order/transaction are seperate.  If true, it allows props.transactionId and props.transaction to be unnecessary
 * @param props.paymentRegister -To pass in the register ID of the current register when coming from the POS. 
 * @param props.mobile -To detect if a page is a mobile device or not to change HTML layout
 */

//const GUEST_USER_ID = 7;
export const UserOrders = (props) => {
    const mountedRef = useRef();
    const activeRegister=useSelector(state=>state.pos.register)
    const currentUserId=useSelector(state=>state.auth.user.profile.id)

    const company=useSelector(state=>state.company);    
    const GUEST_USER_ID = company?.config?.guest_user_id || null;

    const [ orderLength, setOrderLength ]=useState(0)
    const [ refundTotal, setRefundTotal ]=useState(0);
    const [ refundItems, setRefundItems ]=useState([]);
    const [ showAdvancedRefund, setShowAdvancedRefund ]=useState(false);
    const [ haveNoItems, setHaveNoItems ]=useState(true);
    const [ paymentRegister, setPaymentRegister ]=useState("");
    const [ currentOrder, setCurrentOrder ]=useState(null);
    const [ refundable, setRefundable ]=useState(false);
    const [ tokenDetermined, setTokenDetermined ]=useState(false);
    const [ simpleRefund, setSimpleRefund ]=useState(false);
    const [ itemsRefundable, setItemsRefundable ]=useState([])
    const [ transaction, setTransaction ]=useState(props.transaction || null)

//region useCallback

const getAllRefundable=useCallback(()=>{
     if(currentOrder){
        let refundableItems=[]
        currentOrder.items.forEach((item)=>{
            if(item.total_refunded_amount === 0 && item.shipping_refunded_amount === 0 && item.product_type_id!==9 && item.product_type_id!==6){
                refundableItems.push(item)
            }
        })
        if(mountedRef.current) setItemsRefundable(refundableItems);
     }
},[currentOrder]);

//Determines if the whole transaction is refundable or not
const determineRefundability=useCallback(()=>{
    let bool=false;
    if(currentOrder.order_status_id === 2 && props.transactionId) bool = true; //if it's looking at the transaction and it's complete
    if(!props.transactionId && currentOrder.order_status_id===7) bool=true; //if it's looking at the order and it's complete
    if(currentUserId === currentOrder.user_id) bool=false; //no no if a user is on their own account
    if(currentOrder.admin_fee_refunded !== 0) bool = false; //if admin fee has been refunded
    currentOrder.items.forEach((item)=>{ //if any part of an item has been refunded
        if(item.total_refunded_amount !== 0 //already had some for items refunded
            || item.shipping_refunded_amount !==0 //already had shipping refunded
            || item.product_type_id===9  //or is token 
            || item.product_type_id===6) //or is bundle
        bool = false
    })
    if(currentOrder.split_order_ids?.length > 0) bool = false //if it's a parent order
    if(currentOrder.items.length === 0 && !currentOrder.parent_id) bool = false //if there are no items - the exception being if it's a child order
    if(mountedRef.current ===true) setRefundable(bool)
},[currentOrder, currentUserId, props.transactionId]);

//endregion useCallback

//#region UseEffect
    //Controls mounted check on mount and unmount
    useEffect(()=>{
        mountedRef.current = true;

        return()=>{
            mountedRef.current = false;
            setTokenDetermined(false);
        }
    },[]);

    //get the transaction if we don't have it
    useEffect(()=>{
        if(currentOrder && currentOrder.transactions.length > 0){
            const getTransaction = async ()=>{
                if (currentOrder?.transactions?.[0]?.id){
                    try{
                        let response = await Transactions.getAll({id: currentOrder.transactions[0].id})
                        if(response.status === 200 && mountedRef.current && response.data){
                            setTransaction(response.data.transactions[0])  
                        }else if(response.errors){
                            return
                        } //not being able to get transactions won't break the whole page, just leave a couple fields blank, no need to error catch 
                    }catch(ex) {console.error(ex)}
                }
            }

            getTransaction();
        }
    },[currentOrder])
    
    //First effects, based on having props.  
    useEffect(()=>{
        if(mountedRef.current === true && currentOrder){
            if(props.noTransaction === false) setPaymentRegister(currentOrder.register_id)
            if(props.paymentRegister )setPaymentRegister(props.paymentRegister)
            if(props.referer?.includes("/pos/")) {
                let urlSplit = props?.referer?.split("/")
                let posId = urlSplit[3]
                setPaymentRegister(posId)
            }
        }

    },[props,currentOrder]);

    useEffect(()=>{
        /** Adds English Transalation for the Order Status to the order */
        const statusIdText=(orderStatusId)=>{
            let statusName
            switch(orderStatusId){
                case 1:
                    statusName="Pending"
                    break;
                case 2: 
                    statusName="Completed";
                    break;
                case 3: 
                    statusName="Cancelled";
                    break;
                case 4: 
                    statusName="Refunded";
                    break;
                default:
                    break;
            }
            return statusName
        }

        /**Adds an ID to the items in the transaction for maintaining check status if user goes back from refund screen */
        const addFields=(res)=>{
            for(let i =0; i < res.items.length; i++){
                res.items[i].item_id = i;
            }
            res.status_name = statusIdText(res.order_status_id);
        }

        const _getCurrentOrder=async()=>{
            try{
                let response = await Pos.order.get({id: props.orderId})
                if(response.data && response.status===200){
                    addFields(response.data);
                    setCurrentOrder(response.data);
                    if(response.data.parent_id) setHaveNoItems(false)
                }
            }
            catch(ex){
                console.error(ex);
            }
        }

        if(props.transactionId) _getCurrentOrder();
    },[props.orderId, props.transactionId]);

    //Disable/enable refund button based on having items selected
    useEffect(()=>{
        if(refundTotal > 0 && mountedRef.current === true){
            setHaveNoItems(false)
        }
        else{setHaveNoItems(true)}
    }, [refundTotal]);

    //Handles changing between refund and order display
    useEffect(()=>{
        if(showAdvancedRefund === false && refundItems.length > 0){
            for(let i = 0; i < refundItems.length; i++){
               const checkbox = document.getElementById(refundItems[i].key)
               checkbox.checked = true;
            }
        }
    }, [showAdvancedRefund,refundItems]);
    
    //This determines refundability.  In the future, when partial refunds are available, add other order_status_id (such as refunded), and allow the individual item disabling to handle it.
    useEffect(()=>{
        if(currentOrder){
            determineRefundability();
            getAllRefundable();
            setOrderLength(currentOrder.items.length)
            let disabled = false;
            currentOrder.items.forEach((item)=>{
                if(
                    ((currentOrder.order_status_id !==2 && props.transactionId) //for transactions, complete is 2
                    || (!props.transactionId && currentOrder.order_status_id !== GUEST_USER_ID) //for straight orders, complete is 7 and there's no transactionId
                    )
                    || (item.total_refunded_amount !== 0 //already refunded
                    || item.shipping_refunded_amount !==0  //already refunded
                    || item.product_type_id===9  //token product
                    || item.product_type_id===6) //bundle product
                ){
                    //If the order is any status other than complete, it disables the item.  Item is also disabled if there is any refunded amount in either field.
                    //Also disables items individually based on having a refund amount or being a service token (type 9) and bundles that can contain tokens (6)
                    disabled = true; 
                }
                if(item.product_type_id===6) {
                    checkForTokenBundle(item);
                }
                item.disabled = disabled;
            })
        }
    },[currentOrder, getAllRefundable, determineRefundability, props.transactionId, GUEST_USER_ID]);

    //After the individual refundability of items has been determined, check the order as a whole.
    useEffect(()=>{
        if(tokenDetermined===true) determineRefundability();
    },[tokenDetermined, determineRefundability])
    
//#endregion UseEffect
//#region Functions 


    
    /**Adds total together for the refund process of the selected items. */
    const addTotalRefund=(item, checked)=>{
        let shipping =0
        let tax =0
        let value = currentOrder.admin_fee_total
        if(item.shipping) shipping = item.shipping;
        if(item.tax) tax = item.tax;
        if(checked === true){
            value = refundTotal + (parseFloat(item.price) + shipping + tax)
        }
        if(checked === false){
            value = refundTotal - (parseFloat(item.price) + shipping + tax)
        }
        let num = Math.round(value*100)/100
        setRefundTotal(num)
    }
    
    /**Adds items names/prices for the items to be refunded */
    const recordRefundedItems=(item, checked)=>{
        if(checked === true){
            setRefundItems(refundItems => [ ...refundItems, item])
        }
        if(checked === false){
            for(let i =0; i <refundItems.length; i++){
                if(refundItems[i].name === item.name){
                    refundItems.splice(i, 1)
                    break;
                }
            }
        }
    }
    
    const handleCheckBox=(item, e, i)=>{
        let checked = e.target.checked
        let price = (item.final_price *item.quantity) 
        let key = `cb-items-${item.id}`
        let info = {item_id: item.id, name: item.product_name, price: price.toFixed(2), key: key, shipping: item.shipping, id:item.id, tax: item.tax}
        addTotalRefund(info, checked);
        recordRefundedItems(info, checked);
    }

    const checkForTokenBundle=async(item)=>{
        let isToken=false;
        try{
            let response = await Product.get({id: item.product_id});
            if(!response.errors) {
                let bundle = response.data.products[0].bundled_products;
                let bool = bundle.some((items)=>{
                    if (items.product_type_id===9) return true;
                    return false;
                })
                if(bool===true) isToken=true
            }
            else if(response.errors){
                isToken=true //if for some reason token status cannot be determined, default to unrefundable
            }
        }catch(ex){
            console.error(ex)
        }
        item.disabled=isToken;
        setTokenDetermined(true);
    }
//#endregion Functions

    if(!currentOrder) return(
        <Container fluid>
            <Row>
                <Col lg="12">
                    <SkeletonTheme color="#e0e0e0">
                        <Skeleton height={30} style={{marginBottom:"1rem"}} />
                        <Skeleton height={12} count={5} />
                        <Skeleton height={30} style={{marginBottom:"1rem",marginTop:"2rem"}} />
                        <Skeleton height={12} count={10} />
                    </SkeletonTheme>
                </Col>
            </Row>
        </Container>
    );

  return (
    <div>
        {currentOrder && 
            <>
                { showAdvancedRefund ? 
                    <Refund 
                        paymentRegister={paymentRegister}
                        maxTotal={refundTotal} 
                        transaction={props.transaction}
                        adminFee={currentOrder.admin_fee_total} 
                        items={refundItems} 
                        setError={props.setError}
                        setSuccess={props.setSuccess}
                        cleanUp={props.cleanUp}
                        orderLength={orderLength}
                        amountRefund={currentOrder.parent_id ? true : false}
                        transactionId={currentOrder?.transactions[0]?.transaction_type_id !==3 ? currentOrder?.transactions[0]?.id : 0} //if the order has yet to be refunded, there will only be one transaction, but if it's refunded, set to 0 as there is no 0 transaction
                        payment_method={currentOrder?.payment_method?.toLowerCase()}
                    />
                :  
                    <DisplayOrders 
                        from={props.from}
                        adminAuth={props.transaction.admin_auth}
                        currentOrder={currentOrder} 
                        handleCheckBox={handleCheckBox}
                        isAdmin={props.isAdmin}
                        isPatron={props.isPatron}
                        paymentMethod={props.transaction.payment_method}
                        transactionType={props.transaction.transaction_type}
                        mobile={props.mobile}
                        transaction={transaction}
                    />
                }
                <div className="d-flex justify-content-between mt-3 align-items-end" >
                    <div>
                        {props.isAdmin && currentOrder.order_status_id === 2 && currentOrder.payment_method_id !==7 && !currentOrder.split_order_ids.length &&
                            <>
                                <Button 
                                    variant="primary"
                                    disabled={!refundable || haveNoItems || currentOrder.parent_id} 
                                    onClick={()=>setShowAdvancedRefund(!showAdvancedRefund)}>
                                    {/*Goes back to the detail page when an advanced refund is active */}
                                    {showAdvancedRefund ? "Back to Order Details" : "Advanced Refund"}
                                </Button> 
                                {!showAdvancedRefund &&
                                    <Button className="primary" disabled={!refundable} onClick={()=>setSimpleRefund(true)}>
                                        Refund All
                                    </Button>
                                }
                            </>
                        }
                    </div>
                    <div>
                        <Button 
                            variant="light"
                            onClick={()=>{
                                if(props.from==="transactionHistory") props.cleanUp()
                                else props.backToOrders(false)
                            }}
                            data-cy="order-details-back"
                        >
                            Back To Orders 
                        </Button>
                    </div>
                    {simpleRefund &&
                        <Modal show={simpleRefund} onHide={()=>setSimpleRefund(false)}>
                            <Modal.Header closeButton/>
                            <Modal.Body>
                                <SimpleRefund 
                                    fullOrder={currentOrder}
                                    orderId={currentOrder.id}
                                    userId={currentOrder.user_id}
                                    user={`${currentOrder.user_fname} ${currentOrder.user_lname}`}
                                    registerId={activeRegister} 
                                    items={itemsRefundable}
                                    onClose={()=>setSimpleRefund(false)}
                                    adminFee={currentOrder.admin_fee_total}
                                    setError={props.setError}
                                    setSuccess={props.setSuccess}
                                    cleanUp={props.cleanUp}
                                />
                            </Modal.Body>
                        </Modal>
                    }
                </div>
            </>
        }  
    </div>
  )
}

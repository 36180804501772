import React from 'react';
import {Container} from 'react-bootstrap';

import styles from '../Wizard.module.scss';

export const Success = (props) => {
    return (
        <Container fluid>
            <h4 className={`${styles.title}`} data-cy="adv-refund-success-title">Refund Successful!</h4>
            <hr/>
            <p>
                The refund has been successfully processed.<br/>
                You may close this window to return to the order.
            </p>
        </Container>
    );
}
import React, { useState, useEffect, useRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
//import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from '../../../../components/common/Table';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';

import './Subscriptions.scss';
import Users from '../../../../api/Users';
import Toast from '../../../../components/Toast';
import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import SubscriptionApi from '../../../../api/Subscriptions';
import { useRoleCheck } from '../../../../components/common/CustomHooks';
import { authUserHasModuleAccessMany } from "../../../../utils/auth";

const VIEW_MODULE_ID = 75;
const EDIT_MODULE_ID = 206;

export const Subscriptions = (props) => {

    const mountedRef = useRef(false);
    const currentUserRoles = useRoleCheck();
    const [loading,setLoading]=useState(true);
    const [success, setSuccess]=useState();
    const [error, setError]=useState();
    const [memberships,setMemberships]=useState();
    const [activeSub, setActiveSub]=useState();
    const [modalHideShow, setModalHideShow]=useState(false);
    const [newFinalDate, setNewFinalDate]=useState();
    const [newRestartDate, setNewRestartDate]=useState();
    const [removefinalchecked, setRemoveFinalChecked]=useState(false);
    const [removeRestartChecked, setRemoveRestartChecked]=useState(false);
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);

    useEffect(()=>{
        mountedRef.current = true

        return()=>mountedRef.current = false
    },[]);

    // get memberships from api
    useEffect(() => {
        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccessMany([VIEW_MODULE_ID, EDIT_MODULE_ID], props.user_id);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }

        const convertDatesToISO=(data)=>{
            data.forEach((subscription)=>{
                subscription.final_bill_date = convertEachDate(subscription.final_bill_date)
                subscription.first_bill_date = convertEachDate(subscription.first_bill_date)
                subscription.last_bill_date = convertEachDate(subscription.last_bill_date)
                subscription.next_bill_date = convertEachDate(subscription.next_bill_date)
            })
        }
        
        if (props.user_id) checkPermission();

        setLoading(true);

        // TODO: Probably should prevent this from running if the user doesn't have permission to view this page
        // it's not a dealbreaker though, because the API will return a permission error if the user doesn't have permission
        // get all memberships
        Users.memberships({user_id:props.user_id})
        .then(response => {
            if(mountedRef.current) {
                if (response.data){
                    convertDatesToISO(response.data.subscriptions);
                    setMemberships(response.data.subscriptions);
                }
            }
        }).catch(e => console.error(e));

        // cancel stuff when component unmounts
        return () => {
            setLoading(false);
            setMemberships([]);
        }        
    },[props.user_id]);

    useEffect(()=>{
        if(activeSub && mountedRef.current) {
            setModalHideShow(true);
            if(activeSub.final_bill_date) setNewFinalDate(new Date(activeSub.final_bill_date));
            if(activeSub.restart_bill_date) setNewRestartDate(new Date(activeSub.restart_bill_date));
        }
    },[activeSub]);

    useEffect(()=>{
        if(memberships) setLoading(false);
    },[memberships])

    const handleFinalClick=(sub)=>{
        setActiveSub(sub);
        setModalHideShow(true)
    };

    const convertEachDate=(originalDate)=>{
        if(originalDate===null) return null;
        else{
            let split = originalDate.split(" ")
            let date = split[0]
            let time = split[1]
            return date+"T"+time
        }
    }

    const saveNewFinalDate=async()=>{
        setError();
        let date;
        if(removefinalchecked || !newFinalDate) date = null;
        else date = newFinalDate.toISOString();
        try{
            let response = await SubscriptionApi.setFinal({user_id: props.user.id, subscription_id: activeSub.id, final_bill_date: date})
            if(!response.errors){
                if(removefinalchecked || !newFinalDate) activeSub.final_bill_date=null
                else activeSub.final_bill_date = newFinalDate.toISOString();
                setSuccess(<Toast>Final Bill Date has been changed successfully.</Toast>)
            }
            else setError(<ErrorCatcher error={response.errors}/>)
            setNewFinalDate();
            setRemoveFinalChecked(false);
        }catch(ex){console.error(ex)}
    };

    const saveResumeDate=async()=>{
        setError();
        let date;
        if(removeRestartChecked || !newRestartDate) date=null
        else date=newRestartDate.toISOString()
        try{
            let response = await SubscriptionApi.setRestart({user_id:props.user.id, subscription_id: activeSub.id, restart_bill_date: date })
            if(!response.errors){
                if(removeRestartChecked || !newRestartDate) activeSub.restart_bill_date=null;
                else activeSub.restart_bill_date = newRestartDate.toISOString();
                setSuccess(<Toast>Final Bill Date has been changed successfully.</Toast>)
            }else if(response.errors){
                setError(<ErrorCatcher error={response.errors} />)
            }
            setNewRestartDate();
            setRemoveRestartChecked();
        }catch(ex){console.error(ex)}
    }
    
    if(loading)return(
        <SkeletonTheme color="#e0e0e0">
            <div className="mt-3">
                <Skeleton height={28} width={200}/>
                <Skeleton height={16} count={4} />
            </div>
        </SkeletonTheme>
    )

    return (
        <>
            <Container fluid>
                <h4 className="section-title">Subscriptions</h4>
                <hr/>
                {userHasModulePermission===null ?
                    <>Loading</>
                :
                !userHasModulePermission[VIEW_MODULE_ID] ?
                    <p>You do not have permission to view this user's subscriptions.</p>
                :
                <Row>
                    <Col>
                        {!memberships.length &&
                            <p className="text-center">No current subscriptions</p>
                        }
                        {memberships.length>0 &&
                            <div className="profile-subscriptions-wrapper">
                                {memberships.map((membership)=>(
                                    <React.Fragment key={`subscription-card-${membership.id}`}>
                                        <div className="sub-fieldset custom-box-shadow">
                                            <div className="card-title text-center" data-cy={`subscription-title-${membership.id}`}>
                                                {membership?.product_name} {membership?.product_variant_name.trim()!=="Default" ? membership.product_variant_name : ""}
                                            </div>
                                            <div className="text-center mb-3 bold">
                                                {membership?.subscription_status} {membership?.subscription_type_name} Subscription
                                            </div>
                                            <p>
                                                <span>First Bill Date: </span>
                                                {membership?.first_bill_date ? 
                                                    <span>{format(new Date(membership.first_bill_date), "MM/dd/yy").toString()}</span>
                                                    : 
                                                    <span>- - -</span>
                                                }
                                            </p>
                                            <p>
                                                <span>Last Bill Date: </span>
                                                {membership?.last_bill_date ?
                                                    <span>{format(new Date(membership.last_bill_date), 'MM/dd/yy').toString()}</span>
                                                :
                                                    <span>- - -</span>
                                                }
                                            </p>
                                            <p>
                                                <span>Next Bill Date: </span>
                                                {membership?.next_bill_date ?
                                                    <span>{format(new Date(membership.next_bill_date), 'MM/dd/yy').toString()}</span>
                                                :
                                                    <span>- - -</span>
                                                }
                                            </p>
                                            <p>
                                                <span>Payment Profile?</span>
                                                <span>{membership?.payment_profile_id ? "Yes" : "No"}</span>
                                            </p>
                                            <p>
                                                <span>Final Bill Date: </span>
                                                    {!membership?.final_bill_date || membership?.final_bill_date?.includes("1970") ? 
                                                        <span>- - -</span>
                                                    :
                                                        <span>{format(new Date(membership.final_bill_date), "MM/dd/yy").toString()}</span>
                                                    }
                                            </p>
                                            <p>
                                                <span>Restart Date: </span>
                                                {!membership?.restart_bill_date || membership?.restart_bill_date?.includes("1970") ?
                                                    <span>- - -</span>
                                                :
                                                    <span>{format(new Date(membership.restart_bill_date), "MM/dd/yy").toString()}</span>
                                                }
                                            </p>
                                            {userHasModulePermission[EDIT_MODULE_ID] &&
                                                <div className="site-row button-row">
                                                    <Button onClick={()=>handleFinalClick(membership)}>
                                                        Edit Subscription <i className="far fa-edit cp button-icon-margin-left" />
                                                    </Button>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        }
                    </Col>
                </Row>
                }
            </Container>

            {userHasModulePermission && userHasModulePermission?.[EDIT_MODULE_ID] &&
                <Modal show={modalHideShow} onHide={()=>setModalHideShow(false)}>
                    <Modal.Header closeButton>
                        <h5>
                            Edit Subscription{activeSub?.product_name ? `: "${activeSub?.product_name}"` : null}
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="profile-sub-modal-wrapper" data-cy="profile-sub-modal">
                            <fieldset>
                                <legend className="bold">
                                    Final Bill Date
                                </legend>
                                <p>Current final bill date: {" "}
                                    {!activeSub?.final_bill_date || activeSub?.final_bill_date?.includes("1970") ? 
                                        <span>Currently No Final Date</span>
                                        : 
                                        <span>{format(new Date(activeSub?.final_bill_date), 'MM/dd/yyyy').toString()}</span> 
                                    } 
                                </p>
                                <p>
                                    <DatePicker
                                        dateFormat="MM/dd/yyyy"
                                        minDate={new Date()}
                                        maxDate={new Date(new Date().getFullYear()+100,12,31)}
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                        selected={newFinalDate}
                                        onChange={setNewFinalDate}
                                        placeholderText="Select Date"
                                    />
                                </p>
                                <input type="checkbox" name="remove-final-date" onChange={(e)=>setRemoveFinalChecked(e.target.removefinalchecked)} value={removefinalchecked} /> {" "}
                                <label htmlFor="remove-date">Remove Date</label>
                                <br />
                                <Button onClick={saveNewFinalDate}>Save Final Bill Date</Button>
                            </fieldset>
                            <br />
                            <fieldset>
                                <legend className="bold">
                                    Restart Date
                                </legend>
                                <p>
                                    Current Restart Date: {" "}
                                    {!activeSub?.restart_bill_date || activeSub.restart_bill_date.includes("1970") ?
                                            <span>No restart date</span>
                                        :
                                            <span>{format(new Date(activeSub?.restart_bill_date), "MM/dd/yyyy").toString()}</span>
                                    }
                                </p>
                                <p>
                                    <DatePicker
                                        dateFormat="MM/dd/yyyy"
                                        minDate={new Date()}
                                        maxDate={new Date(new Date().getFullYear()+100,12,31)}
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                        selected={newRestartDate}
                                        onChange={setNewRestartDate}
                                        placeholderText="Select Date"
                                    />
                                </p>
                                <input type="checkbox" name="remove-restart-date" onChange={(e)=>setRemoveRestartChecked(e.target.checked)} value={removeRestartChecked} /> {" "}
                                <label htmlFor="remove-date">Remove Date</label>
                                <br />
                                <Button onClick={saveResumeDate}>Save Resume Date</Button>
                            </fieldset>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {success}
            {error}
        </>
    );
}
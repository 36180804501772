import Pos from '../../../api/Pos';
import {stringToUUID} from '../../../api/Common';


export const setItems = (items, active_register, order, customer, hash=null) => {
    let all_items=[];

    //console.log(state.orderAll.user_id, state.user.id);

    if (items){
        items.forEach(item=>{
            let all_addons=[];
            hash=stringToUUID(Math.random());
            let updated_item={
                order_item_id: item.id || null,
                hash:hash,
                order_id:order,
                user_id:customer,
                type:0,
                id:item.id,
                product_id:item.product_id,
                product_type_id: item.product_type_id,
                parent_id: null,
                category_id:null,
                variant_id:item.variant_id,
                variant_name:item.variant_name,
                product_name:item.product_name,
                product_price:item.final_price,
                original_price:item.base_price,
                // discounts:item.base_price - (item.final_price || item.base_price),
                is_taxable:item.is_taxable,
                valid_until:item.valid_until,
                qty:item.quantity,
                addons:[],
                bill_interval: item.bill_interval,
                interval_quantity: item.interval_quantity,
                activation_fee: item.activation_fee,
                bill_num_times: item.bill_num_times,
                price: item.price,
                subscription_max_users: item.subscription_max_users,
                subscription_type_id: item.subscription_type_id,
                subscription_type_name: item.subscription_type_name,
            }
            if (item.giftcard){
                updated_item.giftcard={
                    full_name: item.giftcard.full_name || null,
                    email: item.giftcard.email || null,
                    delivery_date: item.giftcard.delivery_date || new Date(),
                }
            }
            if (item.event) {
                updated_item.event = {
                    event_id: item.event.event_id || null,
                    for_user_id: item.event.for_user_id || null,
                    for_user_name: item.event.for_user_name || null,
                    custom_fields: item.event.custom_fields || null,
                    custom_field_definition: item.event.custom_field_definition || null,
                }
            }
            if (item.addons){
                item.addons.forEach(addon=>{
                    all_addons.push({
                        order_item_id: addon.id || null,
                        hash:hash,
                        order_id:order,
                        user_id:customer,
                        type:2,
                        id: addon.id ? addon.id : addon.product_id,
                        product_name:addon.product_name,
                        product_price:addon.final_price || addon.base_price,
                        original_price:addon.base_price,
                        discounts:addon.base_price - (addon.final_price || addon.base_price),
                        is_taxable:addon.tax?1:0,
                        qty:addon.quantity,
                        parent_id:item.id,
                        category_id:null,
                        variant_id:addon.variant_id,
                        variant_name:"",
                    });
                });
                updated_item.addons=[...all_addons];
            }
            all_items.push(updated_item);
        });
    }
    return all_items;
}

// break down the cart into the proper format for the Order.update call
export const cartToItems = async (cart_items) => {
    let all_items=[];
    cart_items.forEach(i=>{
        if (i.type!==2){
            let all_addons=[];
            if (i.addons){
                i.addons.forEach(j=>{
                    all_addons.push({
                        order_item_id: j.id || null,
                        id: j.id,
                        variant_id:j.variant_id,
                        qty:j.qty
                    });
                });
            }
            all_items.push({
                order_item_id: i.id || null,
                id: i.id,
                variant_id:i.variant_id,
                qty:i.qty,
                valid_until:i.valid_until,
                addons:all_addons
            });
        }
    });
    return all_items;
}

/*
 *  Convert the items returned from setItems() into addons on their own line for display in our POS and Print screens
 */
export const separateAddons = (items_set) => {
    let tempItems = [];
    if (items_set){
        items_set.forEach((item, i) => {
            tempItems.push(item);
            if (item.addons){
                item.addons.forEach(addon=>{
                    tempItems.push(addon);
                });
            }
        });
    }
    return tempItems;
}
import * as actionTypes from './actionTypes';
import { subElementLogic, duplicateElementLogic } from '../../utils/cms';

export const CMSCopyElement = (element_id) => async (dispatch, getState) => {
    const cms_elements = getState().cmsElements.present.elements;
    const original = cms_elements.filter(a => a.id === element_id);
    if (original.length > 0) {
        let copy = await duplicateElementLogic(original[0]);
        if (copy){
            if (!Array.isArray(copy)) copy = [copy];
            copy.forEach(c => {
                /**/dispatch({
                    type: actionTypes.CMS_ADD_ELEMENT, 
                    element: {...c},
                });/**/
            });
        }
    }
};

export const CMSAddElement = (element, indexToReplace, newIndex) => async (dispatch, getState) => {
    const cms_elements = JSON.parse(JSON.stringify(getState().cmsElements.present.elements));

    // certain elements need to have other elements added to them, like the layout or the wizard, so we deal with that here
    let steps = null;
    switch (true){
        case element?.element_id?.toLowerCase() === "slider":
            steps = await subElementLogic({...element}, [...cms_elements].filter(a=>a && a.parent_id===element.id), "custom-steps", "Slide",[
                {name: "slide_number", value: "{counter}"},
                {name: "slide_title", value: "Slide {counter}"},
            ], newIndex);
            break;
        case element?.element_id?.toLowerCase() === "wizard":
            steps = await subElementLogic({...element}, [...cms_elements].filter(a=>a && a.parent_id===element.id), "custom-steps", "WizardStep",[
                {name: "step_number", value: "{counter}"},
                {name: "step_title", value: "Step {counter}"},
            ], newIndex);
            break;
        case element?.element_id?.toLowerCase() === "layout":
            let _layout_type, element_to_update;
            let _children = [];
            // the element is a column, update the parent row with the new column count
            if (element?.properties?.find(p => p.name === "layout-type")?.value?.toLowerCase() === "column"){
                _layout_type = "Column";
                _children = cms_elements.filter(a=>a && a.parent_id===element.parent_id) || [];
                const _idx = cms_elements.findIndex(a=>a.id===element.parent_id);
                if (_idx>=0) element_to_update = cms_elements[_idx];
            } else {
                // the element is a row, if its empty add columns
                if (element?.properties?.find(p => p.name === "layout-type")?.value?.toLowerCase()  === "row"){
                    _layout_type = "Row";
                    element_to_update = element;
                    _children = cms_elements.filter(a=>a && a.parent_id===element.id) || [];
                }
            }

            if (_layout_type === "Row"){
                const column_size = 1; //Math.floor(12 / +_realvalue);
                steps = await subElementLogic({...element_to_update}, [..._children], "custom-layout", "Layout", [
                    {name: "layout-type", value: "Column"},
                    {name: "custom-layout", value: ""},
                    {name: "layout-sm", value: column_size},
                    {name: "layout-md", value: column_size},
                    {name: "layout-lg", value: column_size},
                    {name: "layout-xl", value: column_size},
                    {name: "margin", value: "0px"},
                    {name: "padding", value: "0px"},
                ], newIndex);
            }
            break;
        default:
            steps = null;
            break;
    }

    if (steps){
        dispatch({
            type: actionTypes.CMS_ADD_ELEMENT, 
            element: steps.element,
            indexToReplace: indexToReplace,
            newIndex: newIndex,
        });

        steps.added.forEach((step, i) => {
            dispatch({
                type: actionTypes.CMS_ADD_ELEMENT,
                element: step,
                indexToReplace: indexToReplace,
                newIndex: newIndex + i + 1,
            });
        });

        steps.removed.forEach(step => {
            dispatch({
                type: actionTypes.CMS_REMOVE_ELEMENT,
                element: step,
            });
        });
    } else {
        dispatch({
            type: actionTypes.CMS_ADD_ELEMENT,
            element: element,
            indexToReplace: indexToReplace,
            newIndex: newIndex,
        });
    }
};

export const CMSRemoveElement=(element)=>{
    return{
        type: actionTypes.CMS_REMOVE_ELEMENT,
        element: element
    };
};

export const CMSReset=(elements)=>{
    return{
        type: actionTypes.CMS_RESET,
        elements: elements
    };
};
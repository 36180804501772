import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Print from '../../../Print'
import ErrorCatcher from '../../../../../components/common/ErrorCatcher'
import MultiStep from '../../../../../components/common/MultiStep'

//step imports
import CashCardSplit from './CashCardSplit'
import SplitOrderConfirmation from '../Confirmation'
import SplitPaymentCash from '../PaymentMethods/SplitPaymentCash'
import SplitPaymentCC from '../PaymentMethods/SplitPaymentCC'

import * as actions from '../../../../../store/actions';

export const SingleUserSplit = (props) => {
    const dispatch = useDispatch();

    const splitPaymentStore=useSelector(state=>state.splitPayments);
    const currentOrder=useSelector(state=>state.pos[props.register].orderAll);
    const selectedUser=useSelector(state=>state.pos[props.register].user);

    const [error, setError]=useState();
    const [validated, setValidated]=useState(true);
    const [goToName, setGoToName]=useState(null);
    const [stepName, setStepName]=useState("");
    const [print, setPrint]=useState(false);
    const [cardHalf, setCardHalf]=useState();
    const [hideBack, setHideBack]=useState(false);

    useEffect(()=>{
        if(stepName==="Confirmation")setHideBack(true);
    },[stepName]);

    const singleUserStepOrder=[
        'CashCardSplit',
        'SplitSingleCash',
        'SplitSingleCC',
        'Confirmation'
    ]

    const goTo=(name)=>{
        setGoToName(name)
    }

    const steps=[
        {name: 'CashCardSplit',
        component: 
            <CashCardSplit 
                onClose={props.onClose}
                cancelSplit={props.cancelSplit}
                register={props.register}
                type={"singleUserSplit"}
                currentOrder={currentOrder}
                selectedUser={selectedUser}
            />
        },
        {name: 'SplitSingleCash',
        component:
            <SplitPaymentCash
                type={"singleUserSplit"}
                currentOrder={currentOrder}
                selectedUser={selectedUser}
                onClose={props.onClose}
            />
        },
        {name: 'SplitSingleCC', 
        component: 
            <SplitPaymentCC
                onClose={props.onClose}
                terminalDeviceId={props.terminalDeviceId}
                type={"singleUserSplit"}
                currentOrder={currentOrder}
                selectedUser={selectedUser}
                goTo={goTo}
            />
        },
        {name: 'Confirmation',
        component: 
            <SplitOrderConfirmation 
                selectedUser={selectedUser}
                setPrint={setPrint}
            />
        }
    ]

    useEffect(()=>{
        if(print){
            let cardHalf = splitPaymentStore.allOrders.filter((order)=>{
                if(order.memo === "Card Portion") return order;
                return null;
            })
            setCardHalf(cardHalf[0]);
        }
    },[print, splitPaymentStore.allOrders])

    const validate=(pageName)=>{
        let error={};
        let validated = true;
        switch(pageName){
            case 'CashCardSplit':
                if(splitPaymentStore.cashValue === 0 || splitPaymentStore.remainder === 0){
                    error = {...error, notSplit: "One of your values is 0.  If this is desired, please checkout normally."}
                }if(splitPaymentStore.ordersSplit===false){
                    error = {...error, notCreated: "You have not confirmed the amounts you wish to split your order into."}
                }if(splitPaymentStore.submitting===true){
                    error = {...error, creatingOrder: "The order is currently being split by the system.  Please wait."}
                }
                break;
            case 'SplitSingleCash':
                if(splitPaymentStore.submitting === true){
                    error = {...error, cashSubmitting: "You cannot proceed while submission is occuring."}
                }if(splitPaymentStore.cashSuccess===false){
                    error = {...error, cashNonSuccess: "You must complete a successful cash transaction before proceeding."}
                }
                break;
            case 'SplitSingleCC':
                if(splitPaymentStore.submitting === true){
                    error={...error, ccSubmitting: "You cannot continue while submission is in progress."}
                }if(splitPaymentStore.creditCardSuccess===false){
                    error={...error, ccNonSuccess: "You must complete a successful credit card transaction."}
                }
                break;
            case 'Confirmation':
            default:
                //
                break;
        }
        if(Object.keys(error).length>0) validated=false;
        dispatch(actions.setSplitPaymentErrors(error))
        setValidated(validated)
        return validated
    }

    const multiStepOnSubmit=()=>{
        let registerId = currentOrder.register_id
        if(validated===true && splitPaymentStore.cashSuccess===true && splitPaymentStore.creditCardSuccess===true && splitPaymentStore.submitting ===false){
            setPrint(false);
            props.onClose(true);
        }
    }

    const onAfterPrint=()=>{
        setPrint(false)
        if(validated===true && splitPaymentStore.cashSuccess===true && splitPaymentStore.creditCardSuccess===true && splitPaymentStore.submitting ===false){
            props.onClose(true);
        }
    }

  return (
    <>
    <div className="split-payment-single-wrapper">
        <div className="d-flex justify-content-end">
        <MultiStep
            showNavigation={false}
            goTo={goToName}
            steps={steps}
            stepOrder={singleUserStepOrder}
            onSubmit={multiStepOnSubmit}
            onChangeStep={validate}
            saveStep={(name)=>setStepName(name)}
            lastStepName={"Close"}
            hideBack={hideBack}
        />
        </div>
        {print && 
            <Print 
                register_id={props.register}
                onAfterPrint={onAfterPrint}
                status={2}
                cardPayment={cardHalf}
                payee={null}
                payment_cash={splitPaymentStore.cashValue.amount} 
                payment_change={splitPaymentStore.cashValue.change_amount}
                copies={1}
                order_id={currentOrder?.id}
                order={currentOrder}
                items={currentOrder?.items}
                payment_type={"spCashCC"} 
                transactionId={splitPaymentStore.cashValue.transaction_id}
            />}
        {error}
    </div>
    </>
  )
}
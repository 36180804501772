import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { Button } from 'react-bootstrap'

import Print from '../../../Print'
import usePrevious from '../../../../../components/common/CustomHooks';

export const MultiUserConfirmation = (props) => {

    const mountedRef = useRef();
    const payees=useSelector(state=>state.splitPayments.payees);
    const currentOrder=useSelector(state=>state.pos[props.register].orderAll)
    const [currentPayee, setCurrentPayee]=useState();
    const [print, setPrint]=useState(false);
    const [transactionId, setTransactionId]=useState();
    const oldPayee = usePrevious(currentPayee)

    useEffect(()=>{
        mountedRef.current = true

        return ()=>{
            mountedRef.current = false;
        }
    })

    useEffect(()=>{
        if(currentPayee && mountedRef.current){
            setPrint(true);
        }
    },[currentPayee]);

    useEffect(()=>{
        if(!print && mountedRef.current){
            setCurrentPayee()
        }
    },[print])

    const onAfterPrint=()=>{
        setPrint(false);
    }

  return (

      <>
      {payees?.map((payee)=>(
        <span key={`payee-print-btn-${payee.payeeOrder}`}>
            Print Receipt For: <Button type="button" onClick={()=>setCurrentPayee(payee)}>{payee?.identifier}</Button>
            {payee?.cash && 
                <span className="sp-success">Change Owed: ${payee?.cash?.change_amount?.toFixed(2)}</span>
            }
            {payee.paymentType==="credit card" &&
                <span className="sp-success">Credit Card Transaction</span>
            }
            <br />
        </span>
      ))}
        {print && 
            <Print
                transactionId={currentPayee?.transactionId}
                payment_type={props.type}
                payee={currentPayee}
                payment_cash={currentPayee?.amount}
                copies={1}
                order={currentOrder}
                items={currentOrder.items}
                onAfterPrint={onAfterPrint}
                payment_change={currentPayee?.cash?.change_amount}
            />
        } 
    </>
  )
}

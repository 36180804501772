import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Col, Form } from 'react-bootstrap';

import Fields from './Fields';
import FormGroupText from '../FormGroupText/FormGroupText';
import FormGroupSelect from '../FormGroupSelect/FormGroupSelect';
import FormGroupButton from '../FormGroupButton';
import FormGroupOptions from '../FormGroupOptions';

//import { CreditCard } from '@material-ui/icons';

const states = [
    {value: "", label: ""},
    {value: "AL", label: "Alabama"},
    {value: "AK", label: "Alaska"},
    {value: "AZ", label: "Arizona"},
    {value: "AR", label: "Arkansas"},
    {value: "CA", label: "California"},
    {value: "CO", label: "Colorado"},
    {value: "CT", label: "Connecticut"},
    {value: "DE", label: "Delaware"},
    {value: "DC", label: "District Of Columbia"},
    {value: "FL", label: "Florida"},
    {value: "GA", label: "Georgia"},
    {value: "HI", label: "Hawaii"},
    {value: "ID", label: "Idaho"},
    {value: "IL", label: "Illinois"},
    {value: "IN", label: "Indiana"},
    {value: "IA", label: "Iowa"},
    {value: "KS", label: "Kansas"},
    {value: "KY", label: "Kentucky"},
    {value: "LA", label: "Louisiana"},
    {value: "ME", label: "Maine"},
    {value: "MD", label: "Maryland"},
    {value: "MA", label: "Massachusetts"},
    {value: "MI", label: "Michigan"},
    {value: "MN", label: "Minnesota"},
    {value: "MS", label: "Mississippi"},
    {value: "MO", label: "Missouri"},
    {value: "MT", label: "Montana"},
    {value: "NE", label: "Nebraska"},
    {value: "NV", label: "Nevada"},
    {value: "NH", label: "New Hampshire"},
    {value: "NJ", label: "New Jersey"},
    {value: "NM", label: "New Mexico"},
    {value: "NY", label: "New York"},
    {value: "NC", label: "North Carolina"},
    {value: "ND", label: "North Dakota"},
    {value: "OH", label: "Ohio"},
    {value: "OK", label: "Oklahoma"},
    {value: "OR", label: "Oregon"},
    {value: "PA", label: "Pennsylvania"},
    {value: "RI", label: "Rhode Island"},
    {value: "SC", label: "South Carolina"},
    {value: "SD", label: "South Dakota"},
    {value: "TN", label: "Tennessee"},
    {value: "TX", label: "Texas"},
    {value: "UT", label: "Utah"},
    {value: "VT", label: "Vermont"},
    {value: "VA", label: "Virginia"},
    {value: "WA", label: "Washington"},
    {value: "WV", label: "West Virginia"},
    {value: "WI", label: "Wisconsin"},
    {value: "WY", label: "Wyoming"}
];

const getStyles = () => {
    const rootStyles = getComputedStyle(document.documentElement);
    const _getSassVar = (name) => {
        // split the name by spaces
        const nameArr = name.split(' ');
        // check if any of the values starts with a $, if so, replace it with the value of the variable
        nameArr.forEach((val, i) => {
            if (val.substring(0,1)=== '$') {
                nameArr[i] = rootStyles.getPropertyValue(`--${val.substring(1)}`) ;
            }
        });
        // return the name with the variables replaced
        return nameArr.join(' ');
    }

    const bgColor = rootStyles.getPropertyValue('--form-control-background-color');
    const color = rootStyles.getPropertyValue('--form-control-color');
    const fontFamily = rootStyles.getPropertyValue('--form-control-font-family').replace(/['"]+/g, '');
    const fontSize = rootStyles.getPropertyValue('--form-control-font-size');
    const fontWeight = rootStyles.getPropertyValue('--form-control-font-weight');
    const lineHeight = rootStyles.getPropertyValue('--form-control-line-height');
    const border = rootStyles.getPropertyValue('--form-control-border');
    const borderRadius = rootStyles.getPropertyValue('--form-control-border-radius');
    const padding = rootStyles.getPropertyValue('--form-control-padding');

    const borderArr = border.split(' ');
    const fontFamilyArr = fontFamily.split(',');    
    
    return {
        backgroundColor: _getSassVar(bgColor),
        color: _getSassVar(color),
        fontFamily: _getSassVar(fontFamily),
        fontSize: _getSassVar(fontSize),
        fontWeight: _getSassVar(fontWeight),
        lineHeight: _getSassVar(lineHeight),
        border: _getSassVar(border),
        borderSize: _getSassVar(borderArr[0] || "0"),
        borderStyle: _getSassVar(borderArr[1] || "none"),
        borderColor: _getSassVar(borderArr[2] || border),
        borderRadius: _getSassVar(borderRadius),
        padding: _getSassVar(padding),
        width: "99.85%",
        font: `${_getSassVar(fontFamilyArr[0])}:${_getSassVar(fontWeight)}`,
    }
}

const CCPayment =  props => {
    const {callback, validationCallback} = props;
    const user = useSelector(state => state.auth.user.profile);

    const billingRef = useRef();
    const ccRef = useRef();

    const [billData, setBillData] = useState();
    const [token, setToken] = useState();
    const [validated, setValidated] = useState(false);
    const [fields, setFields] = useState([
        {first_name: false},
        {last_name: false}, 
        {bill_address1: false}, 
        {bill_city: false}, 
        {bill_state: false}, 
        {bill_postalcode: false}, 
        {ccnumber: false}, 
        {ccexp: false}, 
        {cvv: false}
    ]);

    const processCreditCardEntry = useCallback(token => {
        setToken(token);
        if (callback) callback({isToken:true, token:token});
    },[callback]);

    const submitHandler = useCallback((e) => {
        e.preventDefault();
        window.CollectJS.startPaymentRequest();
    },[]);

    const validateFields=useCallback((field, status)=>{
        setFields(prev=>{
            let _fields = [...prev];

            // load prefilled fields
            if (billingRef.current && (field!=="ccnumber" && field!=="ccexp" && field!=="cvv")){
                _fields.forEach(f=>{
                    const _key = Object.keys(f)[0];
                    const _field = billingRef.current.querySelector(`input[name='${_key}']`);
                    if (_field){
                        f[_key] = !!_field.value;
                    }
                });
            }
    
            switch(field){
                case "ccnumber":
                case "ccexp":
                case "cvv":
                    const idx1 = _fields.findIndex(a=>Object.keys(a)[0]===field);
                    if (idx1>-1) _fields[idx1][field] = status; // this is returned by collectjs
                    break;
                case "first_name":
                case "last_name":
                case "bill_address1":
                case "bill_city":
                case "bill_state":
                case "bill_postalcode":
                    const idx2 = _fields.findIndex(a=>Object.keys(a)[0]===field);
                    if (idx2>-1) _fields[idx2][field] = !!status; // status holds the value of the field, but we need to convert it to a boolean
                    break;
                default:
                    break;
            }
            const is_valid=_fields.filter(f=>!f[Object.keys(f)[0]]).length===0;
            setValidated(is_valid);
            return _fields;    
        });
    },[]);

    const changeHandler = useCallback((e, field) => {
        setBillData({...billData, [field]: e.target.value});
        validateFields(field, e.target.value);
    },[billData, validateFields]);

    useEffect(()=>{
        if (user && !billData){
            const _billdata={};
            if (user.first_name) _billdata.first_name = user.first_name;
            if (user.last_name) _billdata.last_name = user.last_name;
            if (user.address1) _billdata.bill_address1 = user.address1;
            if (user.address2) _billdata.bill_address2 = user.address2;
            if (user.city) _billdata.bill_city = user.city;
            if (user.state) _billdata.bill_state = user.state;
            if (user.postal_code) _billdata.bill_postalcode = user.postal_code;
            setBillData(_billdata);
        }
    },[user, billData]);

    useEffect(()=>{
        if (validationCallback) {
            if (props.price > 0) validationCallback(validated);
            else validationCallback(true);
        }
    },[validated, validationCallback, props.price]);

    useEffect(()=>{
        if (!window.CollectJS) return;
        
        const configureCollectJS = () => {
            const css_styles = getStyles();

            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                price: props?.price || undefined,
                currency: 'USD',
                country: 'US',
                callback: (token) => {
                    processCreditCardEntry(token);
                },
                validationCallback: (field, status)=>{
                    validateFields(field, status);
                },
                fieldsAvailableCallback: ()=>{
                    validateFields("ccnumber", false);
                    validateFields("cvv", false);
                    validateFields("ccexp", false);
                },                
                googleFont:css_styles.font,
                customCss: `{
                    "background-color": "${css_styles.backgroundColor}",
                    "color": "${css_styles.color}",
                    "font-family": "${css_styles.fontFamily}",
                    "font-size": "${css_styles.fontSize}",
                    "font-weight": "${css_styles.fontWeight}",
                    "line-height": "${css_styles.lineHeight}",
                    "border-width": "${css_styles.borderSize}",
                    "border-style": "${css_styles.borderStyle}",
                    "border-color": "${css_styles.borderColor}",
                    "border-radius": "${css_styles.borderRadius}",
                    "padding": "${css_styles.padding}",
                    "width": "${css_styles.width}",
                    "height": "auto !important"
                }`,
                fields: {
                    ccnumber: {
                        placeholder: 'CC Number',
                        selector: '#bill_ccnumber',
                        title: 'Credit Card Number',
                        enableCardBrandPreviews:false,
                    },
                    ccexp: {
                        placeholder: 'MM / YY',
                        selector: '#bill_ccexp',
                        title: 'Credit Card Expiration'
                    },
                    cvv: {
                        placeholder: 'CVV',
                        selector: '#bill_cvv',
                        title: 'CVV'
                    }
                }
            });
                
            if (callback) {
                callback({collectJS: window.CollectJS});
            }
        }

        let intervalId;
        if (ccRef.current) configureCollectJS();
        else {
            intervalId = setInterval(() => {
                if (ccRef.current) {
                    configureCollectJS();
                    clearInterval(intervalId);
                }
            }, 200);
        }
    
        return () => {
            if (intervalId) clearInterval(intervalId);
        }
    
    },[callback, validateFields, processCreditCardEntry, props?.price]);

    useEffect(()=>{
        if (billData && callback) callback(billData);
    },[billData, callback]);
    
    useEffect(()=>{
        return () => {
            setBillData(null);
            setToken(null);
        }
    },[]);
    
    return (
        <div style={props.style || null}>
            {token &&
                <p></p>
            }
            {!token &&
            <Row className="d-flex align-items-center">
                <Col sm={12} lg={props?.forceMobile?12:9}>
                    <Row ref={billingRef}>
                        <Col sm={12} lg={6}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="first_name" 
                                type="text" 
                                label="First Name" 
                                feedback="Please enter your first name" 
                                required
                                disabled={props.submitting} 
                                value={user.first_name} 
                                onChange={e => changeHandler(e, "first_name")}
                            />
                        </Col>
                        <Col sm={12} lg={6}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="last_name" 
                                type="text" 
                                label="Last Name" 
                                feedback="Please enter your last name" 
                                required
                                disabled={props.submitting} 
                                value={user.last_name} 
                                onChange={e => changeHandler(e, "last_name")}
                            />
                        </Col>
                        <Col sm={12} lg={6}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="bill_address1" 
                                type="text" 
                                label="Address" 
                                feedback="Please enter your address" 
                                required
                                disabled={props.submitting} 
                                value={user.address1} 
                                onChange={e => changeHandler(e, "bill_address1")}
                            />
                        </Col>
                        <Col sm={12} lg={6}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="bill_address2" 
                                type="text" 
                                label="Address Line #2" 
                                feedback="Please enter your address" 
                                disabled={props.submitting} 
                                value={user.address2} 
                                onChange={e => changeHandler(e, "bill_address2")}
                            />
                        </Col>
                        <Col sm={12} lg={props?.forceMobile?6:5}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="bill_city" 
                                type="text" 
                                label="City" 
                                feedback="Please enter a city name" 
                                required
                                disabled={props.submitting} 
                                default_value={user.city} 
                                onChange={e => changeHandler(e, "bill_city")}
                            />
                        </Col>
                        <Col sm={12} lg={props?.forceMobile?6:5}>
                            <FormGroupSelect 
                                page_id={props.page_id} 
                                name="bill_state" 
                                options={states.map(a=>`${a.value}|${props.forceMobile?a.value:a.label}`)} 
                                label="State" 
                                feedback="Please select a state" 
                                required
                                disabled={props.submitting} 
                                value={states.filter(a=>a.value===user.state || a.label===user.state)?.[0]?.value || ""}
                                onChange={e => changeHandler(e, "bill_state")}
                            />
                        </Col>
                        <Col sm={12} lg={props?.forceMobile?6:2}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="bill_postalcode" 
                                type="text" 
                                pattern="\d{5}$"
                                label="ZIP Code" 
                                feedback="Please enter a zip code" 
                                required
                                disabled={props.submitting} 
                                value={user.postal_code} 
                                onChange={e => changeHandler(e, "bill_postalcode")}
                            />
                        </Col>
                    </Row>
                </Col>
                {props.price > 0 &&
                    <Col sm={12} lg={props?.forceMobile?12:3} as={props?.forceMobile?undefined:Card}>
                        <Fields {...props} ref={ccRef}/>
                        {/*
                        <Row>
                            <Col sm={12}>
                                <FormGroupOptions
                                    type="checkbox"
                                    page_id={props.page_id} 
                                    name="bill_savecard" 
                                    label="" 
                                    disabled={props.submitting} 
                                    onChange={e => changeHandler(e, "bill_savecard")}
                                    onClick={e => changeHandler(e, "bill_savecard")}
                                    value="1"
                                    options={"Save this card"}
                                />
                            </Col>
                        </Row>
                        */}
                    </Col>
                }
                {!props?.hideButton &&
                    <Col sm={12}>
                        <FormGroupButton page_id={props.page_id} label="Pay Now" type="button" onClick={submitHandler} disabled={props.submitting || !validated} click={e=>props.click(e,"done")} id="payButton" />
                    </Col>
                }
            </Row>
            }
        </div>
    );
}

export default CCPayment;
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { randomUUID } from '../../../../../utils/cms';

//import styles from './Layout.module.scss';
const Layout =  React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    let layout=0;
    if (props?.["custom-layout"]){
        if (Array.isArray(props["custom-layout"])) layout = props["custom-layout"][0];
        else layout = props["custom-layout"];
    }

    let Component = Container, extra_styles = {}, renderAs = undefined, fluid = undefined, sm = undefined, md = undefined, lg = undefined, xl = undefined, contenteditable= undefined;
    switch (props?.["layout-type"]?.toLowerCase()) {
        case "column":
            Component = Col;
            if (props?.["layout-sm"]) sm = props?.["layout-sm"] || "auto";
            if (props?.["layout-md"]) md = props?.["layout-md"] || "auto";
            if (props?.["layout-lg"]) lg = props?.["layout-lg"] || "auto";
            if (props?.["layout-xl"]) xl = props?.["layout-xl"] || "auto";
            //contenteditable = "true";
            break;
        case "row":
            Component = Row;    
            renderAs = Container;
            if (props?.fluid) {
                if (props.fluid.toLowerCase()==="boxed") fluid = false;
                if (props.fluid.toLowerCase()==="full width") fluid = true;
            } else fluid = true;

            if (props?.["fluid-max-width"]){
                const _maxWidths = props["fluid-max-width"].split(";");
                let maxWidth;
                switch(true){
                    case +window.innerWidth < 768:
                        maxWidth = _maxWidths.filter(a=>a.substring(0,2)==="sm")?.[0]?.split(":")?.[1] || undefined;
                        break;
                    case +window.innerWidth >= 768 && +window.innerWidth < 992:
                        maxWidth = _maxWidths.filter(a=>a.substring(0,2)==="md")?.[0]?.split(":")?.[1] || undefined;
                        break;
                    case +window.innerWidth >= 992 && +window.innerWidth < 1200:
                        maxWidth = _maxWidths.filter(a=>a.substring(0,2)==="lg")?.[0]?.split(":")?.[1] || undefined;
                        break;
                    case +window.innerWidth >= 1200:
                        maxWidth = _maxWidths.filter(a=>a.substring(0,2)==="xl")?.[0]?.split(":")?.[1] || undefined;
                        break;
                    default:
                        maxWidth = undefined;
                        break;
                }
                if (maxWidth) extra_styles.maxWidth = maxWidth;
            }
            break;
        case "container":
        default:
            Component = Container;
            fluid = true;  // the default container, which is a section is always full width
            break;
    }

    let _custom_classes="flex-grow-1";
    if (props?.["layout-grow"]) _custom_classes += " flex-grow-1";
    if (props?.["layout-type"].toLowerCase()==="container" && props?.["layout-centered"]) _custom_classes += " d-flex justify-content-center";
    if (props?.["layout-type"].toLowerCase()==="column") _custom_classes += " column-x";
    if (props?.["layout-type"].toLowerCase()==="row") _custom_classes += " m-0";


    /*let _style={
        display: "flex", 
        flexWrap: "wrap", 
        justifyContent: "flex-start", 
        alignItems: "flex-start", 
        alignContent: "flex-start", 
        flexGrow: 1,
        flexShrink: 0, 
        flexBasis: "auto", 
        flex: "1 0 auto", ...props?.style, 
        flexDirection: _direction
    };*/

    let _style={...props?.style, ...extra_styles};
    let _classes = `layout_${props.page_id} ${_custom_classes} ${props.className || ""}`.trim().replace(/\s+/g,' ');

    return (
        <Component 
            ref={ref}
            as={renderAs}
            id={props.is_preview ? `preview-${randomUUID()}` : props.id} 
            contentEditable={contenteditable} 
            fluid={fluid} 
            sm={sm} 
            md={md} 
            lg={lg} 
            xl={xl} 
            className={_classes} 
            style={_style} 
            onClick={preview_click}
        >
            {props?.children}
        </Component>
    );
});

export default Layout;
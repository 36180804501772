import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Tabs, Tab} from 'react-bootstrap';

import Login from '../../Login';
import SignUp from '../../SignUp';

export const Step1 = (props) => {
    const {goToStep, disableNext, disableBack} = props;
    const user = useSelector(state => state.auth.user);

    const [showRegister, setShowRegister] = useState(false);

    /*
    useEffect(() => {
        if (user?.profile?.id){
            disableBack(false);
            disableNext(false);
            goToStep({
                preventDefault: () => {},
                stopPropagation: () => {},
            }, props.referrerStep === "back" ? 1 : 3);
        } else {
            disableBack(true);
            disableNext(true);
        }
    }, [user?.profile?.id, goToStep, props.referrerStep, disableBack, disableNext]);
    */



    useEffect(() => {
        if (props?.requires_registration === 1 && !props.currentStep){
            goToStep({
                stopPropagation: () => {},
                preventDefault: () => {}
            }, 1);
        }

        if (user?.profile?.id){
            disableBack(false);
            disableNext(false);
            goToStep({
                preventDefault: () => {},
                stopPropagation: () => {},
            }, 2);
        } else {
            disableBack(true);
            disableNext(true);
        }
    }, [props?.requires_registration, props.currentStep, goToStep, props.referrerStep, disableBack, disableNext, user?.profile?.id]);

    useEffect(() => {
        return () => {
            setShowRegister(false);
        }
    }, []);

    if (user?.profile?.id) return null;

    return (
        <Tabs defaultActiveKey="login" id="step2-login" className="tabs">
            <Tab eventKey="login" title="Sign In">
                <Login {...props} />
            </Tab>
            <Tab eventKey="register" title="Register">
                <p className="bolder">Note: You are now creating a parent account, if enrolling in an event for your child, you will add your child's account after registering.</p>
                <SignUp {...props} forceMobile /> 
            </Tab>
        </Tabs>
    );
}
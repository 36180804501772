import React, { useState, useRef, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { useSelector } from 'react-redux';
import {default as _cssProperties} from '../../cssProperties';

import Component from './Component'; // this is where we render the element
import Dropbox from './Dropbox'; // this is the droppable area to add new elements or move existing ones

export const Element = props => {
	const { children } = props;
	//let allprops = {...props};

	//const dispatch = useDispatch();
	const ref = useRef(null);
	let isContentBlock = useRef(false); // stores whether the element is a content block or not
	const cmsSelectorElements = useSelector(state => state.cmsElements.present);

	const [parentProps, setParentProps] = useState({}); // stores the parent props of the element

	useEffect(() => {
		if (cmsSelectorElements.elements){
			const parent = cmsSelectorElements.elements.find(a=>a && a.id===props.parent_id && a.flag); // flag is set on cms.js if the element is a content block
			if (parent && parent.parent_id){
				isContentBlock.current = true;
			}
			if (props.parent_id){
				const _parent = cmsSelectorElements.elements.find(a=>a && a.id===props.parent_id);
				if (_parent) setParentProps(_parent);
			}
		}
	},[cmsSelectorElements.elements, props.parent_id]);

	// draggable
	const [{ isDragging }, drag, dragPreview] = useDrag({
		type: props?.element_type || "content-block",
		item: () => ({...props}),
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();  // this comes from the return of the drop function in useDrop
			if (item && dropResult?.id) {
				item.parent_id = dropResult.id;
				//console.log(item)
				//dispatch(actions.CMSAddElement({...item},item.index,item.hoverIndex));
			}
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
			handlerId: monitor.getHandlerId(),
		}),
	});


	let classes = props?.className || "";
	//const isActive = canDrop && isOverCurrent && !props?.[props.element_id]?.innerText && !props?.flag && props?.can_drop;
	//const isActiveParent = canDrop && isOver && props?.[props.element_id]?.innerText && !props?.flag && props?.can_drop;
	//if (isActive) classes += " can-drop";
	//else if (isActiveParent) classes += " can-drop";
	if (isDragging) classes += " is-dragging";
	if (+props.index===0) classes += " dustbin";

	const hasDropbox = props.element_type !== "container" && !props?.flag;
	const isVoid = props?.[props.element_id]?.innerText || props?.flag || props.element_id === "img" || props.element_id === "input" || props.element_id === "br" || props.element_id === "hr";

	let inner_accept_type = "content-block";
	if (props.element_type === "div-dustbin") inner_accept_type = "container";
	if (props.element_type === "container" || props.element_type === "content-block") inner_accept_type = "nothing";
	if (props.element_type === "row") inner_accept_type = "column";
	if (props.element_type === "column") inner_accept_type = ["content-block", "row", "form", "wizard"];
	if (props.element_type === "wizard") inner_accept_type = "wizard-step";
	if (props.element_type === "form") inner_accept_type = ["content-block", "row", "wizard"];
	if (props.element_type === "content-block" && props.can_drop===true) inner_accept_type = "content-block";
	if (props.index===0 && props.element_type !== "form" && props.element_type !== "wizard") inner_accept_type = "container";
	if (props.index) drag(ref);

	return (
		<>
			<Component ref={isDragging?dragPreview:ref} {...{...props, onClick: isContentBlock.current === true ? null : props?.onClick}} isContentBlock={isContentBlock.current} className={classes.trim()}>
				{hasDropbox && !isVoid &&
					<Dropbox {...props} pos={props.dzIndex} accept_type={inner_accept_type} />
				}
				{children}
				{/*I:{props.index}*/}
				{/*props.id==="div-dustbin" &&
					<Dropbox {...props} pos={props.dzIndex - 1} accept_type="container" />
				*/}
			</Component>
			{hasDropbox && props.id!=="div-dustbin" && props.element_type !=="row" && 
				<Dropbox {...parentProps} pos={props.dzIndex} accept_type={props.element_type} bottom/>
			}
			{props.id!=="div-dustbin" && props.element_type ==="container" &&
				<Dropbox {...parentProps} pos={props.dzIndex} accept_type="container" />
			}
		</>
	);
}
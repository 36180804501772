import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Skeleton, {SkeletonTheme } from 'react-loading-skeleton';
import format from 'date-fns/format';
import { FileURLContext } from '../../../../contexts/FileURLContext';

import './RegEventListCards.scss';

export const RegEventListCards = (props) => {
  const imageURL = useContext(FileURLContext);
  const history = useHistory();
  const windowSize=window.innerWidth;

  const [ loading, setLoading ]=useState(true);

  useEffect(()=>{
    if(props.events) setLoading(false);
  }, [props.events])

  if (loading===true) return (
    <div className="text-center">
        <SkeletonTheme color="#e0e0e0">
            <Skeleton height={12} count={5} />
            <Skeleton height={12} style={{marginTop:"2rem"}} />
            <Skeleton height={12} count={5} />
        </SkeletonTheme>
    </div>
  );  

  return (
    <>
      <h4 className="rel-title prof-he">Available Classes and Events</h4>
      <div className="rel-row reg-event-cards">
        <Col xs={12} sm={4} md={4} lg={3} xl={2}>
          <Card id="rel-first-card" onClick={()=>history.push('/p/event-register')}>
            <Card.Body 
              id="rel-first-card-body" 
              className="d-flex justify-content-center">
                <p id="rel-first-card-text">
                  Upcoming Events
                </p>
            </Card.Body>
            <Card.Footer id="rel-first-card-footer">
              <span>Event Calendar</span>
            </Card.Footer>
          </Card>
        </Col>
        {(history.location.pathname==="/" || history.location.pathname==="/p/home") && windowSize < 420 ?
          null
          :
          <>
            {props.events?.map((event, i)=>(
              <div className="reg-events-div" key={`rel-card-${i}`}>
                <Card className="rel-card" onClick={()=>{history.push(`/p/event-register?event=${event.id}`)}}>
                  <Card.Body className="rel-card-body d-flex justify-content-center">
                    <p className="rel-card-text">
                      {event.name}
                    </p>
                  </Card.Body>
                  <Card.Text>
                    <span className="rel-card-date d-flex flex-wrap align-content-end">{format(new Date(event.start_datetime), 'MM/dd/yyyy')} to {format(new Date(event.end_datetime), 'MM/dd/yyyy')}</span>
                  </Card.Text>
                  <Card.Footer className="rel-card-footer">
                    <span className="first-word">Event</span> Details
                  </Card.Footer>
                </Card>
              </div>
            ))}
            <div className="rel-all-arrow ml-5" onClick={()=>history.push('/p/event-register')}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-chevron-compact-right rel-arrow" viewBox="0 0 228 371">
                  <path d="m227.67501,185.673l-22.398,-23.273l-156.8,-162.4l-48.477,46.551l134.4,139.12l-134.4,139.12l48.477,46.551l156.8,-162.4l22.398,-23.273l0,0.004z"/>
              </svg>
            </div>
          </>
        }
      </div>
    </>
    );
};


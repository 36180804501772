import React, { useEffect, useState } from 'react'
import '../OrderPage.scss'
const OrderCoupons = ({currentOrder}) => {

    const [ couponsUsed, setCouponsUsed ] = useState([])


    useEffect(()=>{
        //console.log(currentOrder)
        if(currentOrder && currentOrder.coupons_applied.length){
            let couponDetailArray=[]
            if(currentOrder.coupons_applied.length){
                for(let i = 0; i < currentOrder.coupons_applied.length; i++){
                    let allCouponDetails = currentOrder.coupons.find(coupon => coupon.id === currentOrder.coupons_applied[i].id)
                    couponDetailArray.push(allCouponDetails)
                }
            }
            if(couponDetailArray.length){
                setCouponsUsed(couponDetailArray)
            }
        }
    },[currentOrder]);

    return (
        <div className="order-coupons-wrapper">
            <div>
                {couponsUsed.length ? 
                    <>
                        {couponsUsed.map((coupon, i)=>(
                            <div key={`coupon-${coupon.id}`}>
                                <p className="coupon-pair">
                                    <span>
                                        Name:
                                    </span>
                                    <span>
                                        {coupon.name}
                                    </span>
                                </p>
                                <p className="coupon-pair">
                                    <span>
                                        Discount Amount:
                                    </span>
                                    <span>
                                        {coupon?.discount_amount}%
                                    </span>
                                </p>
                                <p className="coupon-pair">
                                    <span>
                                        Description:
                                    </span>
                                    <span>
                                        {coupon.description}
                                    </span>
                                </p>
                                {i !== couponsUsed.length -1 && <hr />}
                            </div>
                        ))}
                    </>
                :
                    "No coupons applied"
                }
            </div>
        </div>
    )
}

export default OrderCoupons
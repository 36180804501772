import Request from './Api';

// get coupon(s)
const get = async (props) => {

    return (
        Request({
            url: "/coupon" + (!props ? "" : "/" + props.id),
            method: "GET"
        })
    );
}
 
// create coupon
const create = async (props) => {

    return (
        Request({
            url: "/coupon/create",
            data: props,
            method: "POST"
        })
    );
}

// update coupon
const update = async (props) => {

    return (
        Request({
            url: "/coupon/edit",
            data: props,
            method: "PUT"
        })
    );
}

// delete coupon
const remove = async (props) => {

    return (
        Request({
            url: "/coupon/delete",
            data: props,
            method: "DELETE"
        })
    );
}

// check coupon name
const checkName = async (props) => {
    return (
        Request({
            url: "/coupon/checkname",
            data: props,
            method: "POST"
        })
    );
}

// checks a coupon code for validity in an order - can this be applied to the items in the cart?
const verifyCode = async props => {
    return (
        Request({
            url: "/coupon/code",
            data: props,
            method: "POST",
        })
    );
}

const Modules = {
    get, create, update, remove, checkName, verifyCode
}
  
export default Modules;

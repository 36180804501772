import React, {useState, useEffect, useRef} from 'react';
import { Form, Button} from 'react-bootstrap';

import States from '../../../../../api/States';

export const SPAddressView = (props) => {
    // destructure props
    const {user,setUserDetails,setValidation}=props;

    const mountedRef = useRef(false)

    const [firstName, setFirstName]=useState(props.user?.first_name || "");
    const [lastName, setLastName]=useState(props.user?.last_name || "");
    const [address1, setAddress1] = useState(props.user?.address1 || "");
    const [address2, setAddress2] = useState(props.user?.address2 || "");
    const [city, setCity] = useState(props.user?.city || "");
    const [state, setState] = useState(props.user?.state || "");
    const [postalCode, setPostalCode] = useState(props.user?.postal_code || "");
    const [userDetails, setUserDetails_]=useState();

    const [statesList, setStatesList] = useState([]);

    //First load
    useEffect(()=>{
        mountedRef.current=true;

        getStatesForDropdown();

        return()=>{
            mountedRef.current = false;
        }
    },[]);

    useEffect(()=>{
        const checkValidation=()=>{
            if(postalCode!==""){
            //   setHaveDetails(true);
              setValidation(null);
            }
        }

        const createUserDetails=()=>{
            let details={
              first_name: firstName,
              last_name: lastName,
              address1: address1,
              address2: address2,
              city: city,
              state: state,
              postal_code: postalCode,
            };
            return details;
        }

        let temp=createUserDetails();
        if(mountedRef.current) {
            setUserDetails_(temp); //formatting for the print receipt and ccPayment
            setUserDetails(temp)
        }
        if (user) checkValidation();
      },[firstName, lastName, address1, address2, city, state, postalCode, setUserDetails, setValidation, user]);
    

    const getStatesForDropdown=()=>{
        let response = States.get();
        if(mountedRef.current) setStatesList(response);
    };



  return (
    <>
        <div>
            <p>These fields are optional but help to ensure a more secure transaction.</p>
            <div className="fields">
                <Form.Row>
                    <Form.Control type="text" name="first_name" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First Name" />
                </Form.Row>
                <Form.Row>
                    <Form.Control type="text" name="last_name" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last Name" />
                </Form.Row>
                <Form.Row>
                    <Form.Control type="text" name="bill_address1" value={address1} onChange={e => setAddress1(e.target.value)} placeholder="Address" />
                </Form.Row>
                <Form.Row>
                    <Form.Control type="text" name="bill_address2" value={address2} onChange={e => setAddress2(e.target.value)} placeholder="Address line 2 (optional)" />
                </Form.Row>
                <Form.Row>
                    <Form.Control  type="text" name="bill_city" value={city} onChange={e => setCity(e.target.value)} placeholder="City" />
                </Form.Row>
                <Form.Row>
                    <Form.Control as="select" className="form-select" name="bill_state" value={state || ""} onChange={e=>setState(e.target.value)}>
                        <option value="" disabled hidden>Select a State</option>
                        {statesList?.map(state=>(
                            <option key={`single-split-cc-state-dd${state.id}`} value={state.short_name} > {state.name} </option>
                            ))};
                    </Form.Control>
                </Form.Row>
                <br/>
            </div>
            <br />
            <h6>Required Fields</h6>
            <div className="cc-fields">
                <input className="cc-sp-zip" required type="text" name="bill_postal_code" value={postalCode} onChange={e => setPostalCode(e.target.value)} placeholder="Postal Code" />
                <span className="cc-sp-req">*</span>
            </div>
            {/* <Button type="submit" disabled={disableSubmit}>Finish Credit Card Payment</Button> */}
        </div>
    </>
  )
}

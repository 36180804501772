import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Users from '../../../api/Users';
import ErrorCatcher from '../../../components/common/ErrorCatcher';

import '../Login/Login.scss';
import { FileURLContext } from '../../../contexts/FileURLContext';

export const Password = (props) => {
    const imageURL = useContext(FileURLContext);
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [body, setBody]=useState();

    // form submission
    const submitHandler = async (e) => {
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setValidated(true);
        setSubmitting(true);
        setError(null);

        if (form.checkValidity() === true) {
            const formData = new FormData(e.target);
            const formDataObj = Object.fromEntries(formData.entries());

            await Users.forgotPassword({
                username:formDataObj.username,
            }).then(response => {
                setSubmitting(false);
                if (!response.errors){
                    setBody(
                        <React.Fragment>
                            <h1>Check your email!</h1>
                            <p>We just sent you an email with a password reset link for user <span className="bold">{formDataObj.username}</span>.</p>
                            <p>Please follow the instructions in it to succesfully reset your password.</p>
                            <p><Link to="/p/">Back to Sign In</Link></p>
                        </React.Fragment>
                    );
                } else {
                    setError(<ErrorCatcher error={response.errors} />);
                }
            }).catch( e => {
                setError(<ErrorCatcher error={e} />);
                setSubmitting(false);
            });
        } else setSubmitting(false);
    };


    if (!body){
        setBody(
            <React.Fragment>
            
            <h5>Forgot your password?</h5>
            <p>
                Enter your username and we'll send a password reset link to your email.
            </p>
            <Form className="my-5" noValidate validated={validated} onSubmit={submitHandler}>
                
                <Form.Row>
                <Form.Group controlId="username" className="ani-input" >
                                {/* <Form.Label>User Name</Form.Label> */}
                                <Form.Control required type="text" name="username"  id="username" placeholder="Enter your user name" />
                            </Form.Group>
                </Form.Row>
                {/* <Form.Label htmlFor="username">
                    User Name
                </Form.Label>
                <Form.Control  required type="text" className="mx-2" name="username" id="username" placeholder="Enter your user name"
                /> */}
                <Form.Row>
                <Button type="submit" disabled={submitting} className={`${submitting?"submitting":""}`}>Send</Button>
                </Form.Row>
            </Form>
            <div className="text-align-sign m-t-15"><Link to="/p/">Sign In</Link></div>
            </React.Fragment>
        );
    }

    return (
        <div className="login-register">
            {imageURL.backgroundImage?.endsWith(".mp4") &&
                <video autoPlay muted loop id="bgVideo">
                    <source type="video/mp4" src={imageURL.backgroundImage} />
                </video>
            }
            <section className="banner-wrapp login-banner" style={!imageURL.backgroundImage?.endsWith(".mp4") ? {backgroundImage:`url(${imageURL.backgroundImage})`} : null}>
            <div className="l-con-wrap container">
            <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                <div className="banner-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="hid-mob">
                                    <h4 className="dash-title-login">{imageURL.companyName}</h4>
                                    <h1 className="portal-he">Forgot Password</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                <div className="auth-form-wrap">
                <div className="logo"></div>
                {body}
                {error}
                </div>
                </div>
            
            </div>
            </div>
            
            </section>
        </div>   
    );
}

export const User = (props) => {
    const imageURL = useContext(FileURLContext);
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [body, setBody]=useState();

    // form submission
    const submitHandler = async (e) => {
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setValidated(true);
        setSubmitting(true);
        setError(null);

        if (form.checkValidity() === true) {
            const formData = new FormData(e.target);
            const formDataObj = Object.fromEntries(formData.entries());

            await Users.forgotUser({
                email:formDataObj.email,
            }).then(response => {
                setSubmitting(false);
                if (!response.errors){
                    setBody(
                        <React.Fragment>
                            <h1>Check your email!</h1>
                            <p>We just sent you an email with all user names registered under your email address.</p>
                            <p><Link to="/p/">Back to Sign In</Link></p>
                        </React.Fragment>
                    ); 
                } else {
                    setError(<ErrorCatcher error={response.errors} />);
                }
            }).catch( e => {
                setError(<ErrorCatcher error={e} />);
                setSubmitting(false);
            });
        } else setSubmitting(false);
    };


    if (!body){
        setBody(
            <React.Fragment>
            
            <h5>Forgot your user name?</h5>
            <p >
                Enter your email and we'll send you your user name.
            </p>
            <Form className="my-5" noValidate validated={validated} onSubmit={submitHandler}>
            <Form.Row>
                <Form.Group controlId="username" className="ani-input" >
                                {/* <Form.Label>User Name</Form.Label> */}
                                <Form.Control required type="email" name="email"  id="email" placeholder="Enter your email" />
                            </Form.Group>
            </Form.Row>
                {/* <Form.Label htmlFor="email">
                    Email
                </Form.Label>
                <Form.Control  required type="email" className="mx-2" name="email" id="email" placeholder="Enter your email"
                /> */}
            <Form.Row>
            <Button type="submit" disabled={submitting} className={`${submitting?"submitting":""}`}>Send</Button>
            </Form.Row>
            </Form>
            <div className="text-align-sign m-t-15"><Link to="/p/">Sign In</Link></div>
            </React.Fragment>
        );
    }

    return (
        <div className="login-register">
            {imageURL.backgroundImage?.endsWith(".mp4") &&
                <video autoPlay muted loop id="bgVideo">
                    <source type="video/mp4" src={imageURL.backgroundImage} />
                </video>
            }
            <section className="banner-wrapp login-banner" style={!imageURL.backgroundImage?.endsWith(".mp4") ? {backgroundImage:`url(${imageURL.backgroundImage})`} : null}>
                <div className="l-con-wrap container">
                <div className="row align-items-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                    <div className="banner-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="hid-mob">
                                        <h4 className="dash-title-login">{imageURL.companyName}</h4>
                                        <h1 className="portal-he">Forgot User Name</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                    <div className="auth-form-wrap">
                    <div className="logo"></div>
                    {body}
                    {error}
                    </div>
                    </div>
                
                </div>
                </div>
            
            </section>
        </div>    
    );
}
import React, { useState, useContext, useEffect, useCallback } from 'react';
import {Navbar,Image,Container,Row,Col,OverlayTrigger,Popover,ListGroup} from 'react-bootstrap';
import { useHistory, withRouter } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { useProSidebar } from 'react-pro-sidebar';

import Menu from '../../../api/Menu';
import ShoppingCart from '../ShoppingCart';
import { FileURLContext } from '../../../contexts/FileURLContext';
import Pos from '../../../containers/POS';


import './Header.scss';

const Header = (props) => {
    
	const { REACT_APP_HEADER_TAG } = process.env;
    const imageURL = useContext(FileURLContext);
	let history = useHistory();
    const { toggleSidebar, toggled } = useProSidebar();

	const profileMenuHandler = (e) => {
		e.preventDefault();
		history.push(e.target.getAttribute("href"));
	};

	const profileMenu = Menu.getProfileMenu();

	return (
		<React.Fragment>
			{ window.location.pathname.split('/')[1] !== "pos" &&
			<Navbar id="site-header" expand="lg" bg="default" className="sticky-top no-select-text">
				<div className="button-bar">
					<div className={`button-bar-left toggle-menu siteboss-logo`}>
						{toggled ?
                            <a href="#!" data-cy="header-menu-close-btn" className="menu-btn hide-full-size" onClick={()=>toggleSidebar()}><i className="far fa-times"></i></a>
                        :
                            <a href="#!" data-cy="header-menu-open-btn" className="menu-btn hide-full-size" onClick={()=>toggleSidebar()} data-tip="Menu"><i className="far fa-bars"></i></a>
                        }
                        <Navbar.Brand href="/p/home" data-cy="header-fullsize-logo-img" style={{backgroundImage:`url(${imageURL.logoHeader})`}} />
                        <div data-cy="header-mobile-logo-img" className="mobile-logo">
                            <a href="/p/" data-tip="Home">
                                {REACT_APP_HEADER_TAG==="QA" ?
                                    <>{"SB"}</>
                                :
                                    <Image src={ imageURL.logoNoText} />
                                }
                            </a>
                        </div>
                        {props.theme?.show_company_name && 
                            <span data-cy="header-company-name" className="company-name">{props?.company_name}</span>
                        }
						{REACT_APP_HEADER_TAG ? (<span className="env-name"><h2>{REACT_APP_HEADER_TAG}</h2></span>) : null}
					</div>
					<div className="button-bar-right">
                        {props.cart_register_id &&
                            <Pos
                                componentName="ShoppingCart"
                                register_id={props.cart_register_id}
                                checkout_url="/p/cart"
                                patron_layout={true}
                                is_patron_register={true}
                            />
                        }
                        <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={
                                <Popover>
                                    <Popover.Content>
                                        <Container fluid>
                                            <Row className="user-info">
                                                {props.user.picture && 
                                                    <Col md="auto">
                                                        <Image src={ props.user.picture } roundedCircle className="user-picture" />
                                                    </Col>
                                                }
                                                <Col>
                                                    <span className="bold">{props.user.first_name} {props.user.last_name}</span> <br/>
                                                    {props.user.role_name}
                                                </Col>
                                            </Row>											
                                        </Container>
                                        
                                        <ListGroup variant="flush">
                                            {profileMenu && profileMenu?.map((item,i) => {
                                                return (
                                                    <ListGroup.Item action key={`root-autocomplete-${i}`} href={item.url} onClick={profileMenuHandler}>
                                                        <i className={item.icon}></i> {item.name}
                                                    </ListGroup.Item>);
                                            })}
                                            <ListGroup.Item action href="/signout" onClick={profileMenuHandler}><i className="far fa-sign-out"></i>Sign out</ListGroup.Item>
                                        </ListGroup>
                                    </Popover.Content>
                                </Popover>
                            }
                        >
                            <div className="profile-btn" data-tip="Profile">
                                <a href="#!" data-cy="header-profile-img">
                                    {props.user.picture ?
                                        <Image src={props.user.picture} roundedCircle className="user-picture" />
                                    :
                                        <Image src={imageURL.noPic} roundedCircle className="user-picture" />
                                    }
                                    <span data-cy="header-profile-name" className="user-name">{props.user.first_name} {props.user.last_name}</span>
                                    <i className="far fa-angle-down fa-d"></i>
                                    
                                </a>
                            </div>
                        </OverlayTrigger>
                        <div className="signout-btn">
                            <a href="/signout" data-cy="header-sign-out">
                                <i className="far fa-sign-out" data-tip="Sign Out"></i>
                            </a>
                        </div>
                    </div>
				</div>
				<ReactTooltip globalEventOff="click" effect="solid" backgroundColor="#262D33" arrowColor="#262D33" />
			</Navbar>}
		</React.Fragment>
	);
}

export default withRouter(Header);
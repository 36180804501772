import React from 'react';
import {format} from 'date-fns';

import {Items, PaymentHistory} from '../Elements';

import styles from '../FullPageReceipt.module.scss';

/**Needs the following props 
 * @param userDetails -user details including f, l, and m name, address1, address2, city, state, zip
 * @param order (needs order id, and price values)
 * @param items (pass in the items seperate, in case they need to be such as a parent/child order)
 * @param transaction
 * @param paymentMethod
 * @param company
 * @param companyLogo
 * @param parentOrder
 * @param receiptMessage - optional, if present, should be an object {object: string, location: "top" or "bottom"}
*/
export const Invoice = React.forwardRef((props, ref) => {
    const headers=[`${props.parentOrder ? `Items from Order #${props.parentOrder.id}`: "Items"}`, "Base Price"];
    if (props?.order?.coupons_applied?.length>0) headers.push("Discount");
    headers.push("Final Price");

    return (
        <div ref={ref}>
            <div className={`${styles["fp-receipt-wrapper"]} ${styles.a4}`}>
                <div className={styles["flex-wrapper-company"]}>
                    <img src={props.companyLogo} alt="" className={styles["company-logo"]} />
                    <div className={styles["right-address"]}>
                        <h1 className={styles["fp-larger"]}>{props.company?.name}</h1>
                        {props.company?.address_street}
                        <br/>
                        {props.company?.address_street2 &&
                            <>
                                {props.company?.address_street2}
                                <br/>
                            </> 
                        }
                        {props.company?.address_city}, {props.company?.address_state} {props.company?.address_postcode}
                        <br />
                        {props.company?.primary_number}
                        <br />
                        {props.company?.email}
                    </div>
                </div>
                <div>
                    {props?.receiptMessage?.message && props?.receiptMessage?.location === "top" &&
                        <div>
                            {props.receiptMessage.message}
                        </div>
                    }
                </div>
                <div className={styles["flex-wrapper-customer-order"]}>
                    <div className={styles["fp-customer"]}>
                        <p> 
                            <span className={styles["bold"]}>Customer:</span>
                            <br/>
                            {`${props.order?.user_fname} ${props.order?.user_lname}`}
                            <br />
                            {props.order?.user?.mainAddress?.address1} <br />
                            {props.order?.user?.mainAddress?.address2 && <>{props.order?.address2}<br/></>}
                            {props.order?.user?.mainAddress?.city && props.order?.user?.mainAddress?.city + " , "} {props.order?.user?.mainAddress?.state} {props.order?.user?.mainAddress?.postal_code}
                        </p>
                    </div>
                    <div className={`${styles["fp-order-details"]} ${styles["totals"]}`}>
                        {props.order &&
                            <table>
                                <tbody>
                                    <tr>
                                        <td className={styles["span-left"]}>Order Date</td> 
                                        <td className={styles["span-right"]}>{format(new Date(props.order.updated_at), "MM-dd-yyy")}</td>
                                    </tr>
                                    <tr>
                                        <td className={styles["span-left"]}>Order Number</td>
                                        <td className={styles["span-right"]}>{props.order.id}</td>
                                    </tr>
                                    {/*(props.order?.transactions.length === 1 || props.paymentMethod) &&
                                        <tr>
                                            <td className={styles["span-left"]}>Payment Type</td>
                                            <td className={styles["span-right"]}>
                                                {props.order?.payment_method_id===1 && props.order?.transactions.length>0 ?
                                                    <>
                                                        {props.order?.transactions.map((transaction, i)=>(
                                                            <span key={`transaction-${i}`} className={styles.normalize}>
                                                                {transaction.cc_type} ending in {transaction.cc_number.substring(transaction.cc_number.length-4)}
                                                            </span>
                                                        ))}
                                                    </>
                                                : props.paymentMethod }
                                            </td>
                                        </tr>
                                    */}
                                    {props.order.parent_id && props.parentOrder &&
                                        <tr>
                                            <td className={styles["span-left"]}>Split Payment</td>
                                            <td className={styles["span-right"]}>
                                                ${props?.parentOrder?.total_price?.toFixed(2)}
                                                <br/><div className={`${styles["split-payment-icon"]}`}>Original Amount</div>
                                            </td>
                                        </tr>
                                    }
                                    {(!props.refunded || !props.partialRefund) && 
                                        <tr>
                                            <td className={styles["span-left"]}></td>
                                            <td className={`${styles.bold} ${styles["span-right"]}`}>
                                                {props.refunded && <span>** REFUNDED **</span>} 
                                                {props.partialRefund && <span>** PARTIALLY REFUNDED **</span>}
                                            </td>
                                        </tr>
                                    }
                                    {/*
                                    <tr className={`${styles["fp-order-last"]} ${styles["highlight"]}`}>
                                        <td>Total Payment</td>
                                        <td colSpan={2} className={styles.amount}>
                                            ${props.order.total_price?.toFixed(2)}
                                        </td>
                                    </tr>
                                    */}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
                
                <Items {...props} headers={headers}/>

                <div className={`${styles["flex-wrapper-totals"]} ${styles["totals"]} text-right`}>                
                    {props.order?.payment_method_id!==7 && props.order?.memo &&
                        <div className={styles.memo}>
                            <span className={styles.bold}>Memo:</span>
                            <p>{props.order?.memo}</p>
                        </div>
                    }
                    {props.order?.coupons_applied?.length>0 && 
                        <div className={styles.coupons}>
                            <span className={styles.bold}>Applied Discounts:</span>
                            <ul>
                            {props.order.coupons_applied.map((coupon, i)=>{
                                let discount="";
                                if (+coupon.discount_type === 1) discount = `$${coupon.discount_amount}`;
                                else if (+coupon.discount_type === 0) discount = `${coupon.discount_amount}%`;
                                if (discount){
                                    if (coupon.name.toLowerCase().includes(parseInt(discount))) discount = "";
                                    else discount = `(${discount})`;
                                }
                                return (<li key={`applied-coupon-${i}`}>{coupon.name} {discount}</li>);
                            })}
                            </ul>
                        </div>
                    }
                    {props.order && 
                        <table>
                            <tbody>
                                <tr>
                                    <td className={styles["span-left"]}>Subtotal</td>
                                    <td className={styles["span-right"]}>${props.order.subtotal_price.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className={styles["span-left"]}>Taxes</td>
                                    <td className={styles["span-right"]}>${props.order.tax_total.toFixed(2)}</td>
                                </tr>
                                {props.order?.tip>0 && 
                                    <tr >
                                        <th className={styles["span-left"]}>Tip</th>
                                        <th className={styles["span-right"]}>${props.order.tip?.toFixed(2)}</th>
                                    </tr>
                                }
                                {/*props.order.admin_fee_total > 0 &&
                                    <tr>
                                        <td className={styles["span-left"]}>Administrative Fee</td>
                                        <td className={styles["span-right"]}>${props.order.admin_fee_total.toFixed(2)}</td>
                                    </tr>
                                */}
                                {props.order?.price_adjustments?.map((adjustment, i)=>(
                                    <tr key={`pa-order-${i}`}>
                                        <td className={styles["span-left"]}>{adjustment.price_adjustment_type_name}</td>
                                        <td className={styles["span-right"]}>
                                            {adjustment.amount > 0 ? "" : "-"}
                                            ${Math.abs(adjustment.amount).toFixed(2)}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    {props.order.total_discount > 0 &&
                                        <>
                                            <td className={styles["span-left"]}>Discounts</td>
                                            <td className={styles["span-right"]}>${props.order.total_discount.toFixed(2)}</td>
                                        </>
                                    }
                                </tr>
                                <tr className={styles["highlight"]}>
                                    <td className={styles["span-left"]}>Final Total</td>
                                    <td className={`${styles["span-right"]} ${styles.amount}`}>${props.order.total_price.toFixed(2)} </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>
                {props?.receiptMessage?.message && props?.receiptMessage?.location === "bottom" &&
                    <div>
                        <p>
                            {props.receiptMessage.message}
                        </p>
                    </div>
                }

                <PaymentHistory order={props.order} />
            </div>
            {props.extraContent && props.extraContent}
        </div>
    );
});
import React from 'react';
import {Container, Row, Col, Table} from 'react-bootstrap';

import wizardStyles from '../Wizard.module.scss';
import styles from './Summary.module.scss';

export const Summary = (props) => {

    const refund_amount = 
        props?.stepData?.type === "amount" ? 
            (+props?.stepData?.amount || 0) 
        :
            /*props?.stepData?.type === "item" && props?.stepData?.all_items === 1 ? 
                +props?.maxTotal 
            :*/
                props?.stepData?.type === "item" /*&& props?.stepData?.all_items === 2*/ ? 
                    props?.stepData?.items?.reduce((acc, item) => +acc + +item.refund_amount, 0) || 0
                : 
                    props?.stepData?.type === "transaction" ?
                        props?.stepData?.transactions?.reduce((acc, item) => +acc + +item.refund_amount, 0) || 0
                    :
                        0;

    const subtotal = +props.order.total_price - +props.order.admin_fee_total - +props.order.tax_total - +props.order.shipping_total - +props.order.tip;

    return (
        <Container fluid className={`${styles.wrapper} ${props.full ? styles.active : ""}`}>
            <Row>
                {!props.full &&
                    <Col sm={12}>
                        <h4 className={wizardStyles.title}>Order #{props.order.id}</h4>
                        <span className={wizardStyles.subtitle} data-cy="refund-sum-max">Maximum Allowed Refund: ${(props?.maxTotal || 0).toFixed(2)}</span>

                        <div className={wizardStyles["info-pair"]}>
                            <span>Subtotal:</span>
                            <span className="text-right" data-cy="refund-sum-subtotal">${subtotal.toFixed(2)}</span>
                        </div>
                        {props?.order?.admin_fee_total > 0 &&
                            <div className={wizardStyles["info-pair"]}>
                                <span>Admin Fee:</span>
                                <span className="text-right">${props.order.admin_fee_total.toFixed(2)}</span>
                            </div>
                        }
                        {props.order.tip > 0 &&
                            <div className={wizardStyles["info-pair"]}>
                                <span>Tip:</span>
                                <span className="text-right" data-cy="refund-sum-tip">${props.order.tip.toFixed(2)}</span>
                            </div>
                        }
                        {/*props.order.admin_fee_total > 0 &&
                            <div className={wizardStyles["info-pair"]}>
                                <span>Admin Fee:</span>
                                <span className="text-right">${props.order.admin_fee_total.toFixed(2)}</span>
                            </div>
                        */}
                        <div className={wizardStyles["info-pair"]}>
                            <span>Tax:</span>
                            <span className="text-right" data-cy="refund-sum-tax">${props.order.tax_total.toFixed(2)}</span>
                        </div>
                        {props.order?.price_adjustments?.map((adjustment, i)=>(
                            <div key={`pa-order-${i}`} className={wizardStyles["info-pair"]}>
                                <span>{adjustment.price_adjustment_type_name}</span>
                                <span className="text-right" data-cy="refund-sum-adjustment">
                                    {adjustment.amount > 0 ? "" : "-"}
                                    ${Math.abs(adjustment.amount).toFixed(2)}
                                </span>
                            </div>
                        ))}
                        {props.order.shipping_total > 0 &&
                            <div className={wizardStyles["info-pair"]}>
                                <span>Shipping:</span>
                                <span className="text-right" data-cy="refund-sum-shipping">${props.order.shipping_total.toFixed(2)}</span>
                            </div>
                        }
                        <div className={wizardStyles["info-pair"]}>
                            <span>Total:</span>
                            <span className="text-right" data-cy="refund-sum-total">${props.order.total_price.toFixed(2)}</span>
                        </div>                
                        <div className={wizardStyles["info-pair"]}>
                            <span>Payments:</span>
                            <span className="text-right" data-cy="refund-sum-payments">${props.order.payment_total.toFixed(2)}</span>
                        </div>
                        <div className={`${wizardStyles["info-pair"]} mb-4`}>
                            <span>Balance:</span>
                            <span className="text-right" data-cy="refund-sum-balance">${(props.order.total_price - props.order.payment_total).toFixed(2)}</span>
                        </div>
                        {props?.stepData?.type &&
                            <>
                                <div className={`${wizardStyles["info-pair"]}`}>
                                    <span>Refund Type:</span>
                                    <span data-cy="refund-sum-type">
                                        {props?.stepData?.type === "amount" && "By Amount"}
                                        {/*props?.stepData?.type === "item" && props.stepData?.all_items === 1 && "All Items"}
                                        {props?.stepData?.type === "item" && props.stepData?.all_items === 2 && "Specific Items"*/}
                                        {props?.stepData?.type === "item" && "By Items"}
                                        {props?.stepData?.type === "transaction" && "By Payments"}
                                    </span>
                                </div>
                                <div className={`${wizardStyles["info-pair"]} mb-3`}>
                                    <span>Refund Amount:</span>
                                    <span className="text-right" data-cy="refund-sum-amount">${refund_amount.toFixed(2)}</span>
                                </div>
                            </>
                        }
                    </Col>
                }
                {props.full &&
                    <Col>
                        <h5 className={wizardStyles.title}>Time to double check...</h5>
                        <hr/>
                        {props?.stepData?.items &&
                            <Table className="table mt-0" responsive>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Refund Type</th>
                                        <th className="text-right">Refund Amount</th>
                                        <th>Memo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {props?.stepData?.items?.map(item => (
                                    <tr key={`summary-prod-${item.id}`}>
                                        <td>{item?.product_name}</td>
                                        <td className="capitalize">{item?.refund_type}</td>
                                        <td className="text-right">${item?.refund_amount.toFixed(2)}</td>
                                        <td>{item?.refund_memo}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        }
                        <p>
                            Are you sure you want to refund&nbsp;
                            {props?.stepData?.type === "amount" ? 
                                <span className="bold uppercase secondary-font" data-cy="adv-refund-summary-amount">${refund_amount.toFixed(2)}</span>
                            :
                                <>
                                    {`${props?.stepData?.items?.length || "1"} item${props?.stepData?.items?.length > 1 ? "s" : ""} totaling`}
                                    &nbsp;
                                    <span className="bold uppercase secondary-font" data-cy="adv-refund-summary-amount">${refund_amount.toFixed(2)}</span>
                                </>
                            }
                            &nbsp;for <span className="bold uppercase secondary-font" data-cy="adv-refund-summary-customer">{`${props.order.user.first_name} ${props.order.user.last_name}`}</span>?
                        </p>
                        <p>If refunding by item, once this refund is complete, you will not be able to process refunds on the same items in the order.</p>
                        {+props?.stepData?.admin_fee > 0 &&
                            <p>
                                When you process a refund that covers the admin fee, this amount is returned to the customer's account, 
                                reinforcing their satisfaction and trust in your services. But remember, refunding this fee doesn't mean your company gets out of 
                                paying the original fee for using the transaction platform. You still need to pay this fee to SiteBoss for the transaction you made.
                            </p>
                        }
                        <hr />
                        <p>
                            <span className="bold">Credit Card</span> refunds can take up to 7 days to process in the bank's systems.
                        </p>
                        <p>
                            <span className="bold">Gift Card</span> refunds will be refunded within the hour.  
                        </p>
                        <p>
                            <span className="bold">Cash and Check</span> refunds need to be issued in cash at the time of refunding.
                        </p>
                        <p>
                            <span className="bold">Manager discounts</span> cannot be refunded as they are not monies recieved.  
                        </p>
                    </Col>
                }
            </Row>
        </Container>
    );
}
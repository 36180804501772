import React from 'react';
import {Container, Button} from 'react-bootstrap';

import styles from './Buttons.module.scss';

export const Buttons = React.forwardRef((props, ref) => {
    const {onStepChange, onHide} = props;

    return (
        <Container fluid className={styles.wrapper} ref={ref}>
            <Button
                variant="light"
                onClick={props.success || onHide}
                data-cy="refund-cancel-button"
                type="button"
                data-type="close"
                disabled={props.submitting}
                className={`${props.registerId ? styles["pos-button"]: ''} ${styles.cancel} btn-cancel w-auto${props.submitting?" submitting":""}`}
            >
                {props.success ? "Close" : "Cancel"}
            </Button>
            {!props.success &&
                <>
                    <Button 
                        className={props.registerId ? `${styles["pos-button"]} ${styles.secondary}` : `${styles.button} btn-light`}
                        data-cy="refund-back"
                        disabled={props.submitting || props.step.step === 1 || !props.step.back}
                        onClick={e => onStepChange(props.step.back , "back")}
                    >
                        Back
                    </Button>
                    {props.step.step < props.maxSteps ?
                        <Button 
                            className={props.registerId ? `${styles["pos-button"]} ${styles.primary}` : styles.button}
                            data-cy="refund-next"
                            disabled={props.submitting || props.step.step  > props.maxSteps || !props.step.next}
                            onClick={e => onStepChange(props.step.next , "next")}
                        >
                            Next
                        </Button>
                        :
                        <Button 
                            className={props.registerId ? `${styles["pos-button"]} ${styles.primary}` : styles.button}
                            data-cy="refund-submit"
                            disabled={props.submitting}
                            onClick={e => onStepChange(props.step.step , "submit")}
                        >
                            Process Refund
                        </Button>
                    }
                </>
            }
        </Container>
    );
});
import React from 'react';
import {Card, Button as BsButton} from 'react-bootstrap';

import styles from './Button.module.scss';

const Button = (props) => {
    return (
        <BsButton as={Card} variant={props.active?"secondary":"light"} className={`${styles.wrapper}`} onClick={e=>props.onClick(e, props)}>
            {props.children}
        </BsButton>
    );
}

export default Button;
import React,{useState, useEffect, useContext} from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { format, toDate } from 'date-fns';

import { FileURLContext } from '../../../../../contexts/FileURLContext';
import APIUsers from '../../../../../api/Users';

import FormGroupText from '../FormGroupText/FormGroupText';
import FormGroupButton from '../FormGroupButton';
import FormGroupDatePicker from '../FormGroupDatePicker/FormGroupDatePicker';

const SignUp =  React.forwardRef((props, ref) => {
    const {callback} = props;
    const company_context = useContext(FileURLContext);

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }
    
    const user = useSelector(state => state.auth.user);
    const [error, setError] = useState();
    const [errorFields, setErrorFields] = useState([]);
    const [success, setSuccess] = useState();
    const [validated, setValidated] = useState(false);   
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState();
    const [fields, setFields] = useState({});

    const lg_size = props?.forceMobile ? 12 : 4;

    const onboardingFormat = props?.onboarding || false;

    useEffect(() => {
        if (success && callback) callback(fields);
    }, [success, callback, fields]);

    useEffect(() => {
        return () => {
            setError(null);
            setValidated(false);
            setSubmitting(false);
            setEmail(null);
            setSuccess(null);
            setErrorFields([]);
            setFields({});
        }
    }, []);

    const checkUsername=async(userName)=>{
        try{
            let response = await APIUsers.checkUsername({username: userName});
            if(response.data[0].available === true) return true;
            else if (response.data[0].available === false){
                return false;
            } 
        }catch(ex){
            console.error(ex);
        }
    }

    const checkEmail=async(email)=>{
        try{
            let response = await APIUsers.checkEmail({email: email});
            if (response.data[0].available === true) return true;
            else if (response.data[0].available === false){
                return false;
            } 
        }catch(ex){
            console.error(ex);
        }
    }

    const externalClickHandler = (e) => {
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSubmitting(true);
        setErrorFields([]);

        let _err=[];
        let _onboarding_err=[];
        const form = e.currentTarget;

        if (form.username.value){
            const usernameTestRegex=/[\100\72-\77\40-\54\57\133-\136\140\173-\176]/.test(form.username.value); 
            if (usernameTestRegex) {
                _err.push({field:"username", message: "Username must not contain special characters"})
            } else {
                const userNameUnique = await checkUsername(form.username.value);
                if (!userNameUnique){
                    if (onboardingFormat) _onboarding_err.push({field:"username", message: "That username was already taken"});
                    else _err.push({field:"username", message: "That username was already taken. Please try another"});
                } 
            }
        }
        
        if (form.email.value){
            const emailTestRegex=/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(form.email.value);
            if (!emailTestRegex) {
                _err.push({field:"email", message: "Email must be an valid email address"});
            } else {
                const emailUnique = await checkEmail(form.email.value);
                if (!emailUnique) {
                    if (onboardingFormat) _onboarding_err.push({field:"email", message: "An account already exists with that email"});
                    else _err.push({field:"email", message: "An account already exists with that email"});
                }
            }
        }

        const passwordTestRegex=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        if (!passwordTestRegex.test(form.password.value)) {
            _err.push({field:"password", message: "Password must be at least 8 characters, contain at least one number, one uppercase letter, one lowercase letter, and one special character"});
        } else {
            if (!onboardingFormat){
                if (form.password.value && form.password_confirmation.value && form.password.value.trim()!==form.password_confirmation.value.trim()){
                    _err.push({field:"password_confirmation", message: "Passwords do not match"});
                }
            }
        }

        if (!form?.dob?.value && !onboardingFormat) _err.push({field:"dob", message: "Please enter your date of birth"});

        setErrorFields(_err);
        if (_err.length) {
            setValidated(true);
            setSubmitting(false);
            return;
        }

        if (_onboarding_err.length > 0) {
            callback({errors: _onboarding_err});
            return;
        }

        if (form.checkValidity() === true) {

            setSubmitting(true);
            setValidated(false);

            //const formData = new FormData(e.target); // dont know why this doesnt work
            let formData = new FormData();
            formData.append("first_name", form.first_name.value);
            formData.append("last_name", form.last_name.value);
            formData.append("middle_name", form?.middle_name?.value || "");
            formData.append("email", form.email.value);
            formData.append("mobile_phone", form.mobile_phone.value);
            formData.append("username", form.username.value);
            formData.append("password", form.password.value);
            formData.append("password_confirmation", onboardingFormat ? form.password.value : form.password_confirmation.value);

            if (form?.dob?.value){
                formData.append("dob", format(new Date(form.dob.value) || toDate(new Date()), "yyyy-MM-dd"));
            }
            const formDataObj = Object.fromEntries(formData.entries());

            try{
                const res = await APIUsers.create(formDataObj);
                if (!res.errors) { // successful registration
                    setFields({...formDataObj, id: res.data.id});
                    setValidated(false);
                    setSuccess(true);
                } else {
                    setSuccess(false);
                    setValidated(true);
                    if (typeof res.errors === "string") setError(res.errors);
                    else {
                        let _err = <></>;
                        Object.keys(res.errors?.validation).forEach((key) => {
                            _err = <>{_err}{res.errors[key]?.[0]}<br/></>;
                        });
                        setError(_err);
                    }
                    console.log(res.errors);
                    //setError(res.errors);
                }
            } catch(ex){
                console.error(ex);
            }
        }
        setSubmitting(false);
    }

    if (user?.profile?.id && !props.is_preview && !onboardingFormat){
        return null;
    }

    return (
        <>
            {success &&
                <>
                    <h1>{props.success_title || "Thank you!"}</h1>
                    <p>
                        {props.success_text || `You've successfully signed up with ${company_context.companyName}. You should receive a welcome email${email ? " at " + email + " " : " "}shortly.`}
                    </p>
                </>
            }
            {!success &&
                <Form
                    ref={ref}
                    onSubmit={submitHandler} 
                    className={`signup_${props.page_id} ${props.className || ""}`}
                    style={props.style} 
                    onClick={preview_click}
                    noValidate 
                    validated={validated}>
                    <Row>
                        <Col sm={12} lg={props?.forceMobile && onboardingFormat?6:lg_size}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="first_name" 
                                type="text" 
                                label="First Name" 
                                required 
                                feedback={errorFields.filter(a=>a.field==="first_name")?.[0]?.message || "Please enter your first name"} 
                                isInvalid={errorFields.filter(a=>a.field==="first_name").length>0} 
                                disabled={submitting} />
                        </Col>
                        {!onboardingFormat &&
                            <Col sm={12} lg={lg_size}>
                                <FormGroupText 
                                    page_id={props.page_id} 
                                    name="middle_name" 
                                    type="text" 
                                    label="Middle Name" 
                                    feedback={errorFields.filter(a=>a.field==="middle_name")?.[0]?.message || "Please enter your middle name"} 
                                    isInvalid={errorFields.filter(a=>a.field==="middle_name").length>0} 
                                    disabled={submitting} />
                            </Col>
                        }
                        <Col sm={12} lg={props?.forceMobile && onboardingFormat?6:lg_size}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="last_name" 
                                type="text" 
                                label="Last Name" 
                                required
                                feedback={errorFields.filter(a=>a.field==="last_name")?.[0]?.message || "Please enter your last name"} 
                                isInvalid={errorFields.filter(a=>a.field==="last_name").length>0} 
                                disabled={submitting} />
                        </Col>
                        <Col sm={12} lg={lg_size}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="email" 
                                type="email" 
                                label="E-mail" 
                                required 
                                feedback={errorFields.filter(a=>a.field==="email")?.[0]?.message || "Please enter your email address"} 
                                isInvalid={errorFields.filter(a=>a.field==="email").length>0}
                                disabled={submitting} 
                                onChange={(e)=>setEmail(e.target.value)} />
                        </Col>
                        <Col sm={12} lg={props?.forceMobile?6:4}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="mobile_phone" 
                                type="phone" 
                                label="Mobile Phone" 
                                placeholder="(xxx) xxx-xxxx" 
                                feedback={errorFields.filter(a=>a.field==="mobile_phone")?.[0]?.message || "Please enter your mobile phone number"}
                                isInvalid={errorFields.filter(a=>a.field==="mobile_phone").length>0}
                                disabled={submitting} />
                        </Col>
                        {!onboardingFormat &&
                            <Col sm={12} lg={props?.forceMobile?6:4}>
                                <FormGroupDatePicker 
                                    page_id={props.page_id} 
                                    name="dob" 
                                    type="text" 
                                    label="Date of Birth" 
                                    placeholder="mm/dd/yyyy" 
                                    maxDate={toDate(new Date())}
                                    required 
                                    feedback={errorFields.filter(a=>a.field==="dob")?.[0]?.message || "Please enter your date of birth"}
                                    isInvalid={errorFields.filter(a=>a.field==="dob").length>0}
                                    disabled={submitting} />
                            </Col>
                        }
                    </Row>
                    <Row style={{marginBottom:"5rem"}}>
                        <Col sm={12} lg={onboardingFormat? 6 : lg_size}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="username" 
                                type="text" 
                                label="User" 
                                required 
                                maxLength={32}
                                feedback={errorFields.filter(a=>a.field==="username")?.[0]?.message || "Please enter your user name"}
                                isInvalid={errorFields.filter(a=>a.field==="username").length>0}
                                disabled={submitting} />
                        </Col>
                        <Col sm={12} lg={props?.forceMobile?6:4}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="password" 
                                type="password" 
                                label="Password"
                                required 
                                feedback={errorFields.filter(a=>a.field==="password")?.[0]?.message || "Please enter your password"}
                                isInvalid={errorFields.filter(a=>a.field==="password").length>0}
                                disabled={submitting} />
                        </Col>
                        {!onboardingFormat &&
                            <Col sm={12} lg={props?.forceMobile?6:4}>
                                <FormGroupText 
                                    page_id={props.page_id} 
                                    name="password_confirmation" 
                                    type="password" 
                                    label="Confirm Password" 
                                    required 
                                    feedback={errorFields.filter(a=>a.field==="password_confirmation")?.[0]?.message || "Please enter your password again"}
                                    isInvalid={errorFields.filter(a=>a.field==="password_confirmation").length>0}
                                    disabled={submitting} />
                            </Col>
                        }
                        <Col sm={12}>
                            <FormGroupButton page_id={props.page_id} label="Sign Up" disabled={submitting} click={props.is_preview?"Button":"Submit Form"} />
                        </Col>
                        {error && 
                            <Col sm={12} className="error-text-ctr">
                                {error}
                            </Col>
                        }
                        {/*
                        <Col sm={12} lg={12} className="d-flex flex-column align-items-center my-5">
                            <Row>
                                <Col sm={12}>
                                    <Form.Label>Or sign up with</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroupButton page_id={props.page_id} label={<><i className="fab fa-google me-2" /><span>Google</span></>} style={{backgroundColor:"#DB4437",color:"#fff",width:"150px"}} disabled={props.submitting} click={()=>externalClickHandler(1)} />
                                    <FormGroupButton page_id={props.page_id} label={<><i className="fab fa-facebook me-2" /><span>Facebook</span></>} style={{backgroundColor:"#3B5998",color:"#fff",width:"150px"}} disabled={props.submitting} click={()=>externalClickHandler(2)} />
                                    <FormGroupButton page_id={props.page_id} label={<><i className="fab fa-linkedin me-2" /><span>LinkedIn</span></>} style={{backgroundColor:"#0077B5",color:"#fff",width:"150px"}} disabled={props.submitting} click={()=>externalClickHandler(3)} />
                                </Col>
                            </Row>
                        </Col>
                    */}
                    </Row>
                </Form>
            }
        </>
    );
});

export default SignUp;
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    current_booking: {
        small_screen: false,
        small_screen_num_days: 1,
        selected_slots: []
    },
    errors: {},
    search_params: {}, // params need to be separate from results to prevent loops
    search_results: {
        services: [],
    },
};

const serviceBookingReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SERVICEBOOKING_SET_CURRENT_BOOKING:
            return {
                ...state,
                current_booking: { ...state.current_booking,  ...action.booking}
            }
        case actionTypes.SERVICEBOOKING_RESET_CURRENT_BOOKING:
            return initialState;
        case actionTypes.SERVICEBOOKING_SET_ERRORS:
            return {
                ...state,
                errors: {...state.errors, ...action.errors}
            }
        case actionTypes.SERVICEBOOKING_RESET_ERRORS:
            return {
                ...state,
                errors: {}
            }
        case actionTypes.SERVICEBOOKING_SET_SEARCH_PARAMS:
            return {
                ...state,
                search_params: {...state.search_params, ...action.search_params}
            }
        case actionTypes.SERVICEBOOKING_SET_SEARCH_RESULTS:
            return {
                ...state,
                search_results: {...state.search_results, ...action.search_results}
            }
        default:
            return state;
    }
};

export default serviceBookingReducer;
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { format, formatISO, isSameDay, parseISO } from 'date-fns';
import Container from 'react-bootstrap/Container';

import { authUserHasModuleAccessMany } from "../../../../utils/auth";
import { getAdminFamilyMembers } from './GetFamily';
import Spinner from '../../../../components/Spinner';
import EventApi from '../../../../api/Events';
import User from '../../../../api/Users';
import Groups from '../../../../api/Groups';
import EventDisplay from './EventDisplay';

import '../../Subscriptions/Subscriptions.css';
import styles from './Events.module.scss';

const VIEW_MODULE_ID = 77;
//edit event can ovverride individual family permission - anyone who has the ability to edit events would be able to see the whole attendee list for said events through the event edit permissions
const EDIT_EVENT_MODULE_ID = 118; 

export const Events = (props) => {

    const mountedRef = useRef(true);
    const authUser = useSelector(state => state.auth.user);
    const [loading,setLoading]=useState(true);
    const [events,setEvents]=useState([]);
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);
    const [eventsByDay, setEventsByDay]=useState([]);
    const [selfOrFamily, setSelfOrFamily]=useState("self");
    const [showFamilySelect, setShowFamilySelect]=useState(false);
    const [allowedFamily, setAllowedFamily]=useState([])

    const sortEventsByDay = useCallback((events) =>{
        let sortedEvents = events.sort((a,b)=>{
            if(a.start_datetime > b.start_datetime) return 1;
            if(a.start_datetime < b.start_datetime) return -1;
            else return 0;
        });
        const eventsByDay = [];
        let i = 0
        let k = 1;
        let j = 0;
        for(i; j < sortedEvents.length;){
            j = i+1;
            let tempEvents = []
            eventsByDay.push({date: format(new Date(events[i].start_datetime), "eee dd"),events: [sortedEvents[i]], tempId: k})
            for(j; j <= sortedEvents.length;){
                if(j === sortedEvents.length || (j < sortedEvents.length && !sameDayCheck(sortedEvents[i], sortedEvents[j]))){
                    //the temp id does need to change as the loop progresses
                    // eslint-disable-next-line no-loop-func
                    let match = eventsByDay.filter((day)=>day.tempId === k)
                    match[0].events = [...match[0].events, ...tempEvents]
                    i = j;
                    break;
                }
                else if(sameDayCheck(sortedEvents[i], sortedEvents[j])){
                    tempEvents.push(sortedEvents[j]);
                    j++;
                }
            }
            k++
        }
        setEventsByDay(eventsByDay);
        setLoading(false);
    },[]);

    const checkPermission = useCallback(async () => {
        try {
            let response = await authUserHasModuleAccessMany([EDIT_EVENT_MODULE_ID, VIEW_MODULE_ID], props.user_id);
            setUserHasModulePermission(response);
        } catch (error) { console.error(error) }
    },[props.user_id]);

    const getAllowedFamily=useCallback(async()=>{
        let allowedFamily;
        if(props?.user?.family_groups.length > 0){
            //if the auth user has the ability to edit events where they could see this anyway
            if(userHasModulePermission[EDIT_EVENT_MODULE_ID]) {
                setShowFamilySelect(true);
                allowedFamily = await getAdminFamilyMembers(props.user_id, props.user, true);
            }
            else {
                setShowFamilySelect(true);
                allowedFamily = await getAdminFamilyMembers(props.user_id, props.user)
            }
            setAllowedFamily(allowedFamily);
        }else {
            setShowFamilySelect(false);
            setAllowedFamily(props.user_id)
        }
    },[userHasModulePermission, props.user_id, props.user])

    const getEvents = useCallback(async (ids)=>{
        try{
            let response = await EventApi.getForUser({
                user_id: ids,
                event_status_id: [2], // confirmed events only
            })
            if(response.status === 200 && mountedRef.current){
                sortEventsByDay(response.data.events)
            }
        }catch(ex){
            console.error(ex)
        }
    },[sortEventsByDay])

    useEffect(() => {
        mountedRef.current = true;
        setLoading(true);
        
        checkPermission();
        getEvents(props.user_id);

        // cancel stuff when component unmounts
        return () => {
            mountedRef.current = false;
            setLoading(false);
            setEvents([]);
        }        
    },[props.user_id, checkPermission, getEvents]);

    useEffect(()=>{
        if(userHasModulePermission !== null && mountedRef.current){
            getAllowedFamily();
        }
    },[userHasModulePermission,getAllowedFamily])

    const sameDayCheck = (eventA, eventB) =>{
        if(isSameDay(parseISO(eventA.start_datetime), parseISO(eventB.start_datetime))){
            return true;
        }else return false;
    }

    const handleSelfFamilyChange=(e)=>{
        setSelfOrFamily(e.target.value);
        setLoading(true);
        if(e.target.value === "self"){
            getEvents(props.user_id);
        }else{
            getEvents(allowedFamily.map((family)=>family.user_id));
        }
    }

    if(loading){
        return(
            <SkeletonTheme color="#e0e0e0">
                <div className="mt-3" data-cy="loading-skeleton-div">
                    <Skeleton height={28} width={200}/>
                    <Skeleton height={16} count={4} />
                </div>
            </SkeletonTheme>
        )
    }
        
    return (
        <Container fluid>
            <h4 className="section-title">Event Schedule</h4>
            <hr/>
            {userHasModulePermission===null ?
                <><Spinner /></>
            :
            !userHasModulePermission[VIEW_MODULE_ID] ?
                <p>You do not have permission to view this user's events.</p>
            :
                <div className={styles["profile-event-wrapper"]}>
                    {showFamilySelect && allowedFamily?.length > 0 &&
                        <div>
                            <label>
                                Show events for:
                            </label>
                            <select onChange={(e)=>handleSelfFamilyChange(e)}>
                                <option value="self" selected={selfOrFamily === "self" ? true: false}>
                                    {authUser.profile.id === props.user_id ?
                                        "Self Only"
                                    :
                                        `Only ${props.user.first_name} ${props.user.last_name}`
                                    }
                                </option>
                                <option value="family" selected={selfOrFamily==="family" ? true: false}>Family</option>
                            </select>
                        </div>
                    }
                    <div>
                        {eventsByDay?.length===0 &&
                            <p className="text-center">No scheduled events</p>
                        }
                        {eventsByDay?.length > 0 && 
                            <>
                                {eventsByDay?.map((day)=>(
                                    <EventDisplay 
                                        day={day} 
                                        family={allowedFamily}
                                        profileUser={props.user}
                                        authUserId = {authUser.profile.id}
                                        canEditEvent={userHasModulePermission[EDIT_EVENT_MODULE_ID]}
                                    />
                                ))} 
                            </>
                        }
                    </div>
                </div>
            }
        </Container>
    );
}
import Request from './Api';

const get = async(props) => {
    // gets mock data for testing - will be removed when api is ready
    //let tasks = await test();

    return (
        Request({
            url: "/notification/list",
            data: {
                user_id: props?.user_id,
            },
            //test: {data:tasks} // send mock data to simulate an api call
        })
    );
}

const Channel = {
	getByUser: async props=>{
		return(
			Request({
				url: "/user/notification/channel/" + (props?.id),
				data: props,
				method: "GET"
			})
		)
	},
	delete: async props=>{
		return(
			Request({
				url: "/user/notification/channel/delete",
				data: props,
				method: "DELETE"
			})
		)
	},
	update: async props=>{
		return(
			Request({
				url: "/user/notification/channel/update",
				data: props,
				method: "PUT"
			})
		)
	},
	add: async props=>{
		return(
			Request({
				url: "/user/notification/channel/add",
				data: props,
				method: "POST"
			})
		)
	}
}


// sets up mock data
/*const test = async() => {
	
	const getRandomDay = () => {
		return Math.floor(Math.random() * (27 - 1 + 1)) + 1;
	}

    return new Promise((resolve, reject) => {
		const current_month=new Date().getMonth()+1;
		const current_year=new Date().getFullYear();		
		const notification=[
			{
				id: 1,
				date:`${current_year}/${("0"+current_month).slice(-2)}/${("0"+getRandomDay()).slice(-2)}`,
				title:"Have you signed up for HS Basketball Tournament yet? Deadline approaching.",
				description:"I'm baby readymade occupy af tousled, pitchfork ugh lyft food truck poke asymmetrical."
			},
			{
				id: 121,
				date:`${current_year}/${("0"+current_month).slice(-2)}/${("0"+getRandomDay()).slice(-2)}`,
				title:"Project Updates.",
				description:"Next level cliche taxidermy, air plant everyday carry hot chicken palo santo yuccie normcore knausgaard sustainable four dollar toast organic kogi drinking vinegar."
			},
			{
				id: 11,
				date:`${current_year}/${("0"+current_month).slice(-2)}/${("0"+getRandomDay()).slice(-2)}`,
				title:"Johnny has invited you to a group.",
				description:"Next level cliche taxidermy, air plant everyday carry hot chicken palo santo yuccie normcore knausgaard sustainable four dollar toast organic kogi drinking vinegar."
			},
			{
				id: 55,
				date:`${current_year}/${("0"+current_month).slice(-2)}/${("0"+getRandomDay()).slice(-2)}`,
				title:"Password reset successfully",
				description:"Paleo godard marfa mixtape butcher venmo ethical slow-carb sustainable taiyaki truffaut jean shorts single-origin coffee fanny pack four dollar toast."
			},
		];

        resolve(notification);
    });
}*/

const Notifications = {
	get, Channel //, create, update, delete, etc. ...
}
  
export default Notifications;
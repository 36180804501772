import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { selectNumberOfItemsInCart } from '../../../store/selectors';
import { Link, useHistory } from 'react-router-dom';

import Pos from '../../../api/Pos';
import Users from '../../../api/Users';
import Preview from '../../../containers/POS/Preview';
import Payment from '../../../containers/POS/Payment';
import { setItems } from '../../../containers/POS/Items/Utils';
import { cancelBooking } from '../../../utils/thunks';

import './ShoppingCart.scss';

import * as actions from '../../../store/actions';
import CCPayment from '../../../containers/POS/Payment/CCPayment';
import { SuccessfulOrder } from './SubComponents/SuccessfulOrder';
import Order from '../../../api/Order';

let collapsed_class = {
    full: "collapsed_full",
    small: "collapsed_small"
}

// this is used both for the display of the patron cart in the top navbar, and for various patron carts
const ShoppingCart = ({ register_id, checkout_url, side_display=false, is_patron_register=false, onClick=()=>{}, is_disabled=false, disabled_message=null, order_type="" }) => {

	let dispatch = useDispatch();
	const history = useHistory();

    const mountedRef = useRef();
    const orderRef = useRef(); //need to hold the id outside of Redux for success modal
    const sizeRef = useRef(window.innerWidth)

    const [showPayment, setShowPayment] = useState(false);

    const order = useSelector(state => state.pos[register_id].orderAll);
    const user = useSelector(state => state.auth.user.profile);
    const company=useSelector(state=>state.company);
    const register=useSelector(state=>state.pos);

	const [customer, setCustomer] = useState();
	const [onCart, setOnCart]=useState('');
    const [modalPart, setModalPart]  = useState();
    const [pagePart, setPagePart] = useState();
    const [sidebarOpenFullSize, setSidebarOpenFullSize] = useState(true);
    const [sidebarOpenSmallSize, setSidebarOpenSmallSize] = useState(false);
    const [sidebarIsCollabsible, setSidebarIsCollapsible] = useState(false);
    const [orderSummary, setOrderSummary] = useState();
    const [transactionComplete, setTransactionComplete]=useState(false);

    const items = useSelector(state => state.pos[register_id].items);
    const currentBooking = useSelector(state => state.serviceBooking.current_booking);
	const showAnimation = useSelector(state => state.pos[register_id].cart_animation);
    const numCartItems = useSelector(state => selectNumberOfItemsInCart(state, register_id));

	//const GUEST_USER_ID = 7;
    const GUEST_USER_ID = company?.config?.guest_user_id || null;


    const paymentModalHandler = (bool) => {
		setShowPayment(bool);
        if(transactionComplete) setTransactionComplete(false);
	};

	useEffect(() => {
		mountedRef.current = true;

        return () => {
            mountedRef.current = false;
            setTransactionComplete(false);
        }
	},[]);

	useEffect(() => {
        let interval;
		// trigger countdown effect here?
        if (currentBooking?.event_id && currentBooking?.service?.default_product_variant_id) {
            let tokenProducts = items.filter(item => item.variant_id === currentBooking.service.default_product_variant_id);

            interval = setInterval(() => {
                tokenProducts?.forEach(item => {
                    if (new Date() >= new Date(item.valid_until)) {
                        dispatch(cancelBooking(register_id));
                        clearInterval(interval);
                        alert('The Service Booking in your cart has expired. Please reserve a new time slot.', {
                            title: "Cart Item Expired",
                            okText: "OK",
                        });
                    }
                });
            }, 1000);
        }
        return () => {
            if (interval) clearInterval(interval);
        }
	},[items, currentBooking, dispatch, register_id]);


    useEffect(()=>{

        const handleResize=()=>{
            //if the size of the window has changed, on collapse
            if(sizeRef.current !== window.innerWidth) {
                setSidebarOpenSmallSize(false);
                sizeRef.current = window.innerWidth;
            }
        }

        window.addEventListener('resize', handleResize);

        return()=>{
            window.removeEventListener('resize', handleResize);
        }

    },[])


    // collapsable Preview pane
    const openSidebarFullSize = useCallback((open) => {
        if (open===false && sidebarOpenSmallSize) {
            setSidebarOpenSmallSize(false);
        }
        setSidebarOpenFullSize(open);
    },[sidebarOpenSmallSize]);

    const openSidebarSmallSize = useCallback((open) => {
        setSidebarOpenSmallSize(open);
    },[]);




    const addCollapsedClass = useCallback((size, component) => {
        if (!component.classList.contains(collapsed_class[size])) {
            component.classList.add(collapsed_class[size]);
        }
    },[]);

    const removeCollapsedClass = useCallback((size, component) => {
        if (component.classList.contains(collapsed_class[size])) {
            component.classList.remove(collapsed_class[size]);
        }
    },[]);

    const orderSummaryPagePart = useMemo(() => (
            <>
                {order &&
                    <>
                        <div>{numCartItems === 1 ? "Item" : "Items"}: {numCartItems}</div>
                        <div>Total: ${order.total_price}</div>
                    </>
                }
            </>
    ),[order, numCartItems]);

    useEffect(() => {
        let preview = document.getElementsByClassName("pc-preview-container");
        let outerPanel = null;
        if(preview && preview?.length > 0) {
            preview?.forEach(previewPanel => {
                if (previewPanel.classList.contains("collapsable-sidebar")) {
                    outerPanel = previewPanel;
                    setSidebarIsCollapsible(true);
                }
            });
        }
        if (outerPanel) {
            if (sidebarOpenFullSize) {
                removeCollapsedClass("full", outerPanel);
            } else {
                addCollapsedClass("full", outerPanel);
            }
            if (sidebarOpenSmallSize) {
                removeCollapsedClass("small", outerPanel);
            } else {
                addCollapsedClass("small", outerPanel);
            }
        }
    },[sidebarOpenFullSize, sidebarOpenSmallSize, addCollapsedClass, removeCollapsedClass]);

	useEffect(()=>{
		checkLocation();
	})

	//For checking what path is current so as to disable the cart while being in checkout (so as to not modify the order)
	const checkLocation=()=>{
		let current = history.location.pathname;
		if(current === checkout_url) setOnCart(true);
		else setOnCart(false);
	}

    const onClickCheckout = useCallback(() => {
        document.body.click();  // causes the cart popover to close
        if(history.location.pathname.includes("/online")) paymentModalHandler(true)
        else history.push(checkout_url);
    },[history, checkout_url]);

    useEffect(() => {
        if (numCartItems>0) {
            setPagePart(
                <div className="shopping-cart-whole">
                    <div className="preview-columns no-select-text">
                        <div className="hidden-collapse-buttons">
                            {/* <Button variant="light" className="collapse-arrow-button full_size" onClick={() => openSidebarFullSize(!sidebarOpenFullSize)}><i className={`far fa-angle-${sidebarOpenFullSize ? 'right' : 'left'}`}></i></Button> */}
                            <Button variant="light" className="collapse-arrow-button small_size" onClick={() => openSidebarSmallSize(!sidebarOpenSmallSize)}><i className={`far fa-angle-${sidebarOpenSmallSize ? 'right' : 'left'}`}></i></Button>
                        </div>
                        <div id="patron-cart-collapsible-preview" className={`${sidebarOpenSmallSize ? "hidden" : ""}`}>
                            <Preview key="tab-preview" cart_layout={true} register_id={register_id} is_patron_register={is_patron_register} show_checkout_btn={false} />
                        </div>
                    </div>
                    <div className={`order-summary-hidden ${!sidebarOpenFullSize && 'show_full'} ${!sidebarOpenSmallSize && 'show_small'}`}>
                        {orderSummaryPagePart}
                    </div>
                    <Button onClick={onClickCheckout} className="sc-checkout-btn">Checkout</Button>
                </div>
            );
        } else {
            setPagePart(
                <div className="text-center mt-5 empty-cart">
                    <h4 className="empty-cart-icon"><i className="far fa-shopping-cart" data-cy="cart-header-btn" /></h4>
                    <div data-cy="empty-cart-text">Your cart is empty.</div>
                </div>
            );
        }
    },[openSidebarFullSize,openSidebarSmallSize,numCartItems, register_id, onClickCheckout, sidebarOpenFullSize, sidebarOpenSmallSize, is_patron_register, orderSummaryPagePart]);

    const handlePaymentFollowUp = (response)=>{
        if(response?.data[0].transaction_status_id === 7 ){
            orderRef.current=order.id
            setTransactionComplete(true);
            if(order_type === "pickup") handleOnlineOrderStatus(response?.data[0]?.order_id);
            dispatch(actions.reset(register_id));
        }
    }

    const handleOnlineOrderStatus = async (orderId) => {
        try {
            let response = await Order.Statuses.logStatus({order_id: orderId, order_status_id: 8});
            if(response.status !== 200){
                console.error(response.data);
            }
        }catch(ex){
            console.error(ex);
        }
    }

    if (is_disabled) return null;

    return (
        <React.Fragment>

            {!side_display &&
                <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    rootClose
                    overlay={
                        <Popover className="cart-popover">
                            <Popover.Content>
                                <Container fluid>
                                    { pagePart }
                                </Container>
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <div className="cart-btn" onClick={onClick}>
                        <Col md="auto">
                            <div data-cy="header-shopping-cart">
                                <i className={"far fa-shopping-cart cart"} data-cy="cart-header-btn" data-tip="Cart">
                                    { numCartItems>0 ? <div className="cart-num-items" animated={ showAnimation ? "1" : "0" } onAnimationEnd={ () => dispatch(actions.endCartAnimation(register_id))} >{ numCartItems }</div>
                                    : null }
                                </i>
                            </div>
                        </Col>
                    </div>
                </OverlayTrigger>
            }

            {side_display &&
                <>{pagePart}</>
            }

			<Modal show={showPayment} backdrop="static" onHide={()=>paymentModalHandler(false)} size="lg" dialogClassName="payment">
                <Modal.Header closeButton />
                <Modal.Body>
                    {/* <Payment register_id={register_id} key="tab-checkout" onClose={()=>paymentModalHandler(false)} patron={true} user_id={null} /> */}
                    {transactionComplete ? 
                        <>
                            {order_type === "pickup" &&
                                <SuccessfulOrder orderType="pickup " orderNumber={orderRef.current || null} />
                            }
                        </>
                    :
                        <CCPayment 
                            setTransSuccess={setTransactionComplete}
                            order={order}
                            passUser={(e)=>console.log(e)}
                            register_id={register_id} 
                            patron_layout={is_patron_register}
                            user={user}
                            setProcessing={(e)=>console.log(e)}
                            disabled={false}
                            bypassCC={()=>{console.log("Bypassing NMI for testing or development")}}
                            memo={window.location.origin?.includes("localhost") ? "BYPASS" : ""}
                            paymentFollowUp={handlePaymentFollowUp}
                        />
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}

export default ShoppingCart;
import React, {useState, useEffect, useRef, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import * as actions from '../../../../../../store/actions'
import AssignPaymentAmounts from '../../MultiUserSingleItem/AssignPaymentAmounts';
import OrderSummary from '../../OrderSummary';
import Pos from '../../../../../../api/Pos';

export const OrderDivision = (props) => {

    const { cancelSplit, setAlreadySplit, register, setSplitType } = props;

    const dispatch=useDispatch();
    const mountedRef = useRef(false);

    const currentOrder=useSelector(state=>state.pos[props.register].orderAll);
    const orderItems=useSelector(state=>state.pos[props.register].orderAll.items);
    const payees=useSelector(state=>state.splitPayments.payees);
    const spErrors=useSelector(state=>state.splitPayments.errors);
    const allPaid=useSelector(state=>state.splitPayments.allPaid);
    const allOrders=useSelector(state=>state.splitPayments.allOrders);

    const [divisionType, setDivisionType]=useState(null);
    const [success, setSuccess]=useState("");
    const [firstLoad, setFirstLoad]=useState(true);
    const [fieldSetDisable, setFieldSetDisable]=useState(false);
    //saved items are only set on first load if the order has items.  Redux items are for retrieving what was saved in order to be reapplied to the order and modified in going back
    const [savedItems, setSavedItems]=useState([]);
    const reduxItems=useSelector(state=>state.splitPayments.originalItems)

//#region useCallback
    const checkAllAssigned=useCallback(()=>{
        let itemAmount=reduxItems.length;
        let i = 0;
        let allAssigned = "No";
        payees.forEach((payee)=>{
            payee.paid = false;
            if(!payee.items) allAssigned = "Partial";
            if(payee.items) i+=payee.items.length;
        })
        if(allAssigned !== "Partial" && itemAmount===i) allAssigned = "Yes";
        dispatch(actions.setSplitPaymentAllItemsAssigned(allAssigned))
    },[reduxItems, payees, dispatch]);

    const checkForSplits=useCallback(async()=>{
        //if there are split ids, they are equal to the payees, and all orders are not set
        let tempOrders=[];
        let i=1;
        if(currentOrder.split_order_ids.length >0 && firstLoad && currentOrder.split_order_ids.length === payees.lengths && allOrders.length===0){
            setAlreadySplit(true);
            currentOrder.split_order_ids.forEach(async(id)=>{
                try{
                    let response = await Pos.order.get({id: id})
                    if(!response.errors){
                        let paid=false;
                        if(response.data.order_status_id !== null && response.data.order_status_id !== 1) paid=true
                        let temp = {payeeOrder:i, identifier: response.data.memo, amount: response.data.subtotal_price + response.data.tax_total, paid: paid, id: response.data.user_id, order: response.data}
                        if(response?.data?.items?.length >0) temp.items = response.data.items
                        tempOrders.push(temp)
                    }
                }catch(ex){console.error(ex)}
                finally{
                    checkAllAssigned();
                    if(tempOrders.length === payees.length && mountedRef.current){
                        dispatch(actions.setSplitPaymentPayees(tempOrders))
                    }
                }
            })
        }
        setFirstLoad(false);
    }, [currentOrder, firstLoad, allOrders, payees, dispatch, checkAllAssigned, setAlreadySplit]);


//#endregion useCallback

//#region useState
    //first load
    useEffect(()=>{
        mountedRef.current = true

        //preventing overlap of validation between components
        dispatch(actions.setSplitPaymentAllItemsAssigned("No"));

        //save a copy of the items from the order before it's modified with the split to reapply them if the split is cancelled or modified.
        
        return()=> mountedRef.current = false
    },[dispatch]);
    
    useEffect(()=>{
        if(firstLoad && currentOrder.items.length > 0 && mountedRef.current){
            setSavedItems(orderItems);
            dispatch(actions.setSplitPaymentOriginalItems(orderItems))
        } 
    },[currentOrder, firstLoad, orderItems, dispatch]);

    useEffect(()=>{
        checkForSplits();
        checkAllAssigned();
    },[checkForSplits, checkAllAssigned]);

    useEffect(()=>{
        if(divisionType) setSplitType(divisionType)
        if(divisionType==="byItems") dispatch(actions.setSplitPaymentRemaining(0));
    },[divisionType, dispatch, setSplitType]);

    useEffect(()=>{
        if((allPaid === "Partial" || allPaid === "Yes") && mountedRef.current) setFieldSetDisable(true);
    },[allPaid, setFieldSetDisable]);
//#endregion useState

//#region Utility
    const handleToggleChange=(value)=>{
        setSuccess("");
        let tempPayees=[];
        let abortChange = false;
        //cancel if there's a split if there is one because there's a change
        if(currentOrder?.split_order_ids?.length > 0) props.cancelSplit(null, false, false);
        dispatch(actions.setSplitPaymentAllOrders([]));
        //if the user already has an item, don't add it
        abortChange = value.payee?.items?.some((item)=>item.id===value.item.id);
        if(!abortChange){
            if(value.payee.items?.id !== value.item.id){
                payees.forEach((payee)=>{
                    if(payee.payeeOrder === value.payee.payeeOrder){
                        if(!payee.items) payee.items = [value.item];
                        else payee.items.push(value.item);
                        tempPayees.push(payee);
                    }
                    else tempPayees.push(payee);
                });    
            };
        }
        removeItemFromOthers(value, tempPayees);
    };

    const removeItemFromOthers=(value, tempPayees)=>{
        tempPayees.forEach(payee=>{
            if(payee.payeeOrder !== value.payee.payeeOrder){
                if(payee?.items){
                    for(let i = 0; i < payee.items.length; i++){
                        if(payee.items[i].id === value.item.id){
                            payee.items.splice(i, 1);
                        };
                    };
                };
            };
        });
    };

    const saveItems=()=>{
        checkAllAssigned();
        dispatch(actions.setSplitPaymentPayees(payees));
        setSuccess("Items saved successfully")
    };

//#endregion Utility

  return (
    <div className="order-division-div">
        <OrderSummary currentOrder={currentOrder} type={"multiMany"}/>
        <fieldset disabled={fieldSetDisable}>
            <div>
                <p>How do the payees want to divide the order?</p>
                <Button type="button" onClick={()=>setDivisionType("byItems")}>By Items</Button>
                <Button type="button" onClick={()=>setDivisionType("byAmount")}>By Amounts</Button>
            </div>
            <br />
            {divisionType==="byAmount" ? 
                <AssignPaymentAmounts 
                    cancelSplit={props.cancelSplit}
                    setAlreadySplit={props.setAlreadySplit}
                    register={props.register} 
                    type={"multiMany"} 
                />
            :
                null
            }
            {divisionType==="byItems" ? 
                <>
                    {reduxItems?.map((item, i)=>(
                        <fieldset key={`fieldset-${item.id}-${i}`}>
                            <legend>Assign {item.product_name} - {item.final_price.toFixed(2)} </legend>
                            {payees?.map((payee, j)=>{
                                let checked=false;
                                if(payee?.items?.forEach((payeeItem)=>{
                                    if(item.id===payeeItem.id) checked = true
                                }));
                                return(
                                    <>
                                        <input 
                                            type="radio"
                                            name={`group-${item.id}-${i}`} 
                                            key={`tb-payee-${payee.payeeOrder}-${item.id}-${j}`} 
                                            id={`tb-payee-${payee.payeeOrder}-${item.id}-${j}`}  
                                            defaultChecked={checked}
                                            onChange={(e)=>handleToggleChange({payee: payee, item: item})}
                                        />
                                        <label htmlFor={`tb-payee-${payee.payeeOrder}-${item.id}-${j}`} >
                                            {payee.identifier}
                                        </label>
                                    </>
                                )
                            })}
                        </fieldset>
                    ))}
                    <br />
                    <Button type="button" onClick={saveItems}>Save Items</Button>
                    <span className="sp-success">{success}</span>
                    <div className="sp-error"> 
                        <span>{spErrors?.missedItems}</span>
                        <span>{spErrors?.missedPayee}</span>
                    </div>
                    <br />
                </>
            :null
            }
        </fieldset>
    </div>
  )
}

import React from 'react';
import { randomUUID } from '../../../../../utils/cms';

import styles from './Video.module.scss';

const Video = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const width = props?.style?.width || "560px";
    const height = props?.style?.height || "315px";

    if (!props.video_url) return null;

    return (
        <div className={styles.wrapper}>
            <iframe 
                title={randomUUID()}
                ref={ref} 
                src={props.video_url} 
                width={width}
                height={height} 
                border="0" 
                allow="autoplay; encrypted-media; picture-in-picture" 
                allowFullScreen 
                onClick={preview_click}
            />
        </div>
    );    
});

export default Video;
import * as actionTypes from './actionTypes';
//import { subElementLogic, duplicateElementLogic } from '../../utils/cms';

export const CMSDisplayMode=(mode)=>{
    return{
        type: actionTypes.CMS_DISPLAY_MODE,
        mode: mode
    };
};

export const CMSSetCurrentWebsite=(website_id)=>{
    return{
        type: actionTypes.CMS_CURRENT_WEBSITE,
        website_id: website_id
    };
};

export const CMSSetCurrentWebsiteTheme=(theme)=>{
    return{
        type: actionTypes.CMS_CURRENT_WEBSITE_THEME,
        theme: theme
    };
};

export const CMSSetCurrentWebsiteCss=(css)=>{
    return{
        type: actionTypes.CMS_CURRENT_WEBSITE_CSS,
        css: css
    };
};

export const CMSSetCurrentPage=(page_id)=>{
    return{
        type: actionTypes.CMS_CURRENT_PAGE,
        page_id: page_id
    };
};

export const CMSSetCurrentPageProps=(page_props)=>{
    return{
        type: actionTypes.CMS_CURRENT_PAGE_PROPS,
        page_props: page_props
    };
};

export const CMSSetCurrentElement=(element_id)=>{
    return{
        type: actionTypes.CMS_CURRENT_ELEMENT,
        element_id: element_id
    };
};

export const CMSSetCurrentElementIndex=(index)=>{
    return{
        type: actionTypes.CMS_CURRENT_ELEMENT_INDEX,
        index: index
    };
}

export const CMSBuilderItems=(items)=>{
    return{
        type: actionTypes.CMS_BUILDER_ITEMS,
        builderItems: items
    };
};

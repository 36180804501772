import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import ErrorCatcher from '../../../../../../components/common/ErrorCatcher';
import NewUserTypeahead from '../../../../../../components/Typeahead/NewUserTypeahead';
import Pos from '../../../../../../api/Pos';
import * as actions from '../../../../../../store/actions'

export const AssignPayees = (props) => {

    const { setFirstLoad, firstLoad, forUser, setForUser, payee, assignPayeeDetails, type }=props;

    const dispatch = useDispatch();
    const mountedRef = useRef(false);
    const allOrders=useSelector(state=>state.splitPayments.allOrders)
    const [payeeDetails, setPayeeDetails]=useState();
    const [payeeIdentifier, setPayeeIdentifier]=useState("");
    const [typeAheadUser, setTypeAheadUser]=useState();
    const [error, setError]=useState();

//#region useCallback
    const constructPayeeDetails=useCallback(()=>{
        let details;
        if(typeAheadUser && !props.forUser){
            details={
                id: typeAheadUser.id,
                identifier: payeeIdentifier,
                payeeOrder: props.payee.payeeOrder
            }
            if(props.firstLoad) setFirstLoad(false)
            //forUser should only be on multi many and should only be the first time the modal loads, clearing after for no more use
            if(props.forUser) setForUser();
        }
        //This will only every be used the first time multi-many is loaded.  Will always only be the first user.
        else if(props.forUser && props.payee.payeeOrder===1 && props.type==="multiMany"){
            details={
                id: props.forUser.id,
                identifier: props.forUser.first_name + props.forUser.last_name,
                payeeOrder: props.payee.payeeOrder
            }
        }
        else details={
            identifier: payeeIdentifier,
            payeeOrder: props.payee.payeeOrder
        }
        if(mountedRef.current) setPayeeDetails(details);
    }, [typeAheadUser, payeeIdentifier, setForUser, setFirstLoad, props.payee.payeeOrder, props.forUser, props.firstLoad, props.type]);
//#endregion useCallback

//#region useEffect
    //first load
    useEffect(()=>{
        mountedRef.current = true

        if(mountedRef.current) {
            if(props.payee.identifier) setPayeeIdentifier(props.payee.identifier);
            else setPayeeIdentifier(`Guest ${props.payee.payeeOrder}`);
        }

        return()=>{
            mountedRef.current = false
        }
    },[props.payee.identifier, props.payee.payeeOrder]);

    useEffect(()=>{
        if(mountedRef.current && (typeAheadUser || payeeIdentifier)) constructPayeeDetails()
    },[typeAheadUser, payeeIdentifier, constructPayeeDetails]);

    useEffect(()=>{
        if(mountedRef.current && payeeDetails) assignPayeeDetails(payeeDetails);
    },[payeeDetails,assignPayeeDetails]);
//#endregion useEffect


    const passUser=async(selection)=>{
        if(mountedRef.current) {
            if(selection.length>0){
                if(allOrders.length >0) dispatch(actions.setSplitPaymentAllOrders([])) //resetting because there's a change
                setTypeAheadUser(selection[0])
                setPayeeIdentifier(`${selection[0].first_name}  ${selection[0].last_name}`)
                //if the name or user has changed and has an order, it needs to be updated
                if(props.payee.order){
                    let userId;
                    if (props.payee.id===7) userId=null
                    else userId = props.payee.id
                    try{
                        let response = await Pos.order.update({
                            order_id: props.payee.order.id,
                            user_id: userId,
                            memo: `${selection[0].first_name}  ${selection[0].last_name}`
                        })
                        if(response.errors) setError(<ErrorCatcher error={response.errors} />)
                    }catch(ex){
                        console.error(ex);
                        setError(<ErrorCatcher error={"There was a problem changing the user."} />)
                    }
                }
            }
            else{
                setTypeAheadUser();
            }
        }
    };

    const handlePayeeIdentifierInput=(e)=>{
        if(allOrders.length > 0) dispatch(actions.setSplitPaymentAllOrders([])) //resetting because there's a change
        setPayeeIdentifier(e.target.value)
    }

  return (
    <>
    <hr />
    <div className="assign-user-div">
        <div>
            <p>You may change the identifier for {payeeIdentifier} <span className="bold"> - OR -</span> Select a user if they want to be associated with their account.</p>
        </div>
        <div className="assign-inputs">
            <fieldset>
                <legend>Enter Name</legend>
                <input name="identifier" type="text" onChange={handlePayeeIdentifierInput} value={payeeIdentifier}/>
            </fieldset>
            <fieldset className="u-typeahead-wrapper">
                <legend>Search for User</legend>
                <NewUserTypeahead passSelection={passUser} multiple={false} />
            </fieldset>
        </div>
    </div>
    {error}
    </>
  )
}

import React from 'react';
import {format} from 'date-fns';
import { Items, PaymentHistory } from '../Elements';

import styles from '../FullPageReceipt.module.scss';

/**Needs the following props 
 * @param userDetails -user details including f, l, and m name, address1, address2, city, state, zip
 * @param order (needs order id, and price values)
 * @param items (pass in the items seperate, in case they need to be such as a parent/child order)
 * @param transaction
 * @param paymentMethod
 * @param company
 * @param companyLogo
 * @param parentOrder
 * @param receiptMessage (optional) - object with {message: string, location: "top" or "bottom"}
*/
export const Receipt = React.forwardRef((props, ref) => {
    const headers=[`${props.parentOrder ? `Items from Order #${props.parentOrder.id}`: "Items"}`, "Price"];

    let transactions = props?.transaction || props?.order?.transactions || null;
    if (!Array.isArray(transactions)) transactions = [transactions];

    return (
        <div ref={ref}>
            <div className={styles["fp-receipt-wrapper"]}>
                <div className={styles["flex-wrapper-company"]}>
                    <img src={props?.companyLogo} alt="" className={styles["company-logo"]} />
                    <div className={styles["right-address"]}>
                        <h1 className={styles["fp-larger"]}>{props.company?.name}</h1>
                        {props.company?.address_street}
                        <br/>
                        {props.company?.address_street2 &&
                            <>
                                {props.company?.address_street2}
                                <br/>
                            </> 
                        }
                        {props.company?.address_city}, {props.company?.address_state} {props.company?.address_postcode}
                        <br />
                        {props.company?.primary_number}
                        <br />
                        {props.company?.email}
                        <br/><br/>
                        {props.transaction && <h2 className={styles["fp-larger"]}>{props.transaction?.[0]?.payment_method_name}</h2>}
                        <h1 className={styles["fp-larger"]}>Order # {props.order?.id}</h1>
                        {format(new Date(props.order.updated_at), "MM-dd-yyy h:mm aa")}<br/>
                        {props?.receiptMessage?.message && props?.receiptMessage?.location === "top" &&
                            <span>
                                {props?.receiptMessage?.message}
                            </span>
                        }
                        <br />
                        <br />
                        <p> 
                            <span className={styles["bold"]}>Customer:</span>
                            <br/>
                            {
                                `${props.order?.user_fname}
                                ${props.order?.user_lname}
                            `}
                            <br />
                            {props.order?.email}
                        </p>

                        {transactions.map(transaction => (
                            <p key={`transaction-${transaction.id}`}>
                                <span className={styles.bold}>{transaction.transaction_type_name}</span>
                                <br/>
                                <span className={styles.bold}>Transaction # {transaction?.id}</span>
                                <br/>
                                {transaction.transaction_response?.transactionid && 
                                    <>
                                        <span className={styles.bold}>Gateway Transaction # {transaction.transaction_response.transactionid}</span>
                                        <br/>
                                    </>
                                }
                                {format(new Date(transaction.date), "MM-dd-yyy h:ii: a")}
                                <br/><br/>

                                {+transaction.transaction_payment_method_id === 5 &&
                                    <>
                                        <span className={styles.bold}>Manager Discount: ${transaction.amount.toFixed(2)}</span>
                                        <br/>
                                    </>
                                }
                                {+transaction.transaction_payment_method_id !== 5 &&
                                    <>
                                        Paid With: {transaction.transaction_payment_method_name}&nbsp;
                                        {transaction.transaction_response?.check_number && 
                                            <>#{transaction.transaction_response.check_number}</>
                                        }
                                        {transaction.transaction_response?.check_name && 
                                            <>
                                                <br/>
                                                From: {transaction.transaction_response.check_name}
                                            </>
                                        }
                                        {transaction?.transaction_response?.cc_number && `${transaction?.transaction_response?.cc_type} ending in ${transaction.transaction_response.cc_number}`}
                                        <br/><br/>
                                        <span className={styles.bold}>Total Payment: ${transaction.amount.toFixed(2)}</span>
                                        {transaction.transaction_response?.tip > 0 &&
                                            <>
                                                <br/>
                                                <span className={styles.bold}>Tip: ${transaction.transaction_response?.tip || 0.00}</span>
                                            </>
                                        }
                                        <br/>
                                        {transaction?.change > 0 && 
                                            <>
                                                <span className={styles.bold}>Cash Tendered: ${transaction.transaction_response.amount.toFixed(2)}</span>
                                                <br/>
                                                <span className={styles.bold}>Change: ${transaction.transaction_response.change.toFixed(2)}</span>
                                            </>
                                        }
                                    </>
                                }
                                <br/>
                                <h4 className={styles["fp-larger"]}>{transaction.transaction_status_name}</h4>
                            </p>                    
                        ))}
                    </div>
                </div>

                <Items {...props} headers={headers} format={"ticket"}/>

                <div className={`${styles["flex-wrapper-totals"]} ${styles["totals"]}`}>
                    {props.order && 
                        <table style={{width:"auto", margin: "0 0 0 auto"}}>
                            <tbody>
                                {props.parentOrder?.total_price >0 && 
                                    <tr>
                                        <td className={styles["span-left"]}>Order #{props.parentOrder?.id} Total</td>
                                        <td className={styles["span-right"]}>${props.parentOrder.total_price.toFixed(2)}</td>
                                    </tr>
                                }
                                <tr>
                                    <td className={styles["span-left"]}>{props.parentOrder ? "Split" : ""} Subtotal</td>
                                    <td className={styles["span-right"]}>${props.order.subtotal_price.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className={styles["span-left"]}>{props.parentOrder ? "Split" : ""} Taxes</td>
                                    <td className={styles["span-right"]}>${props.order.tax_total.toFixed(2)}</td>
                                </tr>
                                {props.order?.tip>0 && 
                                    <tr >
                                        <td className={styles["span-left"]}>{props.parentOrder ? "Split" : ""} Tip</td>
                                        <td className={styles["span-right"]}>${props.order.tip?.toFixed(2)}</td>
                                    </tr>
                                }
                                {/*props.order?.admin_fee_total > 0 &&
                                    <tr>
                                        <td className={styles["span-left"]}>{props.parentOrder ? "Split" : ""} Administrative Fee</td>
                                        <td className={styles["span-right"]}>${props.order.admin_fee_total.toFixed(2)}</td>
                                    </tr>
                                */}
                                {props.order?.price_adjustments?.map((adjustment, i)=>(
                                    <tr key={`pa-order-${i}`}>
                                        <td className={styles["span-left"]}>{props.parentOrder ? "Split" : ""} {adjustment.price_adjustment_type_name}</td>
                                        <td className={styles["span-right"]}>
                                            {adjustment.amount > 0 ? "" : "-"}
                                            ${Math.abs(adjustment.amount).toFixed(2)}
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    {props.order.total_discount > 0 &&
                                        <>
                                            <td className={styles["span-left"]}>{props.parentOrder ? "Split" : ""} Discounts</td>
                                            <td className={styles["span-right"]}>${props.order.total_discount.toFixed(2)}</td>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    <td className={styles["span-left"]}>{props.parentOrder ? "Split" : "Final"}  Total</td>
                                    <td className={`${styles["span-right"]} ${styles.amount}`}>${props.order.total_price.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td className={styles["span-left"]}>Total Payment</td>
                                    <td className={`${styles["span-right"]} ${styles.amount}`}>
                                        {props.order?.transaction?.length>0 ?
                                            `$${props.order.transaction.reduce((acc, curr)=>acc+curr.amount, 0).toFixed(2)}`
                                            : "$0.00"
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                    {props.order?.memo &&
                        <div className={styles.memo}>
                            <p>{props.order?.memo}</p>
                        </div>
                    }
                    {props?.receiptMessage?.message && props?.receiptMessage?.location === "bottom" &&
                        <p>
                            <br />
                            {props?.receiptMessage?.message}
                        </p>
                    }
                </div>
                {!props.transaction &&
                    <PaymentHistory order={props.order} format={"ticket"} />
                }
            </div>
            {props.extraContent && props.extraContent}
        </div>
    );
});

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Accordion, Card, AccordionCollapse} from 'react-bootstrap';

import { useRoleCheck } from '../common/CustomHooks';

import './Tutorials.json';
import './Tutorials.scss';

/**
 * Insert the tutorial component into the div containing the className "header" for the breadcrumbs.  This will insert the icon and modal for use.  This does not work if that header is a Row of Col, they disturb the flex.
 * Requires the following:
 * @param {{}} tutorialSection - the page the tutorial is based around (i.e. User Profile Tutorials) 
 * @param {{}} allSectionTutorial - if you want all of the tutorials based on a section (i.e. User Tutorials will show everything for the user profile (each tab tutorial)).  If this is false, only the subpage will appear
 * @param {{}} subSection - the specific tutorial section you want displayed if allSectionTutorial is false(i.e. Family Tab, Subscription Tab) 
 * @param {{}} navigationTutorial - boolean - if true, the navigation part for that section will appear.  Should be false when linked from a components page (as they've already navigated there!)
 * @param {{}} basicInfo - boolean - if true, the generic information for a section will show (example: "The User's profile is where you can manage all things about your user! You have access to blahdeblah and doopeedoo")
 * */
export const Tutorials = ({tutorialSection, allSectionTutorial, navigationTutorial, basicInfo, subSection, ...props}) => {

    const tutorials = require('./Tutorials.json');
    let userRole = useRoleCheck();
    const [modalShow, setModalShow] =useState(false);
    const [tutorialContent, setTutorialContent]=useState([]);
    const [hasContent, setHasContent]=useState(true); //this has to default to true so that the div exists in the dom before the initial function
    const [multipleSections, setMultipleSections]=useState(true); //this has to default to true so that the div exists in the dom before the initial function

    const getTutorials=useCallback(()=>{
        try{
            let response = tutorials[tutorialSection];
            setTutorialContent(response.content)
        }catch(ex){
            console.error(ex)
        }
    },[tutorialSection, tutorials]);

    const setInnerHTML=useCallback(()=>{
        let hasContent = false //if this is false, set the state to say none of these tutorials exist to display as such.
        if(basicInfo && tutorialContent?.basic) {
            document.getElementById("tutorial-basic").innerHTML = tutorialContent.basic
            hasContent = true;
        }
        if(navigationTutorial && tutorialContent.navigation){
            document.getElementById("tutorial-navigation").innerHTML = tutorialContent.navigation
            hasContent = true;
        } 
        if(subSection && typeof subSection === "string" && !allSectionTutorial) {
            let selectedSection = tutorialContent.subsections.find(section=>section.short_name===subSection);
            if(selectedSection) {
                document.getElementById("section-title").innerHTML = selectedSection.title;
                hasContent=true;
            }
            if((userRole.id===7 || userRole.id===1) && selectedSection) {
                document.getElementById("single-section-patron").innerHTML = selectedSection.patron_block;
                hasContent=true;
            }
            if(userRole.id < 6 && selectedSection) {
                document.getElementById("single-section-staff").innerHTML = selectedSection.staff_block;
                hasContent=true;
            }
            if(userRole.id< 5 && selectedSection){
                document.getElementById("single-section-admin").innerHTML = selectedSection.admin_block;
                hasContent=true;
            } 
            setMultipleSections(false)
        }else if(subSection && Array.isArray(subSection) && !allSectionTutorial){
            for(let i = 0; i < subSection?.length; i++){
                let selectedSection = tutorialContent.subsections.find(section=>section.short_name===subSection[i]);
                if(selectedSection && selectedSection.access_and_above >= userRole.id) {
                    document.getElementById(`multi-section-title-${selectedSection.short_name}-${i}`).innerHTML = selectedSection.title;
                    if(userRole.id === 7 || userRole.id===1){
                        document.getElementById(`multi-section-subsection-patron-${selectedSection.short_name}-${i}`).innerHTML = selectedSection.patron_block;
                        hasContent=true;
                    }
                    if(userRole.id < 6){
                        document.getElementById(`multi-section-subsection-staff-${selectedSection.short_name}-${i}`).innerHTML = selectedSection.staff_block;
                        hasContent=true;
                    }
                    if(userRole.id<5){
                        document.getElementById(`multi-section-subsection-admin-${selectedSection.short_name}-${i}`).innerHTML = selectedSection.admin_block;
                        hasContent=true;
                    }
                }        
            }
        }
        if(tutorialContent?.subsections?.length >0 && allSectionTutorial){
            for(let i=0; i<tutorialContent.subsections.length; i++){
                if(userRole.id === 7 || userRole.id===1){
                    document.getElementById(`patron-block-${tutorialContent.subsections[i].id}`).innerHTML = tutorialContent.subsections[i].patron_block;
                    hasContent=true;
                }
                if(userRole.id < 6){
                    document.getElementById(`staff-block-${tutorialContent.subsections[i].id}`).innerHTML = tutorialContent.subsections[i].staff_block;
                    hasContent=true;
                }
                if(userRole.id < 5){
                    document.getElementById(`admin-block-${tutorialContent.subsections[i].id}`).innerHTML = tutorialContent.subsections[i].admin_block
                    hasContent=true;
                }
            }    
        }
        setHasContent(hasContent);
    },[tutorialContent, basicInfo, navigationTutorial, userRole, allSectionTutorial, subSection]);

    const checkComplete=useCallback(()=>{
        if(document.readyState==="complete" && tutorialContent){
            setInnerHTML();
        }
    },[setInnerHTML, tutorialContent])

    useEffect(()=>{
        getTutorials();
    },[getTutorials]);

    useEffect(()=>{
        if(modalShow) checkComplete();
        else window.addEventListener('load', checkComplete);

        return ()=>window.removeEventListener('load', checkComplete);
    },[checkComplete, modalShow])

    if(modalShow){
        return(
            <Modal size={'lg'} show={modalShow} onHide={()=>setModalShow(false)} className="tutorial-wrapper">
                <Modal.Header closeButton>
                    {tutorialSection}
                </Modal.Header>
                <Modal.Body>
                    {tutorialContent && hasContent &&
                        <>
                            <div id="tutorial-basic">
                            </div>
                            <div id="tutorial-navigation">
                            </div>
                        </>
                    }
                    {tutorialContent && allSectionTutorial && hasContent &&
                        <Accordion>
                            {tutorialContent?.subsections?.map(section=>(
                                <Card key={`tutorial-subsection-${section.id}`}>
                                    {section.access_and_above >= userRole.id && 
                                        <Accordion.Toggle as={Card.Header} eventKey={section.id}>
                                            {section.title}
                                        </Accordion.Toggle>    
                                    }
                                    <Accordion.Collapse eventKey={section.id}>
                                        <>
                                            <div id={`patron-block-${section.id}`}>
                                            </div>
                                            <div id={`staff-block-${section.id}`}>
                                            </div>
                                            <div id={`admin-block-${section.id}`}>
                                            </div>
                                        </>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    }
                    {tutorialContent && hasContent && multipleSections && Array.isArray(subSection) &&
                        subSection?.map((section, i)=>(
                            <div key={`multi-section-${section}-${i}`}>
                                <h5 id={`multi-section-title-${section}-${i}`}>
                                </h5>
                                <div id={`multi-section-subsection-patron-${section}-${i}`}>
                                </div>
                                <div id={`multi-section-subsection-admin-${section}-${i}`}>
                                </div>
                                <div id={`multi-section-subsection-staff-${section}-${i}`}>
                                </div>
                            </div>
                        ))
                    }
                    {tutorialContent && !allSectionTutorial && subSection && hasContent &&
                        <>
                            <h5 id="section-title">
                            </h5>
                            <div id="single-section-patron">
                            </div>
                            <div id="single-section-staff">
                            </div>
                            <div id="single-section-admin">
                            </div>
                        </>
                    }
                    {tutorialContent && !hasContent &&
                        <div>
                            <p>No related tutorials</p>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <>
            {tutorialContent?.access_and_above >= userRole.id &&
                <span className="component-information-icon">
                    <i className="far fa-info-circle" onClick={()=>setModalShow(!modalShow)}/>
                </span>
            }
        </>
    )
}

import PermissionsAPI from '../api/Permissions';
import store from "../redux-store";

export const authCheck = (history) => {

    let user_info = localStorage.getItem("user");
    if (user_info) {
        try{
            return JSON.parse(user_info);
        } catch(error) {}
    } else {
        history.push("/p/");
    }
    return null;

}

// this function determines if the currently logged in auth user has access to a module
// returns a boolean
export const authUserHasModuleAccess = async (module_id, user_id=null) => {
    const auth = store.getState().auth;
    if (!auth.logged_in || !auth.user) return false;

    if (module_id) {
        try {
            let response = await PermissionsAPI.UserModulePermission.get({"module_ids": [module_id], "user_id": user_id});
            if (response.data) {
                return response.data;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error);
            return false;
        }
    } else {
        return false;
    }
    // return true;
}

// this function determines if the currently logged in auth user has access to a module
// returns a an object: {16: true, 17: false}
export const authUserHasModuleAccessMany = async (module_ids, user_id=null) => {
    const auth = store.getState().auth;
    if (!auth.logged_in || !auth.user) return false;

    if (module_ids) {
        try {
            let response = await PermissionsAPI.UserModulePermission.get({"module_ids": module_ids, "user_id": user_id});
            if (response.data && response.status === 200) {
                return response.data;
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    } else {
        return [];
    }
    // let tempTrues={};
    // module_ids.forEach((module)=>{
    //     tempTrues[module] = true
    // })
    // return tempTrues;
}
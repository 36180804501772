import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Row, Col, Container, Card, Form, InputGroup, Button} from 'react-bootstrap';

//import * as actions from '../../../../../store/actions';
import styles from "../GiftCard/GiftCard.module.scss";

const paymentMethodName = "Terminal Transaction";

export const Terminal = (props) => {
    const {onPaymentUpdate, onPaymentMethodChange, calculateTotals} = props;

    const firstLoad = useRef(true);
    const ref = useRef();

    const [totalPayment, setTotalPayment] = useState(0);
    const [terminals, setTerminals] = useState([]);
    const [error, setError] = useState();

    const amountChangeHandler = useCallback(e => {
        onPaymentUpdate(props.payment_method_id, [{payment_method_name: paymentMethodName, amount: +e.target.value || 0}]);
        if (e.target.name === "amount") setTotalPayment(e.target.value);
        /*ref.current.value = "";
        ref.current.defaultValue = "";
        */
    }, [onPaymentUpdate, props.payment_method_id]);

    useEffect(()=>{
        if (props?.totals){
            setTotalPayment(props.totals.total || 0);
        }
    }, [props.totals]);

    useEffect(()=>{
        if (props.payments && firstLoad.current){
            const payment = props.payments.filter(a=>a.payment_method_id === props.payment_method_id);
            if (payment.length > 0) setTerminals(payment.map((a, i)=>({id: i+1, ...a})));
        }
    }, [props.payments, props.payment_method_id]);


    useEffect(()=>{
        if (firstLoad.current) {
            onPaymentMethodChange(props.payment_method_id);
            if (props?.totals?.total){
                amountChangeHandler({target: {value: props.totals.total.toFixed(2)}});
            }
            if (ref.current) ref.current.focus();
        }
    }, [props.payment_method_id, onPaymentMethodChange, amountChangeHandler, props.totals]);

    useEffect(()=>{
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }

        return () => {
            setTerminals([]);
            setError(null);
        }
    }, []);

    return (
        <Container fluid data-cy="checkout-terminal-payment">
            <Row>
                <Col sm={12} lg="6">
                    <Form.Group controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control 
                                ref={ref} 
                                type="number" 
                                min={0} 
                                name="amount" 
                                value={totalPayment || 0} 
                                disabled={props.submitting} 
                                onChange={amountChangeHandler}
                                onBlur={amountChangeHandler}
                                onFocus={(e)=>e.target.select()}
                                onKeyUp={(e)=>e.key === "Enter" && e.target.blur()}
                            />
                        </InputGroup>
                        {error && <div className="error-text mb-3">{error}</div>}
                    </Form.Group>
                </Col>
                <Col sm={12} lg="6">
                    {terminals?.map((terminal, i) => (
                        <Card className={styles.wrapper} key={`terminalc-${i}`}>
                            <Card.Body>
                                <div className={styles.title}>Transaction #{terminal.transaction_id}</div>
                                <p>
                                    Amount: <span className="bold">${terminal.amount.toFixed(2)}</span>
                                </p>
                            </Card.Body>
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}
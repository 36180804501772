import React from 'react'
import { Card } from 'react-bootstrap';

const RouteLoadingError = () => {

    const pageRefresh = () => {
        window.location.reload();
    }

    const logout = () =>{
        window.location.pathname = "/signout";
    }

    return (
        <Card className="content-card mt-3">
            <div className="text-center">
                <p>
                    Oh no! There was an error loading all the routes! It may have been a hiccup in the system or there was a problem authenticating who you are.
                </p>
                <p>
                    If you're stuck here, try {" "}
                    <span onClick={pageRefresh} style={{cursor: "pointer", fontWeight: "600"}}>
                        refreshing the page {" "}
                    </span>
                    or {" "}
                    <span onClick={logout} style={{cursor: "pointer", fontWeight: "600"}}>
                        logging out {" "} 
                    </span>
                    and logging back in! 
                </p>
            </div>

        </Card>
    )
}

export default RouteLoadingError
import undoable from 'redux-undo';
import * as actionTypes from '../actions/actionTypes';

const initialState= {
    elements: [],
};

const cmsElements = (state = initialState, action)=>{
    let new_elements = JSON.parse(JSON.stringify(state.elements || []));

    switch(action.type){
        // add an element to the cms structure
        case actionTypes.CMS_ADD_ELEMENT:
            const idx_add = new_elements.findIndex(a=>a && a.id===action.element.id);
            if (idx_add>=0){
                if (action.indexToReplace && action.newIndex){
                    const item = new_elements[action.indexToReplace];
                    item.parent_id = action.element.parent_id;
                    const newer_elements = [...new_elements];

                    if (action.indexToReplace < action.newIndex){
                        const prevItem = newer_elements.splice(action.newIndex, 0, item);
                        newer_elements.splice(action.indexToReplace, 1, prevItem[0]);
                    } else {
                        const prevItem = newer_elements.splice(action.indexToReplace, 1);
                        newer_elements.splice(action.newIndex, 0, prevItem[0]);
                    }
                    new_elements = [...newer_elements];
                } else {
                    const _index = new_elements[idx_add].index;
                    const _parent_id = new_elements[idx_add].parent_id;
                    new_elements[idx_add] = JSON.parse(JSON.stringify(action.element));
                    new_elements[idx_add].index = _index;
                    new_elements[idx_add].parent_id = _parent_id;
                    new_elements.sort((a, b)=>a.index-b.index);
                }
            } else {
                if (action.newIndex){
                    if (action.newIndex > new_elements.length) action.newIndex = new_elements.length;
                    new_elements.splice(action.newIndex, 0, {...action.element})
                } else new_elements.push({...action.element});
            }

            // remove nulls from the array
            new_elements = new_elements.filter(a=>a);

            // loop through the elements and group them by parent
            let idx=0;
            const _groupElements = (elements, parent_id=0)=>{
                const grouped_elements = [];
                elements.filter(a=>a && a.parent_id===parent_id).forEach(element => {
                    if (element){
                        element.index = idx;
                        idx++;
                        if (element?.[element.element_id]) {
                            if (element[element.element_id]?.props?.value || element[element.element_id]?.props?.innerText)
                                element[element.element_id].innerText = element[element.element_id].props.value || element[element.element_id].props.innerText;
                            element[element.element_id].content = _groupElements(elements, element.id);
                        } 
                        grouped_elements.push(element);
                    }
                });
                return grouped_elements;
            }
            _groupElements(new_elements);
            new_elements.sort((a, b)=>a.index-b.index);

            return{
                ...state,
                elements: [...new_elements]
            }

        // remove an element from the cms structure
        case actionTypes.CMS_REMOVE_ELEMENT:
            const idx_remove = new_elements.findIndex(element=>element && element.id===action.element.id);
            if (idx_remove!==-1) new_elements.splice(idx_remove, 1);

            // loop through the elements and update the index
            new_elements.forEach((element, index)=>{
                if (element?.element_id?.toLowerCase() === "layout"){ // if its a layout make sure we have the right amount of elements so we can update the structure in case a column was removed
                    if (element?.properties?.find(p => p.name === "layout-type")?.value?.toLowerCase() === "row"){
                        const _children = new_elements.filter(a=>a && a.parent_id===element.id) || [];
                        const _layout = element?.properties?.findIndex(p => p.name === "custom-layout");
                        if (_layout>-1){
                            element.properties[_layout].value = _children.length || "";
                            element[element.element_id].props["custom-layout"] = _children.length || "";
                            element[element.element_id].content = _children;
                        }
                    }
                }
                if (element?.element_id?.toLowerCase() === "wizard" || element.element_id.toLowerCase() === "slider"){
                    const _children = new_elements.filter(a=>a && a.parent_id===element.id) || [];
                    const _layout = element?.properties?.findIndex(p => p.name === "custom-steps");
                    if (_layout>-1){
                        element.properties[_layout].value = _children.length || "";
                        element[element.element_id].props["custom-steps"] = _children.length || "";
                        element[element.element_id].content = _children;
                    }
                }
                element.index = index;
            });

            return{
                ...state,
                elements: [...new_elements]
            }

        // reset the cms structure
        case actionTypes.CMS_RESET:
            return{
                ...state,
                elements: action.elements
            }
        default:
            return state;
    }
}

const cmsElementsReducer = undoable(cmsElements);

export default cmsElementsReducer;
import Groups from '../../../../api/Groups';
import Users from '../../../../api/Users';

/**This function will return all family members from all family groups in which this user has admin permission */
export const getAdminFamilyMembers=async(user_id, user, isAdmin=false)=>{
    //ultimately return family
    let adminGroupMembers;
    let uniqueMembers;
    let adminGroups = await getFamilyGroupAdminRoles(user_id, isAdmin);
    if(adminGroups.length > 0) adminGroupMembers =  await getFamilyMembers(adminGroups, user_id)
    else {
        //other format for family uses user_id and not the straight id for the user, so we need to add it
        user.user_id = user.id 
        return [user]
    }
    if(adminGroupMembers) uniqueMembers = await filterFamilyForUnique(adminGroupMembers);
    return uniqueMembers;
}

/** Have to get the groups a user has access to 
 * 
 * If the user is admin of a group, those group ids will be returned in an array, else this function will return false
*/
const getFamilyGroupAdminRoles =async(user_id, isAdmin)=>{
    try{
        let response = await Users.groups({user_id: user_id});
        if(response.status === 200){
            if(isAdmin) return response.data.map((group)=>group.group_id);
            else{
                let adminGroups= []
                response.data.forEach((family)=>{
                    if(family.is_admin) adminGroups.push(family.group_id)
                })
                return adminGroups;
            }
        }else return false
    }catch(ex){
        console.error(ex)
        return false
    }
}


const getFamilyMembers = async(adminIds, user_id)=>{
    try{
        let response = await Groups.groupFilter({filters:{user_id: user_id}});
        if(response.status === 200){
            let adminGroupMembers = [];
            adminIds.forEach((group)=>{
                let family = response.data.groups.filter((family)=>family.id === group)
                if(family){
                    adminGroupMembers = [...adminGroupMembers, ...family[0].group_members]
                }
            })
            return adminGroupMembers;
        }
        else return false;
    }catch(ex){
        console.error(ex)
        return false;
    }
}

const filterFamilyForUnique=async(members)=>{
    let uniqueMembers = [];
    let memberIds = [];
    for(let i = 0; i < members.length; i++){
        if(i === 0) {
            uniqueMembers.push(members[i])
            memberIds.push(members[i].user_id)
        }
        else if(!memberIds.includes(members[i].user_id)){
            uniqueMembers.push(members[i])
            memberIds.push(members[i].user_id)
        }
    }
    return uniqueMembers;
}
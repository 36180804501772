import Request from './Api';

const get = async(props) => {

    // remove this when the tables are mapped on the live server
    if (props?.tables){
        let mockdata = await rtest();
        return (
            Request({
                url: "/location" + (!props ? "": "/"+props.id),
                data: {...props},
                test: {data:mockdata} // send mock data to simulate an api call
            })
        );
    }

    return (
        Request({
            url: "/location",
            data: {...props},
            method: "POST"
        })
    );
}


const getStructured = async(props) => {
    let structuredArray = { errors: null, data: [] };
    let unplacedLocations = [];
    let availabilities = getGenericAvailabilities();

    const search = (location, parent) => {
        if(parent.id === location.parent_id) {
            //parent found, add to children and remove from unplacedLocations
            location.general_availabilities = availabilities;
            parent.children = [...parent.children, {...location, children: []}];
            unplacedLocations = unplacedLocations.filter( item => item.id !== location.id);
        } else if(parent.children.length > 1) {
            //no match, search deeper
            parent.children.map( child => search(location, child) );
        }
    }

    await get(props)
    .then(response => {
        let locations = response.data;
        let i = 0;
        unplacedLocations = locations;
        while(i < locations.length && unplacedLocations.length > 0) {
            for(let j = 0; j < [...unplacedLocations].length; j++) {
                let location = [...unplacedLocations][j];
                if( !location.parent_id || location.id === 1 ) {
                    //place top-level locations with no parents
                    //id check is temp fix for seed error: main building should have parent_id null
                    location.general_availabilities = availabilities;
                    structuredArray.data = [...structuredArray.data, {...location, children: []}];
                    unplacedLocations = unplacedLocations.filter( item => item.id !== location.id);
                } else {
                    //has a parent location, need to find
                    structuredArray.data.map( parent => search(location, parent) );
                }
                return null;
            }
            i += 1;
        }
    })
    .catch(e => console.error(e));

    return structuredArray;
}

const getWithAvailabilities = async (props) => {
    // Locations.getEvents({id: props.location, start_datetime: date_start, end_datetime: date_end, include_sublocations: 0})
    let availabilities = getGenericAvailabilities();
    let returnData = { errors: null, data: {} };

    try {
        await get(props)
        .then(response => {
            returnData.data = response.data[0];
            returnData.data.general_availabilities = availabilities;
        });
        await getEvents(props)
        .then(response => {
            returnData.data.events = response.data?.filter( event => //other events at this location
                event.type_id !== 5 //not meta
                && event.status_id !== 3 //not postponed
                && event.status_id !== 4 //not canceled
            );
        });
    } catch (e) {
        console.error(e);
    }

    return returnData;
}

// create location
const create = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Location Name is required", "Category is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/location/create",
            data: props,
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// update location
const update = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Location Name is required", "Type is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/location/edit",
            data: props,
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// Location Types
const Types = {

    // get status
    get: async(props)=>{
        // sets mock data for testing - will be removed when api is ready
        //let mockdata = {data:null,errors:["Location Type Id is required"]};
        let mockdata = {
            data: [
                {
                    "id": 1,
                    "name": "Primary",
                    "description": null,
                    "children":[]
                },
                {
                    "id": 2,
                    "name": "Gym",
                    "description": null,
                    "children":[
                        {
                            "id": 5,
                            "name": "Court",
                            "description": null,
                            "children":[]
                        },                                
                    ]
                },
                {
                    "id": 3,
                    "name": "Front Desk",
                    "description": null,
                    "children":[]
                },
                {
                    "id": 4,
                    "name": "Restaurant",
                    "description": null,
                    "children":[
                        {
                            "id": 6,
                            "name": "Kitchen",
                            "description": null,
                            "children":[]
                        },
                        {
                            "id": 7,
                            "name": "Register",
                            "description": null,
                            "children":[]
                        },
                        {
                            "id": 8,
                            "name": "Dining Area",
                            "description": null,
                            "children":[
                                {
                                    "id": 9,
                                    "name": "Table",
                                    "description": null,
                                    "children":[]
                                },
                            ]
                        },
                        {
                            "id": 10,
                            "name": "Bar",
                            "description": null,
                            "children":[]
                        }                
                    ]
                },
                {
                    "id": 11,
                    "name": "Printer",
                    "description": null,
                    "children":[]
                },
            ],
            errors:null
        };

        if (props?.id){
            const filtered_data=mockdata.data.filter(item => item.id===parseInt(props.id));
            mockdata.data=filtered_data;
        }
        
        return (
            Request({
                url: "/location/type",
                data: {props},
                test: mockdata // send mock data to simulate an api call
            })
        );
    },
}


// Location Shapes
const Shapes = {

    // ge status
    get: async(props)=>{
        // sets mock data for testing - will be removed when api is ready
        //let mockdata = {data:null,errors:["Location Type Id is required"]};

        const blob_circle = new Blob([`<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><circle cx="25" cy="25" r="20"/></svg>`], {type: 'image/svg+xml'});
        const blob_square = new Blob([`<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><rect cx="25" cy="25" r="20"/></svg>`], {type: 'image/svg+xml'});
        const blob_rectangle = new Blob([`<svg xmlns="http://www.w3.org/2000/svg" width="150" height="50"><rect cx="25" cy="25" r="20"/></svg>`], {type: 'image/svg+xml'});

        let mockdata = {
            data: [
                {
                    "id": 1,
                    "name": "Square",
                    "visual":blob_square
                },
                {
                    "id": 2,
                    "name": "Circle",
                    "visual":blob_circle
                },
                {
                    "id": 3,
                    "name": "Rectangle",
                    "visual":blob_rectangle
                },
            ],
            errors:null
        };

        if (props?.id){
            const filtered_data=mockdata.data.filter(item => item.id===parseInt(props.id));
            mockdata.data=filtered_data;
        }
        
        return (
            Request({
                url: "/location/shape",
                data: props,
                test: mockdata // send mock data to simulate an api call
            })
        );
    },
}


// Event Types 
const Events = {
    Types: {
        // ge status
        get: async(props)=>{
            // sets mock data for testing - will be removed when api is ready
            //let mockdata = {data:null,errors:["Location Id is required"]};

            /*let mockdata = {
                data: [
                    {
                        "id": 1,
                        "name": "Class",
                        "description":"",
                        "is_meta":null,
                        "has_product_fee":null,
                        "allow_reserved_location":null,
                        "group_invites":null,
                        "individual_invites":null,
                        "has_divisions":null,
                        "allowed_subevents":null,
                        "locations":[5,15,16,17,18,19,20]
                    },
                    {
                        "id": 2,
                        "name": "Practice",
                        "description":"",
                        "is_meta":null,
                        "has_product_fee":null,
                        "allow_reserved_location":null,
                        "group_invites":null,
                        "individual_invites":null,
                        "has_divisions":null,
                        "allowed_subevents":null,
                        "locations":[5,15,16,17,18,19,20]
                    },
                    {
                        "id": 3,
                        "name": "Reservation",
                        "description":"",
                        "is_meta":null,
                        "has_product_fee":null,
                        "allow_reserved_location":null,
                        "group_invites":null,
                        "individual_invites":null,
                        "has_divisions":null,
                        "allowed_subevents":null,
                        "locations":[26,22,14,13]
                    },
                    {
                        "id": 4,
                        "name": "Game",
                        "description":"",
                        "is_meta":null,
                        "has_product_fee":null,
                        "allow_reserved_location":null,
                        "group_invites":null,
                        "individual_invites":null,
                        "has_divisions":null,
                        "allowed_subevents":null,
                        "locations":[15,16,17,18,19,20]
                    },
                ],
                errors:null
            };

            if (props?.id){
                const filtered_data=mockdata.data.filter(item => item.id===parseInt(props.id));
                mockdata.data=filtered_data;
            }*/
            
            return (
                Request({
                    url: "/location/event/type",
                    data: {props},
                    //test: mockdata // send mock data to simulate an api call
                })
            );
        },
    }
}



const getGenericAvailabilities=()=>{
    //const d=new Date();

    let slots = [];
    let slotsPerHour = 4;

    for(let day = 2; day <= 6; day += 1) { // Monday through Friday
        for(let hour = 6; hour < 22; hour += 1) {  // open 06:00 until 22:00
            for(let slotNum = 1; slotNum <= slotsPerHour; slotNum += 1) {

                let slotLength = 60 / slotsPerHour;
                let timestamp_hour = hour < 10 ? "0" + hour : hour;
                let timestamp_start_minutes = slotLength * ( slotNum - 1 ); //end of previous slot
                if (timestamp_start_minutes < 10) timestamp_start_minutes = "0" + timestamp_start_minutes;
                let timestamp_end_minutes = slotLength * slotNum - 1; //one minute before next slot
                if (timestamp_end_minutes < 10) timestamp_end_minutes = "0" + timestamp_end_minutes;

                slots = [ ...slots, {
                    day_of_week: day,
                    start_time: timestamp_hour + ":" + timestamp_start_minutes,
                    end_time: timestamp_hour + ":" + timestamp_end_minutes
                } ]
            }
        }
    }

    for(let day = 1; day <= 7; day += 6) { // Saturday and Sunday
        for(let hour = 7; hour < 22; hour += 1) {  // open 07:00 until 22:00
            for(let slotNum = 1; slotNum <= slotsPerHour; slotNum += 1) {

                let slotLength = 60 / slotsPerHour;
                let timestamp_hour = hour < 10 ? "0" + hour : hour;
                let timestamp_start_minutes = slotLength * ( slotNum - 1 ); //end of previous slot
                if (timestamp_start_minutes < 10) timestamp_start_minutes = "0" + timestamp_start_minutes;
                let timestamp_end_minutes = slotLength * slotNum - 1; //one minute before next slot
                if (timestamp_end_minutes < 10) timestamp_end_minutes = "0" + timestamp_end_minutes;

                slots = [ ...slots, {
                    day_of_week: day,
                    start_time: timestamp_hour + ":" + timestamp_start_minutes,
                    end_time: timestamp_hour + ":" + timestamp_end_minutes
                } ]
            }
        }
    }

    /*let filled=[];
    let general_availabilities=[];    
    for (let j=0;j<7;j++){
        for (let k=0;k<24;k++){

            for (let l=0;l<4;l++){
                general_availabilities.push(
                    {
                        day_of_week:j,
                        start_time:`${k}:${l*15}`,
                        end_time:`${k}:${l*15+14}`
                    },        
                );
            }

            /*
            let item = slots[Math.floor(Math.random() * slots.length)];
            const ast=item["start_time"].split(":");
            const aet=item["end_time"].split(":");
            if (!filled[ast[0]]){
                availability.push({...item,day_of_week:j});
                for (let i=ast[0];i<=aet[0];i++){
                    filled[i]=i;
                }
            }
        }
    }

    general_availabilities.sort(function(a, b) {
        const time_a=a.start_time.split(":");
        const time_b=b.start_time.split(":");
        const key_a = new Date(d.getDate(),d.getMonth(),d.getFullYear(),time_a[0],time_a[1]);
        const key_b = new Date(d.getDate(),d.getMonth(),d.getFullYear(),time_b[0],time_b[1]);
        if (key_a < key_b) return -1;
        if (key_a > key_b) return 1;
        return 0;
    });*/

    return slots;
}

const getEvents = props => {
    return (
        Request({
            url: "/location/events",
            data: props,
            method: "POST"
        })
    );
}


// Print Location
const printLocation = {
    getNext: props =>{
        return (
            Request({
                url: "/print_location",
                method: "POST",
                data: props,
            })
        );    
    },
    complete: props =>{
        return (
            Request({
                url: "/print_location/complete",
                method: "POST",
                data: props,
            })
        );    
    },
    addToQueue: props =>{
        return (
            Request({
                url: "/print_location/create",
                method: "POST",
                data: props,
            })
        );
    },
    getLocations: props =>{
        return (
            Request({
                url: "/print_location/location",
                method: "POST",
                data: props,
            })
        );
    },
};



// sets up mock data for tables
const rtest = async () => {
    return new Promise((resolve, reject) => {
        let dummydata=[

            {
                id: 121,
                name: "Restaurant",
                parent_id:1,
                location_type_id: 1,
                location_shape_id: 1,
                location_shape_blob: null,
                height:100,
                width:100,
                left:210,
                top:30,
                selectable:1,
                selectMultiple:0,
                availability:null,
                children:[
                    {
                        id: 123,
                        name: "Table 1",
                        parent_id:1,
                        location_type_id: 1,
                        location_shape_id: 1,
                        location_shape_blob: null,
                        height:100,
                        width:100,
                        left:50,
                        top:50,
                        selectable:1,
                        selectMultiple:0,
                        availability:null,
                        children:[]
                    },
                    {
                        id: 124,
                        parent_id:1,
                        name: "Table 2",
                        location_type_id: 1,
                        location_shape_id: 1,
                        location_shape_blob: null,
                        height:100,
                        width:100,
                        left:155,
                        top:50,
                        selectable:1,
                        selectMultiple:0,
                        availability:null,
                        children:[]
                    },
                    {
                        id: 125,
                        parent_id:1,
                        name: "Table 3",
                        location_type_id: 1,
                        location_shape_id: 1,
                        location_shape_blob: null,
                        height:100,
                        width:100,
                        left:260,
                        top:50,
                        selectable:1,
                        selectMultiple:0,
                        availability:null,
                        children:[]
                    },
                    {
                        id: 126,
                        parent_id:1,
                        name: "Table 4",
                        location_type_id: 1,
                        location_shape_id: 1,
                        location_shape_blob: null,
                        height:100,
                        width:100,
                        left:155,
                        top:155,
                        selectable:1,
                        selectMultiple:0,
                        availability:null,
                        children:[]
                    }        
                ]
            },
        ]


        

        resolve(dummydata);
    })
}


const Locations = {
	get, getStructured, getWithAvailabilities, create, update, getEvents, Types, Shapes, Events, printLocation //, delete, etc. ...
}
  
export default Locations;
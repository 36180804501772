import Request from './Api';

const getSideBar = () => {
	let menu=[];
	let localuser = localStorage.getItem("user");
	if (localuser) {
		try{
			localuser=JSON.parse(localuser);
			menu=localuser.menus[0].sidebar;
		} catch(error){
		}
	}

	return menu;

}

const getProfileMenu = () => {
	let menu=[];
	let localuser = localStorage.getItem("user");
	if (localuser) {
		try{
			localuser=JSON.parse(localuser);
			menu=localuser.menus[0].profile_menu;
		} catch(error){
		}
	}
	return menu;
}


const getFullMenu = async(props) => {
	if (props?.token) {
		return (
			Request({
				headers: { 'Authorization': props.token },
				url: "/user/menu",
				method: "POST"
			})
		);
	} else {
		return (
			Request({
				data: {...props},
				url: "/user/menu",
                method: "POST"
			})
		);
	}
}

const Menu = {
	getSideBar, getProfileMenu, getFullMenu //, create, update, delete, etc. ...
}
  
export default Menu;
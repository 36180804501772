import React, {useEffect, useState, useRef, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {Button, Spinner, Form, Container, Col, Row} from 'react-bootstrap';

import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import Toast from '../../../../components/Toast';
import CartAddressView from '../PatronCart/CartAddressView';
import SPAddressView from '../SplitPayment/SPAddressView'

import APIPOS from '../../../../api/Pos';
import * as actions from '../../../../store/actions'
import { refreshOrder } from '../../../../utils/thunks';
import { checkTransaction } from '../../../../utils/checkTransactionStatus'

const getStyles = () => {
    const rootStyles = getComputedStyle(document.documentElement);
    const _getSassVar = (name) => {
        // split the name by spaces
        const nameArr = name.split(' ');
        // check if any of the values starts with a $, if so, replace it with the value of the variable
        nameArr.forEach((val, i) => {
            if (val.substring(0,1)=== '$') {
                nameArr[i] = rootStyles.getPropertyValue(`--${val.substring(1)}`) ;
            }
        });
        // return the name with the variables replaced
        return nameArr.join(' ');
    }

    const bgColor = rootStyles.getPropertyValue('--form-control-background-color');
    const color = rootStyles.getPropertyValue('--form-control-color');
    const fontFamily = rootStyles.getPropertyValue('--form-control-font-family').replace(/['"]+/g, '');
    const fontSize = rootStyles.getPropertyValue('--form-control-font-size');
    const fontWeight = rootStyles.getPropertyValue('--form-control-font-weight');
    const lineHeight = rootStyles.getPropertyValue('--form-control-line-height');
    const border = rootStyles.getPropertyValue('--form-control-border');
    const borderRadius = rootStyles.getPropertyValue('--form-control-border-radius');
    const padding = rootStyles.getPropertyValue('--form-control-padding');

    const borderArr = border.split(' ');
    const fontFamilyArr = fontFamily.split(',');    
    
    return {
        backgroundColor: _getSassVar(bgColor),
        color: _getSassVar(color),
        fontFamily: _getSassVar(fontFamily),
        fontSize: _getSassVar(fontSize),
        fontWeight: _getSassVar(fontWeight),
        lineHeight: _getSassVar(lineHeight),
        border: _getSassVar(border),
        borderSize: _getSassVar(borderArr[0] || "0"),
        borderStyle: _getSassVar(borderArr[1] || "none"),
        borderColor: _getSassVar(borderArr[2] || border),
        borderRadius: _getSassVar(borderRadius),
        padding: _getSassVar(padding),
        width: "99.85%",
        font: `${_getSassVar(fontFamilyArr[0])}:${_getSassVar(fontWeight)}`,
    }
}

/**Needs the following:
 * @param props.register_id
 * @param props.passUser -to send user details back up to its parent component
 * @param props.paymentMethod -to change the display for the address/cc input
 */
export const CCPayment = (props) => {

    const { setTransSuccess, order, passUser, register_id, patron_layout, user, setPrint, setProcessing, otherButtons, disabled: propDisabled, paymentMethod, bypassCC, memo, paymentFollowUp } = props;

    const dispatch=useDispatch();
    const mountedRef = useRef(false);
    const ccRef = useRef(null);

    const isPaymentSetup = !!window.CollectJS || false;

    const reduxOrder = useSelector(state => state.patronCart.modifiedOrder);
    const reduxDetails = useSelector(state => state.patronCart.userDetails);
    const defaultPatronRegisterId = useSelector(state =>state.pos.register);

    const locationId = props.register_id || defaultPatronRegisterId;  //id for patron register
    const [error, setError]=useState();
    const [success, setSuccess]=useState();
    const [haveOrder, setHaveOrder]=useState(false);
    const [transactionId, setTransactionId]=useState();
    const [transactionStatus, setTransactionStatus]=useState();
    const [userDetails, setUserDetails]=useState();
    const [readyForPayment, setReadyForPayment]=useState(false);
    const [submitting, setSubmitting]=useState(false);
    const [validation, setValidation]=useState(null);
    const [disabled, setDisabled]=useState(false);
    const [ccValidated, setCCValidated]=useState(false);
    const [expValidated, setExpValidated]=useState(false);
    const [cvvValidated, setCVVValidated]=useState(false);
    const [token, setToken]=useState();

    const INTERVAL_RUN = 5000;

//#region useCallback
    const listenSubmit = useCallback(() => {
        setSubmitting(true);
    },[]);

    /**Send Payment to the API */
    const sendPayment= useCallback(async()=>{
        if (!userDetails){

        }
        if(props.memo !=="BYPASS" || !props.memo){
            // let fullMemo = `${props?.memo} ** Credit card payment as part of a split payment for order number ${order.id}.`
            let fullMemo = props.memo || null
            try{
                const payments = [{
                    payment_method_id: 1,
                    amount: order.total_price,
                    type: 'sale',
                    description: "Online Credit Card Payment",
                        payment_token: token,
                        first_name: userDetails?.first_name || "",
                        last_name: userDetails?.last_name || "",
                        bill_address1: userDetails?.address1 || "",
                        bill_address2: userDetails?.address2 || "",
                        bill_city: userDetails?.city || "",
                        bill_state: userDetails?.state || "",
                        bill_postal_code: userDetails?.postal_code || "",
                        memo: fullMemo,
                        //payment_profile_id: paymentFields.payment_profile_id || undefined,
                }];

                const response = await APIPOS.payment.process({order_id: order.id, payments});
                /*let response=await Pos.payment.card({
                    type: "sale",
                    payment_token: token,
                    user_id: props.user.id,
                    order_id: order.id,
                    location_id: locationId,
                    description: "Credit Card Transaction",
                    first_name: userDetails.first_name,
                    last_name: userDetails.last_name,
                    bill_address1: userDetails.address1,
                    bill_address2: userDetails.address2,
                    bill_city: userDetails.city,
                    bill_state: userDetails.state,
                    bill_postal_code: userDetails.postal_code,
                    memo: fullMemo
                })*/
                if(props.paymentFollowUp && mountedRef.current) props.paymentFollowUp(response)
                else{
                    if(!response.errors && mountedRef.current){
                        props.setProcessing(true);
                        if (!Array.isArray(response.data)) response.data=[response.data];
                        response.data.forEach(payment=>{
                            dispatch(actions.transaction(payment.transaction_id, props.order.register_id));
                        });
                        setTransactionId(response.data[0].transaction_id);
                        setTransactionStatus(parseInt(response.data[0].transaction_status_id))
                        dispatch(refreshOrder(props.order.register_id))
                            .then(()=>{
                                setSubmitting(false);
                            })
                    } else if(response.errors){
                        setError(<ErrorCatcher error={response.errors} />)
                        setTransactionStatus(8)
                    }
                }
            }catch(ex){console.error(ex)}
            setSubmitting(false);
        }
        else if(props.bypassCC) {
            setSubmitting(false);
            props.bypassCC();
            let response = {
                data:[{
                    order_id: order?.id,
                    transaction_id: 7,
                    transaction_status_id: 7
                }]
            }
            props.paymentFollowUp(response)
        }
    // having props in here causes the a loop
    // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[token, userDetails, dispatch, locationId]);    


    /**Check transaction status to display for user */
    const checkTrx = useCallback(async () =>{
        // let status = checkTransaction(transactionStatus, transactionId);
        
        if (transactionStatus === 6 && transactionId){
            let response=await APIPOS.payment.terminalStatus({"transaction_id": transactionId});
            if (response.errors){
                if(response.errors?.transaction?.success === false) {
                    setTransactionStatus(8);
                } else {
                    setTransactionStatus(4);
                }
            } else {
                setTransactionStatus(parseInt(response.data[0]?.transaction_status_id));
            }
        }
    },[transactionStatus, transactionId]);


    /**checks to see that all data required is present*/
    const checkValidation=useCallback(()=>{
        setSubmitting(false);
        let validated=null;
        /*console.log(+props.register_id===+defaultPatronRegisterId);
        console.log(props.registerId);
        console.log(defaultPatronRegisterId);*/
        if(ccValidated && expValidated && cvvValidated && userDetails.first_name !=="" && userDetails.last_name !=="" && userDetails.address1 !=="" && userDetails.city !=="" && userDetails.state !=="" && userDetails.postal_code!=="") {
            validated=null;
            dispatch(actions.setPCUserDetails(userDetails));
        }
        else if(props.paymentMethod==="SplitPayment" && +props.register_id!== defaultPatronRegisterId && ccValidated && expValidated && cvvValidated && userDetails.postal_code!==""){
            validated=null
            dispatch(actions.setPCUserDetails(userDetails));
        }
        else validated = "You need to input all required fields to process a payment."
        setValidation(validated);
    },[ccValidated, expValidated, cvvValidated, userDetails, props.register_id, props.paymentMethod,defaultPatronRegisterId, dispatch]);

//#endregion Callbacks

//#region useEffect
    //first load
    useEffect(() => {
        mountedRef.current = true;

        const paymentButton = document.getElementById("payButton");
        paymentButton?.addEventListener("click", listenSubmit);
        
        return() => {
            mountedRef.current = false;
            dispatch(actions.setPCReset());
            paymentButton?.removeEventListener("click", listenSubmit);
        }
    },[dispatch,listenSubmit]);

    //Effects reliant on props, such as forcing to ensure the order is completely loaded
    useEffect(()=>{
        if(props.haveOrder) {
            setHaveOrder(props.haveOrder);
            dispatch(actions.setPCModifiedOrder(props.order));
        };
    },[props.haveOrder, props.order, dispatch]);

    //props.disabled should only come in if the transaction was already successful and these are unnecessary.
    useEffect(()=>{
        if(props.disabled){
            setValidation("")
            setError();
        } 
    },[props.disabled]);

    //Checks for reasons to need to disabled the submit button
    useEffect(()=>{
        if(submitting || validation || transactionStatus===7) setDisabled(true);
        else setDisabled(false);
    },[submitting, validation, transactionStatus]);

    //updating user details as the address component gets them
    useEffect(()=>{
        if(passUser && mountedRef.current && userDetails) {
            passUser(userDetails);
            checkValidation()
        }
        else checkValidation();
    },[checkValidation, passUser, userDetails]);

    //When trasaction status or ID changes, runs checks until the transaction status is complete
    //Handles when transaction status changes
    useEffect(()=>{
        const interval = setInterval(() => {
            if(mountedRef.current && (transactionId || transactionStatus)) checkTrx();
        }, INTERVAL_RUN);

        if(mountedRef.current && transactionStatus===7){
            setTransSuccess(true);
            setSuccess(<Toast>Your transaction was completed successfully!</Toast>)
            setError();
            setPrint(true);
        } 

        return () => clearInterval(interval);
    },[transactionId, transactionStatus, setTransSuccess, setPrint, checkTrx]);

    useEffect(()=>{
        if(token) sendPayment();
    },[token, sendPayment]);

    useEffect(()=>{
        if (!isPaymentSetup) return;
        const configureCollectJS = () => {
            const css_styles = getStyles();

            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                callback: (response) => {
                    setSubmitting(true)
                    setToken(response.token)
                },
                validationCallback: (field, status)=>{
                    checkNMIValidation(field, status);
                },
                invalidCss:{
                    color: 'red'
                },
                validCss:{
                    color: 'green'
                },
                fields: {
                    cvv: {
                        placeholder: 'CVV',
                        selector: "#cvv"
                    },
                    ccnumber: {
                        placeholder: 'Credit Card Number',
                        selector: "#ccnumber"
                    },
                    ccexp: {
                        placeholder: 'MM / YY',
                        selector: '#ccexp'
                    }
                },
                googleFont:css_styles.font,
                customCss: `{
                    "background-color": "${css_styles.backgroundColor}",
                    "color": "${css_styles.color}",
                    "font-family": "${css_styles.fontFamily}",
                    "font-size": "${css_styles.fontSize}",
                    "font-weight": "${css_styles.fontWeight}",
                    "line-height": "${css_styles.lineHeight}",
                    "border-width": "${css_styles.borderSize}",
                    "border-style": "${css_styles.borderStyle}",
                    "border-color": "${css_styles.borderColor}",
                    "border-radius": "${css_styles.borderRadius}",
                    "padding": "${css_styles.padding}",
                    "width": "${css_styles.width}",
                    "height": "auto !important"
                }`,                
            });
        }

        let intervalId;
        if (ccRef.current) configureCollectJS();
        else {
            intervalId = setInterval(() => {
                if (ccRef.current) {
                    configureCollectJS();
                    clearInterval(intervalId);
                }
            }, 200);
        }
    
        return () => {
            if (intervalId) clearInterval(intervalId);
        }
    },[isPaymentSetup]);
//#endregion useEffect

    /**checking the status of the card, expiration, and cvv based on validation response from NMI*/
    const checkNMIValidation=(field, status)=>{
        if (field==="ccnumber" && status) setCCValidated(true);
        else if (field==="ccnumber" && !status) setCCValidated(false);
        else if (field==="ccexp" && status) setExpValidated(true);
        else if (field==="ccexp" && !status) setExpValidated(false);
        else if (field==="cvv" && status) setCVVValidated(true);
        else if (field==="cvv" && !status) setCVVValidated(false);
    }

  return (
    <>
        {patron_layout &&
            <Form as={Container} fluid className="i-frame-height">
                <Row ref={ccRef}>
                    <Col sm={12} lg={props.forceMobile?12:6}>
                        <Form.Label>Credit Card Number</Form.Label>
                        <div id="ccnumber"/>
                    </Col>
                    <Col sm={12} lg={props.forceMobile?12:6} xl={props.forceMobile?6:3}>
                        <Form.Label>Expiration</Form.Label>
                        <div id="ccexp"/>
                    </Col>
                    <Col sm={12} lg={props.forceMobile?12:6} xl={props.forceMobile?6:3}>
                        <Form.Label>CVV</Form.Label>
                        <div id="cvv"/>
                    </Col>
                </Row>
                <CartAddressView 
                    forceMobile={props.forceMobile}
                    user={user} 
                    setUserDetails={setUserDetails} 
                    setValidation={setValidation} 
                />
                {submitting ? null : <p className="error-text">{validation}</p>}
                <div className="patron-cart-buttons default-right">
                    {props.otherButtons && <>{props.otherButtons}</>}
                    <Button variant="primary" size="lg" type="submit" id="payButton" disabled={disabled} data-cy="pay-button">
                        {submitting ? 
                            <>
                                <Spinner size="sm" animation="border" variant="light" className="me-1"/> 
                                Processing
                            </>
                        : "Process Secure Payment" }
                    </Button>
                </div>
                <div id="paymentTokenInfo"></div>
                {success}
                {error}
            </Form> 
        }
        {!patron_layout && props.paymentMethod==="SplitPayment" &&
            <>
                <fieldset disabled={props.disabled}>
                    <div className="sp-address-wrapper">
                        <SPAddressView 
                            user={props.user}
                            setUserDetails={setUserDetails}
                            setValidation={setValidation}
                        /> 
                        <div className="cc-fields">
                            <div id="ccnumber" /><span>*</span>
                            <div id="ccexp" /><span>*</span>
                            <div id="cvv" /><span>*</span>    
                        </div>
                        {submitting ? null : <p className="error-text-ctr">{validation}</p>}
                        <div className="">
                            <Button type="submit" id="payButton" disabled={disabled}>
                                {submitting ? 
                                    <span>
                                        <Spinner animation="border" variant="light" /> 
                                        {" "}Processing
                                    </span> 
                                : 
                                <span>Secure Checkout</span> 
                                }
                            </Button>
                        </div>
                        <div id="paymentTokenInfo"></div>
                    </div>
                    {success}
                    {error}
                </fieldset>
            </>
        }
    </>
  )
}

import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../../../store/actions'

import { cancelSplit } from '../../SplitPaymentUtils';
import { set } from 'date-fns';

export const EachPayment = (props) => {

    const {payee: propsPayee ,assignPayeeTotals,cancelSplit,ordersSplit,setAlreadySplit}=props;

    const dispatch = useDispatch();
    const mountedRef = useRef(false);
    const allOrders=useSelector(state=>state.splitPayments.allOrders)
    const [totalPayment, setTotalPayment]=useState();
    const [payee, setPayee]=useState();

    useEffect(()=>{
        mountedRef.current = true;

        return()=>mountedRef.current = false;
    },[]);

    useEffect(()=>{
        if(mountedRef.current){  
            setPayee(props.payee);
            if(totalPayment===undefined || totalPayment===null) setTotalPayment(props?.payee?.amount || 0);
        }
    },[props.payee, totalPayment]);

    useEffect(()=>{
        if(+totalPayment >= 0) {
            assignPayeeTotals(props.payee, totalPayment);
        }
        if(+totalPayment === "" || isNaN(totalPayment)){
            assignPayeeTotals(props.payee, 0);
        }
        if(allOrders.length >0)dispatch(actions.setSplitPaymentAllOrders([])) //resetting because there's a change
    //adding assignPayeeTotals AND.OR props.payee to the dependency array causes an infinite loop.
    //One or the other together both do so
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[totalPayment, dispatch, allOrders.length]);

    const handleChange=(e)=>{
        e.preventDefault();
        setTotalPayment(e.target.value);
        if(props.ordersSplit){
            props.setAlreadySplit(false);
            props.cancelSplit();
            dispatch(actions.setOrdersHaveBeenSplit(false));
        }
    }

  return (
    <div className="sp-each-payment">
        <p>
            <span>
                Payment Portion for {props?.payee?.identifier}:
            </span>
            <input type="number" name="total-amount" value={totalPayment} placeholder={0} onChange={handleChange} />
        </p>
    </div>
  )
}

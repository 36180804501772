import React, { useState, useEffect } from 'react';
import styles from './ImageViewer.module.scss';


/**
 * 
 * @param {*} images array of images that need urls; can also accept a single string of the url as the image.
 * @param {*} objectName for the image and thumbnail alt text - i.e. "representation of {an event}"
 * @param {*} largeImgMax how large you want the selected image to be
 * @param {*} thumbMax how large you want the thumbnails to be
 * @param {*} thumbLimit how many thumbnails you want to be shown to pick from to view larger
 */
export const ImageViewer = ({
    images, 
    objectName="", 
    largeImgMax = "400px",
    thumbMax = "100px", 
    thumbLimit=null}) => {

    const [selectedImage, setSelectedImage ] =useState(0)
    const [imagesForMap, setImagesForMap] = useState()

    useEffect(()=>{
        if(Array.isArray(images)){
            if(thumbLimit){
                setImagesForMap(images.slice(0, thumbLimit))
            }else setImagesForMap(images)
        } 
        if(typeof images === "string") {
            setImagesForMap([{url: images}])
            setSelectedImage(0)
        }
    },[images, thumbLimit])

    return(
        <div className={styles["image-viewer-wrapper"]}>
            {imagesForMap?.length > 0 && 
                <div className={styles["large-image"]}>
                    <img style={{maxWidth: largeImgMax}} src={imagesForMap[selectedImage].url} alt={`selected representation of the event ${objectName}`} />
                </div>
            }
            <div className={styles["img-thumbs"]}>
                {imagesForMap?.length > 1 && imagesForMap?.map((image, index) => (
                    <React.Fragment key={`image-${index}`}>
                        <img 
                            className={selectedImage === index ? styles.selected : null}
                            style={{maxWidth: thumbMax}}
                            src={image?.url} 
                            alt={`a thumbnail representing the event ${objectName}`} 
                            onClick={()=>setSelectedImage(index)}
                        />
                    </React.Fragment>
                ))}
                {(!imagesForMap || (imagesForMap && imagesForMap?.length === 0)) &&
                    <div>
                        No associated images found!
                    </div>
                }
            </div>
        </div>
    )
}
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button} from 'react-bootstrap';

import APIUsers from '../../../../../../api/Users';
import APIPOS from '../../../../../../api/Pos';

export const Step4 = (props) => {
    const {disableNext, disableBack, saveStepValues, goToStep, setSubmitting} = props;

    const ref = useRef();

    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user.profile);
    let defaultPatronRegister = useSelector(state => state.pos.register)
    if (props.register_id) defaultPatronRegister = props.register_id;

    const [loading, setLoading] = useState(true);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [customFields, setCustomFields] = useState();
    const [error, setError] = useState([]);
    const [success, setSucess] = useState([]);

    const saveInfo = useCallback(async () => {
        setLoading(true);
        let _errors = false, _success = false;
        
        const errors = [];
        const items = [];
        const data = []
        for (let seluser of selectedUsers){
            let _data = {
                for_user_id: seluser.id,
                by_user_id: user.id,
                event_id: props.id,
                user_name: `${seluser.first_name} ${seluser.last_name}`,
            };

            items.push({
                variant_id: props.stepValues.variant_id || props.default_variant_id,
                qty: 1,
                event: {
                    event_id: props.id,
                    for_user_id: seluser.id,
                    custom_fields: customFields?.[seluser.id] || []
                }
            });

            if (customFields){
                if (customFields?.[seluser.id]){
                    customFields[seluser.id].forEach(field=>{
                        _data[`custom_${field.custom_field_id}`] = `${field.value}`;
                    });
                }
            }

            data.push(_data);
        }

        // if a payment token is present, create the order
        if (props.stepValues.token){

            if (props.stepValues.token === "FREE") {
                try{
                    for (let _data of data){
                        const res = await APIUsers.eventRegister(_data);
                        if (res?.errors) {
                            errors.push(`Error while saving the event for ${_data.user_name}.`);
                            _errors = true;
                            break;
                        } else _success = true;
                    }
                } catch(error){
                    errors.push("Error while saving the event.");
                    _errors = true;
                }
            } else {
                try{
                    const res = await APIPOS.order.save({
                        register_id: defaultPatronRegister,
                        user_id: user.id,
                        items: items // defined up there ^
                    }).catch(e=>{
                        errors.push("Error while saving the order.");
                        _errors = true;
                    });
                    if (res?.errors){
                        errors.push("Error while saving the order.");
                        _errors = true;
                    } else if (res?.data){
                        try{

                            const payments = [];
                            /*if (giftCardsApplied?.gift_cards?.length > 0) { // gift cards must be sent first
                                giftCardsApplied.gift_cards.forEach(gc=>{
                                    payments.push({
                                        payment_method_id: 4,
                                        amount: gc.current_balance,
                                        card_code: gc.card_code,
                                    });
                                });
                            }*/
                            payments.push({
                                payment_method_id: 1,
                                amount: res.data[0].total_price,
                                type: 'sale',
                                payment_token: props.stepValues.token,
                                description: props.name,
                                first_name: props.stepValues?.paymentFields?.first_name || props.stepValues.first_name || user.first_name,
                                last_name:  props.stepValues?.paymentFields?.last_name || props.stepValues.last_name || user.last_name,
                                bill_address1: props.stepValues?.paymentFields?.bill_address1 || user.address1,
                                bill_address2: props.stepValues?.paymentFields?.bill_address2  || user.address2,
                                bill_city: props.stepValues?.paymentFields?.bill_city || user.city,
                                bill_state: props.stepValues?.paymentFields?.bill_state  || user.state,
                                bill_postalcode: props.stepValues?.paymentFields?.bill_postalcode  || user.postal_code,
                            });

                            const res2 = await APIPOS.payment.process({order_id: res.data[0].id, payments}).catch(e=>{
                                errors.push("Error processing the payment.");
                                _errors = true;
                            });
                            if (res2?.errors){
                                let error_text = res2.errors;
                                if (res2.errors.some(item => item.includes("Duplicate transaction"))) {
                                    error_text = "Our payment processor does not allow identical payments with the same card within a 20 minute period. Please try again later or use a different card.";
                                }
                                if (!Array.isArray(error_text)) error_text = [error_text];                    
                                error_text.map((err, i) => errors.push(<b key={`error-gateway-${i}`} className="error-text">{err}</b>));                    
                                _errors = true;
                                saveStepValues({token: null});
                            } else {
                                /*try{
                                    for (let _data of data){
                                        const res = await APIUsers.eventRegister(_data).catch(e =>{
                                            errors.push(`Error while saving the event for ${_data.user_name}.`);
                                        });
                                        if (res?.errors) errors.push(`Error while saving the event for ${_data.user_name}.`);
                                        else setSucess(prev=>[...prev, "Payment Successful"]);
                                    }
                                } catch(error){
                                    errors.push("Error while saving the event.");
                                    _errors = true;
                                }*/
                                if (props.stepValues?.paymentFields?.bill_savecard) {
                                    await props.stepValues.collectJS.retokenize();

                                    const _token = await props.stepValues.collectJS.tokenPromise;
                                    const res2 = await APIUsers.PaymentProfile.create({
                                        first_name: props.stepValues?.paymentFields?.first_name || props.stepValues.first_name || user.first_name,
                                        last_name:  props.stepValues?.paymentFields?.last_name || props.stepValues.last_name || user.last_name,
                                        bill_address1: props.stepValues?.paymentFields?.bill_address1 || user.address1,
                                        bill_address2: props.stepValues?.paymentFields?.bill_address2  || user.address2,
                                        bill_city: props.stepValues?.paymentFields?.bill_city || user.city,
                                        bill_state: props.stepValues?.paymentFields?.bill_state  || user.state,
                                        bill_postalcode: props.stepValues?.paymentFields?.bill_postalcode  || user.postal_code,
                                        name: "{card}",
                                        payment_token: _token?.token,
                                    });
                                }
                                ref.current = false;
                                _success = true;
                            }
                        } catch(error){
                            errors.push("Error processing the payment.");
                            _errors = true;
                        }
                    }
                } catch(error){
                    errors.push("Error while saving the order.");
                    _errors = true;
                }
            }
        } else {
            errors.push(
                ...["Please check your credit card numbers or try again with a different one.", 
                <Button variant="primary" className="mt-3" onClick={e=>{
                    disableNext(false);
                    goToStep(e,3);
                }}>Try Again</Button>]
            );
            _errors = true;
            if (props.stepValues?.collectJS) props.stepValues.collectJS.retokenize();
        }

        setLoading(false);
        disableNext(true);
        if (!_errors) disableBack(true);
        else {
            setError([...new Set(errors)]);
            disableBack(false);
            setSubmitting(false);
        }
    }, [props, selectedUsers, customFields, user, defaultPatronRegister, disableNext, disableBack, saveStepValues, goToStep, setSubmitting]);

    useEffect(() => {
        if (props.stepValues?.selectedUsers) setSelectedUsers(props.stepValues.selectedUsers);
        else setSelectedUsers([{id: user.id, first_name: user.first_name, last_name: user.last_name}]);
        if (props.stepValues?.customFields) setCustomFields(props.stepValues.customFields);
    }, [user, props.stepValues]);

    useEffect(() => {
        if (ref.current) saveInfo();
    }, [saveInfo, ref]);

    useEffect(() => {
        ref.current = true;

        return () => {
            setLoading(false);
        }
    }, []);

    if (loading) return (<p>Processing...</p>);
    if (error.length>0) return (
        <>
            <p>
                {success.length>0 && "An error ocurred while processing your registration. Please contact us for more information."}
                {!success.length>0 && "An error ocurred while processing your registration. Please try again later."}
            </p>            
            Error details:
            <ul>
                {error.map((err, i)=>{
                    if (typeof err === "string") return (<li key={`event-payment-error-${i}`}><small key={`err-${i}`}>{err}</small></li>);
                    else return (<div key={`event-payment-error-${i}`}>{err}</div>);
                })}
            </ul>
        </>
    );

    return (
        <>
            {props.confirmation_msg && <div dangerouslySetInnerHTML={{__html: props.confirmation_msg}}/>}
            {!props.confirmation_msg &&
                <p>
                    Thank you for registering.
                </p>
            }
        </>
    );
}
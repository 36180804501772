import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';

import styles from "./Buttons.module.scss";
export const Buttons = (props) => {
    const {onClose, onSave, onComplete, selectedPaymentMethod} = props;

    const closeHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (+props.orderStatus === 2) onSave(e);
        else onClose(e);
    }

    let button_label = 
        selectedPaymentMethod === "TERMINAL" ? "Scan Card" : 
        selectedPaymentMethod === 1 ? "Process Credit Card" : 
        props.totals.balance > props.totals.to_be_paid ? "Process Partial Payment" : 
        props.totals.balance <= props.totals.to_be_paid ? "Complete Sale" : 
        "...";

    if (props.forceEnable && props.totals.to_be_paid <= 0) button_label = "Complete Sale";

    return (
        <Container fluid className={`m-0`}>
            <Row className="m-0">
                <Col sm={props.orderStatus === 2 ? 12 : 4} className="p-0 col-auto d-flex">
                    <Button
                        variant="light"
                        onClick={closeHandler}
                        data-cy="payment-cancel-button"
                        type="button"
                        data-type="close"
                        disabled={props.submitting}
                        className={`${styles.button} btn-cancel w-auto${props.submitting?" submitting":""}`}
                    >
                        {+props.orderStatus === 2 ? "Close" : "Cancel"}
                    </Button>
                    {+props.orderStatus !== 2 &&
                        <Button
                            variant="light"
                            onClick={onSave}
                            type="button"
                            data-type="save"
                            data-cy="payment-save-button"
                            disabled={props.submitting}
                            className={`${styles.button} btn-cancel w-auto${props.submitting?" submitting":""}`}
                        >
                            Save
                        </Button>
                    }
                </Col>
                {+props.orderStatus !== 2 &&
                    <Col sm={8} className="p-0 col-auto">                    
                        <Button 
                            className="pos-button-checkout m-0 w-100"
                            data-cy="details-add-to-cart"
                            disabled={props.submitting || (!props.forceEnable && +props.totals.to_be_paid <=0)}
                            onClick={onComplete}
                        >
                            {button_label}
                        </Button>                    
                    </Col>
                }
            </Row>
        </Container>
    );
}
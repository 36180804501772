import React, {useState, useEffect, useCallback, useRef} from 'react';
import { useSelector } from 'react-redux';
import {Container, Button, InputGroup, Form} from 'react-bootstrap';

import Toast from '../../../../../../../components/Toast';
import APIPOS from '../../../../../../../api/Pos';


export const Email = (props) => {
    const {onClose} = props;

    const ref = useRef();

    const user = useSelector(state=>state.pos[props.order.register_id]?.user);

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    const sendHandler = useCallback(async e => {
        e.preventDefault();

        const email = ref?.current?.value;
        if (!email) return;

        try {
            setSubmitting(true);
            const res = await APIPOS.payment.sendReceipt({transaction_id: props.transaction.id, order_id: props.order.id, email});
            if (res.errors) setError(res.errors);
            else if (res.data) {
                setError(null);
                setSuccess(<Toast>Receipt Sent Successfully!</Toast>);
            }
            setSubmitting(false);
        } catch(e){
            setError(e.message);
            setSubmitting(false);
        }

    }, [props.transaction, props.order]);

    useEffect(() => {
        if (success && onClose){
            setTimeout(()=>onClose(), 3000);
        }
    }, [success, onClose]);

    if (!props.transaction || !props.order) return null;

    return (
        <>
            {success}
            <Container fluid>
                <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" ref={ref} defaultValue={user.email || ""} />
                </Form.Group>
                <Button variant="secondary" onClick={sendHandler} disabled={submitting}>Send</Button>
            </Container>
            {error &&
                <div className="error-text">{error}</div>
            }
        </>
    );
}
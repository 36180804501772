import APIEvents from '../../../../../../api/Events';
import store from '../../../../../../redux-store';
import {updateCart} from '../../../../../../utils/thunks';


//Add the services into one item in the summary
const condenseServices=(service)=>{
    //All the tokens will have the same data, just using first one[0] to get it
    let tokenTotal=service.length;
    let totalPrice=(service[0].base_price*tokenTotal).toFixed(2);
    let totalTax=(service[0].tax*tokenTotal)
    const serviceItem={
        token_total:+tokenTotal,
        final_price:+totalPrice,
        base_price: service[0].base_price,
        product_name:service[0].product_name,
        product_id:service[0].product_id,
        tax:+totalTax,
        event: service[0].event,
        product_type_id: service[0].product_type_id
    }
    return serviceItem;
};
    
//Appends the event details for itm summary to items
const getEventDetails = async (items_original) => {

    let items = JSON.parse(JSON.stringify(items_original));
    let eventList = [];
    items.forEach(item => {
        if (item?.event?.event_id) {
            if (!eventList.includes(item.event.event_id)) eventList.push(item.event.event_id);
        }
    });

    if (eventList.length>0){
        // get event details
        const response = await APIEvents.getSimple({id: eventList});
        const events = response.data?.events;
        if (response.data && !response.errors && events) {
            items.forEach(item => {
                if (item?.event?.event_id) {
                    // find the appropriate event and assign the event info
                    item.event_details = events.find(event => event.id === item.event.event_id);
                    if (item.event_details===-1) {
                        item.event_details = "Details Currently Unavailable";
                    }
                }
            });
        }
    }
    return items;
};

//get sets of service items and put the rest in the array as normal
const sortServices=(items_original)=>{
    let items = [...items_original];
    let tempItems=[];
    let tempService=[];

    //removing everything but the services
    for(let i =0; i<items?.length; i){
        if(items[i].product_type_id !== 9) {
            tempItems.push(items[i])
            items.splice(i, 1)
        }
        else i++;
    }

    //group a service together
    for(let i =0; i<items?.length; i){
        if(items[i].product_type_id === 9) {
            tempService.push(items[i]);
            items.splice(i, 1)
        } else i++
        for(let j=0; j<items.length; j){
            if(items[i].product_type_id===9 && items[i].product_name ===items[j].product_name && items[i].product_id===items[j].product_id){
                tempService.push(items[j])
                items.splice(j, 1)
            }else j++
        };
        let condensed;
        if(tempService.length>0) condensed=condenseServices(tempService);
        if(condensed) tempItems.push(condensed)
        tempService=[];
    }
    return tempItems;
};

export const processOrder = async (original_order, register_id) => {
    const _order = store.getState().pos?.[register_id]?.items;
    let order = {};    
    if (original_order) {
        const orderCopy = {...original_order};
        if (original_order.items?.length>0) {

            // Add the hash to the items
            let items = [...original_order.items];
            if (_order) {
                _order.forEach(_item => {
                    const _itemIndex = items.findIndex(item => +item.id === +_item.id && +item.variant_id === +_item.variant_id && !item.hash);
                    if (_itemIndex > -1) items[_itemIndex].hash = _item.hash;
                });
            }

            // Condense Service tokens to one "item"
            const itemsWithServices = sortServices(items);
            const itemsWithServicesAndEvents = await getEventDetails(itemsWithServices);
            orderCopy.items = [...itemsWithServicesAndEvents];
            order = {...orderCopy};
        }
    }
    return order;
};

export const removeItem = async (order_items, item_id, register_id, user_id, callback) => {
    if (order_items) {
        const itemsCopy = JSON.parse(JSON.stringify(order_items));
        if (itemsCopy?.length>0) {
            const _item = itemsCopy.filter(item => +item.id === +item_id);
            store.dispatch({type: 'POS_REMOVE_ITEM', register_id: register_id, items: {id: _item[0].id, variant: _item[0].variant_id, type: _item[0].product_type_id, hash: _item[0].hash}});
            store.dispatch(updateCart(register_id, user_id, callback));
        }
    }
};

export const updateItemQty = async (order_items, item_id, qty, register_id, user_id, callback) => {
    if (qty === 0){
        removeItem(order_items, item_id, register_id, callback);
        return;
    }
    
    if (order_items) {
        const itemsCopy = JSON.parse(JSON.stringify(order_items));
        if (itemsCopy?.length>0) {
            const _item = itemsCopy.filter(item => +item.id === +item_id);
            store.dispatch({type: 'POS_UPDATE_QTY', register_id: register_id, qty: qty, item: {id: _item[0].id, variant: _item[0].variant_id, type: _item[0].product_type_id, hash: _item[0].hash}});
            store.dispatch(updateCart(register_id, user_id, callback));
        }
    }
};

export const demoOrder = () => {
    return {
        "id": 123,
        "order_status_id": 1,
        "total_price": 25.99,
        "subtotal_price": 24.99,
        "tax_total": 0,
        "shipping_total": 0,
        "admin_fee_total": 1,
        "admin_fee_refunded": 0,
        "payment_method_id": null,
        "payment_method": null,
        "tip": 0,
        "items": [
            {
                "id": 1,
                "total_refunded_amount": 0,
                "shipping_refunded_amount": 0,
                "refund_memo": null,
                "product_id": 1,
                "product_name": "Demo Service",
                "product_description": "This is a demo service",
                "product_type_id": 3,
                "product_image": "https://siteboss.s3.amazonaws.com/themes/1/logo-short.svg",
                "variant_id": 1,
                "variant_name": "Default",
                "addons": [],
                "base_price": 20,
                "final_price": 20,
                "tax": null,
                "shipping": null,
                "quantity": 1,
                "is_taxable": 0,
                "is_shippable": null,
                "valid_until": null
            },
            {
                "id": 2,
                "total_refunded_amount": 0,
                "shipping_refunded_amount": 0,
                "refund_memo": null,
                "product_id": 2,
                "product_name": "Demo Product",
                "product_description": "This is a demo product",
                "product_type_id": 1,
                "product_image": "https://siteboss.s3.amazonaws.com/themes/1/logo-short.svg",
                "variant_id": 2,
                "variant_name": "Default",
                "addons": [],
                "base_price": 4.99,
                "final_price": 4.99,
                "tax": null,
                "shipping": null,
                "quantity": 1,
                "is_taxable": 0,
                "is_shippable": null,
                "valid_until": null,
                "bill_interval": "m",
                "interval_quantity": null,
                "activation_fee": "0.00",
                "bill_num_times": null,
                "subscription_type_id": null,
                "subscription_type_name": null,
                "subscription_max_users": 1
            }
        ],
        "coupon_codes": [],
        "coupons_applied": [],
        "total_discount": 0,
        "split_order_ids": [],
        "transactions": []
    }
}
import React, { useState, useEffect } from 'react';
import { Tooltip as BSTooltip, OverlayTrigger } from 'react-bootstrap';
import styles from './Tooltip.module.scss';

/**Tooltip is a pop up bubble that can be positioned and have custom text.  
 * 
 * @param props.direction - set "bottom", "top", "right", or "left".  If none are passed in, will default to top.
 * @param props.text - the text that you want to display in your tooltip.  Will determine the width of the tooltip as well.
 * @param props.height - if you want to customize the height of your tooltip, otherwise defaults to 30px.  Format as "30px";
 * @param props.width - if you want to customize the width of your tooltip - defaults to 600px otherwise.  Format as "30px"; 
 * @param props.mobile - defaults as ({height: "100px", width: "100px", direction:"bottom" }).  Must include all three parameters for mobile styling.
 * @param props.breakpoint - optional.  Otherwise mobile defaults to 500 or less
 */
export const Tooltip = (props) => {

    const [ active, setActive ]=useState(false);
    const [ height, setHeight ]=useState("30px");
    const [ width, setWidth ]=useState("600px");
    const [ windowSize, setWindowSize ]=useState(window.innerWidth);
    const [ isMobile, setIsMobile ]=useState(false);
    const [ mobile, setMobile ]=useState({
        width: "100px",
        height: "200px",
        direction: "bottom",
    })

    useEffect(()=>{
        let mounted=true;
        let breakpoint=501
        if(props.breakpoint) breakpoint = props.breakpoint
        if(windowSize < breakpoint && mounted === true) {
            setIsMobile(true);
            if(props.mobile)setMobile({
                height: props.mobile.height,
                width: props.mobile.width,
                direction: props.mobile.direction
            })
        }
        
        return()=>{
            mounted =false
        }
    },[windowSize, props.breakpoint, props.mobile]);

    useEffect(()=>{
        let mounted = true

        if(props.height && mounted === true) setHeight(props.height);
        if(props.width && mounted === true) setWidth(props.width);

        return()=> mounted = false
    },[props.height, props.width]);

    return (
        <>
        <OverlayTrigger
            placement={props.direction || "top"}
            overlay={
                <BSTooltip id="button-tooltip">
                    {props.text}
                </BSTooltip>
            }
        >
            {props.children}
        </OverlayTrigger>
        {/*<span 
            className={styles["tooltip-wrapper"]}
            onMouseEnter={()=>setActive(true)}
            onMouseLeave={()=>setActive(false)}
        >
            {props.children}
            {active && isMobile===false &&
                <div style={{height: height, width:width}} className={`${styles["tooltip-text"]} ${styles[props.direction || "top"]}}`}>
                    {props.text}
                </div>
            }
            {active && isMobile===true &&
                <div style={{height: mobile.height, width: mobile.width }} className={`${styles["tooltip-text"]} ${styles[mobile.direction]}`}>
                    {props.text}
                </div>
            }
        </span>*/}
        </>
    );
}


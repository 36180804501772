import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import { Button, Col, Container, Form } from 'react-bootstrap';
import Users from '../../../api/Users';
import ErrorCatcher from '../../../components/common/ErrorCatcher';
import { FileURLContext } from '../../../contexts/FileURLContext';

import '../Login/Login.scss';

const ResetPassword = (props) => {
    const imageURL = useContext(FileURLContext);
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [body, setBody]=useState();

    // form submission
    const submitHandler = async (e) => {

        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setValidated(true);
        setSubmitting(true);
        setError(null);

        if (form.checkValidity() === true) {
            const formData = new FormData(e.target);
            formData.append("token", new URLSearchParams(window.location.search).get("guid"));
            const formDataObj = Object.fromEntries(formData.entries());

            await Users.resetPassword(formDataObj)
            .then(response => {
                setSubmitting(false);
                if (!response.errors){
                    setBody(
                        <React.Fragment>
                            <h1>Success</h1>
                            <p>Your password has been reset.</p>
                            <p><Link to="/">Back to Sign In</Link></p>
                        </React.Fragment>
                    );
                } else {
                    setError(<ErrorCatcher error={response.errors} />);
                }
            }).catch( e => {
                setError(<ErrorCatcher error={e} />);
                setSubmitting(false);
            });
        } else setSubmitting(false);
    };


    if (!body){
        setBody(
            <React.Fragment>
            <h4 className="section-title">Reset Password</h4>
            <Form className="my-5" noValidate validated={validated} onSubmit={submitHandler}>
            <Form.Row>
                <Form.Group controlId="username" className="ani-input" >
                    <Form.Control required type="password" name="password"  id="password" placeholder="Enter your new password" />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group controlId="username" className="ani-input" >
                    <Form.Control required type="password" name="password_confirmation"  id="password2" placeholder="Re-type your new password" />
                </Form.Group>
            </Form.Row>
            <Form.Row>
            <Button type="submit" disabled={submitting} className={`${submitting?"submitting":""}`}>Submit</Button>
            </Form.Row>
                {/* <Col className="col-pass-reset" lg="6">
                    <Form.Row>

                        <Form.Label htmlFor="password">
                            Password
                        </Form.Label>
                        <Form.Control  required type="password" className="mx-2" name="password" id="password" placeholder="Enter your password"
                        />
                    </Form.Row>
                    <Form.Row>
                        <Form.Label htmlFor="password2">
                            Password
                        </Form.Label>
                        <Form.Control  required type="password" className="mx-2" name="password_confirmation" id="password2" placeholder="Re-type your password"
                        />
                    </Form.Row>
                    
                    <Button type="submit" disabled={submitting} className={`${submitting?"submitting":""}`}>Submit</Button>
                </Col> */}
            </Form>
            </React.Fragment>
        );
    }

    return (
        <div id="login" className="login-register">
            {imageURL.backgroundImage?.endsWith(".mp4") &&
                <video autoPlay muted loop id="bgVideo">
                    <source type="video/mp4" src={imageURL.backgroundImage} />
                </video>
            }
            <section className="banner-wrapp login-banner" style={!imageURL.backgroundImage?.endsWith(".mp4") ? {backgroundImage:`url(${imageURL.backgroundImage})`} : null}>
                <div className="l-con-wrap container">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div className="banner-content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="hid-mob">
                                                <h4 className="dash-title-login">{imageURL.companyName}</h4>
                                                <h1 className="portal-he">Reset Password</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                            <div className="auth-form-wrap">
                                <div className="logo"/>
                                {body}
                                {error}                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div> 
    );
}

export default ResetPassword;
import React from 'react';

import PrintFullPage from '../../../Print/PrintFullPage';

export const Print = (props) => {
    const {printKitchenReceipt, showPrint, setShowPrint} = props;

    let content = null;
    if (printKitchenReceipt === "kitchen"){
        content =
            <div style={{pageBreakBefore: "always"}}>
                <div className="page-break" />
                <PrintFullPage preview type={printKitchenReceipt} order={props.order} items={props.order.items} />
            </div>
    }

    return (
        <>
            {showPrint && typeof showPrint !== "string" && 
                <PrintFullPage 
                    print
                    //preview
                    type="receipt" 
                    order={props.order} 
                    items={props.order.items}
                    transaction={showPrint || null}
                    //parentOrder={props.order}
                    onAfterPrint={()=>setShowPrint(null)}
                />
            }
            {(showPrint === "receipt" || showPrint === "invoice" || showPrint === "kitchen") &&
                <>
                    <PrintFullPage 
                        print
                        //preview
                        type={showPrint}
                        order={props.order}
                        items={props.order.items}
                        transaction={null}
                        //parentOrder={props.order}
                        //onAfterPrint={()=>setShowPrint(showPrint === "receipt" ? printKitchenReceipt : null)}
                        onAfterPrint={()=>setShowPrint(null)}
                        extraContent={showPrint === "receipt" ? content : undefined}
                    />
                </>
            }
        </>
    );

}
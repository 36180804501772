import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { randomUUID } from '../../../../../utils/cms';
import styles from './Slider.module.scss';

const is_carousel = 0;

const Slider =  React.forwardRef((props, ref) => {
    const {'custom-height': custom_height} = props;

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [step, setStep] = useState(1);
    const [height, setHeight] = useState();
    const [paginationPosition, setPaginationPosition] = useState([]);
    const [transitionType, setTransitionType] = useState("left");
    const [animate, setAnimate] = useState(false);
    const [direction, setDirection] = useState(-1);

    const transitionTime = props.transition_time || 1000;

    const children = useMemo(() => {
        let _ch = React.Children.toArray(props.children).filter(child => child.props?.slide_number || child.props?.element_id?.toLowerCase() === "slide") || [];
        if (!props.is_preview) _ch = props.transition_type === "right" || props.transition_type === "down" ? [..._ch].reverse() : _ch;
        //if (step < 1) setStep(1);
        //if (_ch.length && step > _ch.length) setStep(_ch.length);
        return (_ch?.map((child, i) => React.cloneElement(child, {
            current_step: step, 
            selected_step: i+1,
            steps: _ch.length,
            custom_height: custom_height, 
            active_class: styles.active, 
            transition_type: props.transition_type || "none",
            transition_time: transitionTime,
            is_preview: props.is_preview,
            is_carousel: is_carousel,
        })) || null);
    }, [props.children, props.transition_type, props.is_preview, custom_height, step, transitionTime]);

    useEffect(() => {
        let _height = custom_height?.match(/\d+/g)?.map(Number)?.[0] || 0;
        if (children?.length>0 && props.is_preview){
            _height = (_height + 48) * (children.length || 1);
        }
        setHeight(_height);
    },[children, custom_height, props.is_preview]);

    useEffect(() => {
        if (props.pagination_position) setPaginationPosition(props.pagination_position.split("-"));
    }, [props.pagination_position]);

    useEffect(() => {
        if (props.transition_type) {
            setTransitionType(props.transition_type);
            if (props.transition_type === "right" || props.transition_type === "down") setDirection(1);
        }
    }, [props.transition_type]);

    useEffect(() => {
        if (animate) {
            const timerId = setTimeout(() => {
                setAnimate(prev=> !prev);
            }, transitionTime);
            return () => clearTimeout(timerId);
        }
    }, [animate, transitionTime]);

    useEffect(() => {
        return () => {
            setStep(1);
            setHeight(null);
            setPaginationPosition([]);
            setAnimate(false);
            setTransitionType("left");
        }
    }, []);

    const transform = useMemo(() => {
        let _mv = (step-1) * (100/(children?.length || 1)) * direction;
        let _t;
        if (!is_carousel){
            switch (transitionType){
                case "left":
                    _t = `translateX(${_mv}%)`;
                    break;
                case "right":
                    if (!props.is_preview) _mv = ((children?.length || 1) - step) * (100/(children?.length || 1)) * -1;
                    _t = `translateX(${_mv}%)`;
                    break;
                case "up":
                    _t = `translateY(${_mv}%)`;
                    break;
                case "down":
                    if (!props.is_preview) _mv = ((children?.length || 1) - step) * (100/(children?.length || 1)) * -1;
                    _t = `translateY(${_mv}%)`;
                    break;
                default:
                    break;
            }
        }
        return _t;
    }, [transitionType, step, children, direction, props.is_preview]);

    const clickHandler = useCallback((e, _step) => {
        e.preventDefault();
        if (!animate && !props.is_preview){
            if (_step <= 0) _step = 1;
            if (_step > children.length) _step = children.length;
            if (_step === step) return;

            setStep(_step);
            setAnimate(true);
        }
    }, [animate, children.length, step, props.is_preview]);

    const arrowClickHandler = useCallback((e, direction = "next") => {
        e.preventDefault();
        if (!animate && !props.is_preview){
            let _step = step;
            if (direction === "next"){
                _step++;
                if (_step > children.length) _step = 1;
            } else {
                _step--;
                if (_step <= 0) _step = children.length;
            }
            if (_step === step) return;

            setStep(_step);
            setAnimate(true);
        }
    }, [animate, children.length, step, props.is_preview]);
    
    const _renderArrows = useCallback(() => {
        if (!props.arrows|| !children?.length || children.length === 1) return null;
        if (props.arrows){
            const _h=(+custom_height?.match(/\d+/g)?.map(Number)?.[0] || 48);
            return (
                <>
                    <a href="#!" variant="link" onClick={(e)=>{arrowClickHandler(e,"back")}} className={styles["arrow-left"]} style={{height:_h, width: _h/6, maxHeight: custom_height}}> </a>
                    <a href="#!" variant="link" onClick={(e)=>{arrowClickHandler(e,"next")}} className={styles["arrow-right"]} style={{height:_h, width: _h/6, maxHeight: custom_height}}> </a>
                </>
            );
        }
    }, [props.arrows, children, custom_height, arrowClickHandler]);

    useEffect(() => {
        if (!props.is_preview && props.autoplay && !animate){
            const timerId = setTimeout(() => {
                arrowClickHandler({preventDefault:()=>{}}, "next");
            }, props.autoplay_delay || 10000);
            return () => clearTimeout(timerId);
        }
    }, [props.autoplay, props.autoplay_delay, animate, props.is_preview, arrowClickHandler]);


    const _renderPagination = useCallback(() => {
        if (!paginationPosition.length || !children?.length || children.length === 1) return null;        
        if (paginationPosition.length>0){
            return (
                <div className={`${styles["pagination-wrapper"]} ${paginationPosition[0]==="top" ? styles.top : styles.bottom} ${paginationPosition[1]==="center" ? styles.center : paginationPosition[1]==="right" ? styles.end : ""}`.trim().replace(/\s+/g,' ')}>
                    {children.map((child, i) =>(
                        <a className={styles[props.pagination]} key={`slider-paginaton-${i}`} href="#!" onClick={(e)=>clickHandler(e, i+1)}>
                            {props.pagination==="numbers" && <>{i+1}</>}
                        </a>
                    ))}
                </div>
            );
        }
    }, [paginationPosition, children, props.pagination, clickHandler]);

    return (
        <div 
            id={props.is_preview ? `preview-${randomUUID()}` : props?.id} 
            ref={ref} 
            className={`${styles.slider} slider_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' ')} 
            style={{...props.style, height: height || "200px"}}
            onClick={preview_click}
        >
            <div 
                className={`${styles["slider-wrapper"]} ${transitionType==="fade" && !props.is_preview?styles.fade:styles.slide} ${animate ? styles.animate : ""} ${(props.is_preview || transitionType === "up" || transitionType === "down") ? "flex-column" : "flex-row"}`.trim().replace(/\s+/g,' ')} 
                style={{
                    minHeight: height * (!props.is_preview && (transitionType === "up" || transitionType === "down")?children?.length || 1:1) || "200px",
                    width: `${(!props.is_preview &&  ((transitionType === "left" || transitionType === "right") && !is_carousel)?(children?.length || 1) * 100:100)}%`, 
                    transform: transform || undefined
                }}
            >
                {children}
            </div>
            {_renderArrows()}
            {_renderPagination()}
        </div>
    );  
});

export default Slider;
import * as actionTypes from './actionTypes';

export const setCurrentService = (service) => {
    return {
        type: actionTypes.SERVICEWIZARD_SET_CURRENT_SERVICE,
        service: service
    };
};

export const setServiceWizardErrors = (errors) => {
    return {
        type: actionTypes.SERVICEWIZARD_SET_ERRORS,
        errors: errors
    };
};

export const resetServiceWizardErrors = () => {
    return {
        type: actionTypes.SERVICEWIZARD_RESET_ERRORS
    };
};

export const setServiceTokenData = (token_data) => {
    return {
        type: actionTypes.SERVICEWIZARD_SET_TOKEN_DATA,
        token_data: token_data
    };
};

export const setServiceLocationData = (location_data) => {
    return {
        type: actionTypes.SERVICEWIZARD_SET_LOCATION_DATA,
        location_data: location_data
    };
};
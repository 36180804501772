import React, { lazy, Suspense, useEffect, useState, useRef } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import RouteLoadingError from './RouteLoadingError';
import Login from '../../containers/User/Login';
import Forgot from "../../containers/User/Forgot";
import ResetPassword from "../../containers/User/ResetPassword";
import SetNameAndPass from "../../containers/User/SetNameAndPass";
import Home from "../../containers/Home";
import Register from "../../containers/User/Register";
import NoMatch from "../common/NoMatch";
import AcceptInvitation from "../../containers/Group/AcceptInvitation";
import LocationPrinter from "../../containers/POS/Print/LocationPrinter";
import { saveLogging } from "../../utils/thunks";

import { lazyWithRetry } from '../../utils/lazyWithRetry';

import RoutesAPI from '../../api/Routes';

import * as actions from '../../store/actions';

//Paths that should not be remembered to be redirected to after registration (i.e. if a user goes to "/forgot-password" and then registers a new user, it would not redirect them back to "/forgot-password")
const doNotRemember = ["/", "/login", "/signout", "/reset-password", "/p/signup", "/p/forgot-password", "/p/forgot-user", "/p/set-name-pass", "/p/reset-password", "/accept-invitation" ] 

// loads the routes
export const Routes = (props) => {
    const mountedRef = useRef(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const logged_in = useSelector(state => state.auth.logged_in);
    const registrationRedirect=useSelector(state=>state.auth.registration_redirect);

    const [routesJson, setRoutesJson] = useState(null);
    const [error, setError] = useState(false);

    const parentProps={...props};

    // get the routes json from the backend
    useEffect(()=>{
        mountedRef.current = true 

        const getRoutes = async () => {
            setError(false)
            try {
                let response = await RoutesAPI.get({});
                // reorder by url so that urls with /:id or similar are at the bottom
                response.data.sort((a, b) => {
                    if (a.url < b.url) return 1;
                    if (a.url > b.url) return -1;
                    return 0;
                });
                if (mountedRef.current && response.status === 200) {
                    setRoutesJson(response.data);
                } else if (mountedRef.current && response.status !== 200) {
                    console.error(response);
                    setError(true);
                    dispatch(saveLogging(`ERROR GETTING ROUTES: ${response.status} ${response.message} User: {{USER_ID}}`));
                }
            } catch (error) {
                console.error(error);
            }
        }
        if (logged_in) {
            getRoutes();
        }
        
        return ()=> mountedRef.current = false
    },[dispatch, logged_in]);

    //This saves links for registration and routing.  It will only add simple search.
    //The routes we have that use hash do not load properly if gone to directly (such as /profile#Transactions).  Doing so skips loading components that loads the data needed
    //The redirection from these also does not work properly in just putting them in directly in the search bar.
    useEffect(()=>{
        if(!doNotRemember.includes(history.location.pathname)===true) {
            let localPath = history.location.pathname;

            //If a url has a search parameter (i.e. ?stuffstuff), will add that to the route
            if(history.location.search !== "") localPath+=history.location.search

            dispatch(actions.setAuthRegistrationRedirect({path: localPath }))
        }
    },[history.location, dispatch]);


    // show a different list for logged in users and not
    if (logged_in){
        return (
            <Suspense fallback={             
                <SkeletonTheme color="#e0e0e0">
                    <Skeleton height={30} style={{marginBottom:"1rem"}} />
                    <Skeleton height={12} count={5} />
                </SkeletonTheme>
            }>

            {!routesJson ?
                <>
                    {error && 
                        <RouteLoadingError />
                    }
                    {/* in case there are errors, let the user logout to reset localstorage vars, etc. */}
                    <Route key="rtr-signout" path="/signout" render={ (props) => {
                        return <Login.Logout logged={parentProps.userUpdate} resetTheme={parentProps.resetTheme} {...props} />
                    } } />
                </>
            :
                <Switch>
                    
                    { /* these are common routes */ }
                    <Route key="rtr-index" exact path="/p" component={Home} />
                    <Route key="rtr-home" exact path="/p/home" component={Home} />
                    <Route key="rtr-signout" path="/signout" render={ (props) => {
                        return <Login.Logout logged={parentProps.userUpdate} resetTheme={parentProps.resetTheme} {...props} />
                    } } />

                    <Route exact path="/p/signup" component={Home} />
                    <Route exact path="/p/forgot-password" component={Home} />
                    <Route exact path="/p/forgot-user" component={Home} />
                    <Route exact path="/p/set-name-pass" component={Home} />
                    <Route exact path="/p/reset-password" component={Home} />
                    <Route path="/authcode" component={Home} />

                    <Route exact path="/print/location" component={LocationPrinter} />

                    {routesJson?.map((route, i)=>{
                        let Component = null;
                        if (route.component_url) {
                            try {
                                Component = lazyWithRetry(() => import(`../../${route.component_url}`));
                                return <Route key={`rtr-${route.component_url+i}`} exact={route.exact} path={`${route.url}`} render={(props) => <Component {...route.props} />} />
                            } catch(error) {
                                console.error(error);
                                return null;
                            }
                        } else return null;
                    })}

                    { /* redirects */ }
                    {/* <Route exact path="/booking" render={() => (<Redirect to="/events/wizard" />)} />   */}

                    <Route key="rtr-nomatch" component={NoMatch} />
                </Switch>
            }

            </Suspense>
        );
    } else {
        return (
            <Suspense fallback={             
                <SkeletonTheme color="#e0e0e0">
                    <Skeleton height={30} style={{marginBottom:"1rem"}} />
                    <Skeleton height={12} count={5} />
                </SkeletonTheme>
            }>
            <Switch>
                <Route exact path="/" render={ (props) => <Login.Login {...props} logged={parentProps.userUpdate} /> } />
                <Route exact path="/p/signup" render={ (props) => <Register {...props} logged={parentProps.userUpdate} /> } />
                <Route exact path="/p/forgot-password" component={Forgot.Password}  />
                <Route exact path="/p/forgot-user" component={Forgot.User}  />
                <Route exact path="/p/set-name-pass" component={SetNameAndPass} {...props} logged={parentProps.userUpdate} />
                <Route exact path="/p/reset-password" component={ResetPassword} />
                <Route path="/signout" render={ (props) => <Login.Logout {...props} logged={parentProps.userUpdate} /> } />
                <Route path="/authcode" render={ (props) => <Register authcode={true} {...props}  logged={parentProps.userUpdate} /> } />
                <Route exact path="/accept-invitation" component={AcceptInvitation} />
                <Route exact path="/print/location" component={LocationPrinter} />
 
                {!logged_in && registrationRedirect && <Login.Login {...props} logged={parentProps.userUpdate} />}
                <Route component={NoMatch} />
            </Switch>                
            </Suspense>
        );
    } 
}
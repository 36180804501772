import ErrorCatcher from "../components/common/ErrorCatcher";
import Toast from "../components/Toast";

export function parseErrorToString (error) {
    // console.log(parseErrorToArray(error));
    return parseErrorToArray(error).join("\n");
}

export function parseErrorToArray (error) {
    let contents = [];
    if(error.message) contents.push(error.message);
    else if(typeof(error.data) === "string") contents.push(error.data);
    else if(error.data?.error) contents.push(error.data.error);
    else if(typeof(error)==="string") contents.push(error);
    else if(error.length > 0) {
        contents = [...contents, ...getErrorValues(error, contents)];
    }
    else if(error?.validation) {
        contents = [...contents, ...getErrorValues(error.validation, contents)];
    }
    return contents;
}

//recursively get all values in the errors object, regardless of depth
//TODO: remove before launch, too much error info to be useful to users
export const getErrorValues = (error, contents=[]) => {
    const values = Object.values(error);
    if(typeof(values) !== "string") {
        values.forEach( (value, i) => {
            value && typeof(value) === "object" ? getErrorValues(value, contents) : contents.push(value);
        });
    }
    return contents;
}


export const setErrorCatcher=(error, warning=false, title)=>{
    if(error) {
        return <ErrorCatcher error={error} warning={warning} title={title}/>
    }
    else return null;
}

export const setSuccessToast=(success)=>{
    if(success){
        return <Toast>{success}</Toast>
    }else return null
}

/**Returns a random string for a password */
export const autoGeneratePasswordHandler = () => {
    const length = 15;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-!@#$%^&*(){}[]<>?";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }        
    return retVal;
}
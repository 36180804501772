import React from 'react'
import { Button } from 'react-bootstrap';

import Order from '../../../../api/Order';
// import Pos from '../../../../api/Pos'
import styles from '../OnlinePickup.module.scss';

export const ChangeStatus = ({
    item=null, 
    status=null, 
    statusText="", 
    active=false, 
    large=false, 
    setActiveStatus=null,
    handleSuccess=(response)=>console.log("success", response),  
    handleError=(response)=>console.error("failure", response)
})=>{

    const handleStatusChange=async()=>{
        if(setActiveStatus) setActiveStatus(status)
        try{
            let response = await Order.Statuses.logStatus({order_id: item.id, order_status_id: status })
            if(response.status === 200){
                handleSuccess(status)
            }else handleError()
        }catch(ex){
            console.error(ex)
        }
    }


    return(
        <>
            <Button 
                className={`${active ? "" :  `${styles["status-btn"]}`} ${large ? `${styles["large-btn"]}` : ""}`} 
                variant={active ? "secondary" : "primary"} 
                onClick={handleStatusChange}
            >
                {statusText}
                <br />
            </Button>
        </>
    )
}
import React,{useState, useEffect} from 'react';
import {Form as BSForm, Modal} from 'react-bootstrap';
import {generateHash, randomUUID} from '../../../../../utils/cms';
import APICms from '../../../../../api/Cms';

const Form =  React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [success_message, setSuccessMessage] = useState();
    const [error, setError] = useState();
    const [validated, setValidated] = useState(false);    
    const [clear, setClear] = useState(false);
    const [children, setChildren] = useState();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (props.children){
            const _children = React.Children.map(props.children, child => {
                if (child) return React.cloneElement(child, {submitting: submitting})
                else return null;
            });
            setChildren(_children);
        }
    }, [props.children, submitting]);

    useEffect(() => {
        return () => {
            setSuccessMessage(null);
            setError(null);
            setValidated(false);
            setClear(false);
            setChildren(null);
            setSubmitting(false);
        }
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSubmitting(true);
        setValidated(true);

        const form = e.currentTarget;
        if (form.checkValidity() === true) {

            const formData = new FormData(form);

            if (props.onSubmit) props.onSubmit(formData.entries());
            else {
                // append the form options to the form data so we can send it to the api
                const sb_form_options={
                    mailto: props?.send_to || "",
                    subject: props?.mail_subject || "",
                    body: props?.mail_body || "",
                    users: props?.users || null,
                };
    
                // checkboxes and radio buttons are a bit tricky, we need to group them together
                let checkboxes={};
                let everythingelse={};
                for (let [name, value] of formData.entries()) {
                    if (name.substring(0,6)==="______"){
                        let _name=name.substring(6).split("-");
                        if (!checkboxes[_name[0]]) checkboxes[_name[0]]=[];
                        checkboxes[_name[0]].push(`${value}`.trim());
                    } else {
                        everythingelse[name]=value;
                    }
                }
                
                if (checkboxes){
                    for (let key in checkboxes){
                        if (checkboxes[key].length===1) checkboxes[key]=checkboxes[key][0];
                        //else checkboxes[key]=JSON.stringify(checkboxes[key]); //if we want to stringify the checkboxes, we can do it here 
                    }
                }

                // send to api
                const data = {...everythingelse, ...checkboxes, sb_form_options};
                const hash= generateHash({page_id: props.form_id, referrer_page_id: props.form_page_id, content: data});
                try{
                    const res = await APICms.forms.send({page_id: props.form_id, referrer_page_id: props.form_page_id, content: data, hash: hash});
                    if (res.errors) setError(res.errors);
                    else if (res.data){
                        setSuccessMessage(props.success_message || "Data submitted successfully!");
                        setClear(true); // clear the form
                        setValidated(false);
                        const _children = React.Children.map(children, child => {
                            if (child.props.name){
                                return React.cloneElement(child, {value:null, clear: true});
                            }
                            return child;
                        });
                        setChildren(_children);
                    }
                } catch (err){
                    setError(err.message);
                    console.log(err);
                }
                
            }
        }
        setSubmitting(false);
    }

    return (
        <>
            <BSForm 
                ref={ref}
                id = {props.is_preview ? `preview-${randomUUID()}` : props.id || randomUUID()}
                onSubmit={submitHandler} 
                className={`form_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' ')}
                style={props.style} 
                onClick={preview_click}
                noValidate 
                validated={validated}
            >
                {children}
                {error && <div className="error-text-ctr">{error}</div>}
            </BSForm>
            <Modal show={success_message?true:false} onHide={() => setSuccessMessage()} centered>
                <Modal.Header closeButton />
                <Modal.Body>{success_message}</Modal.Body>
            </Modal>
        </>
    );
});

export default Form;
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Container, Row, Col, Form, InputGroup} from 'react-bootstrap';

import styles from '../Wizard.module.scss';

export const AdminFee = (props) => {
    const {stepUpdate} = props;

    const company=useSelector(state=>state.company);
    const [amount, setAmount] = useState((+props?.stepData?.admin_fee || +props?.order?.admin_fee_total || 0).toFixed(2));
    const [agreement, setAgreement] = useState(+props?.stepData?.agreement || false);

    const changeHandler = e => {
        let _amount = +e.target.value || 0;
        if (_amount > +props?.order?.admin_fee_total) _amount = +props?.order?.admin_fee_total;
        if (!_amount) _amount = "";
        setAmount(_amount);
    }

    useEffect(()=>{
        if (!agreement && amount) setAmount("");
        stepUpdate(props.stepId, {agreement: Boolean(agreement), admin_fee: (+amount || 0).toFixed(2)});
    }, [amount, agreement, stepUpdate, props.stepId]);

    return (
        <Container fluid>
            <h4 className={`${styles.title}`}>Do you wish to refund the Admin Fee?</h4>
            <span className={styles.subtitle}>Up to ${(props?.order?.admin_fee_total || 0).toFixed(2)}</span>
            <hr/>
            <Row className="mb-3">
                <Col sm={12} className="d-flex align-items-start">
                    <Form.Check 
                        className="ps-0 align-items-start"
                        id="admin-agreement"
                        name="admin-agreement"
                        type="checkbox" 
                        onChange={e => setAgreement(e.target.checked)}
                        checked={agreement}
                    />
                    <label htmlFor="admin-agreement">
                        I understand that by refunding the Admin Fee to the customer,&nbsp;
                        <span className="bold uppercase secondary-font">{company.name}</span> is still responsible for the payment of this fee to SiteBoss as per our Service Agreement. 
                        This refund is a direct reimbursement to the customer and does not affect the original transaction fees charged by SiteBoss.
                    </label>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={3}>
                    <Form.Group controlId="refund-amount">
                        <Form.Label>Refund Admin Fee</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control 
                                type="number" 
                                name="amount" 
                                min={0} 
                                max={props?.order?.admin_fee_total || 0} 
                                step={0.1} 
                                value={amount} 
                                onChange={changeHandler} 
                                disabled={!agreement}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Check 
                        className="ps-0 form-switch"
                        id="admin-check"
                        name="admin-check"
                        type="checkbox" 
                        label={`Full Amount of $${props?.order?.admin_fee_total.toFixed(2)}`}
                        onChange={e => {
                            changeHandler({target: {value: e.target.checked ? props?.order?.admin_fee_total.toFixed(2) : 0}});
                        }}
                        disabled={!agreement}
                        checked={+amount === +props?.order?.admin_fee_total}
                    />
                </Col>
            </Row>
        </Container>
    );
}
import Permissions from "../api/Permissions";
import Users from "../api/Users";
import Products from "../api/Products";
import { Features } from "../containers/Permissions/Features/Features";

const getFeaturePermissions=async(id)=>{
    try{
        let response = await Permissions.Features.permissions({company_id: id})
        if(response.status === 200) return response.data;
        else return "Unable to get Feature Permissions"
    }catch(ex){
        console.error(ex);
        return "Unable to get Feature Permissions"
    }
}

const getProductId=async(variantId)=>{
    try{
        let response = await Products.Variants.get({id: variantId})
        if(response.status === 200) return response.data[0].product_id;
        else return "Unable to get Product Id"
    }catch(ex){
        console.error(ex);
        return "Unable to get Product Id"
    }
}
const getCompanyOwners=async(id)=>{
    try{
        let response = await Users.getList({company_id: id,filters:{ user_roles: [3]}});
        if(response.status === 200) return response.data.users;
        else return "Unable to get company owners"
    }catch(ex){
        console.error(ex);
        return "Unable to get Company Owner"
    }
}

const handleOwners=async(owners, productIds)=>{
    //have to take each owner and check if they've purchased the feature
    for(let i = 0; i < owners.length; i++){
        let purchased = await getOwnerPurchased(owners[i].id, productIds);
        if(typeof purchased !== "string"){
            productIds.forEach((product)=>{
                if(purchased[product.productId] === 1){
                    product.available = true;
                    product.purchased = true;
                } 
            })
        }else return "There was a problem getting the owner purchased features"
    }
    
}

const getOwnerPurchased=async(userId, productIds)=>{
    let idArray = productIds.map((feature)=>feature.productId)
    try{
        let response = await Users.purchasedProduct({user_id: userId, product_id: idArray})
        if(response.status === 200) {
           return response.data
        }else return "Unable to get Owner Purchased";
        
    }catch(ex){
        console.error(ex);
        return "Unable to get Owner Purchased";
    }
}

const getAllFeatureStuff=async(id)=>{
    let featurePermissions = await getFeaturePermissions(id);
    let owners = await getCompanyOwners();
    if(typeof featurePermissions === "string" || typeof owners === "string") {
        return(`Errors: ${featurePermissions ? `-- ${featurePermissions}` : ""} ${owners ? `-- ${owners}` : ""}`)
    }else{
        assignAvailable(featurePermissions);

        //filter features down to unavailable ones, so that we aren't checking ones we know the company already has access to
        let unavailableFeatures = featurePermissions.filter((feature)=>!feature.available);
        let hasProduct = unavailableFeatures.filter((feature)=>feature.product_variants);
        
        await handleVariants(hasProduct);
        //need to find the product id from the variant id

        await handleOwners(owners, hasProduct);

        if(typeof handleVariants === "string" || typeof handleOwners === "string"){
            console.error(`The list of available features may be incomplete -- Errors: ${handleVariants ? `-- ${handleVariants}` : ""} ${handleOwners ? `-- ${handleOwners}` : ""}`)
        }
        return featurePermissions;
    }
}

const handleVariants=async(featuresWithProducts)=>{
    for(let i = 0; i < featuresWithProducts.length; i++){
        let feature = featuresWithProducts[i];
        let productId = await getProductId(feature.product_variants.id);
        if(typeof productId !== "string" ) feature.productId = productId;
        else return "There was a problem getting the product id"
    }
}

const assignAvailable=(featurePermissions)=>{
    for (let i = 0; i < featurePermissions?.length; i++){
        let feature = featurePermissions[i];
     
        //if override is null, no override has been set and default_enabled takes over.
        //If override is 1, feature has access.
        //If override is 0,features is denied.
        if(feature.company_override === null) feature.default_is_enabled === 1 ? feature.available = true : feature.available = false;
        else if(feature.company_override === 1) feature.available = true;
        else if(feature.company_override === 0) feature.available = false;
    }
}

export const returnFeaturesAvailable=async(id)=>{
    let response = await getAllFeatureStuff(id);
    if(typeof response === "string") console.error("There was a problem getting all the company features.", response)
    else {
        let objectFeatures = response.reduce((obj, arrObj) => ({...obj, [arrObj.id]: arrObj}), {});
        return objectFeatures;
    }
    //at this point, if there is a response, it at least has the available features based on normal permissions.  If there was an error with getting the paid features, it will say so in the console.  
}
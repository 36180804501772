import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import TransactionDetails from './TransactionDetails';

import "../OrderPage.scss";

const TransactionHistory = ({currentOrder=null, updateOrder = ()=>{}}) => {


    if (!currentOrder) return null;

    return (
        <Accordion className="transaction-hist-wrapper">
            {currentOrder?.transactions?.map((transaction, i)=>(
                <div key={`each-transaction-${i}`} data-cy="transaction-histories">
                    <Accordion.Toggle as={Card.Header} eventKey={`${i}`} className="transaction-pair transaction-pair-selectable">
                        <span style={{textDecoration: transaction?.transaction_status_id === 2 || transaction?.transaction_status_id === 3 || transaction?.transaction_status_id === 4 ? "line-through" : "none"}}>
                            {transaction?.transaction_payment_method_id === 5 ? "A. Auth" : transaction?.transaction_type_name}
                            {` `}#{transaction?.id}
                        </span>
                        <span style={{textDecoration: transaction?.transaction_status_id === 2 || transaction?.transaction_status_id === 3 || transaction?.transaction_status_id === 4 ? "line-through" : "none"}} className="text-right">
                            ${transaction.amount.toFixed(2)}
                        </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`${i}`}>
                        <TransactionDetails currentOrder={currentOrder} transaction={transaction} updateOrder={updateOrder} user_id={currentOrder.user_id} />
                    </Accordion.Collapse>
                </div>
            ))}
        </Accordion>
    )
}

export default TransactionHistory
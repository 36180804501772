import WizardBlock from './Components/WizardBlock';
import Wizard from './Components/Wizard';
import WizardStep from './Components/Wizard/WizardStep';

export const Components = {
    Wizard: {
        component: Wizard,
        name: "Wizard",
        component_name: "Wizard",
        tooltip: "A wizard is a series of steps that guide the user through a process",
        apply_styles: true,
        can_drop: true,
        list: "Wizards",
        hidden: true,
        props: [
            {
                display_name: "Buttons",
                name: "wizard_buttons",
                description: "The style of the buttons",
                type: "select",
                options: ["Next & Previous", "Step Numbers", "Step Labels"],
                value: "Next & Previous",
                elements: ["Wizard"],
            },
            {
                display_name: "Show in popup",
                name: "modal",
                description: "Show the wizard inside a popup",
                type: "checkbox",
                value: 0,
                elements: ["Wizard"],
            },
            {
                display_name: "Popup Title",
                name: "modal_title",
                description: "The title of the popup",
                type: "text",
                value: "",
                elements: ["Wizard"],
                condition:{
                    name: "modal",
                    value: 1
                }
            },
            {
                display_name: "Popup Width",
                name: "modal_width",
                description: "The width of the popup",
                type: "select",
                options: ["Small", "Medium", "Large", "Extra Large"],
                value: "Medium",
                elements: ["Wizard"],
                condition:{
                    name: "modal",
                    value: 1
                }
            },
            {
                name: "custom-steps",
                type: "custom",
                description: "Allows you to define the number of steps this wizard will have",
                display_name: "Steps",
                value: "",
                elements: ["Wizard"],
                source:{
                    component: "containers/Cms/Components/Properties/WizardStructure",
                    props: {
                        steps: "{value}", // this is the value from up there... because its dinamic, we wrap it in curly braces so it grabs the value from the json
                        selection: "method:selection" // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas
                    },
                }
            },
        ]
    },
    WizardStep:{
        component: WizardStep,
        name: "Step",
        component_name: "WizardStep",
        tooltip: "A step within a wizard",
        apply_styles: true,
        can_drop: true,
        hidden: false,
        list: "Wizards",
        props: [
            {
                display_name: "Step Number",
                name: "step_number",
                description: "The number of the step",
                type: "number",
                value: 1,
                elements: ["WizardStep"],
            },
            {
                display_name: "Step Title",
                name: "step_title",
                description: "The title of the step",
                type: "text",
                value: "",
                elements: ["WizardStep"],
            },
            {
                display_name: "Requires Login",
                name: "requires_login",
                description: "Only show this step to logged in users",
                type: "checkbox",
                value: 0,
                elements: ["WizardStep"],
            },
        ]
    },
    WizardBlock: {
        component: WizardBlock,
        name: "Wizard Block",
        component_name: "WizardBlock",
        tooltip: "The wizard block is a page element that contains a wizard",
        apply_styles: true,
        list: "WizardBlocks",
        props: [
            {
                name: "wizard_id",
                type: "select",
                options: [],
                description: "The wizard to display",
                display_name: "Wizard",
                value: "",
                source: {
                    component: "containers/Cms/Components/Properties/ContentBlockSelect", // we will add the path where needed
                    props: {
                        page_type: 12,
                        selection: "method:selection"
                    },
                },
                elements: ["WizardBlock"]
            }
        ]
    },
}
import React, {useState, useEffect, useMemo} from 'react';
import {Container, Button} from 'react-bootstrap';

import wizardStyles from '../Wizard.module.scss';
import styles from './Step1.module.scss';

export const Step1 = (props) => {
    const {stepUpdate} = props;
    const [selected, setSelected] = useState(props?.stepData?.type || "amount");

    const clickHandler = type => setSelected(type);

    useEffect(()=>{
        stepUpdate(props.stepId, {type: selected});
    },[stepUpdate, props.stepId, selected]);

    const allowProductRefund = useMemo(() => {
        let bool=true;
        if (props.order.admin_fee_refunded !== 0) bool = false;
        for (const item of props.order.items) {
            if (item.total_refunded_amount !== 0 || item.shipping_refunded_amount !==0 || item.product_type_id===9  || item.product_type_id===6) {
                bool = false;
                break;
            }
        }
        for (const transaction of props.order.transactions) {
            if (transaction.transaction_type_id === 3 && transaction.transaction_status_id === 7) {
                bool = false;
                break;
            }
        }
        if (props.order.split_order_ids?.length > 0) bool = false;
        if (props.order.items.length === 0 && !props.order.parent_id) bool = false;
        return bool;
    }, [props.order]);

    return (
        <Container fluid>
            <h4 className={`${wizardStyles.title}`}>What are you refunding?</h4>
            <p>
                <span className="bold">Credit Card</span> refunds can take up to 7 days to process in the bank's systems.
            </p>
            <p>
                <span className="bold">Gift Card</span> refunds will be refunded within the hour.  
            </p>
            <p>
                <span className="bold">Cash and Check</span> refunds need to be issued in cash at the time of refunding.
            </p>
            <p>
                <span className="bold">Manager discounts</span> cannot be refunded as they are not monies recieved.  
            </p>
            <hr/>
            <Button variant={selected === "amount" ? "primary" : "light"} className={`${styles.button}`} onClick={e=>clickHandler("amount")} data-cy="adv-refund-fixed-amount-btn">
                A Fixed Amount
                <p className={styles.small}>If you want to refund a specific amount up to the order total</p>
            </Button>
            <Button variant={selected === "item" ? "primary" : "light"} className={`${styles.button}`} disabled={!allowProductRefund} onClick={e=>clickHandler("item")} data-cy="adv-refund-items-btn">
                Items or Services
                <p className={styles.small}>If you want to refund or return a specific item from the order</p>
            </Button>
            <Button variant={selected === "transaction" ? "primary" : "light"} className={`${styles.button}`} onClick={e=>clickHandler("transaction")} data-cy="adv-refund-payments-btn">
                Payments
                <p className={styles.small}>If you want to refund a specific payment that was made on the order</p>
            </Button>
        </Container>
    );
}
import React,{useState, useEffect, useContext} from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { format, toDate, differenceInYears } from 'date-fns';

import { FileURLContext } from '../../../../../contexts/FileURLContext';
import APIUsers from '../../../../../api/Users';
import APIGroups from '../../../../../api/Groups';

import FormGroupText from '../FormGroupText/FormGroupText';
import FormGroupSelect from '../FormGroupSelect/FormGroupSelect';
import FormGroupButton from '../FormGroupButton';
import FormGroupDatePicker from '../FormGroupDatePicker/FormGroupDatePicker';

const SignUpFamily =  React.forwardRef((props, ref) => {
    const {callback} = props;
    const company_context = useContext(FileURLContext);

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }
    
    const user = useSelector(state => state.auth.user);

    const [groupRoles, setGroupRoles] = useState([]);
    const [error, setError] = useState();
    const [errorFields, setErrorFields] = useState([]);
    const [success, setSuccess] = useState();
    const [validated, setValidated] = useState(false);   
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState();

    const lg_size = props?.forceMobile ? 12 : 6;


    useEffect(() => {
        const _getGroupRoles = async () => {
            try{
                let response = await APIGroups.get({id: props.group_id});
                if (response.data){
                    setGroupRoles(response.data[0].group_member_roles?.filter(a=>a.name!=="Admin")?.sort((a,b)=>{
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    }));
                }
            }catch(ex){
                console.error(ex);
            }
        }
        if (props?.group_id) _getGroupRoles();
    }, [props?.group_id]);

    useEffect(() => {
        return () => {
            setError(null);
            setValidated(false);
            setSubmitting(false);
            setEmail(null);
            setSuccess(null);
            setErrorFields([]);
            setGroupRoles([]);
        }
    }, []);

    const checkUsername=async(userName)=>{
        try{
            let response = await APIUsers.checkUsername({username: userName});
            if(response.data[0].available === true) return true;
            else if (response.data[0].available === false){
                return false;
            } 
        }catch(ex){
            console.error(ex);
        }
    }

    const checkEmail=async(email)=>{
        try{
            let response = await APIUsers.checkEmail({email: email});
            if (response.data[0].available === true) return true;
            else if (response.data[0].available === false){
                return false;
            } 
        }catch(ex){
            console.error(ex);
        }
    }    


    const checkDOBHandler = (value) => {
        let _has_error = false, _err = {field:"dob-msg", message: "This person does not meet the age requirement"};
        if (value){
            const age = differenceInYears(new Date(), toDate(new Date(value)));
            if (props.max_age && age>+props.max_age){
                _has_error = true;
                if (props.above_age_msg) _err.message = props.above_age_msg;
                else _err.message = "This attendee is older than the maximum age for the event.";
            }
            if (props.min_age && age<+props.min_age){
                _has_error = true;
                if (props.below_age_msg) _err.message = props.below_age_msg;
                else _err.message = "This attendee is younger than the minimum age for the event.";
            }
        } else if (props.min_age || props.max_age) _has_error = true;

        if (_has_error){
            setErrorFields(prev=>{
                let _prev = [...prev];
                const idx = _prev.findIndex(a=>a.field==="dob-msg");
                if (idx>-1) _prev[idx] = _err;
                else _prev.push(_err);
                return _prev;
            });
        } else {
            setErrorFields(prev=>{
                let _prev = [...prev];
                const idx = _prev.findIndex(a=>a.field==="dob-msg");
                if (idx>-1) _prev.splice(idx, 1);
                return _prev;
            });
        }
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSubmitting(true);
        setErrorFields([]);

        let _err=[];
        const form = e.currentTarget;

        if (form.username.value){
            const usernameTestRegex=/[\100\72-\77\40-\54\57\133-\136\140\173-\176]/.test(form.username.value); 
            if (usernameTestRegex) {
                _err.push({field:"username", message: "Username must not contain special characters"})
            } else {
                const userNameUnique = await checkUsername(form.username.value);
                if (!userNameUnique) _err.push({field:"username", message: "That username was already taken. Please try another"});
            }
        }
        
        if (form.email.value){
            const emailTestRegex=/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(form.email.value);
            if (!emailTestRegex) {
                _err.push({field:"email", message: "Email must be an valid email address"});
            }
        }

        const passwordTestRegex=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        if (!passwordTestRegex.test(form.password.value)) {
            _err.push({field:"password", message: "Password must be at least 8 characters, contain at least one number, one uppercase letter, one lowercase letter, and one special character"});
        }

        if (!form.dob.value) _err.push({field:"dob", message: "Please enter your date of birth"});

        setErrorFields(_err);
        if (_err.length) {
            setValidated(true);
            setSubmitting(false);
            return;
        }

        if (form.checkValidity() === true) {

            setSubmitting(true);
            setValidated(false);

            //const formData = new FormData(e.target); // dont know why this doesnt work
            let formData = new FormData();
            formData.append("first_name", form.first_name.value);
            formData.append("last_name", form.last_name.value);
            formData.append("email", form.email.value);
            formData.append("username", form.username.value);
            formData.append("password", form.password.value);
            formData.append("password_confirmation", form.password.value);
            if (form.dob.value){
                formData.append("dob", format(new Date(form.dob.value) || toDate(new Date()), "yyyy-MM-dd"));
            }
            const formDataObj = Object.fromEntries(formData.entries());

            try{
                const res = await APIUsers.create(formDataObj);
                if (!res.errors) { // successful registration
                    const res2 = await APIGroups.invite({
                        group_id: props.group_id,
                        user_ids: parseInt(res.data.id),
                        member_role_id: parseInt(form.group_role_id.value),
                        status_id: 2
                    });
                    if (res2.data) {
                        setValidated(false);
                        setSuccess(true);
                        if (callback) callback();
                    }
                } else {
                    setValidated(true);
                    if (typeof res.errors === "string") setError(res.errors);
                    else {
                        let _err = <></>;
                        Object.keys(res.errors?.validation).forEach((key) => {
                            _err = <>{_err}{res.errors[key]?.[0]}<br/></>;
                        });
                        setError(_err);
                    }
                    console.log(res.errors);
                    //setError(res.errors);
                }
            } catch(ex){
                console.error(ex);
            }
        }
        setSubmitting(false);
    }

    return (
        <>
            {success &&
                <>
                    <h1>{props.success_title || "Thank you!"}</h1>
                    <p>
                        {props.success_text || `You've successfully registered a family member and should receive a welcome email${email ? " at " + email + " " : " "}shortly.`}
                    </p>
                </>
            }
            {!success &&
                <Form
                    ref={ref}
                    onSubmit={submitHandler} 
                    className={`signup_${props.page_id} ${props.className || ""}`}
                    style={props.style} 
                    onClick={preview_click}
                    noValidate 
                    validated={validated}>
                    <Row>
                        <Col sm={12} lg={lg_size}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="first_name" 
                                type="text" 
                                label="First Name" 
                                required 
                                feedback={errorFields.filter(a=>a.field==="first_name")?.[0]?.message || "Please enter a first name"} 
                                isInvalid={errorFields.filter(a=>a.field==="first_name").length>0} 
                                disabled={submitting} />
                        </Col>
                        <Col sm={12} lg={lg_size}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="last_name" 
                                type="text" 
                                label="Last Name" 
                                required
                                feedback={errorFields.filter(a=>a.field==="last_name")?.[0]?.message || "Please enter a last name"} 
                                isInvalid={errorFields.filter(a=>a.field==="last_name").length>0} 
                                disabled={submitting} />
                        </Col>
                        <Col sm={12} lg={lg_size}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="email" 
                                type="email" 
                                label="E-mail" 
                                required 
                                feedback={errorFields.filter(a=>a.field==="email")?.[0]?.message || "Please enter an email address"} 
                                isInvalid={errorFields.filter(a=>a.field==="email").length>0}
                                disabled={submitting} 
                                onChange={(e)=>setEmail(e.target.value)} />
                        </Col>
                        <Col sm={12} lg={6}>
                            <FormGroupDatePicker 
                                page_id={props.page_id} 
                                name="dob" 
                                type="text" 
                                label="Date of Birth" 
                                placeholder="mm/dd/yyyy" 
                                maxDate={toDate(new Date())}
                                required 
                                feedback={errorFields.filter(a=>a.field==="dob")?.[0]?.message || "Please enter a date of birth"}
                                isInvalid={errorFields.filter(a=>a.field==="dob").length>0}
                                disabled={submitting} 
                                onChange={(e)=>checkDOBHandler(e.target.value)}
                            />
                            <div className="bold" dangerouslySetInnerHTML={{__html: errorFields.filter(a=>a.field==="dob-msg")?.[0]?.message}}/>
                        </Col>
                        <Col sm={12} lg={6}>
                            <FormGroupSelect
                                page_id={props.page_id}
                                name="group_role_id"
                                label="Relationship"
                                required
                                feedback={errorFields.filter(a=>a.field==="group_role_id")?.[0]?.message || "Please select a relationship"}
                                isInvalid={errorFields.filter(a=>a.field==="group_role_id").length>0}
                                disabled={submitting}
                                options={groupRoles?.map(a=>`${a.id}|${a.name}`) || []}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} lg={6}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="username" 
                                type="text" 
                                label="User" 
                                required 
                                feedback={errorFields.filter(a=>a.field==="username")?.[0]?.message || "Please enter a user name"}
                                isInvalid={errorFields.filter(a=>a.field==="username").length>0}
                                disabled={submitting} />
                        </Col>
                        <Col sm={12} lg={6}>
                            <FormGroupText 
                                page_id={props.page_id} 
                                name="password" 
                                type="password" 
                                label="Password" 
                                required 
                                feedback={errorFields.filter(a=>a.field==="password")?.[0]?.message || "Please enter a password"}
                                isInvalid={errorFields.filter(a=>a.field==="password").length>0}
                                disabled={submitting} />
                        </Col>
                        <Col sm={12}>
                            <FormGroupButton page_id={props.page_id} label="Sign Up" disabled={submitting} click={props.is_preview?"Button":"Submit Form"} />
                        </Col>
                        {error && 
                            <Col sm={12} className="error-text-ctr">
                                {error}
                            </Col>
                        }
                    </Row>
                </Form>
            }
        </>
    );
});

export default SignUpFamily;
import Request from './Api';


// ai functions
const ai = async (props) => {
    return (
        Request({
            url: "/cms/ai",
            method: "POST",
            data: props,
        })
    );
}

// website functions
const websites={
    get: async (props) => {
        return (
            Request({
                url: "/cms/site"+(props?.id?"/"+props.id:""),
                method: "POST",
                data: props,
            })
        );
    },

    create: async (props) => {
        if (props?.id){
            return (
                Request({
                    url: "/cms/site/edit",
                    data: props,
                    method: "PUT",
                })
            );
        } else {
            return (
                Request({
                    url: "/cms/site/create",
                    data: props,
                    method: "POST",
                })
            );
        }
    }

}

// page functions
const pages={
    history:{
        get: async (props) => {
            return (
                Request({
                    url: "/cms/site/page/history"+(props?.id?"/"+props.id:""),
                    method: "POST",
                    data: props,
                })
            );
        },
    
        create: async (props) => {
            if (props?.id){
                return (
                    Request({
                        url: "/cms/site/page/history/edit",
                        data: props,
                        method: "PUT",
                    })
                );
            } else {
                return (
                    Request({
                        url: "/cms/site/page/history/create",
                        data: props,
                        method: "POST",
                    })
                );
            }
        },
    
        delete: async (props) => {
            return (
                Request({
                    url: "/cms/site/page/history/delete",
                    data: props,
                    method: "DELETE",
                })
            );
        }
    },

    get: async (props) => {
        return (
            Request({
                url: "/cms/site/page"+(props?.id?"/"+props.id:""),
                method: "POST",
                data: props,
            })
        );
    },

    create: async (props) => {
        if (props?.id){
            return (
                Request({
                    url: "/cms/site/page/edit",
                    data: props,
                    method: "PUT",
                })
            );
        } else {
            return (
                Request({
                    url: "/cms/site/page/create",
                    data: props,
                    method: "POST",
                })
            );
        }
    },

    copy: async (props) => {
        return (
            Request({
                url: "/cms/site/page/copy",
                data: props,
                method: "POST",
            })
        );
    },

    delete: async (props) => {
        return (
            Request({
                url: "/cms/site/page/delete",
                data: props,
                method: "DELETE",
            })
        );
    }

}

// page functions
const pageTypes={
    get: async (props) => {
        return (
            Request({
                url: "/cms/site/page/type"+(props?.id?"/"+props.id:""),
                method: "POST",
                data: props,
            })
        );
    },
}

// url functions
const urls={
    /*  Create a url for the company
        ----------------------------
        params:
        -------
        company_id: id of the company, required
        website_id: id of the website to link to that url, can be null to display the default placeholder page
        domain: domain name, required but should default to siteboss.net
        subdomain: subdomain name, required
    */
    create: async (props) => {
        if (!props.domain) props.domain="siteboss.net";

        if (props?.id){
            return (
                Request({
                    url: "/cms/site/url/edit",
                    data: props,
                    method: "PUT",
                })
            );
        } else {
            return (
                Request({
                    url: "/cms/site/url/create",
                    data: props,
                    method: "POST",
                })
            );
        }
    },

    /*  Get a list of urls for a company
        ----------------------------
        params:
        -------
        company_id: id of the company, required
        website_id: id of the website to link to that url
        subdomain: subdomain name
        domain: domain name
    */
    get: async (props) => {
        return (
            Request({
                url: "/cms/site/url"+(props?.id?"/"+props.id:""),
                data: props,
            })
        );
    },
    validate: async (props) => {
        return (
            Request({
                url: "/cms/site/url/validate",
                data: props,
                method: "POST",
            })
        );
    },
    getAll: async(props)=>{
        return(
            Request({
                url: "/cms/site/url",
                data: props,
                method: "GET",
            })
        )
    },
    delete: async(props)=>{
        return(
            Request({
                url: "/cms/site/url/delete",
                data: props,
                method: "DELETE"
            })
        )
    }
}

const themes={
    get: async (props) => {
        return (
            Request({
                url: "/cms/theme"+(props?.id?"/"+props.id:""),
                data: props,
            })
        );
    },

    create: async (props) => {
        if (props?.id){
            return (
                Request({
                    url: "/cms/theme/edit",
                    data: props,
                    method: "PUT",
                })
            );
        } else {
            return (
                Request({
                    url: "/cms/theme/create",
                    data: props,
                    method: "POST",
                })
            );
        }
    },

    copy: async (props) => {
        return (
            Request({
                url: "/cms/theme/copy",
                data: props,
                method: "POST",
            })
        );
    },


    delete: async (props) => {
        return (
            Request({
                url: "/cms/theme/delete",
                data: props,
                method: "DELETE",
            })
        );
    },

    css: {
        get: async (props) => {
            return (
                Request({
                    url: "/cms/theme/css"+(props?.id?"/"+props.id:""),
                    data: props,
                })
            );
        },

        create: async (props) => {
            if (props?.id){
                return (
                    Request({
                        url: "/cms/theme/css/edit",
                        data: props,
                        method: "PUT",
                    })
                );
            } else {
                return (
                    Request({
                        url: "/cms/theme/css/create",
                        data: props,
                        method: "POST",
                    })
                );
            }
        },

        delete: async (props) => {
            return (
                Request({
                    url: "/cms/theme/css/delete",
                    data: props,
                    method: "DELETE",
                })
            );
        }

    }
}

const sendmail = async (props,send_to) => {
    if (typeof send_to === "string") send_to = JSON.parse(send_to);
    props.send_to = send_to;
    return (
        Request({
            url: "/cms/contact/sendmail",
            method: "POST",
            data: props,
        })
    );
}

const forms = {
    get: async (props) => {
        return (
            Request({
                url: "/form/submissions"+(props?.id?"/"+props.id:""),
                data: props,
            })
        );
    },
    send: async (props) => {
        let header_data={};
        if (props.hash){
            // re-do the header to include the hash
            let token="";
            let localuser = localStorage.getItem("user");
            if (localuser) {
                try{
                    localuser=JSON.parse(localuser);
                    token=localuser.token;
                }catch(error){}
            }
            header_data = {'Authorization': token, 'X-Anti-Spam-Hash': props.hash};
            delete props.hash;
        }
        return (
            Request({
                url: "/form/submissions/create",
                method: "POST",
                data: props,
                headers: header_data || undefined,
            })
        );
    }    
}

const deviceList = () => {
    return [
        {
            "name": "iPhone SE",
            "width": 320,
            "height": 568,
            "resX": 640,
            "resY": 1136,
            "pixelRatio": 2
        },
        {
            "name": "iPhone XR",
            "width": 414,
            "height": 896,
            "resX": 828,
            "resY": 1792,
            "pixelRatio": 2
        },
        {
            "name": "iPhone 12 Pro",
            "width": 390,
            "height": 844,
            "resX": 780,
            "resY": 1684,
            "pixelRatio": 2
        },
        {
            "name": "Pixel 5",
            "width": 144,
            "height": 70.4,
            "resX": 1080,
            "resY": 2340,
            "pixelRatio": 4
        },
        {
            "name": "Samsung Galaxy S8+",
            "width": 159.5,
            "height": 73.4,
            "resX": 1440,
            "resY": 2960,
            "pixelRatio": 4
        },
        {
            "name": "Samsung Galaxy S20 Ultra",
            "width": 166.9,
            "height": 76.0,
            "resX": 1440,
            "resY": 3200,
            "pixelRatio": 4
        },
        {
            "name": "iPad Air",
            "width": 250.6,
            "height": 174.1,
            "resX": 2048,
            "resY": 1536,
            "pixelRatio": 2
        },
        {
            "name": "iPad Mini",
            "width": 203.2,
            "height": 134.8,
            "resX": 2048,
            "resY": 1536,
            "pixelRatio": 2
        },
        {
            "name": "Surface Pro 7",
            "width": 292,
            "height": 201,
            "resX": 2736,
            "resY": 1824,
            "pixelRatio": 3
        },
        {
            "name": "Surface Duo",
            "width": 270,
            "height": 185,
            "resX": 1800,
            "resY": 1350,
            "pixelRatio": 2
        },
        {
            "name": "Galaxy Fold",
            "width": 160.9,
            "height": 62.9,
            "resX": 1536,
            "resY": 720,
            "pixelRatio": 4.6
        },
        {
            "name": "Samsung Galaxy A51/71",
            "width": 159.0,
            "height": 75.1,
            "resX": 1080,
            "resY": 2400,
            "pixelRatio": 4
        },
        {
            "name": "Nest Hub",
            "width": 214.6,
            "height": 124.8,
            "resX": 1280,
            "resY": 800,
            "pixelRatio": 2
        },
        {
            "name": "Nest Hub Max",
            "width": 214.6,
            "height": 124.8,
            "resX": 1280,
            "resY": 800,
            "pixelRatio": 2
        },
    ];            
}

const Cms = {
   urls, pages, pageTypes, websites, themes, sendmail, deviceList, ai, forms
}
  
export default Cms;
import React, {useState, useEffect, useCallback, useRef, useMemo, Suspense} from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch} from 'react-redux';
import { Modal, Button, Container, Row, Col, Card } from 'react-bootstrap';

import PrintFullPage from '../../../POS/Print/PrintFullPage';
import CCPayment from '../CCPayment';
import ItemSummary from './ItemSummary';
import { OrderTotals } from './OrderTotals/OrderTotals';

import Pos from '../../../../api/Pos';
import Events from '../../../../api/Events'
import * as actions from '../../../../store/actions'
import './PatronCart.scss';

export const PatronCart = ({ patron_layout, events:events_display=true, url=null, hide_edit_cart_button=false, is_disabled=false, disabled_message=null}) => {

    const history = useHistory();
    const dispatch=useDispatch();

    const isPaymentSetup = !!window.CollectJS || false;

    const defaultPatronRegisterId = useSelector(state=>state.pos.register);
    const user=useSelector(state=>state.auth.user.profile);     //will always be the logged in user for cart
    const reduxOrder = useSelector(state => state.pos[defaultPatronRegisterId].orderAll);

    const mountedRef = useRef(false);
    const [loading, setLoading]=useState(true);
    const [order, setOrder]=useState();
    const [noOrder, setNoOrder]=useState();
    const [userDetails, setUserDetails]=useState();
    const [printerHasProps, setPrinterHasProps]=useState();
    const [transSuccess, setTransSuccess]=useState(false);
    const [print, setPrint]=useState(false);
    const [processing, setProcessing]=useState(false);
    const [displayStep, setDisplayStep] = useState(0);
    const [currentBreakpoint, setCurrentBreakpoint] = useState();

    //Appends the event details for itm summary to items
    const getEventDetails = useCallback(async (items_original) => {
        let items = JSON.parse(JSON.stringify(items_original));
        // identify which items have events
        let eventList = [];
        items.forEach(item => {
            if (item?.event?.event_id) {
                if (!eventList.includes(item.event.event_id)) eventList.push(item.event.event_id);
            }
        });

        if (eventList.length>0){
            // get event details
            const response = await Events.getSimple({id: eventList});
            const events = response.data?.events;
            if (response.data && !response.errors && events) {
                items.forEach(item => {
                    if (item?.event?.event_id) {
                        // find the appropriate event and assign the event info
                        item.event_details = events.find(event => event.id === item.event.event_id);
                        if (item.event_details===-1) {
                            item.event_details = "Details Currently Unavailable";
                        }
                    }
                });
            }
        }

        return items;

        // // assign event details to the proper items
        // if(each.hasOwnProperty("event") && each.product_type_id !==3){
        //     try{
        //         let response=await Events.getSingle({id:each.event.event_id});
        //         if(!response.errors){
        //             each.event_details = response.data[0];
        //         }else if(response.errors) each.event_details = "Details Currently Unavailable"
        //     }catch(ex){console.error(ex)}
        // }
    },[]);

    //get sets of service items and put the rest in the array as normal
    const sortServices=useCallback((items_original)=>{
        let items = [...items_original];
        let tempItems=[];
        let tempService=[];

        //removing everything but the services
        for(let i =0; i<items?.length; i){
            if(items[i].product_type_id !== 9) {
                tempItems.push(items[i])
                items.splice(i, 1)
            }
            else i++
        }

        //group a service together
        for(let i =0; i<items?.length; i){
            if(items[i].product_type_id === 9) {
                tempService.push(items[i]);
                items.splice(i, 1)
            } else i++
            for(let j=0; j<items.length; j){
                if(items[i].product_type_id===9 && items[i].product_name ===items[j].product_name && items[i].product_id===items[j].product_id){
                    tempService.push(items[j])
                    items.splice(j, 1)
                }else j++
            };
            let condensed;
            if(tempService.length>0) condensed=condenseServices(tempService);
            if(condensed) tempItems.push(condensed)
            tempService=[];
        }
        return tempItems
        
    },[]);

    /**Gets order from redux and processes the services appropriately */
    const processOrder = useCallback(async (original_order) => {
        
        if (original_order) {
            let orderCopy = original_order;

            if (original_order.items?.length>0) {
                // Condense Service tokens to one "item"
                let itemsWithServices = sortServices(original_order.items);
                let itemsWithServicesAndEvents = await getEventDetails(itemsWithServices);
                orderCopy.items = itemsWithServicesAndEvents;
                setOrder(orderCopy);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    },[sortServices, getEventDetails]);

    //first load
    useEffect(()=>{
        mountedRef.current = true;

        const handleResize = () => {
            const currentWidth = window.innerWidth;
            if (currentWidth < 576) setCurrentBreakpoint({size:'xs', width:currentWidth});
            else if (currentWidth < 768) setCurrentBreakpoint({size:'sm', width:currentWidth});
            else if (currentWidth < 992) setCurrentBreakpoint({size:'md', width:currentWidth});
            else if (currentWidth < 1200) setCurrentBreakpoint({size:'lg', width:currentWidth});
            else setCurrentBreakpoint({size:'xl', width:currentWidth});
        }
        handleResize();
        window.addEventListener('resize', handleResize);
    
        return()=>{
            mountedRef.current=false;
            window.removeEventListener('resize', handleResize);
            setProcessing(false)
        }
    },[]);

    const backButtonHandler = useCallback(() => {
        // do stuff to go back to previous screen
        setDisplayStep(prev => (prev > 0) ? prev - 1 : 0);
    },[]);

    const nextButtonHandler = useCallback(() => {
        // do stuff to go back to previous screen
        setDisplayStep(prev => prev + 1);
    },[]);

    const additionalPaymentButton = useMemo(() => (
        <div className="display-small-screens">
            <Button variant="light" onClick={backButtonHandler}>
                <i className="far fa-arrow-left"></i>
                View Order Details
            </Button>
        </div>
    ), [backButtonHandler]);

    const nextStepButton = useMemo(() => (
        <div className="display-small-screens">
            <Button variant="primary" className="icon-right" onClick={nextButtonHandler}>
                Proceed to Payment
                <i className="far fa-arrow-right"></i>
            </Button>
        </div>
    ), [nextButtonHandler]);

    useEffect(() => {
        if(reduxOrder && reduxOrder !== order && (!processing)) {
            //reduxOrder?.items?.length>0
            setLoading(true);
            processOrder(reduxOrder);
        } else {
            setLoading(false);
        }
    },[reduxOrder, processOrder, processing, order]);

    //Clears local storage after print receipt has details
    useEffect(()=>{
        if(printerHasProps && transSuccess){
            Pos.local.remove(`POS-${defaultPatronRegisterId}-order-id`);
            dispatch(actions.setPCReset());
            dispatch(actions.reset(defaultPatronRegisterId));
        };
    },[printerHasProps, transSuccess, dispatch, defaultPatronRegisterId]);


    //Add the services into one item in the summary
    const condenseServices=(service)=>{
        //All the tokens will have the same data, just using first one[0] to get it
        let tokenTotal=service.length;
        let totalPrice=(service[0].base_price*tokenTotal).toFixed(2);
        let totalTax=(service[0].tax*tokenTotal)
        const serviceItem={
            token_total:+tokenTotal,
            final_price:+totalPrice,
            base_price: service[0].base_price,
            product_name:service[0].product_name,
            product_id:service[0].product_id,
            tax:+totalTax,
            event: service[0].event,
            product_type_id: service[0].product_type_id
        }
        return serviceItem
    };

    const handleHide=()=>{
        setPrint(false);
        setNoOrder(true);
    }

    const handleEdit = useCallback((e) => {
        history.push(url);
    },[history, url]);

    if (loading) return(
        <SkeletonTheme color="#e0e0e0">
            <Skeleton height={30} style={{marginBottom:"1rem"}} />
            <Skeleton height={12} count={5} />
        </SkeletonTheme>
    );

    if (!user) return (<Card className="content-card"><p>You must be logged in to view your cart!</p></Card>);
    if (is_disabled) return (<Card className="content-card"><div className="register-disabled-message">{disabled_message || "We are not accepting orders at this time."}</div></Card>);
    if (noOrder || !order || order?.items?.length===0 || !reduxOrder?.items?.length) return (<Card className="content-card"> <p>You currently have no items in your cart!</p></Card>);

    return (
    <Card className={`patron-cart-main-wrapper content-card ${events_display ? 'expanded-summary' : 'simplified-summary'}`}>
        {noOrder===null &&
            <Suspense fallback={             
                <SkeletonTheme color="#e0e0e0">
                    <Skeleton height={30} style={{marginBottom:"1rem"}} />
                    <Skeleton height={12} count={5} />
                </SkeletonTheme>
            }>
            </Suspense>
        }

        <Row>
            <Col sm={12} xl={8} className={`patron-cart-left ${displayStep===0 && 'current-step'}`}>
                <div>
                    <div className="mobile-header">
                        <p className="text-link"><a href="#pc-input-head">Back to Top</a></p>
                    </div>
                    <h4 className="pc-header">Order Summary</h4>
                    {order &&
                        <>
                            <span id="pc-item-sum">
                                <ItemSummary order={order} events_display={events_display} />
                            </span>
                            <div className="patron-cart-order-totals" data-cy="patron-cart-order-totals">
                                {order && <OrderTotals order={order}/>}
                            </div>
                        </>
                    }
                    {url && patron_layout && 
                        <div className="patron-cart-buttons default-left">
                            {!hide_edit_cart_button && <Button variant="primary" onClick={handleEdit}>Edit Cart</Button>}
                            {isPaymentSetup ? <>{nextStepButton}</> : <p>Checkout is Unavailable</p>}
                        </div>
                    }
                </div>
            </Col>
            <Col sm={12} xl={4} className={`patron-cart-right ${displayStep===1 && 'current-step'}`}>
                <div>
                    <h4 className="pc-header">Payment Details</h4>
                    <CCPayment 
                        forceMobile={currentBreakpoint?.width<=1400?false:true /* I know its sort of backwards but css has this breakpoint, so we need to match it */}
                        setTransSuccess={setTransSuccess}
                        order={order} 
                        passUser={setUserDetails} 
                        defaultPatronRegisterId={defaultPatronRegisterId}
                        patron_layout={patron_layout}
                        user={user}
                        setPrint={setPrint}
                        setProcessing={setProcessing}
                        otherButtons={additionalPaymentButton}
                        // memo="BYPASS"
                    /> 
                    <div className="mobile-header">
                        <h4>Input Your Payment Details</h4>
                        <p className="text-link"  id="pc-input-head" ><a href="#pc-item-sum">View your Item Summary</a></p>
                    </div>
                </div>
            </Col>
        </Row>
        
        <Modal show={print} size="lg" onHide={handleHide} data-cy="success-modal">
            <Modal.Header closeButton>
                <h4>Thank You!</h4>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                <p style={{fontSize: "1rem", padding: "10px"}}>
                    Your transaction has been successful! Thank you for your purchase.  
                    Please print your receipt for your records.  
                    Upon closing this notification, you will be redirected back to the home page for your convenience.  
                </p>
                <PrintFullPage 
                    transSuccess={transSuccess} 
                    setPrinterHasProps={setPrinterHasProps} 
                    userDetails={userDetails} 
                    order={order}
                    items={order?.items}
                />
            </Modal.Body>
        </Modal>     
    </Card>
    )
}
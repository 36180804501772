import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Col, Form, Row, Button } from 'react-bootstrap'
import { authCheck } from "../../../../../utils/auth";

import '../Notes.css'
import ErrorCatcher from '../../../../../components/common/ErrorCatcher'
import NoteApi from '../../../../../api/Notes';

const NoteModal =(props) => {
    const convertStatus=props.convertStatus;

    let history = useHistory();

    const [ activeNote, setActiveNote ]=useState(); //For edit
    const [ noteStatus, setNoteStatus ]=useState();
    const [ haveEdit, setHaveEdit ]=useState(false); //Have active note
    const [ noteLength, setNoteLength ]=useState(0); //For character counter under text area
    const [ user, setUser ]=useState(); //author
    const [ validated, setValidated ]=useState(false);
    const [ submitting, setSubmitting ] = useState(false);
    const [ error, setError ] = useState(null);


    const getUser=useCallback(()=>{
        if (props.user) setUser(props.user);
        else{
            let u = authCheck(history);
        }
    },[props.user,history]);

    useEffect(()=>{
        getUser();
    },[props.user,getUser]);

    useEffect(()=>{
        if(props.note){
            setActiveNote(props.note);
            setHaveEdit(true);
            let status=convertStatus(props.note.status)
            setNoteStatus(props.note.status)
        }
    }, [props.note, convertStatus]); //set note for edit

    useEffect(()=>{
        if(activeNote){
            setNoteLength(activeNote.note.length);
        }
    },[activeNote])




//#region Handlers
    const valueOnChange=(e)=>{
        e.preventDefault()
        setNoteLength(e.target.value.length)
    }

    const onSubmit =(e)=>{
        e.preventDefault();
        formSubmit(e)
    }
    
    const formSubmit=async(e)=>{
        e.preventDefault(); 

        const form = e.currentTarget; 

        setValidated(true)
        setSubmitting(true)
        let newNote;
        if(form.checkValidity()===true && activeNote === undefined){
            newNote = setUpForNew(e)
            try{
                let response = await NoteApi.create(newNote)
                tryBlock(response, "New Note Created Successfully")
            }
            catch(err){
                setError(<ErrorCatcher error={err}/>)
                console.error(err)
            } 
        }
        else if(form.checkValidity()===true && activeNote){
            newNote = setUpForEdit(e)
            try{
                let response = await NoteApi.edit(newNote)
                tryBlock(response, "Note Edited Successfully")
            }
            catch(err){
                setError(<ErrorCatcher error={err}/>)
                console.error(err)
            }
        }
        setSubmitting(false)
    }

//#endregion Handlers    

//#region Utility Functions
    const cleanUp=()=>{
        setHaveEdit(false)
        setActiveNote()
        setNoteLength(0)
    }

    const setUpForNew=(e)=>{
        const formData = new FormData(e.target);
        formData.append("user_id", props.id)
        const formDataObject = Object.fromEntries(formData.entries());
        return formDataObject   
    }

    const setUpForEdit=(e)=>{
        const formData = new FormData(e.target);
        formData.append("id", activeNote.id)
        const formDataObject = Object.fromEntries(formData.entries());
        return formDataObject 
    }

    const tryBlock=(response, msg)=>{
        if(!response.errors){
            setSubmitting(false);
            setValidated(false);
            cleanUp()
            props.createEditSuccess(msg);
        }else{
            setSubmitting(false);
            setError(<ErrorCatcher error={response.errors}/>)
        }
    }
//#endregion Utility Functions

    return (
        <Container>
            <Form noValidate validated={validated} onSubmit={onSubmit}>
                <Form.Group controlId='note'>
                    <Col sm={12}>
                        {haveEdit ?
                            <Form.Control 
                                required 
                                className="note-text-area"
                                as="textarea"
                                aria-label="text area for note content" 
                                rows="8"
                                maxLength="3000" 
                                name="note" 
                                placeholder="Enter your note here."
                                defaultValue={activeNote.note}  
                                onChange={valueOnChange}>
                            </Form.Control>
                        :
                            <Form.Control 
                                required 
                                className="note-text-area"
                                as="textarea"
                                aria-label="text area for note content" 
                                rows="4"
                                maxLength="3000" 
                                name="note"
                                onChange={valueOnChange} 
                                placeholder="Enter your note here.">
                            </Form.Control>
                        }
                        <span>{noteLength}/3000</span>  
                    </Col>
                </Form.Group>
                <Form.Group controlId='status'>
                    <Col sm={6}>
                        <Form.Label>
                            Who can view this note?
                        </Form.Label>
                        <Form.Control 
                            required
                            as="select"
                            name="status"
                            aria-label="Select visibility permission."
                            value={noteStatus || 2}
                            onChange={(e)=>setNoteStatus(e.target.value)}
                        >
                                <option value={2}>Admin</option>
                                <option value={1}>All</option>
                                <option value={3}>Only Me</option>
                                <option value={5}>Staff</option>
                                <option value={6}>IT/Support</option>
                        </Form.Control>
                    </Col>   
                </Form.Group>
                <Row className="pt-3">
                    <Col sm="auto p-0">
                        <Button type="submit" disabled={submitting}>Save Note</Button>
                    </Col>
                    <Col sm="auto p-0">
                        <Button type="button" variant="light" onClick={props.closeModal}>Cancel</Button>  
                    </Col>
                </Row>
            </Form>
            {error}
        </Container>
    )
}

export default NoteModal

import React, {useState, useEffect, useRef, useCallback} from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import Pos from '../../../../api/Pos';
import * as actions from '../../../../store/actions'
import { checkTransaction } from '../../../../utils/checkTransactionStatus';


/**Needs the following:
 * @var props.terminalDeviceId
 * @var props.orderId
 * @var props.userId
 */
export const ScanCard = (props) => {
    const { terminalDeviceId, orderId, user, type, adjustPayeeStatus } = props;

    const dispatch = useDispatch();
    const mountedRef = useRef(false);
    const [error, setError]=useState();
    const [success, setSuccess]=useState();
    const [transactionId, setTransactionId]=useState();
    const [transactionStatus, setTransactionStatus]=useState();

    useEffect(()=>{
        mountedRef.current = true

        return()=>{
            mountedRef.current = false;
        }
    },[]);

    useEffect(()=>{
        let interval;
        let response;
        if(transactionId && transactionStatus && transactionStatus!==7) {
            interval = setInterval(()=>{
               response =  checkTransaction(transactionStatus, transactionId);
            }, 5000);
            setTransactionStatus(response.transactionStatus)
            if(!response.errors && response.transactionStatus===7){
                if(props.type==="multiSingleSplit") adjustPayeeStatus("success","credit card scan", props.user, transactionId, null)
                if(props.type==="singleUserSplit") dispatchEvent(actions.setSplitPaymentCCSuccess(true))
                clearInterval(interval)
                dispatch(actions.setSplitPaymentSubmitting(false))
            }
        }
        
        return()=>{
            clearInterval(interval)
        }
    },[transactionId, transactionStatus, props.type, props.user, dispatch,adjustPayeeStatus]);

    const sendScan=async()=>{
        if(!transactionId){
            dispatch(actions.setSplitPaymentSubmitting(true))
            try{
                let response = await Pos.payment.terminal({
                    "poi_device_id": props.terminalDeviceId,
                    "type":"sale",
                    "user_id":props.userId,
                    "order_id":props.orderId,
                    "location_id":1,
                    "store_payment_info":1
                });
                if(!response.errors && mountedRef.current){
                    setTransactionId(response.data[0]);
                    setTransactionStatus(6);
                }
                else if(response.errors){
                    dispatch(actions.setSplitPaymentSubmitting(false))
                    setError(<ErrorCatcher error={response.errors}/>)
                }
            }catch(ex){
                console.error(ex)
            }
        }
    };

  return (
    <>
        {error}
        <Button onClick={sendScan}>Scan Card</Button>
    </>
  )
}

import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { attemptAuth } from '../../../utils/thunks';
import Users from '../../../api/Users';
import ErrorCatcher from '../../../components/common/ErrorCatcher';
import { FileURLContext } from '../../../contexts/FileURLContext';

import '../Login/Login.scss';

const SetNameAndPass = (props) => {
    const imageURL = useContext(FileURLContext);
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [body, setBody]=useState();

    const [uname, setUname] = useState();
    const [unameTaken, setUnameTaken] = useState(false);

    // get old username from query string ?u={b64 encoded username}
    const oldUsername = window.atob(new URLSearchParams(window.location.search).get("u"));
    const passResetToken = new URLSearchParams(window.location.search).get("guid");

    let dispatch = useDispatch();
    let history = useHistory();

    const setUnameHandler = e => {
        //should really be checking username availability here instead of in submitHandler
        let value = e.target.value;
        setUname(value);
    }

    // form submission
    const submitHandler = async (e) => {
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setValidated(true);
        setSubmitting(true);
        setError(null);

        if (form.checkValidity() === true) {
            const formData = new FormData(e.target);
            formData.append("token", passResetToken);
            const formDataObj = Object.fromEntries(formData.entries());

            await Users.checkUsername({ username: formDataObj.new_username })
            .then( response => {
                if(response.data?.[0].available) {
                    Users.resetPassword({
                        ...formDataObj
                    }).then(response => {
                        if (!response.errors){
                            Users.login({ username: oldUsername, password: formDataObj.password, rememberme: 1 })
                            .then( response => {
                                if(response.data) {
                                    Users.setUsername({
                                        id: response.data.profile.id,
                                        token: response.data.token,
                                        username: formDataObj.new_username
                                    }).then( response => {
                                        if(!response.errors) {
                                            dispatch(attemptAuth(formDataObj.new_username, formDataObj.password, 1));
                                        }
                                    }).catch(e => console.error(e));
                                }
                            }).catch(e => console.error(e));
                        } else {
                            setError(<ErrorCatcher error={response.errors} />);
                        }
                    }).catch( e => {
                        setError(<ErrorCatcher error={e} />);
                        setSubmitting(false);
                    });
                } else {
                    setError(<ErrorCatcher error={{ message: "Username is already in use." }} />)
                }
            }).catch(e => console.error(e));

        } else setSubmitting(false);
    };


    if (!body){
        setBody(
            <React.Fragment>
                <h4>Set Login Details</h4>
                <p>
                    Please enter your desired username and password
                </p>
                <Form className="my-5" noValidate validated={validated} onSubmit={submitHandler}>
                    <Form.Row>
                        <Form.Group controlId="uname" className="ani-input" >
                            <Form.Control required type="text" name="new_username" id="username" placeholder="Enter your new username" value={uname} onChange={setUnameHandler} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group controlId="pword" className="ani-input" >
                            <Form.Control required type="password" name="password"  id="password" placeholder="Enter your new password" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group controlId="pword2" className="ani-input" >
                            <Form.Control required type="password" name="password_confirmation"  id="password2" placeholder="Re-type your password" />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Button type="submit" disabled={submitting} className={`${submitting?"submitting":""}`}>Submit</Button>
                    </Form.Row>
                </Form>
            </React.Fragment>
        );
    }

    return (
        <div id="login" className="login-register">
            {imageURL.backgroundImage?.endsWith(".mp4") &&
                <video autoPlay muted loop id="bgVideo">
                    <source type="video/mp4" src={imageURL.backgroundImage} />
                </video>
            }
            <section className="banner-wrapp login-banner" style={!imageURL.backgroundImage?.endsWith(".mp4") ? {backgroundImage:`url(${imageURL.backgroundImage})`} : null}>
                <div className="l-con-wrap container">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div className="banner-content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="hid-mob">
                                                <h4 className="dash-title-login">{imageURL.companyName}</h4>
                                                <h1 className="portal-he">Let's get started</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                            <div className="auth-form-wrap">
                                <div className="logo"></div>
                                {body}
                                {error}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default SetNameAndPass;
import Request from './Api';
import Menu from './Menu';

// get user(s)
const get = async (props) => {

    let user={};

    // if the user exists in local storage, return that
    if (props && (!props.username && !props.id && !props.all)){
        user=localStorage.getItem("user");
        if (user) return Promise.resolve({data:JSON.parse(user)});
    }

    // if id is provided, retrieve that single user; otherwise send request to login
    const users = await Request({
        ...props,
        url: "/user" + (props && props.id ? "/user/" + props.id : props.all ? "" : "/login"),
        method: props && (props.id || props.all) ? "GET" : "POST",
        data: {...props},
    });

    if (users?.data && !props.id && !props.all){
        let menus=await Menu.getFullMenu({ token: users.data.token });
        users.data.menus=menus.data;

        /* uncomment when endpoint is ready
        let socials=await getSocials({id:props.id});
        users.data.social=socials.data;*/
    }

    return Promise.resolve(users);
}

const login = async (props) => {
    return(
        Request({
            url: "/user/login",
            method: "POST",
            data: { ...props }
        })
    )
}

const setUsername = async (props) => {
    return(
        Request({
            url: "/user/user/" + props.id,
            method: "PUT",
            headers: props.token ? { "Authorization": props.token } : null,
            data: { "username": props.username }
        })
    )
}

const getList = async (props) => {
    return (
        Request({
            url: "/user/list",
            method: "POST",
            data: {...props}
        })
    )
}

const address = async (props) => {
    return (
        Request({
            url: "/user/user/" + props.id + "/address",
            method: "GET"
        })
    );
}

// not currently working
const orders = async (props) => {
    return (
        Request({
            url: "/user/orders/" + props.id,
            method: "GET"
        })
    );
}

const purchasedProduct = async (props) => {
    return (
        Request({
            url: "/user/product_purchased",
            data: {...props},
            method: "POST"
        })
    );
}

// create user
const create = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["First Name is required","Last Name is required", "Email is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/user/register",
            method: "POST",
            data: {...props},
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// update user
const update = async (props) => {
    
    return (
        Request({
            url: "/user/user/"+props.id,
            data: {...props},
            method: "PUT"
        })
    );
}

// delete user
const remove = async (props) => {
    
    return (
        Request({
            url: "/user/user/"+props.id,
            data: {...props},
            method: "DELETE"
        })
    );
}


// list of users
const list = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["First Name is required","Last Name is required", "Email is required."]};
    /*let mockdata = {data:{this_page:2,page_record_count:23,total_record_count:48,users:[
            {id: 1,username: "tom",first_name: "Tom",last_name: "Huerter",email: "t.huerter@impactathleticsny.com"},
            {id: 2,username: "joe",first_name: "Joe",last_name: "Gero",email: "j.gero@impactathleticsny.com"}
        ],
    },errors:null};*/
    
    return (
        Request({
            url: "/user/list",
            method: "POST",
            data: {...props},
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

const getSocials=async(props)=>{
    return Request({
        ...props,
        url: "/user/social/"+props.id,
        method: "GET",
        data: {
            ...props
        },
    });
}

//get purchased gift cards
const getGiftCards=async(props)=>{
    return Request({
        ...props,
        url: "/user/giftcards",
        method: "POST",
        data: {
            ...props
        },
    });
}


// get user memberships
const memberships = async (props) => {

//     /** */
//     // sets mock data for testing - will be removed when api is ready
//     //let mockdata = {data:null,errors:["User not found."]};
//     /*let mockdata = {data:{
//         "subscriptions": [
//             {
//               "id": 2,
//               "product_id": 20,
//               "product_variant_id": 150,
//               "subscription_status_id": 1,
//               "subscription_status": "Active",
//               "bill_interval": "M",
//               "interval_quantity": 1,
//               "product_name": "Access Pass",
//               "product_variant_name": "Yearly"
//             }
//         ],
//         "outstanding_charges": [
//             {
//               "event_id": 12,
//               "requires_membership": 1,
//               "event_name": "Fall Basketball",
//               "product_id": 155,
//               "product_name": "Admission Fee: Fall Basketball",
//               "product_variants": [
//                 {
//                   "product_variant_id": 255,
//                   "product_variant_name": "Default",
//                   "price": 25.50
//                 }
//               ]
//             }
//         ]
//     }, errors:null};
//     /**/

    let data={data:null,errors:["User not found."]};
    const auser=await  Request({
        url: "/user/user/"+props.user_id,
        method: "GET",
        data: {props},
        //test: mockdata // send mock data to simulate an api call
    });

    if (auser.data[0]) {
        data.data={"subscriptions":[...auser.data[0].subscriptions]};
        data.errors=null;
    }
    
    return Promise.resolve(data);
}

// get user events
const events = async (props) => {

    /** */
    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["User not found."]};
    let mockdata = {"errors": null, "data": [
        {
            "id": 139,
            "parent_id": null,
            "name": "HS Basketball League",
            "description": "All team members must be wearing their uniforms.",
            "image": "",
            "location_id": 17,
            "start_datetime": "2021/07/27 14:00:00",
            "end_datetime": "2021/07/30 18:00:00",
            "event_type_id": 4,
            "event_type_name": "Games",
            "event_status_id": 1,
            "event_status_name": "Pending",
        }
    ]}
    /**/

    return (
        Request({
            url: "event",
            method: props.method || "POST",
            data: {props},
            //test: mockdata // send mock data to simulate an api call
        })
    );
}


// get user groups
const groups = async (props) => {

    /** */
    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["User not found."]};
    /*let mockdata = {data:[
        {
            id: 123,
            type_id: 4, // Family
            status_id: 1, // Active
            name: "Family",
            description: "Making a mess",
            is_admin: 1,
            group_member_role_id: 6, // Parent/Guardian
            group_member_role: "Parent/Guardian",
            group_member_status_id: 2 // confirmed
        }, 
        {
            id: 33,
            type_id: 2, // Team
            status_id: 1, // Active
            name: "The Hornets",
            description: "Over 50 basketball league",
            is_admin:0,
            group_member_role_id: 2, 
            group_member_role: "Player",
            group_member_status_id:1 // invited
        }, 
        {
            id: 633,
            type_id: 2, // Team
            status_id: 1, // Active
            name: "The Cool",
            description: "Under 80 Shuffleboard",
            is_admin:1,
            group_member_role_id: 1, 
            group_member_role: "Coach",
            group_member_status_id:2 // confirmed
        }
    ], errors:null};
    /**/

    return (
        Request({
            url: "/user/groups" + (props.id ? "/" + props.id : ""),
            method: "GET"
        })
    );
}


// reset password link
const forgotPassword = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["User not found."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/user/passreset",
            data: {
                username: props.username,
                token: props.token
            },
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// forgot user link
const forgotUser = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["User not found."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/user/usernamereminder",
            data: {
                email: props.email,
                token: props.token
            },
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// reset password
const resetPassword = async (props) => {
    
    return (
        Request({
            url: "/user/passresetconfirm",
            data: {...props},
            method: "POST"
        })
    );
}

// reset password email
const resetPasswordEmail = async (props) => {
    
    return (
        Request({
            url: "/user/passreset",
            data: {...props},
            method: "POST"
        })
    );
}

// registration verification code
const verify = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Code is invalid."]};
    //let mockdata = {data:"D17F25ECFBCC7857F7BEBEA469308BE0B2580943E96D13A3AD98A13675C4BFC2",errors:null};
    
    return (
        Request({
            url: "/user/verify",
            data: {code: props.code},
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

const multiregister = async (props) => {
    return (
        Request({
            url: "/user/multiregister",
            data: {...props},
            method: "POST"
        })
    );
}



/**/
// get a photo from a person generated by ai, only 50 requests a month so use carefully
/*const fakePhoto = async(id) => {
    Promise.resolve("https://picsum.photos/seed/"+(id+1)+"/240?random="+(id+1));
    try {
        const response = await Request({
            url: 'https://api.generated.photos/api/v1/faces?per_page=1&gender=male&order_by=random',
            method: 'GET',
            headers: { 'Authorization': 'API-Key WudeqJRiCo5TT_ovZhv6-w' }
        });
        return response.faces[0].urls[4]["512"];
    } catch(error) {
        return Promise.resolve("https://picsum.photos/seed/"+(id+1)+"/240?random="+(id+1)); // fallback to placeholder image
    }
}*/


const upload = async (props, uploadProgressCallback) => {
    return (
        Request({
            url: "/user/upload",
            data: props,
            method: "POST",
            config: {
                onUploadProgress: progressEvent => {
                    let percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    if (percent >= 100) uploadProgressCallback(100);
                    else uploadProgressCallback(percent);
                }
            }
        })
    );
}

// get media by id
const getMedia = async (props) => {
    return(
        Request({
            url: "/user/media",
            method: "POST",
            data: { ...props }
        })
    )

}

// get all media from a user
const getAllMedia = async (props) => {
    return(
        Request({
            url: "/user/media/user",
            method: "POST",
            data: { ...props }
        })
    )
}

// update user media
const updateMedia = async (props) => {
    return (
        Request({
            url: `user/media/edit`,
            method: "POST",
            data: props
        })
    );
}

// delete user media
const removeMedia = async (props) => {
    return (
        Request({
            url: `user/media/${props.id}`,
            method: "DELETE",
            data: props
        })
    );
}

const checkEmail = async (props) => {

    return (
        Request({
            url: "user/check-email-availability",
            data: {...props},
            method: "POST"
        })
    );
}

const checkUsername = async (props) => {

    return (
        Request({
            url: "user/check-username-availability",
            data: {...props},
            method: "POST"
        })
    );
}

const tokens = async (props) => {
    return (
        Request({
            url: "/user/tokens",
            data: {...props},
            method: "POST",
        })
    );
}

const wallet = async (props) => {
    return (
        Request({
            url: "/user/wallet",
            method: "GET",
            data: props
        })
    );
}

const eventRegister = async (props) => {
    return (
        Request({
            url: "/user/event/register",
            method: "POST",
            data: props
        })
    );
}

const eventRemove = async (props) => {
    return (
        Request({
            url: "/user/event/remove",
            method: "DELETE",
            data: props
        })
    );
}

const userEvent = async (props) => {
    return (
        Request({
            url: "/user/event/" + props.id,
            method: "GET"
        })
    );
}

const userEventResponse = async (props) => {
    return (
        Request({
            url: "/user/event/request_response",
            method: "POST",
            data: props
        })
    );
}

const getOutstandingCharges = async (props) => {
    return (
        Request({
            url: "/user/outstanding_charges",
            method: "POST",
            data: {...props}
        })
    );
}


const Roles = {
    get: async props => {
        return (
            Request ({
                url: "/user/roles/" + props.user_id,
                data: {},
                method: "GET"
            })
        );
    },
    getAll: async props => {
        return (
            Request ({
                url: "/user/roles",
                data: {},
                method: "GET"
            })
        );
    },
    edit: async props => {
        return (
            Request ({
                url: "/user/" + props.user_id + "/roles",
                data: {role_ids: props.roles},
                method: "PUT"
            })
        );
    }
}

// const Family = {
//     get: async props => {
//         let data={data:null,errors:["User not found."]};
//         const auser=await  Request({
//             url: "/user/user/"+props.user_id,
//             method: "GET",
//             data: {props},
//             //test: mockdata // send mock data to simulate an api call
//         });
    
//         if (auser.data[0].family) {
//             data.data=[...auser.data[0].family];
//             data.errors=null;
//         }

//         return Promise.resolve(data);

//         /*return (
//             Request ({
//                 url: "/user/children/",
//                 data: {user_id:props.user_id},
//                 method: "GET",
//                 test: mockdata
//             })
//         );*/
//     },
// }

const Checkin = {
    getAll: async props => {
        return (
            Request ({
                url: "checkins/",
                data: props,
                method: "POST"
            })
        );
    },
    get: async props => {
        return (
            Request ({
                url: "user/checkin_history/",
                data: props,
                method: "POST"
            })
        );
    },
    create: async props => {
        return (
            Request ({
                url: "/user/checkin/",
                data: props,
                method: "POST"
            })
        );
    }
}

const Merge = {
    merge: async props=>{
        return(
            Request({
                url: "/user/merge",
                data: props,
                method: "POST"
            })
        )
    },
    undo_merge: async props=>{
        return(
            Request ({
                url:"/user/undo_merge",
                data: props,
                method: "POST"
            })
        )
    },
    get_user: async props=>{
        return(
            Request({
                url:`/user/merge/${props.user_id}`,
                data: props,
                method: "GET"
            })
        );
    },
    get_all_merges: async props=>{
        return(
            Request({
                url:'/user/merge',
                data: props,
                method: "GET"
            })
        );
    },
    get_merges_post: async props=>{
        return(
            Request({
                url:'user/merge/search',
                data: props,
                method: "POST"
            })
        );
    }
}

const PaymentProfile = {
    create: async props=>{
        return(
            Request({
                url: "payment/nmi_create_profile",
                data: props,
                method: "POST"
            })
        )
    },

    createFromTransaction: async props=>{
        return(
            Request({
                url: "payment/nmi_create_profile_from_transaction",
                data: props,
                method: "POST"
            })
        )
    }
}

const Users = {
    get, 
    getList, 
    getOutstandingCharges, 
    getGiftCards, 
    address, 
    orders, 
    purchasedProduct, 
    create, 
    update, 
    remove, 
    list, 
    forgotUser, 
    forgotPassword, 
    resetPassword, 
    resetPasswordEmail, 
    verify, 
    multiregister, 
    groups, 
    events, 
    upload, 
    getMedia, 
    getAllMedia, 
    updateMedia,
    removeMedia, 
    tokens, 
    wallet, 
    checkUsername, 
    login, 
    checkEmail, 
    setUsername, 
    eventRegister, 
    eventRemove, 
    userEvent, 
    userEventResponse, 
    memberships, 
    Roles, 
    Checkin, 
    Merge, 
    PaymentProfile, 
    getSocials
}

export default Users;
import React from 'react';
import {Button, ToggleButton} from 'react-bootstrap';
import { checkBoolean, toKebabCase, randomUUID } from '../../../../../utils/cms';

const FormGroupButton =  React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    let Component = Button;
    if (props?.toggle) Component = ToggleButton;

    let type="button";
    switch (props.click){
        case "Submit Form":
            type="submit";
            break;
        case "Reset Form":
            type="reset";
            break;
        default:
            type="button";
            break;
    }
    if (props.type) type=props.type;

    const _disabled = props.submitting || checkBoolean(props?.disabled) || false;

    return (
        <Component 
            id={`${toKebabCase(props?.label)}-${randomUUID()}`}
            ref={ref} 
            type={type}
            className={`form-group-button_${props.page_id} ${props.className || ""}`} 
            style={props.style} 
            variant={props?.variant || "primary"}
            href={props.click==="Open Link"?props?.href || undefined:undefined}
            onClick={typeof props.click==="function"?props.click : preview_click}
            disabled={_disabled}
            value={props?.value || undefined}
            >
                {props?.label || "Button" }
        </Component>
    );
});

export default FormGroupButton;
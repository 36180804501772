import React, { useState, useRef, useEffect, useCallback } from 'react'
import { format } from 'date-fns'
import { Button } from 'react-bootstrap';

import Pos from '../../../../../api/Pos';

import "../../../../../assets/css/scss/pages.scss"
import DisplayOrdersTable from '../DisplayOrdersTable'
import DisplayOrdersDetailTable from './DisplayOrdersDetailTable'
import DisplayOrdersParentDetailTable from './DisplayOrdersParentDetailTable'
import RefundDisplay from '../../../../Pay/Refund/RefundOrderDisplay'
import MobileDisplayOrders from './MobileDisplayOrders'
import MobileRefundDisplay from '../../../../Pay/Refund/RefundOrderDisplay/MobileRefundDisplay'
import MobileDisplayOrdersTable from '../DisplayOrdersTable/MobileDisplayOrdersTable'
import PrintFullPage  from '../../../../POS/Print/PrintFullPage'
import Print from '../../../../POS/Print'


/** Component that simply displays the orders from a transaction. 
 * @param isAdmin 
 * @param isPatron
 * @param object props.currentOrder -the order that is being displayed
 * @param function props.handleCheckbBox  -what happens when an order is checked
 * @requires order_status_id===2(Completed)||order_status_id===5(partially_refunded) for checkboxes to be enabled.  Being 1(Pending), 3(Cancelled), or 4(Refunded) will disbale checkboxes
 */
export const DisplayOrders = (props) => {

    const mountedRef = useRef(false)
    const [ refunded, setRefunded ]=useState(false);
    const [ partialRefund, setPartialRefund ]=useState(false);
    const [ modifiedRefund, setModifiedRefund] =useState(Object.assign({}, props.currentOrder));
    const [ modifiedCompleted, setModifiedCompleted]=useState(Object.assign({}, props.currentOrder));
    const [ userDetails, setUserDetails ]=useState();
    const [ parentOrder, setParentOrder ]=useState(null);
    const [ printerHasProps, setPrinterHasProps ]=useState(false);
    const [ paymentTypeForPrint, setPaymentTypeForPrint ]=useState(props.currentOrder.payment_method || "Cash");
    const [ print, setPrint ]=useState(false);

    const createUserDetails=useCallback(()=>{
        let details = {
            first_name: props.currentOrder?.user_fname,
            middle_name:null,
            last_name: props.currentOrder?.user_lname,
            address1:props.currentOrder?.email,
            address2:null,
            city:null,
            state:null,
            postal_code:null
        }
        setUserDetails(details)
    },[props])

    //Set mounted on mount and unmount
    useEffect(()=>{
        mountedRef.current =true

        return()=>{
            mountedRef.current = false
        }
    },[])

    //Determine display type for order based on refund status
    useEffect(()=>{
        /**Function that splits items into two different arrays based on their refund status. */
        const splitItems=()=>{
            let tempItems = props.currentOrder.items
            let itemsRefunded =[];
            let itemsCompleted =[];
            tempItems.forEach((item)=>{
                if(item.total_refunded_amount !== 0 || item.shipping_refunded_amount !== 0){
                    itemsRefunded.push(item)
                }
                else itemsCompleted.push(item)
            })
            if(mountedRef.current === true){
                let completed = modifiedCompleted;
                let refunded = modifiedRefund;
                completed.items = itemsCompleted;
                refunded.items = itemsRefunded;
                setModifiedCompleted(completed);
                setModifiedRefund(refunded);
            } 
        }

        const getParentOrder=async()=>{
            setPaymentTypeForPrint("spMultiSingle")
            try{
                let response = await Pos.order.get({id: props.currentOrder.parent_id})
                if(response.status === 200){
                    setParentOrder(response.data)
                }
            }catch(ex){
                console.error(ex)
            }
        }

        if(props.currentOrder && props.currentOrder.parent_id) getParentOrder();
        splitItems();
        createUserDetails();

    },[props.currentOrder, createUserDetails, modifiedCompleted, modifiedRefund]);
    
    //Determines full or partial refund for split display.  
    useEffect(()=>{
        if(props.currentOrder.order_status_id === 4 && mountedRef.current) setRefunded(true);
        if (modifiedRefund.items.length >0 && modifiedCompleted.items.length > 0 && mountedRef.current) {
            setPartialRefund(true);
            setRefunded(false);
        }else if(modifiedRefund.items.length >0 && modifiedCompleted.items.length ===0 && mountedRef.current) setRefunded(true);
    }, [modifiedRefund, modifiedCompleted, props.currentOrder.order_status_id])

    
    //Determines full or partial refund for split display.  
    useEffect(()=>{
        if(props.currentOrder.order_status_id === 4 && mountedRef.current===true) setRefunded(true);
        if (modifiedRefund.items.length >0 && modifiedCompleted.items.length > 0 && mountedRef.current === true) {
            setPartialRefund(true);
            setRefunded(false);
        }
    }, [modifiedRefund, modifiedCompleted, props.currentOrder.order_status_id]);



    return (
    <div>
        {props.mobile ? 
            <MobileDisplayOrders 
                currentOrder={props.currentOrder}
                refunded={refunded}
                partialRefund={partialRefund}
                isPatron={props.isPatron}
                isAdmin={props.isAdmin}
            />
            :    
            <>
                <h5 className="d-flex justify-content-between">
                    <span>
                        <i className="far fa-receipt" />
                        {" "}
                        Order #{props.currentOrder.id}
                    </span>
                    <span>
                        {(props.from==="transactionHistory" || props.from==="orders-page") && !props.currentOrder.split_order_ids.length &&
                            <PrintFullPage 
                                // transSuccess
                                userDetails={userDetails}
                                setPrinterHasProps={setPrinterHasProps}
                                order={props.currentOrder}
                                items={parentOrder ? parentOrder.items : props.currentOrder.items}
                                btnText={<><i  className="far fa-print cp" />Print Invoice Copy</>}
                                refunded={refunded}
                                partialRefund={partialRefund}
                                parentOrder={parentOrder}
                            />
                        }
                        {!props.currentOrder.split_order_ids.length &&
                            <Button
                                onClick={()=>setPrint(true)}
                                data-cy="order-details-print-btn"
                            >
                                <i  className="far fa-print cp" /> Print Receipt Copy
                            </Button>
                        }
                    </span>
                </h5>
            <h5 data-cy="order-status-name">Order Status: {props.currentOrder.status_name}</h5>
            <div className="uo-order-table-wrapper">
                <DisplayOrdersDetailTable 
                    currentOrder={props.currentOrder}
                    transactionType={props.transactionType}
                    paymentMethod={props.paymentMethod}
                    refunded={refunded}
                    partialRefund={partialRefund}
                    isPatron={props.isPatron}
                    transaction={props.transaction}
                />
            </div>
            </>
        }   
        {props.isPatron ? 
            null : 
            <p className="small my-3">
                **Please note, Tokens and Token bundles are currently not refundable.**  
                <br />
                To start a refund or return, use the "Refund" button.
            </p>
        }
        <hr />
        {props.currentOrder?.coupons_applied?.length > 0 &&
            <>
                <span>
                    <h5>
                        <i className="far fa-badge-percent" />
                        Coupons Applied
                    </h5> 
                </span>
                {props.currentOrder.coupons_applied?.map((coupon, i)=>(
                    <p key={`uo-coupon-${i}`} className="uo-coupon-p">
                        <span className="coupon-title">Coupon:</span> {coupon.name}
                    </p>
                ))}
                <hr />
            </>
        }
        {props.currentOrder&& props.currentOrder?.payment_method_id!==7 && !props.currentOrder.parent_id &&
            <>{partialRefund ?
                <> 
                    <h5>
                        <i className="far fa-shopping-basket"/>
                        Order Items
                    </h5> 
                    <h6>
                        <i className="far fa-tag" />
                        Refunded Items
                    </h6> 
                    {props.mobile ?
                        <MobileRefundDisplay currentOrder={modifiedRefund} isPatron={props.isPatron} />
                    :
                        <RefundDisplay currentOrder={modifiedRefund} isPatron={props.isPatron} />
                    }
                    <h6>
                        <i className="far fa-tags" />
                        Order Items
                    </h6>
                    {props.mobile ? 
                        <MobileDisplayOrdersTable currentOrder={modifiedCompleted} handleCheckBox={props.handleCheckbox} isAdmin={props.isAdmin} isPatron={props.isPatron} />
                    :
                        <DisplayOrdersTable currentOrder={modifiedCompleted} handleCheckBox={props.handleCheckBox} isAdmin={props.isAdmin} isPatron={props.isPatron}/>
                    }
                </>
            :
                <>
                    {refunded ?
                        <>
                            <h5>
                                <i className="far fa-tag" />
                                Refunded Items
                            </h5> 
                            {props.mobile ? 
                                <MobileRefundDisplay currentOrder={props.currentOrder} isPatron={props.isPatron} />
                                :
                                <RefundDisplay currentOrder={props.currentOrder} isPatron={props.isPatron}/>
                            }
                        </>
                    :
                        <>
                            <h5>
                                <i className="far fa-tags" />
                                Order Items
                            </h5> 
                            {props.isMobile ? 
                                <MobileDisplayOrdersTable handleCheckbox={props.handleCheckBox} isAdmin={(props.currentOrder.split_order_ids && props.currentOrder.split_order_ids.length > 0) ? false : props.isAdmin} currentOrder={props.currentOrder} isPatron={props.isPatron} />
                            :
                                <DisplayOrdersTable handleCheckBox={props.handleCheckBox} isAdmin={(props.currentOrder.split_order_ids && props.currentOrder.split_order_ids.length > 0) ? false : props.isAdmin} currentOrder={props.currentOrder} isPatron={props.isPatron} />
                            }
                        </>
                    }
                </>
            }</>
        }   
        {props.currentOrder?.payment_method_id===7 && //Token redemptions
            <span className="d-flex justify-content-center mb-5 bold">
                This transaction was a token redemption.
            </span>
        }
        {props.currentOrder.parent_id && //child of a split payment
            <>
                <span className="d-flex justify-content-center mb-5 bold">
                    This order is a child order to #{props?.currentOrder?.parent_id}
                </span>
                {parentOrder &&
                    <>
                        <DisplayOrdersParentDetailTable parentOrder={parentOrder} />
                        {props.isMobile ? 
                            <MobileDisplayOrdersTable handleCheckbox={props.handleCheckBox} isAdmin={false} currentOrder={parentOrder} isPatron={props.isPatron} />
                        :
                            <DisplayOrdersTable handleCheckBox={props.handleCheckBox} isAdmin={false} currentOrder={parentOrder} isPatron={props.isPatron} />
                        }
                    </>
                }
            </>
        }
        {props.currentOrder.split_order_ids.length >0 &&
            <span className="d-flex justify-content-center mb-3 bold">
                This order was split into the following orders -- {" "}
                {props.currentOrder.split_order_ids.map((order, i)=>(
                    <React.Fragment key={`child-orders-${i}-${order}`}>
                        #{order} {" "}
                    </React.Fragment>
                ))}
            </span>
        }
        {print &&
            <Print 
                register_id={props.currentOrder.register_id}
                items={parentOrder ? parentOrder.items : props.currentOrder.items}
                customer={{first_name: props.currentOrder.user_fname, last_name: props.currentOrder.user_lname, id: props.currentOrder.user_id}}
                order_id={props.currentOrder.id}
                order={parentOrder ? parentOrder : props.currentOrder}
                status={props.currentOrder.order_status_id}
                payment_type={paymentTypeForPrint}
                copies={1}
                tip={props.currentOrder.tip}
                updateDate={props.currentOrder.updated_at}
                onAfterPrint={()=>setPrint(false)}
                kitchenTicket={true}
                payee={parentOrder ? {
                    order:{
                        subtotal_price: props.currentOrder.subtotal_price,
                        tax_total: props.currentOrder.tax_total,
                        admin_fee_total: props.currentOrder.admin_fee_total,
                        total_price: props.currentOrder.total_price,
                    }
                } : null}
            />
        }
    </div>
  )
}

import React, { useRef, useEffect, useState } from 'react';
import {ReactComponent as WoldMap} from './world.svg';

const GoogleMapMarker = (props) => {
    useEffect(() => {
        if (!props.map) return;
        const marker = new window.google.maps.Marker({position: props.position, map: props.map});
        return () => marker.setMap(null);
    }, [props.position, props.map]);
  
    return <>{props.children}</>;
}

const Map = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        window.initMap = () => {
            const map = new window.google.maps.Map(mapRef.current, {
                center: { lat: +props.lat, lng: +props.lng },
                zoom: props.map_zoom || 10,
                minZoom: props.min_zoom || 3,
                maxZoom: props.max_zoom || 21,
                disableDefaultUI: true,
                scrollwheel: props.is_preview? false : true,
                mapTypeId: props.map_style || 'roadmap'
            });
            setMap(map);
            setLoaded(true);
        };

        let googleMapsScript = document.getElementById('googleMapsScript');
        if (!googleMapsScript) {
            if (mapRef.current){
                googleMapsScript = document.createElement('script');
                googleMapsScript.id = 'googleMapsScript';
                googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${props.api_key}&callback=initMap`;
                document.body.appendChild(googleMapsScript);
            }
        } else {
            //if (mapRef.current && loaded) window.initMap();
        }
        
        
        return () => {
            window.initMap = undefined;
        };
    }, [props, mapRef, loaded]);

    useEffect(() => {
        return () => {
            setMap(null);
            setLoaded(false);
        };
    }, []);

    if (!props.api_key || !props.lat || !props.lng ) return <WoldMap ref={ref} width="100%" onClick={preview_click} />;

    return (
        <div ref={ref} onClick={preview_click} style={{ width: '100%', minHeight: '200px', padding:0, margin: 0 }}>
            {!loaded && <WoldMap width="100%" />}
            <div ref={mapRef} style={{ width: '100%', height: '200px', ...(props.style || {})}}>
                {map && <GoogleMapMarker map={map} position={{ lat: +props.lat, lng: +props.lng }} />}
            </div>
        </div>
    );
});

export default Map;
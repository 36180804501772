import React, { useState } from 'react';
import { format } from 'date-fns';

import { ExpandedRow } from './ExpandedRow';
import { TableSkeleton } from '../TableSkeleton/TableSkeleton';

import styles from './TableWithDropdown.module.scss';

/**
 * 
 * @param {*} loading status from the parent component - will show loading skeleton if loading
 * @param {*} skeletonRows If loading, will show the number of rows passed here.  Columns will be based on the number of headings
 * @param {*} title Is a title that shows above the table if desired
 * @param {*} tableHeadings Must be passed as an array of objects.  [{key: "must match data", label: "what you want it to say"}]. Optionally, you can include a "sortHeader", which will add the sorting carats
 * @param {*} hiddenHeadings Hides the heading row, but still uses it for what data goes where
 * @param {*} data Our standard arrays of data from most of our endpoints
 * @param {*} expandedRow If true, will allow for an expanded row with additional data
 * @param {*} expandedText What the expanded column indicator will be
 * @param {*} expandedHeadings The data that will be shown in the expanded row - formatted like tableHeadings
 * @param {*} ellipsis If true, will add the ellipsis class to the table that handles overflow text
 * @param {*} rowOnClick If passed, will allow for a row to be clicked and will pass the row data to the function
 * @param {*} clickCell If true, will allow for the last cell to be clicked and will pass the row data to the function 
 * @param {*} clickCellContent What the content of the last cell will be - can pass in something like a button or icon
 * @param {*} clickCellFunction The function that will be called when the last cell is clicked
 * @param {*} dateFormat The format that the date will be shown in, in line with date-fns documentation for "format"
 * @param {*} sortOnClick A function to carry both the sort row and the sort direction back to the parent component.
 * 
 */
export const TableWithDropdown =({
    loading=false,
    skeletonRows=25,
    title="",
    tableHeadings=[],
    hiddenHeadings=false,
    data=[],
    expandedRow=false,
    expandedText="Additional Info",
    expandedHeadings=[],
    ellipsis=false,
    rowOnClick=null,
    clickCell=true,
    clickCellContent="Click Here",
    clickCellFunction=null,
    dateFormat = "MM/dd/yy",
    sortOnClick=null,
    name="",
    overrideExpandedRow=false,
    overrideExpandedObject=<tr></tr>
})=>{

    const [ showExpanded, setShowExpanded ] = useState(false);
    const [ expandedRowId, setExpandedRowId ] = useState(null);
    const [ sortColumn, setSortColumn] =useState("id");
    const [ sortDirection, setSortDirection ] = useState("DESC");

    const handleExpanded=(row)=>{
        if(showExpanded && row.id === expandedRowId){
            setExpandedRowId(null);
            setShowExpanded(false);
        }else{
            setExpandedRowId(row.id);
            setShowExpanded(true);
        }
    }

    const handleHeader=(sortHeader)=>{
        setSortColumn(sortHeader);
        let direction = sortDirection === "DESC" ? "ASC" : "DESC";
        setSortDirection(direction)
        sortOnClick(sortHeader, direction)
    }

    return(
        <>
            {title &&
                <span className="section-title">
                    {title}
                </span>
            }
            {tableHeadings.length > 0 &&
                <table
                    className={`
                        ${ellipsis ? styles.ellipsis : ''}
                        ${styles["table-wrapper"]}
                    `}
                    data-cy={`${name}-table`}
                    name={name}
                >
                    {!hiddenHeadings && 
                        <thead className={styles["top-heading"]}>
                            <tr className={styles.headings}>
                                {tableHeadings?.map((heading, i)=>{
                                    let isString = typeof heading?.label === "string" ? true : false;
                                    return(
                                        isString ?
                                            <React.Fragment key={`heading-td-${i}`} >
                                                {heading?.sortHeader ?
                                                    <td onClick={()=>handleHeader(heading?.sortHeader)} data-cy={`header-sort-td-${heading?.sortHeader}`}>
                                                        <span>{heading.label}</span>
                                                        <i className={`ml-1 fad fa-${sortColumn===heading.key?sortDirection==="DESC"?'sort-down':'sort-up':'sort'}`}></i>
                                                    </td>
                                                :
                                                    <td key={i} data-cy={`header-label-${heading?.label}`}>{heading?.label}</td>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment key={`heading-td-${i}`} >
                                                {/* this is used for passing in any html element we want to be here 
                                                - as such anything needed like a name, data-cy, etc, 
                                                should be included in that element passed in */}
                                                {heading?.label}   
                                            </React.Fragment>
                                    )
                                })}
                                {expandedRow &&
                                    <td data-cy="expanded-td-heading">Expanded</td>
                                }
                                {clickCell && clickCellFunction &&
                                    <td data-cy="extra-spaced-heading">{" "}</td>
                                }
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {loading ?
                            <TableSkeleton columns={tableHeadings.length} rows={skeletonRows} data-cy="table-loading-skeleton"/>
                        :
                            <>
                                {data?.map((row, i)=>(
                                    <React.Fragment key={`row-${row.id}-${i}`}>
                                        <tr onClick={rowOnClick ? ()=>rowOnClick(row) : null} data-cy={`each-table-row-${row.id}`}>
                                            {tableHeadings?.map((heading, j)=>(
                                                <td key={`${heading.key}-${j}`}>
                                                    {heading?.key?.toLowerCase()?.includes("date") || heading?.key?.toLowerCase()?.includes("updated_at") || heading?.key?.toLowerCase()?.includes("created_at") ?
                                                        <>
                                                            {row[heading?.key] && format(new Date(row[heading?.key]), dateFormat)}
                                                        </>
                                                    :
                                                        <>
                                                            {heading?.key?.includes("multiple-") && heading.hasOwnProperty("multiple") ?
                                                                <>
                                                                    {heading?.multiple?.map((multi, k)=>(
                                                                        <span key={`heading-multiple-${multi}-${k}`}>
                                                                            {row[multi]}{" "}
                                                                        </span>
                                                                    ))}
                                                                </>
                                                            :
                                                                <>
                                                                    {row[heading?.key]}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </td>
                                            ))}
                                            {expandedRow && 
                                                <td className={styles.cp} onClick={()=>handleExpanded(row)} data-cy="handle-expanded-td"> 
                                                    {expandedText}
                                                </td>
                                            }
                                            {clickCell && clickCellFunction &&
                                                <td className={styles.cp} onClick={()=>clickCellFunction(row)} data-cy="handle-detail-click-td">
                                                    {clickCellContent}
                                                </td>
                                            }
                                        </tr>
                                        <ExpandedRow 
                                            overrideRow={overrideExpandedRow}
                                            overrideObject={overrideExpandedObject}
                                            hiddenHeadings={hiddenHeadings}
                                            row={row}
                                            expandData={expandedHeadings}
                                            showExpanded={showExpanded}
                                            expandedRowId={expandedRowId}
                                        />
                                    </React.Fragment>
                                ))}
                            </>
                        }
                    </tbody>
                </table>
            }
        </>
    )
}
import * as actionTypes from './actionTypes';

export const selectedCoupon = (coupon) => {
    return {
        type: actionTypes.SET_COUPON,
        payload: coupon
    };
};

export const setParams = (params) => {
    return {
        type: actionTypes.SET_PARAMS,
        payload: params
    };
};

export const setParamInitData = (payload) => {
    return {
        type: actionTypes.SET_PARAM_INIT_DATA,
        payload: payload
    };
};

export const setParamMultiInputData = (itemList) => {
    return {
        type: actionTypes.SET_PARAM_MULTI_INPUT_DATA,
        payload: itemList
    };
};

export const setConditions = (conditions) => {
    return {
        type: actionTypes.SET_CONDITIONS,
        payload: conditions
    };
};

export const setErrors = (errors) => {
    return {
        type: actionTypes.SET_ERRORS,
        payload: errors
    };
};

export const addToErrors = (errors) => {
    return {
        type: actionTypes.SET_ADDITIONAL_ERRORS,
        payload: errors
    };
};

export const resetAllErrors = () => {
    return {
        type: actionTypes.RESET_ERRORS
    };
};

export const resetErrors = () => {
    return dispatch => {
        dispatch(resetAllErrors());
    };
};

import React, {useState, useEffect} from 'react';
import {Container, Button} from 'react-bootstrap';

import APIRegisters from '../../../../../api/Registers';

import styles from '../Wizard.module.scss';

export const Register = (props) => {
    const {stepUpdate} = props;
    
    const [registers, setRegisters] = useState([]);
    const [selected, setSelected] = useState(props?.stepData?.register_id || props.order.register_id);

    const clickHandler = register_id => setSelected(register_id);

    useEffect(()=>{
        stepUpdate(props.stepId, {register_id: selected});
    },[stepUpdate, props.stepId, selected]);

    useEffect(()=>{
        const getRegisters = async ()=>{
            console.log("call")
            try{
                const response = await APIRegisters.get()
                if (response.data) setRegisters(response.data);
            } catch(ex){
                console.error(ex);
            }
        }
        getRegisters();

        return () => setRegisters([]);
    }, []);

    return (
        <Container fluid data-cy="adv-refund-register-select">
            <h4 className={`${styles.title}`}>Which Register will issue the refund?</h4>
            <hr/>
            {registers?.map((register, i)=>(
                <Button 
                    key={`refund-reg-[${i}]${register.id}`} 
                    onClick={e => clickHandler(register.id)}
                    size="lg"
                    className={selected === register.id ? "btn-primary" : "btn-light"}
                >
                    {register.name}
                </Button>
            ))}
        </Container>
    );
}
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Modal, Button } from 'react-bootstrap';
import { format, differenceInMinutes } from 'date-fns';

import Pos from '../../../../../api/Pos';
import Events from '../../../../../api/Events';

import ErrorCatcher from '../../../../../components/common/ErrorCatcher';

import './SelectEvent.css'

export const SelectEvent = props => {
    let history = useHistory();

    const [userId, setUserId] = useState();
    const [tokenId, setTokenId] = useState();
    const [productId, setProductId] = useState();
    const [show, setShow] = useState();
    const [events, setEvents] = useState([]);
    const [error, setError] = useState();

    const [modalSize, setModalSize] = useState();
    const [modalTitle, setModalTitle] = useState();
    const [body, setBody] = useState();
    const [footer, setFooter] = useState();

    const defaultView = useCallback(() => {
        const clickHandler = async event => {
            setModalSize("md");
            let confirmClick = () => {
                Pos.payment.token(
                    {
                        token_id: tokenId,
                        event_id: event.id,
                        user_id: userId
                    }
                ).then(response => {
                    if(!response.errors) {
                        setModalTitle("Thank You!");
                        setBody(
                            <Modal.Body>
                                Token successfully redeemed.
                            </Modal.Body>
                        );
                        setFooter(
                            <Modal.Footer>
                                <Button onClick={() => history.go(0)}>Close</Button>
                            </Modal.Footer>
                        );
                    } else {
                        setError(<ErrorCatcher error={response.errors} />)
                    }
                }).catch(e => console.error(e));
            };
    
            let datetime = new Date(event.start_datetime);
            let time = format(datetime, "h:mm aa");
            let date = format(datetime, "MMM do yyyy");
    
            setBody(
                <Modal.Body>
                    Are you sure you want to redeem this token for {event.name} at {time} on {date}?
                </Modal.Body>
            );
            setFooter(
                <Modal.Footer>
                    <Button variant="secondary" onClick={defaultView}>
                        No
                    </Button>
                    <Button variant="primary" onClick={confirmClick}>
                        Yes
                    </Button>
                </Modal.Footer>
            );
        }
    
        setModalSize("lg");
        setModalTitle("Redeem Token");
        setBody(
            <Modal.Body className="selectEvent">
                <span>Please select an event or class:</span>
                <hr />
                {/* getting a location name from the API call would be nice */}
                {events.map( (event, i) => {
                    let start = new Date(event.start_datetime);
                    let end = new Date(event.end_datetime);
                    let duration = differenceInMinutes(end, start);
                    if (duration % 15 !== 0) duration += 1;
                    let durationM = (((duration / 60) % 1) * 60).toFixed(0);
                    let durationH = ((duration - durationM) / 60).toFixed(0);

                    return (
                    <Card key={`token-event-pos-${i}`} as="a" href="#!" className="info-container detail-card product" onClick={ () => clickHandler(event) }>
                        <div><span className="bold">{event.name}</span></div>
                        <div>{format(start, "MM/dd/yyyy h:mm aa")}</div>
                        <div>Duration: {durationH > 0 ? durationH + " hour" + (durationH > 1 ? "s" : "") : ""}{durationM > 0 ? " " + durationM + " minutes" : ""}</div>
                    </Card>
                    )
                })}
            </Modal.Body>
        );
        setFooter(null);
    },[events, history, tokenId, userId]);

    useEffect(() => {
        setShow(props.show);
        setUserId(props.user_id);
        setTokenId(props.token?.id);
        setProductId(props.token?.product_id);
        setError(null);
    },[props])

    useEffect(() => {
        let mounted = true;

        if(productId) {
            Events.getSimple({
                product_id: productId,
                start_datetime: format(new Date(), "yyyy-MM-dd") + "T00:00:00-05:00",
                event_status_id: 2 // status Confirmed
            })
            .then( response => {
                if(mounted) {
                    setEvents(response.data?.events);
                }
            }).catch(e => console.error(e));
        }

        return () => {
            mounted = false;
            setEvents([]);
        }
    },[productId])

    useEffect(() => {
        if(show) defaultView();
        setError(null);
    },[show, events,defaultView])

    const closeHandler = () => {
        setShow(false);
    }

    return (
        <React.Fragment>
            <Modal size={modalSize} show={show} onHide={closeHandler} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                {body}
                {footer}
            </Modal>
            {error}
        </React.Fragment>
    );

}
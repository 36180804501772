import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import APIPOS from '../../../../../../api/Pos';
import {loadOrderIntoRedux} from '../../../../../../utils/thunks';
import {randomUUID} from '../../../../../../utils/cms';
import * as actions from '../../../../../../store/actions';

import styles from './CartIcon.module.scss';

const icon_styles = ["far", "fas", "fal", "fab", "fad"];

const CartIcon = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();

    let activeRegister=useSelector(state=>state.pos.register);
    if (props.register_id) activeRegister=props.register_id;
    
    const user = useSelector(state => state.auth.user);

    const [numItems, setNumItems] = useState(0);
    const [localOrderId, setLocalOrderId] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(false);


    useEffect(() => {
        if (localOrderId && !props.is_preview){
            APIPOS.order.get({id: localOrderId}).then(response=>{
                if (!response.errors && response.data && response.data.split_order_ids.length===0){
                    setNumItems(response.data?.items?.length);
                    dispatch(loadOrderIntoRedux(response.data, activeRegister));
                }
                setIsFirstLoad(true);
            });
        }
    }, [localOrderId, props.is_preview, activeRegister, dispatch]);

    useEffect(() => {
        if (props.is_preview) {
            setNumItems(2);
            setIsFirstLoad(true);
        } else if (!localOrderId && !isFirstLoad && user?.profile?.id){
            if (localStorage.getItem(`POS-${activeRegister}-order-id`)) setLocalOrderId(JSON.parse(localStorage.getItem(`POS-${activeRegister}-order-id`)));
            else {
                APIPOS.order.latestOpen({ user_id: user.profile.id, register_id: activeRegister }).then( async response => {
                    if (!response.errors && response.data) {
                        if (response.data.order_status_id === 1) {
                            setLocalOrderId(response.data.id);
                        } else {
                            setNumItems(0);
                            APIPOS.local.remove(`POS-${activeRegister}-order-id`);
                            dispatch(actions.setPCReset());
                            dispatch(actions.reset());
                        }
                    } else if (response.errors) {
                        console.error(response.errors);
                    }
                });
            }
        }
    }, [props.is_preview, activeRegister, dispatch, user?.profile?.id, localOrderId, isFirstLoad, numItems]);


    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    let fa_style="far";
    if (props.icon_style) {
        if (icon_styles.includes(props.icon_style)) fa_style=props.icon_style;
    }

    return (
        <div ref={ref} id={props.is_preview ? `preview-${randomUUID()}` : props.id} className={`${props.className || ""} ${styles.container}`} style={props.style || null}>
            <a href={!props.is_preview && props.url? props.url : "#!"} target={props.target || "_self"} onClick={preview_click} className={styles.icon}>
                <i className={`${fa_style} fa-shopping-cart`}/>
            </a>
            {numItems>0 &&
                <div className={styles["cart-num-items"]}>{numItems}</div>
            }
        </div>    
    );
});

export default CartIcon;
import React from 'react';
import { Button } from 'react-bootstrap';

import styles from './Section.module.scss';

export const Section = (props) => {
    return (
        <div className={`section ${styles.section}`}>
            <Button variant="primary" onClick={props.add}>Add Section</Button>
        </div>
    );
}
import React, { useState } from 'react';
import BsToast from 'react-bootstrap/Toast';

import './Toast.scss';

export const Toast = (props) =>{
    const [show, setShow] = useState(true);

    return (
        <div className={`toast-wrapper${show?" show":""}`}>
            <BsToast onClose={() => setShow(false)} show={show} delay={3000} autohide data-cy="toast-message">
                <BsToast.Body>{props.children}</BsToast.Body>
            </BsToast>
        </div>
    );    
}
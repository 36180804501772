import React from 'react';
import { randomUUID } from '../../../../../utils/cms';
import styles from './Slider.module.scss';

const Slide =  React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    let style = {...props.style, position: "relative", height: props.custom_height || undefined, animationDuration: props.transition_time || 1000};

    if (!props.is_preview){
        if (props.is_carousel){
            style = {...style, flex: 1};
            if (+props.current_step === +props.selected_step) style = {...style, flexBasis: "80%"};
            else style = {...style, width: 20/(props.steps || 1)+"%"};
        } else {
            if (props.transition_type === "left" || props.transition_type === "right" || props.transition_type === "up" || props.transition_type === "down"){
                style = {...style, flex: 1};
            } else if (props.transition_type === "fade"){
                style = {...style, width:"100%", position: "absolute", top: 0, left: 0, /*zIndex: +props.current_step !== +props.selected_step ? 9995 : 9999*/};
            } else {
                style = {...style, width:"100%", position: "absolute", top: 0, left: 0, opacity: +props.current_step !== +props.selected_step ? 0 : 1};
            }
        }
    } else style = {...style, flex: 1};

    return (
        <>
            {props.is_preview && <span className="step-label">{props?.slide_title || `SLIDE #${props?.slide_number}` || "SLIDE"}</span>}
            <div 
                id={props.is_preview ? `preview-${randomUUID()}` : props?.id} 
                ref={ref} 
                className={`slide_${props.page_id} ${props.className || ""} ${+props.current_step === +props.selected_step || props.is_preview ? props.active_class || styles.active : ""}`.trim().replace(/\s+/g,' ')} 
                style={style} onClick={preview_click}
            >
                {props?.children}
            </div>
        </>
    );
});

export default Slide;
import React from 'react'
import QRCode from "react-qr-code";

const DEFAULT_PRINT_WIDTH = "320px";

const styles = {
    container: {
        padding: "15px",
    },
    label: {
        fontFamily: "'Roboto', sans-serif",
        fontSize: "0.9rem",
        fontWeight: "500",
        marginBottom: "14px",
        textAlign: "center",
    },
    avoidBreak: {
        pageBreakAfter: "aviod"
    },
    forceBreak: {
        pageBreakAfter: "always"
    }
}

export const QRCodeLabel = (props) => {

    let width = props.width || DEFAULT_PRINT_WIDTH;
    let bigText = props.bigText || null;

    let adjustedContainerStyle = {
        ...styles.container,
        width: width
    }

    return (
        <div className="qr-code-container" style={adjustedContainerStyle}>
            {bigText &&
                <div style={styles.label}>{bigText}</div>
            }
            <span style={styles.avoidBreak}></span>
            <QRCode
                value={props.value}
                size={256}
                style={{ maxWidth: "100%", height: "auto" }}    // make the size responsive to the container size
                viewBox={`0 0 256 256`} // this is needed to make the size responsive - not sure what this does
            />
            <span style={styles.forceBreak}></span>
        </div>
    )
}

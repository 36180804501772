import Header from './Components/Header';
import Footer from './Components/Footer';
import UpcomingEvents from './Components/Events/UpcomingEvents';
import EventsView from './Components/Events/EventsView';
import Login from './Components/Login';
import SignUp from './Components/SignUp';
import SignUpFamily from './Components/SignUpFamily';
import Map from './Components/Map';
import Gallery from './Components/Gallery';
import Wysiwyg from './Components/Wysiwyg';
import Html from './Components/Html';
import Heading from './Components/Heading';
import Layout from './Components/Layout';
import List from './Components/List';
import Text from './Components/Text';
import Anchor from './Components/Anchor';
import Card from './Components/Card';
import Image from './Components/Image';
import Video from './Components/Video';
import Button from './Components/Button';
import Menu from './Components/Menu';
import ContentBlock from './Components/ContentBlock';
import Slider from './Components/Slider';
import Slide from './Components/Slider/Slide';
import GroupPage from './Components/_Special/GroupPage';

import CartIcon from './Components/Cart/CartIcon';
import Checkout from './Components/Cart/Checkout';

//import Onboarding from './Components/Onboarding';

import {Components as wizardStuff} from './_wizards';
import {Components as formStuff} from './_forms';

const Components = {
    /*
    Onboarding: {
        component: Onboarding,
        name: "Onboarding",
        component_name: "Onboarding",
        tooltip: "Onboarding process for SiteBoss",
        apply_styles: true,
        can_drop: false,
        hidden: false,
        list: "Elements",
        props: []
    },
    */


    Layout: {
        component: Layout,
        name: "Layout",
        component_name: "Layout",
        tooltip: "The layout is a container that can be used to group other elements together",
        apply_styles: true,
        can_drop: true,
        list: "Elements",
        props: [
            {
                name: "layout-type",
                type: "select",
                options: [{value: "Container", text: "Section"}, "Row", "Column"],
                description: "The type of layout to use",
                display_name: "Type",
                readonly: true,
                value: "Row",
                elements: ["Layout"]
            },
            {
                name: "layout-centered",
                type: "checkbox",
                description: "Whether the content should be centered or not",
                display_name: "Center Content",
                value: 0,
                elements: ["Layout"],
                condition: {
                    name: "layout-type",
                    value: ["Container"]
                }
            },
            {
                name: "fluid",
                type: "select",
                options: ["Full Width","Boxed"],
                description: "Whether the layout should be full width or not",
                display_name: "Content Width",
                value: "",
                restrict_to: ["Row"], // value is passed to this component after its set in the parent... in this case we set the value in the container but pass it to the row
                elements: ["Layout"],
                condition: {
                    name: "layout-type",
                    value: ["Row","Container"]
                }
            },
            {
                name: "fluid-max-width",
                type: "custom",
                description: "The maximum width of the layout",
                display_name: "Max Width",
                value: "",
                restrict_to: ["Row"],
                elements: ["Layout"],
                condition: {
                    name: "fluid",
                    value: ["Boxed"]
                },
                source:{
                    component: "containers/Cms/Components/Properties/LayoutMaxWidth",
                    props: {
                        maxWidths: "{value}", // this is the value from up there... because its dinamic, we wrap it in curly braces so it grabs the value from the json
                        selection: "method:selection" // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas
                    },
                }
            },
            {
                name: "custom-layout",
                type: "custom",
                description: "Allows you to define a custom structure to organize the content of the layout",
                display_name: "Layout Structure",
                value: "",
                restrict_to: ["Row"],
                elements: ["Layout"],
                condition: {
                    name: "layout-type",
                    value: ["Row","Container"]
                },
                source:{
                    component: "containers/Cms/Components/Properties/LayoutStructure",
                    props: {
                        columns: "{value}", // this is the value from up there... because its dinamic, we wrap it in curly braces so it grabs the value from the json
                        selection: "method:selection" // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas
                    },
                }
            },
            {
                name: "layout-sm",
                type: "range",
                min: 0,
                max: 12,
                description: "The column size to use on small screens",
                display_name: "Small",
                value: 1,
                icon: "far fa-mobile",
                elements: ["Layout"],
                condition: {
                    name: "layout-type",
                    value: ["Column"]
                }
            },
            {
                name: "layout-md",
                type: "range",
                min: 0,
                max: 12,
                description: "The column size to use on medium screens",
                display_name: "Medium",
                value: 1,
                icon: "far fa-tablet",
                elements: ["Layout"],
                condition: {
                    name: "layout-type",
                    value: ["Column"]
                }
            },
            {
                name: "layout-lg",
                type: "range",
                min: 0,
                max: 12,
                description: "The column size to use on large screens",
                display_name: "Large",
                value: 1,
                icon: "far fa-laptop",
                elements: ["Layout"],
                condition: {
                    name: "layout-type",
                    value: ["Column"]
                }
            },
            {
                name: "layout-xl",
                type: "range",
                min: 0,
                max: 12,
                description: "The column size to use on extra large screens",
                display_name: "Extra Large",
                value: 1,
                icon: "far fa-desktop",
                elements: ["Layout"],
                condition: {
                    name: "layout-type",
                    value: ["Column"]
                }
            },
        ]
    },
    Heading: {
        component: Heading,
        name: "Heading",
        component_name: "Heading",
        tooltip: "The heading is a text element that can be used to create a title for a section of the page",
        apply_styles: true,
        list: "Elements",
        props: [
            {
                name: "type",
                type: "select",
                options: ["XXL","XL","Large","Medium","Small","Normal"],
                description: "The type of heading to use",
                display_name: "Size",
                value: "XXL",
                elements: ["Heading"]
            },
            {
                name: "text",
                type: "text",
                description: "The text to display in the heading",
                display_name: "Heading Text",
                value: "Title",
                elements: ["Heading"],
                ai: true
            }
        ]
    },
    Text: {
        component: Text,
        name: "Text",
        component_name: "Text",
        tooltip: "A text element that can be used to display a paragraph, or a simple text block",
        apply_styles: true,
        can_drop: false,
        list: "Elements",
        props: [
            {
                name: "type",
                type: "select",
                options: ["Text","Paragraph"],
                description: "The type of text to use",
                display_name: "Type",
                value: "Text",
                elements: ["Text"]
            },
            {
                name: "text",
                type: "textarea",
                description: "The text to display in the text element",
                display_name: "Text",
                value: "The quick brown fox jumps over the lazy dog...",
                elements: ["Text"],
                ai: true
            }
        ]
    },
    Image:{
        component: Image,
        name: "Image",
        component_name: "Image",
        tooltip: "The image element can be used to display an image on the page",
        apply_styles: true,
        can_drop: false,
        list: "Elements",
        props: [
            {
                name: "src",
                type: "text",
                description: "The image to display",
                display_name: "Image URL",
                value: "",
                elements: ["Image"],
                media: true
            },
            {
                name: "alt",
                type: "text",
                description: "The caption text to use for the image",
                display_name: "Caption",
                value: "",
                elements: ["Image"]
            },
            {
                name: "width",
                type: "text",
                description: "The width of the image",
                display_name: "Width",
                value: "",
                elements: ["Image"]
            },
            {
                name: "height",
                type: "text",
                description: "The height of the image",
                display_name: "Height",
                value: "",
                elements: ["Image"]
            },
            {
                name: "image_align",
                type: "select",
                options: [
                    {text:"Left",value:"left"},
                    {text:"Center",value:"center"},
                    {text:"Right",value:"right"},
                ],
                description: "The alignment of the image",
                display_name: "Alignment",
                value: "",
                elements: ["Image"]
            },
            {
                name: "url",
                type: "text",
                description: "When set, clicking the image will redirect to the specified URL",
                display_name: "Link URL",
                value: "",
                elements: ["Image"]
            },
            {
                name: "target",
                type: "select",
                options: [
                    {text:"Same Page",value:"_self"},
                    {text:"New Browser Tab",value:"_blank"},
                ],
                description: "If the URL should open in the same page or a new browser tab",
                display_name: "Open In",
                value: "_self",
                elements: ["Image"]
            },
        ]
    },
    Video: {
        component: Video,
        name: "Video",
        component_name: "Video",
        tooltip: "The video element can be used to display a video on the page",
        apply_styles: true,
        can_drop: false,
        list: "Elements",
        props: [
            {
                name: "video_url",
                type: "custom",
                description: "The Youtube or Vimeo video to display",
                display_name: "Video URL",
                value: "",
                elements: ["Video"],
                source:{
                    component: "containers/Cms/Components/Properties/Video",
                    props: {
                        input: "{value}", // this is the value from up there... because its dynamic, we wrap it in curly braces so it grabs the value from the json
                        selection: "method:selection" // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas
                    },
                }
            },
        ]
    },
    List: {
        component: List,
        name: "List",
        component_name: "List",
        tooltip: "The list is a text element that can be used to display a list of items, either ordered or unordered",
        apply_styles: true,
        can_drop: false,
        list: "Elements",
        props: [
            {
                name: "type",
                type: "select",
                options: ["Unordered","Ordered"],
                description: "The type of list to use",
                display_name: "Type",
                value: "Unordered",
                elements: ["List"]
            },
            {
                name: "ordered_type",
                type: "select",
                options: [
                    {value:"1",text:"Numbers"},
                    {value:"A",text:"Uppercase Letters"},
                    {value:"a",text:"Lowercase Letters"},
                    {value:"I",text:"Uppercase Roman Numerals"},
                    {value:"i",text:"Lowercase Roman Numerals"},
                ],
                description: "The type of ordered list to use",
                display_name: "Ordered Type",
                value: "1",
                elements: ["List"],
                condition: {
                    name: "type",
                    value: "Ordered"
                }
            },
            {
                name: "ordered_start",
                type: "number",
                description: "The starting number for the ordered list",
                display_name: "Ordered Start",
                value: "1",
                elements: ["List"],
                condition: {
                    name: "type",
                    value: "Ordered"
                }
            },
            {
                name: "items",
                type: "array",
                description: "A list of items to display",
                display_name: "List Items",
                elements: ["List"],
                addItems: true,
                allowGrouping: true, // allows the creation of groups of items
                value: [
                    [{
                        id: 1,
                        value: "Item 1"
                    }],
                    [{
                        id: 2,
                        value: "Item 2"
                    }],
                    [{
                        id: 3,
                        value: "Item 3"
                    }]
                ],
                items: [
                    {
                        type: "text",
                        display_name: "Item",
                        value: ""
                    }
                ]
            }            
        ]
    },
    Button: {
        component: Button,
        name: "Button",
        component_name: "Button",
        tooltip: "Displays a clickable button or link",
        apply_styles: true,
        can_drop: false,
        list: "Elements",
        props: [
            {
                name: "type",
                type: "select",
                options: ["Button","Link", "Event Button"],
                description: "The type of button to use",
                display_name: "Type",
                value: "Button",
                elements: ["Button"]
            },
            {
                name: "text",
                type: "textarea",
                description: "The text to display in the button",
                display_name: "Text",
                value: "Click Me!",
                elements: ["Button"]
            },
            {
                name: "link",
                type: "text",
                description: "The link to use for the button",
                display_name: "Link",
                value: "",
                elements: ["Button"],
            },
            {
                name: "event_id",
                type: "text",
                description: "The ID of the event to load",
                display_name: "Event ID",
                value: "",
                elements: ["Button"],
                condition: {
                    name: "type",
                    value: "Event Button"
                }
            },
            {
                name: "variant",
                type: "select",
                options: ["Primary","Secondary","Success","Danger","Warning","Info","Light","Dark","Link"],
                description: "The variant to use for the button",
                display_name: "Variant",
                value: "Primary",
                elements: ["Button"]
            },
            {
                name: "size",
                type: "select",
                options: ["Small","Medium","Large"],
                description: "The size to use for the button",
                display_name: "Size",
                value: "Medium",
                elements: ["Button"]
            },
            {
                name: "target",
                type: "select",
                options: ["Same Tab","New Tab","Popup"],
                description: "The target to use for the link",
                display_name: "Open In",
                value: "Same Tab",
                elements: ["Button"]
            },
            {
                name: "popup_centered",
                type: "checkbox",
                description: "If the popup should be centered on the screen",
                display_name: "Centered",
                group: "Popup",
                value: true,
                elements: ["Button"],
                condition: {
                    name: "target",
                    value: "Popup"
                }
            },
            {
                name: "popup_size",
                type: "select",
                options: [{value:"sm", text:"Small"},{value:"md", text:"Medium"},{value:"lg", text:"Large"},{value:"xl", text:"Extra Large"}],
                description: "The size of the popup",
                display_name: "Popup Size",
                group: "Popup",
                value: "",
                elements: ["Button"],
                condition: {
                    name: "target",
                    value: "Popup"
                }
            },
            {
                name: "popup_fullscreen",
                type: "select",
                description: "If the popup should be full screen",
                display_name: "Full Screen",
                options: [{value:"true", text:"Always"},{value:"sm-down", text:"Small"},{value:"md-down", text:"Medium"},{value:"lg-down", text:"Large"},{value:"xl-down", text:"Extra Large"},{value:"xxl-down", text:"Extra Extra Large"}],
                group: "Popup",
                value: "",
                elements: ["Button"],
                condition: {
                    name: "target",
                    value: "Popup"
                }
            },
        ]
    },
    Anchor: {
        component: Anchor,
        name: "Anchor",
        component_name: "Anchor",
        tooltip: "The anchor element can be used to mark a section of the page that can be linked to",
        apply_styles: false,
        can_drop: false,
        list: "Elements",
        props: [
            {
                name: "anchor_id",
                type: "text",
                description: "The ID to link to the anchor. For example, if the ID is 'my-anchor', you can link to it by using the URL '#my-anchor'",
                display_name: "Anchor ID",
                value: "",
                elements: ["Anchor"]
            }
        ]
    },
    Card: {
        component: Card,
        name: "Card",
        component_name: "Card",
        tooltip: "The card element can be used to display a card on the page",
        apply_styles: true,
        can_drop: true,
        list: "Widgets",
        props: [
            {
                name: "title",
                type: "text",
                description: "The title to display in the card",
                display_name: "Title",
                value: "Card Title",
                elements: ["Card"],
                ai: true
            },
            {
                name: "text",
                type: "textarea",
                description: "The text to display in the card",
                display_name: "Text",
                value: "The quick brown fox jumps over the lazy dog...",
                elements: ["Card"],
                ai: true
            },
            {
                name: "image",
                type: "text",
                description: "The image to display in the card",
                display_name: "Image URL",
                value: "",
                elements: ["Card"],
                media: true
            },
        ]
    },
    ContentBlock: {
        component: ContentBlock,
        name: "Content Block",
        component_name: "ContentBlock",
        tooltip: "The content block is a page element that can be shared between pages",
        apply_styles: true,
        list: "ContentBlocks",
        props: [
            {
                name: "contentblock_id",
                type: "select",
                options: [],
                description: "The content block to display",
                display_name: "Content Block",
                value: "",
                source: {
                    component: "containers/Cms/Components/Properties/ContentBlockSelect", // we will add the path where needed
                    props: {
                        page_type: 2,
                        selection: "method:selection"
                    },
                },
                elements: ["ContentBlock"]
            }
        ]
    },
    Menu: {
        component: Menu,
        name: "Menu",
        component_name: "Menu",
        tooltip: "The menu element can be used to display a menu on the page",
        list: "Components",
        props:[
            {
                name: "menu_alignment",
                type: "select",
                options: ["Left","Center","Right"],
                description: "The alignment to use for the menu",
                display_name: "Menu Alignment",
                value: "Left",
                elements: ["Menu"]
            },
            {
                name: "menu_collapse_on",
                type: "select",
                options: [
                    {value:"always",text:"Always Expanded"},
                    {value:"never",text:"Always Collapsed"},
                    {value:"sm",text:"On Small screens"},
                    {value:"md",text:"On Medium screens and under"},
                    {value:"lg",text:"On Large screens and under"},
                    {value:"xl",text:"On Extra Large screens and under"},
                ],
                description: "The screen size to collapse the menu on",
                display_name: "Collapse Menu",
            },
            {
                name: "items",
                type: "json",
                description: "The navigation to display in the header",
                display_name: "Navigation",
                elements: ["Menu"],
                addItems: true,
                allowHierarchy: true,  // allows nested items
                value: [
                    [{ id: 1, value: "Home"}, { id: 2, value: "/"}, {id: 3, value: "far fa-home"}, {id: 4, value: 0}],
                    [{ id: 5, value: "About"}, { id: 6, value: "/about"}, {id: 7, value: "far fa-smile"}, {id: 8, value: 0}],
                    [{ id: 9, value: "Contact"}, { id: 10, value: "/contact"}, {id: 11, value: "far fa-envelope"}, {id: 12, value: 0}]
                ],
                items: [
                    {
                        type: "text",
                        display_name: "Title",
                        value: "",
                        required: true
                    },
                    {
                        type: "text",
                        display_name: "URL",
                        value: ""
                    },
                    {
                        type: "text",
                        display_name: "Icon Class",
                        value: ""
                    },
                    /*
                    {
                        type: "autocomplete",
                        display_name: "Icon Class",
                        values: fontAwesomeClasses.map(item=>{return {value:`far fa-${item}`, text: item, icon: `far fa-${item}`}}),
                        value: "",
                    },
                    */
                    {
                        type: "checkbox",
                        display_name: "Requires Logged In",
                        value: 1
                    },
                ]
            },
        ]
    },

    Slider: {
        component: Slider,
        name: "Slider",
        component_name: "Slider",
        tooltip: "A page element that can be used to display content in a transition effect",
        apply_styles: true,
        can_drop: true,
        list: "Components",
        props: [
            {
                name: "pagination",
                type: "select",
                options: [{value:"bullets", text:"Bullets"},{value:"numbers", text:"Numbers"}],
                description: "The type of pagination to use",
                display_name: "Pagination",
                value: "",
                elements: ["Slider"]
            },
            {
                name: "pagination_position",
                type: "select",
                options: [
                    {value:"top-left",text:"Top Left"},
                    {value:"top-center",text:"Top Center"},
                    {value:"top-right",text:"Top Right"},
                    {value:"bottom-left",text:"Bottom Left"},
                    {value:"bottom-center",text:"Bottom Center"},
                    {value:"bottom-right",text:"Bottom Right"},
                ],
                description: "The position of the pagination",
                display_name: "Pagination Position",
                value: "bottom-center",
                elements: ["Slider"],
                condition: {
                    name: "pagination",
                    value: ["bullets","numbers"]
                }
            },
            {
                name: "arrows",
                type: "checkbox",
                description: "Whether to display arrows for navigation",
                display_name: "Arrows",
                value: 1,
                elements: ["Slider"]
            },
            {
                name: "transition_type",
                type: "select",
                options: [{value:"left",text:"Slide Left"}, {value:"right",text:"Slide Right"}, {value:"up",text:"Slide Up"}, {value:"down",text:"Slide Down"}, {value:"fade",text:"Fade"}],
                description: "The type of transition to use",
                display_name: "Transition Type",
                value: "left",
                elements: ["Slider"]
            },
            {
                name: "transition_time",
                type: "number",
                description: "The time in milliseconds for the transition to complete",
                display_name: "Transition Time (ms)",
                value: "1000",
                elements: ["Slider"]
            },
            {
                name: "autoplay",
                type: "checkbox",
                description: "Whether to autoplay the slider",
                display_name: "Autoplay",
                value: 0,
                elements: ["Slider"]
            },
            {
                name: "autoplay_delay",
                type: "number",
                description: "The time in milliseconds to wait before transitioning to the next slide",
                display_name: "Autoplay Delay (ms)",
                value: "10000",
                elements: ["Slider"],
                condition: {
                    name: "autoplay",
                    value: 1
                }
            },
            {
                name: "custom-height",
                type: "text",
                description: "The minimum height of the slider in pixels",
                display_name: "Minimum Height",
                value: "200px",
                elements: ["Slider"]
            },
            {
                name: "custom-steps",
                type: "custom",
                description: "Allows you to define the number of steps this slider will have",
                display_name: "Steps",
                value: 1,
                elements: ["Slider"],
                source:{
                    component: "containers/Cms/Components/Properties/WizardStructure",
                    props: {
                        steps: "{value}", // this is the value from up there... because its dinamic, we wrap it in curly braces so it grabs the value from the json
                        selection: "method:selection" // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas
                    },
                }
            },
        ]
    },
    Slide:{
        component: Slide,
        name: "Slide",
        component_name: "Slide",
        tooltip: "A slide within a slider",
        apply_styles: true,
        can_drop: true,
        hidden: true,
        list: "Components",
        props: [
            {
                display_name: "Slide Number",
                name: "slide_number",
                description: "The number of the slide",
                type: "number",
                value: 1,
                elements: ["Slide"],
            },
            {
                display_name: "Slide Title",
                name: "slide_title",
                description: "The title of the slide",
                type: "text",
                value: "",
                elements: ["Slide"],
            },
        ]
    },    

    Header: {
        component: Header,
        name: "Header",
        component_name: "Header",
        tooltip: "The header is the topmost part of the page. It contains the logo, navigation, and other elements",
        list: "Components",
        can_drop: true,
        props:[
            {
                name: "logo",
                type: "image",
                description: "The logo to display in the header",
                display_name: "Logo",
                value: "",
                elements: ["Header"],
                media: true
            },
            {
                name: "logo_text",
                type: "text",
                description: "The text to display in the header",
                display_name: "Logo Text",
                value: "Logo Text",
                elements: ["Header"]
            },
            {
                name: "logo_link",
                type: "text",
                description: "The link to go to when the logo is clicked",
                display_name: "Logo Link",
                value: "/",
                elements: ["Header"]
            },
            {
                name: "logo_width",
                type: "text",
                description: "The width of the logo",
                display_name: "Logo Width",
                value: "100px",
                elements: ["Header"]
            },
            {
                name: "logo_height",
                type: "text",
                description: "The height of the logo",
                display_name: "Logo Height",
                value: "100px",
                elements: ["Header"]
            },
            {
                name: "menu",
                type: "json",
                description: "The navigation to display in the header",
                display_name: "Navigation",
                value: [{
                    "name": "Home",
                    "url": "/"
                }, {
                    "name": "About",
                    "url": "/about"
                }, {
                    "name": "Contact",
                    "url": "/contact"
                }],
                elements: ["Header"]
            },
            {
                name: "menu_alignment",
                type: "select",
                options: ["Left","Center","Right"],
                description: "The alignment to use for the menu",
                display_name: "Menu Alignment",
                value: "Left",
                elements: ["Header"]
            },
        ]
    },
    Footer: {
        component: Footer,
        name: "Footer",
        component_name: "Footer",
        tooltip: "The footer is the bottommost part of the page. It contains the logo, navigation, and other elements",
        apply_styles: true,
        list: "Components",
        props: [
            {
                name: "text",
                type: "text",
                description: "Text to display in the footer",
                display_name: "Footer Text",
                value: "",
                elements: ["Footer"]
            },
            {
                name: "address",
                type: "text",
                description: "The address to display in the footer",
                display_name: "Company Address",
                value: "",
                elements: ["Footer"]
            },
            {
                name: "phone",
                type: "text",
                description: "One or more phone numbers to display in the footer",
                display_name: "Company Phone",
                value: "",
                elements: ["Footer"]
            },
            {
                name: "links",
                type: "json",
                description: "A list of links to display in the footer",
                display_name: "Links",
                value: [
                    {
                        name: "Home",
                        group: "Links",
                        url: "/"
                    }, 
                    {
                        name: "Terms & Conditions",
                        group: "Links",
                        url: "/terms"
                    },
                    {
                        name: "FAQ",
                        group: "Links",
                        url: "/faq"
                    },
                    {
                        name: "About Us",
                        group: "Company",
                        url: "/about"
                    },
                    {
                        name: "Contact Us",
                        group: "Company",
                        url: "/contact"
                    },
                ],
                elements: ["Footer"]
            },
            {
                name: "socials",
                type: "json",
                description: "A list of social media links to display in the footer, in the format 'name|url'",
                display_name: "Social Links",
                value: [
                    "facebook|https://facebook.com",
                    "twitter|https://twitter.com/",
                    "youtube|https://youtube.com/"
                ],
                elements: ["Footer"]
            },
        ]
    },
    Wysiwyg: {
        component: Wysiwyg,
        name: "Wysiwyg",
        component_name: "Wysiwyg",
        tooltip: "A block of HTML content",
        apply_styles: false,
        list: "Elements",
        props: [
            {
                name: "html",
                type: "wysiwyg",
                description: "The content to display",
                display_name: "Content",
                value: "",
                elements: ["Wysiwyg"]
            }
        ]
    },
    Html: {
        component: Html,
        name: "Html",
        component_name: "Html",
        tooltip: "A block of HTML content",
        apply_styles: false,
        list: "Elements",
        props: [
            {
                name: "html",
                type: "textarea",
                description: "The content to display",
                display_name: "Content",
                value: `<p>Add something <b>COOL</b> here...</p>`,
                elements: ["Html"]
            }
        ]
    },
    CartIcon: {
        component: CartIcon,
        name: "Cart Icon",
        component_name: "CartIcon",
        tooltip: "Show an icon that displays the number of items in the cart",
        apply_styles: true,
        list: "Components",
        props: [
            {
                name: "url",
                type: "text",
                description: "The URL to go to when the icon is clicked",
                display_name: "Cart URL",
                value: "",
                elements: ["CartIcon"],
            },
            {
                name: "target",
                type: "select",
                options: [{text:"Same Page",value:"_self"}, {text:"New Browser Tab",value:"_blank"}],
                description: "If the URL should open in the same page or a new browser tab",
                display_name: "Open In",
                value: "_self",
                elements: ["CartIcon"]
            },
            {
                name: "register_id",
                type: "text",
                description: "The ID of the register to use for the cart",
                display_name: "Register ID",
                value: "",
                elements: ["CartIcon"]
            },
            {
                name: "icon_style",
                type: "select",
                description: "The style of the icon",
                display_name: "Type",
                options: [{value: "far", text: "Regular"}, {value: "fas", text: "Solid"}, {value: "fal", text: "Light"}, {value: "fad", text: "Duotone"}],
                value: "far",
                elements: ["CartIcon"]
            },
        ]
    },

    Checkout: {
        component: Checkout,
        name: "Cart",
        component_name: "Checkout",
        tooltip: "Allows the user to view and checkout their cart",
        apply_styles: false,
        list: "Components",
        props: [
            {
                name: "empty_message",
                type: "text",
                description: "The message to display when the cart is empty",
                display_name: "Empty Message",
                value: "Your cart is empty.",
                elements: ["Checkout"]
            },
            {
                name: "events_display",
                type: "checkbox",
                description: "Display aditional event information",
                display_name: "Display Event Info",
                value: true,
                elements: ["Checkout"]
            },
            {
                name: "register_id",
                type: "text",
                description: "The ID of the register to use for the cart",
                display_name: "Register ID",
                value: "",
                elements: ["Checkout"]
            },
        ]    
    },

    Gallery: {
        component: Gallery,
        name: "Gallery",
        component_name: "Gallery",
        tooltip: "The gallery is a collection of images",
        apply_styles: true,
        list: "Components",
        props: [
            {
                name: "type",
                type: "select",
                description: "The type of gallery to display",
                display_name: "Type",
                options: ["Masonry", "Grid"],
                value: "Masonry",
                elements: ["Gallery"]
            },
            {
                name: "image_width",
                type: "number",
                description: "The width of the images in the gallery",
                display_name: "Width",
                value: "",
                elements: ["Gallery"],
                condition: {
                    name: "type",
                    value: "Grid"
                }
            },
            {
                name: "image_height",
                type: "number",
                description: "The height of the images in the gallery",
                display_name: "Height",
                value: 200,
                elements: ["Gallery"],
                condition: {
                    name: "type",
                    value: "Grid"
                }
            },
            {
                name: "xl_columns",
                type: "range",
                description: "The number of columns to display on extra large screens",
                display_name: "Extra Large",
                icon: "far fa-desktop",
                value: 6,
                min: 1,
                max: 6,
                elements: ["Gallery"]
            },
            {
                name: "lg_columns",
                type: "range",
                description: "The number of columns to display on large screens",
                display_name: "Large",
                icon: "far fa-laptop",
                value: 4,
                min: 1,
                max: 6,
                elements: ["Gallery"]
            },
            {
                name: "md_columns",
                type: "range",
                description: "The number of columns to display on medium screens",
                display_name: "Medium",
                icon: "far fa-tablet",
                value: 2,
                min: 1,
                max: 6,
                elements: ["Gallery"]
            },
            {
                name: "sm_columns",
                type: "range",
                description: "The number of columns to display on small screens",
                display_name: "Small",
                icon: "far fa-mobile",
                value: 1,
                min: 1,
                max: 6,
                elements: ["Gallery"]
            },
            {
                name: "gap",
                type: "number",
                description: "The gap between images",
                display_name: "Gap",
                value: 10,
                elements: ["Gallery"]
            },
            {
                name: "show_caption",
                type: "checkbox",
                description: "Whether to show the image caption",
                display_name: "Show Caption",
                value: true,
                elements: ["Gallery"]
            },
            {
                name: "click_expand",
                type: "checkbox",
                description: "Whether to enable expanding the image when clicked",
                display_name: "Click to Expand",
                value: true,
                elements: ["Gallery"]
            },
            {
                name: "images",
                type: "custom",
                description: "The images to display in the gallery",
                display_name: "Images",
                value: [
                    {url: "https://via.placeholder.com/250x400", title: "Image 1", description: "Image 1 Description"},
                    {url: "https://via.placeholder.com/450x250", title: "Image 2", description: "Image 2 Description"},
                    {url: "https://via.placeholder.com/800x600", title: "Image 3", description: "Image 3 Description"},
                    {url: "https://via.placeholder.com/250x120", title: "Image 4", description: "Image 4 Description"},
                    {url: "https://via.placeholder.com/500x320", title: "Image 5", description: "Image 5 Description"},
                    {url: "https://via.placeholder.com/480x600", title: "Image 6", description: "Image 6 Description"},
                ],
                elements: ["Gallery"],
                media: true,
                mediaMultiSelect: true,
                source:{
                    component: "containers/Cms/Components/Properties/ImagePreview",
                    props: {
                        images: "{value}",
                        selection: "method:selection"
                    },
                }

            },
        ]
    },
    UpcomingEvents: {
        component: UpcomingEvents,
        name: "Upcoming Events",
        component_name: "UpcomingEvents",
        tooltip: "The events widget shows upcoming events",
        apply_styles: true,
        list: "Components",
        props:[
            {
                name: "layout",
                type: "select",
                options: ["horizontal","vertical"],
                description: "The layout of the events",
                display_name: "Layout",
                value: "horizontal",
                elements: ["UpcomingEvents"]
            },
            {
                name: "limit",
                type: "number",
                description: "The number of events to show",
                display_name: "Limit",
                value: 3,
                elements: ["UpcomingEvents"]
            },
            {
                name: "event_types",
                type: "text",
                description: "A list of events type Ids to show, for example: [1,2,3]",
                display_name: "Event Types",
                value: [],
                elements: ["UpcomingEvents"],
                source: {
                    component: "components/Typeahead/EventTypesTypeahead", // we will add the path where needed
                    props: {
                        async: false,
                        name: "type-search",
                        multiple: true,
                        placeholder: "Select event types",
                        initialDataIds: "{value}",
                        passSelection: "method:selection" // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas 
                    },
                    value: null,
                },
            },
            {
                name: "title",
                type: "text",
                description: "The title to display above the events",
                display_name: "Title",
                value: "Upcoming Events",
                elements: ["UpcomingEvents"]
            },
            {
                name: "subtitle",
                type: "text",
                description: "The subtitle to display above the events",
                display_name: "Subtitle",
                value: "Events in the next 30 days",
                elements: ["UpcomingEvents"]
            },
            {
                name: "show_images",
                type: "select",
                options: ["true","false"],
                description: "Whether to show images for the events",
                display_name: "Show Images",
                value: "true",
                elements: ["UpcomingEvents"]
            },
            {
                name: "placeholder_images",
                type: "text",
                description: "A prompt to fetch images from the placeholder service",
                display_name: "Placeholder Image Prompt",
                value: "basketball courts",
                elements: ["UpcomingEvents"]
            },
            {
                name: "checkout_url",
                type: "text",
                description: "The URL to go to when the checkout button is clicked",
                display_name: "Checkout URL",
                value: "",
                elements: ["UpcomingEvents"]
            },
            {
                name: "register_id",
                type: "text",
                description: "The ID of the register to use when adding events to the cart",
                display_name: "Register ID",
                value: "",
                elements: ["UpcomingEvents"]
            },
            {
                name: "data",
                type: "json",
                description: "A list of events to show if not using Event Type IDs",
                display_name: "Events",
                value: [
                    {
                        id: 1,
                        name: "Event 1",
                        image: "https://siteboss.net/images/event.png",
                        start_datetime: "2023-01-01 10:00:00",
                        location_name: "Location 1",
                    },
                    {
                        id: 2,
                        name: "Event 2",
                        image: "https://siteboss.net/images/event.png",
                        start_datetime: "2023-01-01 11:00:00",
                        location_name: "Location 2",
                    },                    
                ],
                elements: ["UpcomingEvents"]
            },
        ]
    },
    EventsView: {
        component: EventsView,
        name: "Event List",
        component_name: "EventsView",
        tooltip: "The events page shows a list of events",
        apply_styles: true,
        list: "Components",
        props:[
            {
                name: "type",
                type: "select",
                options: ["table","card"],
                description: "The type of view to show",
                display_name: "View Type",
                value: "table",
                elements: ["EventsView"]
            },
            {
                name: "event_types",
                type: "json",
                description: "A list of events type Ids to show, for example: [1,2,3]",
                display_name: "Event Type IDs",
                value: [],
                elements: ["EventsView"]
            },
            {
                name: "show_images",
                type: "select",
                options: ["true","false"],
                description: "Whether to show images for the events",
                display_name: "Show Images",
                value: "true",
                elements: ["EventsView"]
            },
            {
                name: "placeholder_images",
                type: "text",
                description: "A prompt to fetch images from the placeholder service",
                display_name: "Placeholder Image Prompt",
                value: "basketball courts",
                elements: ["EventsView"]
            },
            {
                name: "checkout_url",
                type: "text",
                description: "The URL to go to when the checkout button is clicked",
                display_name: "Checkout URL",
                value: "",
                elements: ["EventsView"]
            },
            {
                name: "register_id",
                type: "text",
                description: "The ID of the register to use when adding events to the cart",
                display_name: "Register ID",
                value: "",
                elements: ["EventsView"]
            },
            {
                name: "data",
                type: "json",
                description: "A list of events to show if not using Event Type IDs",
                display_name: "Events",
                /*value: [
                    {
                        id: 1,
                        name: "Event 1",
                        image: "https://siteboss.net/images/event.png",
                        start_datetime: "2023-01-01 10:00:00",
                        end_datetime: "2023-02-01 11:00:00",
                        location_name: "Location 1",
                    },
                    {
                        id: 2,
                        name: "Event 2",
                        image: "https://siteboss.net/images/event.png",
                        start_datetime: "2023-01-01 11:00:00",
                        end_datetime: "2023-02-01 11:00:00",
                        location_name: "Location 2",
                    },                    
                ],*/
                elements: ["EventsView"]
            }
        ]
    },

    Login: {
        component: Login,
        name: "Login",
        component_name: "Login",
        tooltip: "The login page allows users to authenticate to the site",
        apply_styles: true,
        list: "Components",
        props:[
            {
                display_name: "Redirect To",
                name: "redirect_to",
                description: "The page to redirect to after login",
                type: "text",
                value: "/"
            },
        ]
    },
    SignUp: {
        component: SignUp,
        name: "Sign Up / Register",
        component_name: "SignUp",
        tooltip: "The sign up page allows users to register an account on the site",
        apply_styles: true,
        list: "Components",
        props:[
            {
                display_name: "Success Title",
                name: "success_title",
                description: "The title to display when the sign-up form is submitted successfully",
                type: "text",
                value: "Thank you!"
            },
            {
                display_name: "Success Message",
                name: "success_message",
                description: "Message to display when the sign-up form is submitted successfully",
                type: "text",
                value: ""
            },
        ]
    },
    SignUpFamily: {
        component: SignUpFamily,
        name: "Family / Group Join",
        component_name: "SignUpFamily",
        tooltip: "Allows users to register user to a family / group",
        apply_styles: true,
        list: "Components",
        props:[
            {
                name: "group_id",
                type: "typeahead",
                description: "Family / Group to join",
                display_name: "Group / Family",
                value: [],
                source: {
                    component: "components/Typeahead/GroupTypeahead", // we will add the path where needed
                    props: {
                        async: false,
                        multiple: false,
                        initialDataIds: "{value}", // send the value stored in the array to the component so that it populates already selected group
                        passSelection: "method:selection" // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas 
                    },
                    value: null,
                },
            },
            {
                display_name: "Success Title",
                name: "success_title",
                description: "The title to display when the sign-up form is submitted successfully",
                type: "text",
                value: "Thank you!"
            },
            {
                display_name: "Success Message",
                name: "success_message",
                description: "Message to display when the sign-up form is submitted successfully",
                type: "text",
                value: ""
            },
        ]
    },
    Map: {
        component: Map,
        name: "Map",
        component_name: "Map",
        tooltip: "The map shows a map of a given location",
        apply_styles: true,
        list: "Components",
        props:[
            {
                name: "map_type",
                type: "select",
                options: ["google"],
                description: "The type of map to show",
                display_name: "Map Type",
                value: "google",
                elements: ["Contact"]
            },
            {
                name: "api_key",
                type: "text",
                description: "The API key to use for the map",
                display_name: "Map API Key",
                value: "",
                elements: ["Contact"]
            },
            {
                name: "lat",
                type: "number",
                description: "The latitude of the location to show on the map",
                display_name: "Latitude",
                value: 42.266757,
                elements: ["Contact"]
            },
            {
                name: "lng",
                type: "number",
                description: "The longitude of the location to show on the map",
                display_name: "Longitude",
                value: -72.66898,
                elements: ["Contact"]
            },
            {
                name: "map_zoom",
                type: "number",
                description: "The zoom level of the map",
                display_name: "Zoom",
                value: 13,
                elements: ["Contact"]
            },
            {
                name: "min_zoom",
                type: "number",
                description: "The minimum zoom level of the map",
                display_name: "Minimum Zoom",
                value: 3,
                elements: ["Contact"]
            },
            {
                name: "max_zoom",
                type: "number",
                description: "The maximum zoom level of the map",
                display_name: "Maximum Zoom",
                value: 20,
                elements: ["Contact"]
            },
            {
                name: "map_style",
                type: "select",
                options: ["roadmap", "satellite", "hybrid", "terrain"],
                description: "The type of map to show",
                display_name: "Map Type",
                value: "roadmap",
                elements: ["Contact"]
            }
        ]
    },
    GroupPage: {
        component: GroupPage,
        name: "Groups Page",
        component_name: "GroupPage",
        tooltip: "The groups page shows members that are in groups like teams",
        apply_styles: true,
        list: "Components",
        props:[
            {
                name: "group_id",
                type: "number",
                description: "The id of the group to show",
                display_name: "Group ID",
                value: "",
                elements: ["GroupPage"]
            },
            {
                name: "data",
                type: "json",
                description: "The event to show if not using Event ID",
                display_name: "Event",
                value: [
                    {
                        id: 1,
                        name: "Average Joe Dodgeball Team",
                        description: "Dodge, Duck, Dip, Dive, and Dodge.",
                        group_members:[
                            {
                                first_name: "Peter",
                                last_name: "LaFleur",
                                group_member_role_name: "Captain",
                            },
                            {
                                first_name: "Kate",
                                last_name: "Veatch",
                                group_member_role_name: "Member",
                            },
                            {
                                first_name: "Steve",
                                last_name: "The Pirate",
                                group_member_role_name: "Member",
                            },
                        ]
                    },
                ],
                elements: ["GroupPage"]
            }
        ]
    },
    ...formStuff,
    ...wizardStuff,
};

export default Components;
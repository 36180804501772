import React, { useCallback } from 'react';
import { Typeahead } from './Typeahead';
import APIGroups from '../../api/Groups';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './Typeahead.scss';

/**Basic async typeahead for searching groups.  After a selection is made, prop function will pass the data back up to the parent component.
 * This is a wrapper for the common Typeahead component, this includes only the few pieces specific for this data type.
 * @param {()} multiple to allow multiple selections
 * @param {()} passSelection to pass the array of selected Services back
 * @param {()} initialData to set the initial value of the typeahead - array of ids
*/
const GroupTypeahead = (props) => {

    const makeRequest = useCallback(async (query, perPage, page=1) => {
        let response = await APIGroups.get();
        let responseObj = {
            data: response.data || null,
            errors: response.errors || null
        }
        return responseObj;
    },[]);

    // each item in responseObj.data is an option
    const formatForLabel = (option) => (
        `${option?.name}`
    );

    return (
        <Typeahead
            {...props}
            id="group-search"
            formatForLabel={formatForLabel}
            makeRequest={makeRequest}
            async={false}
            paginated={false}
            placeholder={props.placeholder ? props.placeholder : "Enter a group name..."}
        />
    )
}

export default GroupTypeahead;
import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { useHistory } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import Pos from '../../../../api/Pos';
import Users from '../../../../api/Users';
import {stringToUUID} from '../../../../api/Common';
import {setItems} from '../../Items/Utils';
import * as actions from '../../../../store/actions';

import './List.css';

export const List = (props) => {
    //let history = useHistory();
    const dispatch = useDispatch();
    const active_register=useSelector(state => state.pos.register);
    const selected_tab=useSelector(state => state.pos.tab);
    const company=useSelector(state=>state.company);    

    const [tabs,setTabs]=useState([]);

    //const GUEST_USER_ID = 7;
    const GUEST_USER_ID = company?.config?.guest_user_id || null;

    const clickHandler=async(e,tab)=>{
        e.preventDefault();

        if (tab){

            dispatch(actions.activeTab({...tab}));
            dispatch(actions.selectedItems({...tab})); // backward compatibility with the location selector
            dispatch(actions.reset());
    
            // load from db
            Pos.order.get({id:tab.id}).then(async res2=>{
                dispatch(actions.order(tab.id));
                dispatch(actions.orderAll(res2.data?.[0]));

                // load the customer
                if (res2.data?.[0]?.user_id && +res2.data[0].user_id !== GUEST_USER_ID){
                    await Users.get({id:res2.data[0].user_id}).then(response=>{
                        if (response.data){
                            dispatch(actions.selectCustomer(response.data[0]));
                        } 
                    }).catch(e => console.error(e));
                }

                // resets redux and our local cart so we can populate them again with server data (I know there's a better way, I'm tired gimme a break)
                await Pos.local.remove(`POS-${active_register}`).then(a=>{
                    dispatch(actions.resetItems());
                });

                if (res2.data?.[0]?.items){
                    const items=await setItems(res2.data[0].items,active_register,res2.data[0].id,res2.data[0].user_id);
                    // updates redux
                    if (items){
                        items.forEach(item=>{
                            dispatch(actions.addPOSItem(item));
                            if (item.addons){
                                item.addons.forEach(addon=>{
                                    dispatch(actions.addPOSItem(addon));
                                });
                            }
                        });
                    }
                }
            });

        }
        dispatch(actions.activeOption("#preview")); // change to preview
    }

    const newClickHandler=async(e)=>{        
        e.preventDefault();

        let tab_number="001";

        let order=await Pos.order.save({register_id: active_register, user_id: GUEST_USER_ID});
        if (order.data?.[0]){
            dispatch(actions.order(order.data[0].id));
            dispatch(actions.orderAll(order.data[0]));
            tab_number=order.data[0].id;
            //tab_number=('000'+order.data[0].id).slice(-3);
        }

        /*let tab_number=1;
        let res = await Pos.local.get(`POS-NUMTABS-${active_register}`);
        if (res){
            if (!Array.isArray(res)) res=[res];
            tab_number=res.length+1;
        }*/

        const tab={
            hash:stringToUUID(Math.random()),
            id:tab_number,
            name:"",
        }

        Pos.local.append(`POS-NUMTABS-${active_register}`,tab).then(a=>{
            setTabs(prev=>[...prev,tab]);
        });
    }    

    useEffect(()=>{
        let mounted = true;

        if (mounted){
            Pos.local.get(`POS-NUMTABS-${active_register}`).then(item=>{
                if (Array.isArray(item)) setTabs(item);
                else setTabs([item]);
            });
        }

        return () => {
            mounted = false;
        }    
    },[active_register]);

    return (
        <React.Fragment>
            <Button variant="light" onClick={newClickHandler} type="button">New Tab</Button>
            <Table responsive striped hover className="tabs-table">
                <thead>
                    <tr>
                        <th className="">Tab #</th>
                        {/*<th className="text-right">Subtotal</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {tabs && tabs.map((i,j)=>(i &&
                        <tr key={`loc-tb-${i.id}-${j}`} onClick={e=>clickHandler(e,i)} className={(selected_tab?.id===i.id?"active":null)}>
                            <td>{('000'+i.id).slice(-3)}{i.user_name}</td>
                            {/*<td className="text-right">${i.total.toFixed(2)}</td>*/}
                        </tr>
                    ))}
                </tbody>
            </Table>    
        </React.Fragment>
    );
}
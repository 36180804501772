import * as actionTypes from '../actions/actionTypes';

const initialState = { 
    variants: [],
    selected_variant: null,
    initialized: false
};

const productReducer = (state = initialState, action) => {
    let variants = [...state.variants];

    switch (action.type) {
        case actionTypes.PRODUCT_ADD_VARIANT:
            return {
                ...state,
                variants: [...state.variants,action.variant],
                initialized: true
            }
        case actionTypes.PRODUCT_REMOVE_VARIANT:
            return {
                ...state,
                variants: [...state.variants.filter(item=>item.id!==parseInt(action.variant))],
            };
        case actionTypes.PRODUCT_REMOVE_VARIANT_CATEGORY:
            let [variant_name,category_id]=action.variant.split(":");
            variants.forEach((variant,i)=>{
                if (variant.name===variant_name) {
                    variants[i].addons=[...variants[i].addons.filter(b=>+b.category_id!==+category_id)];
                }
            });
            return {
                ...state,
                variants: [...variants],
            };
        case actionTypes.PRODUCT_REMOVE_VARIANT_ADDON:
            let [v_name,c_id,addon_id]=action.variant.split(":");
            variants.forEach((variant,i)=>{
                if (variant.name===v_name) {
                    variants[i].addons=[...variants[i].addons.filter(b=>+b.category_id!==+c_id || +b.id!==+addon_id)];
                }
            });
            return {
                ...state,
                variants: [...variants],
            };
        case actionTypes.PRODUCT_SELECT_VARIANT:
            return {
                ...state,
                selected_variant: action.selected
            };
        case actionTypes.PRODUCT_EDIT_VARIANT:

            //let i = variants.indexOf( variant => +variant.id === +action.variant.id );
            //console.log(i);
            //variants[i] = action.variant;

            //console.log(variants);

            return {
                ...state,
                variants: [...variants.map( item =>
                        item.id === action.variant.id ? item = action.variant : item
                )]
            };
        default:
            return state;
    }
}

export default productReducer;
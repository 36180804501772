//import { startOfDay } from 'date-fns';
import * as actionTypes from '../actions/actionTypes';
//import { selectCurrentEvent, selectEventNameList } from '../selectors';

const initialState = {
    current_pointer: [],
    master: {
        name: '',
        description: '',
        recurring: {},
        children: []
    },
    errors: [],
    step: null,
    loadData: {},
    response_object: null,
};

function saveToCurrentEventRecurring(parent, pointer, obj) {
    if(Array.isArray(pointer) && pointer.length>0 && parent.children) {
        let index = pointer.shift();
        let children = parent.children;
        children[index] = saveToCurrentEventRecurring(children[index], pointer, obj);
        return {...parent, children: children };
    }
    return {...parent, recurring: {
        ...parent.recurring,
        ...obj
    }};
}

function saveToCurrentEvent(parent, pointer, obj) {
    if(Array.isArray(pointer) && pointer.length>0 && parent.children) {
        let index = pointer.shift();
        let children = parent.children;
        children[index] = saveToCurrentEvent(children[index], pointer, obj);
        return {...parent, children: children };
    }
    return {...parent, ...obj};
}

function deleteChildEvent(parent, pointer) {
    if(Array.isArray(pointer) && parent.children) {
        if (pointer.length===1) {
            let index = pointer.shift();
            let children = parent.children;
            children = children.filter((child, i) => i!==index);
            return {...parent, children: children };
        } else if (pointer.length>1) {
            let index = pointer.shift();
            let children = parent.children;
            children[index] = deleteChildEvent(children[index], pointer);
            return {...parent, children: children };
        }
    }
    return {...parent};
}

function addChildEvent(parent, pointer, obj) {
    if(Array.isArray(pointer) && parent.children) {
        if (pointer.length===0) {
            //let index = pointer.shift();
            let children = parent.children;
            children.push(obj);
            return {...parent, children: children };
        } else {
            let index = pointer.shift();
            let children = parent.children;
            children[index] = addChildEvent(children[index], pointer, obj);
            return {...parent, children: children };
        }
    }
    return {...parent};
}

// copy of function in selectors - combine these later into slice file
function findChild(parent, pointer) {
    if(parent && Array.isArray(pointer) && pointer.length>0) {
        let child = parent.children[pointer.shift()];
        return findChild(child, pointer);
    }
    return parent;
}

const eventWizardReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.EVENTWIZARD_SET_CURRENT_EVENT:
            return {
                ...state,
                master: saveToCurrentEvent(state.master, [...state.current_pointer], action.event)
            }
        case actionTypes.EVENTWIZARD_SET_CURRENT_POINTER:
            return {
                ...state,
                current_pointer: action.current_pointer
            }
        case actionTypes.EVENTWIZARD_SET_CURRENT_POINTER_PARENT:
            return {
                ...state,
                current_pointer: state.current_pointer.slice(0, state.current_pointer.length-action.levels)
            }
        case actionTypes.EVENTWIZARD_SET_CURRENT_EVENT_RECURRING:
            return {
                ...state,
                master: saveToCurrentEventRecurring(state.master, [...state.current_pointer], action.recurring)
            }
        case actionTypes.EVENTWIZARD_SET_MASTER_EVENT:
            return {
                ...state,
                master: action.event
            }
        case actionTypes.EVENTWIZARD_SET_ERRORS:
            return {
                ...state,
                errors: action.errors
            }
        case actionTypes.EVENTWIZARD_RESET_ERRORS:
            return {
                ...state,
                errors: action.errors
            }
        case actionTypes.EVENTWIZARD_SET_CURRENT_STEP:
            return {
                ...state,
                step: action.step
            }
        case actionTypes.EVENTWIZARD_SET_LOAD_DATA:
            return {
                ...state,
                loadData: {...state.loadData, ...action.data}
            }
        case actionTypes.EVENTWIZARD_SET_RESPONSE:
            return {
                ...state,
                response_object: action.response_object
            }
        case actionTypes.EVENTWIZARD_DELETE_BY_POINTER:
            // we can't delete the root event
            // change pointer to root so we don't have an error deleting our current event
            return {
                ...state,
                master: deleteChildEvent(state.master, [...action.pointer]),
                current_pointer: []
            }
        case actionTypes.EVENTWIZARD_ADD_CHILD_BY_POINTER:
            let parent = findChild(state.master, [...action.data.pointer]);
            let index = parent.children.length;
            return {
                ...state,
                master: addChildEvent(state.master, [...action.data.pointer], action.data.obj),
                current_pointer: action.data.pointer.length>0 ? [...action.data.pointer, index] : [index]
            }
        default:
            return state;
    }
};

export default eventWizardReducer;
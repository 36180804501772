import React, {useState} from 'react';
import {Card, Button} from 'react-bootstrap';

import CustomFields from './CustomFields';

import styles from './Member.module.scss';

export const Member = (props) => {
    const {click, customFieldChange} = props;    
    const [selected, setSelected] = useState(props?.selected || false);
    const [expanded, setExpanded] = useState(false);

    const toggleSelectedHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        if (!props?.member?.disabled) {
            const _selected = selected;
            //console.log({value: props.member.id, checked: !_selected}, props.member)
            click({preventDefault: e.preventDefault, stopPropagation: e.stopPropagation, target: {value: props.member.id, checked: !_selected}});
            setSelected(!_selected);
        }
    }

    const toggleExpandedHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        if (!props?.member?.disabled) {
            setExpanded(!expanded);
            if (!selected) toggleSelectedHandler(e);
        }
    }

    const customFieldChangeHandler = e => {
        e.preventDefault();
        e.stopPropagation();
        if (props?.member?.id) customFieldChange(e, props?.member?.id);
    }

    if (!props.member) return null;

    return (
        <Card key={`member-card-${props.id}-${props.member.id}`} onClick={toggleExpandedHandler} className={`${styles["member-wrapper"]} ${props.member.disabled ? styles.disabled : ""} ${selected ? styles.selected: ""}`}>
            <div className={styles.header}>
                <Button href="#!" onClick={toggleSelectedHandler} className={`${styles.check} ${selected ? styles.selected : ""}`}>
                    <i className="far fa-check-square"/>
                </Button>
                <span className="bold">{`${props.member.first_name} ${props.member.last_name}`}</span>
                <span className="ms-1">({props.member.group_member_role})</span>
            </div>
            {props?.customFields?.length > 0 &&
                <div className={styles.body}>
                    <span className={styles.msg}>{props.member.disabled ? props.member.message : ''}</span>
                    {expanded && selected &&
                        <div>
                            <CustomFields key={`custom-fields-${props.id}-${props.member.id}`} {...props} onChange={customFieldChangeHandler} />
                        </div>
                    }
                </div>
            }
        </Card>
    );
}
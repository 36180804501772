import Request from './Api';

const CompanyConfig = {
    get: async (props) => {
        return (
            Request({
                url: "/company_config",
                method: "POST",
                data: props
            })
        )
    },
    create: async (props) => {
        return (
            Request({
                url: "/company_config/create",
                method: "POST",
                data: props
            })
        );
    },
    update: async (props) =>{
        return (
            Request({
                url: "/company_config/edit",
                method: "PUT",
                data: props
            })
        )
    },
    delete: async (props) => {
        return (
            Request({
                url: "/company_config/delete",
                method: "DELETE",
                data: props
            })
        );
    }
}

const ConfigTypes = {
    get: async (props) => {
        return (
            Request({
                url: "/company_config/type",
                method: "POST",
                data: props
            })
        )
    },
    create: async (props) => {
        return (
            Request({
                url: "/company_config/type/create",
                method: "POST",
                data: props
            })
        );
    },
    delete: async (props) =>{
        return (
            Request({
                url: "/company_config/type/delete",
                method: "DELETE",
                data: props
            })
        );
    },
    edit: async (props)=>{
        return(
            Request({
                url: "/company_config/type/edit",
                method: "PUT",
                data: props
            })
        )
    }
}

const Config = {
    CompanyConfig, ConfigTypes
}

export default Config;
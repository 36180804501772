import * as actionTypes from '../actions/actionTypes';

const initialState= {
    userDetails: {},
    modifiedOrder: {}
};

const patronCartReducer= (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.PATRONCART_SET_USER_DETAILS:
            return{
                ...state,
                userDetails: action.userDetails
            }
        case actionTypes.PATRONCART_SET_MODIFIED_ORDER:
            return{
                ...state,
                modifiedOrder: action.modifiedOrder
            }
        case actionTypes.PATRONCART_RESET:
            return{
                userDetails: {},
                modifiedOrder: {},
            }
        default:
            return state;
    }
}

export default patronCartReducer;
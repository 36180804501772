import React, {useState, useEffect, useCallback} from 'react';
import {Container, Accordion } from 'react-bootstrap';

import Item from './Item';
import styles from '../Wizard.module.scss';

export const Items = (props) => {
    const {stepUpdate} = props;

    const flat = props?.order?.items?.reduce((acc, item) => {
        acc.push(item);
        if (item.addons) acc.push(...item.addons);
        return acc;
    }, []);

    const [items, setItems] = useState(props?.stepData?.items || []);

    const itemUpdateHandler = useCallback(data => {
        setItems(prev => {
            let _items = [...prev];
            if (+data.refund <= 0 || data.checked.selected === false) _items = _items.filter(a => +a.id !== +data.item.id);
            else {
                let _item = {
                    id: data.item.id,
                    product_id: data.item.product_id,
                    variant_id: data.item.variant_id,
                    product_name: data.item.product_name,
                    refund_amount: +data.refund,
                    refund_tax: +data.tax || 0,
                    refund_shipping: +data.shipping || 0,
                    refund_price: +data.price || 0,
                    refund_memo: data.memo,
                    return: data.checked.return,
                    refund_type: data.checked.full ? "full amount" : data.checked.half ? "half amount" : data.checked.shipping ? "shipping" : data.checked.tax ? "tax" : "custom amount",
                }

                let _idx = _items.findIndex(item => item.id === data.item.id);
                if (_idx > -1) _items[_idx] = _item;
                else _items.push(_item);
            }
            return _items;
        });
    },[]);

    useEffect(()=>{
        stepUpdate(props.stepId, {items});
    },[items, stepUpdate, props.stepId]);

    if (!props.order) return null;
        
    return (
        <Container fluid>
            <h4 className={`${styles.title}`}>Select the items you wish to refund</h4>
            <hr/>
            {flat.map((item, i)=>(
                <React.Fragment key={`refund-items-${i}`}>
                    <Accordion className="mx-3">
                        <Item
                            key={`refund-item-${item.id}`} 
                            {...props}
                            item={item}
                            index={i+1}
                            data={items.find(a => +a.id === +item.id)}
                            itemUpdate={itemUpdateHandler}
                        />
                    </Accordion>
                </React.Fragment>
            ))}
        </Container>
    );
}
import Request from "./Api";

// Get all registers
const get = async (props) => {
  return Request({
    url: "/register",
    method: "POST",
    data: props,
  });
};

// Create new register
const create = async (props) => {
  return Request({
    url: "/register/create",
    method: "POST",
    data: props,
  });
};

// Edit event
const edit = async (props) => {
  return Request({
    url: "/register/edit",
    method: "PUT",
    data: props,
  });
};

const delete_register = async (props) => {
  return Request({
    url: "/register/" + props.id,
    method: "DELETE",
    data: props,
  });
};


const Groups = {
    get: async (props) => {
        return Request({
            url: "/register/group",
            method: "POST",
            data: props,
        });
    },

    create: async (props) => {
        return Request({
            url: "/register/group/create",
            method: "POST",
            data: props,
        });
    },

    edit: async (props) => {
        return Request({
            url: "/register/group/edit",
            method: "PUT",
            data: props,
        });
    },

    delete: async (props) => {
        return Request({
            url: "/register/group/" + props.id,
            method: "DELETE",
            data: props,
        });
    },
};

const Registers = {
  get,
  create,
  edit,
  delete_register,
  Groups
};

export default Registers;

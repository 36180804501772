import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDrop } from "react-dnd";
import { randomUUID, formatJsonFromCMS } from '../../../../../../utils/cms';
import * as actions from '../../../../../../store/actions';

import styles from "./Dropbox.module.scss";

export const Dropbox = (props) => {
    const dispatch = useDispatch();
    const cmsSelector = useSelector(state => state.cms);

    //const { data, onDrop } = props;

    const [{ isOver, isOverCurrent, canDrop }, drop] = useDrop({
        accept: props.accept_type,
        drop: (item, monitor) => {
			const canDrop = (props?.[props.element_id]?.innerText || !props?.can_drop) ? false : monitor.canDrop();  // dont allow dropping on text elements

            if (props?.flag || props.element_id==="ContentBlock") return; // cant drop into content blocks (for now)
			if (monitor.didDrop()) return;
			if (!monitor.isOver({ shallow: true })) return;
			if (!canDrop) return;
			if (item.id === props.id) return; // dont replace items with themselves

			let moveToIndex = null;

			// if move within the same parent, behave like a sortable
			if (props.id === item.parent_id){
				moveToIndex=item.hoverIndex;
				if (moveToIndex > -1){
					//dispatch(actions.CMSAddElement(item, dragIndex, moveToIndex));
				}
			}

			if (!item.element_id){ // this was just dropped from the toolbar, so we need to format it
				const _item={...item};
				item=formatJsonFromCMS([{..._item}])[0];
				item.element_id = _item.component_name;
				item.id = `${_item.component_name}-${randomUUID()}`;
				item.index = _item.index /*|| props.index + props[props.element_id]?.content?.length || 0*/;
				item.can_drop = _item.can_drop || false;
				item.apply_styles = _item.apply_styles!==undefined ? _item.apply_styles : true;
				item.properties = _item.properties;

				if (_item.id.includes("HTMLTAG-")) { // this is an HTML tag item, so we extract the value and set it as innerText
					if (item[item.element_id]?.props?.value || item[item.element_id]?.props?.innerText){
						item[item.element_id].innerText = item[item.element_id].props.value || item[item.element_id].props.innerText;
					}
				}				
			} else if (!item.element_id) item.element_id=item.id.split('-')[0]; // this is a component so the id already includes the element_id

			if (!item.id){
				if (props?.id) item.id = `${props.id}`;
				else item.id = `${item.element_id}-${randomUUID()}`;
			}

			item.parent_id = props.id;

			dispatch(actions.CMSAddElement({...item}, (item.index || null), props.pos));
			dispatch(actions.CMSSetCurrentElement({...item}.id));

			return {...props}; // returns the drop target object to the dragged element, so we can change parent_id and whatever else we need
        },
        canDrop: (item, monitor) => {
            return true;
        },
        collect: (monitor) => ({
			handlerId: monitor.getHandlerId(),
			didDrop: monitor.didDrop(),
			isOver: monitor.isOver(),
			isOverCurrent: monitor.isOver({ shallow: true }),
			canDrop: monitor.canDrop(),
        })
    });

    const isActive = isOver && canDrop;
    return (
        <div 
            ref={drop} 
            data-accept={props.accept_type} 
            data-index={props.pos}
            className={`
				${styles["drop-zone"]} 
				${props.accept_type === "container" ? styles.section: ""} 
				${isActive ? styles.active : ""}
				${isOverCurrent ? styles.over : ""}
				${props.classes || ""}
				${props.bottom ? styles.bottom : ""} 
				${props.accept_type === "column" && cmsSelector.displayMode!=="xray" ? `col-sm-auto ${styles.vertical}` : ""}
			`.trim().replace(/\s+/g,' ')}
			/*style={{display:"block",height:"fit-content"}}*/
		>
			<div/> {/* this fella is supposed to be here */}
			{/*props.pos*/}
		</div>
    );
};
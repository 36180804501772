//import { useState, useEffect } from 'react';
import axios from 'axios';
import { confirm } from '../components/Confirmation';

const { REACT_APP_API_URL } = process.env;
const qs = new URLSearchParams(window.location.search);
/**
 * Request Wrapper with default success/error actions
 */
const Request = async (props) => {

	// mocks an api call
	if (props.test){
		return new Promise(resolve => {
			const data = props.test;
			setTimeout(() => {
			  resolve(data);
			}, Math.random() * 20);
		});
	}	

	// regular api calls
    // Can't pull from redux as this is not a functional react component
	let token="";
	let localuser = localStorage.getItem("user");
	if (localuser) {
		try{
			localuser=JSON.parse(localuser);
			token=localuser.token;
		}catch(error){}
	}

	let params = {};
	if (qs){
		qs.forEach((value, key) => {
			params[key]=value;
		});		
	}
	if (props.data) params ={...params, ...props.data};

	const client = axios.create({
		...props.config,
		baseURL: REACT_APP_API_URL,
		headers: props.headers || {'Authorization': token}, /* for sending files {'Content-Type': 'multipart/form-data' } */ 
		method: props.method || "GET",
		data: props.method!=="GET" ? params || {} : undefined,
		params: !props.method || props.method==="GET" ? params || {} : undefined,
		timeout: 10000
	});

	const onSuccess = (response) => {
        let responseObject = {
            status: response.status,
            ...response.data
        }
		return responseObject;
  	}

  	const onError = (error=null) => {
		let errordata={
			message: "An error ocurred.",
			request: error?.config || null
		};
		
		//timeout needs to come first here because it's also a response, 
		//but lacks all the other things for a normal response as the timeout is defined by the frontend, not the back
		 if (error === "timeout"){ 
			errordata={
				status: 408, //checked code, according the MDN
				error: "Your request has timed out."
			}
		} else if (error?.response) {
			errordata={
				...errordata,
				status: error.response.status,
				data: null,
				headers: error.response.headers,
                error: error.response.data?.error || error.response.data || null
			}
		} else if (error?.message) {
            errordata.message = error.message;
        }

		return errordata;
	}

    // this will execute on every request right after the call to await client (before success or error)
	client.interceptors.response.use(config => config, err => {
		let skip401 = false;
		const error = err.response;
		const code = err.code;
		const msg = err.message;
		if(error.data.error === "Invalid username or password.") skip401 = true; //so we don't see if for login errors
		if(window?.location?.pathname?.includes("signout")) skip401 = true //so we don't see it if any late calls happen after logging out
        // this only happens when the token is expired, not when login has wrong credentials
		//just checking for the 401 now, as the other things that held 401 have been changed
		if (error?.status===401 && !skip401 && !error?.config.__isRetryRequest) {
			onError(error);
            // open a modal
            if (!localStorage.getItem('expired-auth')) {
                localStorage.setItem('expired-auth', true); // prevent multiple modals stacking on top of each other
                confirm(props.text,{
                    title:"Your session has expired",
                    confirmation: "Please sign in again to continue.",
                    okText:"Sign Back In",
                    // timeout: 30000, // 30 seconds
                }).then(result =>{
                    window.location.href = "/signout";
                });
            }
	   	} 
		if(code === "ECONNABORTED" && msg.includes("timeout of 10000ms exceeded")){
			let timeoutError = "timeout"
			return Promise.reject(timeoutError);
		}
        return Promise.reject(err);
	});

    try {
		const response = await client({...props});
        // console.log("response",response);
        if (!response) {
            return onError();
        }
		return onSuccess(response);
	} catch (error) {
		return onError(error);
	}
}

export default Request;
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import { startOfDay, isAfter, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import {Container, Button} from 'react-bootstrap';

import Email from './Email';
import PrintFullPage from '../../../../Print/PrintFullPage';

import Toast from '../../../../../../components/Toast';
import { confirm } from '../../../../../../components/Confirmation';
import {refreshOrder} from '../../../../../../utils/thunks';
import APIPOS from '../../../../../../api/Pos';

import styles from './Options.module.scss';

export const Options = (props) => {
    const {onClose} = props;

    const dispatch = useDispatch();

    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [showEmail, setShowEmail] = useState(false);
    const [showPrint, setShowPrint] = useState(false);

    const isPastMidnight = useMemo(() => {
        if (!props?.transaction?.date) return false;
        const startOfToday = startOfDay(new Date());
        return isAfter(startOfToday, parseISO(props.transaction.date));
    }, [props?.transaction?.date]);

    const printToggleHandler = useCallback(async (e, type="print") => {
        e.preventDefault();

        if (type === "email") {
            setShowEmail(!showEmail);
            setShowPrint(false);
        } else {
            setShowPrint(true); // this one is not going to toggle because its a direct action to print
            setShowEmail(false);
        }
    }, [showEmail]);

    const voidHandler = useCallback(async (e, type = "void") => {
        e.preventDefault();

        setShowPrint(false);
        setShowEmail(false);

        const result = await confirm(`Are you sure you want to ${type} this transaction?`, {
            title: `${type==="void" ? "Void" : "Refund"} Transaction`,
            okText: "Yes",
            cancelText: "No",
            cancelButtonStyle: "primary",
            okButtonStyle: "light"
        });
        if (result) {
            try{
                setSubmitting(true);
                let fnc = APIPOS.payment.processVoid;
                if (type === "refund") fnc = APIPOS.payment.processRefund; // this is here for the heck of it, but refunds are not allowed here and should be done through the refund wizard
                const res = await fnc({transaction_id: props.transaction.id, order_id: props.order.id, memo: `user ${type === "void" ? "voided" : "refunded"}`});
                if (res.errors) setError(res.errors);
                else if (res.data) {
                    setError(null);
                    setSuccess(<Toast>Transaction {type==="void" ? "Voided" : "Refunded"} Successfully!</Toast>);
                    dispatch(refreshOrder(props.order.register_id));
                }
                setSubmitting(false);
            } catch(err){
                setError(err.message);
                setSubmitting(false);
            }
        }
    }, [props.transaction, props.order, dispatch]);

    useEffect(() => {
        if (success && onClose){
            setTimeout(()=>onClose(), 3000);
        }
    }, [success, onClose]);

    if (!props.transaction || !props.order) return null;

    return (
        <>
            {success}
            <Container fluid className={styles.wrapper}>
                <Button variant="light" disabled={submitting} onClick={printToggleHandler}>Print Receipt</Button>
                {showPrint &&
                    <PrintFullPage 
                        print 
                        type="transaction" 
                        order={props.order} 
                        transaction={props.transaction} 
                        onAfterPrint={()=>setShowPrint(false)}
                    />
                }

                <Button variant="light" disabled={submitting} onClick={e=>printToggleHandler(e,"email")}>Email Receipt</Button>
                {showEmail &&
                    <Container className={styles["option-wrapper"]}>
                        <Email transaction={props.transaction} order={props.order} onClose={()=>setShowEmail(false)} />
                    </Container>
                }

                {onClose &&
                    <Button variant="light" onClick={onClose} disabled={submitting}>No Receipt</Button>
                }
                {props?.transaction?.transaction_status_id === 7 && 
                    <>
                        <hr/>
                        {!isPastMidnight &&
                            <Button variant="light" onClick={voidHandler} disabled={submitting || success}>Void Transaction</Button>
                        }
                        {/*isPastMidnight &&
                            <Button variant="light" onClick={e => voidHandler(e, "refund")} disabled={submitting || success}>Refund Transaction</Button>
                        */}
                    </>
                }
            </Container>
            {error &&
                <div className="error-text">{error}</div>
            }
        </>
    );
}
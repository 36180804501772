import React from 'react'

import Invoice from './Invoice';
import Receipt from './Receipt';
import Kitchen from './Kitchen';
import Transaction from './Transaction';

export const FullPageReceipt = React.forwardRef((props, ref) => {
    switch(props.type){
        case "invoice":
            return (
                <Invoice {...props} ref={ref} />
            );
        case "receipt":
            return (
                <Receipt {...props} ref={ref} />
            );
        case "kitchen":
            return (
                <Kitchen {...props} ref={ref} />
            );
        case "transaction":
            return (
                <Transaction {...props} ref={ref} />
            );
        default:
            return null;
    }
});
import React from 'react';
import { randomUUID } from '../../../../../utils/cms';

const Text = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    let Component = "p";
    if (props?.type === "Text") Component = "span";
    const _props={
        ref: ref, 
        id: props.is_preview ? `preview-${randomUUID()}` : props.id,
        className: `Text_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' '),
        style: props?.style || null,
        onClick: preview_click
    }

    //

    let text = props?.text || `Text`;
    if (text.includes("\r\n") || text.includes("\r") || text.includes("\n")) {

        // replace line breaks with <br />
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');

        /*text = text.split(/\r\n|\r|\n/).map((line, i) =>{
            return <>{line}<br /></>
        });*/
    }
    
    return <Component {..._props} dangerouslySetInnerHTML={{__html: text}}/>;
});

export default Text;
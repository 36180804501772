import Pos from '../api/Pos'

/**Returns transaction status and an error in the case of error
 * Returns as {error: ..., transactionStatus: ...}
 * @var transactionStatus 
 * @var transactionId 
 */
export const checkTransaction = async (transactionStatus, transactionId) =>{
    if (transactionStatus === 6 && transactionId){
        let transactionStatus;
        let response=await Pos.payment.terminalStatus({"transaction_id": transactionId});
        if (response.errors){
            if(response.errors?.transaction?.success === false) {
                transactionStatus=8;
            } else {
                transactionStatus=4;
            }
            return ({
                error: response.errors,
                transactionStatus: transactionStatus
            })
        } else if(response.data) {
            transactionStatus = response.data[0]?.transaction_status_id;
            return({
                error: null,
                transactionStatus: transactionStatus
            }) 
        }
    }
}
import React,{useState,useEffect, useCallback} from 'react';

import OrderTable from '../OrderTable';

import '../Orders.scss';

export const POSOrders = (props) => {

    const windowSize=window.innerWidth;

    //uses different settings for mobile due to being in a modal
    const [isMobile, setIsMobile]=useState(false);

    // first load
    useEffect(() => {
        let mounted = true;

        if(windowSize <=999 && mounted) setIsMobile(true);

        return()=> mounted=false;
        
    },[windowSize]);


    return (
        <>
            <OrderTable
                isMobile={isMobile}
                onClose={props.onClose}
                from={"pos-orders"}
                orderLimit={10}
                setActiveOrderId={()=>{}}
                setSuccess={props.setSuccess}
                setError={props.setError}
                referer={window.location.pathname}
            />
        </>
    );
}

export default POSOrders;
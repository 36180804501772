import React from 'react'
import { Card } from 'react-bootstrap';
import { format } from 'date-fns'

const SubMap = ({membership, i, ...props}) => {
    return (
        <Card key={`subs-pos-${i}`} data-cy={`subscription-${membership?.product_variant_id}`} className="info-container detail-card"> 
            <div className="bold">
                {membership?.product_name} {membership?.product_variant_name?.trim()!=="Default" ? membership?.product_variant_name : ""}
            </div>
            <div className={
                    (membership?.subscription_status==="Active" && "sub-active")
                    || (membership?.subscription_status==="Expired" && "sub-expired")
                    || (membership?.subscription_status==="Cancelled" && "sub-cancelled")
                    || (membership?.subscription_status==="Suspended" && "sub-suspended")
                }
            >
                {membership?.subscription_status} {membership?.subscription_type_id ? ` -- ${membership?.subscription_type_name}` : ""} Subscription
            </div>
            {/* {membership?.first_bill_date && 
                <div>
                    First Bill Date: {format(new Date(membership?.first_bill_date), "MM/dd/yy")}
                </div>
            } */}
            {membership?.last_bill_date && 
                <div>
                    Last Bill Date: {format(new Date(membership?.last_bill_date), "MM/dd/yy")}
                </div>
            }
            {membership?.next_bill_date && 
                <div>
                    Next Bill Date: {format(new Date(membership?.next_bill_date), "MM/dd/yy")}
                </div>
            } 

        </Card>
    )
}

export default SubMap
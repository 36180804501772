import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import { authCheck } from "../../../../utils/auth";
import Spinner from '../../../../components/Spinner';
import { authUserHasModuleAccessMany } from "../../../../utils/auth";
import Users from '../../../../api/Users';

import '../../Subscriptions/Subscriptions.css';

const VIEW_MODULE_ID = 78;

export const ResetPassword = (props) => {
    let history = useHistory();

    const [submitting, setSubmitting] = useState(false);
    const [body, setBody] = useState(false);
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);

    let user = authCheck(history);

    useEffect(() => {
        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccessMany([VIEW_MODULE_ID], props.user_id);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }
        checkPermission();
    }, [props.user_id]);

    // form submission
    const submitHandler = async (e) => {
        setSubmitting(true);

        await Users.resetPasswordEmail({username:props.user.username});
        setBody(
            <React.Fragment>
                <p>A password reset link has been sent to the user's email.</p>
            </React.Fragment>
        );
    };

    if (!body){
        setBody(
            <div className="site-col" style={{"alignItems": "center"}}>
                <p className="mt-3">By clicking this button, an email with a password reset link will be sent to the user's email account.</p>
                <div className="d-flex mt-2">
                    <Button type="button" size="lg" disabled={submitting} className={`btn-danger ${submitting?" submitting":""}`} onClick={submitHandler}>Reset Password</Button>
                </div>
            </div>
        );
    }

    return (
        <Container fluid>
            <h4 className="section-title">Reset Password</h4>
            <hr/>
            {userHasModulePermission===null ?
                <><Spinner /></>
            :
            !userHasModulePermission[VIEW_MODULE_ID] ?
                <p>You do not have permission to reset this user's password.</p>
            :
                <Row>
                    <Col>{body}</Col>
                </Row>
            }
        </Container>
    );
}

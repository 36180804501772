import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { addToCart } from "../../../utils/thunks";
import { cartToItems } from "../../../containers/POS/Items/Utils";
import ErrorCatcher from "../../../components/common/ErrorCatcher";
import Toast from "../../../components/Toast";

import Products from "../../../api/Products";

import "./UnpaidEvents.scss";

import Users from "../../../api/Users";

export const UnpaidEvents = (props) => {
    let dispatch = useDispatch();
    const items = useSelector((state) => state.pos[props.register]?.items);

    const mountedRef = useRef(false);
    const [error, setError]=useState();
    const [success, setSuccess]=useState();
    const [loading, setLoading] = useState(true);
    const [pendingChargesOriginal, setPendingChargesOriginal] = useState();
    const [pendingCharges, setPendingCharges] = useState();
    const [selectedCharge, setSelectedCharge] = useState();
    const [showModal, setShowModal] = useState(false);
    const [duplicate, setDuplicate] = useState(false);
    const [forUser, setForUser]=useState();
    const [change, setChange]=useState(false);
    const [refresh, setRefresh] = useState(false);
        
    const markInCartEvents = useCallback(()=>{
        if (pendingChargesOriginal) {
            let charges = [...pendingChargesOriginal];
            //data is nested and original items are dependant upon multiple levels of the nesting
            if(items.length > 0 && charges){
            let inCartCharges=[];
            items.forEach((item)=>{
                charges.forEach((familyMember)=>{
                    familyMember.charges.forEach((charge)=>{
                    charge.inCart=false;
                        if(familyMember.id === item.event?.for_user_id){
                            if(charge.product_id === item.product_id){
                                inCartCharges.push(charge)
                            }
                        }
                    })
                })
            })
            inCartCharges.forEach((charge)=>{
                charge.inCart=true;
            });
            setChange(prev=>!prev);
            }
            //lighter loop to go through if there are no items in the cart
            else if(charges){
                charges.forEach((familyMember)=>{
                    familyMember.charges.forEach((charge)=>{
                    charge.inCart=false;
                    })
                })
            }
            setPendingCharges(charges);
        }
        setLoading(false);
    },[pendingChargesOriginal, items]);

        useEffect(()=>{
            mountedRef.current = true;

            return()=> mountedRef.current = false;
        },[]);

        useEffect(()=>{
            if(mountedRef.current && items) {
                markInCartEvents();
            }
        },[markInCartEvents, pendingChargesOriginal, items]);
    
    const openModalHandler = (user, charge) => {
        setDuplicate(false);
        let matchingItems = items.filter( item => item.product_id === charge.product_id);
        matchingItems.forEach( item => {
        if( item.event?.for_user_id === user.id) {
            setDuplicate(true);
        }
        });
        setSelectedCharge(charge);
        setForUser(user);
        setShowModal(true);
    };

    const closeModalHandler = () => {
        setShowModal(false);
    };

    const addChargeToCart = async () => {
        await Products.get({ id: selectedCharge.product_id })
        .then((response) => {
            let item = response.data.products[0];
            dispatch(
            addToCart([
                {
                category_id: item.categories[0]?.id,
                discounts: 0,
                hash: null,
                id: item.id,
                product_type_id: item.product_type_id,
                is_taxable: item.is_taxable,
                original_price: +item.product_variants[0].price,
                parent_id: null,
                product_name: item.name,
                product_price: +item.product_variants[0].price,
                qty: 1,
                type: 1,
                variant_id: item.product_variants[0].id,
                variant_name: item.product_variants[0].name,
                event: {
                    event_id: selectedCharge.event_id,
                    for_user_id: forUser.id,
                },
                },
            ],
            false,
            props.register)
            );
        })
        .catch((e) => console.error(e));
        closeModalHandler();
    };

    useEffect(() => {
        const getLatestOutstandingCharges = async (id) => {
          const response=await Users.getOutstandingCharges({ id: id });
          if (response.data) {
            let charges = [], familyCharges = [];
            
            if (response.data.outstanding_charges?.length) charges.push({ name: props.user.first_name, id: props.user.id, charges: [] });
            
            response.data.outstanding_charges?.forEach((charge) => {
              charges[0].charges.push(charge);
            });

            response.data.family?.forEach((user, i) => {
              if (user.outstanding_charges?.length) {
                  familyCharges[i] = {
                    name: user.first_name,
                    id: user.id,
                    charges: [],
                  };
                  user.outstanding_charges?.forEach((charge) => {
                    familyCharges[i]?.charges.push(charge);
                  });
              }
            });
            familyCharges?.forEach((charge) => charges.push(charge));
            setPendingChargesOriginal(charges);
            if(charges.length === 0) setLoading(false); //if there are no charges, it won't trigger the useEffect that triggers the loading state
          }
        }

        if (props.user.id && mountedRef.current) {
            getLatestOutstandingCharges(props.user.id);
        }
    }, [props.user, refresh, mountedRef]);

    const removeEvent = async (user_id, event_id) => {
        setSuccess();
        setError();

        // Filter it from products already in the cart
        let cart_items = items.filter((item) => item?.event?.event_id !== event_id && item?.event?.for_user_id !== user_id);

        try{
            let response=await Users.eventRemove({
                user_id: forUser.id,
                event_id: event_id,
            })
            if (!response.errors) {
                dispatch(addToCart(await cartToItems(cart_items), true, props.register));
                setRefresh(refresh => 1-refresh);
                setSuccess(<Toast>User successfully unregistered from {selectedCharge.event_name}.</Toast>)
            }else if(response.errors){
                setError(<ErrorCatcher error={response.errors} />)
            }
        }catch(ex){console.error(ex)}
        
        closeModalHandler();
    };

    useEffect(()=>{ 
      return()=>{
        setPendingChargesOriginal(null);
        setPendingCharges(null);
        setSelectedCharge(null);
        setForUser(null);
      }
    },[]);

  return (
    <React.Fragment>
      <div className={loading || !pendingCharges ? 'm-3' : ''}>
      {loading ? 
        <SkeletonTheme color="#e0e0e0">
          <Skeleton height={30} style={{marginBottom:"1rem"}} />
          <Skeleton height={16} count={4} />
        </SkeletonTheme>
      :
        <>
        {pendingCharges && pendingCharges.length>0 &&
        <table width="100%" className="mb-3" data-cy="unpaid-events-table">
          <tbody>
            <tr className="tr-light-bg">
              <th width="25%" className="tr-p-10">
                <span className="eve-he">User</span>
              </th>
              <th width="50%" className="tr-p-10">
                <span className="eve-he">Event</span>
              </th>
              <th width="25%" className="tr-p-10">
                <span className="eve-he">Amount</span>
              </th>
            </tr>
              {pendingCharges && pendingCharges.map((user, i) =>
                  user.charges?.map((charge, j) => (
                    <tr
                      data-cy="home-each-charge-row"
                      className={charge.inCart ? "bd-bt clickable background-logo-blue-50": "bd-bt clickable"}
                      onClick={() => openModalHandler(user, charge)}
                      key={`${i}-${j}`}
                    >
                      <td width="25%" className="tr-p-10">
                        {user.name}
                      </td>
                      <td width="50%" className="tr-p-10">
                        <span className="unpd-e-tex">{charge.event_name}</span>
                      </td>
                      <td width="25%" className="tr-p-10">
                        <span>${charge.product_variants[0].price}</span>
                        {" "}
                        {charge.inCart ? <i className="far fa-shopping-cart"></i> : null}
                      </td>
                    </tr>
                  ))
                )
              }
          </tbody>
        </table>
        }
        
        {(!pendingCharges || pendingCharges.length<=0) &&
          <div>No pending charges</div>
        }
        <br />
        </>
      }
      </div>
      <Modal centered show={showModal} onHide={closeModalHandler} data-cy="add-charge-modal">
        <Modal.Header closeButton>
          <Modal.Title>{ duplicate ? "Oops" : "Add to Cart" }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { !duplicate && <span className="unpd-e-tex">
            {selectedCharge?.event_name} for {props.user?.name} - $
            {selectedCharge?.product_variants[0].price}
          </span> }
          { !duplicate && <br/> }
          <span>{ duplicate ? "This event fee is already in your cart." : "Add this event fee to your shopping cart?" }</span>
        </Modal.Body>
        { !duplicate ? <Modal.Footer>
          <Button variant="secondary" onClick={closeModalHandler}>
            No
          </Button>
          <Button variant="primary" onClick={addChargeToCart}>
            Yes
          </Button>
          {forUser &&
            <Button
              variant="danger"
              onClick={() => {
                let user_id = forUser.id;
                let unpaid_event_id = selectedCharge.event_id;
                removeEvent(user_id, unpaid_event_id);
              }}
            >
              Unregister
            </Button>
          }
        </Modal.Footer> : <br/> }
      </Modal>
      {success}
      {error}
    </React.Fragment>
  );
};

import Request from './Api';

// get module(s)
const Modules={
    get: async (props) => {
        return(
            Request({
                url: "/module",
                method: "POST",
                data: props
            })
        )
    },
    create: async (props) => {
        return (
            Request({
                url: "/module/create",
                data: props,
                method: "POST"
            })
        );
    },
    update: async (props) => {
        return (
            Request({
                url: "/module/edit",
                data: props,
                method: "PUT"
            })
        );
    },
    assign: async (props) => {
        return (
            Request({
                url: "/module/assign",
                data: props,
                method: "POST"
            })
        );
    },
    assignMany: async (props) => {
        return (
            Request({
                url: "/module/assign_many",
                data: props,
                method: "POST"
            })
        );
    },
    sort: async(props)=>{
        return(
            Request({
                url: "/module/sort",
                data: props,
                method: "POST"
            })
        )
    }
}

const getModuleTypes = async(props)=>{
    return (
        Request({
            url: "/module/type",
            data: props,
            method: "POST"
        })
    )
}

const ModuleTypes={
    get: async (props) => {
        return(
            Request({
                url: "/module/type",
                method: "POST",
                data: props
            })
        )
    },
    add: async (props) => {
        return (
            Request({
                url: "/module/type/add",
                method: "POST",
                data: props
            })
        )
    },
    edit: async (props) => {
        return(
            Request({
                url: "/module/type/edit",
                method: "PUT",
                data: props
            })
        )
    },
    delete: async (props) => {
        return(
            Request({
                url: "/module/type/delete",
                method: "DELETE",
                data: props
            })
        )
    }
}

const Features = {
    getAll: async props =>{
        return (
            Request({
                url: "/feature",
                data: props,
                method: "POST"
            })
        )
    }, 
    create: async props =>{
        return (
            Request({
                url: "/feature/create",
                data: props,
                method: "POST"
            })
        )
    },
    edit: async props =>{
        return (
            Request({
                url: "/feature/edit",
                data: props,
                method: "PUT"
            })
        )
    },
    delete: async props =>{
        return (
            Request({
                url: "/feature/delete",
                data: props,
                method: "DELETE"
            })
        )
    },
    sort: async props =>{
        return (
            Request({
                url: "/feature/sort",
                data: props,
                method: "POST"
            })
        )
    },
    permissions: async props =>{
        return(
            Request({
                url: "/feature/permission",
                data: props,
                method: "POST"
            })
        )
    },
    assign: async props =>{
        return(
            Request({
                url: "/feature/assign",
                data: props,
                method: "POST"
            })
        )
    }
}

const Endpoints = {
    getAll: async props =>{
        return (
            Request({
                url: "/endpoint",
                data: props,
                method: "POST"
            })
        )
    },
    create: async props =>{
        return (
            Request({
                url: "/endpoint/create",
                data: props,
                method: "POST"
            })
        )
    },
    createMany: async props =>{
        return (
            Request({
                url: "/endpoint/create_many",
                data: props,
                method: "POST"
            })
        )
    },
    edit: async props =>{
        return (
            Request({
                url: "/endpoint/edit",
                data: props,
                method: "PUT"
            })
        )
    },
    delete: async props =>{
        return (
            Request({
                url: "/endpoint/delete",
                data: props,
                method: "DELETE"
            })
        )
    }
}

const PermissionLevels = {
    get: async props =>{
        return (
            Request({
                url: "/module/permission_level",
                data: props,
                method: "POST"
            })
        )
    },
    create: async props =>{
        return (
            Request({
                url: "/module/permission_level/create",
                data: props,
                method: "POST"
            })
        )
    },
    edit: async props =>{
        return (
            Request({
                url: "/module/permission_level/edit",
                data: props,
                method: "PUT"
            })
        )
    },
    delete: async props =>{
        return (
            Request({
                url: "/module/permission_level/delete",
                data: props,
                method: "DELETE"
            })
        )
    }
}

const MenuItems = {
    get: async props =>{
        return (
            Request({
                url: "/menu_item",
                data: props,
                method: "POST"
            })
        )
    },
    create: async props =>{
        return (
            Request({
                url: "/menu_item/create",
                data: props,
                method: "POST"
            })
        )
    },
    edit: async props =>{
        return (
            Request({
                url: "/menu_item/set",
                data: props,
                method: "POST"
            })
        )
    },
    editMany: async props=>{
        return(
            Request({
                url: "/menu_item/set_many",
                data: props,
                method: "POST"
            })
        )
    },
    sort: async props =>{
        return(
            Request({
                url: "/menu_item/sort",
                data: props,
                method: "POST"
            })
        )
    },
    delete: async props =>{
        return (
            Request({
                url: "/menu_item/delete",
                data: props,
                method: "DELETE"
            })
        )
    }
}

const UserModulePermission = {
    get: async(props) => {
        return (
            Request({
                url: `/user/module_permission`,
                data: props,
                method: "POST"
            })
        )
    }
}

const Permissions = {
    Modules, getModuleTypes, ModuleTypes, PermissionLevels, Features, Endpoints, MenuItems, UserModulePermission
}
  
export default Permissions;

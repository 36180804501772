import React, { useState, useEffect } from 'react';
import { Button, Col } from 'react-bootstrap';

import Products from '../../../api/Products';

import './MembershipDetail.css';

export const MembershipDetail = props => {

	const [subs, setSubs] = useState([]);

    const addSub = sub => {
        setSubs(prevState => [...prevState, sub]);
    }

    useEffect(() => {
        let mounted = true;

        if(subs.length === 0) { // don't run if subs already set
            if(props?.user?.subscriptions) {
                props.user.subscriptions?.forEach( sub => {
                    Products.Variants.get({id: sub.product_variant_id})
                    .then( response => {
                        if(mounted) {
                            let interval = "";
                            switch(response.data[0].bill_interval) {
                                case "y":
                                    interval = "Year";
                                    break;
                                case "m":
                                    interval = "Month";
                                    break;
                                case "d":
                                    let qty = response.data[0].interval_qty;
                                    interval = qty > 1 ? qty + " Days" : "Day";
                                    break;
                                default:
                                case null:
                                    interval = null;
                                    break;
                            }
                            addSub(
                                {
                                    name: sub.product_name,
                                    status: sub.subscription_status,
                                    price: response.data[0].price,
                                    interval: interval,
                                    id: sub.id
                                }
                            );
                        }
                    }).catch(e => console.error(e));
                });
            }
        }
        return () => mounted = false;
    },[props, subs]);

	return (
		<React.Fragment>
            <div data-cy="home-memberships">
                {subs?.map( (sub, i) =>
                    <Col key={`mem-details-list-${i}`} className="subs-col" data-cy={`sub-${sub.id}`}>
                        {/* no id, needed to use index as key */}
                        <span className="subs-active">{sub.status}</span>
                        <h5 className="profile-name">{sub.name}</h5>
                        {/* <div className="subs-desc">+ More Cyro Sessions</div>
                        <div className="subs-desc">+ More Fitness Class Tokens</div>
                    <div className="subs-desc">+ Discounts on golf Simulators</div> */}
                        {/* <hr /> */}
                        <span className="prof-amount">${sub.price}{sub.interval ? "/" + sub.interval : null}</span>
                        <hr />
                        {/* <div className="profile-basic">Expires on 03 Sept 2021;</div> */}
                    </Col>
                )}
                {subs?.length === 0 && <span>No active membership</span>}
            </div>
		</React.Fragment>
	)
}
import React from 'react';
import {Card} from 'react-bootstrap';

import Button from '../Button';

import styles from './PaymentButton.module.scss';

const PaymentButton = (props) => {

    let icon = "far fa-credit-card";
    if (props.type==="visa") icon="fab fa-cc-visa";
    if (props.type==="mastercard") icon="fab fa-cc-mastercard";
    if (props.type==="amex") icon="fab fa-cc-amex";
    if (props.type==="discover") icon="fab fa-cc-discover";
    if (props.type==="dinersclub") icon="fab fa-cc-diners-club";
    if (props.type==="paypal") icon="fab fa-cc-paypal";
    if (props.type==="stripe") icon="fab fa-cc-stripe";
    if (props.type==="applepay") icon="fab fa-cc-apple-pay";
    if (props.type==="googlepay") icon="fab fa-cc-google-pay";
    if (props.type==="jcb") icon="fab fa-cc-jcb";
    if (props.type==="bank") icon="far fa-money-check-alt";

    return (
        <Button {...props}>
            <Card.Body className={`${styles.container} ${props.active?styles.active:""}`}>
                <i className={`${styles.icon} ${icon}`}/>
                <div className='bold'>
                    {props.name}
                </div>
            </Card.Body>
        </Button>
    );
}

export default PaymentButton;
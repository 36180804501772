import React from 'react'


//three types of split payments:
//spMultiSingle spMultiMany spCashCC
export const SplitPaymentReceipts = ({styles, ...props}) => {

  return (
    <>
        <tr>
            <td style={styles.columnName}>
                {props?.payment_type==="spCashCC" ? 
                    <span>Subtotal</span>
                :
                    <span>Original Subtotal</span>
                }
            </td>
            <td style={styles.columnPrice}>${props?.order?.subtotal_price?.toFixed(2)}</td>
        </tr>
            {props?.payment_type ==="spCashCC" ?
                <>
                    <tr>
                        <td style={styles.columnName}>
                            Tax
                        </td>
                        <td style={styles.columnPrice}>
                            ${(props?.order?.tax_total).toFixed(2)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <hr />
                        </td>
                    </tr>
                </>
                :
                null
            }
            {(props?.payment_type === "spMultiSingle" || props?.payment_type==="spMultiMany") ?
                <>
                    <tr >
                        <td style={styles.columnName}>Your Subtotal</td>
                        <td style={styles.columnPrice}>${props?.payee?.order?.subtotal_price}</td>
                    </tr>
                    {props?.payee?.order?.tip>0 && 
                        <tr >
                            <th style={styles.columnName}>Tip</th>
                            <th style={styles.columnPrice}>${props.payee.order.tip?.toFixed(2)}</th>
                        </tr>
                    }
                    <tr>
                        <td style={styles.columnName}>Tax</td>
                        <td style={styles.columnPrice}>${(props?.payee?.order?.tax_total).toFixed(2)}</td>
                    </tr>
                    {props?.payee?.order?.price_adjustments?.map((adjustment, i)=>(
                        <tr key={`pa-order-${i}`}>
                            <td style={styles.columnName}>{adjustment.price_adjustment_type_name}</td>
                            <td style={styles.columnPrice}>
                                {adjustment.amount > 0 ? "" : "-"}
                                ${Math.abs(adjustment.amount).toFixed(2)}
                            </td>
                        </tr>
                    ))}
                    {/*props?.payee?.paymentType !== "cash" ?
                        <tr>
                            <td style={styles.columnName}>
                                Administrative Fee ({props?.adminFeeRate?.toFixed(2)}%)
                            </td>
                            <td style={styles.columnPrice}>
                                ${(props?.payee?.order?.admin_fee_total)?.toFixed(2)}
                            </td>
                        </tr>
                    :
                        null
                    */}
                    <tr>
                        <td colSpan={2}>
                            <hr />
                        </td>
                    </tr>
                    <tr className="font-weight-bold">
                        <th style={styles.columnName} className="ml-3">Your Total</th>
                        {props?.payee?.paymentType === "cash" ?
                            <>
                                <td style={styles.columnPrice}>${(props?.payee?.order?.subtotal_price + props?.payee?.order?.tax_total).toFixed(2)}</td>
                            </>
                        :
                            <td style={styles.columnPrice}>${props?.payee?.order?.total_price?.toFixed(2)}</td>
                        }
                    </tr>
                    {(props?.payee?.paymentType==="cash" && props?.payee?.cash?.change_owed !==0) &&
                        <tr>
                            <th style={styles.columnName}>Change Owed</th>
                            <th style={styles.columnPrice}>${(props?.payee?.cash?.change_amount).toFixed(2)}</th>
                        </tr>
                    }
                </>
            :
                null
            }
            {/* {(props?.payment_type !=="spCashCC" && props?.payment_type !=="spMultiSingle") && 
                <>
                    <tr>
                        <th>Total</th>
                        <th>${props.order?.total_price?.toFixed(2)}</th>
                    </tr>
                    <tr>
                        <th>Payment</th>
                        <th>${props?.payment_cash?.toFixed(2)}</th>
                    </tr>
                    <tr>
                        <th>Change</th>
                        <th>${props?.payment_change?.toFixed(2) || 0.00}</th>
                    </tr>
                </>
            } */}
            {props?.status===2 && props?.payment_type==="spCashCC" && 
                <>
                    <tr>
                        <th style={styles.columnName}>Cash Payment</th>
                        <th style={styles.columnPrice}>
                            ${props?.payment_cash?.toFixed(2)}
                        </th>
                    </tr>
                    <tr>
                        <th style={styles.columnName}>Change</th>
                        <th style={styles.columnPrice}>${props?.payment_change?.toFixed(2) || 0.00}</th>
                    </tr>
                    <tr>
                        <td style={styles.columnName}>Card Payment: </td>
                        <td style={styles.columnPrice}>${(props?.cardPayment?.subtotal_price + props?.cardPayment?.tax_total).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td style={styles.columnName}>Administrative Fee ({props.adminFeeRate.toFixed(1)}%)</td>
                        <td style={styles.columnPrice}>${props?.cardPayment?.admin_fee_total}</td>
                    </tr>
                    <tr>
                        <th style={styles.columnName}>Full Total</th>
                        <th style={styles.columnPrice}>${(props?.order?.subtotal_price + props?.order?.tax_total + props?.cardPayment?.admin_fee_total).toFixed(2)}</th>
                    </tr>
                </>
            }
    </>
  )
}
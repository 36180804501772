import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

const CustomSelectField = props => {

    const [name, setName] = useState("");
    const [options, setOptions] = useState([]);
    const [required, setRequired] = useState(false);
    //const [validate, setValidate] = useState("");
    //const [message, setMessage] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [currentFieldValue, setFieldValue] = useState("");

    const fieldValueHandler = event => {
        setFieldValue(event.target.value);
    }

    useEffect(() => {
        setName(props.name);
        setOptions(props.options);
        if(props.required) setRequired(props.required);
        //if(props.validate) setValidate(props.validate);
        //if(props.message) setMessage(props.message);
        if(props.placeholder_text) setPlaceholder(props.placeholder_text);
        if(props.default_value) setFieldValue(props.default_value);
    },[props])

    return (
        <Form.Group controlId={name}>
            <div className="form-select-wrap event-custom">
                <Form.Label>{placeholder}</Form.Label>
                <Form.Control required={required} as="select" className="form-select" name={`custom_${name}`} value={currentFieldValue || ""} onChange={fieldValueHandler}>
                    <option key={`${name}_placeholder`} value="" disabled={required ? true : false}>Select an Answer</option>
                    {options.map( (option, i) =>
                        <option key={`${name}_${i}`} value={option.value}>{option.text}</option>
                    )}
                </Form.Control>
            </div>
        </Form.Group>
    );
}

export default CustomSelectField;
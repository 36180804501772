import React, {useState} from 'react';
import {Container, Button, Modal} from 'react-bootstrap';

import Options from './Options';

import styles from './Transactions.module.scss';

export const Transactions = (props) => {
    const [show, setShow] = useState(false);
    const [transaction, setTransaction] = useState();

    const closeHandler = () => {
        setShow(false);
        setTransaction(null);
    }

    const clickHandler = (e, transaction) => {
        e.preventDefault();
        setShow(true);
        setTransaction(transaction);
    }

    if (!props?.order || props?.order?.transactions?.length < 1) return null;

    return (
        <>
        <Container fluid className={styles.container}>
            {props.order?.transactions?.filter(a => 
                +a.transaction_status_id===7 
                && +a.transaction_type_id !== 2 
                && +a.transaction_type_id !== 3
                && +a.amount - +a.amount_refunded > 0
            ).map((transaction,i)=>(
                <Button key={`transaction-${i}`} variant="light" className={styles.button} onClick={e=>clickHandler(e, transaction)}>
                    <span>
                        {transaction.transaction_payment_method_id === 1 && 
                            <>
                                {transaction?.cc_type || "Card"}
                                {transaction.transaction_payment_method_id === 1 && transaction?.cc_number ? ` ending in ${transaction.cc_number}` : ""}
                            </>
                        }
                        {transaction.transaction_payment_method_id !== 1 && 
                            <>
                                {transaction?.transaction_payment_method_name || "Payment"}
                                {transaction.transaction_response?.check_number ? ` #${transaction.transaction_response.check_number }` : ""}
                            </>
                        }
                    </span>
                    <span>${transaction.amount.toFixed(2)}</span>
                </Button>
            ))}
        </Container>
        <Modal show={show} onHide={closeHandler} size="sm" className={styles.modal} backdropClassName={styles.backdrop}>
            <Modal.Header closeButton/>
            <Modal.Body className="p-3">
                <Options onClose={closeHandler} order={props.order} transaction={transaction} user={props.user} />
            </Modal.Body>
        </Modal>
        </>
    );
}
import FormBlock from './Components/FormBlock';
import Form from './Components/Form';
import FormGroupText from './Components/FormGroupText';
import FormGroupTextarea from './Components/FormGroupTextarea';
import FormGroupSelect from './Components/FormGroupSelect';
import FormGroupOptions from './Components/FormGroupOptions';
import FormGroupButton from './Components/FormGroupButton';
import FormGroupDatePicker from './Components/FormGroupDatePicker';

export const Components = {
    Form: {
        component: Form,
        name: "Form",
        component_name: "Form",
        tooltip: "The form is a container that can be used to group elements that can submit or retrieve data",
        apply_styles: true,
        can_drop: true,
        element_type: "form",
        list: "Forms",
        props: [
            {
                display_name: "Success Message",
                name: "success_message",
                description: "Message to display when the form is submitted successfully",
                type: "text",
                value: ""
            },
            {
                display_name: "Send To",
                name: "send_to",
                description: "Email addresses to send the form data to separated by commas, e.g. hi@siteboss.net, bye@siteboss.net",
                type: "text",
                value: "",
            },
            {
                display_name: "Mail Subject",
                name: "mail_subject",
                description: "Subject of the email to send",
                type: "text",
                value: "",
                condition:{
                    name: "send_to",
                    value: "{not_empty}"
                },
            },
            {
                display_name: "Mail Body",
                name: "mail_body",
                description: "Body of the email to send",
                type: "text",
                value: "",
                condition:{
                    name: "send_to",
                    value: "{not_empty}"
                },
            },
            {
                name: "users",
                type: "text",
                description: "Specific users to send the form data to",
                display_name: "Send to Users",
                value: [],
                source: {
                    component: "components/UserTypeahead", // we will add the path where needed
                    props: {
                        async: false,
                        name: "type-search",
                        multiple: true,
                        userSelection: "{value}", // send the value stored in the array to the component so that it populates already selected users
                        passUserSelection: "method:selection" // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas 
                    },
                    value: null,
                },
            },
        ]
    },
    FormGroupText:{
        component: FormGroupText,
        name: "Field (single-line)",
        component_name: "FormGroupText",
        tooltip: "A single-line input field that can be used to submit or retrieve data",
        apply_styles: true,
        can_drop: false,
        list: "Forms",
        props: [
            {
                display_name: "Name",
                name: "name",
                description: "Name of the single-line field. Must be unique within the form",
                type: "text",
                value: ""
            },
            {
                display_name: "Label",
                name: "label",
                description: "Label of the single-line field",
                type: "text",
                value: "Label"
            },
            {
                display_name: "Placeholder",
                name: "placeholder",
                description: "Placeholder text of the single-line field",
                type: "text",
                value: "",
            },
            {
                display_name: "Type",
                name: "type",
                description: "The type of field to use",
                type: "select",
                value: "text",
                options: ["text", "number", "password", "email", "phone"]
            },
            {
                display_name: "Required",
                name: "required",
                description: "Whether the field must be filled out when submitting the form or not",
                type: "checkbox",
                value: 0
            },
            {
                display_name: "Error Message",
                name: "feedback",
                description: "Error message to display when the field is invalid",
                type: "text",
                value: "",
                condition:{
                    name: "required",
                    value: "{not_empty}"
                }
            },
            {
                display_name: "Disabled",
                name: "disabled",
                description: "Whether the field is disabled or not",
                type: "checkbox",
                value: 0
            },
            {
                display_name: "Read Only",
                name: "readonly",
                description: "Whether the field is read only or not",
                type: "checkbox",
                value: 0
            },
        ]
    },
    FormGroupTextarea:{
        component: FormGroupTextarea,
        name: "Field (multi-line)",
        component_name: "FormGroupTextarea",
        tooltip: "A multi-line input field that can be used to submit or retrieve data",
        apply_styles: true,
        can_drop: false,
        list: "Forms",
        props: [
            {
                display_name: "Name",
                name: "name",
                description: "Name of the multi-line field. Must be unique within the form",
                type: "text",
                value: ""
            },
            {
                display_name: "Label",
                name: "label",
                description: "Label of the multi-line field",
                type: "text",
                value: "Label"
            },
            {
                display_name: "Placeholder",
                name: "placeholder",
                description: "Placeholder text of the multi-line field",
                type: "text",
                value: "",
            },
            {
                display_name: "Rows",
                name: "rows",
                description: "The number of rows to display by default",
                type: "text",
                value: "3",
            },
            {
                display_name: "Required",
                name: "required",
                description: "Whether the field must be filled out when submitting the form or not",
                type: "checkbox",
                value: 0
            },
            {
                display_name: "Error Message",
                name: "feedback",
                description: "Error message to display when the field is invalid",
                type: "text",
                value: "",
                condition:{
                    name: "required",
                    value: "{not_empty}"
                }
            },
            {
                display_name: "Disabled",
                name: "disabled",
                description: "Whether the field is disabled or not",
                type: "checkbox",
                value: 0
            },
            {
                display_name: "Read Only",
                name: "readonly",
                description: "Whether the field is read only or not",
                type: "checkbox",
                value: 0
            },
        ]
    },
    FormGroupSelect:{
        component: FormGroupSelect,
        name: "Selection Box",
        component_name: "FormGroupSelect",
        tooltip: "A selection box that can display multiple options for the user to choose from",
        apply_styles: true,
        can_drop: false,
        list: "Forms",
        props: [
            {
                display_name: "Name",
                name: "name",
                description: "Name of the selection box. Must be unique within the form",
                type: "text",
                value: ""
            },
            {
                display_name: "Label",
                name: "label",
                description: "Label of the selection box",
                type: "text",
                value: "Label"
            },
            {
                display_name: "Placeholder",
                name: "placeholder",
                description: "Placeholder text of the selection box",
                type: "text",
                value: "",
            },
            {
                display_name: "Options ",
                name: "options",
                description: "options to show on the selection box separated by commas. e.g. option1, option2, option3",
                type: "text",
                value: "option1, option2, option3",
            },
            {
                display_name: "Required",
                name: "required",
                description: "Whether the field must be filled out when submitting the form or not",
                type: "checkbox",
                value: 0
            },
            {
                display_name: "Error Message",
                name: "feedback",
                description: "Error message to display when the field is invalid",
                type: "text",
                value: "",
                condition:{
                    name: "required",
                    value: "{not_empty}"
                }
            },
            {
                display_name: "Disabled",
                name: "disabled",
                description: "Whether the field is disabled or not",
                type: "checkbox",
                value: 0
            },
            {
                display_name: "Read Only",
                name: "readonly",
                description: "Whether the field is read only or not",
                type: "checkbox",
                value: 0
            },
        ]
    },
    FormGroupOptions:{
        component: FormGroupOptions,
        name: "Option Boxes",
        component_name: "FormGroupOptions",
        tooltip: "A group of selection boxes that can be used to select one or more options",
        apply_styles: true,
        can_drop: false,
        list: "Forms",
        props: [
            {
                display_name: "Name",
                name: "name",
                description: "Name of the option group. Must be unique within the form",
                type: "text",
                value: ""
            },
            {
                display_name: "Label",
                name: "label",
                description: "Label of the option group",
                type: "text",
                value: "Label"
            },
            {
                display_name: "Type",
                name: "type",
                description: "The type of field to use",
                type: "select",
                value: "checkbox",
                options: ["checkbox", "radio"]
            },
            {
                display_name: "Options",
                name: "options",
                description: "A comma separated list of options to use",
                type: "text",
                value: "Option 1, Option 2, Option 3"
            },
            {
                display_name: "Required",
                name: "required",
                description: "Whether the field must be filled out when submitting the form or not",
                type: "checkbox",
                value: 0,
                condition:{
                    name: "options",
                    value: "{length}",
                    extra: {
                        operator: "=",
                        value: 1
                    }
                }
            },
            {
                display_name: "Error Message",
                name: "feedback",
                description: "Error message to display when the field is invalid",
                type: "text",
                value: "",
                condition:{
                    name: "required",
                    value: "{not_empty}"
                }
            },
        ]
    },    
    FormGroupButton:{
        component: FormGroupButton,
        name: "Button",
        component_name: "FormGroupButton",
        tooltip: "A button that can be used to submit data on a form or open a link",
        apply_styles: true,
        can_drop: false,
        list: "Forms",
        props: [
            {
                display_name: "Label",
                name: "label",
                description: "Label of the button",
                type: "text",
                value: "Label"
            },
            {
                display_name: "Variant",
                name: "variant",
                description: "The variant of the button",
                type: "select",
                value: "primary",
                options: ["primary", "secondary", "success", "danger", "warning", "info", "light", "dark", "link"]
            },
            {
                display_name: "Click Action",
                name: "click",
                description: "The action to perform when the button is clicked",
                type: "select",
                value: "submit",
                options: ["Submit Form", "Reset Form", "Open Link"]
            },
            {
                display_name: "URL",
                name: "href",
                description: "The URL to open when the button is clicked",
                type: "text",
                value: "https://",
                condition: {
                    name: "click",
                    value: "Open Link"
                }
            },
            {
                display_name: "Disabled",
                name: "disabled",
                description: "Whether the field is disabled or not",
                type: "checkbox",
                value: 0
            },
        ]
    },
    FormGroupDatePicker:{
        component: FormGroupDatePicker,
        name: "Date Picker",
        component_name: "FormGroupDatePicker",
        tooltip: "A date picker that can be used to select a date",
        apply_styles: true,
        can_drop: false,
        list: "Forms",
        props: [
            {
                display_name: "Name",
                name: "name",
                description: "Name of the date picker field. Must be unique within the form",
                type: "text",
                value: ""
            },
            {
                display_name: "Label",
                name: "label",
                description: "Label of the date picker field",
                type: "text",
                value: "Label"
            },
            {
                display_name: "Placeholder",
                name: "placeholder",
                description: "Placeholder text of the date picker field",
                type: "text",
                value: "",
            },
            {
                display_name: "Enable Range",
                name: "selectRange",
                description: "Whether to enable selecting a date range or not",
                type: "checkbox",
                value: 0
            },
            {
                display_name: "Min Date",
                name: "minDate",
                description: "The minimum date that can be selected",
                type: "text",
                value: "",
            },
            {
                display_name: "Max Date",
                name: "maxDate",
                description: "The maximum date that can be selected",
                type: "text",
                value: "",
            },
            {
                display_name: "Required",
                name: "required",
                description: "Whether the field must be filled out when submitting the form or not",
                type: "checkbox",
                value: 0
            },
            {
                display_name: "Error Message",
                name: "feedback",
                description: "Error message to display when the field is invalid",
                type: "text",
                value: "",
                condition:{
                    name: "required",
                    value: "{not_empty}"
                }
            },
            {
                display_name: "Disabled",
                name: "disabled",
                description: "Whether the field is disabled or not",
                type: "checkbox",
                value: 0
            },
            {
                display_name: "Read Only",
                name: "readonly",
                description: "Whether the field is read only or not",
                type: "checkbox",
                value: 0
            },
        ]
    },    
    FormBlock: {
        component: FormBlock,
        name: "Form Block",
        component_name: "FormBlock",
        tooltip: "The form block is a page element that contains a form",
        apply_styles: true,
        element_type: "form",
        list: "FormBlocks",
        props: [
            {
                name: "form_id",
                type: "select",
                options: [],
                description: "The form to display",
                display_name: "Form",
                value: "",
                source: {
                    component: "containers/Cms/Components/Properties/ContentBlockSelect", // we will add the path where needed
                    props: {
                        page_type: 10,
                        selection: "method:selection"
                    },
                },
                elements: ["FormBlock"]
            }
        ]
    },

}
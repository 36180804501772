import React from 'react';
import {format} from 'date-fns';
import { Items } from '../Elements';

import styles from '../FullPageReceipt.module.scss';

/**Needs the following props 
 * @param userDetails -user details including f, l, and m name, address1, address2, city, state, zip
 * @param order (needs order id, and price values)
 * @param items (pass in the items seperate, in case they need to be such as a parent/child order)
*/
export const Kitchen = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <div className={styles["fp-kitchen-wrapper"]}>
                <div className={`${styles["flex-wrapper-company"]}`}>
                    <h1>Order # {props.order?.id}</h1>
                    {format(new Date(props.order.updated_at), "MM-dd-yyy h:mm aa")}<br/>
                    For: {` ${props.order?.user_fname} ${props.order?.user_lname}`}
                </div>
                <Items {...props} format={"kitchen"}/>
            </div>
            {props.extraContent && props.extraContent}
        </div>
    );
});
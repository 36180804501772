import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Error from '../../../api/Error';
import { parseErrorToArray, getErrorValues } from '../../../utils/validation';

import './ErrorCatcher.scss'

/**
 * Wraps the contents of an error response in a styled div
 * 
 * @param {*} title - could have something other than OOPS at the top of the error catcher
 * @param {*} warning - tells the modal to be a blue info color instead of red error color
 * @param {*} props expects an error or children property
 * @param {*} props.onClose - a function to call when the modal is closed
 * @returns a div containing a list of the error response's values
 */
const ErrorCatcher = props => {
	
	const userInfo = useSelector(state => state.auth);

	const [show, setShow] = useState(props.show || true);
    const [error, setError] = useState();

	//const showModal = () => setShow(true);
	const closeModal = () => {
        if (props.onClose) props.onClose();
        setShow(false);
    }

    useEffect(() => {

        let contents = [];

        if (props.error) {
            console.error("Error Catcher:", props.error);
            console.trace();
            if(props.error) {
                contents = parseErrorToArray(props.error);
            } else {
                contents.push("An error has occured");
                contents = getErrorValues(props.error);
            }
        } else {
            contents.push(props.children);
        }

        setError(contents);

        let userProfile = userInfo?.user?.profile;
        let errorMessage = userProfile ? `User# ${userProfile.id} ${userProfile.first_name} ${userProfile.last_name}` : "";
        errorMessage += "\n" + window.navigator.userAgent;
        errorMessage += "\n" + convertToString(contents);
        errorMessage += "\n" + getStackTrace().join('\n');

        let msg = !props.warning ? "ERROR CATCHER DISPLAYED" : "WARNING DISPLAYED"

        if (!props.skipSave){
            Error.save({
                name: msg,
                message: errorMessage,
            });
        }
    },[props, userInfo?.user?.profile]);

    const getStackTrace = () => {
        let stack;
        try {
          throw new Error('');
        }
        catch (error) {
          stack = error.stack || '';
        }
        stack = stack.split('\n').map(function (line) { return line.trim(); });
        return stack.splice(stack[0] === 'Error' ? 2 : 1);
    };

    const convertToString = (messageArray) => {
        let messageString = "";
        messageArray.forEach((item, i) => {
            if (i!==0) messageString += "\n";
            messageString += JSON.stringify(item)?.substring(1, item.length);
        });
        return messageString;
    }
    
	return (
		<Modal data-cy="error-catcher-modal" className={!props.warning ? "error-modal" : "warning-modal"} show={show} onHide={closeModal} size={"m"} centered>
			<div className="error">
				<h1>{ props.title || "Oops!"}</h1>
				<ul>
                    {error && error.map((item, i) =>(
					    <li key = { "err" + i } style={{overflow:"hidden"}}>
						    { item/*JSON.stringify(item).substring(1, item.length) /*substring to remove quotation marks*/ }
					    </li>
                    ))}
				</ul>
			</div>
		</Modal>
	);	
};



export default ErrorCatcher;
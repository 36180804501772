import React, { useState, useEffect } from 'react';
import { Button, Card, Modal, Form, Spinner, Col, Row } from 'react-bootstrap';
import { format } from 'date-fns';

import ErrorCatcher from '../../../components/common/ErrorCatcher/ErrorCatcher';
import { DobDatePicker } from '../../../components/DatePicker';

import Users from '../../../api/Users';

import './DobPrompt.scss';

const INVALID_AFTER_DATE = new Date("2021-01-01");

export const DobPrompt = ({ user, message=null, title="Select your Date of Birth", className="", onClose=()=>{}}) => {

    const [ modalShow, setModalShow ] = useState(false);
    const [ dobValue, setDobValue ] = useState(null);
    const [ submitting, setSubmitting ] = useState(false);
    const [ error, setError ] = useState('');
    const [ success, setSuccess ] = useState('');

    const handleCloseModal = () => {
        setDobValue(null);
        setModalShow(false);
        onClose();
    }

    const handleOpenModal = () => {
        setModalShow(true);
    }

    useEffect(() => {
        if (user?.id && dobValue) {
            setSubmitting(true);
            Users.update({
                id: user.id,
                dob: format(dobValue, "yyyy-MM-dd")
            })
            .then(response => {
                if (!response.errors) {
                    setSubmitting(false);
                    setSuccess("Thank you!");
                } else if(response.errors) {
                    setSubmitting(false);
                    setError(<ErrorCatcher error={response.errors} />);
                }
            });
        }
    },[dobValue,user.id]);

    // if dob is null
    // or dob is before 1900
    // or dob is after the INVALID_AFTER_DATE
    // then it's not a valid date, display prompt
    if (user.id && (!user?.dob || new Date(user?.dob) < new Date("1900-01-01") || new Date(user?.dob) > INVALID_AFTER_DATE)) {
        return (
            <>
                {error}

                <Card className={`my-2 dob-banner ${className}`} data-cy="dob-banner" onClick={handleOpenModal}>
                    {message}
                </Card>

                <Modal
                    data-cy="dob-full-modal"
                    dialogClassName="dob-set-modal"
                    show={modalShow}
                    onHide={handleCloseModal}
                    centered
                > 
                    <Modal.Header closeButton />
                    <Modal.Body className="no-select-text">
                        <Row>
                            <Col sm="auto">
                                <DobDatePicker
                                    selected={dobValue}
                                    onChange={date => setDobValue(date)}
                                    disabled={submitting}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {submitting &&
                                    <span className="dob-message"><Spinner size="sm" animation="border" variant="dark" /></span>
                                }
                                {!submitting && success &&
                                    <span className="dob-message dob-success">{success}</span>
                                }
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Button variant="primary" data-cy="dob-set-save-btn" onClick={handleCloseModal}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    
    return (<></>);
}

import React,{useState, useEffect} from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { matchSorter } from 'match-sorter';
import { useHistory } from 'react-router-dom';
import { Modal, Nav, Form, InputGroup, Button, Pagination, Table as BsTable } from 'react-bootstrap';
/*
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
*/

import './Table.scss';
import { TableSkeleton } from '../TableSkeleton/TableSkeleton';
//import { propTypes } from 'react-bootstrap/esm/Image';

// Default UI for filtering
const GlobalFilter = ({preGlobalFilteredRows, globalFilter, setGlobalFilter, newButtonClickHandler, newButtonLabel, label=true}) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200);

    return (
        <Nav className="material navbar navbar-light">
            <Form className="d-flex flex-row w-100" onSubmit={e=>e.preventDefault()}>
                <Form.Group controlId="searchbar-input" className="form-group">
                    <Form.Label column sm={1}>Search</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={`Search ${count} record${count===1?"":"s"}`}
                        aria-label={`Search ${count} record${count===1?"":"s"}`}
                        value={value || ""}
                        onChange={e => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }} 
                    />
                </Form.Group>
                {newButtonClickHandler && <Button variant="primary" onClick={newButtonClickHandler}><i className="far fa-plus"/>{newButtonLabel || "Add New"}</Button>}
            </Form>
        </Nav>
    );
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}
  
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

const Table = ({ columns, data, newClick, newLabel, searchBar=true, className, typeAheadOnRemove=false, typeAheadSelected=false, pageSize=25, sortBy={id: "id", desc: false}, loading=false }) => {

    let history = useHistory();

    const [modalShow, setModalShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [modalTitle, setModalTitle] = useState();
    const [modalFooterItems, setModalFooterItems] = useState();

    const modalCloseHandler = () => setModalShow(false);
    const modalShowHandler = (title = null, selected, footerItems = null) => {
        setModalTitle(title);
        setSelectedRow(selected);
        setModalFooterItems(footerItems);
        setModalShow(true);
    };

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true
            })
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        /*setPageSize,*/
        state: { pageIndex, /*pageSize,*/ globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter,
        setHiddenColumns
    } = useTable(
        {
            columns,
            data: data || [],
            initialState: { pageSize: [pageSize], pageIndex: 0, sortBy: [sortBy]},
            filterTypes,
            
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    useEffect(() => {
        let hidden=[];
        columns?.[0]?.columns.forEach(a => {
            if (a.show===false) hidden.push(a.id);
        });
        setHiddenColumns(hidden);
    },[columns, setHiddenColumns]);    

    let pages=[];
    for (let i=0;i<pageCount;i++){
        pages.push(<Pagination.Item active={pageIndex===i} onClick={() => gotoPage(i)}>{i+1}</Pagination.Item>);
    }

    return (
        <>
            {(searchBar===true || searchBar==="top") &&
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    newButtonClickHandler={newClick}
                    newButtonLabel={newLabel}
                />
            }
            <BsTable {...getTableProps()} className={`table ${className}`} responsive>
                <thead>
                    <tr>
                        {headerGroups[1].headers.map((column,i) => (
                            <th key={`table-tr-${i}`} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header') &&
                                    <>
                                        <span>{column.render('Header')}</span>
                                        <i className={`fal ${column.isSorted?column.isSortedDesc?'table-sort-desc':'table-sort-asc':'table-sort-none'}`}></i>
                                    </>
                                }
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {loading &&
                        //have to specify that col.show !== false because columns will show without the show property on them as well
                        <TableSkeleton columns={columns[0].columns.filter((col)=>col.show!==false).length} rows={5} />
                    }
                    {!loading && page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr key={`table-row-${i}`} {...row.getRowProps()} onClick={() => {
                                row.allCells.map(cell=>{
                                    if (cell.column?.showModal){
                                        let fields = {};
                                        let title=null;
                                        let footerItems=null;
                                        if(cell.column?.modalFields) {
                                            cell.column.modalFields?.forEach( field => {
                                                fields[field.name] = row.values[field.value];
                                            }
                                            )
                                        }
                                        else fields = row.original;
                                        if(cell.column?.modalTitle) title = cell.column.modalTitle;
                                        if(cell.column?.modalFooterItems?.length > 0) {
                                            footerItems = cell.column.modalFooterItems.map( item => row.values[item]);
                                        }
                                        modalShowHandler(title, fields, footerItems);
                                        return true;
                                    }
                                    if (cell.column?.url){
                                        if (cell.column?.condition){
                                            let filter;
                                            cell.column.condition.forEach(cond => {
                                                if (cond.operator === "=") filter = row.values[cond.field] === cond.value;
                                                else if (cond.operator === "!=") filter = row.values[cond.field] !== cond.value;
                                                else if (cond.operator === ">") filter = row.values[cond.field] > cond.value;
                                                else if (cond.operator === ">=") filter = row.values[cond.field] >= cond.value;
                                                else if (cond.operator === "<") filter = row.values[cond.field] < cond.value;
                                                else if (cond.operator === "<=") filter = row.values[cond.field] <= cond.value;
                                                else if (cond.operator === "in") filter = cond.value.includes(row.values[cond.field]);
                                                else if (cond.operator === "!in") filter = !cond.value.includes(row.values[cond.field]);
                                                else if (cond.operator === "regex") filter = row.values[cond.field].match(cond.value);
                                                else if (cond.operator === "!Wregex") filter = !row.values[cond.field].match(cond.value);
                                            })
                                            if (!filter) return false;
                                        }
                                        const [url,id]=cell.column.url.split(":");
                                        history.push("/p"+url+cell.row.original[id]);
                                        return true;
                                    }
                                    if (cell.column?.onClick) cell.column.onClick(row.original);
                                    return false;
                                });
                            }}>
                                {row.cells.map((cell,i) => {
                                    return (
                                        <td key={`table-cell-${i}`} {...cell.getCellProps({className: cell.column.className})}>
                                            {cell.render('Cell', {id:cell.row.original?.id || false, typeAheadOnRemove: typeAheadOnRemove})}
                                        </td>
                                    )
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </BsTable>
            {pages.length>1 &&
                <Pagination>
                    {/*<Pagination.First className="pagination-first" onClick={() => gotoPage(0)} disabled={!canPreviousPage} />*/}
                    <Pagination.Prev className="pagination-prev" onClick={() => previousPage()} disabled={!canPreviousPage} />
                    {/*<Pagination.Ellipsis />*/}
                    {pages}
                    {/*<Pagination.Ellipsis />*/}
                    <Pagination.Next className="pagination-next" onClick={() => nextPage()} disabled={!canNextPage} />
                    {/*<Pagination.Last className="pagination-last" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />*/}
                    {/*
                    <DropdownButton
                        className="ml-2"
                        as={ButtonGroup}
                        size="sm"
                        variant="light"
                        title={`Showing ${pageSize} item${pageSize===1?"":"s"} per line`}
                    >
                        <Dropdown.Header>Lines to show</Dropdown.Header>
                        {[10, 20, 50, 100].map(pageSize => (
                            <Dropdown.Item eventKey={pageSize} onClick={e => {setPageSize(pageSize)}}>{pageSize}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                    */}
                </Pagination>
            }

            {searchBar && searchBar==="bottom" &&
                <GlobalFilter
                    label={false}
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    newButtonClickHandler={newClick}
                    newButtonLabel={newLabel}
                />
            }

            {selectedRow && 
                <Modal className="table-info-modal" show={modalShow} onHide={modalCloseHandler}>
                    { modalTitle ? <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header> : null
                    }
                    <Modal.Body>
                        {[selectedRow].map((v, i)=>{
                            let content=[];
                            for(let p in v){
                                if (v[p]){
                                    content.push(
                                        <p key={`table-content-${i}`}>
                                            <span className="bold">{p}</span><br/>
                                            <span>{v[p]}</span>
                                        </p>
                                    );
                                }
                            } 
                            return content;
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" type="button" onClick={modalCloseHandler}>Close</Button>
                        { modalFooterItems }
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default Table;
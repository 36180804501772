import React from 'react';

import styles from '../OnlinePickup.module.scss'

export const ItemQuickList = ({items, ...props}) =>{

    return(
        <div className={styles['item-container']}>
            {items?.map((item)=>(
                <ul className={styles["item-wrapper"]}>
                    <li className={`${styles["item-name"]}`}>
                        {item.product_name}
                        {item.variant_id 
                            && (item.parent_id===item.product_id || !item.parent_id)
                            && item.variant_name.trim().toLowerCase()!==item.product_name.trim().toLowerCase()
                            && item.variant_name.trim().toLowerCase()!=="default"
                            && item.variant_name.trim().toLowerCase()!=="" &&
                                <span>
                                    {` (${item?.variant_name})`} 
                                </span>
                        } 
                    </li>
                    {item?.addons?.length > 0  && item?.addons?.map((addon)=>(
                        <>
                            <span>
                                - {addon?.product_name}
                            </span>
                            <br />
                        </>
                    ))}
                </ul>
            ))}
        </div>
    )
}
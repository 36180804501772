import React, { useState, useEffect, createRef, useRef, useCallback, useContext } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import Wallet from '../../../Dashboard/Wallet';
import DisplayQRCode from '../../../../components/common/DisplayQRCode';
import SingleUpload from '../../../../components/Uploader';
import CheckinButton from '../CheckinButton';
import { FileURLContext } from '../../../../contexts/FileURLContext';
import { authUserHasModuleAccessMany } from "../../../../utils/auth";

import Users from '../../../../api/Users';

const WALLET_MODULE_ID = 216;
const SUBSCRIPTION_MODULE_ID = 75;
const GROUPS_MODULE_ID = 106;
const CHECKIN_SCANNER_MODULE_ID = 127; //If they have access to use the checkin scanner, they would have access to do so manually

export const CardInfo = (props) => {
    let history = useHistory();

    const profilePictureRef = createRef();
    const mountedRef = useRef(false);

    const imageURL = useContext(FileURLContext);
    const nopic = imageURL.noPic;

    const [ userInfo, setUserInfo ] = useState();
    const [ groups, setGroups ] = useState([]);
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);

//#region useCallback

    const getUser=useCallback(async()=>{
        try{
            let response = await Users.get({id: props.user_id})
            if(!response.errors && mountedRef.current){
                setUserInfo(response.data[0])
            }
        }catch(ex){console.error(ex)}
    },[props.user_id]);

    const getGroups=useCallback(async()=>{
        try{
            let response = await Users.groups({id:props.user_id})
            if(!response.errors && mountedRef.current){
                let confirmed_groups = response.data.filter(user_group => user_group.group_member_status_id===2);
                setGroups(confirmed_groups);
            }
        }catch(ex){console.error(ex)}
    },[props.user_id]);

//#endregion useCallback

//#region useEffect

    useEffect(()=>{
        mountedRef.current = true

        return()=>{
            mountedRef.current=false
        }
    },[]);

    // check if auth user has permission to view this page for this user
    useEffect(() => {
        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccessMany([
                    WALLET_MODULE_ID, 
                    SUBSCRIPTION_MODULE_ID, 
                    GROUPS_MODULE_ID,
                    CHECKIN_SCANNER_MODULE_ID
                ], props.user_id);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }
        checkPermission();
    }, [props.user_id]);

    useEffect(()=>{
        if(props.user && mountedRef.current) setUserInfo(props.user);
        else if(props && props.user_id && mountedRef.current) getUser();
    },[props, getUser, props.refresh]);

    useEffect(()=>{
        if(userInfo && mountedRef.current) getGroups();
    },[userInfo,getGroups])

//#endregion

    // updates the profile image after upload
    const uploadProfilePicHandler = async (formData) =>{
        if (formData){
            formData.append('user_id',props.user_id);
            formData.append('type',2);
            formData.append('description',"Profile Picture");
            await Users.upload(formData).then(res=>{
                if (!res.errors){
                    /*if (user?.profile?.profile_img_path){
                        user.profile.profile_img_path=data.file_url;
                    }*/
                }
            });
        }
    }

    if (!userInfo) return (
        <div className="text-center">
            <SkeletonTheme color="#e0e0e0">
                <Skeleton circle={true} height={100} width={100} style={{marginBottom:"1rem"}} />
                <Skeleton height={12} count={5} />
                <Skeleton height={12} style={{marginTop:"2rem"}} />
                <Skeleton height={12} count={5} />
            </SkeletonTheme>
        </div>
    );

    //console.log("userHasModulePermission", userHasModulePermission);
    
    return (
        userInfo &&
        <React.Fragment>
        <Card className="user-profile-img-wallet-card mb-3">
            <div style={window.innerWidth <=1200 ? {width: "150px"} : {width: "275px"}}>
                <SingleUpload 
                    DzRef={profilePictureRef}
                    type="image/*, .heic, .heif"
                    previewHeight={window.innerWidth <=1200 ? "175px" : "250px"}
                    previewPadding="1rem"
                    backgroundSize="contain"
                    previewSrc={userInfo.profile_img_path || nopic}
                    onSend={uploadProfilePicHandler}
                >
                </SingleUpload>
                {/*<Image src={ userInfo.profile_img_path || nopic} roundedCircle className="profile-picture" />*/}
            </div>
            <div>
                <h4 className="text-center">
                    {userInfo.first_name} {userInfo.last_name}
                </h4>
                {userHasModulePermission && userHasModulePermission[CHECKIN_SCANNER_MODULE_ID] &&
                    <CheckinButton userInfo={userInfo} />
                }
            </div>
            <div>
                <Row>
                    {/* This is currently always 0, regardless of outstanding charges.  Can comment back in when it's used
                    <Col className="text-center">
                    <span className="bold">${userInfo?.wallet?.due_amount || 0.00}</span><br/>
                    Balance Due
                </Col>*/}
                    {props.upcoming_events &&                             
                        <Col>
                            <span className="bold">{props?.upcoming_events || "0"}</span><br/>
                            Upcoming Events
                        </Col>
                    }
                </Row>
            </div>
        </Card>
        <Card className="profile-quick-glance-info">
            <div className="qr-card">
                <Card.Subtitle>QR Code</Card.Subtitle>
                <DisplayQRCode user_id={userInfo.id} />   
            </div>
            {userHasModulePermission && userHasModulePermission[WALLET_MODULE_ID] &&
                <div>
                    {window.innerWidth <=1200 ? null : <hr/>}
                    <Card.Subtitle className="my-3">WALLET</Card.Subtitle>
                    <Wallet user={userInfo} compact={true} />
                </div>
            }

            {userHasModulePermission && userHasModulePermission[SUBSCRIPTION_MODULE_ID] &&
                <div>
                    {window.innerWidth <=1200 ? null : <hr/>}

                    {userInfo.subscriptions &&
                        <>
                        {/* subscription statuses - active, suspended, cancelled, expired */}
                            <Card.Subtitle className="my-3">SUBSCRIPTIONS</Card.Subtitle>
                            {userInfo.subscriptions.map((subscription)=>(
                                <Card.Text className="profile-each-sub-wrapper" key={`each-sub-profile-${subscription.id}`}>
                                    <span className={
                                            (subscription.subscription_status==="Active"&& "sub-active") 
                                            || (subscription.subscription_status==="Expired" && "sub-expired")
                                            || (subscription.subscription_status==="Cancelled" && "sub-cancelled")
                                            || (subscription.subscription_status==="Suspended" && "sub-suspended")
                                        }>
                                        {subscription.subscription_status==="Active" ?
                                            <i className="far fa-lightbulb" style={{paddingLeft:"5px", paddingRight:"4px"}}/>
                                            :
                                            <i className="far fa-lightbulb-slash" />
                                        }
                                        {" "}{subscription.subscription_status}
                                    </span>
                                    {" "}{subscription.product_name}
                                    {subscription?.subscription_type_name ?
                                        <span>
                                            {" "} -- {subscription.subscription_type_name}
                                        </span>
                                    :
                                        <></>
                                    }
                                </Card.Text>
                            ))}
                        </>
                    }
                </div>
            }
            <div>
                {window.innerWidth <=1200 ? null : <hr/>}
                <Card.Subtitle className="my-3">CONTACT INFO</Card.Subtitle>
                {userInfo.email &&
                    <Card.Text>
                        <i className="far fa-envelope text-secondary mr-2"></i> {userInfo.email}
                    </Card.Text>
                }
                {userInfo.home_phone &&
                    <Card.Text>
                        <i className="far fa-phone text-secondary mr-2"></i> {userInfo.home_phone}
                    </Card.Text>
                }
                {userInfo.mobile_phone &&
                    <Card.Text>
                        <i className="far fa-mobile text-secondary mr-2"></i> {userInfo.mobile_phone}
                    </Card.Text>
                }
            </div>

            {userHasModulePermission && userHasModulePermission[GROUPS_MODULE_ID] &&
                <div>
                {window.innerWidth > 1200 && <hr/>}
                    <Card.Subtitle className="my-3">GROUPS</Card.Subtitle>
                    <Card.Text>
                        {groups?.length ? groups.map((group,i) => 

                        <span className="text-link" key={`grp-${i}`}>
                            {props.currentUserRoleId < 6 ?
                                <>
                                <i className="far fa-users" /> {" "}
                                <a  href="#!"
                                    onClick={()=>history.push({
                                        pathname:`/p/groups/${group.group_id}`,
                                        //setting history states to make accurate breadcrumbs from whence a user came
                                        state: {
                                        fromPath: history.location,
                                        fromName: "Profile",
                                        fromUser: `${props?.user?.first_name} ${props?.user?.last_name}`
                                        }
                                    })} 
                                >
                                    {group.name}
                                </a>
                                </>
                            :
                                <>
                                <i className="far fa-users" /> {" "}
                                <a href="#!"
                                    onClick={()=>history.push({
                                        pathname:`/p/my/groups/${group.group_id}`,
                                        //setting history states to make accurate breadcrumbs from whence a user came
                                        state: {
                                        fromPath: history.location,
                                        fromName: "Profile",
                                        fromUser: `${props?.user?.first_name} ${props?.user?.last_name}`
                                        }
                                    })}
                                >
                                    {group.name}
                                </a>
                                </>
                            }
                            <br />
                        </span>
                    ) : "None"}
                    </Card.Text>
                </div>
            }
            {userInfo.social &&
            <div>
                <React.Fragment>
                    <hr/>
                    <Card.Subtitle className="my-3">SOCIAL PROFILES</Card.Subtitle>
                    <Card.Text>
                        {userInfo.social.map(social => {
                            return (
                                <Button key={`soc-${social.id}`} variant="light" size="sm" href={social.handle}>
                                    <i className={`fab fa-${social.name} color-${social.name}`}></i>
                                </Button>
                            );
                        })}
                    </Card.Text>
                </React.Fragment>
            </div>
            }
        </Card>
        </React.Fragment>
    );
}
import React,{useState, useEffect} from 'react';
import {Navbar} from 'react-bootstrap';

import Menu from '../Menu';

const Header = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [logoStyles, setLogoStyles] = useState({});

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 200) {
                document.getElementById("navbar").classList.add(`scrolled_${props.page_id}`);
            } else{
                document.getElementById("navbar").classList.remove(`scrolled_${props.page_id}`);
            }
        }

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        return () => window.removeEventListener('scroll', onScroll);
    }, [props.page_id]);

    useEffect(() => {
        let width="3.5rem";
        let height="3.5rem";
        if (props.logo_width) width = props.logo_width;
        if (props.logo_height) height = props.logo_height;
        setLogoStyles({backgroundImage:`url(${props.logo || props.company_context.logoHeader})`,width: width, height: height});
    }, [props.logo, props.logo_width, props.logo_height, props.company_context.logoHeader]);

    useEffect(() => {
        return () => {
            setLogoStyles({});
        };
    }, []);

    return (
        <Navbar ref={ref} id="navbar" collapseOnSelect expand="lg" style={props?.style || null} sticky="top" className={`header_${props.page_id} navbar_${props.page_id} sticky-top no-select-text ${props.className || ""}`.trim().replace(/\s+/g,' ')} onClick={preview_click}>
            <Navbar.Brand href={props.logo_link || "/" } className={`navbar-brand_${props.page_id}`} style={logoStyles} />
            <Menu {...props} />
            {props.children}
        </Navbar>
    );
});

export default Header;
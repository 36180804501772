import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { format } from 'date-fns';

import OrderPage from '../../OrderPage'
import TableWithDropDown from '../../../../components/common/TableWithDropdown';
import { ChangeStatus } from './ChangeStatus';
import { ExpansionButtons } from './ExpansionButtons';
import { ItemQuickList } from './ItemQuickList';
import { setErrorCatcher } from '../../../../utils/validation';
import { authUserHasModuleAccess } from '../../../../utils/auth';
import { getPickupOrders } from '../OnlinePickupUtils';
import Pos from '../../../../api/Pos';

const ORDER_PAGE_MODULE_ID = 305

export const PickupOrderList =({
    data=null, 
    large=false, 
    handleSuccess=()=>{}, 
    loading=false, 
    sortOnClick=null, 
    modal=false,
    ...props
}) => {

    const [ activeItem, setActiveItem ] = useState(null);
    const [ showFullOrder, setShowFullOrder ] = useState(false);
    const [ showItemDetails, setShowItemDetails ] = useState(false);
    const [ authUserHasOrderAccess, setAuthUserHasOrderAccess ] = useState(false);
    const [ error, setError ]=useState(null);
    const [ orderItems, setOrderItems ] =useState(null);
    const [ activeStatus, setActiveStatus]=useState(null);
    const [ refresh, setRefresh ]=useState(false)

    const getSingleOrder = useCallback(async()=>{
        let order = await getPickupOrders({id: activeItem.id});
        if(order.data){
            setActiveStatus(order.data.order_status_id)
            setActiveItem(order.data)
        }
    },[activeItem])

    useEffect(()=>{
        const checkAuth = async()=>{
            try{
                let response = await authUserHasModuleAccess(ORDER_PAGE_MODULE_ID)
                setAuthUserHasOrderAccess(response)
            }catch(ex){
                console.error(ex)
            }
        }
    
        checkAuth();
        
    },[]);

    useEffect(()=>{

        if(data && activeItem && refresh) {
            let updatedItem = data.filter((each)=>each.id === activeItem.id);
            setActiveItem(updatedItem[0]);
            setRefresh(false);
        }
    // We do NOT want it updating when active item updates, it will cause a loop.
    // Basically, we're looking for the updated version of the same thing to force a rerender when we DO need it
    // eslint-disable-next-line 
    },[refresh, data])

    const getSingleOrderItems=async()=>{
        setError(null);
        try{
            let response = await Pos.order.getItems({id:activeItem.id});
            if(response) setOrderItems(response.data)
            else setErrorCatcher("There was a problem getting the order items.")
    }catch(ex){
            console.error(ex)
            setErrorCatcher("There was a problem getting the order items.")
        }
    }

    const handleRowClick=(item)=>{
        setShowFullOrder(false);
        setShowItemDetails(false);
        setOrderItems(null)
        if(activeItem && activeItem.id === item.id){
            setActiveItem(null);
            setActiveStatus(null);
        }
        else {
            setActiveItem(item)
            setActiveStatus(item.order_status_id)
        }
    }

    const handleShowOrder=()=>{
        setShowFullOrder(!showFullOrder);
        if(showItemDetails) setShowItemDetails(false);
    }

    const handleShowItems=()=>{
        setShowItemDetails(!showItemDetails);
        getSingleOrderItems();
        if(showFullOrder) setShowFullOrder(false)
    };

    const expandedRow =(
        <div>
            <h4 className="section-title">#{activeItem?.id}</h4>
            {activeItem &&
                <p>
                    <strong>For:</strong> {activeItem?.user_fname} {activeItem.user_lname} {" "}
                    {activeItem?.user?.mobile_phone && <span>(Phone: {activeItem?.user?.mobile_phone})</span>}
                    <br />
                    <strong>Started:</strong> {format(new Date(activeItem?.created_at), "h:mm a" )}
                    <br />
                    <strong>Last Updated:</strong> {format(new Date(activeItem?.updated_at), "h:mm a")}
                </p>
            }
            <ChangeStatus 
                item={activeItem} 
                status={8} 
                statusText="Not Started" 
                active={activeStatus === 8} 
                setActiveStatus={setActiveStatus} 
                handleError={setError} 
                large={large} 
                handleSuccess={handleSuccess} 
            />
            <ChangeStatus 
                item={activeItem} 
                status={9} 
                statusText="In Process" 
                active={activeStatus === 9} 
                setActiveStatus={setActiveStatus} 
                handleError={setError} 
                large={large} 
                handleSuccess={handleSuccess}
            />
            <ChangeStatus 
                item={activeItem} 
                status={10} 
                statusText="Ready for Pickup" 
                active={activeStatus === 10} 
                setActiveStatus={setActiveStatus} 
                handleError={setError} 
                large={large} 
                handleSuccess={handleSuccess}
            />
            <ChangeStatus 
                item={activeItem} 
                status={11} 
                statusText="Picked Up" 
                active={activeStatus === 11} 
                setActiveStatus={setActiveStatus} 
                handleError={setError} 
                large={large} 
                handleSuccess={handleSuccess}
            />

            <br />

            <ExpansionButtons
                auth={authUserHasOrderAccess}
                largeButtons={large}
                showItemDetails={showItemDetails}
                setShowItemDetails={setShowItemDetails}
                showFullOrder={showFullOrder}
                setShowFullOrder={setShowFullOrder}
                handleShowItems={handleShowItems}
                handleShowOrder={handleShowOrder}
                getData={getSingleOrder}
                setRefresh={setRefresh}
            />
            {authUserHasOrderAccess && showFullOrder && activeItem &&
                <div>
                    <OrderPage importedId={activeItem?.id} />
                </div>
            }
            {showItemDetails &&
                <ItemQuickList 
                    items={orderItems}
                />
            }
        </div>
    )
    

    return(
        <div>
            {error}
            {activeItem || refresh ?
                <>
                {modal ? 
                    <>
                        <Modal show={activeItem ? true : false} size="lg" onHide={()=>setActiveItem(null)}>
                            <Modal.Body>
                                {expandedRow}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={()=>setActiveItem(null)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                :
                    <>
                        {expandedRow}
                    </>
                }
                </>
            :
                <>
                    {modal ? 
                        <>
                        </>
                            :
                        <p>
                            Select an order to edit.
                        </p>
                    }
                </>    
            }
            <TableWithDropDown 
                loading={loading}
                skeletonRows={5}
                title={""}
                tableHeadings={[
                    {key: "order_status_name", label: "Current Status", sortHeader: sortOnClick ? true:false },
                    {key: "id", label: "Order Id", sortHeader: sortOnClick ? true:false  },
                    {key: "updated_at", label: "Ordered At", sortHeader: sortOnClick ? true:false },
                    {key: "multiple-fullName", label: "Customer", multiple: ["user_fname", "user_lname"], sortHeader: sortOnClick ? true:false }

                ]}
                dateFormat={"MM/dd/yy h:mm a"}
                data={data}
                rowOnClick={handleRowClick}
            />
        </div>
    )
}
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Container, Row, Col, Button} from 'react-bootstrap';

import APIPOS from '../../../../api/Pos';

import Print from './Print';

const debounce = (fn, delay) => {
    let timeoutId = null;
    return function(...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
}

export const Success = (props) => {
    const registerInfo = useSelector(state => state.pos?.[props?.register_id]?.registerInfo);

    const [order, setOrder] = useState(null);

    const [showPrint, setShowPrint] = useState("receipt");
    const [printKitchenReceipt, setPrintKitchenReceipt] = useState(null);

    const printHandler = (transaction) => {
        setShowPrint(transaction);
    }

    // get the updated order from the server, we need a debounce here in order to add a delay + prevent multiple calls + yeah, it's a good practice
    useEffect(()=>{
        const _getOrder = debounce(async () => {
            const response = await APIPOS.order.get({id: props.order.id});
            if (!response.errors && response.data) setOrder(response.data);
        }, 1000);
        _getOrder();
    },[props.order]);

    useEffect(()=>{
        if (registerInfo?.register_definition?.print_kitchen_ticket) setPrintKitchenReceipt("kitchen");
    },[registerInfo]);

    if (!props?.order?.order_status_id === 2) return null;

    return (
        <Container fluid>
            <Row>
                <Col sm={12} lg={8}>
                    <h4 className="card-title" data-cy="success-title">Payment Successful!</h4>
                    <span className="bold" data-cy="success-order-number">Order #{props.order?.id}</span><br/>
                    <div className={"my-4"}>
                        Thank you!
                    </div>
                    {/*props.order?.transactions?.length === 1 &&
                        <Button variant="light" onClick={()=>printHandler(props.order.transactions[0])}>Print Payment</Button>
                    */}
                    <Button variant="light" onClick={()=>printHandler("receipt")}>Print Receipt</Button>
                    <Button variant="light" onClick={()=>printHandler("invoice")}>Print Invoice</Button>
                </Col>
                <Col sm={12} lg={4} data-cy="order-success-all-transactions">
                    {props.order?.transactions?.length>0 && props.order?.transactions?.map((item,i)=>(
                        <React.Fragment key={`transaction-receipt-${i}`}>
                            <p>
                                <span className="bold" data-cy="success-transaction-details">
                                    {item.transaction_payment_method_name}&nbsp;
                                    {item.cc_number && item.cc_type && `${item.cc_type} ending in ${item.cc_number}`}
                                    {item.transaction_response?.check_number && `#${item.transaction_response.check_number}`}
                                    {item.transaction_response?.check_name && ` / ${item.transaction_response.check_name}`}
                                    {item.transaction_response?.card_code && ` / ${item.transaction_response.card_code}`}
                                </span><br/>
                                <span className="bold" data-cy="success-transaction-number">Transaction #{item.id}</span><br/>
                                {item.nmi_transaction_id && 
                                    <>
                                        <span className="bold">Gateway Transaction ID:</span> {item.cc_trans_id}<br/>
                                    </>
                                }
                                <span className="bold">Amount:</span> <span data-cy="success-amount">${item.amount.toFixed(2)}</span><br/>
                                {item.transaction_response?.change > 0 && 
                                    <>
                                        <span className="bold">Cash Tendered:</span> <span data-cy="success-cash">${item.transaction_response.amount.toFixed(2)}</span><br/>
                                        <span className="bold">Change:</span><span data-cy="success-change"> ${item.transaction_response.change.toFixed(2)}</span>
                                        <br/>
                                    </>
                                }
                                <span className="bold" data-cy="success-transaction-status">{item.transaction_status_name}</span>
                            </p>
                            <Button variant="light" onClick={()=>printHandler(item)}>Print</Button>
                            <hr/>
                        </React.Fragment>
                    ))}
                </Col>
            </Row>
            {order && <Print showPrint={showPrint} setShowPrint={setShowPrint} order={order} printKitchenReceipt={printKitchenReceipt} />}
        </Container>
    );
}
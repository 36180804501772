import React, {useState, useCallback, useEffect} from 'react';
import {ProgressBar, OverlayTrigger, Tooltip} from 'react-bootstrap';
import Dropzone from 'react-dropzone';

import {useUploadProgress} from '../../contexts/UploadProgressContext';
import EasyCrop from '../EasyCrop/EasyCrop';

import styles from './SingleUpload.module.scss';
export const Uploader = (props) =>{
	const {onSend} = props;
	const {uploadProgress, updateUploadProgress} = useUploadProgress();

	const [progress, setProgress] = useState(0);
	const [errors, setErrors] = useState("");
	const [preview, setPreview] = useState(props.previewSrc || null);
	const [imgForCrop, setImgForCrop]=useState(null);
	const [easyCropHideShow, setEasyCropHideShow]=useState(false);
	const [croppedImg, setCroppedImg]=useState(null);
	const [name, setName]=useState("");

	const sendHandler = useCallback(imgData=>{
		onSend(imgData);
	},[onSend]);

	// file added, lets validate, upload, and do nasty things
	const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
		setProgress(0);
		updateUploadProgress(0);
		
		// create form data for api submission
		let crop_enabled = false;
		acceptedFiles.forEach(file => {
			setErrors("");
			const dataobj = URL.createObjectURL(file);
			if (!props.disablePreview) setPreview(dataobj);
			setName(file.name);

			if (!props.disableCrop){
				setImgForCrop(dataobj);
				setEasyCropHideShow(true);
				crop_enabled = true;
			}
		});

		// Throw a pretty error at the user
		rejectedFiles.forEach((file) => {
			file.errors.forEach((err) => {
				if (err.code === "file-too-large") {
					setErrors("File is too large!");
					return false;
				}

				if (err.code === "file-invalid-type") {
					setErrors("Invalid file type");
					return false;
				}
			});
			return false;
		});

		if (!crop_enabled){
			// send each file individually (because most stuff is set up for 1 file at a time)
			acceptedFiles.forEach(file=>{
				const data = new FormData();
				data.append('file', file, file.name);
				sendHandler(data);
			});
		}
	}, [sendHandler, updateUploadProgress, props.disableCrop, props.disablePreview]);

	const onCancel =()=>{
		setErrors("")
		setEasyCropHideShow(false);
		if (!props.disablePreview) setPreview(props.previewSrc);
	}

	const setImg =(cropped, blob)=>{
		if (!props.disablePreview) setPreview(cropped);
		setCroppedImg(blob);
		setEasyCropHideShow(false);
	}

	useEffect(()=>{
		if (croppedImg){
			let cropped = new FormData();
			cropped.append('file', croppedImg, name);
			if (croppedImg.size > 2000000){
				setErrors("Your file was too large!");
				setPreview(null);
			} else {
				sendHandler(cropped);
				setCroppedImg(null);
			}
		}
	}, [croppedImg, name, sendHandler]);

	useEffect(()=>{
		if (props.previewSrc && !props.disablePreview) setPreview(props.previewSrc);
	}, [props.previewSrc, props.disablePreview]);

	useEffect(() => {
		setProgress(uploadProgress);
	}, [uploadProgress]);

	return (
		<>
		{errors ?
			<>
				<span>
					<i className="far fa-exclamation-triangle"></i>
					{errors}
				</span>
				<br/>
			</>
		:null}
		<Dropzone 
			ref={props.DzRef}
			accept={props.type}
			multiple={props.multiple || false}
			autoProcessQueue
			maxSize={100485760}
			onDrop={onDrop}
		>
			{({getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept }) => (
				<div style={{
						minWidth: props.minWidth || "100%",
						maxWidth: props.maxWidth || "unset",
						minHeight: props.minHeight || "unset",
						maxHeight: props.maxHeight || "unset",
						height: props.previewHeight || "unset",
						padding: props.previewPadding || 0,
						backgroundSize: props.backgroundSize || "cover",
						backgroundImage: `url(${preview})`,
					}} 
					{...getRootProps({className:`${styles["transparent-dropzone"]}${isDragActive ? styles["dnd-active"]:""}${isDragAccept ? styles["dnd-accept"]:""}${isDragReject ? styles["dnd-reject"]:""}`})}
				>
					{progress > 0 && <ProgressBar now={progress} />}
					<input {...getInputProps()} />
					{errors ?
						<OverlayTrigger
							show={true}
							placement="bottom"
							overlay={
								<Tooltip className="tooltip-error">
									{errors}
								</Tooltip>
							}>
							<React.Fragment>{props.children}</React.Fragment>
						</OverlayTrigger>
					: props.children }
				</div>
			)}
		</Dropzone>

		{easyCropHideShow ? 
			<EasyCrop 
				img={imgForCrop} 
				setImg={setImg}
				cancel={onCancel} 
				hideShow={()=>setEasyCropHideShow(false)}
				canvasHeight={props?.croppedHeight || null}
				canvasWidth={props?.croppedWidth || null}
			/> 
		: null }
		</>
	);
};
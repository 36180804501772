import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Container, Row, Col, Modal} from 'react-bootstrap';

import {Summary, Buttons, Step1, Register, ByAmount, ByItem, ByTransaction, Memo, AdminFee, Items, Success} from '.';
import ErrorCatcher from '../../../../components/common/ErrorCatcher';

import APIPOS from '../../../../api/Pos';
import styles from './Wizard.module.scss';

const maxSteps = 9;
export const Wizard = (props) => {
    const {onHide, redrawOrder} = props;

    const buttonsRef= useRef();
    
    const [stepData, setStepData] = useState({});
    const [currentStep, setCurrentStep] = useState({step: 1, next: null, back: null});
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState();

    const processRefund = useCallback(async ()=>{
        let _errors = [];
        if (!stepData.type) _errors.push("Please select refund type.");
        if (stepData.type === "amount" && !stepData?.amount) _errors.push("Amount is required.");
        /*if (stepData.type === "item" && !stepData?.all_items) _errors.push("Please select refund type.");
        else if (stepData.all_items === 2 && (!stepData?.items || !stepData?.items.length)) _errors.push("Please select items to refund.");*/
        if (stepData.type === "item" && !stepData?.items.length) _errors.push("Please select items to refund.");
        if (stepData.type === "transaction" && (!stepData?.transactions || !stepData?.transactions.length)) _errors.push("Please select payments to refund.");
        if (+stepData.admin_fee > 0 && !stepData.agreement) _errors.push("You must agree to the terms to issue the cash discount.");

        if (_errors.length > 0) {
            setErrors(_errors);
            return;
        }

        let refund_amount = 0, items = null, transactions = null;
        if (stepData.type === "amount") refund_amount = +stepData.amount;
        else if (stepData.type === "item") {
            /*if (stepData.all_items === 1) { // refunding all items, grab them from the order
                refund_amount = +props.maxTotal;
                items = props.order.items.map(item => ({
                    id: item.id,
                    product_id: item.product_id,
                    variant_id: item.variant_id,
                    product_name: item.product_name,
                    refund_amount: +item.price + +item.tax + +item.shipping,
                    refund_tax: +item.tax || 0,
                    refund_shipping: +item.shipping || 0,
                    refund_price: +item.price || 0,
                    refund_memo: "",
                    refund_type: "full amount"
                }));
            } else if (stepData.all_items === 2) {*/
                refund_amount = stepData.items.reduce((acc, item) => +acc + +item.refund_amount, 0);
                items = stepData.items;
            //}
        } else if (stepData.type === "transaction") {
            refund_amount = stepData.transactions.reduce((acc, item) => +acc + +item.refund_amount, 0);
            transactions = stepData.transactions;
        }

        try{
            const res = await APIPOS.orderRefund({
                order_id: props.order.id, 
                refund_amount, 
                refund_type: stepData.type,
                items: items || undefined, 
                transactions: transactions || undefined,
                admin_fee: stepData.admin_fee || undefined, 
                register_id: +stepData.register_id || +props.registerId || +props.order.register_id || null,
                memo: stepData?.memo || undefined
            });

            //console.log(res)
            
            if (res.errors) setErrors(res.errors);
            else if (res.data) {
                setSuccess(res.data);
                setErrors(null);
            }
        } catch(e){
            setErrors([e]);
        }
    },[stepData, /*props.maxTotal,*/ props.order, props.registerId]);

    const allDoneHandler = useCallback((e)=>{
        e.preventDefault();
        setCurrentStep({step: 1, next: null, back: null});
        setStepData({});
        redrawOrder();
        onHide(success);
    }, [onHide, redrawOrder, success]);

    /* determines which steps are next and previous depending on the current step, and which elements need to be reset according to the data*/
    const getNextPrevSteps = useCallback((step, data) => {
        let next = null, back = null, resets = null;
        switch(step){
            case 1: // Step1
                if (data?.type){
                    if (data.type === "amount") next = 2;
                    else if (data.type === "item") next = 5;
                    else if (data.type === "transaction") next = 4;
                }
                break;
            case 2: // ByAmount
                back = 1;
                if (data?.type === "amount" && data?.amount) {
                    next = props.registerId ? 8 : 7;
                    resets = {/*all_items: null,*/ items: null, admin_fee: null, transactions: null};
                } else if (data?.type !== "amount") {
                    resets = {amount: null};
                }
                break;                
            /*case 3: // ByItem
                back = 1;
                resets = {amount: null, transactions: null};
                if (data?.type === "item" && data?.all_items) next = data.all_items === 2 ? 5 : 6;
                else if (data?.type === "item" && data?.all_items === 1) resets = {...resets, items: null};
                else if (data?.type !== "item") resets = {...resets, all_items: null, items: null};
                break;
            */
            case 4: // ByTransaction
                back = 1;
                resets = {amount: null, /*all_items: null,*/ items: null, admin_fee: null};
                if (data?.type === "transaction" && data?.transactions?.length > 0) next = props.registerId ? 8 : 7;
                else if (data?.type !== "transaction") resets = {...resets, transactions: null};
                break;
            case 5: // Items
                back = 1;
                resets = {amount: null};
                if (data?.type ==="item" && /*data?.all_items===2 &&*/ data?.items?.length > 0) {
                    if (props.order.admin_fee_total > 0) next = 6;
                    else next = props.registerId ? 8 : 7;
                }
                break;
            case 6: // AdminFee
                resets = {amount: null};
                back = data?.items?.length > 0 ? 5 : 1;
                if (data?.admin_fee > 0 && data?.agreement) next = props.registerId ? 8 : 7;
                break;
            case 7: // Register
                back = data.type === "amount" || data.type==="transaction" ? 2 : props.order.admin_fee_total > 0 ? 6 : 5;
                next = 8;
                break;
            case 8: // Memo
                back = !props.registerId ? 7 : (data.type === "amount" ? 2 : (data.type === "item" ? (props.order.admin_fee_total > 0 ? 6 : 5) : 4 ));
                next = 9;
                break;
            case 9: // Summary
                //back = !props.registerId ? 7 : (data.type === "amount" ? 2 : 6);
                back = 8;
                break;
            default:
                break;
        }
        
        return {next, back, resets};
    }, [props.registerId, props.order.admin_fee_total]);

    const stepChangeHandler = useCallback((step, type = "next") => {
        if (step < 1 || !step) step = 1;

        if (type === "submit") processRefund();
        else if (step <= maxSteps){
            const _nextprev = getNextPrevSteps(step, stepData); // get next and prev steps
            setCurrentStep({step, next: _nextprev.next, back: _nextprev.back});
        }
    }, [getNextPrevSteps, processRefund, stepData]);

    const stepUpdateHandler = useCallback((step, data) => {
        setStepData(prev=>{
            prev = {...prev, ...data};
            const _nextprev = getNextPrevSteps(step, prev); // validate and get next and prev steps
            if (_nextprev.resets) prev = {...prev, ..._nextprev.resets}; // reset values that are not supposed to be there (for example, items when the refund type changed to by amount)
            setCurrentStep({step, next: _nextprev.next, back: _nextprev.back});
            return prev;
        });
    }, [getNextPrevSteps]);


    useEffect(()=>{
        return ()=>{
            setStepData({});
            setCurrentStep({step: 1, next: null, back: null});
            setErrors(null);
            setSuccess(null);
        }
    }, []);

    return (
        <Modal show onHide={()=>onHide(success)} size="xl" className={styles.modal} backdropClassName={styles.backdrop} backdrop="static">
            <Modal.Header closeButton/>
            <Modal.Body>
                <Container fluid>
                    <Row className="body d-flex">
                        <Col sm={12} lg={3} className="summary-bar">
                            <Summary {...props} stepData={stepData} stepUpdate={stepUpdateHandler}/>
                        </Col>
                        <Col>
                            <div className={styles.content}>
                                {success ? <Success /> :
                                    <>
                                        {currentStep.step === 1 && <Step1 {...props} stepId={1} stepUpdate={stepUpdateHandler} stepData={stepData} />}
                                        {currentStep.step === 2 && <ByAmount {...props} stepId={2} stepUpdate={stepUpdateHandler} stepData={stepData} />}
                                        {currentStep.step === 3 && <ByItem {...props} stepId={3} stepUpdate={stepUpdateHandler} stepData={stepData} />}
                                        {currentStep.step === 4 && <ByTransaction {...props} stepId={4} stepUpdate={stepUpdateHandler} stepData={stepData} />}
                                        {currentStep.step === 5 && <Items {...props} stepId={5} stepUpdate={stepUpdateHandler} stepData={stepData} />}
                                        {currentStep.step === 6 && <AdminFee {...props} stepId={6} stepUpdate={stepUpdateHandler} stepData={stepData} />}
                                        {currentStep.step === 7 && <Register {...props} stepId={7} stepUpdate={stepUpdateHandler} stepData={stepData} />}
                                        {currentStep.step === 8 && <Memo {...props} stepId={8} stepUpdate={stepUpdateHandler} stepData={stepData} />}
                                        {currentStep.step === 9 && <Summary {...props} stepId={9} stepUpdate={stepUpdateHandler} stepData={stepData} full />}
                                    </>
                                }
                            </div>
                            {errors && <ErrorCatcher show error={errors} onClose={()=>setErrors(null)}/>}
                            {!props.registerId &&
                                <Buttons ref={buttonsRef} step={currentStep} maxSteps={maxSteps} onStepChange={stepChangeHandler} onHide={()=>onHide(success)} success={Boolean(success) ? allDoneHandler : null}/>
                            }
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            {props.registerId && // style it for the pos
                <Modal.Footer className="p-0">
                    <Buttons ref={buttonsRef} step={currentStep} maxSteps={maxSteps} onStepChange={stepChangeHandler} onHide={()=>onHide(success)} registerId={props.registerId} success={Boolean(success) ? allDoneHandler : null}/>
                </Modal.Footer>
            }
        </Modal>
    );    
}
import React from 'react';
import Tabs from './Tabs';

export const UpcomingSchedule = props => {

    return (
        <React.Fragment>
            <div className="">
                <Tabs includes={[
                    { id: 1, hash: "personal", text: "Personal", component: "Personal", user_id: props.user_id },
                    /*{id:2,hash:"cityblocks",text:"City Blocks",component:"CityBlocks"},
                    {id:3,hash:"family",text:"Family",component:"Family"},*/
                ]} />
            </div>
        </React.Fragment>
    );
}
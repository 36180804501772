import * as actionTypes from '../actions/actionTypes';

const initialState={
    all_family_no_dupes: [],
    all_family_members: [],
    all_family_groups: [],
}

const familyReducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.SET_ALL_FAMILY_NO_DUPES:
            return{
                ...state,
                all_family_no_dupes:action.all_family_no_dupes
            }
        case actionTypes.SET_ALL_FAMILY_MEMBERS:
            return{
                ...state,
                all_family_members: action.all_family_members
            }
        case actionTypes.SET_ALL_FAMILY_GROUPS:
            return{
                ...state,
                all_family_groups: action.all_family_groups
            }
        case actionTypes.RESET_FAMILY:
            return{
                ...initialState
            }
        default:
            return state;
    }
};

export default familyReducer;
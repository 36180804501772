import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Row, Col, Form } from 'react-bootstrap';

import States from '../../../../../api/States';

export const CartAddressView = (props) => {

    const { user, setUserDetails: propSetUserDetails, setValidation: propSetValidation} = props;

    const mountedRef = useRef(false);
    const [validation, setValidation]=useState("");
    const [firstName, setFirstName]=useState(props.user.first_name ||"");
    const [middleName, setMiddleName]=useState(props.user.middle_name||"");
    const [lastName, setLastName]=useState(props.user.last_name||"");
    const [address1, setAddress1]=useState(props.user.address1||"");
    const [address2, setAddress2]=useState(props.user.address2||"");
    const [city, setCity]=useState(props.user.city||"");
    const [state,setState]=useState(props.user.state||"");
    const [postalCode, setPostalCode]=useState(props.user.postal_code||"");
    const [states, setStates]=useState();
    const [userDetails, setUserDetails]=useState({}) //package up the details for print receipt
    const [haveDetails, setHaveDetails]=useState(null)

    //Handles validation here in this component or is negating if parent handles props the validation task off to parent component
    const checkValidation=useCallback(()=>{
        if(firstName !=="" && lastName !=="" && address1 !=="" && city !=="" && state !=="" && postalCode!==""){
        setHaveDetails(true);
        if(!propSetValidation) setValidation(null); //if there are other props sent in for validation, it's handled there, otherwise this component handles it
        }else{
        setHaveDetails(false);
        if(!propSetValidation) setValidation("Please input all required fields.")
        } 
    },[firstName, lastName, address1, city, state, postalCode, propSetValidation]);

    //First load, gets the states for the DDs
    useEffect(()=>{
        mountedRef.current = true;

        getStates();

        return()=> mountedRef.current=false
    },[]);

    //If any field changes, take it and assemble it in a way to pass up
    useEffect(()=>{
        const createUserDetails=()=>{
            const details={
                first_name: firstName,
                middle_name:middleName,
                last_name: lastName,
                address1: address1,
                address2: address2,
                city: city,
                state: state,
                postal_code: postalCode,
            };
            return details;
        }

        const temp=createUserDetails();
        if (mountedRef.current) setUserDetails(temp); //formatting for the print receipt
        if (user) checkValidation();
    },[firstName, middleName, lastName, address1, address2, city, state, postalCode,checkValidation,user]);

    //When userDetails changes, pass it to parent component
    useEffect(()=>{
        if(mountedRef.current) propSetUserDetails(userDetails);
    },[userDetails,propSetUserDetails]);

    const getStates = ()=>{
        let response = States.get();
        if(mountedRef.current) setStates(response);
    }

    return (
        <Row>
            <Col sm={12} lg={props.forceMobile?12:6}>
                <Form.Group controlId="first-name">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        data-cy="cart-first-name"
                        type="text"
                        value={firstName}
                        onChange={e=>setFirstName(e.target.value)}
                        placeholder="First Name"
                        required
                    />
                </Form.Group>
            </Col>
            <Col sm={12} lg={props.forceMobile?12:6}>
                <Form.Group controlId="last-name">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        data-cy="cart-last-name"
                        type="text"
                        value={lastName}
                        onChange={e=>setLastName(e.target.value)}
                        placeholder="Last Name"
                        required
                    />
                </Form.Group>
            </Col>
            <Col sm={12} lg={props.forceMobile?12:6}>
                <Form.Group controlId="address1">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        data-cy="cart-address-1"
                        type="text"
                        value={address1}
                        onChange={e=>setAddress1(e.target.value)}
                        placeholder="Address"
                        required
                    />
                </Form.Group>
            </Col>
            <Col sm={12} lg={props.forceMobile?12:6}>
                <Form.Group controlId="address2">
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                        data-cy="cart-address-2"
                        type="text"
                        value={address2}
                        onChange={e=>setAddress2(e.target.value)}
                        placeholder="Address 2"
                    />
                </Form.Group>
            </Col>
            <Col sm={12} lg={props.forceMobile?12:6}>
                <Form.Group controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        data-cy="cart-city"
                        type="text"
                        value={city}
                        onChange={e=>setCity(e.target.value)}
                        placeholder="City"
                        required
                    />
                </Form.Group>
            </Col>
            <Col sm={12} lg={props.forceMobile?12:6} xl={props.forceMobile?6:3}>
                <Form.Group controlId="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                        data-cy="cart-state"
                        as="select"
                        custom
                        value={state}
                        onChange={e=>setState(e.target.value)}
                        required
                    >
                        <option hidden>Select a State</option>
                        {states?.map((state)=>(
                            <option key={`cart-states-${state.id}`} value={state.short_name}>{state.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col sm={12} lg={props.forceMobile?12:6} xl={props.forceMobile?6:3}>
                <Form.Group controlId="postal-code">
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control
                        data-cy="cart-postal-code"
                        type="text"
                        value={postalCode}
                        onChange={e=>setPostalCode(e.target.value)}
                        placeholder="Postal Code"
                        required
                    />
                </Form.Group>
            </Col>                        
            <div className="error-text">{validation}</div>
        </Row>
    )
};
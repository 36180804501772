import React, {useState, useCallback, useEffect} from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { startOfDay, isAfter, parseISO, format } from 'date-fns';
import { Button } from 'react-bootstrap';

import Toast from '../../../../../components/Toast';
import { confirm } from '../../../../../components/Confirmation';
import { useRoleCheck } from '../../../../../components/common/CustomHooks';
import { authUserHasModuleAccessMany } from '../../../../../utils/auth';

import APIPOS from '../../../../../api/Pos';
import APITransactions from '../../../../../api/Transactions';

const VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID = 320;
const REFUND_PERMISSION_MODULE_ID = 178;
const VIEW_TRANSACTION_STATUS_MODULE_ID = 302;

export const TransactionDetails = (props) => {
    const {updateOrder} = props;
    
    const history = useHistory();  
    const role = useRoleCheck();
    const user = useSelector(state => state.auth.user);
    
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [advancedDetails, setAdvancedDetails] = useState(false);
    const [canSeeStatus, setCanSeeStatus] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isPatron, setIsPatron] = useState(false);
    const [transactionData, setTransactionData] = useState();

    useEffect(()=>{
        const checkAccess = async () => {
            try {
                const res = await authUserHasModuleAccessMany([VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID, REFUND_PERMISSION_MODULE_ID, VIEW_TRANSACTION_STATUS_MODULE_ID]);
                if (res) {
                    setAdvancedDetails(res[VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID]);
                    setCanSeeStatus(res[VIEW_TRANSACTION_STATUS_MODULE_ID]);
                    setIsAdmin(res[REFUND_PERMISSION_MODULE_ID]);
                    setIsPatron (!(res[VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID] || res[REFUND_PERMISSION_MODULE_ID]))
                }
            } catch(ex){
                console.error(ex)
            }
            
            if(role.id > 3 && props.user_id === user.profile.id) { //unless requested otherwise, this will not be a widget.  It's common practice to not be able to adjust things on your own profile.  SB immune for testing purposes
                setIsPatron(true); //if anyone is on their own profile, treat them as patron
                setIsAdmin(false);
            }
        }

        checkAccess();

    },[props.user_id, role.id, user.profile.id]);

    useEffect(()=>{
        const loadTransactionData = async () => {
            try {
                const res = await APITransactions.getAll({id:props.transaction.id});
                if (res.data) setTransactionData(res.data?.transactions?.[0]);
            } catch(ex){
                console.error(ex);
            }
        }


        if (props?.transaction?.id && advancedDetails) loadTransactionData();

        return () => {
            setTransactionData(null);
        }
    },[props?.transaction?.id, advancedDetails]);

    const voidHandler = useCallback(async (e, transaction) => {
        e.preventDefault();

        const result = await confirm(`Are you sure you want to void this transaction?`, {
            title: `Void Transaction`,
            okText: "Yes",
            cancelText: "No",
            cancelButtonStyle: "primary",
            okButtonStyle: "light"
        });
        if (result) {
            try{
                setSubmitting(true);
                const res = await APIPOS.payment.processVoid({transaction_id: transaction.id, order_id: props.currentOrder.id, memo: "user voided"});
                if (res.errors) setError(res.errors);
                else if (res.data) {
                    setError(null);
                    setSuccess(<Toast>Transaction Voided Successfully!</Toast>);
                    updateOrder(props.currentOrder.id);
                }
                setSubmitting(false);
            } catch(err){
                setError(err.message);
                setSubmitting(false);
            }
        }
    }, [props.currentOrder.id, updateOrder]);


    if (!props.transaction || !props.currentOrder) return null;

    return (
        <div className="selected-transaction">
            <p className="transaction-pair" data-cy="transaction-date">
                <span>Date:</span>
                <span>{format(new Date(props.transaction.date), "MM-dd-yyy h:ii: a")}</span>
            </p>
            <p className="transaction-pair" data-cy="transaction-type">
                <span>Type:</span>
                <span>{props.transaction?.transaction_type_name}</span>
            </p>
            <p className="transaction-pair" data-cy="transaction-number">
                <span>Transaction #:</span>
                <span>{props.transaction?.id}</span>
            </p>
            {props.transaction?.transaction_response?.transactionid && 
                <p className="transaction-pair" data-cy="transaction-gateway-number" >
                    <span>Gateway Transaction #:</span> 
                    <span>{props.transaction.transaction_response.transactionid}</span>
                </p>
            }
            <p className="transaction-pair" data-cy="transaction-payment-type">
                <span>Payment Type:</span>
                <span>
                    {+props.transaction.transaction_payment_method_id === 5 
                        ? "Manager Discount" 
                        : +props.transaction.transaction_payment_method_id === 1
                            ? `${props.transaction.cc_type} ending in ${props.transaction.cc_number}`
                            : props.transaction.transaction_payment_method_name
                    }
                    {props.transaction.transaction_response?.check_number && ` #${props.transaction.transaction_response.check_number}`}
                </span>
            </p>
            {props.transaction.transaction_response?.admin_user_name && advancedDetails &&
                <p className="transaction-pair" data-cy="transaction-manager-auth">
                    <span>Manager:</span>
                    <span>{props.transaction.transaction_response.admin_name} ({props.transaction.transaction_response.admin_user_name})</span>
                </p>                            
            }
            {transactionData?.admin_auth_id && advancedDetails &&
                <p className="transaction-pair" data-cy="transaction-authorized-by">
                    <span>Authorized by:</span>
                    <span>{transactionData.admin_name} ({transactionData.admin_auth})</span>
                </p>
            }
            {props.transaction.transaction_response?.check_name && 
                <p className="transaction-pair" data-cy="transaction-from">
                    <span>From:</span>
                    <span>{props.transaction.transaction_response.check_name}</span>
                </p>
            }            
            <p className="transaction-pair" data-cy="transaction-amount">
                <span>Amount:</span>
                <span>${props.transaction.amount.toFixed(2)}</span>
            </p>
            {props.transaction?.change > 0 && 
                <>
                    <p className="transaction-pair" data-cy="transaction-cash">
                        <span>Cash Tendered:</span>
                        <span>${props.transaction.transaction_response.amount.toFixed(2)}</span>
                    </p>
                    <p className="transaction-pair" data-cy="transaction-change">
                        <span>Change:</span>
                        <span>${props.transaction.transaction_response.change.toFixed(2)}</span>
                    </p>
                </>
            }
            {canSeeStatus &&
                <p className="transaction-pair" data-cy="transaction-status">
                    <span>Status:</span>
                    <span>{props.transaction.transaction_status_name}</span>
                </p>
            }
            <div className="my-3 ms-auto text-right">
                <Button variant="light" className="mb-2" onClick={e=>history.push(`/p/transaction/${props.transaction.id}`)} disabled={submitting}>More Details</Button>
                {props.transaction.transaction_status_id ===7 && (props.transaction.amount - props.transaction.amount_refunded > 0) && !props.transaction?.date && isAfter(startOfDay(new Date()), parseISO(props.transaction.date)) && isAdmin &&
                    <Button variant="light" className="mb-2" onClick={e=>voidHandler(e, props.transaction)} disabled={submitting || success}>Void Transaction</Button>
                }
                {error && <div className="error-text me-2">{error}</div>}
                {success}
            </div>
        </div>
    )
}
import { createContext, useContext, useState } from 'react';

const UploadProgressContext = createContext();
export const useUploadProgress = () => useContext(UploadProgressContext);

export const UploadProgressProvider = ({ children }) => {
    const [uploadProgress, setUploadProgress] = useState(0);

    const updateUploadProgress = (progress) => {
        setUploadProgress(progress);
    };

    return (
        <UploadProgressContext.Provider value={{ uploadProgress, updateUploadProgress }}>
            {children}
        </UploadProgressContext.Provider>
    );
};
import React from 'react';
import {Card as BsCard} from 'react-bootstrap';

import styles from "./Card.module.scss";

export const Card = (props) => {
    return (
        <div className={styles.product_item} data-cy="product-cards-wrapper">
            <BsCard as="a"
                data-cy="product-card"
                href="#!"
                onClick={() => (props.loading || props.disabled) ? null : props.onClick()}
                className={`${styles["product-card"]} no-select-text p-0 ${props.active? styles.active:""} ${props.disabled? styles.disabled:""} ${props.styles}`}
            >
                <BsCard.Body>
                    <div className={styles["product-container"]}>
                        <BsCard.Title className={styles["product-name"]} data-cy="product-name">{props.title}</BsCard.Title>
                        {props.children}
                    </div>
                </BsCard.Body>
            </BsCard>
        </div>
    );
}
import React, { useState, useEffect, useContext } from 'react';
import {Image as Img} from 'react-konva';

import { FileURLContext } from '../../../contexts/FileURLContext';

export const Image = (props) => {

    const imageURL = useContext(FileURLContext);

	const [image, setImage] = useState(new window.Image());

	useEffect(() => {
		const img = new window.Image();
		if (props.blob) img.src=URL.createObjectURL(props.blob);
		else img.src = imageURL.base+"basketball-court.svg";
		setImage(img);
	}, [props.blob, imageURL.base]);

	return (
		<Img
			image={image}
			x={props.x}
			y={props.y}
			width={props.width}
			height={props.height}
			//fill={props.fill}
          	opacity={props.opacity}
			onSelect={props.onSelect}
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		/>
	);  
}
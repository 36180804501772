import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

export const OrderTotals = (props) => {

    if (!props.order) return null;

    return (
        <Container className="ps-os-totals-wrapper text-right">
            <Row>
                <Col>
                    <Row>
                        <Col>Subtotal</Col>
                    </Row>
                    {/*<Row>
                        <Col>Admin Fee</Col>
                    </Row>*/}
                    <Row>
                        <Col>Total Tax</Col>
                    </Row>
                    <Row className="titles">
                        <Col>Final Total</Col>
                    </Row>
                </Col>
                <Col sm="auto">
                    <Row>
                        <Col>${props.order?.subtotal_price?.toFixed(2)}</Col>
                    </Row>
                    {/*<Row>
                        <Col>${props.order?.admin_fee_total?.toFixed(2)}</Col>
                    </Row>*/}
                    <Row>
                        <Col>${props.order?.tax_total?.toFixed(2)}</Col>
                    </Row>
                    <Row className="titles">
                        <Col>${props.order?.total_price?.toFixed(2)}</Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
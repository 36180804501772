import React, {useState, useEffect} from 'react';
import {format} from 'date-fns';
import {Button} from 'react-bootstrap';

//import EventWizard from '../EventWizard';
import EventWizardFull from '../EventWizardFull';
import APIEvents from '../../../../../api/Events';

const EventDetail = (props) => {

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }  

    const [data, setData] = useState([]);

    useEffect(() => {
        return () => {
            setData([]);
        }
    }, []);   

    useEffect(() => {        
        const _loadEvent = async (id) => {
            let _data;
            if (id){
                const response = await APIEvents.publicGet({id: id});
                if (response?.data?.events) _data=response.data.events;
            } else {
                _data = props?.data;
            }
            if (_data){
                for(let item of _data){
                    let startFormatted = format(new Date(item?.start_datetime), "ccc MM/dd/yyyy");
                    let endFormatted = format(new Date(item?.end_datetime), "ccc MM/dd/yyyy");
                    if (startFormatted === endFormatted) {
                        if (item.is_meta) item.date = startFormatted;
                        else {
                            const startFormatted2 = format(new Date(item?.start_datetime), "hh:mm aa");
                            endFormatted = format(new Date(item?.end_datetime), "hh:mm aa");
                            item.date= <>{startFormatted}<br/>{`${startFormatted2} - ${endFormatted}`}</>;
                        }
                    } else {
                        startFormatted = format(new Date(item?.start_datetime), "ccc MM/dd/yyyy"+(!item.is_meta?" hh:mm aa":""));
                        endFormatted = format(new Date(item?.end_datetime), "ccc MM/dd/yyyy"+(!item.is_meta?" hh:mm aa":""));
                        item.date= <>{`From ${startFormatted}`}<br/>{`To ${endFormatted}`}</>;
                    }
                }
                setData(_data);
            }
        }

        if (props?.data) _loadEvent();
        else _loadEvent(props?.id);

    }, [props?.data, props?.id]);


    return (
        <div className={`event-detail_${props.page_id} ${props.className || ""}`} style={props?.style || null} onClick={preview_click}>
            {data && data.map((item, i) => (
                <EventWizardFull key={`event-wizard-${i}`} {...props} {...item} />
            ))}
        </div>
    );
}

export default EventDetail;

import React, {useCallback} from 'react'
import { Typeahead } from './Typeahead'

import Users from '../../api/Users'

/** @param {{}} postFilters - for the endpoint can include {user_ids: 123, user_roles: [2,3]} */
export const NewUserTypeahead = ({async=true, multiple=true, paginated=true, initialDataIds=null, postFilters=null, ...props }) => {
    
    const getUsers=useCallback(async(query, perPage, page=1)=>{
        let responseObj;
        let filter={
            user_ids: postFilters?.user_ids || null,
            user_roles: postFilters?.user_roles || null,
            search_words: query || null
        }
        try{
            let response = await Users.list({
                filters: filter,
                max_records: perPage,
                page_no: page,
                sort_col: "username",
                sort_direction: "ASC"
            });
            responseObj={
                data: response.data?.users || null,
                errors: response.errors || null
            }
        }catch(ex){
            console.error(ex);
            responseObj={
                data: null,
                errors: ex
            }
        }
        return responseObj;
    },[postFilters]);

    const formatForLabel = (option)=>{
        return `${option?.first_name} ${option?.last_name} (${option?.username}---${option?.email})`
    }
    
    return (
        <Typeahead
            {...props}
            id="new-user-typeahead"
            makeRequest={getUsers}
            formatForLabel={formatForLabel}
            async={async}
            multiple={multiple}
            paginated={paginated}
            initialDataIds={initialDataIds}
        />
    )
}

export default NewUserTypeahead
import React, { useState, useEffect, useCallback } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Button from 'react-bootstrap/Button';
import { format } from 'date-fns';

import {confirm} from '../../../../components/Confirmation';
import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import Users from '../../../../api/Users';

export const CheckinButton = (props) => {

    const [error, setError]=useState();
    const [ userInfo, setUserInfo ] = useState();
    const [ lastCheckin, setLastCheckin ] = useState();
    
    useEffect(() => {
        if (props.userInfo?.id) {
            loadUserLastCheckin(props.userInfo.id);
        }
    },[props.userInfo]);

    useEffect(() => {
        return () => {
            setUserInfo(null);
            setLastCheckin(null);
        }
    },[]);

    const loadUserLastCheckin = async (user_id) => {
        await Users.Checkin.get({
            user_id: user_id,
            sort_dir: "DESC",
            limit: 1
        }).then(async response => {
            if (!response.errors) {
                if (response.data.length>=1) setLastCheckin(new Date(response.data[0].checkin_at));
            } else if(response.errors) {
                console.error(response.errors);
                setError(<ErrorCatcher title="Cannot load last check in" error={response.errors} />)
            }
        }).catch(e => console.error(e));
    }

    const confirmHandler = useCallback((props) => {
        confirm(`Check in ${props.text || "user"} to the facility?`, {
            title: props.title,
            okText:"Yes",
            cancelText:"No",
            cancelButtonStyle:"light"
        }).then(result =>{
            if (result===true) props.click();
        }).catch(e => console.error(e));
    },[]);

    const checkinUser = async (user_id) => {
        await Users.Checkin.create({
            user_id: user_id,
        }).then(async response => {
            if (!response.errors && response.data) {
                setLastCheckin(new Date(response.data?.checkin_at));
            } else {
                console.error(response.errors);
                setError(<ErrorCatcher title="Cannot check in user" error={response.errors} />)
            }
        }).catch(e => console.error(e));
    }

    if (!props.userInfo) return (
        <div className="text-center">
            <SkeletonTheme color="#e0e0e0">
                <Skeleton height={12} count={1} />
            </SkeletonTheme>
        </div>
    );
    
    return (
        props.userInfo &&
            <div className="site-col" style={{"alignItems": "center"}}>
                <Button variant={props.variant || "primary"} className={`btn btn-checkin ${props.classes || ""}`} data-cy="checkin-btn"
                    onClick={() => {
                        confirmHandler({
                            title: "Confirm Check In",
                            text:`${props.userInfo.first_name} ${props.userInfo.last_name}`,
                            click:() => checkinUser(props.userInfo.id)
                        });
                    }}
                >{props?.text ? props.text : "Check In This User"}
                </Button>
                {!props.hide_checkins &&
                    <div className="checked-in-today text-center mt-1">
                        {lastCheckin ?
                            <>
                                <span>Last check in:</span><br />
                                <span data-cy="checkin-last">{format(lastCheckin, "M/d/Y h:mm b")}</span>
                            </>
                        :
                            <>No prior check in.</>
                        }
                    </div>
                }
                {error}
            </div>
    );
}
import React, {useState, useEffect, useCallback, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col, Container} from 'react-bootstrap';

import Items from './Items';
import Totals from './Totals';

import {processOrder, demoOrder, removeItem, updateItemQty} from './thunks';
import {cancelBooking, refreshOrder, loadOrderIntoRedux} from '../../../../../../utils/thunks';
import PrintFullPage from '../../../../../POS/Print/PrintFullPage';
import {randomUUID} from '../../../../../../utils/cms';

import APIUsers from '../../../../../../api/Users';
import APIPOS from '../../../../../../api/Pos';
import * as actions from '../../../../../../store/actions';

import styles from './Checkout.module.scss';

const Checkout = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const dispatch = useDispatch();
    const history = useHistory();

    const totalsRef=useRef();
    const colRef=useRef();

    const register_id = props?.register_id || 0;
    
    const reduxUpdate = useSelector(state => state.pos?.[register_id]?.updated); 
    const reduxOrder = useSelector(state => state.pos?.[register_id]?.orderAll);
    const items = useSelector(state => state.pos?.[register_id]?.items);
    const currentBooking = useSelector(state => state.serviceBooking.current_booking);
    const user = useSelector(state => state.auth.user);

    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [updateOrder, setUpdateOrder] = useState(false); // used to force a re-render of the order when the order is updated in the redux store
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [stickyHeight, setStickyHeight] = useState("100%");
    const [stickyWidth, setStickyWidth] = useState("auto");
    const [printerHasProps, setPrinterHasProps] = useState();
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const [localOrderId, setLocalOrderId] = useState(0);

    const submitHandler = useCallback(async (token, paymentFields, giftCardsApplied = []) => {
        if (paymentFields.collectJS && !processing) {
            setProcessing(true);
            const promises = [];
            if (!token){
                await paymentFields.collectJS.startPaymentRequest();
                promises.push(paymentFields.collectJS.tokenPromise);
                //const tokenres = await collectJS.tokenPromise;
            } else{
                promises.push(Promise.resolve({token: token}));
            }
            
            const res = await Promise.all(promises);
            if (res[0].token) {
                const _data = {
                    payment_token: res[0].token,
                    first_name: paymentFields.first_name || user.profile.first_name,
                    last_name: paymentFields.last_name || user.profile.first_name,
                    bill_address1: paymentFields?.bill_address1 || undefined,
                    bill_address2: paymentFields.bill_address2 || undefined,
                    bill_city: paymentFields.bill_city || undefined,
                    bill_state: paymentFields.bill_state || undefined,
                    bill_postalcode: paymentFields.bill_postalcode || undefined,
                    payment_profile_id: paymentFields.payment_profile_id || undefined,
                };

                const payments = [];
                if (giftCardsApplied?.gift_cards?.length > 0) { // gift cards must be sent first
                    giftCardsApplied.gift_cards.forEach(gc=>{
                        payments.push({
                            payment_method_id: 4,
                            amount: gc.current_balance,
                            card_code: gc.card_code,
                        });
                    });
                }
                payments.push({
                    payment_method_id: 1,
                    amount: order.total_price,
                    type: 'sale',
                    description: "Online Credit Card Payment",
                    ..._data,
                });

                try{
                    /*
                    const res3 = await APIPOS.payment.card({
                        order_id: order.id,
                        type: 'sale',
                        description: "Online Credit Card Payment",
                        ..._data,
                    });
                    */
                    const res3 = await APIPOS.payment.process({order_id: order.id, payments});
                    if (res3.errors || res3.error) {
                        await paymentFields.collectJS.retokenize();
                        setError(res3.errors || res3.error.message);
                    } else if (res3.data){
                        if (paymentFields?.bill_savecard) {
                            await paymentFields.collectJS.retokenize();

                            const _token = await paymentFields.collectJS.tokenPromise;
                            const res2 = await APIUsers.PaymentProfile.create({
                                first_name: paymentFields.first_name,
                                last_name: paymentFields.last_name,
                                bill_address1: paymentFields.bill_address1,
                                bill_address2: paymentFields.bill_address2,
                                bill_city: paymentFields.bill_city,
                                bill_state: paymentFields.bill_state,
                                bill_postalcode: paymentFields.bill_postalcode,
                                name: "{card}",
                                payment_token: _token?.token,
                            });
        
                            //console.log(res2, res[0].token, _token.token)
                        }

                        /*
                        const items = [];
                        const data = []
                        for (let seluser of selectedUsers){
                            let _data = {
                                for_user_id: seluser.id,
                                by_user_id: user.id,
                                event_id: props.id,
                                user_name: `${seluser.first_name} ${seluser.last_name}`,
                            };
                
                            items.push({
                                variant_id: props.stepValues.variant_id || props.default_variant_id,
                                qty: 1,
                                event: {
                                    event_id: props.id,
                                    for_user_id: seluser.id
                                }
                            });
                
                            if (customFields){
                                if (customFields?.[seluser.id]){
                                    customFields[seluser.id].forEach(field=>{
                                        _data[`custom_${field.custom_field_id}`] = `${field.value}`;
                                    });
                                }
                            }
                
                            data.push(_data);
                        }
                        */

                        if (!Array.isArray(res3.data)) res3.data=[res3.data];
                        setSuccess(res3.data);

                        res3.data.forEach(payment=>{
                            dispatch(actions.transaction(payment.transaction_id, register_id));
                        });
                        dispatch(refreshOrder(register_id));
                    }
                } catch (e) { 
                    setError(e.message);
                    setSuccess(null);
                    setProcessing(false);
                }
            }
            setProcessing(false);
        } else {
            setError("Could not connect to payment processor.");
        }
    },[processing, order.id, order.total_price, dispatch, register_id, user?.profile]);

    const getOrder = useCallback(async () => {
        let _reduxOrder = reduxOrder;
        if (!reduxOrder && localOrderId && user?.profile) {
            // loads the order from the server
            const response = await APIPOS.order.get({id: localOrderId});
            if (!response.errors && response.data && response.data.split_order_ids.length===0){
                _reduxOrder = response.data;
                dispatch(actions.selectCustomer(user.profile, register_id));
                dispatch(loadOrderIntoRedux(response.data, register_id));
            }
        }

        if (updateOrder || (_reduxOrder && !processing && Object.entries(order || {}).toString() !== Object.entries(_reduxOrder || {}).toString())) {
            setLoading(true);
            const res =await processOrder(_reduxOrder, register_id);
            if (Object.keys(res).length>0) {
                setOrder(res);
            } else if (updateOrder) setOrder(res);
            setLoading(false);
            setUpdateOrder(false);
        }
    },[reduxOrder, processing, order, register_id, updateOrder, dispatch, localOrderId, user?.profile]);

    const removeItemHandler = useCallback(async (id) => {
        if (id && !processing) {
            setProcessing(true);
            await removeItem(items, id, register_id, user?.profile?.id, ()=>{
                history.go(0);
            });
        }
    },[items, processing, register_id, history, user?.profile?.id]);

    const updateQtyHandler = useCallback(async (id, qty) => {
        if (id && !processing) {
            setProcessing(true);
            await updateItemQty(items, id, qty, register_id, user?.profile?.id, ()=>{
                history.go(0);
            });
        }
    },[items, processing, register_id, history, user?.profile?.id]);


    useEffect(() => {
        if (!props.is_preview && !localOrderId && !isFirstLoad && user?.profile?.id){
            if (localStorage.getItem(`POS-${register_id}-order-id`)) setLocalOrderId(JSON.parse(localStorage.getItem(`POS-${register_id}-order-id`)));
            else {
                APIPOS.order.latestOpen({ user_id: user.profile.id, register_id: register_id }).then( async response => {
                    if (!response.errors && response.data) {
                        if (response.data.order_status_id === 1) {
                            setLocalOrderId(response.data.id);
                        } else {
                            APIPOS.local.remove(`POS-${register_id}-order-id`);
                            dispatch(actions.setPCReset());
                            dispatch(actions.reset());
                        }
                    } else if (response.errors) {
                        console.error(response.errors);
                    }
                });
            }
        }
    }, [props.is_preview, register_id, dispatch, user?.profile?.id, localOrderId, isFirstLoad]);

    useEffect(() => {
        if (!Object.entries(order || {}).toString() && !isFirstLoad){
            if (props.is_preview){
                setOrder(demoOrder());
                setIsFirstLoad(true);
            } else if (localOrderId) {
                getOrder();
                setIsFirstLoad(true);
            }
        }
    }, [getOrder, order, props.is_preview, isFirstLoad, localOrderId]);


    useEffect(() => {
        if (reduxUpdate === true) {
            setUpdateOrder(true);
            getOrder();
            dispatch(actions.setUpdated(false, register_id));
        }
    }, [reduxUpdate, getOrder, dispatch, register_id]);

    useEffect(() => {
        if (user?.payment_profiles){
            const _paymentMethods = [];
            user.payment_profiles.forEach((profile) => {
                _paymentMethods.push({
                    id: profile.id, 
                    name: profile.name, 
                    type: profile.card_type, 
                    profile_id: profile.customer_profile_id, 
                    payment_profile_id: profile.customer_payment_profile_id
                });
            });
            setPaymentMethods(_paymentMethods);
        }
    }, [user]);

	useEffect(() => {
        let interval;
		
        if (currentBooking?.event_id && currentBooking?.service?.default_product_variant_id && items?.length > 0) {
            const tokenProducts = items.filter(item => item.variant_id === currentBooking.service.default_product_variant_id);

            interval = setInterval(() => {
                const exp = tokenProducts.filter(item => new Date() >= new Date(item.valid_until));
                if (exp.length > 0) {
                    dispatch(cancelBooking(register_id));
                    clearInterval(interval);
                    alert('The Service Booking in your cart has expired. Please reserve a new time slot.', {
                        title: "Cart Item Expired",
                        okText: "OK",
                    });
                }      
            }, 1000);
        }

        return () => {
            if (interval) clearInterval(interval);
        }
	},[items, currentBooking, dispatch, register_id]);

    //Clears local storage after print receipt has details
    useEffect(()=>{
        if(printerHasProps && success){
            APIPOS.local.remove(`POS-${props.register_id}-order-id`);
            dispatch(actions.setPCReset());
            dispatch(actions.reset());
        };
    },[printerHasProps, success, dispatch, props.register_id]);


    // lil hack to make the totals column have a min height in case the items column is shorter
    useEffect(() => {
        if (totalsRef.current) {
            setStickyHeight(`${totalsRef.current.scrollHeight}px`);
            if (colRef?.current){
                const _style = window.getComputedStyle(totalsRef.current);
                setStickyWidth(`${colRef.current.offsetWidth - (parseFloat(_style.paddingRight) || 0)}px`);
            }
        }
    }, [order]);

    if (!props.is_preview && !props.register_id) return "Please select a register.";

    return (
        <Container fluid ref={ref} id={props.is_preview ? `preview-${randomUUID()}` : props.id} className={`checkout-wrapper ${props.className || ""} ${styles.container}`} style={props.style || null} onClick={preview_click}>
            <Row>
                <Col className={`${styles["item-container"]} checkout-items order-2 order-lg-1`}>
                    {success &&
                        <div className={styles["print-wrapper"]}>
                            <PrintFullPage 
                                transSuccess={success} 
                                setPrinterHasProps={setPrinterHasProps} 
                                userDetails={user.profile} 
                                order={order}
                                items={order?.items || items || []}
                            />
                        </div>
                    }
                    {!success &&
                        <Items order={order} loading={loading} processing={processing} error={error} success={success} delete={removeItemHandler} updateQty={updateQtyHandler} events_display={props.events_display} empty_message={props.empty_message} />
                    }
                </Col>
                <Col ref={colRef} sm={12} lg={4} className={`${styles["totals-container"]} order-1 order-lg-2`} style={{height:stickyHeight}}>
                    <Totals ref={totalsRef} register_id={register_id} order={order} loading={loading} processing={processing} error={error} success={success} submit={submitHandler} payment_methods={paymentMethods} style={{width: stickyWidth}} />
                </Col>
            </Row>
        </Container>
    );
});

export default Checkout;
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

const Slaask = (props) => {

	const user = useSelector(state => state.auth.user);

    const [scriptIsLoaded, setScriptIsLoaded] = useState(false);

    const removeScript = useCallback(() => {
        if (scriptIsLoaded) {
            let slaaskElement = document.getElementById("slaask-script");
            if (slaaskElement) slaaskElement.remove();
        }
    }, [scriptIsLoaded]);

    useEffect(() => {
        if (user) {
            
            window._slaaskSettings = {
                key: "spk-a103e1b5-b029-4e23-85f5-ec9ab02538b5",
                identify: () => {
                    if (user?.profile) return {
                        id: user.profile.id,
                        name: user.profile.first_name + " " + user.profile.last_name,
                        email: user.profile.email,
                        avatar: user.profile.profile_img_path,
                        company: "Impact",
                    }
                    else return null;
                }
            };

            if (!scriptIsLoaded) {
                // this script is currently only visible for staff and admins, not patrons or guests
                let userRoles = user.roles?.map(role => role.id) || [];
                if (userRoles.find(role => role<=5)) {
                    let script = document.createElement('script');
                    script.setAttribute('id', 'slaask-script');
                    script.src = "https://cdn.slaask.com/chat_loader.js";
                    script.async = true;
                    document.body.appendChild(script);

                    setScriptIsLoaded(true);
                }
            } else {
                // reload slaask if user info changes
                // window._slaask.identifyContact();
            }
        } else {    // no user
            removeScript();
        }

        return () => {
            removeScript();
        }
    }, [user, scriptIsLoaded, removeScript]);

    // this is specifically to hide the slaask button after the user logs out - I couldn't remove the script
    useEffect(() => {
        if (!user) {
            document.body.classList.add('hide-slaask');
        } else {
            document.body.classList.remove('hide-slaask');
        }
    }, [user]);

    return <></>;
}
export default Slaask;
import React from 'react';
import {Circle as Circ} from 'react-konva';

export const Circle = (props) => {
  return (
        <Circ
          x={props.x}
          y={props.y}
          width={props.width}
          height={props.height}
          fill={props.fill}
          opacity={props.opacity}
				  strokeWidth={props.stoke}
				  stroke={props.stokeColor}
          onSelect={props.onSelect}
          onClick={props.onClick}
				  onMouseEnter={props.onMouseEnter}
				  onMouseLeave={props.onMouseLeave}
        />
  );  
}
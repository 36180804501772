import React, { useState, useEffect, useCallback } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import SubMap from './SubMap';
import Users from '../../../../api/Users';

import './Subscriptions.css';

export const Subscriptions = (props) => {

    const [loading,setLoading]=useState(true);
    const [memberships,setMemberships]=useState({
        active: [], 
        suspended: [],
        cancelled: [], 
        expired: []
    });

    const filterMemberships = useCallback((memberships, statusId) => {
        return memberships.filter((membership) => membership.subscription_status_id === statusId)
    },[]);

    // first load, get memberships from api
    useEffect(() => {
        let mounted = true;

        setLoading(true);
        // get all memberships
        Users.memberships({user_id:props.user_id})
        .then(response => {
            if(mounted) {
                if (response.data){
                    let memberships = response.data.subscriptions;
                    let active = filterMemberships(memberships, 1);
                    let suspended = filterMemberships(memberships, 2);
                    let cancelled = filterMemberships(memberships, 3);
                    let expired = filterMemberships(memberships, 4);
                    setMemberships({
                        active: active || [], 
                        suspended: suspended || [] ,
                        cancelled: cancelled || [], 
                        expired: expired || []
                    });
                }

            }
        }).catch(e => console.error(e));
        
        setLoading(false);
        // cancel stuff when component unmounts
        return () => {
            mounted = false;
            setLoading(false);
            setMemberships([]);
        }        
    },[props.user_id, filterMemberships]);
    
        
    return (
        <React.Fragment>
            {loading ? 
                <Spinner animation="border" variant="secondary" />
                :
                <>
                    {memberships?.active?.length > 0 ?
                        memberships?.active?.map((membership,i)=>(
                            <SubMap key={`subs-pos-${i}`} membership={membership} />
                        ))
                    :
                        <span className="text-center mb-1">No Active Subscriptions</span>
                    }
                    {memberships?.suspended?.length > 0 ?
                        memberships?.suspended?.map((membership,i)=>(
                            <SubMap key={`subs-pos-${i}`} membership={membership} />
                        ))
                    :
                        <span className="text-center mb-1 mt-1" ><i>No Suspended Subscriptions</i></span>
                    }
                    {memberships?.cancelled?.length > 0 ?
                        memberships?.cancelled?.map((membership,i)=>(
                            <SubMap key={`subs-pos-${i}`} membership={membership} />
                        ))
                    :
                        <span className="text-center mb-1 mt-1">No Cancelled Subscriptions</span>
                    }
                    {memberships?.expired?.length > 0 ?
                        memberships?.expired?.map((membership,i)=>(
                            <SubMap key={`subs-pos-${i}`} membership={membership} />
                        ))
                    :
                        <span className="text-center mt-1">No Expired Subscriptions</span>
                    }
                </>
            }
        </React.Fragment>
    );
}
import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table'

import '../Refund.scss'

/** Component for displaying the specific information of refunded transactions in a table format */
export const RefundDisplay = (props) => {

    const eachItem=(item, isAddon)=>{
        return(
            <>
                <tr>
                    <td>{item.id}</td>
                    <td>{item.product_name} {item?.variant_name?.toLowerCase() === "default" ? null : <span> - {item.variant_name}</span>}</td>
                    {item.total_refunded_amount ?
                        <td>${(item.total_refunded_amount).toFixed(2)}</td>
                    :
                        <td>$0.00</td>
                    }
                    <td>${(item.final_price + item.tax).toFixed(2)}</td>
                    {item.shipping_refunded_amount ? 
                        <td>${(item.shipping_refunded_amount).toFixed(2)}</td>
                    :
                        <td>$0.00</td>
                    }
                    {item.shipping ? 
                        <td>${(item.shipping).toFixed(2)}</td>
                    :
                        <td>$0.00</td>
                    }
                </tr>
                {props.isPatron ?
                    null
                :
                    <>    
                        {item.refund_memo ?
                            <tr>
                                <td colSpan="2">Refund Memo for {item.id}</td>
                                {item.refund_memo ? 
                                    <td colSpan="4">{item.refund_memo}</td>
                                :
                                    <td colSpan="4">No item memo</td>
                                }
                            </tr>
                            :
                            null
                        }
                    </>
                }
            </>
        )
    }

    return (
        <Table reponsive="true" striped>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Refund Amount</th>
                    <th>Original Price</th>
                    <th>Shipping Refunded</th>
                    <th>Original Shipping</th>
                </tr>
            </thead>
            <tbody>
                {props.currentOrder.items?.map((item, i)=>(
                    <React.Fragment key={`rd-table-items${i}${item.id}`}>
                        {eachItem(item, false)}
                        {item?.addons?.map((addon, i)=>(
                            <React.Fragment key={`rd-table-addons${i}${addon.id}`}>
                                {eachItem(addon, true)}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </tbody>
        </Table>
    )
}

import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const Fields = React.forwardRef((props, ref) => {
    return (
        <Row ref={ref}>
            <Col sm={12} lg={props?.forceMobile?12:6}>
                <Form.Label>Credit Card Number</Form.Label>
                <div id="bill_ccnumber" />
            </Col>
            <Col sm={12} lg={props?.forceMobile?6:3}>
            <Form.Label>Exp. Date</Form.Label>
                <div id="bill_ccexp" />
            </Col>
            <Col sm={12} lg={props?.forceMobile?6:3}>
                <Form.Label>CVV</Form.Label>
                <div id="bill_cvv" />
            </Col>
        </Row>
    );
});

export default Fields;
import Pos from '../../../../api/Pos';

/**@param {*} type - takes in byAmounts or byItems */
/**@param {*} parameters - any other details needed by the call as an object*/
export const refundTypes=async(type, parameters, registerId)=>{
    let response;
    let returnData;
    if(type === "byItems"){
        try{
            response = await Pos.addRefund({
                user_id: parameters.userId,
                order_id: parameters.orderId,
                register_id: registerId,
                admin_auth_id: parameters.adminAuthId,
                admin_fee_refunded: parameters.adminFee,
                items: parameters.items
            })
            returnData = {
                data: response.data,
                errors: response.errors
            }
        }catch(ex){
            console.error(ex)
            returnData = {
                data: null,
                errors: ex
            }
        }
    }
    if(type === "byAmount"){
        try{
            response = await Pos.payment.refund({
                amount: parameters.order.subtotal_price + parameters.order.tax_total,
                transaction_id: parameters.transactionId,
                payment_type: parameters.paymentType,
                register: registerId 
            })
            returnData = {
                data: response.data,
                errors: response.errors
            }
        }catch(ex){
            console.error(ex)
            returnData = {
                data: null,
                errors: ex
            }
        }
    }
    return returnData;
}

/**
 * @param {{}} orderId
 */
export const cancelSplit=async(orderId)=>{
    let returnData;
    try{
        let response = await Pos.payment.cancel_split({
            order_id: orderId
        });
        returnData ={
            data: response.data,
            errors: response.errors
        } 
    }catch(ex){
        console.error(ex)
        returnData = {
            data: null,
            errors: ex
        }
    }
    return returnData;
}

export const addToOrder = async(items, userId, orderId, registerId,orderStatusId, appliedCoupons)=> {

    const tempItems=[];

    items.forEach((item)=>{
        const tempAddons=[]
        item.order_item_id = item.id
        item?.addons?.forEach((addon)=>{
            tempAddons.push({
                order_item_id: null,
                id: null,
                variant_id: addon.variant_id,
                qty: addon.qty || 1,
            })
        });
        const tempItem={
            order_item_id: null,
            id: null,
            user_id: userId,
            variant_id: item.variant_id,
            qty: item.qty || 1,
            addons: tempAddons,
        }
        if (item.event) {
            tempItem.event = {
                event_id: item.event.event_id || null,
                for_user_id: item.event.for_user_id || null,
                for_user_name: item.event.for_user_name || null,
                custom_fields: item.event.custom_fields || null,
                custom_field_definition: item.event.custom_field_definition || null,
            }
        }
        tempItems.push(tempItem);
    })

    // update order details
    try{
        let response = await Pos.order.update({
            register_id: +registerId,
            order_id: orderId,
            items: tempItems,
            user_id: userId,
            order_status_id: orderStatusId,
            applied_coupon_ids: appliedCoupons,
            tip:0,
        });
        if(!response.errors){
            if (response.data?.[0]?.items) {
                let order = response.data[0];
                return order;
            };
        };
    }catch(ex){console.error(ex)};	
};

export const createOrder = async(currentOrder, payees, splitType) => {

    const prepForAmounts=()=>{
        let callData = {
            order_id: currentOrder.id,
            type: "amounts",
            amounts:[]
        }
        payees.forEach((payee)=>{
            callData.amounts.push({
                user_id: payee.id,
                amount: payee.amount,
                memo: payee.identifier
            })
        });
        return callData;
    };

    const prepForItems=()=>{
        let callData={
            order_id: currentOrder.id,
            type:"items",
            items: []
        }
        payees.forEach((payee)=>{
            payee.items.forEach((item)=>{
                callData.items.push({
                    id: item.id,
                    user_id: payee.id,
                    memo: payee.identifier
                })
            })
        })
        return callData;
    }
    
    const createOrderCall = async()=>{
        let callData;
        let responseData;
        if(splitType==="byAmount") callData=prepForAmounts();
        if(splitType==="byItems") callData=prepForItems();
        try{
            let response = await Pos.payment.split_payment(callData);
            if(!response.errors){
                responseData = response.data
                if(splitType==="byItems"){
                    response.data.split_order_ids = Array.from(new Set(response.data.split_order_ids))
                }else responseData = response.data
            }else if(response.errors){
                responseData = {error: response.errors}
            }
        }catch(ex){
            console.error(ex);
            responseData= {error: ex}
        }
        return responseData;
    };

    let temp = await createOrderCall()

    return temp;
}

import * as actionTypes from "../actions/actionTypes";

const initialState = {
    updateNeeded: false
}

const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_EVENTS:
            return {
                ...state,
                updateNeeded: !state.updateNeeded
            };
        case actionTypes.RESET_EVENTS:
            return {
                ...initialState,
            }
        default:
            return state;
    }
}

export default calendarReducer;
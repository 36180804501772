import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { randomUUID, checkBoolean, localGroup, toKebabCase } from '../../../../../utils/cms';

const FormGroupTextarea =  React.forwardRef((props, ref) => {
    const bkRef = useRef();

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [value, setValue] = useState(props?.value || "");

    useEffect(() => {
        if (props?.value) setValue(props.value);
    }, [props?.value]);

    useEffect(() => {
        if (props.id && bkRef.current){
            localGroup(bkRef.current.querySelector("textarea"), props.id);
        }
    }, [bkRef, props.id]);

    useEffect(() => {
        return () => {
            setValue("");
        }
    }, []);

    const changeHandler = (e) => {
        setValue(e.target.value);
        localGroup(e.target, props.id);
        if (props?.onChange) props.onChange(e);
    }

    const blurHandler = (e) => {
        setValue(e.target.value);
        localGroup(e.target, props.id);
        if (props?.onBlur) props.onBlur(e);
    }    

    const _disabled = checkBoolean(props?.disabled) || false;
    const _readonly = checkBoolean(props?.readonly) || false;
    const _required = checkBoolean(props?.required) || false;    

    return (
        <Form.Group 
            ref={ref || bkRef} 
            className={`form-group-textarea_${props.page_id} ${props.className || ""}`} 
            style={props.style} 
            controlId={`${toKebabCase(props?.name) || toKebabCase(props?.label)}-${randomUUID()}`}
            onClick={preview_click}>
                <Form.Label>{props?.label || ""}</Form.Label>
                <Form.Control 
                    ref={props.inputRef || null}
                    as="textarea" 
                    rows={props?.rows || 3} 
                    name={props?.name || props?.label || randomUUID()} 
                    value={value} 
                    placeholder={props?.placeholder || ""} 
                    onChange={changeHandler}
                    onBlur={blurHandler} 
                    disabled={_disabled}
                    readOnly={_readonly}
                    required={_required}
                    isInvalid={props?.isInvalid || false}
                />
                <Form.Control.Feedback type="invalid">
                    {props?.feedback || <>This field is required</>}
                </Form.Control.Feedback>
        </Form.Group>
    );
});

export default FormGroupTextarea;
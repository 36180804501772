import * as actionTypes from '../actions/actionTypes';

const initialState= {
    logo: null,
    favicon: null,
    css: null,
    variables: null,
};

const themeReducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.THEME_LOGO:
            return{
                ...state,
                logo: action.logo
            }

        case actionTypes.THEME_FAVICON:
            return{
                ...state,
                favicon: action.favicon
            }

        case actionTypes.THEME_CSS:
            return{
                ...state,
                css: action.css
            }

        case actionTypes.THEME_VARIABLES:
            return{
                ...state,
                variables: action.vars
            }

        default:
            return state;
    }
}

export default themeReducer;
import * as actionTypes from './actionTypes';


export const setProductVariant = (variant,type) => {
    return {
        type: type,
        variant: variant
    };
};

export const addProductVariant = (variant) => {
    return dispatch => {
        dispatch(setProductVariant(variant,actionTypes.PRODUCT_ADD_VARIANT));
    };
}; 

export const removeProductVariant = (id) => {
    return dispatch => {
        dispatch(setProductVariant(id,actionTypes.PRODUCT_REMOVE_VARIANT));
    };
};

export const removeProductVariantCategory = (id) => {
    return dispatch => {
        dispatch(setProductVariant(id,actionTypes.PRODUCT_REMOVE_VARIANT_CATEGORY));
    };
};

export const removeProductVariantAddOn = (id) => {
    return dispatch => {
        dispatch(setProductVariant(id,actionTypes.PRODUCT_REMOVE_VARIANT_ADDON));
    };
};

export const setSelectedProductVariant = (selected,type) => {
    return {
        type: type,
        selected: selected
    };
}

export const selectProductVariant = (id) => {
    return dispatch => {
        dispatch(setSelectedProductVariant(id,actionTypes.PRODUCT_SELECT_VARIANT));
    };
}

export const setExistingProductVariant = (variant, type) => {
    return {
        type: type,
        variant: variant
    };
}

export const editProductVariant = (variant) => {
    return dispatch => {
        dispatch(setExistingProductVariant(variant, actionTypes.PRODUCT_EDIT_VARIANT));
    };
}



import React, { useState, useEffect, useRef } from 'react'
//import Button from 'react-bootstrap/Button';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {Container,Row,Col,Card} from 'react-bootstrap';
//import { format } from 'date-fns'
//import ReactToPrint from 'react-to-print';
import Spinner from '../../../../components/Spinner';
import { authUserHasModuleAccessMany } from "../../../../utils/auth";

import '../Waiver.scss';

const VIEW_MODULE_ID = 79;

export const UsersWaivers = (props) => {

    //#region useState/useEffect

    //const urlRef = useRef();
    const mountedRef = useRef(false);
                
    const [ error, setError ]=useState();
    //const [ printHideShow, setPrintHideShow ]=useState(false);
    const [ waiver, setWaiver ]=useState(props.user.signed_waiver_path);
    //const [ viewShow, setViewShow ]=useState(false);
    const [loading, setLoading]=useState(true);
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);

    //First load
    useEffect(()=>{
        mountedRef.current = true
        
        return()=>{
            mountedRef.current = false;
            setError()
        }
    },[]);

    // check if the logged in user has permission to see this user's documents
    useEffect(() => {
        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccessMany([VIEW_MODULE_ID], props.user_id);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }
        checkPermission();
    }, [props.user_id]);

    //After props load get ensure waiver is set
    useEffect(()=>{
        if(props && mountedRef.current===true){
            setWaiver(props.user.signed_waiver_path);
            setLoading(false);
        }
    },[props])


    //PDF Viewwer for when waivers are viewable in browser.  Should circumvent IOS not working well with PDFs
    // const MyDoc=(
    //     <Document file={waiver}>
    //         <Page pageNumber={1} />
    //     </Document>
    // )
      
    //#endregion useState/useEffect

    //#region handlers

    /*
    const handlePrintOnClick=(waiver)=>{
        setPrintHideShow(!printHideShow)
    }
    */

    //#endregion handlers


    if(loading) return(
        <SkeletonTheme color="#e0e0e0">
            <div className="mt-3 text-center" data-cy="loading-skeleton-div">
                <Skeleton height={28} width={200}/>
                <Skeleton height={16} count={4} />
            </div>
        </SkeletonTheme>
    )
    
    return (
        <Container>
        <h4 className="section-title">Documents</h4>
        <hr/>
        {userHasModulePermission===null ?
            <><Spinner /></>
        :
        !userHasModulePermission[VIEW_MODULE_ID] ?
            <p>You do not have permission to view this user's documents.</p>
        :
            <Row>
                <Col sm={12}>
                    {error && <p>{error}</p>}
                    {props && props.user.has_signed_waiver === 0 &&
                        <p className="text-center">
                            This user has no signed documents on file
                        </p>
                    }
                    {props && props.user.has_signed_waiver === 1 &&

                        <>
                            <p>
                                This user has a signed document on file
                            </p>
                        
                            <Card className="wav-card standout p-4">
                                <Card.Title>Registration Waiver</Card.Title>
                                    <a href={waiver} className="wav-a-tag" download>Download Copy</a>
                                    {/* <span onClick={e=>{handlePrintOnClick(waiver)}}className="wav-a-tag">
                                        <WaiverPrint activeWaiver={waiver} printShowHandler={()=>setPrintHideShow(!printHideShow)} printHideShow={printHideShow} />
                                    </span>
                                    <span onClick={e=>setViewShow(!viewShow)} className="wav-a-tag">
                                        {viewShow ? <span>Hide</span> : <span>View</span> }
                                    </span> */}
                                
                                    {/* <Col xs={12}>
                                        {viewShow ?
                                            <PDFViewer document={MyDoc}>

                                            </PDFViewer>
                                        :
                                            null
                                        }
                                        
                                    </Col> */}
                                    
                            </Card>
                        </>

                    }
                </Col>
            </Row>
        }
      </Container>
    
  )
}

//  const WaiverPrint=(props)=>{
    
//     const urlRef = useRef()
    
//     return(
//         <div>
//             <ReactToPrint 
//                 documentTitle="Registration Waiver"
//                 trigger={()=><button className="wav-a-tag">Print Copy</button>}
//                 content={()=> urlRef.current}
//             />
//             <div style={{ display: "none"}}>
//                 <div ref={ urlRef }>
//                     {props.activeWaiver}
//                 </div>
//             </div>
//         </div>
//     )
// }

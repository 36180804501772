import React, {useState, useEffect, useRef} from 'react';
import {Row, Col, Modal, Button, Form, InputGroup} from 'react-bootstrap';

export const Price = (props) => {
    const {click} = props;

    const ref = useRef();

    let _price = 0;
    if (props?.activeProduct?.variant_id && props?.activeProduct?.product_variants?.length>0){
        const _item = props.activeProduct.product_variants.filter(a=>a.id===props.activeProduct.variant_id)[0];
        _price = _item.price;
    } else {
        _price = props.price;
    }


    const [price, setPrice] = useState(_price);
    const [error, setError] = useState();

    const clickHandler = (price) => {
        const _error = {};
        if (price === null) _error.price = "The price is required";

        if (Object.keys(_error).length > 0) setError(_error);
        else {
            setError(null);
            if (props?.activeProduct?.variant_id && props?.activeProduct?.product_variants?.length>0){
                const _item = props.activeProduct.product_variants.filter(a=>a.id===props.activeProduct.variant_id)[0];
                _item.price = price;
                click(_item, 5);
            }
        }
    }

    useEffect(() => {
        if (ref.current && props.show) {
            ref.current.focus();
        }
    }, [props.show]);

    return (
        <Modal className="pos-modal pos-p-modal events-modal" backdropClassName="pos-p-backdrop" show={props.show} onHide={props.hide} size="sm" backdrop="static" data-cy="price-picker-modal" >
            <Modal.Header closeButton={true}>
                <Modal.Title data-cy="price-picker-modal-title">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row data-cy="price-picker-wrap">
                    <Col>
                        <Form.Group controlId="recipient_name">
                            <Form.Label>Price</Form.Label>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control 
                                    ref={ref}
                                    type="number" 
                                    placeholder="Enter a price" 
                                    value={price || ""} 
                                    onChange={e=>setPrice(+e.target.value || 0)} 
                                    onFocus={e=>e.target.select()}
                                    onKeyUp={e=>e.key==="Enter" && clickHandler(price)}
                                />
                            </InputGroup>
                            {error && error?.price && <div className="error-text mb-3">{error.price}</div>}
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Body className="p-0">
                <Button className="pos-button-checkout m-0" onClick={()=>clickHandler(price)}>Add to cart</Button>
            </Modal.Body>
        </Modal>
    );
}
import React, { useEffect, useRef, useState, useContext, createContext } from 'react';
import { useSelector} from 'react-redux';
import ReactToPrint from 'react-to-print';
import Table from 'react-bootstrap/Table';
import { format } from 'date-fns'
import { numberOfTokens, selectTimeSlot } from '../../../store/selectors';
import { convertMinutesToHoursDays } from '../../../utils/dates.js';
import { FileURLContext } from '../../../contexts/FileURLContext';

import { OrderTicket } from './OrderTicket';
import SplitPaymentReceipts from './SplitPaymentReceipts';


// The stylesheet for this component is inline because the ReactToPrint component keeps losing the styles every time prod gets rebuilt
const styles = {
    hideWrapper: {
        display: "none",
    },
    printRef: {
        width: "100%",
        fontFamily: "'Roboto', sans-serif",
        textAlign: "center",
        fontSize: "0.95rem",
        backgroundColor: "#fff",
    },
    mainReceipt: {
        marginLeft: "1rem",
        marginRight: "1.4rem",
    },
    hr: {
        color: "#aaa"
    },
    originalPrice: {
        textDecoration: "line-through",
        fontSize: "0.8rem",
        color: "#FF0000",
        paddingRight: "3px",
    },
    fullWidth: {
        paddingLeft: "0 !important",
    },
    addonIndent: {
        paddingLeft: "10px",
    },
    logo: {
        marginTop: "15px",
        marginBottom: "5px",
        textAlign: "center",
        maxWidth: "150px",
    },
    address: {
        marginBottom: "12px",
        textAlign: "center",
        fontSize: "0.9rem",
    },
    addressSpan: {
        display: "block",
        lineHeight: "1.1rem",
    },
    orderStatus: {
        marginTop: "0",
        marginBottom: "2px",
        textAlign: "center",
        fontSize: "1.3rem"
    },
    orderNum: {
        marginTop: "0",
        marginBottom: "2px",
        textAlign: "center",
        fontSize: "1.5rem",
    },
    items: {
        marginTop: "10px",
        width: "100%",
        fontSize: "1rem",
        lineHeight: "1.2rem",
    },
    itemHeader: {
        fontWeight: "700",
    },
    tableHr: {
        color: "#aaa",
        marginTop: "3px",
        marginBottom: "3px",
    },
    columnName: {
        textAlign: "left",
    },
    columnPrice: {
        textAlign: "end",
    },
    blankLine: {
        color: "#fff",
    },
    hidden: {
        display: "none",
    }
}

//Component is used in the following places:
//src\containers\User\Profile\UserOrders\DisplayOrders\DisplayOrders.js
//src\containers\POS\Payment\Payment.js
//src\containers\POS\Buttons\Buttons.js

/** Needs the following props:
 * @param items (order's items)
 * @param customer (selected user)
 * @param order_id (the order's id)
 * @param order (the full order)
 * @param transactionId
 * @param status
 * @param payment_type "Cash" or "cs", "spCashCC", "spMultiSingle", "spMultiMany", "byItems", "byAmount"
 * @param amount for split payments that allow for a flat amount to be paid
 * @param parent_order for when split payments have a parent order to display the full amount
 * @param cash
 * @param payment_change
 * @param copies
 * @param tip
 * @param companyDetails
 * @param hasProps - this is used for the POS to make sure that the print component has set all its own states before clearing redux and local storage.  
 */

//const GUEST_USER_ID = 7;
export const Print = (props) => {
    const ref = useRef(); // container of component to print
    const refP = useRef(); // react-to-print component
    const mountedRef = useRef(false);

    // const FileURLContext = createContext();
    const imageURL = useContext(FileURLContext);
    const logo = imageURL.logo;

    const currentPos = useSelector(state => state.pos[props.register_id]);
    const [items,setItems]=useState([]); 
    const tax_rate=useSelector(state => state.pos.tax_rate) || 0; 
    const adminfee_rate=useSelector(state => state.pos.adminfee_rate); 
    const [aitems, setAItems]=useState(props.items)
    const customer = props.customer
    const order_id = props.order_id
    const order = props.order
    const status = +props.status || +order?.status || null;
    const [transaction, setTransaction]=useState(props?.transactionId || null)
    const companyDetails=useSelector(state=>state.company);
    const registerInfo = useSelector(state=>state.pos[props.register_id] || null);

    const currentBooking = useSelector(state => state.serviceBooking.current_booking);
    const serviceNumberOfTokens = useSelector(numberOfTokens);
    const selectedTimeslot = useSelector(selectTimeSlot);

    const [serviceShowBookingEvent, setServiceShowBookingEvent] = useState(false);
    const [serviceEventPurchaseItem, setServiceEventPurchaseItem] = useState(<></>);

    const copies=[...Array(+props.copies || 1).keys()];

    const GUEST_USER_ID = companyDetails?.config?.guest_user_id || null;

    //check props for rerender
    
    useEffect(()=>{
        mountedRef.current = true

        //Ensuring that all the async functions and calls are done after an order(and before print) so that the order can be cleared without losing data for printing
        if(mountedRef.current && order && order_id && props.hasProps) props.hasProps(true); 

        return()=>{
            mountedRef.current = false
        }
    },[props, order, order_id]);

    useEffect(()=>{
        if(currentPos && !props.transactionId) setTransaction(currentPos.transactionId)
    },[currentPos, props.transactionId])

    useEffect(()=>{
        //const a=[...aitems].sort((a, b) => a.variant_name?.localeCompare(b.variant_name) || true); // group by variant if available
        setItems(aitems);
    },[aitems]);

    useEffect(() => {
        refP.current.handlePrint();
    },[items]);

    useEffect(() => {
		let mounted = true;

        let fauxItem = (<></>);
        if (currentBooking?.event_id && currentBooking?.service?.variant_id) {
            let tokenProducts = aitems.filter(item => item.variant_id === currentBooking.service.default_product_variant_id);
            let numberTokenProducts = tokenProducts.length;

            if (numberTokenProducts >= serviceNumberOfTokens) {
                // There is a Service Booking
                // create the faux service event to show
                setServiceShowBookingEvent(true);
                let serviceStyle = {};
                if (numberTokenProducts > serviceNumberOfTokens) {
                    serviceStyle = styles.hidden;
                }
                fauxItem = (
                    <tr key={`product-line-service-booking`} style={serviceStyle}>
                        <td>
                            {currentBooking.service.name} - {convertMinutesToHoursDays(selectedTimeslot.duration+1)}
                        </td>
                    </tr>
                );
            }
        }
        setServiceEventPurchaseItem(fauxItem);

        return () => {
            mounted = false;
        }
    },[aitems, currentBooking, serviceNumberOfTokens, selectedTimeslot]);

    // replace this with back-end total discount when available
    let total_discount = 0;

    const confirmPrint = () => {
        window.printWasCalled = true;
    }

    const findReceiptMessage=()=>{
        let message = {
            message: null,
            location: "top"
        };
        if(registerInfo?.registerInfo?.register_definition?.receipt_message) message.message = registerInfo.registerInfo.register_definition.receipt_message;
        else if (registerInfo?.register_group?.register_group_definition?.receipt_message) message.message = registerInfo?.register_group?.register_group_definition?.receipt_message;
        else if(companyDetails?.config?.receipt_message) message.message = companyDetails.config.receipt_message;
        if(registerInfo?.registerInfo?.register_definition?.receipt_message) message.location = registerInfo.registerInfo.register_definition.receipt_message_location || "top";
        else if(registerInfo?.register_group?.register_group_definition?.receipt_message_location) message.location = registerInfo?.register_group?.register_group_definition?.receipt_message_location || "top";
        else if(companyDetails?.config?.receipt_message_location) message.location = companyDetails.config.receipt_message_location || "top";
        
        return message;
    }

    const receiptMessage = findReceiptMessage();

    return (
        <>
            <ReactToPrint 
                ref={refP}
                content={() => ref.current}
                onBeforePrint={confirmPrint}
                onAfterPrint={props.onAfterPrint || null}
            />
            <div style={styles.hideWrapper}>
                <div ref={ref} style={styles.printRef}>
                    
                    <>
                    {/*copies.map(c=>(*/
                    
                        <div key={`prt-cnt-${"c"}`}>

                            <div style={styles.mainReceipt}>
                                <img src={logo} alt={`${companyDetails?.name?.toUpperCase()}` || "SITEBOSS"} style={styles.logo} />
                                <div style={styles.address}>
                                    <span style={styles.addressSpan}>{companyDetails?.address_street}</span>
                                    <span style={styles.addressSpan}>{companyDetails.address_city ? `${companyDetails.address_city},` : null} {companyDetails?.address_state} {companyDetails?.address_postalcode}</span>
                                    <span style={styles.addressSpan}>{companyDetails?.email}</span> {/* website is not included in the company call, replaced with email*/}
                                </div>
                                {receiptMessage?.message && receiptMessage?.location === "top" &&
                                    <p>
                                        {receiptMessage?.message}
                                    </p>
                                }
                                <div style={styles.orderStatus}>
                                    {+props.status===1 &&
                                        <b>NOT PAID</b>
                                    }
                                    {+props.status===2 &&
                                        <b>COMPLETE</b>
                                    }
                                    {+props.status===3 &&
                                        <b>CANCELLED</b>
                                    }
                                    {+props.status===4 &&
                                        <b>REFUNDED</b>
                                    }
                                    {order?.payment_method &&
                                        <b> 
                                        : {order?.payment_method?.toUpperCase()}
                                        </b>  
                                    }
                                    {(props.payment_type==="spCashCC" || props.payment_type==="spMultiSingle" || props.payment_type==="spMultiMany") ?
                                        <b>
                                           {" "} Split Payment
                                        </b>
                                        : 
                                        null
                                    }
                                </div>
                                <div style={styles.orderNum}>
                                    {order_id &&
                                        <>
                                            <b>Order #{order_id}</b><br/>
                                        </>
                                    }
                                    {transaction &&
                                        <>
                                            <b>Transaction # {transaction}</b>
                                            <br/>
                                        </>
                                    }
                                </div>
                                <div style={styles.date}>{props.updateDate ? <span>Rawr {format(new Date(props.updateDate), "MM/dd/yyyy h:mm aa")}</span> : <span>{format(new Date(), "MM/dd/yyyy hh:mm aa")}</span>}</div>
                                {/*!props.show && selected_location?.[0]?.id ?
                                    <React.Fragment>
                                        <h5 style={{width:"380px"}}>{selected_location?.[0]?.name || selected_location?.[0]?.user_name || "Tab #"+selected_location?.[0]?.id}</h5>
                                    </React.Fragment>
                                : null */}
                                {customer && customer.id !== GUEST_USER_ID &&
                                    <div style={styles.customer}>
                                        Customer: {customer.first_name + " " + customer.last_name}
                                    </div>
                                }
                                <table style={styles.items}>
                                    <thead>
                                        <tr style={styles.itemHeader}>
                                            {props?.payment_type === "byAmount" ?
                                                <th style={styles.columnName}>Original Items</th>
                                            :
                                                <th style={styles.columnName}>Item</th>
                                            }
                                            <th style={styles.columnPrice}>Price</th>
                                        </tr>
                                    </thead>

                                    {/* display the faux service event */}
                                    {serviceShowBookingEvent && serviceEventPurchaseItem}

                                    {aitems?.length>0 &&
                                        <React.Fragment>
                                            <tbody >
                                                <tr>
                                                    <td colSpan={2}>
                                                        <hr style={styles.hr} />
                                                    </td>
                                                </tr>
                                            {aitems.map((item,i) => (
                                                <tr key={`product-line-${i}`}>
                                                    <td style={styles.columnName}>
                                                        {((item.parent_id && item.parent_id!==item.product_id) || item?.addon) && 
                                                            <span style={styles.addonIndent}>• {" "}</span>
                                                        }
                                                        {item.product_name}
                                                        {item.variant_id 
                                                            && (item.parent_id===item.product_id || item.parent_id===null)
                                                            && item.variant_name.trim().toLowerCase()!==item.product_name.trim().toLowerCase()
                                                            && item.variant_name.trim().toLowerCase()!=="default"
                                                            && item.variant_name.trim().toLowerCase()!=="" &&
                                                                <>
                                                                    {" "}-{" "}<span>{item.variant_name}</span>
                                                                </>
                                                        }
                                                        {item?.giftcard &&
                                                            <>
                                                                <br/>
                                                                {" "}-{" "}<span>For: {item.giftcard.full_name} ({item.giftcard.email}).</span><br/>
                                                                {" "}-{" "}<span>Deliver On: {format(new Date(item.giftcard.delivery_date), "MM/dd/yyyy")}</span>
                                                            </>
                                                        }
                                                    </td>
                                                    <td style={styles.columnPrice}>
                                                        <div>
                                                            {item.product_price!==null && +item.original_price>=0 &&
                                                                <>
                                                                    {+item.product_price < +item.original_price &&
                                                                        <span style={styles.originalPrice}><s>${(+item.original_price).toFixed(2)}</s></span>
                                                                    }
                                                                    <span>${(+item.product_price).toFixed(2)}</span>
                                                                </>
                                                            }
                                                            {!!item.base_price && 
                                                                <> 
                                                                    {+item.final_price < +item.base_price &&
                                                                        <span style={styles.originalPrice}><s>${(+item.base_price).toFixed(2)}</s></span>
                                                                    }
                                                                    <span>${+item.final_price>0 ? (+item.final_price).toFixed(2) : '0.00'}</span>
                                                                </>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                                <tr>
                                                    <td colSpan={2}>
                                                        <hr style={styles.tableHr} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            
                                            <tfoot>
                                                {(props.payment_type==="spCashCC" || props.payment_type==="spMultiSingle" || props.payment_type==="spMultiMany") ?
                                                    <SplitPaymentReceipts 
                                                        styles={styles}
                                                        payment_type={props.payment_type}
                                                        payment_cash={props.payment_cash}
                                                        payment_change={props.payment_change}
                                                        order={props.order}
                                                        payee={props.payee}
                                                        status={status}
                                                        adminFeeRate={adminfee_rate}
                                                        cardPayment={props.cardPayment}
                                                    />
                                                    :
                                                    <>
                                                        <tr style={order?.coupons?.length>0 ? {} : styles.hidden}>
                                                            <td style={styles.columnName}>You Saved</td>
                                                            <td style={styles.columnPrice}><div>${order?.total_discount?.toFixed(2)}</div></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={styles.columnName}>
                                                                <span>Subtotal</span>
                                                            </td>
                                                            <td style={styles.columnPrice}>${order?.subtotal_price?.toFixed(2)}</td>
                                                        </tr>
                                                        {order?.tip>0 && 
                                                            <tr >
                                                                <th style={styles.columnName}>Tip</th>
                                                                <th style={styles.columnPrice}>${order?.tip?.toFixed(2)}</th>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td style={styles.columnName}>Tax ({tax_rate.toFixed(1)}%)</td>
                                                            <td style={styles.columnPrice}>${order?.tax_total?.toFixed(2)}</td>
                                                        </tr>
                                                        {order?.price_adjustments?.map((adjustment, i)=>(
                                                            <tr key={`pa-order-${i}`}>
                                                                <td style={styles.columnName}>{adjustment.price_adjustment_type_name}</td>
                                                                <td style={styles.columnPrice}>
                                                                    {adjustment.amount > 0 ? "" : "-"}
                                                                    ${Math.abs(adjustment.amount).toFixed(2)}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {/*(props?.payment_type !== "cs" || props?.payment_type !=="Cash") &&
                                                            <tr>
                                                                <td style={styles.columnName}>Administrative Fee ({adminfee_rate.toFixed(1)}%)</td>
                                                                <td style={styles.columnPrice}>
                                                                    {props?.payee?.order ? 
                                                                        <span>{props?.payee?.order?.admin_fee_total?.toFixed(2)}</span>
                                                                        :
                                                                        <span>${order?.admin_fee_total?.toFixed(2)}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        */}
                                                        <tr style={styles.fullWidth}>
                                                            <th colSpan={2}>
                                                                <hr />
                                                            </th>
                                                        </tr>
                                                        {(props.payment_type==="spCashCC" || props.payment_type==="spMultiSingle" || props.payment_type==="spMultiMany") ?
                                                            null
                                                        :
                                                            <tr>
                                                                <th style={styles.columnName}>Total</th>
                                                                <th style={styles.columnPrice}>${order?.total_price?.toFixed(2)}</th>
                                                            </tr>
                                                        }
                                                        {status===2 && (props?.payment_type === "cs" || props?.payment_type === "Cash" || props?.payment_type === "cash") &&
                                                            <React.Fragment>
                                                                <tr>
                                                                    <th style={styles.columnName}>Payment</th>
                                                                    <th style={styles.columnPrice}>${order?.total_price?.toFixed(2)}</th>
                                                                </tr>
                                                                <tr>
                                                                    <th style={styles.columnName}>Change</th>
                                                                    <th style={styles.columnPrice}>${props?.payment_change?.toFixed(2) || 0.00}</th>
                                                                </tr>
                                                            </React.Fragment>                            
                                                        }
                                                        <tr>
                                                            {/* Blank row to try to get a margin at the bottom of the receipt */}
                                                            <th style={styles.blankLine}>{". "}</th>
                                                            <th style={styles.blankLine}>{". "}</th>
                                                        </tr>
                                                    </>
                                                }
                                            </tfoot>
                                        </React.Fragment>
                                    }
                                </table>
                                {receiptMessage?.message && receiptMessage?.location === "bottom" &&
                                    <p>
                                        {receiptMessage?.message}
                                    </p>
                                }
                            </div>
                                                     
                            {props.register_id && (props.register_id === 3 || props.register_id === 5) && props?.kitchenTicket !==false &&
                                <>
                                <div style={{pageBreakBefore: "always"}}>
                                    <div className="page-break" />
                                    <OrderTicket items={aitems} orderId={order_id} customer={props.customer}/>
                                </div>
                                </>
                            }
                        </div>
                    /*))*/}
                    </>
                    {/*<Preview print={1} {...props}/>*/}
                </div>
            </div>
            
        </>
    );
}
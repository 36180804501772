import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from 'react-bootstrap/Container';
import { useSelector } from "react-redux";

import List from "./List";
import Event from "./Event";

const Register = (props) => {

    let params = useParams();
    const defaultPatronRegisterId = useSelector(state =>state.pos.register)
    const register_id = props.register_id || defaultPatronRegisterId;

    const eventId = new URLSearchParams(window.location.search).get("event");
    const show = useSelector(state => state.pos[register_id].show_event_register);

    return (
        <React.Fragment>
            <CssBaseline />
            { show ?
            <section className="membership-wrap events-container">
                <div data-cy="event-register-top-card">
                    {eventId ? <Event id={eventId} /> : <List />}   
                </div>
            </section>
            : null }
        </React.Fragment >
    );
}

export default Register;
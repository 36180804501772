import React from 'react';
import {Row, Col} from 'react-bootstrap';
import '../OrderPage.scss';

const OrderPriceDetails = ({currentOrder=null}) => {
    if (!currentOrder) return null;

    return (
        <Row className="order-price-details">
            <Col sm={12}>
                <p className="price-pair">
                    <span>Subtotal Price:</span>
                    <span className="text-right" data-cy="order-subtotal">${currentOrder?.subtotal_price?.toFixed(2)}</span>
                </p>
                {/*
                <p className="price-pair">
                    <span>Admin Fee Total:</span>
                    <span className="text-right" data-cy="order-adminFee">${currentOrder?.admin_fee_total ? currentOrder?.admin_fee_total?.toFixed(2) : "0.00"}</span>
                </p>
                {currentOrder.admin_fee_refunded > 0 &&
                    <p className="price-pair">
                        <span>Admin Fee Refunded:</span>
                        <span className="text-right" data-cy="order-adminFee-refunded">${currentOrder?.admin_fee_refunded?.toFixed(2)}</span>
                    </p>
                }
                */}
                {currentOrder.tip > 0 &&
                    <p className="price-pair">
                        <span>Tip:</span>
                        <span className="text-right" data-cy="order-tip">${currentOrder.tip ? currentOrder?.tip?.toFixed(2) : "0.00"}</span>
                    </p>
                }
                <p className="price-pair">
                    <span>Tax Total:</span>
                    <span className="text-right" data-cy="order-tax-total">${currentOrder.tax_total ? currentOrder?.tax_total?.toFixed(2) : "0.00"}</span>
                </p>
                {currentOrder?.price_adjustments?.map((adjustment, i)=>(
                    <p key={`pa-order-${i}`} className="price-pair">
                        <span data-cy="order-adjustment-type">{adjustment.price_adjustment_type_name}</span>
                        <span className="text-right" data-cy="order-adjustment-value">
                            {adjustment.amount > 0 ? "" : "-"}
                            ${Math.abs(adjustment.amount).toFixed(2)}
                        </span>
                    </p>
                ))}
                <p className="price-pair">
                    <span>Shipping Total:</span>
                    <span className="text-right" data-cy="order-shipping-total">${currentOrder?.shipping_total ? currentOrder?.shipping_total?.toFixed(2) : "0.00"}</span>
                </p>
                <p className="price-pair">
                    <span>Total Price:</span>
                    <span className="text-right" data-cy="order-total-price">${currentOrder?.total_price?.toFixed(2)}</span>
                </p>
                <p className="price-pair">
                    <span>Total Payments:</span>
                    <span className="text-right" data-cy="order-total-payments">${currentOrder?.payment_total?.toFixed(2)}</span>
                </p>
                <p className="price-pair">
                    <span>Pending Balance:</span>
                    <span className="text-right" data-cy="order-pending-price">${(currentOrder?.total_price - currentOrder?.payment_total).toFixed(2)}</span>
                </p>
            </Col>
        </Row>
    );
}

export default OrderPriceDetails
import React,{useState,useEffect,useRef} from "react";
import { useSelector,useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import NumericInput from "react-numeric-input2";

import ErrorCatcher from "../../../components/common/ErrorCatcher";
import { Coupons as CouponPicker } from "../Preview/Coupons";
import { convertMinutesToHoursDays } from '../../../utils/dates.js';
import { unsetServiceBooking, refreshOrder, updateCart } from '../../../utils/thunks';
import { selectTimeSlot, numberOfTokens } from '../../../store/selectors';
import Print from "../Print";
import Confirmation from "../Confirmation";
import Pos from "../../../api/Pos";
import Users from "../../../api/Users";
import States from "../../../api/States";
import Products from "../../../api/Products";
import * as actions from "../../../store/actions";

import "./Payment.scss";
import { useCallback } from "react";

const INTERVAL_RUN = 5000;
let TYPES_REQUIRING_USER_SELECTED = [1, 3, 4, 5, 6, 7, 9];
let TYPES_REQUIRING_NON_GUEST_ACCOUNTS = [1, 3, 9];

const Payment = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();
    
    const ref = useRef();
    const refTotal = useRef();

    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [print,setPrint]=useState(false);
    const [cash,setCash]=useState();
    const [change,setChange]=useState(0);
    const [showCash,setShowCash]=useState(false);
    const [showAddr, setShowAddr] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [transactionId, setTransactionId] = useState();
    const [transactionStatus, setTransactionStatus] = useState(6);
    const [paymentType,setPaymentType] = useState();
    const [coupons, setCoupons] = useState([]);
    const [memo, setMemo] = useState("");
    const [requireCustomer, setRequireCustomer] = useState(false);
    const [requireNonGuest, setRequireNonGuest] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");
    const [statesOrProvincesList, setStatesOrProvincesList] = useState([]);
    const [containsRecurringSub, setContainsRecurringSub] = useState(false);
    const [customerPermission, setCustomerPermission] = useState(false);
    const [couponModalShow,setCouponModalShow] = useState(false);
    const [savedBooking, setSavedBooking] = useState();     // this is used to store booking data after it has been deleted from redux
    const [message, setMessage] = useState("");
    const [printerHasProps, setPrinterHasProps]=useState(false)
    const [addressAccordionOpen, setAddressAccordionOpen] = useState(false);

    const tax_rate = useSelector(state => state.pos.tax_rate) || 0;
    const adminfee_rate = useSelector(state => state.pos.adminfee_rate);
    const active_register=useSelector(state => state.pos.register);

    const items = useSelector(state => state.pos[props.register_id].items);
    const customer = useSelector(state => state.pos[props.register_id].user);
    const order_id = useSelector(state => state.pos[props.register_id].order);
    const order = useSelector(state => state.pos[props.register_id].orderAll);
    const tip = useSelector(state => state.pos[props.register_id].tip);

    const currentBooking = useSelector(state => state.serviceBooking.current_booking);
    const selectedTimeslot = useSelector(selectTimeSlot);
    const serviceNumberOfTokens = useSelector(numberOfTokens);
    const company=useSelector(state=>state.company);    

    const DEFAULT_GUEST_USER_ID = company?.config?.guest_user_id || null;

    //const DEFAULT_GUEST_USER_ID = 7;


//#region useEffect

    /**
     * requires a user to be selected if items includes a product of type:
     * 1 (subscription)
     * 3 (digital)
     * 4 (service)
     * 5 (class/event)
     * 6 (bundle)
     * 7 (rental)
     * 9 (token)
     */
    useEffect(() => {
        let mounted = true;

        if(items.find(item => TYPES_REQUIRING_USER_SELECTED.includes(item.product_type_id))) {
            setRequireCustomer(true);
        } else {
            setRequireCustomer(false);
        }
        if(items.find(item => TYPES_REQUIRING_NON_GUEST_ACCOUNTS.includes(item.product_type_id))) {
            setRequireNonGuest(true);
        } else {
            setRequireNonGuest(false);
        }
        items.forEach(item => {
            if(item.product_type_id === 1 && !containsRecurringSub) {
                Products.Variants.get({id: item.variant_id})
                .then( response => {
                    if(mounted && response.data[0].bill_interval !== "y") {
                        setContainsRecurringSub(true);
                    }
                }).catch(e => console.error(e));
            }
        })

        return () => mounted = false;
    },[items, containsRecurringSub]);

    useEffect(()=>{
        const checkTrx = async () =>{
            if (transactionStatus === 6 && transactionId){
                let res2=await Pos.payment.terminalStatus({"transaction_id": transactionId});
                if (res2.errors){
                    setError(<ErrorCatcher error={res2.errors} />);
                    if(res2.errors?.transaction?.success === false || res2.data[0]?.transaction_status_id===8) {
                        setTransactionStatus(8);
                    } else {
                        setTransactionStatus(4);
                    }
                } else {
                    setSuccess({
                        ...(res2.data[0]),
                        transaction_id: transactionId,
                    });
                    if (res2.data[0]?.transaction_status_id===7){
                        setPrint(true);
                        clearInterval(interval) //after transaction comes back successful, no need to query excessively.  Was previously being cleared when everything unmounted on refresh that is now unnecessary.  
                        // send to db
                    }
                    setTransactionStatus(res2.data[0]?.transaction_status_id);
                }
            }
        }

        checkTrx();

        const interval = setInterval(() => {
            checkTrx();
        }, INTERVAL_RUN);

        return () => clearInterval(interval);
    },[transactionId, transactionStatus]);

    const processCreditCardEntry = useCallback((token) => {
        setError(null);
        setSubmitting(true);
        Pos.payment.card(
            {
                "type": "sale",
                "payment_token": token.token,
                "user_id" : (props.user_id || customer?.id || DEFAULT_GUEST_USER_ID),
                "order_id": order.id || null,
                "location_id": 1,
                "description": "card transaction",
                "first_name": firstName,
                "last_name": lastName,
                "bill_address1": address1,
                "bill_address2":address2,
                "bill_city": city,
                "bill_state": state,
                "bill_postalcode": postalCode,
                "store_payment_info": 1,
                "memo": memo,
            }
        ).then(res => {
            if (!res.errors && res.data){
                dispatch(actions.transaction(res.data[0].transaction_id, props.register_id));
                setSuccess({
                    ...(res.data[0]),
                    transaction_id: res.data[0].transaction_id,
                });
                dispatch(refreshOrder(props.register_id))
                .then(()=>{
                    setSubmitting(false);
                    setTransactionStatus(res.data[0].transaction_status_id);
                    if(res.data[0].transaction_status_id===7) setPrint(true);
                })
            } else {
                setError(<ErrorCatcher error={res.errors || res.message || "Server error occurred in Payment"} />);
                setSubmitting(false);
            }
        }).catch(e => {
            setError(<ErrorCatcher error={e} />);
            setSubmitting(false);
        });
    },[props, customer, order, address1, address2, city, state, postalCode, firstName, lastName, dispatch, memo, DEFAULT_GUEST_USER_ID]);

    useEffect(()=>{    
        window.CollectJS.configure({
            variant: "lightbox",
            theme: 'bootstrap',
            callback: (token) => processCreditCardEntry(token)
        });
    },[processCreditCardEntry]);

    // get customer address whenever a customer is selected
    useEffect(() => {
        let mounted = true;

        statesOrProvincesHandler();

        if(customer) {
            setFirstName(customer.first_name);
            setLastName(customer.last_name);

            Users.address({id: customer.id})
            .then(response => {
                if(mounted) {
                    if(response.data) {
                        setAddress1(response.data[0].address1);
                        setAddress2(response.data[0].address2);
                        setCity(response.data[0].city);
                        setState(response.data[0].state);
                        setPostalCode(response.data[0].postal_code);
                        setCountry(response.data[0].country);
                    }else {
                        setAddress1("");
                        setAddress2("");
                        setCity("");
                        setState("");
                        setPostalCode("");
                        setCountry("");
                    }
                }
            }).catch(e => console.error(e));
        }

        return () => mounted = false;
    },[customer]);

    // if this is for patron checkout, always be in CC info mode and get customer options
    useEffect(() => {
        let mounted = true;

        if(props.patron) {
            ccInfoClickHandler();

            // get current user from local storage
            Users.get({})
            .then( response => {
                if(mounted) {
                    dispatch(actions.selectCustomer({
                        id: response.data.profile.id,
                        first_name: response.data.profile.first_name,
                        last_name: response.data.profile.last_name
                    }, props.register_id));
                }
            }).catch(e => console.error(e));
        }

        return () => mounted = false;

    // not going to risk with messing with payment by adding more dependencies without very thorough testing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.patron, props.register_id, dispatch]);

    // This is not where this belongs, but I'm not sure where to put it that will be able to access state and return an object
    const exportBooking = useCallback(() => {
        let booking = null;
        if (currentBooking.service) {
            booking = {
                service_name: currentBooking.service.name,
                location_name: currentBooking.location_names[currentBooking.selected_location],
                timeslot_start: selectedTimeslot.start,
                timeslot_end: selectedTimeslot.end,
            };
        }
        return booking;
    }, [currentBooking, selectedTimeslot]);

    useEffect(() => {
        if (success && transactionStatus===7) {
            // use the if statement to prevent the deletion from happening before the save
            if (savedBooking) {
                dispatch(unsetServiceBooking());
            } else {
                setSavedBooking(exportBooking());
            }
        }
    },[success, transactionStatus, savedBooking, dispatch, exportBooking]);

    //Once the printer has props, clear the localstorage and redux
    useEffect(()=>{
        if(printerHasProps===true && success){
            Pos.local.remove(`POS-${props.register_id}`);
            Pos.local.remove(`POS-${props.register_id}-order-id`);
            dispatch(actions.reset(props.register_id));
            setPaymentType();
        };
        return()=>setPrinterHasProps(false);
    },[printerHasProps, success, dispatch, props.register_id]);

//#endregion useEffect

//#region Handlers

    // tip select calculation
    const tipSelectHandler=(e)=>{
        dispatch(actions.setTip((+order.total_price * +e.target.hash.substr(1) / 100).toFixed(2), props.register_id));
    }

    // tip input
    const tipChangeHandler=(e)=>{
        dispatch(actions.setTip(e, props.register_id));
    }

    // cash input
    const cashChangeHandler=(e)=>{
        setCash(+e);
        setChange(+e - (+order.total_price - +order.admin_fee_total));
        
        if (refTotal.current) refTotal.current.innerHTML = "$"+ (+order.total_price - +order.admin_fee_total).toFixed(2);
        //document.getElementById("payment-order-total").innerHTML = "$"+ order.total_price.toFixed(2);
    }

    // pay with cash button click
    const cashClickHandler = () => {
        setShowAddr(false);
        setShowCash(true);
        setPaymentType("cs");
        //document.getElementById("payment-order-total").innerHTML = "$"+ (order.total_price.toFixed(2) - order.admin_fee_total.toFixed(2)).toFixed(2);
        if (refTotal.current) refTotal.current.innerHTML = "$"+ (+order.total_price - +order.admin_fee_total).toFixed(2);
    }

    const ccInfoClickHandler = () => {
        setShowCash(false);
        setShowAddr(true);
        setPaymentType("cc");
        if (refTotal.current) refTotal.current.innerHTML = "$"+ order.total_price.toFixed(2);
        //document.getElementById("payment-order-total").innerHTML = "$"+ order.total_price.toFixed(2);
        
    }

    const currentStateHandler = event => {
        setState(event.target.value);
    }

    const statesOrProvincesHandler = async country => {
        /* let source = null;
        let placeholder = null; */
        let list = [];
      
        /* switch(country) {
            case "CA":
                source = Provinces.get();
                placeholder = "Province";
                break;
            default:
            case "US":
                source = States.get();
                placeholder = "State";
                break;
        } */

        let source = States.get();
        let placeholder = "State";
      
        list.push(<option key="state_placeholder" value="" disabled> { placeholder } </option>)
        source.map( item => (
            list.push(<option key={ item.short_name } value={ item.short_name }> { item.name } </option>)
        ))
      
        setStatesOrProvincesList(list);
    }

    const handleCouponModalOpen = (e) => {
        setCouponModalShow(true);
    }

    const handleCouponModalClose = (e) => {
        setCouponModalShow(false);
    }

    // form submit
    const submitHandler = useCallback(async (e,type) => {
        const form = e.currentTarget;

        e.preventDefault();
        setSubmitting(true);
        setValidated(true);
        setError(null);
        setSuccess(null);
        
        if (!type) type=paymentType;
        
        //setPaymentType(e.nativeEvent.submitter.dataset.type);
        if (form.checkValidity() === true) {
            switch(type){
                case "cs":
                    await Pos.payment.cash({
                        "user_id" : (props.user_id || customer?.id || DEFAULT_GUEST_USER_ID)+"",
                        "order_id": order_id || null,
                        "memo": memo,
                        "location_id": 1,
                        "tender_amount": cash,
                    }).then( res => {
                        if ( res.data && res.data[0].transaction_id ) {
                            setSuccess("Done!");
                            dispatch(actions.transaction(res.data[0].transaction_id, props.register_id));
                            dispatch(refreshOrder(props.register_id))
                            .then(()=>{
                                setTransactionStatus(7);
                                setPrint(true);
                            })
                        } else {
                            setError(<ErrorCatcher error={res.errors} />);
                        }
                    }).catch( e => {
                        console.error(e);
                        setError(<ErrorCatcher error={e} />);
                    });
                    break;
                case "cc":
                    setShowCash(false);
                    // this code will check memo if the first characters equal "BYPASS" and if so, it will bypass the CC entry screen
                    if (memo.substr(0,6).toUpperCase()==="BYPASS") {
                        processCreditCardEntry({ token: '00000000-000000-000000-000000000000' }); // NMI test token
                    } else {
                        window.CollectJS.startPaymentRequest();
                    }
                    break;
                case "sc":
                    setShowCash(false);
                    setShowAddr(false);
                    if (!transactionId){
                        await Pos.payment.terminal({
                            "poi_device_id": props.terminalDeviceId,
                            "type": "sale",
                            "user_id": (props.user_id || customer?.id || DEFAULT_GUEST_USER_ID),
                            "order_id": order_id || null,
                            "location_id": 1,
                            "store_payment_info": 1,
                            "memo": memo
                        }).then( res => { 
                            if ((res.status===200 || res.status===202) && res.data){ // 
                                setTransactionId(res.data[0]);
                                dispatch(actions.transaction(res.data[0], props.register_id));

                                setSuccess({
                                    auth_code: "In Flight",
                                    nmi_transaction_id: "",
                                    transaction_id: res.data[0],
                                });
                            } else setError(<ErrorCatcher error={res.errors || "Unknown error when using payment terminal"} />);
                        }).catch( e => {
                            console.error(e);
                            setError(<ErrorCatcher error={e} />);
                        });
                    } else {
                        // if theres a transaction id. use it and prompt for new card
                        setTransactionStatus(6);
                        setSuccess({
                            transaction_status_id: 6,
                            transaction_status: "In Flight",
                            transaction_id: transactionId
                        });
                    }
                    break;
                default:
                    /* if (form.checkValidity() === true) {
                        setSuccess("Done!");
                        setPrint(true);
                    } */
                    break;
            }
        }
        setSubmitting(false);
        setValidated(false);
    }, [paymentType, transactionId, order_id, memo, customer, cash, props.user_id, props.register_id, props.terminalDeviceId, dispatch, processCreditCardEntry, DEFAULT_GUEST_USER_ID]);

    // close button click after payment confirm
    const closeHandler = (e, force = false) =>{

        e.preventDefault();

        if (transactionStatus === 7 || force) {
            Pos.local.remove(`POS-${props.register_id}`)
            Pos.local.remove(`POS-${props.register_id}-order-id`)
            .then(async a => {
                let res = await Pos.local.get(`POS-NUMTABS-${props.register_id}`);
                if (res){
                    if (!Array.isArray(res)) res=[res];
                    const new_numtabs = res.filter(a => +a.id !== +order_id);
                    Pos.local.save(`POS-NUMTABS-${props.register_id}`, new_numtabs);
                }
                dispatch(actions.reset(props.register_id));
                props.onClose();
                // history.go(0);
            });
        } else {
            props.onClose();
        }
    }

//#endregion Handlers

    if (success){
        return (
        <Container fluid>
            {print && !props.patron && 
            
                <Print 
                    register_id={props.register_id}
                    payment_type={paymentType} 
                    payment_cash={cash} 
                    payment_change={+change} 
                    copies={props.copies || "1"} 
                    tip={tip} 
                    items={items}
                    customer={customer}
                    order_id={order_id}
                    order={order}
                    hasProps={setPrinterHasProps}
                    status={2}
                />
            }
            <Row>
                <Col>
                    {(!transactionId || transactionStatus === 7) ?
                        <h1>Thank You!</h1>
                    : null}
                    {(transactionStatus === 8) ?
                        <h1>Oops!</h1>
                    : null}
                    {(transactionStatus === 4) ?
                        <h1>Canceled</h1>
                    : null}
                    {(transactionId && transactionStatus===6) ?
                        <h1>Swipe Card</h1>
                    : null}
                </Col>
            </Row>
            <Row>
                <Col ref={ref}>
                    {transactionId===6 ? <Spinner animation="border" /> : null}
                    {message ?
                        <p>{message}.</p>
                    : (!transactionId || transactionStatus===7) ?
                        <p data-cy="transaction-status-successful">Your payment was successful.</p>
                    : transactionStatus === 8 ?
                        <p data-cy="transaction-status-card-failed">Card transaction failed. Retry with a different card or different payment method.</p>
                    : transactionStatus === 4 ?
                        <p data-cy="transaction-status-card-canceled">{error ? error.message : "Card transaction canceled."}</p>
                    : null}
                </Col>
            </Row>
            {(transactionStatus===7) &&
            <Row>
                <Col>
                    <Confirmation booking={savedBooking} />
                </Col>
            </Row>
            }
            <Row>
                <Col>
                    {(transactionId && transactionStatus === 6) ?
                        <p>Press Cancel on Card Terminal to cancel transaction.</p>
                    : null}
                    <Button variant="primary" onClick={closeHandler} type="button" className={`mt-4 ${transactionId && transactionStatus === 6 ? " submitting" : ""}`} disabled={transactionId && transactionStatus === 6}>
                        {(!transactionId || transactionStatus!==6) ?
                            "Close" : "Waiting..."
                        }
                    </Button>
                </Col>
            </Row>
        </Container>);
    }

    // This should be moved somewhere but not sure where - needs access to both cart state and booking state
    let fauxItem = (<></>);
    let serviceShowBookingEvent = false;
    if (currentBooking?.event_id && currentBooking?.service?.default_product_variant_id) {
        let tokenProducts = items.filter(item => item.variant_id === currentBooking.service.default_product_variant_id);
        let numberTokenProducts = tokenProducts.length;
        let servicePrice = +tokenProducts[0]?.product_price * serviceNumberOfTokens;

        if (numberTokenProducts >= serviceNumberOfTokens) {
            // There is a Service Booking
            // create the faux service event to show
            serviceShowBookingEvent = true;
            fauxItem = (
                <Row key={`product-line-service-booking`}>
                    <Col sm="9" className="summary-product-name">
                        {currentBooking.service.name} - {convertMinutesToHoursDays(selectedTimeslot.duration+1)}
                    </Col>
                </Row>
            );
        }
    }

    let shoppingCartSummaryItems = "";
    if (props.patron) {
        shoppingCartSummaryItems = (
            <div className="summary-box">
                {fauxItem}
                {items.map((item,i) =>  {
                    return (
                        <Row key={`product-line-${i}`}>
                            <Col sm="9" className="summary-product-name">
                                {item.product_name}
                            </Col>
                            <Col sm="3" className="summary-product-price">
                                ${item.product_price.toFixed(2)}
                            </Col>
                        </Row>
                    )}
                )}
            </div>
        );
    }

    return (
        <Container fluid>
            { requireCustomer && (!(props.user_id || customer?.id) || (requireNonGuest && customer?.is_guest)) && !props.patron ?
                // there is no customer selected but one of the order items requires it
                <div className="no-customer-warning">
                    <h5>This order contains items that must be associated with an account.</h5>
                    <h5>Please select a user before checking out.</h5>
                    {!requireNonGuest ?
                        <h5>You can use a guest account for this transaction.</h5>
                    :
                        <h5>You CANNOT use a guest account - subscriptions and tokens MUST be associated with a live user.</h5>
                    }
                    <div>
                        <Button onClick={props.onClose}>OK</Button>
                    </div>
                </div>
            : 
            <>
            {!!order && order?.items && order?.items.length>0 ?
                <Form noValidate validated={validated} onSubmit={submitHandler}>

                    {/* TIP section */}
                    {!props.patron &&
                        <Form.Group>
                            <Form.Label>Tip</Form.Label>
                            <div className="tip-wrapper">
                                <Button variant="light" data-cy="payment-tip-10" onClick={tipSelectHandler} href="#10">10%</Button>
                                <Button variant="light" data-cy="payment-tip-15" onClick={tipSelectHandler} href="#15">15%</Button>
                                <Button variant="light" data-cy="payment-tip-20" onClick={tipSelectHandler} href="#20">20%</Button>
                                <Button variant="light" data-cy="payment-tip-other" onClick={tipSelectHandler} href="#0">Other</Button>
                                <InputGroup>
                                    <i className="far fa-arrow-right prepend-arrow"/>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text><small>$</small></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <NumericInput
                                        data-cy="payment-tip-input"
                                        className="form-control dollar-input"
                                        name="tip"
                                        onChange={tipChangeHandler}
                                        value={+tip}
                                        min={0}
                                        precision={2}
                                    />                                        
                                </InputGroup>
                            </div>
                        </Form.Group>
                    }

                    {/* DIVIDER */}
                    {!props.patron && <hr/>}


                    <Row>
                        <Col sm="12" lg="8">
                            <Form.Label>{ !props.patron ? "Payment" : "Billing Info"}</Form.Label>

                            {/* PAYMENT TYPE */}
                            { !props.patron &&
                                <div className="payment-wrapper">
                                    <Button
                                        variant={(paymentType==="cs"?"primary":"light")}
                                        type="button"
                                        onClick={cashClickHandler}
                                        data-type="cs"
                                        data-cy="payment-cash-button"
                                        disabled={submitting}
                                        className={`${submitting?" submitting":""}`}
                                    >
                                        Cash
                                    </Button>
                                    <Button
                                        variant={(paymentType==="sc"?"primary":"light")}
                                        type="button"
                                        data-type="sc"
                                        data-cy="payment-scancard-button"
                                        disabled={submitting}
                                        className={`${submitting?" submitting":""}` }
                                        onClick={(e) => {
                                            if (+order.total_price===0) {
                                                e.preventDefault();
                                                setError(<ErrorCatcher
                                                    error="There has been an error with this order, please refresh the page and try again."
                                                    onClose={() => setError()}
                                                />);
                                            } else {
                                                setPaymentType("sc");
                                                // document.getElementById("payment-order-total").innerHTML = "$"+ order.total_price.toFixed(2);
                                                submitHandler(e,"sc");
                                            }
                                        }}
                                    >
                                        Scan Card
                                    </Button>
                                    <Button
                                        variant={(paymentType==="cc"?"primary":"light")}
                                        type="button"
                                        onClick={(e) => {
                                            if (+order.total_price===0) {
                                                e.preventDefault();
                                                setError(<ErrorCatcher
                                                    error="There has been an error with this order, please refresh the page and try again."
                                                    onClose={() => setError()}
                                                />);
                                            } else {
                                                ccInfoClickHandler(e);
                                            }
                                        }}
                                        data-type="cc"
                                        data-cy="payment-entercard-button"
                                        disabled={submitting}
                                        className={`${submitting?" submitting":""}`}
                                    >
                                        Enter Card #
                                    </Button>
                                </div>
                            }

                            {/* CASH PAYMENT */}
                            <Form.Group style={{display:showCash?"block":"none"}}>
                                <Form.Label>Pay with Cash</Form.Label>
                                <div className="cash-wrapper">
                                    <Button variant="light" className="pos-denom-btn" data-cy="payment-denom-1" onClick={e=>cashChangeHandler(1)} disabled={order?.total_price > 1}>
                                        $1
                                    </Button>
                                    <Button variant="light" className="pos-denom-btn" data-cy="payment-denom-5" onClick={e=>cashChangeHandler(5)} disabled={order?.total_price > 5}>
                                        $5
                                    </Button>
                                    <Button variant="light" className="pos-denom-btn" data-cy="payment-denom-10" onClick={e=>cashChangeHandler(10)} disabled={order?.total_price > 10}>
                                        $10
                                    </Button>
                                    <Button variant="light" className="pos-denom-btn" data-cy="payment-denom-20" onClick={e=>cashChangeHandler(20)} disabled={order?.total_price > 20}>
                                        $20
                                    </Button>
                                    <Button variant="light" className="pos-denom-btn" data-cy="payment-denom-50" onClick={e=>cashChangeHandler(50)} disabled={order?.total_price > 50}>
                                        $50
                                    </Button>
                                    <Button variant="light" className="pos-denom-btn" data-cy="payment-denom-100" onClick={e=>cashChangeHandler(100)} disabled={order.total_price > 100}>
                                        $100
                                    </Button>
                                    <Button variant="light" data-cy="payment-denom-other" onClick={e=>cashChangeHandler(0)}>Other</Button>
                                    <InputGroup>
                                        <i className="far fa-arrow-right prepend-arrow"/>                                                       
                                        <InputGroup.Prepend>
                                            <InputGroup.Text><small>$</small></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <NumericInput
                                            className="form-control cy-cash-input dollar-input"
                                            name="cash"
                                            onChange={cashChangeHandler}
                                            value={cash}
                                            min={0}
                                            precision={2}
                                        />
                                    </InputGroup>
                                </div>
                                {(cash>0 && +change<0) &&
                                    <div className="error-text text-center">
                                        Cash entered is less than the total amount.
                                    </div>
                                }
                            </Form.Group>

                            {/* CARD PAYMENT */}
                            <Form.Group style={{display:showAddr?"block":"none"}}>
                                {props.register_id && !props.patron &&
                                <>
                                <Accordion className="enter-card-wrapper">
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        <p className="text-center">
                                                <span className="bold">Optional: </span>
                                                Click / Tap here to enter name and address
                                                fields for additional fraud protection.
                                        </p>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                        <Row>
                                            <Col sm="12" lg="6">
                                                <Form.Label>First Name</Form.Label>
                                                <Form.Control type="text" name="first_name" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First Name" />
                                            </Col>
                                            <Col sm="12" lg="6">
                                                <Form.Label>Last Name</Form.Label>
                                                <Form.Control type="text" name="last_name" value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last Name" />
                                            </Col>
                                            <Col sm="12" lg="6">
                                                <Form.Label>Address Line 1</Form.Label>
                                                <Form.Control type="text" name="bill_address1" value={address1} onChange={e => setAddress1(e.target.value)} placeholder="Address" />
                                            </Col>
                                            <Col sm="12" lg="6">
                                                <Form.Label>Address Line 2</Form.Label>
                                                <Form.Control type="text" name="bill_address2" value={address2} onChange={e => setAddress2(e.target.value)} placeholder="Address line 2 (optional)" />
                                            </Col>
                                            <Col sm="12" lg="6">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control  type="text" name="bill_city" value={city} onChange={e => setCity(e.target.value)} placeholder="City" />
                                            </Col>
                                            <Col sm="12" lg="6">
                                                <Form.Label>State</Form.Label>
                                                <Form.Control custom as="select" className="form-select" name="bill_state" value={state || ""} onChange={currentStateHandler}>
                                                    {statesOrProvincesList}
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Accordion>
                                <Row>
                                    <Col sm="12" lg="6">
                                        <Form.Label>Postal Code</Form.Label>
                                        <Form.Control required={showAddr} type="text" name="bill_postal_code" value={postalCode} onChange={e => setPostalCode(e.target.value)} placeholder="Postal Code" />
                                    </Col>
                                </Row>
                                </>
                                }
                                {active_register && props.patron && 
                                    <>
                                        <Form.Row>
                                            <Form.Control required={showAddr} type="text" name="first_name" value={firstName}
                                                onChange={e => setFirstName(e.target.value)} placeholder="First Name" />
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Control required={showAddr} type="text" name="last_name" value={lastName}
                                                onChange={e => setLastName(e.target.value)} placeholder="Last Name" />
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Control required={showAddr} type="text" name="bill_address1" value={address1}
                                                onChange={e => setAddress1(e.target.value)} placeholder="Address" />
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Control type="text" name="bill_address2" value={address2}
                                                onChange={e => setAddress2(e.target.value)} placeholder="Address line 2 (optional)" />
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Control required={showAddr} type="text" name="bill_city" value={city}
                                                onChange={e => setCity(e.target.value)} placeholder="City" />
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Control required={showAddr} custom as="select" className="form-select" name="bill_state" value={state || ""}
                                                onChange={currentStateHandler}>
                                                {statesOrProvincesList}
                                            </Form.Control>
                                        </Form.Row>
                                        <Form.Row>    
                                            <Form.Control required={showAddr} type="text" name="bill_postal_code" value={postalCode} onChange={e => setPostalCode(e.target.value)} placeholder="Postal Code" />
                                        </Form.Row>
                                    </>
                                }
                            </Form.Group>

                            {/* MEMO */}
                            { !props.patron &&
                                <Form.Group>
                                    <Row>
                                        <Col>
                                            <Form.Label>Memo</Form.Label>
                                            <Form.Control data-cy="payment-memo" type="text" name="memo" value={memo} onChange={e => setMemo(e.target.value)} />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            }
                        
                        </Col>

                        {/* SUMMARY - on right side */}
                        <Col sm="12" lg="4" className="text-right order-summary-wrapper">
                            {props?.patron && <Form.Label>Shopping Cart Summary</Form.Label>}
                            {shoppingCartSummaryItems}

                            <div className="payment-total-grid">

                                <div className="grid-row-wrapper">
                                    <span>Subtotal:</span>
                                    <span data-cy="payment-subtotal">${order?.subtotal_price?.toFixed(2)}</span>
                                </div>

                                <div className="grid-row-wrapper">
                                    <span>Tax ({tax_rate?.toFixed(1)}%):</span>
                                    <span data-cy="payment-tax">${(+order?.tax_total).toFixed(2)}</span>
                                </div>

                                <div className="grid-row-wrapper">
                                    <span>Admin. Fee ({adminfee_rate?.toFixed(1)}%):</span>
                                    <span data-cy="payment-adminfee">${(+order?.admin_fee_total).toFixed(2)}</span>
                                </div>

                                {showCash &&
                                <div data-cy="payment-cash-discount" className="grid-row-wrapper">
                                    <span>Cash Discount:</span>
                                    <span className="subtract">- ${(+order?.admin_fee_total).toFixed(2)}</span>
                                </div>
                                }

                                {!props.patron &&
                                <div 
                                    data-cy="payment-discount-link"
                                    className={`grid-row-wrapper total-discount cp ${order?.coupons?.length>0 ? '' : 'hidden'}`} 
                                    onClick={handleCouponModalOpen}
                                >
                                    <span>Discounts:</span>
                                    <span data-cy="payment-discount-amount">${order?.total_discount?.toFixed(2)}</span>
                                </div>
                                }

                                {!props.patron &&
                                <div data-cy="payment-tip" className="grid-row-wrapper">
                                    <span>Tip:</span>
                                    <span>${(+order?.tip).toFixed(2)}</span>
                                </div>
                                }

                                <div data-cy="payment-total" className="grid-row-wrapper total break-above">
                                    <span>TOTAL:</span>
                                    <span ref={refTotal} id="payment-order-total" data-cy="payment-total">${order?.total_price?.toFixed(2)}</span>
                                </div>

                                {showCash &&
                                <>
                                    <div data-cy="payment-cash-received" className="grid-row-wrapper break-above bold-number">
                                        <span>Cash Received:</span>
                                        <span>${(cash || 0).toFixed(2)}</span>
                                    </div>
                                    <div data-cy="payment-change" className="grid-row-wrapper bold-number">
                                        <span>CHANGE:</span>
                                        <span>{(+change > 0 || !change) ? '$' + (+change || 0).toFixed(2) : ''}</span>
                                    </div>
                                </>
                                }
                            </div>
                            
                            <Form.Group className="mt-4 hidden">
                                <Form.Control type="text" name="coupon_code" placeholder="Coupon Code" defaultValue={""} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" className="mt-3 p-0 d-flex">
                            <Button
                                variant="primary"
                                type="submit"
                                data-type="print"
                                data-cy="payment-submit-button"
                                disabled={submitting || !paymentType}
                                className={`btn-complete ${submitting?" submitting":""}`}
                            >
                                Complete Sale
                            </Button>
                            <Button
                                variant="light"
                                onClick={(e)=>closeHandler(e, true)}
                                type="button"
                                data-type="save"
                                data-cy="payment-save-button"
                                disabled={submitting}
                                className={`btn-cancel w-auto${submitting?" submitting":""}`}
                            >
                                Save
                            </Button>
                            <Button
                                variant="light"
                                onClick={props.onClose}
                                data-cy="payment-cancel-button"
                                type="button"
                                data-type="close"
                                disabled={submitting}
                                className={`btn-cancel w-auto${submitting?" submitting":""}`}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                    {error}
                </Form>
                :
                <div className="mb-1" data-cy="no-order">
                    <h4>Whoa!</h4>
                    {!order ?
                        <>No order currently started.  Please start an order.</>
                        :
                        <>No items in order.  Please add items to order.</>
                    }
                    <div className="text-center mt-2"><Button onClick={props.onClose}>OK</Button></div>
                </div>
            }
            </>
            }
            {
                print && !props.patron && 
                <Print 
                    registerId={props.register_id}
                    payment_type={paymentType} 
                    payment_cash={cash} 
                    payment_change={+change} 
                    copies={props.copies || "1"} 
                    tip={tip} 
                    items={items}
                    customer={customer}
                    order_id={order_id}
                    order={order}
                    hasProps={setPrinterHasProps}
                />
            }
            <Modal centered show={containsRecurringSub && !customerPermission} backdrop="static" className="pop-up-alert" >
                <Alert variant={"warning"} >
                    <div>
                        <p><h4>{"Customer Confirmation Required"}</h4></p>
                        <p className="bold">{"Please confirm that the customer acknowledges this card will be charged for all purchased subscriptions until cancelled."}</p>
                    </div>
                    <Button variant={"danger"} onClick={ props.onClose }>Cancel</Button>
                    <Button variant={"success"} onClick={ () => setCustomerPermission(true) }>Accept</Button>
                </Alert>
            </Modal>
            <CouponPicker
                register_id={props.register_id}
                title="Available Discounts" 
                show={couponModalShow} 
                hide={handleCouponModalClose}
            />
        </Container>
    );
}
export default Payment;
import React, {useState} from 'react';
import {ButtonGroup, Button, InputGroup, FormControl} from 'react-bootstrap';

import styles from './Tip.module.scss';

const tipAmounts = [0, 10, 15, 20];

export const Tip = (props) => {
    const {onTipChange} = props;

    const [showOther, setShowOther] = useState(false);

    const clickHandler = (e, tip) => {
        e.preventDefault();
        e.stopPropagation();
        setShowOther(false);
        onTipChange(tip);
    }
    const otherClickHandler = (e) => {
        setShowOther(true);
    }

    // note to self: floating points in js suck!!!!
    return (
        <>
            <label className="form-label">Tip</label>
            <ButtonGroup aria-label="Tip" className={styles.buttons}>
                {tipAmounts.map((a,i)=>(
                    <Button 
                        key={`tip-amount-${i}`} 
                        variant="light" 
                        disabled={props.submitting}
                        className={+a.toFixed(2) === +((props?.order?.tip * 100 / props?.order?.subtotal_price).toFixed(2) || 0) && !showOther  ? "active" : null} 
                        onClick={e=>clickHandler(e, a)}
                        data-cy={`payment-tip-${a}`}
                    >
                        {!a ? "No Tip" : `${a}%`}
                    </Button>
                ))}
                <Button variant="light" className={showOther ? "active": ""} disabled={props.submitting} onClick={otherClickHandler} data-cy="payment-tip-other">Other</Button>
                <InputGroup style={{display:showOther?"flex":"none"}}>
                    <i className="far fa-arrow-right prepend-arrow"/>
                    <InputGroup.Prepend>
                        <InputGroup.Text><small>$</small></InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        data-cy="payment-tip-input"
                        type="number"
                        name="tip"
                        defaultValue={props?.order?.tip || 0}
                        min={0}
                        precision={2}
                        disabled={props.submitting}
                        onChange={e=>onTipChange(e.target.value, true)}
                    />
                </InputGroup>
            </ButtonGroup>
        </>
    );
}
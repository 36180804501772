import React,{useState,useEffect} from 'react';
import { useSelector } from 'react-redux';
import { toCamelCase, getPageById } from '../../../../../../../utils/cms';

export const XRay = React.forwardRef((props, ref) => {
    const cmsSelector = useSelector(state => state.cms);    
    const [value, setValue] = useState(props?.display_name || props.element_id);

    let is_text = false, XRayComponent = "div";    
    if (props[props.element_id]?.innerText){
        is_text = true;
        //XRayComponent = props.element_id;
    } 

    // ultimate source of thruth is the properties array since this is what we can change in the toolbar
    let _style={}, _classes="", _other={};
    props?.properties?.filter(a=>a?.changed && a?.value).forEach(a => {
        if (a.prop_type==="styles") _style[toCamelCase(a.name.trim())]=a.value;
        else if (a.prop_type==="classes") _classes+=`${a.value.trim()} `;
        //else if (a.prop_type==="value") value = a.value.trim();
        else _other[a.name.trim()] = a.value;
    });

    // get the name of the content block
    useEffect(() => {
        switch(props.element_id){
            case "ContentBlock":
                const cbid = props.properties.filter(a=>a.name === "contentblock_id" && a.value)[0]?.value;
                if (cbid){
                    getPageById({id: cbid}).then(res=>{
                        if (res?.[0]?.title) setValue(`${props.element_id} - ${res[0].title}`);
                    });
                }
                break;
            case "contentBlock":
                if (props[props.element_id]?.id){
                    getPageById({id: props[props.element_id]?.id}).then(res=>{
                        if (res?.[0]?.title) setValue(`${props.element_id} - ${res[0].title}`);
                    });
                }
                break;
            default:
                break;
        }
    }, [props]);

    return (
        <XRayComponent id={props.id} ref={ref} style={{/*..._style,*/...props.style}} className={`${props.index?"xray-element":""} type-${props.element_type} ${cmsSelector.currentElement === props.id ? "active-element" : ""} ${props.className || ""} ${/*_classes*/""}`.trim().replace(/\s+/g,' ')} onClick={props?.onClick || null} data-handler-id={props["data-handler-id"]}>
            {XRayComponent!=="div" && value}
            {XRayComponent==="div" &&
                <>
                    {props.index>0 && <span>{value}</span>}                    
                    {props.children}
                </>
            }
            {/*`i:${props.index}`*/}
        </XRayComponent>
    );
});
import * as actionTypes from './actionTypes';

export const setSplitPaymentErrors = (errors)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_ERRORS,
        errors: errors
    };
};

export const setSplitPaymentCashSuccess=(cashSuccess)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_CASH_SUCCESS,
        cashSuccess: cashSuccess
    };
};

export const setSplitPaymentCCSuccess=(creditCardSuccess)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_CC_SUCCESS,
        creditCardSuccess: creditCardSuccess
    };
};

export const setSplitPaymentSubmitting=(submitting)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_SUBMITTING,
        submitting: submitting
    };
};

export const setSplitPaymentRequiresUser=(requiresUser)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_REQUIRES_USER,
        requiresUser: requiresUser
    }
}

export const setSplitPaymentRemaining=(remainder)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_REMAINDER,
        remainder: remainder
    };
};

export const setSplitPaymentCashValue=(cashValue)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_CASH_VALUE,
        cashValue: cashValue,
    }
}

export const setSplitPaymentForUser=(forUser)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_FORUSER,
        forUser: forUser
    }
}

export const setSplitPaymentPayees=(payees)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_PAYEES,
        payees: payees
    }
}

export const setSplitPaymentAllPaid=(allPaid)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_ALL_PAID,
        allPaid:allPaid
    }
};

export const setSplitPaymentAllItemsAssigned=(allItemsAssigned)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_ALL_ITEMS,
        allItemsAssigned: allItemsAssigned
    };
};

export const setOrdersHaveBeenSplit=(ordersSplit)=>{
    return{
        type:actionTypes.SET_ORDERS_HAVE_BEEN_SPLIT,
        ordersSplit: ordersSplit
    };
};

export const setSplitPaymentAllOrders=(allOrders)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_ALL_ORDERS,
        allOrders: allOrders
    };
};

export const setSplitPaymentSplitType=(paymentType)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_SPLIT_TYPE,
        paymentType: paymentType
    }
};

export const setSplitPaymentOriginalItems=(originalItems)=>{
    return{
        type: actionTypes.SET_SPLIT_PAYMENT_ORIGINAL_ITEMS,
        originalItems: originalItems
    }
}

export const resetSplitPayments=()=>{
    return{
        type:actionTypes.RESET_SPLIT_PAYMENT_STATE
    }
};
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Container, Row, Col, Button as BsButton, Card} from 'react-bootstrap';

import Button from '../Button';

import FormGroupText from '../../../../FormGroupText';
import APICoupons from '../../../../../../../../api/Coupons';
import { updateCart } from '../../../../../../../../utils/thunks';
import * as actions from '../../../../../../../../store/actions';

import styles from "./Coupon.module.scss";

const Coupon = (props) => {
    const dispatch = useDispatch();

    const couponRef = useRef();

    const xxx = useSelector(state => state.pos?.[props.register_id]);

    const order = useSelector(state => state.pos?.[props.register_id]?.orderAll);
    const user = useSelector(state => state.auth.user);
    const items = useSelector(state => state.pos?.[props.register_id]?.items);
    const coupons_selected = useSelector(state => state.pos?.[props.register_id]?.coupons_selected);
    const coupon_codes = useSelector(state => state.pos?.[props.register_id]?.coupon_codes);
    
    const [isNonCombinableSelected, setIsNonCombinableSelected] = useState(false);
    const [selected, setSelected] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        console.log(xxx)
    }, [xxx]);


    useEffect(() => {
        // loop through coupons
        // if a coupon is currently selected that is not combinable, then all the others should be disabled
        let isNonCombinable = false;
        order?.coupons?.forEach(coupon => {
            if (coupon.combinable===0 && coupons_selected.includes(coupon.id)) {
                isNonCombinable = true;
            }
        });
        setIsNonCombinableSelected(isNonCombinable);
    },[coupons_selected, order?.coupons]);

    const handleSelectCoupon = useCallback(async coupon => {
        const _selected = JSON.parse(JSON.stringify(coupons_selected));
        const index = _selected.findIndex(id=>+id===+coupon.id);
        if (index<0) _selected.push(coupon.id);
        else _selected.splice(index, 1);
        dispatch(actions.setCouponsSelected([..._selected], props.register_id));
        //await new Promise(resolve => setTimeout(resolve, 1000)); // I know this is bad, gimme a break I'm sick
        setSelected(_selected);
    }, [coupons_selected, dispatch, props.register_id]);

    useEffect(() => {
        dispatch(updateCart(props.register_id));
        new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
            dispatch(actions.setUpdated(true, props.register_id));
        });
    }, [selected, dispatch, props.register_id]);

    const applyCouponHandler = async (e) => {
        e.preventDefault()
        const _value = couponRef?.current?.value?.trim();
        if (_value){
            const res = await APICoupons.verifyCode({
                coupon_code: _value, 
                user_id: user.profile?.id || null, 
                product_variant_ids: items?.map(item => item.variant_id) || []
            });
            if (res.errors) setError(res.errors);
            if (res?.data?.length>0) {
                setError(null);
                handleSelectCoupon(res?.data?.[0]);
                //console.log(res)
            }
        }
    }

    if (!props.register_id) return null;

    return (
        <Container fluid>
            <Row>
                <Col>
                    <FormGroupText 
                        inputRef={couponRef}
                        page_id={props.page_id} 
                        name="coupon_code" 
                        type="text" 
                        label="Coupon Code" 
                        feedback="Please enter a valid code" 
                        disabled={props.submitting}
                        value={""} 
                        append={
                            <BsButton variant="light" size="small" onClick={applyCouponHandler}>Apply</BsButton>
                        }
                    />
                    {error && <div className="error-text mb-3">{error}</div>}                    
                </Col>
            </Row>
            {order?.coupons?.length>0 &&
                <Row>
                    <Col>
                        {order?.coupons?.map(coupon => {
                            let selected = !!coupons_selected.includes(coupon.id);
                            let disabled = (!selected && isNonCombinableSelected) || (!selected && coupon.combinable===0 && coupons_selected.length>0);
                            return (
                                <Button {...props} active={selected} disabled={disabled} key={`coupon-${coupon.id}`} onClick={()=> !disabled ? handleSelectCoupon(coupon) : ()=>{}}>
                                    <Card.Body className={styles.container}>
                                        <div className="bold">{coupon.name}</div>
                                        {coupon.description!=="" &&
                                            <div className="small">{coupon.description}</div>
                                        }
                                        {!coupon.combinable &&
                                            <div>(Cannot be combined with other discounts.)</div>
                                        }
                                    </Card.Body>
                                </Button>
                            )
                        })}
                    </Col>
                </Row>
            }
        </Container>
    );
}

export default Coupon;
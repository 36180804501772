import React, { useState, useEffect, useRef, useCallback } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Container, Card, Button } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import SubHeader from '../../../components/common/SubHeader';
import ErrorCatcher from '../../../components/common/ErrorCatcher';
import { getFeatures } from '../PermissionsUtils/PermissionUtils';

import './Features.scss';

export const Features = () => {
    
    const history = useHistory();
    const mountedRef = useRef(false);
    const [ features, setFeatures ]=useState([]);
    const [ selectedFeature, setSelectedFeature]=useState(null);
    const [ moduleOrVariant, setModuleOrVariant ]=useState("");
    const [ error, setError ]=useState();
    const [ success, setSucces ]=useState();
    const [ loading, setLoading ]=useState(false);
    const adminDash = JSON.parse(localStorage.getItem("adminDash"))

    useEffect(()=>{
        mountedRef.current = true

        const getFeaturesGet = async()=>{
            let response = await getFeatures({includeModules: true, includeVariants: true});
            if(mountedRef.current && response.data){
                let sorted = response.data.sort((a,b)=>(a.sort_order < b.sort_order ? -1 : a.sort_order > b.sort_order))
                setFeatures(sorted)
                setLoading(false)
            }else if(response.errors){
                setError(<ErrorCatcher error={response.errors} />)
                setLoading(false)
            }
        }

        getFeaturesGet();

        return () => mountedRef.current = false
    },[]);

    const handleActive = (feature, type)=>{
        if(selectedFeature?.id === feature.id && moduleOrVariant === type){
            setSelectedFeature(null)
            setModuleOrVariant("")
        }else{
            setSelectedFeature(feature)
            setModuleOrVariant(type)
        }
    }

    if(loading) return(
        <SkeletonTheme color="#e0e0e0">
            <div className="mt-3 text-center">
                <Skeleton height={28} width={200}/>
                <Skeleton height={16} count={4} />
            </div>
        </SkeletonTheme>
    )

    // create the breadcrumbs array
    const breadcrumbs = [
        { linkAs: Link, linkProps: { to: "/p/home" }, text: "Home" }
    ];
    // if admin dashboard, add the admin dashboard link to the breadcrumbs
    if (adminDash) {
        breadcrumbs.push({ linkAs: Link, linkProps: { to: "/p/permission/admin" }, text: "Permission Dashboard" })
    }
    // add the current page to the breadcrumbs
    breadcrumbs.push({ text: "Feature Dashboard" })

    return (
        <Container fluid className="feature-wrapper">
            <SubHeader items={breadcrumbs} />
            <Card className="content-card">
                <div className="feature-headings">
                    {error}
                    {success}
                    <span>
                        <h4 className="section-title">
                            Feature Dashboard
                        </h4>
                    </span>
                    <span>
                        <Button 
                            className="outline-tertiary"
                            onClick={()=>history.push("/p/features/sort")}
                        >
                            Re-Order Features
                        </Button>
                        <Button 
                            className="outline-secondary"
                            onClick={()=>{history.push("/p/module/dashboard")}}
                        >
                            View All Modules
                        </Button>
                        <Button
                            onClick={()=>{history.push('/p/features/new')}}
                        >
                            New Feature
                        </Button>
                    </span>
                </div>
                {features.length>0 && !loading && features.map((feature)=>(
                    <div className="each-feature" key={`feature-row-${feature.id}`}>
                        <div className="btn-row">
                            <span>
                                {feature.name}
                            </span>
                            <Button 
                                onClick={()=>{history.push(`/p/features/${feature.id}`)}}
                                className="edit-btn"
                            >
                                <i className="far fa-edit" />
                                Edit
                            </Button>
                            <div className="feature-modules">
                                <Button 
                                    className={selectedFeature?.id === feature.id ? moduleOrVariant==="modules" ? "primary" : "opacity" : "outline-primary"}
                                    onClick={()=>handleActive(feature, "modules")}
                                >
                                    {selectedFeature?.id === feature.id
                                    && moduleOrVariant==="modules" ? 
                                        <i className="far fa-chevron-down" /> 
                                    : 
                                        <i className="far fa-chevron-right" />
                                    }
                                    Associated Modules
                                </Button>
                            </div>
                            <div className="feature-variants">
                                <Button
                                    className={selectedFeature?.id === feature.id ? moduleOrVariant==="variants" ? "primary" : "opacity" : "outline-primary"}
                                    onClick={()=>handleActive(feature, "variants")}
                                >
                                    {selectedFeature?.id === feature.id 
                                    && moduleOrVariant==="variants" ? 
                                        <i className="far fa-chevron-down" /> 
                                    : 
                                        <i className="far fa-chevron-right" />
                                    }
                                    Associated Variants
                                </Button>
                            </div>
                        </div>
                        <div className="module-feature-lists">
                            {selectedFeature?.id === feature.id 
                            && feature?.modules?.length > 0 
                            && moduleOrVariant==="modules" &&
                                <ul>
                                    {feature?.modules?.map((module)=>(
                                        <li key={`module-list-${module.id}`}>
                                            {module.name}
                                        </li>
                                    ))}
                                </ul>
                            }
                            {selectedFeature?.id === feature?.id 
                            && feature?.modules?.length === 0 
                            && moduleOrVariant==="modules" &&
                                <ul>
                                    <li>
                                        No Modules Associated
                                    </li>
                                </ul>
                            }
                            {selectedFeature?.id === feature.id && feature?.product_variants?.length > 0 && moduleOrVariant==="variants" && 
                                <ul>
                                    {feature?.product_variants?.map((variant)=>(
                                        <li key={`variant-list-${variant?.id}`}>
                                            {variant.product_name} - {variant.product_variant_name}
                                        </li>
                                    ))}
                                </ul>
                            }
                            {selectedFeature?.id === feature.id && feature?.product_variants?.length === 0 && moduleOrVariant==="variants" &&
                                <ul>
                                    <li>
                                        No Product Variants
                                    </li>
                                </ul>
                            }
                        </div>
                    </div>
                ))}
            </Card>
        </Container>
    )
}

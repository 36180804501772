import React from 'react';
import { randomUUID } from '../../../../../utils/cms';

const Image = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    let Component = "img";
    let _props={
        id: props.is_preview ? `preview-${randomUUID()}` : props.id,
        className: `Image_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' '),
        style: {display:"block",...(props?.style || {})},
        src: props?.src || "https://via.placeholder.com/250",
        alt: props?.alt || "",
    }
    if (props?.width) _props.width=props.width;
    if (props?.height) _props.height=props.height;
    if (props?.image_align) {
        switch(props.image_align){
            case "left":
                _props.style = {..._props.style, marginRight:"auto"};
                break;
            case "right":
                _props.style = {..._props.style, marginLeft:"auto"};
                break;
            case "center":
                _props.style = {..._props.style, marginLeft:"auto", marginRight:"auto"};
                break;
            default:
                break;
        }
    }

    if (props.url) {
        return (
            <a ref={ref} href={!props.is_preview && props.url? props.url : "#!"} target={props.target || "_self"}  onClick={preview_click} 
                    style={{...props.style, margin:0,padding:0,border:0,background:"transparent",boxShadow:"none"}}>
                <Component {..._props}/>
            </a>
        );
    } else return <Component ref={ref} onClick={preview_click} {..._props} />;
    
});

export default Image;
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const CityBlocks = () => {

    return (
        <React.Fragment>
             <div className="org-desc">
                <Row>
                    <Col xs="8" sm md="7" className="pd-10"><span className="tabs-she-text">07:00 AM - 08:00 AM</span></Col>
                    <Col xs="4" sm md="5" className="pd-10"><span className="tabs-she-text">Workout</span></Col>
                </Row>
            </div>
            <div className="blue-desc">
                <Row>
                    <Col xs="8" sm md="7" className="pd-10"><span className="tabs-she-text">12:00 AM - 02:00 AM</span></Col>
                    <Col xs="4" sm md="5" className="pd-10"><span className="tabs-she-text">Toms Resturant</span></Col>
                </Row>
            </div>
            <div className="blue-desc">
                <Row>
                    <Col xs="8" sm md="7" className="pd-10"><span className="tabs-she-text">12:00 AM - 02:00 AM</span></Col>
                    <Col xs="4" sm md="5" className="pd-10"><span className="tabs-she-text">Toms Resturant</span></Col>
                </Row>
            </div>
            <div className="blue-desc">
                <Row>
                    <Col xs="8" sm md="7" className="pd-10"><span className="tabs-she-text">12:00 AM - 02:00 AM</span></Col>
                    <Col xs="4" sm md="5" className="pd-10"><span className="tabs-she-text">Toms Resturant</span></Col>
                </Row>
            </div>
            <br></br>
        </React.Fragment>


    )
}
import React, { useRef, useState, useEffect } from 'react';
import {Container, Button} from 'react-bootstrap';

export const WebcamCapture = (props) => {
    const {onCapture, onStreamReady} = props;

    const videoRef = useRef();
    const containerRef = useRef();

    const [disableWebcam, setDisableWebcam] = useState(false);

    useEffect(() => {
        if (!disableWebcam && videoRef.current) {
            if (navigator.mediaDevices.getUserMedia && videoRef.current) {
                navigator.mediaDevices.getUserMedia({ video: {width: { ideal: 1920 }, height: { ideal: 1080 }} }).then(stream => {
                    videoRef.current.srcObject = stream;
                    if (onStreamReady) onStreamReady(stream, videoRef.current);
                }).catch(err => {
                    setDisableWebcam(true);
                    console.error(err);
                });
            }
        }
    }, [onStreamReady, disableWebcam]);

    useEffect(() => {
        const video = videoRef.current;

        return () => {
            if (video) {
                const stream = video.srcObject;
                if (stream) {
                    const tracks = stream.getTracks();
                    tracks.forEach(track => {
                        track.stop();
                    });
                }
            }
        };
    }, []);

    const captureImage = () => {
        /*const video = videoRef.current;
        const canvas = document.createElement('canvas');
        canvas.width = video.offsetWidth;
        canvas.height = props.height || video.offsetHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);*/

        const scaleFactor = 4;

        const video = videoRef.current;
        const canvas = document.createElement('canvas');
        const displayWidth = video.offsetWidth;
        const displayHeight = props.height || video.offsetHeight;
    
        canvas.width = displayWidth * scaleFactor;;
        canvas.height = displayHeight * scaleFactor;;
        const ctx = canvas.getContext('2d');    
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const videoRatio = videoWidth / videoHeight;
        const displayRatio = displayWidth / displayHeight;
    
        let sx = 0;
        let sy = 0;
        let sWidth = videoWidth;
        let sHeight = videoHeight;
    
        if (videoRatio > displayRatio) {
            const scaledVideoWidth = videoHeight * displayRatio;
            sx = (videoWidth - scaledVideoWidth) / 2;
            sWidth = scaledVideoWidth;
        } else {
            const scaledVideoHeight = videoWidth / displayRatio;
            sy = (videoHeight - scaledVideoHeight) / 2;
            sHeight = scaledVideoHeight;
        }
    
        ctx.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, displayWidth * scaleFactor, displayHeight * scaleFactor);
    
        if (onCapture) onCapture(canvas.toDataURL('image/jpeg', 0.85));
    };

    if (disableWebcam) {
        return (
            <Container fluid>
                <small>Webcam access was denied. Please enable it in your browser settings to use this feature.</small>
            </Container>
        );
    }

    return (
        <Container fluid ref={containerRef}>
            <video ref={videoRef} autoPlay className={`${props?.video?.classNames || "w-100"}`} height={props.height || undefined} style={{objectFit: 'cover'}} />
            <Button variant="light" onClick={captureImage} className={`${props?.button?.classNames || "w-100"}`}>Capture Image</Button>
        </Container>
    );
};
import * as actionTypes from '../actions/actionTypes';

const initialState = { 
    items: [],
    selected_roots:null,
    selected_items:[],
    attendees:[],
    step:null,
};
  
const mapReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_TIMELINE:
            return {
                ...state,
                items: action.items,
            };
        case actionTypes.SELECT_MAP_ROOT:
            return {
                ...state,
                selected_roots: action.selected_roots,
            };
        case actionTypes.SELECT_MAP_ITEMS:
            return {
                ...state,
                selected_items: action.selected_items===null ? [] : [...state.selected_items, action.selected_items],
            };
        case actionTypes.DESELECT_MAP_ITEMS:
            return {
                ...state,
                selected_items: [...state.selected_items].filter( item => item.id !== action.selected_item.id )
            };
        case actionTypes.EVENT_WIZARD_STEP:
            return {
                ...state,
                step: action.step,
            };
        case actionTypes.EVENT_TYPE:
            return {
                ...state,
                event_type: action.event_type,
            };
        case actionTypes.EVENT_ADD_ATTENDEE:
            return {
                ...state,
                attendees: action.attendees,
            };
        case actionTypes.EVENT_REMOVE_ATTENDEE:
            return {
                ...state,
                attendees: [...state.attendees.filter(item=>item.id!==action.id)],
            };
        case actionTypes.EVENT_SAVE_SPOT:
            let updated_items=[...state.selected_items];
            updated_items.forEach(item=>{
                if (+item.id===+action.spot.location_id) item.booking=action.spot
            })
            return {
                ...state,
                selected_items: updated_items,
            };
            
        default:
            return state;
    }
}

export default mapReducer;
import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

import APIProducts from '../../../../api/Products';
import * as actions from '../../../../store/actions';

import usePrevious from '../../../../components/common/CustomHooks'
import styles from "./Types.module.scss";

export const Types = (props) => {
    const dispatch = useDispatch();

    const [types, setTypes] = useState([]);
    const [defaultTypes, setDefaultTypes] = useState([]);
    const [activeType, setActiveType] = useState(useSelector(state => state.pos[props.register_id].type));

	useEffect(() => {        
        const _getTypes = async () => {
            if (props.type_id){
                const res = await APIProducts.Types.get({id: props.type_id});
                if (res.data) {
                    setTypes(res.data);
                    setDefaultTypes(res.data);
                }
            }
        }
        if (props.type_id) _getTypes();
	}, [props.type_id]);

    useEffect(()=>{
        return ()=>{
            setTypes([]);
            setDefaultTypes([]);
        }
    },[]);

    const clickHandler = (id) =>{
        dispatch(actions.activeType(id, props.register_id));
        setActiveType(id);
    }

    if (!props.type_id) return null;

    return (
        <>
            <div className={styles["category-buttons"]}>
                {types && types?.length>0 &&
                    <Button as="a" size="sm" variant={(!activeType || activeType===-1)?"primary":"light"} data-cy="btn-category-all" className="btn-category" onClick={()=>clickHandler(0)}>
                        All Types
                    </Button>
                }
                {types && types?.map((item,i)=> (
                    <Button as="a" size="sm" variant={+activeType===+item.id?"primary":"light"} data-cy="btn-category" className="btn-category" key={item.id+"-"+i} onClick={()=>clickHandler(item.id)}>
                        {item.name}
                    </Button>
                ))}
            </div>
        </>
    );
}
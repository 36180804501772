import Request from './Api';


const publicGet = async (props) => {
    if (props?.event_types){
        if (props.event_types && typeof props.event_types === "string"){
            if (!(props.event_types.startsWith("[") && props.event_types.endsWith("]"))) props.event_types = [props.event_types];
            else JSON.parse(props.event_types);
        }
    }

    return(
        Request({
            url:"/public/event",
            data: props,
            method: "POST",
        })
    );
}

// Get event(s) - these are split into different endpoints because each [can] have different permissions attached

const getSingle = async (props) => {
    return (
        Request({
            url: "/event/" + props.id,
            method: "GET"
        })
    );
}

const getSimple = async (props) => {
    return (
        Request({
            url: "/event",
            method: "POST",
            data: props
        })
    );
}

const getDetail = async (props) => {
    return (
        Request({
            url: "/event/detail",
            method: "POST",
            data: props
        })
    );
}

const getForUser = async (props) => {
    return (
        Request({
            url: "/event/user",
            method: "POST",
            data: props
        })
    );
}

const getService = async (props) => {
    return (
        Request({
            url: "/service/event",
            method: "POST",
            data: props
        })
    );
}

const getServiceForUser = async (props) => {
    return (
        Request({
            url: "/service/event/user",
            method: "POST",
            data: props
        })
    );
}

// Create new event
const create = async (props) => {
    return (
        Request({
            url: "/event/add",
            method: "POST",
            data: props
        })
    );
}

// Edit event
const edit = async (props) => {
    return (
        Request({
            url: "/event/edit",
            method: "POST",
            data: props
        })
    );
}

// Create new events from wizard
const addWizard = async (props) => {
    return (
        Request({
            url: "/event/add_wiz",
            method: "POST",
            data: props
        })
    );
}


// Event types
const Types = {

    get: async(props)=>{
        return (
            Request({
                url: "/event/type",
                method: "POST",
                data: props
            })
        );
    },
    
    create: async(props)=>{
        return (
            Request({
                url: "/event/type/create",
                method: "POST",
                data: props
            })
        );
    },
    
    edit: async(props)=>{
        return (
            Request({
                url: "/event/type/edit",
                method: "PUT",
                data: props
            })
        );
    },
    
    delete: async(props)=>{
        return (
            Request({
                url: "/event/type/delete",
                method: "DELETE",
                data: props
            })
        );
    },
    
    order: async(props)=>{
        return (
            Request({
                url: "/event/type/order",
                method: "POST",
                data: props
            })
        );
    },
    
}

// Edit event role of user
const edit_role = async (props) => {
    return (
        Request({
            url: "/event/edit_role",
            method: "POST",
            data: props
        })
    );
}

const get_user_responses = async (props) => {
    return (
        Request({
            url: "/event/user_responses",
            method: "POST",
            data: props
        })
    )
}

const get_custom_fields = async (props) => {
    return (
        Request({
            url: "/event/custom_fields/" + props.event_id,
            method: "GET",
            data: props
        })
    )
}

const set_custom_fields = async (props) => {
    return (
        Request({
            url: "/event/custom_fields/" + props.event_id + "/edit",
            method: "PUT",
            data: props
        })
    )
}

const invite = async (props) => {
    return (
        Request({
            url: "/event/invite",
            method: "POST",
            data: props
        })
    )
}

const remove_members = async (props) => {
    return (
        Request({
            url: "/event/remove_members",
            method: "DELETE",
            data: props
        })
    )
}

const export_attendees = async (props) => {
    return (
        Request({
            url: "/event/attendees/export",
            method: "POST",
            data: props
        })
    )
}

const locationsAvailable = async (props) => {
    return (
        Request({
            url: "/event/locations_available",
            method: "POST",
            data: props
        })
    )
}

// Get Event by Statuses
const getEventStatus = async (props)=>{
    return(
        Request ({
            url: "/event/statuses",
            method: "GET",
            data: props
        })
    )
}

const editEventStatus = async (props)=>{
    return(
        Request({
            url: "/event/status/"+props.event_id,
            method: "PUT",
            data: props
        })
    );
}

const eventPostFilter = async (props)=>{
    return(
        Request({
            url: "/event",
            method: "POST",
            data: props
        })
    )
}

const getByLocationAndDate = async (props)=>{
    return(
        Request({
            url: "/event/by_location_and_date_range",
            method: "POST",
            data: props
        })
    )
}

const Events = {
    publicGet, getSingle, getSimple, getDetail, getForUser, getService, getServiceForUser, create, edit, addWizard, Types, edit_role, get_user_responses, get_custom_fields, set_custom_fields, invite, remove_members, export_attendees, locationsAvailable, getEventStatus, editEventStatus, eventPostFilter, getByLocationAndDate
}
  
export default Events;
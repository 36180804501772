import React, { useRef, useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { randomUUID, formatCMSFromJson } from '../../../../utils/cms';
import { FileURLContext } from '../../../../contexts/FileURLContext';
import * as actions from '../../../../store/actions';

import Section from './Section';
import Element from './Element'; // this is where d&d logic is

export const Dustbin = (props) => {
    const {remove, setLoading} = props;
    const dispatch = useDispatch();
    const cmsSelector = useSelector(state => state.cms);
    const company_context = useContext(FileURLContext);

    const _index = useRef(0);

    const [items, setItems] = useState([]);
    const [isClickable, setIsClickable] = useState(false);
    //const [elements, setElements] = useState([]);

    const clickHandler = useCallback((e,id) => {
        e.preventDefault();
        e.stopPropagation();
        if (cmsSelector.currentElement !== id) dispatch(actions.CMSSetCurrentElement(id));
        return false;
    },[dispatch, cmsSelector.currentElement]);

    const _getElementType = useCallback(element => {
        if (element.element_id.toLowerCase() === 'layout' || element.element_id.toLowerCase() === 'div'){
            if (element?.[element.element_id]?.props?.['layout-type']){
                switch(element[element.element_id].props['layout-type'].toLowerCase()){
                    case 'container':
                    case 'row':
                    case 'column':
                        return element[element.element_id].props['layout-type'].toLowerCase();
                    default:
                        return 'content-block';
                }
            }
        } else {
            if (element.element_id === "Wizard") return "wizard";
            if (element.element_id === "WizardStep") return "wizard-step";
            if (element.element_id === "Slider") return "slider";
            if (element.element_id === "Slide") return "slide";
            if (element.element_id === "Form") return "form";
        }
        return 'content-block';
    },[]);

    const _getDisplayName = useCallback((element) => {
        if (element?.[element.element_id]?.props?.['layout-type']){
            switch(element[element.element_id].props['layout-type'].toLowerCase()){
                case 'container':
                    return "SECTION";
                case 'row':
                case 'column':
                    return element[element.element_id].props['layout-type'].toUpperCase();
                default:
                    return element.element_id;
            }
        }
        return element.element_id;
    },[]);

    const addSection = useCallback(async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // add a container layout with a row layout in it
        const _container_id = `div-container-${randomUUID()}`;
        const _row_id = `div-row-${randomUUID()}`;
        const _item = {
            Layout:{
                id: _container_id,
                parent_id: 'div-dustbin',
                props: {
                    "layout-type": "Container",
                    fluid: "Full Width"
                },
                content:[{
                    Layout:{
                        id: _row_id,
                        parent_id: _container_id,
                        props: {
                            "layout-type": "Row"
                        },
                        content: [
                            /*{
                                Layout:{
                                    id: `div-column-${randomUUID()}`,
                                    parent_id: _row_id,
                                    props: {
                                        "layout-type": "Column",
                                    },
                                    content: []
                                }
                            },*/
                        ],
                    }
                }]
            }
        };

        const item=await formatCMSFromJson([_item]);
        if (item?.length){
            item[0].parent_id = 'div-dustbin';
            item.forEach(itm =>{
                dispatch(actions.CMSAddElement({...JSON.parse(JSON.stringify(itm))}));
            })
            dispatch(actions.CMSSetCurrentElement({...item[item.length-1]}.id));                
        }

        return false;
    },[dispatch]);

    const renderElements = useCallback((elements, parentId = 0) => {
        return elements.filter(element => element.parent_id === parentId).map((element, i) => {

            if (_index.current || isClickable) { // fist element is not clickable
                element.onClick = e => clickHandler(e,element.id);
                if (_index.current) element.remove = e => remove(e,element.id);
                else element.remove = e => e.preventDefault();
            }

            element.page_id = element.id || `preview--${randomUUID()}`;
            element.company_context = company_context;
            element.is_preview = true;
            element.element_type = _getElementType(element);
            element.display_name = _getDisplayName(element);
            element.index = _index.current;
            element.parent_id = parentId;
            element.dzIndex = element.index + 1;  // need to add 1 to index because we'll be adding a dropzone
            element.key = `preview-${element.id}-${parentId}-${_index.current}-${randomUUID()}`;
            
            _index.current += 1; // increment index so we keep the order from the flat array to make sorting easier

            return (
                <Element {...element} setLoading={setLoading}>
                    {renderElements(elements, element.id)}
                </Element>
            );
        });
    },[isClickable, _getElementType, _getDisplayName, clickHandler, remove, setLoading, company_context, _index]);
        
    // when data is set
    useEffect(() => {
        if (props?.data){
            setItems(props.data.map((a, i) => ({
                ...a,
                index: i
            })));
        }
    },[props?.data, setLoading]);

    // whether the first element is clickable or not
    useEffect(() => {
        if (+props?.page_type===10 || +props?.page_type===11 || +props?.page_type===12) setIsClickable(true);
    },[props?.page_type]);

    useEffect(() => {
        if (items.length && _index.current === items.length) setLoading(false);
    },[items, setLoading]);

    useEffect(() => {
        return() => {
            setItems([]);
            setIsClickable(false);
        }
    },[]);

    const elements = useMemo(() => {
        _index.current = 0;
        return renderElements(items);
    }, [items, renderElements]);
    
    //if (!items.length) return null;

    return (
        <div className={props.className} style={props.style} onClick={null} id={props.id || "dustbin"} page_id={props.page_id || "dustbin"}>
            {elements}
            {+props?.page_type!==10 && +props?.page_type!==11 && +props?.page_type!==12 && <Section add={addSection} />}
        </div>
    );
}
import React, { useMemo } from 'react';
import { randomUUID } from '../../../../../utils/cms';

const List =  React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    //const [items, setItems] = useState([]);

    let Component = "ul";
    if (props?.type==="Ordered") Component = "ol";

    const items = useMemo(() => {
        const _loadItems = (_items) => {
            let __items = [];
            const flatItems = _items.flatMap(_item => Array.isArray(_item)? _item.map(a => a) : _item);
            flatItems.forEach((item, i) => {
                if (item?.value) __items.push(<li key={`item-${item.id || randomUUID()}-${i}`}>{item.value}</li>);
                if (item?.children && item?.children.length){
                    __items.push(<Component type={props.ordered_type || undefined} key={`container-item-${item.id || randomUUID()}-${i}`}>{_loadItems(item.children)}</Component>);
                }
            });
            return __items;
        }

        let _items = [];
        if (props?.items) {
            if (typeof props.items === "string") _items=props.items.split(",");
            else if (Array.isArray(props.items)){
                _items = _loadItems([...props.items]);
            }
        }
        return _items;
    }, [props.items, props.ordered_type]);

    return (
        <Component
            id={props.is_preview ? `preview-${randomUUID()}` : props?.id}
            ref={ref}
            className={`list_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' ')}
            style={props.style}
            onClick={preview_click}
            type={props.ordered_type || undefined}
            start={props.ordered_start || undefined}
        >
            {items}
        </Component>
    );
});

export default List;
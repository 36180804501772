//import cssProperties from './cssProperties';

const blocks=[
    {
        id: `HTMLTAG-99991`,
        name: "div",
        display_name: "Div",
        component_name: "div",
        component: <div />,
        class: "layout",
        tooltip: "Adds a div block to the page",
        list: "Elements",
        apply_styles: true,
        can_drop: true,
        hidden: true,
        props: [
            {
                display_name: "Display",
                name: "display",
                type: "select",
                description: "Display of the layout",
                value: "flex",
                options: ["flex", "block", "inline-block", "inline"]
            },
            {
                display_name: "Direction",
                name: "flex-direction",
                type: "select",
                description: "Direction of the layout",
                value: "row",
                options: ["row", "column"]
            },
        ]
    },
    {
        id: `HTMLTAG-99995`,
        name: "hr",
        display_name: "Divider",
        component_name: "hr",
        class: "text",
        tooltip: "Adds a horizontal divider to the page",
        list: "Elements",
        apply_styles: true,
        props: []
    },    
    /*
    {
        id: `HTMLTAG-99998`,
        name: "img",
        display_name: "Image",
        component_name: "img",
        class: "image",
        tooltip: "Adds an image block to the page",
        list: "Elements",
        apply_styles: true,
        props: [
            {
                display_name: "Image URL",
                name: "src",
                type: "image",
                description: "Image file URL",
                value: "https://via.placeholder.com/250",
            },
            {
                display_name: "Title",
                name: "alt",
                type: "text",
                description: "Image title",
                value: "Placeholder Image",
            },
        ]
    },
    */
]

export default blocks;
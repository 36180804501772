import React, {useState, useEffect, useContext} from 'react';
import { getPageById, parseCmsComponent, randomUUID } from '../../../../../utils/cms';
import { FileURLContext } from '../../../../../contexts/FileURLContext';    

const ContentBlock = React.forwardRef((props, _) => {
    const company_context = useContext(FileURLContext);

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [page, setPage] = useState();
    

    useEffect(() => {
        const _getPageById = async () => {
            if (props.contentblock_id){
                const res=await getPageById({id: props.contentblock_id});
                if (res){
                    /*
                    let data=res[0].content;
                    if (!Array.isArray(data)) data=[data];
                    setPage(<Dustbin id={props.id} page_id={props.page_id} data={data} />);
                    */
 
                    let _props = {
                        id: props.is_preview ? `preview-${randomUUID()}` : undefined,
                        page_id: props.is_preview ? "preview" : props.page_id,
                        onClick: preview_click,
                        is_preview: props.is_preview
                    };

                    const _page = await parseCmsComponent(res[0].content, company_context, _props);
                    setPage(_page);
                } 
            }
        }
        _getPageById();
    },[props.contentblock_id, props.id, props.page_id, props.is_preview, preview_click, company_context]);


    useEffect(() => {
        return () => {
            setPage(null);
        }
    },[]);


    if (!props.contentblock_id){
        return <div className="empty-contentblock" onClick={preview_click} />
    }

    return page || <div/>;

});

export default ContentBlock;
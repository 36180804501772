import * as actionTypes from '../actions/actionTypes';

const initialState = {
    errors: {},
    cashSuccess: false,
    creditCardSuccess: false,
    submitting: false,
    requiresUser:false,
    remainder: 0,
    cashValue:0,
    forUser:{},
    payees:[],
    allPaid:"No",
    allItemsAssigned:"No",
    ordersSplit: false,
    allOrders: [],
    paymentType: "",
    originalItems: []
};

const splitPaymentsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_SPLIT_PAYMENT_ERRORS:
            return{
                ...state,
                errors: action.errors
            };
        case actionTypes.SET_SPLIT_PAYMENT_CASH_SUCCESS:
            return{
                ...state,
                cashSuccess: action.cashSuccess
            };
        case actionTypes.SET_SPLIT_PAYMENT_CC_SUCCESS:
            return{
                ...state,
                creditCardSuccess: action.creditCardSuccess
            };
        case actionTypes.SET_SPLIT_PAYMENT_SUBMITTING:
            return{
                ...state,
                submitting: action.submitting
            };
        case actionTypes.SET_SPLIT_PAYMENT_REQUIRES_USER:
            return{
                ...state,
                requiresUser: action.requiresUser
            };
        case actionTypes.SET_SPLIT_PAYMENT_REMAINDER:
            return{
                ...state,
                remainder: action.remainder
            };
        case actionTypes.SET_SPLIT_PAYMENT_CASH_VALUE:
            return{
                ...state,
                cashValue: action.cashValue
            };
        case actionTypes.SET_SPLIT_PAYMENT_FORUSER:
            return{
                ...state,
                forUser: action.forUser
            };
        case actionTypes.SET_SPLIT_PAYMENT_PAYEES:
            return{
                ...state,
                payees: action.payees
            };
        case actionTypes.SET_SPLIT_PAYMENT_ALL_PAID:
            return{
                ...state,
                allPaid: action.allPaid
            };
        case actionTypes.SET_SPLIT_PAYMENT_ALL_ITEMS:
            return{
                ...state,
                allItemsAssigned: action.allItemsAssigned
            }
        case actionTypes.SET_ORDERS_HAVE_BEEN_SPLIT:
            return{
                ...state,
                ordersSplit: action.ordersSplit
            }
        case actionTypes.SET_SPLIT_PAYMENT_ALL_ORDERS:
            return{
                ...state,
                allOrders: action.allOrders
            }
        case actionTypes.SET_SPLIT_PAYMENT_SPLIT_TYPE:
            return{
                ...state, 
                paymentType: action.paymentType
            }
        case actionTypes.SET_SPLIT_PAYMENT_ORIGINAL_ITEMS:
            return{
                ...state,
                originalItems: action.originalItems
            }
        case actionTypes.RESET_SPLIT_PAYMENT_STATE:
            return{
                errors: {},
                cashSuccess: false,
                creditCardSuccess: false,
                submitting: false,
                requiresUser:false,
                remainder: 0,
                cashValue:0,
                forUser:{},
                payees:[],
                allPaid:"No",
                allItemsAssigned:"No",
                ordersSplit: false,
                allOrders: [],
                paymentType: "",
                originalItems:[]
            };
        default:
            return state;
    }
};

export default splitPaymentsReducer
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Row,Col,Modal,Button, Form, Card} from 'react-bootstrap';
import ErrorCatcher from '../../../components/common/ErrorCatcher';

import * as actions from '../../../store/actions';

import CouponsAPI from '../../../api/Coupons';

import { updateCart } from '../../../utils/thunks';

import "./Coupons.scss";

export const Coupons = ({show, hide, title, register_id}) => {
    const dispatch = useDispatch();

    const order = useSelector(state => state.pos[register_id].orderAll);
    const customer = useSelector(state => state.pos[register_id].user);
    const items = useSelector(state => state.pos[register_id].items);
    const coupons_selected = useSelector(state => state.pos[register_id].coupons_selected);
    const coupon_codes = useSelector(state => state.pos[register_id].coupon_codes);
    const [isNonCombinableSelected, setIsNonCombinableSelected] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        // loop through coupons
        // if a coupon is currently selected that is not combinable, then all the others should be disabled
        let isNonCombinable = false;
        order?.coupons?.forEach(coupon => {
            if (coupon.combinable===0 && coupons_selected.includes(coupon.id)) {
                isNonCombinable = true;
            }
        });
        setIsNonCombinableSelected(isNonCombinable);
    },[coupons_selected, order?.coupons]);

    const handleSelectCoupon = (coupon) => {
        let selected = coupons_selected;
        let index = selected.findIndex(id=>id===coupon.id);
        if (index<0) {
            selected.push(coupon.id);
        } else {
            selected.splice(index, 1);
        }
        dispatch(actions.setCouponsSelected([...selected], register_id));
        dispatch(updateCart(register_id));
    }

    const handleSubmitCouponCode = async (event) => {
        let coupon_code = event.target.coupon_code.value;
        let variants = items.map(item => item.variant_id);
        // check code with api/coupon/code at Coupons.checkCode()
        await CouponsAPI.verifyCode({
            coupon_code: coupon_code,
            user_id: customer?.id || null,
            product_variant_ids: variants,
        })
        .then( response => {
            if (!response.errors) {
                console.log(response);
            } else {
                console.log(response);
                setError(<ErrorCatcher error={response.errors} />);
            }
        }).catch(e => {
            setError(<ErrorCatcher error={e} />);
            console.error(e);
        });
        // save it to 
        // if no error, and isNonCombinableSelected==0, add the coupon id to coupons_selected
    }

    return (
        <Modal className="pos-modal coupon-modal" show={show} onHide={hide} size="md" centered backdrop="static">
            <Modal.Header closeButton={false}>
                <Modal.Title>{title}</Modal.Title>
                <Button data-cy="done-btn" onClick={hide} style={{marginLeft:"auto"}}>Done</Button>
            </Modal.Header>
            <Modal.Body>
                <Row className={`coupon-row saved-total ${order?.total_discount>0 ? 'highlight-price' : ''}`} data-cy="discount-amount">
                    <Col>
                        <h4 className="text-center mb-0">You save ${(order?.total_discount)?.toFixed(2)}</h4>
                    </Col>
                </Row>
                <Row className={`coupon-row coupon-input hidden`}>
                    <Col>
                        <Form noValidate onSubmit={handleSubmitCouponCode}>
                            <Form.Control type="text" name="coupon_code" placeholder="Coupon Code" defaultValue={""} />
                            <Button type="submit">Go</Button>
                        </Form>
                    </Col>
                </Row>
                <Row>
                    <Col className="p-0">
                        {/* if there are coupon codes entered, display them here */}
                        {order?.coupons?.map(coupon => {
                            let selected = !!coupons_selected.includes(coupon.id);
                            let disabled = (!selected && isNonCombinableSelected) || (!selected && coupon.combinable===0 && coupons_selected.length>0);
                            return (
                                <Card
                                    key={`coupon-${coupon.id}`}
                                    className={`coupon-row ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
                                    onClick={()=> !disabled ? handleSelectCoupon(coupon) : ()=>{}}
                                    data-cy="discount-item"
                                >
                                    <div className="coupon-item">
                                        <div className="name" data-cy="discount-name">{coupon.name}</div>
                                        {coupon.description!=="" &&
                                            <div className="description">{coupon.description}</div>
                                        }
                                        {!coupon.combinable &&
                                            <div className="no-combine" data-cy="discount-no-combine">(Cannot be combined with other discounts.)</div>
                                        }
                                    </div>
                                </Card>
                            )
                        })}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}
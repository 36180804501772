import React, {useRef, useState, useEffect, useCallback, useContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'

import {AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Col, Row, Card, Button, Modal, Spinner } from 'react-bootstrap';

//import TodayEvents from './Events/TodayEvents/TodayEvents';
import Details from '../../User/Details/Details'
import Subscriptions from './Subscriptions';
import OutstandingCharges from './OutstandingCharges';
import Groups from './Groups';
import Family from './Family';
//import Events from './Events';
import Tokens from './Tokens';
import Waiver from '../../User/Waiver/';
import CheckinButton from '../../User/Profile/CheckinButton';

import UserCreateEdit from '../../../components/UserCreateEdit';
import UsersAPI from '../../../api/Users';

import * as actions from '../../../store/actions';
import { FileURLContext } from '../../../contexts/FileURLContext';
import { updateCart } from '../../../utils/thunks';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './Users.scss';

const Users = (props) => {
    const dispatch = useDispatch();
    const ref = React.createRef();
    const mountedRef = useRef(false);

    const imageURL = useContext(FileURLContext);
    const nopic = imageURL.noPicBig;

    //const nopic = "https://assets.website-files.com/5e51c674258ffe10d286d30a/5e535a2b8e24938384074dac_peep-73.svg";

    const [eventUsers, setEventUsers] = useState();
    const [userSelections, setUserSelections] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [loading,setLoading]=useState(false);
    const [userLoading, setUserLoading]=useState(true);
    const [ eventModal, setEventModal ]=useState(false);
    const [ profileModal, setProfileModal ]=useState(false);
    const [ profileImgPath, setProfileImgPath ]=useState(null);
    const [ waiverStatus, setWaiverStatus ]=useState(null);
    const [ waiverSubmitted, setWaiverSubmitted]=useState(false);
    const [ userFamily, setUserFamily ]=useState();
    const [registerId, setRegisterId] = useState(props.register_id);

    const modalCloseHandle = () => setModalShow(false);
    const eventModalOnClick = () => setEventModal(!eventModal);
    const profileModalOnClick = ()=> setProfileModal(!profileModal);

    const selectedUser = useSelector(state => state.pos[props.register_id].user);
    const finishedLoadingCustomer = useSelector(state => state.pos[props.register_id].finished_loading_customer);
    const company = useSelector(state => state.company);
    const GUEST_USER_ID = company?.config?.guest_user_id || null;

    const newUserCloseHandler = (newUserId)=>{
        getNewUserById(newUserId);
    }

    const filterBy = () => true;

    const searchHandle = (query) => {
        setLoading(true);
        setUserLoading(true);
        setProfileImgPath();
        setWaiverStatus(null)
        UsersAPI.list({filters:{user_roles:null,search_words:query || null},max_records:50,page_no:1,sort_col:"username",sort_direction:"ASC"})
        .then(response => {
            if(response.data) {
                setEventUsers(response.data.users);
            } else if (response.errors) {}
            setLoading(false)}
        ).catch(e => console.error(e));
    }

    useEffect(()=>{
        mountedRef.current = true

        return ()=> mountedRef.current = false
    },[])

    useEffect(()=>{
        const getUserSelection=async()=>{
            if (selectedUser) {
                setUserSelections([selectedUser]);
                setProfileImgPath(null);
                setUserFamily(null);
                setWaiverStatus(null);
                UsersAPI.get({id:selectedUser.id})
                .then( response =>{
                    if(mountedRef.current){
                        setProfileImgPath(response.data[0].profile_img_path);
                        setWaiverStatus(response.data[0].has_signed_waiver);
                        setUserFamily(response.data[0].family_groups);
                        setUserLoading(false);
                    }
                }).catch((ex)=>{
                    console.error(ex);
                    setUserLoading(false);
                })
            } else setUserSelections([]);
        };
        getUserSelection();
    },[selectedUser, waiverSubmitted]);


    const getNewUserById=async(newUserId)=>{
        try{
            let response = await UsersAPI.get({id:newUserId});
            if(!response.errors){
                dispatch(actions.selectCustomer(response.data[0], props.register_id));
            }
        } catch(ex){console.error(ex)}
        setModalShow(false);
    }

    /*

    useEffect(() => {
        let mounted = true;

        UsersAPI.getList().then(response => {
            if(mounted) {
                setEventUsers(response.data?.users);
            }
        }).catch(e => console.error(e));

        // adds the users in the event to the list
        /*if (props.event && props.event.users){
            setUserSelections(props.event.users);
        }

        return () => {
            mounted = false;
        }
	}, []);
    */

    const resetUserForWaiver=()=>{
        setWaiverSubmitted(true);
        setWaiverStatus(1);
    }

    const renderInput = ({ inputClassName, inputRef, referenceElementRef, ...props },{ onRemove, selected }) => {
        return (
            userSelections &&
            <React.Fragment>
                <div className="d-flex justify-space-between">
                    <Button variant="light" className="btn-category" data-cy="new-user" onClick={()=>setModalShow(true)}>New User</Button>
                    <Button className="btn-category" variant="light" data-cy="users-change-user-btn" onClick={() => {
                        setUserSelections([]);
                        ref.current.clear();
                        onRemove(userSelections[0]);
                        dispatch(actions.resetCustomer(props.register_id));
                        }}>
                        Change User
                    </Button>
                </div>
                {userSelections.length<=0 &&
                    <>
                        <label className="form-label">User Name</label>
                        <input
                            {...props}
                            className="form-control"
                            ref={input => {
                                referenceElementRef(input);
                                inputRef(input);
                            }}
                            type="text"
                        />
                    </>
                }
                {userSelections.map((user, i) => (
                    user &&
                    <Row key={`user-select-${user.id}`} data-cy={`user-select-${user.id}`}>
                        <div className="user-info site-col">
                            {userLoading && <div className="user-pic" />}
                            {!userLoading && profileImgPath && <div className="user-pic" data-cy="users-profile-pic" style={{backgroundImage:`url(${profileImgPath})`}} />}
                            {!userLoading && !profileImgPath && <div className="user-pic" data-cy="users-profile-pic" style={{backgroundImage:`url(${nopic})`}} />}
                            <div className="user-buttons-wrapper">
                                {!userLoading && imageURL && imageURL.companyId===2 &&
                                    <Waiver 
                                        msg={""} 
                                        user={user}
                                        btntxt={"Unsigned Waiver"} 
                                        waiverStatus={waiverStatus}
                                        reset={resetUserForWaiver}
                                        noBackground={true}
                                        type="button"
                                        variant="danger"
                                        classes="btn-category"
                                    />
                                }
                                <Button variant="light" id="pos-edit-profile-btn" className="btn-category" data-cy="users-edit-profile-btn" onClick={profileModalOnClick}>Edit Profile</Button>
                                <CheckinButton userInfo={user} register_id={registerId}  variant="light" text="Check In" classes="btn-category" />
                            </div>
                            
                            <h4 className="current-user" data-cy="users-fullname">{user.first_name+" "+user.last_name}</h4>
                            <Modal dialogClassName="pos-user-modal" size="xl" show={profileModal} onHide={profileModalOnClick}>
                                <Modal.Header closeButton />
                                <Modal.Body>
                                    <Details posId={user.id} />
                                </Modal.Body>
                            </Modal>
                            <Card className="mt-2" data-cy="pos-user-pending-charges">
                                <h5>Pending Charges</h5>
                                <OutstandingCharges user_id={user.id} register_id={registerId} />
                            </Card>
                            <Card data-cy="pos-user-subscriptions">
                                <h5>Subscriptions</h5>
                                <Subscriptions user_id={user.id} searchBar={false} register_id={registerId} />
                            </Card>
                            <Card data-cy="pos-user-tokens">
                                <h5>Redeem Tokens</h5>
                                <Tokens user_id={user.id} register_id={registerId} />
                            </Card>
                            <Card data-cy="pos-user-family">
                                <h5>Family Members</h5>
                                <Family user_id={user.id} userFamily={userFamily} searchBar={false} register_id={registerId} />
                            </Card>
                            <Card data-cy="pos-user-groups">
                                <h5>Groups</h5>
                                <Groups user_id={user.id} searchBar={false} register_id={registerId} />
                            </Card>
                            {/* <Button id="pos-event-sche-btn" onClick={eventModalOnClick}>Full Event Schedule</Button>
                            */}
                            {/* <Card>
                                <h5>Today's Events</h5>
                                <TodayEvents user_id={option.id}/>
                            </Card> */}
                        
                            {/* <Modal show={eventModal} onHide={eventModalOnClick}>
                                <Modal.Header closeButton>
                                    <Modal.Title> Events Schedule</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Events user_id={option.id} searchBar={false} />
                                </Modal.Body>
                            </Modal> */}
                        </div>
                    </Row>
                ))}
            </React.Fragment>
        )
    };

    if (!finishedLoadingCustomer) {
        return (
            <div className="user-container text-center pt-3">
                <Spinner animation="border" variant="secondary" />
            </div>
        )
    }

    return (
        <div className="user-container">

            <Modal show={modalShow} size="lg" onHide={modalCloseHandle}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">New User</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: "2rem"}}>
                    <UserCreateEdit afterSubmit={newUserCloseHandler} />
                </Modal.Body>
            </Modal>

            <Row>
                <Col className={`p-${userSelections.length>0?0:2} m-0`}>
                    <AsyncTypeahead
                        // filterBy={filterBy}
                        id="user_autocomplete"
                        isLoading={loading}
                        minLength={3}
                        onSearch={searchHandle}
                        onChange={item=>{
                            //console.log("ITEM 0", item[0])
                            dispatch(actions.selectCustomer(item[0], props.register_id));
                            dispatch(updateCart(props.register_id, item?.[0]?.id || GUEST_USER_ID || 0 ));
                        }}                        
                        labelKey={option => `${option?.first_name} ${option?.last_name} <${option?.username}>`}
                        options={eventUsers || []}
                        placeholder="Enter a user name..."
                        selected={userSelections}
                        renderInput={renderInput}
                        ref={ref}
                        inputProps={{'data-cy': 'search-users'}}
                    />
                </Col>
            </Row>
        </div>
    );
}
export default Users;
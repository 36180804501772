import React from 'react';
import { randomUUID } from '../../../../../utils/cms';

const WizardStep =  React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    /*if (!props.is_preview){
        if (+props.current_step !== +props.selected_step) return null;
    }*/

    return (
        <>
            {props.is_preview && <span className="step-label">{props?.step_title || `STEP #${props?.step_number}` || "STEP"}</span>}
            <div id={props.is_preview ? `preview-${randomUUID()}` : props?.id} ref={ref} className={`wizard-step_${props.page_id} ${props.className || ""} ${props.current_step !== +props.selected_step && !props.is_preview ? "d-none": ""}`.trim().replace(/\s+/g,' ')} style={props.style} onClick={preview_click}>
                {props?.children}
            </div>
        </>
    );
});

export default WizardStep;
import React,{useState,useEffect,useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Card, InputGroup, Button } from 'react-bootstrap';

import ErrorCatcher from '../../../components/common/ErrorCatcher';
import * as actions from '../../../store/actions';
import Users from '../../../api/Users';

import './CheckUser.css';

export const CheckUser = (props) => {
    let history = useHistory();
    const dispatch = useDispatch();    
    const ref=useRef();

    const onClose=props.onClose;

    //const user = localStorage.getItem("user");

    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    const [users, setUsers] = useState([]);
    const [user, setUser] = useState();
    const [searchResults, setSearchResults] = useState([]);
    const [outstandingCharges, setOutstandingCharges] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        let mounted = true;

        Users.get( { all: true } )
        .then( response => {
            if (mounted) {
                setUsers(response.data);
            }
        }).catch(e => {
            console.error(e);
            setError(<ErrorCatcher error={e} />);
        });

        return () => mounted = false;
    },[])

    useEffect(() => {
        let mounted = true;

        Users.get({"id": user?.id})
        .then( response => {
            if (mounted) {
                if(response.data) {
                    setOutstandingCharges(response.data[0]?.outstanding_charges);
                    setSubscriptions(response.data[0]?.subscriptions);
                }
            }
        }).catch( e=> {
            console.error(e);
            setError(<ErrorCatcher error={e} />)
        })

        return () => mounted = false;
    },[user])

    const clickUserHandler = (user) => {
        setUser(user);
        setSearchResults([]);
    }

    // form submit
    const submitHandler = async (e) => {
        const form = e.currentTarget;
        
        e.preventDefault();
        setSubmitting(true);
        setValidated(true);
        setError(null);
        setSuccess(null);
        setUser(null);

        const formData = new FormData(form);
        const formDataObj = Object.fromEntries(formData.entries());

        setSearchResults([...users].filter( u =>
            `${u.profile.first_name.toLowerCase()} ${u.profile.last_name.toLowerCase()}`.indexOf(formDataObj.searchString.toLowerCase()) !== -1
            || u.profile.email.toLowerCase().indexOf(formDataObj.searchString.toLowerCase()) !== -1
            ).map( u => u.profile));
        
        setSubmitting(false);
        setValidated(true);
    }

    const customerInfo = React.useMemo(() => {
        const activeCustomerHandler = () => {
            dispatch(actions.selectCustomer(user));
            onClose();
        }

        return(
            <React.Fragment>
                {user &&
                    <Card className="customer-info-card">
                        <Card.Header>
                        <p className="h6">
                            {`${user?.first_name} ${user?.last_name} currently has `}
                            { subscriptions?.length > 0 ? "the following active subscriptions:" : "no active subscriptions."}
                        </p>
                        { subscriptions?.length > 0 &&
                            <ul>
                                {subscriptions.map(
                                    item => <li>{item.product_name + ", " + item.product_variant_name}</li>
                                )}
                            </ul>
                        }
                        <div className="h6">
                            {outstandingCharges?.length > 0 ? "The following charges are pending:"
                            : "No pending charges."
                            }
                        </div>
                        </Card.Header>
                        {outstandingCharges?.length ?
                            <Card.Body scrollable>
                            {outstandingCharges?.map( item =>
                                <Card>
                                    <Card.Header>
                                        <text className="h6">{item.product_name}</text>
                                    </Card.Header>
                                    <Card.Body className="event-fee">
                                        <div>
                                            {"Price: $" + item.product_variants[0].price}
                                        </div>
                                        {item.requires_membership ? <div>
                                            Requires Membership
                                        </div> : null}
                                    </Card.Body>
                                </Card>
                            )}
                            </Card.Body>
                            : null
                        }
                        <Card.Footer>
                            <Button variant="primary" onClick={activeCustomerHandler}>Set as Active Customer</Button>
                        </Card.Footer>
                    </Card>
                }
            </React.Fragment>
        );
    },[user, subscriptions, outstandingCharges,onClose,dispatch]);

    return (
        <React.Fragment>
            <Container fluid>
                <Form noValidate validated={false} onSubmit={submitHandler}>
                    <Form.Group controlId="searchgroup">
                        <InputGroup>
                            <Form.Control type="text" name="searchString" placeholder="Search for Name or Email..." />
                            <InputGroup.Append>
                                <Button variant="secondary" type="submit" disabled={submitting} >
                                    Search
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                </Form>
                {searchResults.map( (u, i) =>
                    <div className={"lookup-result" + (i % 2 === 0 ? " shaded" : "")}>
                        <div className="lookup-clickable" onClick={() => clickUserHandler(u)}>
                            {`${u.first_name} ${u.last_name}, ${u.email}`}
                        </div>
                    </div>)}
                {customerInfo}
                {error}
            </Container>
        </React.Fragment>
    );
}

export default CheckUser;
import React, { useState, useEffect } from 'react';
import Registers from '../../../../api/Registers';

import '../OrderPage.scss';

const OrderOtherDetails = ({currentOrder=null, advancedDetailPermission=true}) => {
    
    const [register, setRegister]=useState(null);

    useEffect(()=>{
        const getRegister=async()=>{
            try{
                let response = await Registers.get({
                    id: currentOrder?.register_id
                })
                if(response.status===200 && !response.errors) setRegister(response.data[0])
            }catch(ex){console.error(ex)}
        }

        if(currentOrder?.register_id){
            getRegister();
        }
    },[currentOrder?.register_id])

    return (
        <div className="order-other-details-wrapper">
            <div>
                <p className="detail-pair">
                    <span>
                        Name:
                    </span>
                    <span>
                        {currentOrder?.user_fname} {" "} {currentOrder?.user_lname}
                    </span>
                </p>
                <p className="detail-pair">
                    <span>
                        Username:
                    </span>
                    <span>
                        {currentOrder?.user_name}
                    </span>
                </p>
                <p className="detail-pair">
                    <span>
                        Email:
                    </span>
                    <span>
                        {currentOrder?.email}
                    </span>
                </p>
                <p className="detail-pair">
                    <span>
                        Location:
                    </span>
                    <span>
                        {currentOrder?.location_name} {" "}
                        {register &&
                            <>
                                 - {register?.register_group_name} 
                                {advancedDetailPermission &&
                                    <>
                                {" "}(ID: {register?.id})
                                    </>
                                }
                            </>
                        }
                    </span>
                </p>
                {advancedDetailPermission &&
                    <>
                        <p className="detail-pair">
                            <span>
                                Memo:
                            </span>
                            <span>
                                {currentOrder?.memo ? currentOrder?.memo : "----"}
                            </span>
                        </p>
                    </>
                }
            </div>
        </div>
    )
}

export default OrderOtherDetails
import React from 'react';

import styles from './BottomBanner.module.scss'

/**
 * @param {{}} detailBtn - boolean, shows the detail button.
 * @param {{}} data - whatever data you want to pass into the banner.  Will map the items in accordance to itemFormat(item) prop passed in.  If none will do {item?.name}
 * @param {{}} itemFormat - formatItem should be an html object to render - if not included, will default to {item.name}
 * @param {{}} onDetailClick When the detail button is clicked, this function will be called.  Only applies if detailBtn is true
 * @param {{}} onItemClick On item click can be passed in to here or can be included in whatever formatItem(item) you choose
 * @param {{}} amountToFilter How many items you want the banner to show at a time
 * @param {{}} parameter - an object to control any additional filters of data.  Must be supplied as {param: "object key", value: "object value"}.  If one or both of these are null, will not filter
 */
export const BottomBanner =({
    detailBtn=true, 
    data, 
    itemFormat=null, 
    onDetailClick=()=>{}, 
    onItemClick=()=>{}, 
    amountToFilter=4, 
    parameter={param: null, value: null}, 
    zIndex=null,
    ...props 
}) => {
    
    let localData = [];
    let leftoverAmount = 0;

    const filterByAmount = ()=>{
        localData =localData?.slice(0, amountToFilter);
    }

    const filterByParameter =()=>{
        if(parameter?.param && parameter?.value){
            localData = [...data?.filter(item=>item[parameter?.param] === parameter?.value)]
        }else localData = [...data]
        filterByAmount();
    }

    const findLeftoverAmount = ()=>{
        if(data && data?.length > amountToFilter){
            leftoverAmount = data.length - amountToFilter;
        }
    }

    if(data) {
        filterByParameter();
        findLeftoverAmount();
    }
  
    return (
        <div 
            className={styles["bottombanner-wrapper"]}
            style={{zIndex: zIndex}}
            {...props}    
        >
            <div className={styles["horizontal-list"]}>
                {detailBtn &&
                    <i className="far fa-list-ul cp" onClick={onDetailClick}></i>
                }
                {localData?.map((item, i )=>(
                    <div 
                        key={`scroll-item-${i}`} 
                        onClick={onItemClick} 
                        className={styles["each-item"]}
                    >
                        {itemFormat ?
                            <>
                                {itemFormat(item)}
                            </>
                        :
                            <span>
                                {item?.name}
                            </span>
                        }
                    </div>
                ))}
                {leftoverAmount ?
                    <span>(+ {leftoverAmount} more)</span>
                :
                    null
                }
            </div>
        </div>
    )
}
import React,{useContext} from 'react';

import { FileURLContext } from '../../../contexts/FileURLContext';

import './Footer.scss';


export const Footer = () => {
    const themeStuff = useContext(FileURLContext);

    return (
        <footer id="site-footer">
            <div className="copyright">
                Copyright &copy; { new Date().getFullYear() }. {themeStuff.companyName}
            </div>
        </footer>
    );
};
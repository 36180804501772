import Request from './Api';

// Get service(s)
const get = async (props) => {
    return (
        Request({
            url: "/service",
            method: "POST",
            data: props
        })
    );
}

// Create new service
const create = async (props) => {
    return (
        Request({
            url: "/service/add",
            method: "POST",
            data: props
        })
    );
}

// Edit service
const edit = async (props) => {
    return (
        Request({
            url: "/service/edit",
            method: "PUT",
            data: props
        })
    );
}

// Delete service
const remove = async (props) => {
    return (
        Request({
            url: "/service/delete",
            method: "DELETE",
            data: props
        })
    );
}

// Create booking event
const createBooking = async (props) => {
    return (
        Request({
            url: "/service/add_booking",
            method: "POST",
            data: props
        })
    );
}

// Cancel booking event
const cancelBooking = async (props) => {
    return (
        Request({
            url: "/service/cancel_booking",
            method: "POST",
            data: props
        })
    );
}

const Services = {
    get, create, edit, remove, createBooking, cancelBooking
}

export default Services;
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { createPortal } from 'react-dom';

export const Frame = ({children,...props}) => {
    const [contentRef, setContentRef] = useState(null);
    const [height, setHeight] = useState("100%");
    const [width, setWidth] = useState("100%");

    useEffect(() => {
        if (props?.height) setHeight(props.height);
        if (props?.width) setWidth(props.width);
    }, [props?.height, props?.width]);

    useEffect(() => {
        return () => {
            setHeight("100%");
            setWidth("100%");
        }
    }, []);

    const mountNode =contentRef?.contentWindow?.document?.body;

    return (
      <iframe {...props} ref={setContentRef} title={props?.title || "_"} style={{height: height, width: width}} onLoad={e => setTimeout(() => {
                const obj = ReactDOM.findDOMNode(contentRef);
                obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
            }, 1000)}>
        {mountNode && createPortal(children, mountNode)}
      </iframe>
    )
}
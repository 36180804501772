import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';

import { logout } from '../../../utils/thunks';
import ErrorCatcher from '../../../components/common/ErrorCatcher';
import * as actions from '../../../store/actions';
import { attemptAuth } from '../../../utils/thunks';

import './Login.scss';
//import theme from '../../../assets/css/scss/themes.scss';

import { FileURLContext } from '../../../contexts/FileURLContext';

export const Logout = ({ resetTheme=()=>{}, portal = true }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(logout(history, portal));
        resetTheme();
    },[dispatch, history, resetTheme, portal]);

    return (
        <>loading...</>
    );
}

export const Login = (props) => {
    const imageURL = useContext(FileURLContext);
    let history = useHistory();
    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [message, setMessage] = useState();
    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [disabled, setDisabled] = useState(false);
    const registrationRedirect=useSelector(state=>state.auth.registration_redirect);
    const fromSignUp=useSelector(state=>state.auth.from_signup);
    const registrationError = useSelector(state=>state.auth.error);
    
    useEffect(()=>{
        let disabled = false;
        if(submitting) disabled=true;
        if(!userName) disabled=true;
        if(!password) disabled=true;
        setDisabled(disabled);
    },[userName, password, submitting])

    useEffect(() => {
        if (props.logged_in) history.push("/p/home");
        else if (props.redirect_path !== '/') return <Redirect to="/p/" />
        setSubmitting(props.loading);
        if (props.location?.state?.login_message) {
            setMessage(
                <div style={{marginBottom: "0.7rem", marginTop: "0.7rem", textAlign: "center", color: "rgb(243, 138, 19)", fontSize: "1rem"}}>
                    {props.location.state.login_message || "This is a message!"}
                </div>
            )
        } else {
            setMessage(
                <br />
            );
        }
    }, [props.error, props.loading, props.logged_in, props.redirect_path, history,props.location.state]);

    useEffect(() => {
        if (props.error || registrationError) {
            setError(<ErrorCatcher error={props.error || registrationError} />);
        }
    },[props.error, registrationError]);

    const submitHandler = async (e) => {
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setValidated(true);
        setSubmitting(true);
        setError(null);

        if (form.checkValidity() === true) {
            dispatch(actions.setAuth({ error: null })); // reset errors

            const formData = new FormData(e.target);
            formData.append("rememberme", 1); // remove when checkbox is added
            const formDataObj = Object.fromEntries(formData.entries());

            //if user came from a page before registering and then logging in, redirect them to that page
            let path = null;
            if (registrationRedirect?.path && fromSignUp) {
                history.push(registrationRedirect.path);
                path = registrationRedirect.path;
            }

            dispatch(attemptAuth(formDataObj.username,formDataObj.password,formDataObj.rememberme, path));
            setSubmitting(false);
        } else setSubmitting(false);
    };

    if (props.logged_in) history.push("/p/home");

    return (
        <div id="login" className="login-register">
            {imageURL.backgroundImage?.endsWith(".mp4") &&
                <video autoPlay muted loop id="bgVideo">
                    <source type="video/mp4" src={imageURL.backgroundImage} />
                </video>
            }
            <section className="banner-wrapp login-banner" style={!imageURL.backgroundImage?.endsWith(".mp4") ? {backgroundImage:`url(${imageURL.backgroundImage})`} : null}>
                <div className="l-con-wrap container">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div className="banner-content">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="hid-mob">
                                                <h4 className="dash-title-login">{imageURL.companyName}</h4>
                                                <h1 className="portal-he">Portal Login</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                            <div className="auth-form-wrap">
                            <div className="logo"></div>
                                <Form noValidate validated={validated} onSubmit={submitHandler} >
                                <div className="text-align-sign">Don't have an account? <Link to="/p/signup">Sign up now</Link></div>
                                {message}
                                    <Form.Row>
                                        <Form.Group controlId="username" className="ani-input" >
                                            {/* <Form.Label>User Name</Form.Label> */}
                                            <Form.Control required type="text" name="username" placeholder="Enter user name" data-cy={"login-username"} value={userName} onChange={(e)=>setUsername(e.target.value)} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group controlId="password" className="ani-input" >
                                            {/* <Form.Label>Password</Form.Label> */}
                                            <Form.Control required type="password" name="password" placeholder="Password" data-cy={"login-password"} value={password} onChange={(e)=>setPassword(e.target.value)} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Button variant="primary" type="submit" disabled={disabled} className={`${submitting?" submitting":""}`} data-cy={"login-submit"}>Sign In</Button>
                                        <br />
                                        <div className="text-cent-forget" >
                                        <Link to="/p/forgot-password">Forgot your password?</Link><br />
                                        <Link to="/p/forgot-user">Forgot your user name?</Link>
                                        </div>
                                    </Form.Row>
                                </Form>
                                {error}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
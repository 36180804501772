export const getStyles = () => {
    const rootStyles = getComputedStyle(document.documentElement);
    const _getSassVar = (name) => {
        // split the name by spaces
        const nameArr = name.split(' ');
        // check if any of the values starts with a $, if so, replace it with the value of the variable
        nameArr.forEach((val, i) => {
            if (val.substring(0,1)=== '$') {
                nameArr[i] = rootStyles.getPropertyValue(`--${val.substring(1)}`) ;
            }
        });
        // return the name with the variables replaced
        return nameArr.join(' ');
    }

    const bgColor = rootStyles.getPropertyValue('--form-control-background-color');
    const color = rootStyles.getPropertyValue('--form-control-color');
    const fontFamily = rootStyles.getPropertyValue('--form-control-font-family').replace(/['"]+/g, '');
    const fontSize = rootStyles.getPropertyValue('--form-control-font-size');
    const fontWeight = rootStyles.getPropertyValue('--form-control-font-weight');
    const lineHeight = rootStyles.getPropertyValue('--form-control-line-height');
    const border = rootStyles.getPropertyValue('--form-control-border');
    const borderRadius = rootStyles.getPropertyValue('--form-control-border-radius');
    const padding = rootStyles.getPropertyValue('--form-control-padding');

    const borderArr = border.split(' ');
    const fontFamilyArr = fontFamily.split(',');    
    
    return {
        backgroundColor: _getSassVar(bgColor),
        color: _getSassVar(color),
        fontFamily: _getSassVar(fontFamily),
        fontSize: _getSassVar(fontSize),
        fontWeight: _getSassVar(fontWeight),
        lineHeight: _getSassVar(lineHeight),
        border: _getSassVar(border),
        borderSize: _getSassVar(borderArr[0] || "0"),
        borderStyle: _getSassVar(borderArr[1] || "none"),
        borderColor: _getSassVar(borderArr[2] || border),
        borderRadius: _getSassVar(borderRadius),
        padding: _getSassVar(padding),
        width: "99.85%",
        font: `${_getSassVar(fontFamilyArr[0])}:${_getSassVar(fontWeight)}`,
    }
}

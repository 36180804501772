import React,{useContext} from 'react';
import { useHistory } from 'react-router-dom';
import {Container, Col, Row, Card, Button} from 'react-bootstrap';
import format from 'date-fns/format';
import { FileURLContext } from '../../../../contexts/FileURLContext';

import './RegisterCards.scss';

export const RegisterCards = (props) => {
  const imageURL = useContext(FileURLContext);
	let history = useHistory();

    const datesFormatter = (startDate, endDate) => {
        let startFormatted = format(new Date(startDate), "ccc MM/dd/yyyy");
        let endFormatted = format(new Date(endDate), "ccc MM/dd/yyyy");

        if(startFormatted === endFormatted) return startFormatted;
        else return startFormatted + " - " + endFormatted;
    }

    const clickHandler = (event_id) => {
        history.push(`/p/event-register?event=${event_id}`);
    }

    return (
      <Container fluid className="register-cards">
        {/*!props.parent && <h4>Register For Upcoming Events</h4>*/}
        <Row className="register-cards-row">
          {props.events?.map((event, i) => (
            <Col xs={12} sm={12} md={6} lg={4} xl={3} className="mb-3" key={`reg-ev-card-${i}`}>
              <Card className="standout mb-3 event-list-card" onClick={() => clickHandler(event.id)}>
                <Card.Body>
                  <Card.Title>{event.name}</Card.Title>
                    <p className="reg-ev-main-txt" dangerouslySetInnerHTML={{ __html: event.description }}/>
                    <Card.Text>
                      <span className="reg-ev-card-date">
                        <i className="far fa-map-marker-alt" />
                        {
                          props.locations?.find(
                            (location) => location.id === event.location_id
                          )?.name
                        }
                      </span>
                      <br />
                      <span className="reg-ev-card-date">
                        <i className="far fa-calendar" />
                        {datesFormatter(event.start_datetime, event.end_datetime)}
                      </span>
                  </Card.Text>
                  <Button variant="primary" className="w-100" onClick={() => clickHandler(event.id)}>Event Details</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
};

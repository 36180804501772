import React, {useCallback, useState, useEffect} from 'react';
import {Card, Form, Button} from 'react-bootstrap';

import Coupon from './Coupon';
import GiftCard from './GiftCard';
import PaymentButton from './PaymentButton';
import CCPayment from '../../../CCPayment';
import styles from './Totals.module.scss';

const Totals = React.forwardRef((props, ref) => {
    const {submit} = props;

    const [collectJS, setCollectJS] = useState();
    const [token, setToken] = useState();
    const [validated, setValidated] = useState(false);
    const [giftCardsApplied, setGiftCardsApplied] = useState();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showCCForm, setShowCCForm] = useState(false);
    const [paymentFields, setPaymentFields] = useState({});
    const [finalTotal, setFinalTotal] = useState(0);
    
    const paymentHandler = useCallback(response => {
        if (response.isToken && response.token) setToken(response.token);
        if (response.collectJS) setCollectJS(response.collectJS);
        setPaymentFields(prev=>{
            const _prev = {...prev};
            if (response.collectJS) _prev.collectJS = response.collectJS;
            if (response.token) _prev.token = response.token;
            return {..._prev, ...response};
        });
    }, []);

    const validationHandler = useCallback(isValid => {
        setValidated(isValid || false);
    },[]);

    const paymentClickHandler = useCallback((e, method) => {
        e.preventDefault();

        setToken(null);
        setCollectJS(null);

        if (method.id === "paynow") {
            setShowCCForm(!showCCForm);
            setPaymentMethods(prev=>{
                const _prev=[...prev];
                _prev.forEach(a=>a.active = false);
                return [..._prev];
            });
        } else {
            setShowCCForm(false);
            if (method.payment_profile_id){
                setPaymentFields(prev=>{
                    if (prev.collectJS) setCollectJS(prev.collectJS);
                    return ({...prev, payment_profile_id: method.payment_profile_id})
                });
                setPaymentMethods(prev=>{
                    const _prev=[...prev];
                    _prev.forEach(a=>a.active = a.payment_profile_id === method.payment_profile_id?true:false);
                    return [..._prev];
                });
                setValidated(true);
                //submit(token, {...paymentFields, payment_profile_id: method.payment_profile_id});
            }
        }
    },[showCCForm]);

    useEffect(() => {
        let _pmethods=[];
        //if (props.payment_methods) _pmethods = [...props.payment_methods]; // enable when add a card is working
        _pmethods.push({id: "paynow", name: _pmethods.length===0?"Pay Now":"Use a different card", type: null});

        setPaymentMethods(_pmethods);
    }, [props.payment_methods]);

    useEffect(() => {
        if (!paymentFields?.collectJS && window.CollectJS){
            window.CollectJS.configure({
                variant: 'inline',
                price: props.order?.total_price|| undefined,
                currency: 'USD',
                country: 'US',
            });
            setPaymentFields(prev=>({...prev, collectJS: window.CollectJS}));
            setCollectJS(window.CollectJS);
        }
    }, [paymentFields?.collectJS, props.order?.total_price]);

    useEffect(() => {
        let _total = props.order?.total_price || 0;
        if (giftCardsApplied?.total > 0){
            _total -= giftCardsApplied.total;
            if (_total < 0) _total = 0;
        }
        setFinalTotal(_total);
    }, [props.order?.total_price, giftCardsApplied?.total]);

    if (!props?.order || !props?.order?.items) return null;

    return (
        <Card ref={ref} className={`checkout-totals ${styles.wrapper} ${props.is_preview?styles.preview:""}`} style={props.style || null}>
            {props.success && props.success.length > 0 &&
                <>
                    <h5 className="title text-center">Your order was placed successfully!</h5>
                    {props.success.map((s, i) => (
                        <React.Fragment key={`transaction-success-${i}`}>
                            <div className={styles.container}>
                                <div className={styles.fees}>
                                    <span>Transaction #</span>
                                    <span>{s.transaction_id}</span>
                                </div>
                                {s.nmi_transaction_id &&
                                    <div className={styles.fees}>
                                        <span>Gateway Receipt #</span>
                                        <span>{s.nmi_transaction_id}</span>
                                    </div>
                                }
                                <div className={styles.fees}>
                                    <span>Amount</span>
                                    <span>${s.amount.toFixed(2)}</span>
                                </div>
                                <hr/>
                            </div>
                        </React.Fragment>
                    ))}
                </>
            }
            {!props.success && props.order &&
                <>
                    <div className={styles.container}>
                        <div className={styles.subtotal}>
                            <span>Subtotal</span>
                            <span>${props.order?.subtotal_price?.toFixed(2)}</span>
                        </div>
                        <div className={styles.fees}>
                            <span>Taxes</span>
                            <span>${props.order?.tax_total?.toFixed(2)}</span>
                        </div>
                        {props.order?.price_adjustments?.map((adjustment, i)=>(
                            <div key={`pa-order-${i}`} className={styles.fees}>
                                <span>{adjustment.price_adjustment_type_name}</span>
                                <span>
                                    {adjustment.amount > 0 ? "" : "-"}
                                    ${Math.abs(adjustment.amount).toFixed(2)}
                                </span>
                            </div>
                        ))}
                        {props.order.total_discount> 0 &&
                            <div className={styles.fees}>
                                <span>Discounts</span>
                                <span>-${props.order?.total_discount?.toFixed(2)}</span>
                            </div>
                        }
                        {/*
                        <div className={styles.fees}>
                            <span>Admin Fee</span>
                            <span>${props.order?.admin_fee_total?.toFixed(2)}</span>
                        </div>
                        */}
                        {giftCardsApplied && giftCardsApplied.total > 0 &&
                            <div className={styles.fees}>
                                <span>Gift Card Balance</span>
                                <span>-${giftCardsApplied.total?.toFixed(2)}</span>
                            </div>
                        }
                        <div className={styles.total}>
                            <span>Total</span>
                            <span>
                                ${finalTotal?.toFixed(2)}
                            </span>
                        </div>
                    </div>
                    {props.error &&
                        <>
                            <hr/>
                            <span className="error-text">{props.error}</span>
                        </>
                    }
                    {/*
                    <hr/>
                    <div className={styles.form}>
                        <Coupon {...props} />
                    </div>
                    */}
                    <hr/>
                    <div className={styles.form}>
                        <GiftCard {...props} onSelected={setGiftCardsApplied} />
                    </div>
                    {paymentMethods.length > 0 &&
                        <>
                            <hr/>
                            {paymentMethods.map((method, i) => (
                                <PaymentButton key={`payment-button-${i}`} {...method} onClick={paymentClickHandler} />
                            ))}
                        </>
                    }
                    {showCCForm &&
                        <div className={styles["ccform-wrapper"]} /*style={{display: showCCForm?"block":"none"}}*/>
                            <h5 className="title text-center">Payment Information</h5>
                            <CCPayment 
                                {...props}
                                style={null}
                                forceMobile 
                                hideButton 
                                callback={paymentHandler} 
                                validationCallback={validationHandler} 
                                price={+finalTotal} 
                            />
                        </div>
                    }
                    <Button variant="primary" size="lg" className="btn-block w-100 mt-3" disabled={props.processing || props.loading || (finalTotal>0 && !collectJS) || !validated} onClick={()=>submit(token, paymentFields, giftCardsApplied)}>Process Payment</Button>
                </>
            }
        </Card>       
    );
});

export default Totals;
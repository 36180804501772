import React from 'react'
import { format } from 'date-fns'

export const DisplayOrdersDetailTable = (props) => {
    return (
        <table className="uo-order-det-table">
                    <tbody>
                    <tr>
                        <th> Purchaser </th>
                        <td> {props.currentOrder.user_fname} {props.currentOrder.user_lname}</td>
                    </tr>
                    {props.currentOrder.payment_method_id!==7 && //Prices are unneccesary with token redeemed products as this info isn't saved in that order but in the token's purchase.
                        <tr>
                            <th> Total Transaction</th>
                            {props.currentOrder.total_price?.toString().includes(".") ? 
                                <td>${props.currentOrder.total_price.toFixed(2)}</td> 
                                : 
                                <td>${props.currentOrder.total_price}.00</td>  }
                        </tr>
                    }
                    {props.transactionType &&
                        <tr>
                            <th>Transaction Type</th>
                            <td>{props.transactionType}</td>
                        </tr>
                    }
                    {props.paymentMethod && 
                        <tr>
                            <th>Payment Method</th>
                            <td>{props.paymentMethod}</td>
                        </tr>
                    }
                    {props.currentOrder.payment_method_id !== 7 &&
                        <>
                            <tr>
                                <th>Total Tip</th>
                                {props.currentOrder.tip?.toString().includes(".") ?
                                    <td>${props.currentOrder.tip}</td>
                                    :
                                    <td>${props.currentOrder.tip}.00</td>
                                }
                            </tr>
                            <tr>
                                <th>Total Tax</th>
                                {props.currentOrder.tax_total?.toString().includes(".") ? 
                                    <td>${props.currentOrder.tax_total.toFixed(2)}</td> 
                                    : 
                                    <td>${props.currentOrder.tax_total}.00</td>}
                            </tr>
                            {props.currentOrder?.price_adjustments?.map((adjustment, i)=>(
                                <tr key={`pa-order-${i}`}>
                                    <td>{adjustment.price_adjustment_type_name}</td>
                                    <td>
                                        {adjustment.amount > 0 ? "" : "-"}
                                        ${Math.abs(adjustment.amount).toFixed(2)}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <th>Shipping Total</th>
                                {props.currentOrder.shipping_total?.toString().includes(".") ? 
                                    <td>${props.currentOrder.shipping_total.toFixed(2)}</td> 
                                    : 
                                    <td>${props.currentOrder.shipping_total}.00</td> }
                            </tr>
                            {/*<tr>
                                <th>Admin Fee Total</th>
                                {props.currentOrder.admin_fee_total?.toString().includes('.') ? 
                                    <td>${props.currentOrder.admin_fee_total.toFixed(2)}</td> 
                                    : 
                                    <td>${props.currentOrder.admin_fee_total}.00</td>}
                            </tr>*/}
                            {props.isPatron ? null :
                                <tr>
                                    <th>Transaction Completed By</th>
                                    <td>{props?.transaction?.admin_auth}</td>
                                </tr>
                            }
                        </>
                    }
                    {/*(props.refunded === true || props.partialRefund === true) ? 
                        <>
                            <tr>
                                <th>Admin Fee Refunded</th>
                                {props.currentOrder.admin_fee_refunded?.toString().includes('.') ?
                                    <td>${props.currentOrder.admin_fee_refunded.toFixed(2)}</td>
                                    :
                                    <td>${props.currentOrder.admin_fee_refunded}.00</td>
                                }
                            </tr>
                        </>
                    :
                    null   
                    */}
                    <tr>
                        <th>Transaction Date</th>
                        {props.currentOrder.created_at && 
                            <td>{format(new Date(props.currentOrder.created_at), 'MM/dd/yyyy')}</td>}
                    </tr>
                    <tr>
                        <th>Transaction Updated</th>
                        {props.currentOrder.updated_at && 
                            <td>{format(new Date(props.currentOrder.updated_at), "MM/dd/yyyy")}</td>}
                    </tr>
                    {props.isPatron ?
                        null
                    :
                        <tr>
                            <th>Memo</th>
                            {props.currentOrder.memo ? 
                                <td>{props.currentOrder.memo}</td> 
                                : 
                                <td>No memos for this order</td> }
                        </tr>
                    }   
                    </tbody>
                </table>
    )
}

export default DisplayOrdersDetailTable

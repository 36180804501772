import { createSelector } from 'reselect';
import { startOfWeek, endOfWeek, formatISO, startOfDay, endOfDay, addDays, differenceInMinutes, parseISO } from 'date-fns';

// SELECTORS FOR REDUX

// given the node of a tree object and the array of parent locations this will return the correct event
function findChild(parent, pointer) {
    if(parent && Array.isArray(pointer) && pointer.length>0) {
        let child = parent.children[pointer.shift()];
        return findChild(child, pointer);
    }
    return parent;
}

// given the node of a tree object and the array of parent locations this will return the correct event
function findNames(parent, pointer, array) {
    let newarray = [...array, parent.name];
    if(parent && Array.isArray(pointer) && pointer.length>0) {
        let child = parent.children[pointer.shift()];
        return findNames(child, pointer, newarray);
    }
    return newarray;
}

// given the node of a tree object and the array of parent locations this will return any fees found
function findFees(parent, pointer, array) {
    let newarray = parent.has_fee && pointer.length>0 ? [...array, {name: parent.name, price: parseFloat(parent.price)}] : [...array];
    if(parent && Array.isArray(pointer) && pointer.length>1) {
        let child = parent.children[pointer.shift()];
        return findFees(child, pointer, newarray);
    }
    return newarray;
}

// returns a copy of the node that is being worked on, given the master tree and current_location_pointer array
export const selectCurrentEvent = createSelector([state => state.eventwizard.master, state => state.eventwizard.current_pointer], (master_event, current_pointer) => {
    return findChild(master_event, [...current_pointer]);
});

// returns a copy of the parent node to the one that is being worked on, given the master tree and current_location_pointer array
export const selectCurrentParent = createSelector([state => state.eventwizard.master, state => state.eventwizard.current_pointer], (master_event, current_pointer) => {
    if (current_pointer.length>0) {
        return findChild(master_event, [...current_pointer.slice(0,current_pointer.length-1)]);
    }
    return null;
});

// returns an array of names for the breadcrumb for the current event, given the master tree and current_location_pointer array
export const selectEventNameList = createSelector([state => state.eventwizard.master, state => state.eventwizard.current_pointer], (master_event, current_pointer) => {
    return findNames(master_event, [...current_pointer], []);
});

// returns an array of fees for all parents and grandparents of the currentEvent
export const selectParentFees = createSelector([state => state.eventwizard.master, state => state.eventwizard.current_pointer], (master_event, current_pointer) => {
    return findFees(master_event, [...current_pointer], []);
});



// 
// SELECTORS FOR SERVICES
// 

export const selectRangeStart = createSelector([state => state.serviceBooking.current_booking.selected_date, state => state.serviceBooking.current_booking.small_screen],
    (date, isSmallScreen) => {
        let startDate = isSmallScreen ? startOfDay(date) : startOfWeek(date);
        return formatISO(startDate);
    }
);

export const selectRangeEnd = createSelector([state => state.serviceBooking.current_booking.selected_date,
    state => state.serviceBooking.current_booking.small_screen,
    state => state.serviceBooking.current_booking.small_screen_num_days],
    (date, isSmallScreen, numDays) => {
        let endDate = isSmallScreen ? endOfDay(addDays(date, numDays-1)) : endOfWeek(date);
        return formatISO(endDate);
    }
);

export const selectCurrentLocationConflictEvents = createSelector([state => state.serviceBooking.current_booking.selected_location, state => state.serviceBooking.current_booking.conflict_events],
    (location, events) => {
        if (!events || events.length===0) return null;
        let found = events.find(event => event.location_id===location);
        return (found!=='undefined') ? found.datetime_ranges : null;
    }
);

export const selectTimeSlot = createSelector([state => state.serviceBooking.current_booking.selected_slots],
    (slots) => {
        let obj = { start: null, end: null, duration: 0 };
        if (slots?.length>0) slots.map(slot => {
            let start = parseISO(slot.start);
            let end = parseISO(slot.end);
            if (obj.start===null || start < obj.start) {
                obj.start = start;
            }
            if (obj.end===null || end > obj.end) {
                obj.end = end;
            }
            obj.duration = differenceInMinutes(obj.end, obj.start);
            return slot;
        });
        return obj;
    }
);

export const numberOfTokens = createSelector([state => state.serviceBooking.current_booking.selected_slots],
    (slots) => {
        return slots?.length || 0;
    }
);



// 
// SELECTORS FOR SHOPPING CART
// 

export const selectNumberOfItemsInCart = createSelector([state => state.serviceBooking.current_booking.service, state => state.pos, (state, register_id) => register_id],
    (bookingService, pos, register_id) => {
        if (!register_id) return 0;

        let tokenProducts = [];
        let items = pos?.[register_id]?.items;
        
        //if (!items) return 0;

        if (bookingService?.hasOwnProperty('product_id')) {
            tokenProducts = items.filter(item => item.variant_id === bookingService.default_product_variant_id);
        }
        let numberTokenProducts = tokenProducts.length;

        let returnValue = items.length - (numberTokenProducts>0 ? numberTokenProducts - 1 : 0);
        return returnValue;
    }
);


// 
// SELECTORS FOR BOOKING
// 

export const selectTokensForCurrentBooking = createSelector([state => state.serviceBooking.current_booking, state => state.pos.available_tokens],
    (currentBooking, availableTokens) => {
        let tokens = [];
        // let product_id = currentBooking.service ? currentBooking.service.default_product_variant_id : null;
        // if (availableTokens && product_id) tokens = availableTokens.filter(token => token.product_variant_id===product_id);

        let product_ids = currentBooking?.service?.products?.map(product => product.variants[0].id);
        if(availableTokens && product_ids){
            for(let i=0; i < product_ids.length; i++){
                let tempTokens = availableTokens.filter(token => token.product_variant_id === product_ids[i]);
                tokens = [...tokens, ...tempTokens]
            }
        }

        let toRedeem = 0;
        let toBuy = 0;
        if (currentBooking.selected_slots?.length > 0) {
            let numSelectedSlots = currentBooking.selected_slots.length / currentBooking.service.timeslots_for_token;
            // toRedeem = tokens.length >= numSelectedSlots ? numSelectedSlots : tokens.length;
            toRedeem = tokens.length >= numSelectedSlots ? numSelectedSlots : 0;
            toBuy = numSelectedSlots - toRedeem;
        }

        return {
            available: tokens.length,
            toRedeem: toRedeem,
            toBuy: toBuy,
            tokens: tokens
        }
    }
);
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FileURLContext } from '../../contexts/FileURLContext';
import { parseCmsComponent, getPageById } from '../../utils/cms';

const CMSParser = (props) => {
    const companyStuff = useContext(FileURLContext);
    const [pageContent, setPageContent] = useState();
    const [loaded, setLoaded] = useState(false);

    const auth = useSelector(state => state.auth);

    useEffect(() => {
        const _loadPage = async () => {
            const response = await getPageById({ id: props.page_id, page_type_id: 2 }, false);

            // check if restricted access is enabled
            if (response?.[0]?.restricted_access){
                if (auth.logged_in){
                    let has_group = false;
                    if (response[0]?.page_access_groups?.length>0){
                        auth.user?.groups?.forEach(group => {
                            if (group.id && response[0].page_access_groups.some(a=>a.group_id === group.id)) has_group = true;
                        });
                    } else has_group = true;

                    let has_role = false;
                    if (response[0]?.page_access_roles?.length>0){
                        auth.user?.roles?.forEach(role => {
                            if (role.id && (response[0].page_access_roles.some(a=>+a.role_id === +role.id) || role.id<=3)) has_role = true;
                        });
                    } else has_role = true;

                    if (!has_group || !has_role){
                        // no access, redirect page
                        if (response[0]?.redirect_to) window.location.href=response[0].redirect_to;
                        else setPageContent("Access Denied");
                        return false;
                    }
                } else {
                    // not logged in, redirect page
                    if (response[0]?.redirect_to) window.location.href=response[0].redirect_to;
                    else setPageContent("Access Denied");
                    return false;
                }
            }

            if (response?.[0]?.content) {
                const data = typeof response[0].content === "string" ? JSON.parse(response[0].content) : response[0].content;
                document.title = response[0].title+" - "+companyStuff.companyName;
                const res = await parseCmsComponent(data, companyStuff, props);
                setPageContent(res);
            }
        }

        if (props.page_id && companyStuff) _loadPage();
    }, [props, companyStuff, auth]);

    const content = useMemo(() => {
        setLoaded(true);
        if (!pageContent) return null;
        return pageContent;
    }, [pageContent]);

    useEffect(() => {
        return() => {
            setPageContent(null);
            setLoaded(false);
        }
    },[]);

    if (!loaded) return "Loading...";
    if (loaded) return content || null;
};

export default CMSParser;

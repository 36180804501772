import React, {useState, useEffect, useRef, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux';

import * as actions from '../../../../../../store/actions';
import Pos from '../../../../../../api/Pos';
import { EachPayment } from './EachPayment';
import { cancelSplit } from '../../SplitPaymentUtils';

export const AssignPaymentAmounts = (props) => {

    const { setAlreadySplit, register, type }=props;

    const dispatch=useDispatch();
    const mountedRef=useRef(false);
    const totalOrder=useSelector(state=>state.pos[register].orderAll);
    const ordersSplit=useSelector(state=>state.splitPayments.ordersSplit);
    const payees=useSelector(state=>state.splitPayments.payees);
    const spErrors=useSelector(state=>state.splitPayments.errors);
    const allPaid=useSelector(state=>state.splitPayments.allPaid);
    const allOrders=useSelector(state=>state.splitPayments.allOrders);
    
    const [fieldsetDisable, setFieldsetDisable]=useState(false)
    const [firstLoad, setFirstLoad]=useState(true);
    const [totalPrice, setTotalPrice]=useState();
    const [payeeTotals, setPayeeTotals]=useState([]);
    const [remainder, setRemainder]=useState();
    const [checkedAutoCalc, setCheckedAutoCalc]=useState(false);

//#region useCallbacks    
    //function is named for approximate because paying cash/card and admin fees may effect the final price.
    const calculateApproximatePrices=useCallback(()=>{
        if(totalPrice){
            let newPrice = 0;
            payeeTotals.forEach((total)=>{
                newPrice += +total.amount
            })
            let remaining = +totalPrice - +newPrice;
            let rounded = remaining.toFixed(2);
            setRemainder(+rounded);
            dispatch(actions.setSplitPaymentRemaining(+rounded));
            console.log(rounded);
        }
    },[payeeTotals, totalPrice, dispatch]);


    //because the payees are frequently looped and modified, a function to keep them in the proper order for display purposes. 
    const sortPayees=useCallback(async(payeeType)=>{
        if(payeeType && payeeType.length>0){
            payeeType.sort((a,b)=>{
                if(a.payeeOrder < b.payeeOrder) return -1
                if(a.payeeOrder > b.payeeOrder) return 1
                else return 0;
            });
        }
    },[]);    

    //will see if the order is already split and apply that data if needed
    //This is where the payees can and will get out of order.  There's no way to know what order they were before they were passed into the DB and in what order the calls will complete.
    const checkForSplits=useCallback(async()=>{
        if(firstLoad){
            if(totalOrder){
                let amount = (totalOrder.subtotal_price + totalOrder.tax_total)
                setTotalPrice(+amount);
                setRemainder(+amount);
                dispatch(actions.setSplitPaymentRemaining(+amount));
            }
            let tempOrders =[];
            let i = 1;
            if(totalOrder.split_order_ids.length > 0 && totalOrder.split_order_ids.length === payees.length && allOrders.length===0){
                    setAlreadySplit(true);
                    totalOrder.split_order_ids.forEach(async(id)=>{
                        try{
                            let response = await Pos.order.get({id: id});
                            if(!response.errors && mountedRef.current){
                                let paid = false;
                                if(response.data.order_status_id !==null && response.data.order_status_id !== 1)paid = true;
                                let temp = {payeeOrder: i, identifier: response.data.memo, amount: response.data.subtotal_price + response.data.tax_total, paid: paid, id: response.data.user_id, order: response.data}
                                tempOrders.push(temp)
                            }
                        }catch(ex){
                            console.error(ex);
                        }finally{
                            if(tempOrders.length === payees.length && mountedRef.current){
                                sortPayees(tempOrders)
                                setPayeeTotals(tempOrders);
                            }
                        }
                        i++;
                    })
            }else if(totalOrder.split_order_ids.length > 0 ){
                payees.forEach((payee)=>{
                    let temp = {payeeOrder: payee.payeeOrder, identifier: payee.identifier, amount: 0, paid: false, id: payee.id}
                    if(payee.order) temp.order = payee.order;
                    if(payee.amount) temp.amount =payee.amount
                    tempOrders.push(temp)
                });
                sortPayees(tempOrders);
                setPayeeTotals(tempOrders);
            }
            setFirstLoad(false);
        }
    },[totalOrder, firstLoad, allOrders, payees, dispatch, setAlreadySplit, sortPayees]);


//#endregion useCallbacks

//#region useEffects

    //first load
    useEffect(()=>{
        mountedRef.current = true

        //ensuring that the other option in this multistep doesn't stop validation;
        dispatch(actions.setSplitPaymentAllItemsAssigned("Yes"));
        return()=> {
            mountedRef.current = false
            setPayeeTotals([]);
        }
    },[dispatch]);

    useEffect(()=>{
        if(allPaid === "Partial" || allPaid === "Yes") setFieldsetDisable(true);
    },[allPaid]);

    useEffect(()=>{
        checkForSplits()
    },[checkForSplits, totalOrder]);

    useEffect(()=>{
        if(mountedRef.current) calculateApproximatePrices();
    },[calculateApproximatePrices, payeeTotals]);

    useEffect(()=>{
        //Using .01 and -.01 to account for JS math.  Even simple math is often ending up .9999 instead of 0.  The new orders will be passed to the backend for accuracy.
        if(payeeTotals.length >0 && remainder < .01 && remainder >-.01 ){
            let missingPayment=false;
            payeeTotals.forEach((payee)=>{
                if(payee.amount === 0) missingPayment=true
            })
            if(!missingPayment) {
                sortPayees(payeeTotals);
                dispatch(actions.setSplitPaymentPayees(payeeTotals));
            }
        }
        calculateApproximatePrices();
    },[remainder, payeeTotals, dispatch, calculateApproximatePrices, sortPayees]);

    //sortPayees is not written as a callback and listed as a dependency because it's having parameters passed in rather than relying on something that can be put in its dependency array
    useEffect(()=>{
        if(payees && payees.length > 0){
            sortPayees(payees)
        }
    },[payees,sortPayees])

//#endregion useEffects

    /**if auto calculate is checked, uses this to do the math for the user instead */
    const autoCalculation=(payee, amount)=>{
        let tempTotals = [];
        let temp;
        let remaining = totalPrice - +amount;
        payees.forEach((localPayee)=>{
            if(localPayee.payeeOrder===payee.payeeOrder){
                temp = {payeeOrder: localPayee.payeeOrder, identifier: localPayee.identifier, amount: (+amount).toFixed(2), paid: false, id: localPayee.id}
                tempTotals.push(temp);
            }
        })
        //remove the one we just assigned
        let remainingPayees = payees.filter((localPayee)=>{
            if(localPayee.payeeOrder!==payee.payeeOrder) return localPayee;
            return null;
        })
        //remove one from the length for the final one
        for(let i=0; i<remainingPayees.length; i++){
            let leftover = (remaining.toFixed(2) || 0)
            //assigning all of what's left to the last person
            if(i === remainingPayees.length-1){
                temp = {payeeOrder: remainingPayees[i].payeeOrder, identifier: remainingPayees[i].identifier, amount: +leftover, paid: false, id:remainingPayees[i].id}
            }
            else{
                let dividedAmount = (remaining / (remainingPayees.length)).toFixed(2);
                remaining -= +dividedAmount;
                temp = {payeeOrder: remainingPayees[i].payeeOrder, identifier: remainingPayees[i].identifier, amount: +dividedAmount, paid: false, id:remainingPayees[i].id}
            }
            tempTotals.push(temp);
            setRemainder(0);
        }
        return tempTotals
    }

    /**Assign amounts/orders to the payee that's being added into the input box - can divert to autocalculate */
    const assignPayeeTotals=(payee, amount)=>{
        let tempTotals = [];
        if(checkedAutoCalc){
            tempTotals = autoCalculation(payee, amount);
        }else{
            for(let i=0; i < payeeTotals.length; i++){
                if(payeeTotals[i].payeeOrder===payee.payeeOrder){
                    let temp = {payeeOrder: payeeTotals[i].payeeOrder, identifier: payeeTotals[i].identifier, amount: +amount, paid: false, id: payee.id}
                    tempTotals.push(temp)
                }
                else tempTotals.push(payeeTotals[i]);
            };
            let alreadyHas = tempTotals.some(entry=>{
                return entry.payeeOrder === payee.payeeOrder
            })
            if(!alreadyHas) tempTotals.push({payeeOrder: payee.payeeOrder, identifier: payee.identifier, amount: +amount, paid: false, id: payee.id});
        }
        if(mountedRef.current) {
            sortPayees(tempTotals)
            setPayeeTotals(tempTotals)
        };
    }

    const passCancelSplit=()=>{
        cancelSplit(totalOrder.id)
    }

  return (
    <fieldset disabled={fieldsetDisable}>
        <div className="amount-assign-div">
            {type !== "multiMany" && 
                <p>The payees will be splitting ${+totalPrice?.toFixed(2)}.  How would they like to divide that?</p>
            }
            <p className="bold">Please note, and explain to the customers, that the amounts may vary slightly based on paying with cash or card.</p>
            <p>Total to still be alloted: ${+remainder?.toFixed(2)}</p>
            {payees && payees?.map((payee)=>{
                let payeeCopy = {...payee};
                return (
                    <React.Fragment key={`assign-payment-${payee.payeeOrder}`}>
                        <EachPayment 
                            payee={payeeCopy} 
                            assignPayeeTotals={assignPayeeTotals} 
                            cancelSplit={passCancelSplit}
                            ordersSplit={ordersSplit} 
                            setAlreadySplit={setAlreadySplit}
                        />
                    </React.Fragment>
                )
            })}
            {/* <input type="checkbox" name="auto-calc" onChange={()=>setCheckedAutoCalc(!checkedAutoCalc)} />
            {" "} 
            <label htmlFor='auto-calc'>Automatically Calculate Totals</label> */}
            <div className="sp-error">
                <span>{spErrors?.leftoverRemainder}</span>
                <span>{spErrors?.negativeRemainder}</span>
                <span>{spErrors?.noAmountOnPayee}</span>
            </div>
        </div>
    </fieldset>
  )
}

import React, { useState } from 'react'
import { format } from 'date-fns-tz';

const OrderStatuses = ({currentOrder = null, orderStatuses}) => {

    const [selectedStatus, setSelectedStatus] = useState(null)

    const handleStatusClick=(statusId)=>{
        if(selectedStatus === statusId){
            setSelectedStatus(null)
        }else setSelectedStatus(statusId)
    }

    return (
        <div className="order-status-wrapper">
            {currentOrder?.order_status_history?.length > 0 ?
                <>
                    <hr />
                    <h5>
                        Status History
                    </h5>
                    <div className="status-map">
                        {currentOrder?.order_status_history?.map(status=>(
                            <div key={`order-status-${status.id}`} className="each-status">
                                <div className="outlined" onClick={()=>{handleStatusClick(status.id)}}>
                                    <p>
                                        <span className="bold">
                                            {status?.order_status_name}
                                        </span>
                                        <br />
                                        {format(new Date(status?.logged_at), "M/d/yy")}
                                        <br />
                                        {format(new Date(status?.logged_at), "h:mm a z")}
                                    </p>
                                </div>
                                <div className="status-notes">
                                    {selectedStatus === status.id &&
                                        <>
                                            Memo: {status?.memo ? status.memo : "----" }
                                        </>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </>
                :
                <h4>
                   Order Status: {orderStatuses?.find(status=> status.id === currentOrder.order_status_id)?.name}
                </h4>
            }

        </div>
    )
}

export default OrderStatuses
import React, {useCallback, useState, useRef, useEffect} from 'react';
import {Card, Row, Col, Container, Button} from 'react-bootstrap';

import FormGroupText from '../../../../FormGroupText';

import APIGiftCards from '../../../../../../../../api/GiftCards';
import styles from "./GiftCard.module.scss";

const GiftCard = props => {
    const {onSelected} = props;
    
    const gcRef = useRef();

    const [error, setError] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [giftCards, setGiftCards] = useState([]);

    const checkHandler = useCallback(async e => {
        e.preventDefault();

        const code = gcRef?.current?.value;
        if (!code) return;
        
        setSubmitting(true);
        const res = await APIGiftCards.get({card_code: code, with_balance: 1});
        if (res?.errors) setError(res.errors);
        else if (res?.data?.giftcards?.data.length) {
            setError(null);
            setGiftCards(prev=>{
                const _prev = [...prev];
                if (!_prev.find(a=>a.id===res.data.giftcards.data[0].id)) _prev.push(res.data.giftcards.data[0]);
                return [..._prev];
            });
        }
        gcRef.current.value = "";
        gcRef.current.defaultValue = "";
        setSubmitting(false);
    }, []);

    const removeHandler = useCallback(async (e, id) => {
        e.preventDefault();
        setGiftCards(prev=>{
            const _prev = [...prev];
            const index = _prev.findIndex(a=>a.id===id);
            if (index>=0) _prev.splice(index, 1);
            return [..._prev];
        });
    }, []);

    useEffect(() => {
        if (giftCards){
            let _total = 0;
            giftCards.forEach(g=>{
                _total += g.current_balance;
            });
            onSelected({
                total: _total,
                gift_cards: giftCards
            });
        }
    }, [giftCards, onSelected]);

    useEffect(()=>{
        return() => {
            setGiftCards([]);
            setSubmitting(false);
            setError(null);
        }
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col>
                    <FormGroupText 
                        inputRef={gcRef}
                        page_id={props.page_id} 
                        name="card_code" 
                        type="text" 
                        label="Gift Card Code" 
                        feedback="Please enter a valid code" 
                        disabled={props.submitting || submitting}
                        value={""} 
                        append={
                            <Button variant="light" size="small" disabled={props.submitting || submitting} onClick={checkHandler}>Check</Button>
                        }
                    />
                    {error && <div className="error-text mb-3">{error}</div>}
                </Col>
            </Row>
            {giftCards?.map((giftCard, i) => (
                <Row key={`giftc-${giftCard.id}-${i}`}>
                    <Col>
                        <Card className={styles.wrapper}>
                            <Card.Body>
                                <div>{giftCard.card_code}</div>
                                <div>
                                    Balance: <span className="bold">${giftCard.current_balance.toFixed(2)}</span>
                                </div>
                            </Card.Body>
                            <Card.Body className={`text-link ${styles.toolbar}`}>
                                <Button variant="link" href="#!" disabled={props.submitting || submitting} onClick={e=>removeHandler(e, giftCard.id)}>Remove</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ))}
        </Container>
    );
}

export default GiftCard;
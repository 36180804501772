import React from 'react';
import { format } from 'date-fns';

export const MobileDisplayOrders = (props) => {
  return (
      <>
        <h5>
            <i className="far fa-receipt" />
            {" "}
            Order #{props.currentOrder.id}
        </h5>
        <h5>Order Status: {props.currentOrder.status_name}</h5>
        <div className="uo-order-table-wrapper">
            <table className="uo-order-det-table">
                <tbody>
                <tr>
                    <th> Purchaser </th>
                    <td className="pl-4"> {props.currentOrder.user_fname} {props.currentOrder.user_lname}</td>
                </tr>
                {props.currentOrder.payment_method_id!==7 && //Prices are unneccesary with token redeemed products as this info isn't saved in that order but in the token's purchase.
                    <>
                        <tr>
                            <th> Total</th>
                            {props.currentOrder.total_price?.toString().includes(".") ? 
                                <td className="pl-4">${props.currentOrder.total_price.toFixed(2)}</td> 
                                : 
                                <td className="pl-4">${props.currentOrder.total_price}.00</td>  }
                        </tr>
                    </>
                }
                {props.transactionType &&
                    <>
                        <tr>
                            <th>Type</th>
                            <td className="pl-4">{props.transactionType}</td>
                        </tr>
                    </>
                }
                {props.paymentMethod && 
                    <>
                        <tr>
                            <th>Payment Method</th>
                            <td className="pl-4">{props.paymentMethod}</td>
                        </tr>
                    </>
                }
                {props.currentOrder.payment_method_id !== 7 &&
                    <>
                        <tr>
                            <th>Tip</th>
                            {props.currentOrder.tip?.toString().includes(".") ?
                                <td className="pl-4">${props.currentOrder.tip}</td>
                                :
                                <td className="pl-4">${props.currentOrder.tip}.00</td>
                            }
                        </tr>
                        <tr>
                            <th>Tax</th>
                            {props.currentOrder.tax_total?.toString().includes(".") ? 
                                <td className="pl-4">${props.currentOrder.tax_total.toFixed(2)}</td> 
                                : 
                                <td className="pl-4">${props.currentOrder.tax_total}.00</td>}
                        </tr>
                        {props.currentOrder?.price_adjustments?.map((adjustment, i)=>(
                                <tr key={`pa-order-${i}`}>
                                    <td>{adjustment.price_adjustment_type_name}</td>
                                    <td className="pl-4">
                                        {adjustment.amount > 0 ? "" : "-"}
                                        ${Math.abs(adjustment.amount).toFixed(2)}
                                    </td>
                                </tr>
                        ))}
                        {/* <tr>
                            <th>Shipping Total</th>
                            {props.currentOrder.shipping_total?.toString().includes(".") ? 
                                <td className="pl-4">${props.currentOrder.shipping_total.toFixed(2)}</td> 
                                : 
                                <td className="pl-4">${props.currentOrder.shipping_total}.00</td> }
                        </tr> */}
                        {/*
                        <tr>
                            <th>Admin Fee Total</th>
                            {props.currentOrder.admin_fee_total?.toString().includes('.') ? 
                                <td className="pl-4">${props.currentOrder.admin_fee_total.toFixed(2)}</td> 
                                : 
                                <td className="pl-4">${props.currentOrder.admin_fee_total}.00</td>}
                        </tr>
                        */}
                        {!props.isPatron &&
                            <tr>
                                <th>Completed By</th>
                                <td className="pl-4">{props.currentOrder.adminAuth}</td>
                            </tr>
                        }
                    </>
                }
                {(props.refunded === true || props.partialRefund === true) ? 
                    <>
                        <tr>
                            <th>Admin Fee Refunded</th>
                            {props.currentOrder.admin_fee_refunded?.toString().includes('.') ?
                                <td className="pl-4">${props.currentOrder.admin_fee_refunded.toFixed(2)}</td>
                                :
                                <td className="pl-4">${props.currentOrder.admin_fee_refunded}.00</td>
                            }
                        </tr>
                    </>
                :
                null   
            }
                <tr>
                    <th>Original Date</th>
                    {props.currentOrder.created_at && 
                        <td className="pl-4">{format(new Date(props.currentOrder.created_at), 'MM/dd/yyyy')}</td>}
                </tr>
                <tr>
                    <th>Updated Date</th>
                    {props.currentOrder.updated_at && 
                        <td className="pl-4">{format(new Date(props.currentOrder.updated_at), "MM/dd/yyyy")}</td>}
                </tr>
                {props.isPatron ?
                    null
                :
                    <>
                        <tr>
                            <th>Memo</th>
                            {props.currentOrder.memo ? 
                                <td className="pl-4">{props.currentOrder.memo}</td> 
                                : 
                                <td className="pl-4">No memos for this order</td> }
                        </tr>
                    </>
                }   
                </tbody>
            </table>
        </div>
    </>
  )
}

export default MobileDisplayOrders

import * as actionTypes from './actionTypes';

export const setAddOn = (index, id) => {
    return {
        type: actionTypes.SET_ADD_ON,
        index: index,
        id: id
    };
};

export const setAddOns = (addOns) => {
    return {
        type: actionTypes.SET_ADD_ONS,
        add_on_categories: addOns
    };
};

export const removeAddOn = (index) => {
    return {
        type: actionTypes.REMOVE_ADD_ON,
        index: index
    };
};

export const addAddOn = () => {
    return {
        type: actionTypes.ADD_ADD_ON
    };
};
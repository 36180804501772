import * as actionTypes from '../actions/actionTypes';

const initialState={
    id: null,
    name: null,
    address_street: null,
    address_street2: null,
    address_city: null,
    address_state: null,
    address_postcode: null,
    admin_fee_rate: null,
    primary_number: null,
    email: null,
    company_id: null,
    config: null
};

const companyReducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.SET_COMPANY_DETAILS:
            return{
                ...state,
                id: action.companyDetails.id,
                name:action.companyDetails.name,
                address_street:action.companyDetails.address_street,
                address_street2:action.companyDetails.address_street2,
                address_city:action.companyDetails.address_city,
                address_state:action.companyDetails.address_state,
                address_postcode:action.companyDetails.address_postcode,
                admin_fee_rate:action.companyDetails.admin_fee_rate,
                primary_number:action.companyDetails.primary_number,
                email:action.companyDetails.email,
                company_id:action.companyDetails.company_id,
            }
        case actionTypes.SET_COMPANY_ID:
            return{
                ...state,
                id: action.id,
            }
        case actionTypes.SET_COMPANY_NAME:
            return{
                ...state,
                name: action.name,
            }
        case actionTypes.SET_COMPANY_CONFIG:
            return{
                ...state,
                config: action.config,
            }
        default: 
            return state;
    }
};

export default companyReducer;
import Pos from "../../../api/Pos"

export const getPickupOrders=async(filters)=>{
    let resp={
        data: null,
        errors:null
    }
    try{
        let response = await Pos.order.get(filters)
        if(response.status === 200 && response.data) resp.data = response.data;
        else if(response.errors) resp.errors = response.errors;
        else resp.errors = "An error has occured retrieving the online orders.";
    }catch(ex){
        console.error(ex);
        resp.errors = ex
    }
    return resp;
}
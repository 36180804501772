import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {Container, Table} from 'react-bootstrap';

const COLUMN_HEADINGS = [
	"Item Name",
	"Price",
];

export const ItemSummary = (props) => {
	//if (!props?.events_display) COLUMN_HEADINGS.splice(1,2);

	if (!props.order) return(      
		<SkeletonTheme color="#e0e0e0">
				<Skeleton height={30} style={{marginBottom:"1rem"}} />
				<Skeleton height={12} count={5} />
		</SkeletonTheme>
	)

	return (
		<Container fluid className="pc-item-sum-wrapper">
			<Table responsive>
				<thead>
					<tr>
						{COLUMN_HEADINGS.map(((heading,i)=><th key={`pc-item-sum-${i}`} className={heading==="Price"?"text-right":""}>{heading}</th>))}
					</tr>
				</thead>
				<tbody>
					{props.order?.items?.map((item)=>{
						const variant = item?.event_details?.variants?.filter(a=>a.name!=="Default" && +a.id===+item.variant_id) || null;
						let event_user = null;
						if (props?.events_display) {
							if (item.event?.for_user_name) event_user = item.event.for_user_name;
							else event_user = `${props.order?.user_fname} ${props.order?.user_lname}`;
						}
						return (
							<tr key={`pc-os-${item.id}`}>
								<td>
									{item.product_name}
									{props?.events_display &&
										<p className="small">
											{item.product_description && 
												<>
													{item.product_description}
													<br/><br/>
												</>
											}
											{props?.events_display &&
												<>
													<span className='bold'>For:</span> {event_user}<br/>
													{item.event?.custom_fields?.filter(a=>a.value)?.map((field,i)=>(
														<React.Fragment key={`pc-os-cf-${i}`}>
															<span className='bold'>{field.custom_field_label}:</span> {field.value}<br/>
														</React.Fragment>
													))}
													<br/>
												</>
											}
											{variant?.[0] &&
												<>
													<span className='bold'>Your payment plan:</span><br/>
													{variant[0].name}<br/>
													${variant[0].price || 0.00} / {variant[0].bill_interval==="d"?"day":variant[0].bill_interval==="y"?"year":variant[0].bill_interval==="w"?"week":"month"}
													{variant[0].bill_num_times>0 && 
														<>
															&nbsp;for {variant[0].bill_num_times} {variant[0].bill_interval==="d"?"day":variant[0].bill_interval==="y"?"year":variant[0].bill_interval==="w"?"week":"month"}(s)
														</>
													}
												</>
											}
										</p>
								}
								</td>
								<td className="text-right">${item.final_price.toFixed(2)}</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</Container>
	)
};
import React, {useState, useEffect, useRef, useCallback} from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import Toast from '../../../../components/Toast'
import { authCheck } from '../../../../utils/auth';
import Pos from '../../../../api/Pos'

import './SimpleRefund.scss'

//used in /containers/User/Profile/UserOrders

/** Needs the following props
 * @param {int} orderId
 * @param {int} userId
 * @param {string} user //the user having a refund
 * @param {int} registerId
 * @param {array} items
 * @param {int} adminFee
 */
export const SimpleRefund = (props) => {
  
    const history = useHistory()
    const loggedUser = authCheck(history)

    const [error, setError]=useState();
    const [modifiedItems, setModifiedItems]=useState([]);
    const [refundAmount, setRefundAmount]=useState(0)

    useEffect(()=>{
        const modifyItems=()=>{
            let tempItems=[]
            let total = 0;
            props.items.forEach((item)=>{
                total += item.final_price + (item.tax || 0) + (item.shipping || 0)
                let tempItem={
                    item_id: item.id,
                    total_refund_amount: item.final_price,
                    tax: item.tax,
                    shipping_refunded_amount: item.shipping,
                    refund_memo: `Full order #${props.orderId} refunded.`,
                    product_name: item.product_name,
                    final_price: item.final_price,
                };
                tempItems.push(tempItem);
                if(item?.addons?.length > 0){
                    item.addons.forEach((addon)=>{
                        total += addon.final_price + (addon.tax || 0) + (addon.shipping || 0)
                        let addOnItem={
                            item_id: addon.id,
                            total_refund_amount: addon.final_price,
                            tax: addon.tax,
                            shipping_refunded_amount: addon.shipping,
                            refund_memo: `Full order #${props.orderId} refunded.`,
                            product_name: addon.product_name,
                            final_price: addon.final_price,
                        };
                        tempItems.push(addOnItem)
                    })
                }
            })
            setRefundAmount(total);
            return tempItems;
        }

        if(props.items.length > 0){
            let newItems = modifyItems();
            setModifiedItems(newItems)
        }
    },[props.items, props.orderId])

    const simpleRefundCall=async()=>{
        modifiedItems.forEach((item)=>{
            delete item.product_name;
            delete item.final_price;
        })
        try{
            let response = await Pos.addRefund({
                order_id: +props.orderId,
                user_id: +props.userId,
                register_id: +props.registerId,
                admin_auth_id: loggedUser.profile.id,
                admin_fee_refunded: props.adminFee,
                items: modifiedItems,
            })
            handleResponse(response)
        }catch(ex){console.error(ex)}
    }

    const byAmountRefund=async()=>{
        let transId = props?.fullOrder?.transactions.find(transaction=>transaction.transaction_status_id===7).id
        let paymentMethod = props?.fullOrder?.payment_method?.toLowerCase()?.replaceAll(" ", "")
        if(transId && paymentMethod){
            try{
                let response = await Pos.payment.refund({
                    amount: props.fullOrder.total_price,
                    transaction_id: transId,
                    payment_type: paymentMethod,
                    register: +props.registerId,
                })
                handleResponse(response);
            }catch(ex){
                console.error(ex)
            }
        }
        else if(!transId || !paymentMethod){
            props.setError(<ErrorCatcher error={"The transaction associated with this order was unable to be discerned or was never completed.  Unable to refund transaction."} />)
        }
    }

    const handleResponse=(response)=>{
        if(response.errors) {
            props.setError(<ErrorCatcher error={response.errors} />)
            props.onClose();
        }else if (response.status===200){
            props.setSuccess(<Toast>Refund applied successfully</Toast>)
            props.onClose();
            props.cleanUp();
        }
    }
    
    return (
    <>
        <div className="simple-refund">
            {props.items.length === 0 ?
                <p className="text-center">
                    This will refund the entirety of this order : ${props?.fullOrder?.total_price?.toFixed(2)}
                    <Button onClick={byAmountRefund}>
                        Confirm Refund
                    </Button>
                </p>
            :
                <>
                    <p>
                        The following items are being refunded for {props?.user}:
                    </p>
                    <p>
                        {modifiedItems?.length > 0 && modifiedItems?.map((item)=>(
                            <React.Fragment key={`refund-item-${item.id}`}>
                                <span data-cy="simple-refund-product">• {item?.product_name} - ${(item?.final_price + item.tax).toFixed(2)}</span>
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                    <p data-cy="simple-refund-total">
                        For a total of ${refundAmount?.toFixed(2)}
                    </p>
                    {error}
                    <Button onClick={simpleRefundCall}>
                        Confirm Refund
                    </Button>
                </>    
            }
        </div>
    </>
  )
}

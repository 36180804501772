import ReactToPrint from "react-to-print";

/**
 * Converts JSON to CSV with the entire JSON object or, optionally, with a passed in header row and values to grab selectively
 * @param {*} json the object to be converted
 * @param {*} headers if you only want certain aspects of the JSON object, pass an array of the data you want
 * @param {*} download if you want to download the file, set to true
 * @returns the CSV string if both print and download are false
 */
export const convertJSONtoCSV = (json, headers = null, download = false)=>{
    let csv;
    let header
    
    if(headers){
        header = headers;
    }else header = Object.keys(json[0]);

    const headerString = header.join(",");
    const replacer = (key, value) => value ? value : '';
    const rowItems = json.map((row)=>(
        header
            .map((fieldName)=> JSON.stringify(row[fieldName], replacer))
            .join(",")
    ));
    csv = [headerString, ...rowItems].join("\n");

    if(download)createCSVFile(csv);
    else return csv;
}

export const createCSVFile = (data)=>{
    const blob = new Blob([data], { type: 'text/csv'});

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'download.csv')
    a.click()
}

//need to add handling for nested data.  
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from '../../../../../store/actions'

import MultiStep from '../../../../../components/common/MultiStep';
//steps
import SelectUsers from '../MultiUserSingleItem/SelectUsers';
import OrderDivision from './OrderDivision';
import Payments from './Payments'
import Confirmation from '../../../Confirmation';
import MultiUserConfirmation from '../MultiUserConfirmation';


export const MultiUserMultiItems = (props) => {

    const dispatch = useDispatch();
    const currentOrder=useSelector(state=>state.pos[props.register].orderAll);
    const splitPaymentStore=useSelector(state=>state.splitPayments);

    const [alreadySplit, setAlreadySplit]=useState(false);
    const [validated, setValidated]=useState(true);
    const [splitType, setSplitType]=useState();
    const [goToName, setGoToName]=useState(null);
    const [stepName, setStepName]=useState("")
    const [hideBack, setHideBack]=useState(false);

    useEffect(()=>{
        if(stepName==="Confirmation") setHideBack(true);
      },[stepName])

    useEffect(()=>{
        if(splitType) dispatch(actions.setSplitPaymentSplitType(splitType));
    },[splitType, dispatch]);

    const stepOrder = [
        'SelectUsers',
        'OrderDivision',
        "Payments",
        "Confirmation"
    ]
    
    const goTo=(name)=>{
        setGoToName(name)
    }

    const steps=[
        {
            name: 'SelectUsers',
            component:
            <SelectUsers 
                register={props.register}
                type="multiMany"
                currentOrder={currentOrder}
                cancelSplit={props.cancelSplit}
            />
        },
        {
            name: 'OrderDivision',
            component: 
                <OrderDivision
                    cancelSplit={props.cancelSplit}
                    setAlreadySplit={setAlreadySplit} 
                    register={props.register}
                    setSplitType={setSplitType}
                />
        },
        {
            name: 'Payments',
            component:
                <Payments
                    onClose={props.onClose}
                    alreadySplit={alreadySplit}
                    register={props.register} 
                    splitType={splitType}
                    terminalDeviceId={props.terminalDeviceId}
                    type="multiMany"
                    goTo={goTo}
                />
        },
        {
            name: 'Confirmation',
            component: 
                <MultiUserConfirmation
                    register={props.register}
                    type={"spMultiMany"}
                />
        }
    ];

    const validate=(pageName)=>{
        let error = {};
        let validated=true;
        switch(pageName){
            case "SelectUsers":
                if(splitPaymentStore.payees.length < 2 || !splitPaymentStore.payees){
                    error={...error, numberOfPayees: "You must select at least 2 payees."}
                }
                if(splitPaymentStore.payees.some(payee => {return !payee.identifier})){
                    error={...error, namePayees: "Each payee must have some sort of identifier, even if you just use numbers."}
                }
                break;
            case "OrderDivision":
                if(splitPaymentStore.allItemsAssigned==="No"){
                    error={...error, missedItems: "You must assign each item to a payee."}
                }
                if(splitPaymentStore.allItemsAssigned==="Partial"){
                    error={...error, missedPayee: "You must assign at least one item to each payee."}
                }
                if(splitPaymentStore.remainder > 1 ){
                    error = {...error, leftoverRemainder: "You must assign the whole amount to the chosen payees."}
                }
                if(splitPaymentStore.remainder < -1){
                    error = {...error, negativeRemainder: "You have exceeded the total price of the order.  Please adjust accordingly."}
                }
                if(splitPaymentStore.payees.some(payee => {return (payee.amount<=0)})){
                    error={...error, noAmountOnPayee: "Each payee must have an amount allotted to them.  It cannot be zero."}
                }
                break;
            case "Payments":
                if(splitPaymentStore.allPaid === "No" || splitPaymentStore.allPaid === "Partial"){
                    error = {...error, notAllPaid: "Please complete a transaction for each person.  "}
                  }
                break;
            case "Confirmation":
            default:
                //stuff?
                break;
        }
        if(Object.keys(error).length>0) validated=false;
        dispatch(actions.setSplitPaymentErrors(error));
        setValidated(validated);
        return validated;
    }

    const multiStepSubmit=()=>{
        if(validated && splitPaymentStore.allPaid==="Yes") props.onClose(true);
    }

  return (
    <div className="multi-user-multi-sp-wrapper">
        <MultiStep
            showNavigation={false}
            steps={steps}
            stepOrder={stepOrder}
            onChangeStep={validate}
            onSubmit={multiStepSubmit}
            goTo={goToName}
            hideBack={hideBack}
            saveStep={(name)=>setStepName(name)}
        />
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';
import { format, formatInTimeZone } from 'date-fns-tz'

import { convertTimeString } from '../../../utils/pos'
import SubHeader from '../../../components/common/SubHeader';

const defaultSubheader=[
    {linkAs: Link, linkProps: {to: "/p/home"}, text: "Home"},
    {text: "Sorry! Closed"}
]

export const ClosedHours = ({ 
    subHeaderItems=defaultSubheader,
    hours,
    customText="",
    registerName="this register",
    fullPage=true,
    ...props
})=>{

    const [ loading, setLoading ]=useState(true);
    const [ hoursCopy, sethoursCopy ]=useState([]);

    useEffect(()=>{
        if(hours){
            setLoading(true)
            let hoursCopy = JSON.parse(JSON.stringify(hours))
            hoursCopy?.days?.forEach((day)=>{ 
                if(!day.start && !day.end && !day.closed) day.closed = false;
                else if(!day.closed) {
                    day.closed = false;
                    if(day.start && typeof(day.start)==="string")day.start = convertTimeString(day.start, hours.timezone);
                    if(day.end && typeof(day.end)==="string")day.end = convertTimeString(day.end, hours.timezone);
                }
            });
            sethoursCopy(hoursCopy)
            setLoading(false);
        }
    },[hours])


    const hourBlock=(<>
        <h4 className="section-title">Sorry!</h4>
        <div>
            <div>
                {customText ? 
                    <span>
                        {customText}
                    </span>
                    :
                    <span>
                        {}
                        Currently, {registerName} is closed! The hours of availability are as follows: 
                    </span>
                }
            </div>
            <div>
                {!loading && hoursCopy && hoursCopy?.days?.map((day)=>(
                    <ul key={`day-hours-${day.date}`}>
                        <strong>
                            {day?.date} - {" "}
                        </strong>
                        {day?.closed ?
                            <span>
                                CLOSED 
                            </span>
                        :
                            <>
                                {day.start ?
                                    <span>
                                        {format(new Date(day?.start), 'h:mm a' )} - {format(new Date(day?.end), "h:mm a z")}
                                    </span>
                                    :
                                    <span>
                                        NO HOURS SET
                                    </span>

                                }
                            </>
                        }
                    </ul>
                ))}
            </div>
            {hoursCopy && hoursCopy?.extra_closed?.length > 0 && 
                <>
                    <hr />
                    <p>
                        These are the additional days that {registerName} is closed:
                    </p>
                    {hoursCopy?.extra_closed?.map((closed)=>(
                        <p key={`extra-closed-days-${closed}`}>
                            {format(new Date(closed), 'MMMM do')}
                        </p>
                    ))}
                </>
            }
        </div>    
    </>)
    

    return(
        <>
            {fullPage ? 

                <Container fluid>
                    <SubHeader 
                        items={subHeaderItems}
                    />
                    <Card className="content-card">
                        {hourBlock}
                    </Card>
                </Container>
            :
                <>
                    {hourBlock}
                </>
                
            }

        </>
    )
}
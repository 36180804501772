import Request from './Api';

const save = async (props) => {
    return (
        Request({
            url: "/create-log-error",
            method: "POST",
            data: {...props}
        })
    );
}


const Error = {
	save
}
  
export default Error;
export function fancyTimeFormat (duration, ampm=false) {
    // Hours, minutes and seconds
    let hrs = ~~(duration / 3600);
    let mins = ~~((duration % 3600) / 60);
    let secs = ~~duration % 60;
    let suffix = 'AM';
    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";
    if (hrs > 0) {
        if (ampm && hrs>12) {
            hrs = hrs-12;
            suffix = 'PM';
        }
        ret += (hrs < 10 ? "0" : "") + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins
    if (ampm) {
        ret += " " + suffix;
    } else {
        ret += ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
    }
    return ret;
}

// send in string like "13:00"
// return back string like "1:00 PM"
export function formatTimeAmpm (time, withMinutes=true) {
    // Hours, minutes and seconds
    let time_array = time.split(":");
    let hrs = parseInt(time_array[0]);
    let mins = parseInt(time_array[1]);
    let suffix = 'AM';
    let returnString = "";
    if (hrs > 0) {
        if (hrs>12) {
            hrs = hrs-12;
            suffix = 'PM';
        }
        returnString += "" + hrs;
    }
    if (withMinutes) {
        returnString += ":" + (mins < 10 ? "0" : "") + mins;
    }
    returnString += " " + suffix;
    return returnString;
}

export function isNumeric(n, min=0) {
    return !isNaN(parseFloat(n)) && isFinite(n) && n>=min;
}


// time in "09:00" or "09:00:00" format, return minutes in integer
export function convertTimeToMinutes (time) {
    let timearray = time.split(":");
    let minutes = 0;
    if (timearray[0] && timearray[1]) {
        minutes = (parseInt(timearray[0]) * 60) + parseInt(timearray[1]);
    }
    return minutes;
}

// take minutes (integer) and return either minutes, hours + minutes, days + hours + minutes
export function convertMinutesToHoursDays (minutes) {
    if (minutes<=0) return "0 minutes";
    let days = Math.floor(minutes / 1440);
    minutes -= days*1440;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    let string = days>0 ? `${days} ${days===1 ? 'day' : 'days'} ` : '';
    string += hours>0 ? `${hours} ${hours===1 ? 'hour' : 'hours'} ` : '';
    string += minutes>0 ? `${minutes} ${minutes===1 ? 'minute' : 'minutes'} ` : '';
    return string;
}

export function roundPrice (value) {
    return '$' + Number(Math.round(parseFloat(value + 'e' + 2)) + 'e-' + 2).toFixed(2);
}



// Util for SERVICE BOOKING

export function generateServiceBlockTimeslots (service) {
    let new_blocks = [];

    // add timeslots to each block
    service.blocks.map(block => { // Monday through Friday
        let start = convertTimeToMinutes(block.start_time);
        let end = start + block.duration;
        let slots = [];

        // convert the block start and end time to minutes, cycle through them
        for(let block_start = start; block_start < end; block_start += service.block_minutes) {
                slots = [ ...slots, {
                    start_time: fancyTimeFormat(block_start),
                    end_time: fancyTimeFormat(block_start + service.block_minutes),
                } ]
        }
        // add timeslots to the original block, then add to new_blocks array
        new_blocks = [...new_blocks, {
            ...block,
            end_time: fancyTimeFormat(end),
            timeslots: slots
        }];
        return block;
    });
    return new_blocks;
}

//takes a time value and rounds the 4s and 9s on minutes up and the 59s on seconds for display purposes
//depending on the desired time and format, the result from here may have to be reformatted to suit needs
export const roundTimes=(originalTime)=>{
    let minutes = originalTime.getMinutes().toString();
    let seconds = originalTime.getSeconds().toString();
    if(minutes[1]==="4" || minutes[1]==="9") originalTime.setMinutes(new Date(originalTime).getMinutes()+1);
    if(seconds[1]==="9") originalTime.setSeconds("00");
    return originalTime;
}

import React from 'react'

export const DisplayOrdersParentDetailTable = ({parentOrder, ...props}) => {
    return (
        <table className="uo-order-det-table">
            <tbody>
                    <tr>
                        <th>
                            Parent Id
                        </th>
                        <td>
                            #{parentOrder?.id}        
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Parent Total
                        </th>
                        <td>
                            ${parentOrder?.total_price?.toFixed(2)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Parent Subtotal
                        </th>
                        <td>
                            ${parentOrder?.subtotal_price?.toFixed(2)}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Children Ids
                        </th>
                        <td>
                            {parentOrder?.split_order_ids?.map((child, i)=>(
                                <React.Fragment key={`child-id-${i}`}>
                                    #{child} {" "}
                                </React.Fragment>
                            ))}
                        </td>
                    </tr>
                    <tr>
                        <th colSpan={2}>
                            Parent Items
                        </th>
                    </tr>
                </tbody>
            </table>
    )
}

export default DisplayOrdersParentDetailTable
import React from 'react';
import { format } from 'date-fns';

const DEFAULT_PRINT_WIDTH = "320px";

// The stylesheet for this component is inline because the ReactToPrint component keeps losing the styles every time prod gets rebuilt
const styles = {
    wrapper: {
        fontFamily: "'Roboto', sans-serif",
        marginTop: "0.1rem",
        marginLeft: "0.75rem",
    },
    item: {
        fontWeight: "700",
        fontSize: "1.2rem",
        borderTop: "solid 1px #ddd",
        textAlign: "left",
        lineHeight: "1.4rem",
        marginTop: "8px",
    },
    addon: {
        borderTop: "none",
        paddingLeft: "0.8em",
        textIndent: "-0.8em",
        marginTop: "0",
    },
    orderNum: {
        textAlign: "center",
        fontSize: "1.8rem",
    },
    title: {
        textAlign: "center",
        fontSize: "1.1rem",
    },
    date: {
        textAlign: "center",
        fontSize: "1.2rem",
    },
    addonIndent: {
        marginLeft: "1rem",
        marginRight: "0.5rem",
    },
    customer: {
        marginTop: "2px",
    }
}

export const OrderTicket = (props) => {

    let width = props.width || DEFAULT_PRINT_WIDTH;

    let adjustedWrapperStyle = {
        ...styles.wrapper,
        maxWidth: width,
    }

    return (
        <div style={adjustedWrapperStyle}>
            <h3 style={styles.title}>{props.printerName}</h3>
            <div>
                <h4 style={styles.orderNum}>
                    Order #{props.orderId}
                </h4>
                <h4 style={styles.date}>
                    {format(new Date(), "MM/dd/yyyy h:mm aa")}
                    {props.customer && 
                        <p style={styles.customer}>For: {props.customer.first_name} {props.customer.last_name}</p>
                    }
                </h4>
            </div>
            <section>
                {props.items?.map((item, i)=> {
                    let isAddon = item.parent_id!==item.product_id;
                    let adjustedItemStyle = isAddon ? {...styles.item, ...styles.addon} : styles.item;
                    return (
                    <div key={`kt-receipt-item-${i}`} style={adjustedItemStyle}>
                        {item.parent_id && item.parent_id!==item.product_id && 
                            <span style={styles.addonIndent}>• {" "}</span>
                        }
                        {item.product_name}
                        {item.variant_id 
                            && (item.parent_id===item.product_id || item.parent_id===null)
                            && item.variant_name.trim().toLowerCase()!==item.product_name.trim().toLowerCase()
                            && item.variant_name.trim().toLowerCase()!=="default"
                            && item.variant_name.trim().toLowerCase()!=="" &&
                                <div>
                                    {" "}-{" "} {item.variant_name}
                                </div>
                        }
                    </div>
                )})}
                <div>
                    {/* Adding a blank line to try to get a margin at the bottom of the receipt */}
                    {" "} 
                </div>
            </section>
        </div>
    )
}

import React, { useState, useEffect, useRef } from 'react';
import {Form} from 'react-bootstrap';
import { randomUUID, toCamelCase, toKebabCase, checkBoolean, localGroup } from '../../../../../utils/cms';

const FormGroupOptions =  React.forwardRef((props, ref) => {
    const bkRef = useRef();

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.onClick){
        preview_click = props.onClick;
    }

    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(props?.value || props.type==="radio" ? "" : []);

    const _disabled = checkBoolean(props?.disabled) || false;
    const _readonly = checkBoolean(props?.readonly) || false;
    const _required = checkBoolean(props?.required) || false;    

    const changeHandler = (e) => {
        const prev = value || [];
        if (props.type==="radio") setValue(`${e.target.value}`.trim());
        else {
            if (e.target.checked) setValue(prev=>[...(prev || []), `${e.target.value}`.trim()]);
            else {
                const _value = value.filter((v) => v!==`${e.target.value}`.trim());
                setValue(_value);
            }
        }
        localGroup(e.target, props.id, prev);
    }

    useEffect(() => {
        if (props?.options) {
            const _options = props.options.split(",");
            setOptions(_options || []);
        }
    }, [props.options]);

    useEffect(() => {
        if (props.id && bkRef.current && options.length){
            const boxes = bkRef.current.querySelectorAll("input");
            boxes.forEach((box) => {
                localGroup(box, props.id);
            });
        }
    }, [bkRef, props.id, options]);

    useEffect(() => {
        return () => {
            setValue(null);
            setOptions([]);
        }
    }, []);

    return (
        <Form.Group 
            ref={ref || bkRef} 
            className={`form-group-text_${props.page_id} ${props.className || ""}`} 
            style={props.style}
            controlId={`${toKebabCase(props?.name) || toKebabCase(props?.label)}-${randomUUID()}`}
            onClick={preview_click}>
                <Form.Label>{props?.label || ""}</Form.Label>        
                {options?.map((option,i) => (
                    <Form.Check 
                        key={`option-${randomUUID()}-${i}`}
                        inline={props?.inline || false}
                        reverse={props?.reverse || undefined}
                        className={`form-group-options_${props.page_id} ${props.className || ""}`} 
                        style={props.style}
                        type={props.type}
                        id={`${option || randomUUID()}-${i}`}
                        label={`${option}`}
                        name={`______${props?.name || toCamelCase(props.label) || randomUUID()}-${i}`}
                        value={option}
                        checked={props.type==="radio" ? `${value}`.trim()===`${option}`.trim() : value?.includes(`${option}`.trim())}
                        onChange={changeHandler}
                        onClick={preview_click}
                        disabled={_disabled}
                        readOnly={_readonly}
                        required={_required}
                        feedback={_required ? props?.feedback || "This field is required" : ""}
                    />
                ))}
        </Form.Group>
    );
});

export default FormGroupOptions;
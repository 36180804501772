import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import Pos from '../../../api/Pos';
import * as actions from '../../../store/actions';

import './Registers.css';
import '../Items/Products/Products.scss';

export const Registers = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [registers,setRegisters]=useState([]);
    const defaultPatronRegisterId = useSelector(state => state.pos.register)

    useEffect(()=>{
        let mounted = true;

        Pos.get().then(response => {
            if (mounted) setRegisters(response.data);
        }).catch(e => console.error(e));

        return () => {
            mounted = false;
        }    
    },[]);

    const clickHandler = async (e, id) => {
        e.preventDefault();
        if (id) {
            await Pos.local.remove(id && id !== defaultPatronRegisterId ? `POS-${id}` : "PATRON-CART")
            .then( a => {
                dispatch(actions.reset());
                dispatch(actions.order(null));
                dispatch(actions.orderAll(null));
                dispatch(actions.transaction(null));
                dispatch(actions.orderImported(false));
                dispatch(actions.orderStatus(1));
                history.push("/p/pos/"+id);
            });
        }
    }

    return (
        <Container fluid>
            <Row>
                {registers.map(i=>(
                    <Col sm="12" key={`reg-${i.id}`}>
                        <Card as="a" href="#!" onClick={(e)=>clickHandler(e,i.id)} className="product p-0">
                            <Card.Body>
                                <Card.Title>{i.name}</Card.Title>
                                {i.description &&
                                    <Card.Text>{i.description}</Card.Text>
                                }
                            </Card.Body>
                            <Card.Footer></Card.Footer>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}
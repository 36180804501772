import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { Spinner } from 'react-bootstrap';

import Users from '../../../../api/Users';

import './Groups.css';

export const Groups = (props) => {

    const [loading,setLoading]=useState(true);
    const [groups,setGroups]=useState([]);

    // first load, get events from api
    useEffect(() => {
        let mounted = true;

        setLoading(true);
        Users.groups({id:props.user_id})
        .then(response => {
            if(mounted && response.data) {
                let confirmed_groups = response.data.filter(user_group => user_group.group_member_status_id===2);
                setGroups(confirmed_groups);
                setLoading(false);
            }
        }).catch(e => {
            console.error(e);
            setLoading(false);
        });

        // cancel stuff when component unmounts
        return () => {
            mounted = false;
            setLoading(false);
            setGroups([]);
        }        
    },[props.user_id]);

    return (
        <React.Fragment>
        {loading ? 
            <Spinner animation="border" variant="secondary" />
            :
            <>
                {!groups.length &&
                    <span>None</span>
                }
                {groups && groups.map((group,i)=>(
                    // <Card key={`group-pos-${i}`} as="a" href="#!" className="info-container detail-card">
                    <Card key={`group-pos-${group.group_id}`} data-cy={`group-${group.group_id}`} className="info-container detail-card">
                        <div>Name: {group.name}</div>
                        <div>Role: {group.group_member_role}</div>
                    </Card>
        
                ))}
            </>
        }
        </React.Fragment>
    );
}
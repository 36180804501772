import * as actionTypes from './actionTypes';

export const setPCUserDetails=(userDetails)=>{
    return{
        type: actionTypes.PATRONCART_SET_USER_DETAILS,
        userDetails: userDetails
    };
};

export const setPCModifiedOrder=(modifiedOrder)=>{
    return{
        type: actionTypes.PATRONCART_SET_MODIFIED_ORDER,
        modifiedOrder: modifiedOrder
    };
};

export const setPCReset=()=>{
    return{
        type: actionTypes.PATRONCART_RESET
    }
}
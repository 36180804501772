import React from 'react'
import Table from 'react-bootstrap/Table'

export const MobileRefundDisplay = (props) => {

    const eachItem = (item, isAddon)=>{
        return(
            <>
                <tr style={{borderTop: "solid 2px"}}>
                    <th>Id</th>
                    <td className="pl-4">{item.id}</td>
                </tr>
                <tr>
                    <th>Name</th>
                    {!isAddon ?
                        <td className="pl-4">
                            {item.product_name}
                            {item?.variant_name?.toLowerCase()==="default" ? 
                            null 
                            : 
                                <span>- {item.variant_name}</span>
                            }
                        </td>
                        :
                        <td>
                            <span className="bold">ADDON - </span>
                            {item.product_name}
                        </td>
                    }
                </tr>
                <tr>
                    <th>Refunded</th>
                    {item.total_refunded_amount ?
                        <td className="pl-4">${(item.total_refunded_amount).toFixed(2)}</td>
                    :
                        <td className="pl-4">$0.00</td>
                    }
                </tr>
                <tr>
                    <th>Original Price</th>
                    <td className="pl-4">${(item.final_price + item.tax).toFixed(2)}</td>
                </tr>
                {/* <tr>
                    <th>Shipping Refunded</th>
                    {item.shipping_refunded_amount ? 
                        <td className="pl-4">${(item.shipping_refunded_amount).toFixed(2)}</td>
                    :
                        <td className="pl-4">$0.00</td>
                    }
                </tr>
                <tr>
                    <th>Original Shipping</th>
                    {item.shipping ? 
                        <td className="pl-4">${(item.shipping).toFixed(2)}</td>
                    :
                        <td className="pl-4">$0.00</td>
                    }
                </tr> */}
                {props.isPatron ?
                    null
                :
                    <>
                        {item.refund_memo ? 
                            <tr>
                                <th>Refund Memo for {item.id}</th>
                                {item.refund_memo ? 
                                    <td className="pl-4">{item.refund_memo}</td>
                                    :
                                    <td className="pl-4">No item memo</td>
                                }
                            </tr>
                        :
                            null
                        }
                    </>
                }
            </>
        )
    }

  return (
    <>
        <Table reponsive="true" striped>
            <tbody>
                {props.currentOrder.items?.map((item, i)=>(
                    <React.Fragment key={`rd-table-items${i}${item.id}`}>
                        {eachItem(item, false)}
                        {item?.addons?.map((addon, i)=>(
                            <React.Fragment key={`rd-table-addons${i}${addon.id}`}>
                                {eachItem(addon, true)}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ))}
            </tbody>
        </Table>
    </>
  )
};

export default MobileRefundDisplay

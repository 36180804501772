import React from 'react';
import {useSelector} from 'react-redux';
import {Row, Col, Button, DropdownButton, Dropdown, ButtonGroup} from 'react-bootstrap';

import { selectTimeSlot, selectTokensForCurrentBooking } from '../../../../../../../store/selectors';

import styles from './Items.module.scss';

const hideQty = [1, 4, 5]; // product types to hide the quantity for
const showFor = [1, 4, 5, 9] // product types to show the "for" field for

const Items = props => {    
    const currentBooking = useSelector(state => state.serviceBooking.current_booking);
    const tokens = useSelector(selectTokensForCurrentBooking);
    const errors = useSelector(state => state.serviceBooking.errors);
    const selectedTimeslot = useSelector(selectTimeSlot);
    
    if (!props?.order?.items || props?.success) return (props.empty_message || "Your cart is empty.");

    return (
        <>
            {!props.sucess && props?.order?.items?.map((item, i)=>{
                const variant = item?.event_details?.variants?.filter(a=>a.name!=="Default" && +a.id===+item.variant_id) || null;

                return (
                    <Row key={`cart-item-${i}`} className={styles.item}>
                        <Col sm={12} md="auto">
                            {item.product_image &&
                                <img src={item.product_image} alt={item.product_name} className={styles.image} />
                            }
                        </Col>
                        <Col>
                            <p className={styles.title}>
                                <span>{item.product_name}</span><br/>
                                <span>${item.final_price.toFixed(2)}</span><br/>
                            </p>
                            <div className={styles.details}>
                                <p>{item.product_description ? item.product_description : ""}</p>
                                {props?.events_display && showFor.includes(+item.product_type_id) &&
                                    <>
                                        <p>
                                            <span className={styles.bold}>For:</span> 
                                            <span>{item.event?.for_user_name ? item?.event?.for_user_name : props.order?.user_fname} {props.order?.user_lname}</span>
                                        </p>
                                        {item.event?.custom_fields?.filter(a=>a.value)?.map((field,i)=>(
                                            <p key={`pc-os-cf-${i}`}>
                                                <span className={styles.bold}>{field.custom_field_label}:</span> {field.value}
                                            </p>
                                        ))}
                                    </>
                                }
                                {variant?.[0] &&
                                    <>
                                        <br/>
                                        <span className={styles.bold}>Your payment plan:</span><br/>
                                        {variant[0].name}<br/>
                                        ${variant[0].price || 0.00} / {variant[0].bill_interval==="d"?"day":variant[0].bill_interval==="y"?"year":variant[0].bill_interval==="w"?"week":"month"}
                                        {variant[0].bill_num_times>0 && 
                                            <>
                                                &nbsp;for {variant[0].bill_num_times} {variant[0].bill_interval==="d"?"day":variant[0].bill_interval==="y"?"year":variant[0].bill_interval==="w"?"week":"month"}(s)
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            <div className={`text-link ${styles.toolbar}`}>
                                {!hideQty.includes(+item.product_type_id) &&
                                    <DropdownButton as={ButtonGroup} id={`btn-qty`} size="sm" variant="light" title={`Qty ${item?.quantity || 1}`} className={styles["btn-qty"]}>
                                        {[0,1,2,3,4,5,6,7,8,9,10].map((q, i)=>(
                                            <Dropdown.Item key={`btn-qty-option-${i}`} eventKey={q} onClick={()=>props.updateQty(item.id, q)}>
                                                {q} {q === 0 && " (remove)"}
                                            </Dropdown.Item>
                                        ))}
                                </DropdownButton>
                                }

                                {currentBooking?.service?.products?.filter(a=>+a.id === +item.product_id)?.length > 0 && tokens?.toRedeem > 0 &&
                                    <Button variant="link" href="#!" disabled={props.processing || props.loading} onClick={()=>alert("1")}>Tokens</Button>
                                }

                                <Button variant="link" href="#!" disabled={props.processing || props.loading} onClick={()=>props.delete(item.id)}>Remove</Button>
                            </div>
                        </Col>
                    </Row>
                );
            })}
        </>
    );
}

export default Items;
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useHistory, Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import Stack from '../../../components/common/Stack';

import Pos from '../../../api/Pos';
import Order from '../../../api/Order';
import SubHeader from '../../../components/common/SubHeader';
import Print from '../../POS/Print';
import PrintFullPage from '../../POS/Print/PrintFullPage';
import { setErrorCatcher, setSuccessToast } from '../../../utils/validation';
import OrderStatuses from './OrderPageComponents/OrderStatuses';
import OrderPriceDetails from './OrderPageComponents/OrderPriceDetails';
import OrderOtherDetails from './OrderPageComponents/OrderOtherDetails';
import OrderItems from './OrderPageComponents/OrderItems';
import TransactionHistory from './OrderPageComponents/TransactionHistory';
import SimpleRefund from '../../Pay/Refund/SimpleRefund';
import Refund from '../../Pay/Refund';
import OrderCoupons from './OrderPageComponents/OrderCoupons';

import './OrderPage.scss';

const intervalTimerMS = 90000 //90 seconds

export const OrderPage = ({importedId, registerId = null}) => {

    const mountedRef = useRef(false);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const [print, setPrint]=useState(false);
    const [currentOrder, setCurrentOrder]=useState(null);
    const [parentOrder, setParentOrder]=useState(null);
    const [orderId, setOrderId]= useState();
    const [allOrderStatuses, setAllOrderStatuses]=useState([]);
    const [doneStatuses, setDoneStatuses]=useState([]);
    const [userDetails, setUserDetails]=useState();
    const [ refunded, setRefunded ]=useState(false);
    const [ partialRefund, setPartialRefund ]=useState(false);
    const [ modifiedRefund, setModifiedRefund] =useState(Object.assign({}, currentOrder));
    const [ modifiedCompleted, setModifiedCompleted]=useState(Object.assign({}, currentOrder));
    const [ isPickupOrder, setIsPickupOrder ] = useState(false)

    /**Adds an ID to the items in the transaction for maintaining check status if user goes back from refund screen */
    const addFields=(res)=>{
        for(let i =0; i < res.items.length; i++){
            res.items[i].item_id = i;
        }
    }

    const getCurrentOrder=useCallback(async(id, parent=false)=>{
        try{
            let response = await Pos.order.get({
                id: id
            })
            if(response.status ===200 ) {
                addFields(response.data)
                let oStatId = response.data.order_status_id;
                if(oStatId === 8 || oStatId === 9 || oStatId === 10) setIsPickupOrder(true)
                else setIsPickupOrder(false)
                if(!parent) setCurrentOrder(response.data)
                if(response.data.parent_id && !parent) setParentOrder(response.data.parent_id)
                if(parent) setParentOrder(response.data)
            }else(setError(response.errors))
        }catch(ex){console.error(ex)}
    },[]);

    useEffect(()=>{
        mountedRef.current = true

        const getOrderStatuses=async()=>{
            try{
                let response = await Order.Statuses.getStatuses();
                setAllOrderStatuses(response.data)
                if (response.data){
                    let doneStatuses=response.data.filter((status)=>status.is_done === 1)
                    setDoneStatuses(doneStatuses)
                }
            }catch(ex){console.error(ex)}
        }

        getOrderStatuses();

        return()=> {
            mountedRef.current = false
            setCurrentOrder(null);
            setOrderId(null);
            setSuccess(null);
            setError(null);
        }
    },[]);

    useEffect(()=>{
        if(importedId) setOrderId(importedId)
        else setOrderId(history.location.pathname.split("/")[3])
    },[importedId, history.location.pathname])

    useEffect(()=>{
        let interval;
        if(isPickupOrder){
            interval = setInterval(()=>{
                getCurrentOrder(orderId)
            }, intervalTimerMS)
        }else clearInterval(interval)

        return(()=>{
            clearInterval(interval)
        })
    //we don't need orderid here because there's already a differnet useEffect to manage that.  This has become a mess of effects T.T
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[isPickupOrder, getCurrentOrder])

    useEffect(()=>{
        if(orderId){
            getCurrentOrder(orderId);
        }
    },[orderId, getCurrentOrder]);

    useEffect(()=>{
        if(parentOrder && typeof parentOrder === 'number') getCurrentOrder(parentOrder, true)
    },[parentOrder, getCurrentOrder]);

    useEffect(()=>{
        const createUserDetails=()=>{
            let details = {
                first_name: currentOrder?.user_fname,
                middle_name:null,
                last_name: currentOrder?.user_lname,
                address1:currentOrder?.email,
                address2:null,
                city:null,
                state:null,
                postal_code:null
            }
            setUserDetails(details)
        }

        //Determine display type for order based on refund status
        /**Function that splits items into two different arrays based on their refund status. */
        const splitItems=()=>{
            let tempItems = currentOrder.items
            let itemsRefunded =[];
            let itemsCompleted =[];
            tempItems.forEach((item)=>{
                if(item.total_refunded_amount !== 0 || item.shipping_refunded_amount !== 0){
                    itemsRefunded.push(item)
                }
                else itemsCompleted.push(item)
            })
            if(mountedRef.current === true){
                let completed = modifiedCompleted;
                let refunded = modifiedRefund;
                completed.items = itemsCompleted;
                refunded.items = itemsRefunded;
                setModifiedCompleted(completed);
                setModifiedRefund(refunded);
            } 
        }

        if(currentOrder){
            splitItems();
            createUserDetails();
        }

    },[currentOrder, modifiedCompleted, modifiedRefund]);

    //Determines full or partial refund for split display.  
    useEffect(()=>{
        if(currentOrder){
            if(currentOrder.order_status_id === 4 && mountedRef.current) setRefunded(true);
            if (modifiedRefund.items.length >0 && modifiedCompleted.items.length > 0 && mountedRef.current) {
                setPartialRefund(true);
                setRefunded(false);
            }else if(modifiedRefund.items.length >0 && modifiedCompleted.items.length ===0 && mountedRef.current) setRefunded(true);
        }
    }, [modifiedRefund, modifiedCompleted, currentOrder])
   
    //Determines full or partial refund for split display.  
    useEffect(()=>{
        if(currentOrder){
            if(currentOrder.order_status_id === 4 && mountedRef.current===true) setRefunded(true);
            if (modifiedRefund.items.length >0 && modifiedCompleted.items.length > 0 && mountedRef.current === true) {
                setPartialRefund(true);
                setRefunded(false);
            }
        }
    }, [modifiedRefund, modifiedCompleted, currentOrder]);

    const redrawOrder=()=>{
        getCurrentOrder(orderId);
    }

    const addAddonsAsItemsForPrint=(items)=>{
        let allItems = [];
        for(let i = 0; i < items.length; i++){
            allItems.push(items[i])
            if(items[i].addons.length > 0){
                for(let j = 0; j < items[i].addons.length; j++){
                    items[i].addons[j].addon = true
                    allItems.push(items[i].addons[j])
                }
            }
        }
        return allItems;
    }

    return (
        <Container fluid className={`order-page-wrapper`}>
            {!importedId &&
                <SubHeader items = {[
                    { linkAs: Link, linkProps: { to: "/p/home" }, text: "Home" },
                    { linkAs: Link, linkProps: { to: "/p/all-orders" }, text: "All Orders" },
                    { text: `Order #${orderId}` },
                ]} />
            }
            <div className={`${!importedId ? "card content-card" : ""}`}>
            <Row>
                <Col sm={12} className="order-head">
                    <Stack direction="horizontal" gap={2}>
                        <h3 className="section-title">
                            Order #{orderId} 
                        </h3>
                        <div className="ms-sm-auto order-1 order-lg-2">
                            {currentOrder &&
                                <PrintFullPage 
                                    // transSuccess
                                    type="invoice"
                                    userDetails={userDetails}
                                    order={parentOrder?.items ? parentOrder : currentOrder}
                                    items={parentOrder ? parentOrder.items : currentOrder.items}
                                    btnText={<><i  className="far fa-print cp" />Print Invoice Copy</>}
                                    refunded={refunded}
                                    partialRefund={partialRefund}
                                    parentOrder={parentOrder}
                                />
                            }
                            {currentOrder && !currentOrder?.split_order_ids?.length &&
                                <>
                                    <PrintFullPage 
                                        type="receipt"
                                        userDetails={userDetails}
                                        order={currentOrder}
                                        items={parentOrder ? parentOrder.items : currentOrder.items}
                                        btnText={<><i  className="far fa-print cp" />Print Receipt Copy</>}
                                        transaction={null}
                                        refunded={refunded}
                                        partialRefund={partialRefund}
                                        parentOrder={parentOrder}
                                    />
                                    {/*
                                    <Button
                                        onClick={()=>setPrint(true)}
                                        data-cy="order-details-print-btn"
                                    >
                                        <i  className="far fa-print cp" /> Print Receipt Copy
                                    </Button>
                                    */}
                                </>
                            }
                        </div>
                    </Stack>
                </Col>
                <Col sm={12}>
                    {success}
                    {error}
                    {currentOrder && currentOrder.parent_id &&
                        <span>
                            This order is a part of the parent order {" "}
                            <i className="far fa-link cp split-order-link" onClick={()=>history.push(`/p/order/${currentOrder.parent_id}`)}>
                                #{currentOrder.parent_id}
                            </i>
                        </span>
                    }
                    {currentOrder && currentOrder.split_order_ids?.length > 0 &&
                        <>
                            <span>This order was split into the following orders:</span>
                            {currentOrder.split_order_ids.map((splitOrder, index)=>(
                                <span key={index}>
                                    <i className="far fa-link cp split-order-link" onClick={()=>history.push(`/p/order/${splitOrder}`)}>
                                        #{splitOrder}
                                    </i>
                                </span>
                            ))}
                        </>
                    }
                </Col>
            </Row>
            {currentOrder &&
                <>
                    <Row>
                        <br />
                        <OrderStatuses currentOrder={currentOrder} orderStatuses={allOrderStatuses} />  
                    </Row>
                    <hr />                
                    <Row>
                        <Col>
                            <h5><i className="far fa-list" /> Details</h5>
                            <OrderOtherDetails currentOrder={currentOrder} />
                        </Col>
                        <Col>
                            <h5><i className="far fa-receipt" /> Order Totals</h5>
                            <OrderPriceDetails currentOrder={currentOrder} />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <h5><i className="far fa-badge-percent" /> Coupons Applied</h5>
                            <OrderCoupons currentOrder={currentOrder} />
                        </Col>
                        <Col>
                            <h5><i className="far fa-credit-card" /> Transaction History</h5>
                            <TransactionHistory currentOrder={currentOrder} updateOrder={getCurrentOrder} />
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col>
                            <OrderItems 
                                currentOrder={currentOrder} 
                                parentOrder={parentOrder}    
                                modifiedRefund={modifiedRefund}
                                modifiedCompleted={modifiedCompleted}
                                setSuccess={setSuccess} 
                                setError={setError} 
                                refunded={refunded}
                                partialRefund={partialRefund}
                                doneStatuses={doneStatuses}
                                redrawOrder={redrawOrder}
                                registerId={registerId}
                            />
                        </Col>
                    </Row>
                </>
            }

            {(print) &&
                <Print 
                    register_id={currentOrder.register_id}
                    items={parentOrder ? addAddonsAsItemsForPrint(parentOrder.items) : addAddonsAsItemsForPrint(currentOrder.items)}
                    customer={{first_name: currentOrder.user_fname, last_name: currentOrder.user_lname, id: currentOrder.user_id}}
                    order_id={currentOrder.id}
                    order={parentOrder ? parentOrder : currentOrder}
                    status={currentOrder.order_status_id}
                    payment_type={currentOrder.payment_method}
                    copies={1}
                    tip={currentOrder.tip}
                    updateDate={currentOrder.updated_at}
                    onAfterPrint={()=>setPrint(false)}
                    kitchenTicket={true}
                    payee={parentOrder ? {
                        order:{
                            subtotal_price: currentOrder.subtotal_price,
                            tax_total: currentOrder.tax_total,
                            admin_fee_total: currentOrder.admin_fee_total,
                            total_price: currentOrder.total_price,
                        }
                    } : null}
                />
            }
            </div>
        </Container>
    );
}
import React, { useState, useEffect, useRef, useMemo } from 'react';

/**Hook that returns only the lowest number (highest permission) role for the currently logged in user
 * Also returns commonly used permissions "isPatron, isStaff, isAdmin, isSiteBoss"
*/
export const useRoleCheck=()=>{
    let user=JSON.parse(localStorage.getItem('user'));
    let roleIds=[];
    user.roles.forEach((role)=>{
        roleIds.push(role.id)
    })
    let lowest 
    if(roleIds.length===0) lowest=7;
    else lowest = Math.min(...roleIds)
    let role;
    let perm
    switch(lowest){
        case 1:
            role= "SiteBoss Master Admin";
            perm= "isSiteBoss";
            break;
        case 2:
            role="SiteBoss Super Admin";
            perm= "isSiteBoss";
            break;
        case 3:
            role="SiteBoss Manager";
            perm= "isSiteBoss";
        break;
        case 4:
            role="Company Super Admin";
            perm="isAdmin"
            break;
        case 5:
            role="Company Staff";
            perm="isStaff"
            break;
        case 6: 
            role="Non-Staff Manager";
            perm="isPatron"
            break;
        case 7:
            role="Patron";
            perm="isPatron"
            break;
        default: 
            role="Patron"; //If for some reason there is no role found, it will default to patron
            perm="isPatron"
            break;
    }

    const result = useMemo(()=>{
        return ({id: lowest, name: role, permission: perm})
    },[lowest, role, perm]);

    return result;
};

export default function usePrevious(data){

    const ref = useRef();

    useEffect(()=>{
        ref.current = data
    }, [data])
    return ref.current
}

import React, {useState, useEffect} from 'react';

import Transactions from './Transactions';
import styles from './Totals.module.scss';
import { giftCardsNoChange } from '../../PosUtils';

export const Totals = (props) => {
    const [paymentAggregates, setPaymentAggregates] = useState([]);

    useEffect(() => {
        if (props?.order?.transactions) {
            const _paymentAggregates = [];
            props.order.transactions.forEach(t=>{
                if (t.transaction_payment_method_id){
                    const _index = _paymentAggregates.findIndex(a=>+a.transaction_payment_method_id === +t.transaction_payment_method_id);
                    if (_index > -1){
                        _paymentAggregates[_index].amount += t.amount;
                    } else {
                        _paymentAggregates.push({...t, amount: t.amount});
                    }
                }
            });
            setPaymentAggregates(_paymentAggregates);
        }
    }, [props?.order?.transactions]);

    const calculateGC=(amount, total)=>{
        if (amount > total){
            return amount - total;
        } else{
            return 0;
        }
    }

    const calculateChange = (amount, balance, giftCardAmount) =>{
        let change = 0;
        if(!giftCardAmount && amount > 0){
            change =  Math.abs(balance) //the original calculation when this function was added, just in a function instead of in the jsx
        } 
        else if(giftCardAmount > balance && !amount){
            change = amount; //if the gift card is greater than the balance, the giftcard will cover the whole thing and the cash wasn't needed.
        }
        else if(giftCardAmount && amount){
            //the balance is updated every time we update the payments
            if(balance - amount > 0 ) change = 0; //if we still have balance, we don't want to see change
            else change = Math.abs(balance); 
        }
        return change;
    }

    if (!props?.order || !props?.totals) return null;

    let paymentsSeperate = giftCardsNoChange(props.payments);
    // we do this manual calculations so we can later add or remove the cash discount without doing an api call
    let total = +props.totals.total;
    let subtotal = +props?.order?.subtotal_price;
    let balance = +props.totals.balance - +props.totals.to_be_paid;

    let gCBalance = calculateGC(paymentsSeperate?.giftCards?.amount, total);
    let localChange = calculateChange(paymentsSeperate?.otherPayments?.amount, balance, paymentsSeperate?.giftCards?.amount);


    return (
        <div className={styles.container} data-cy="checkout-totals-container">
            <div className={styles.subtotal} data-cy="checkout-totals-subtotal">
                <span>Subtotal</span>
                <span>${subtotal?.toFixed(2)}</span>
            </div>
            <div className={styles.fees}>
                <span>Taxes</span>
                <span>${props?.order?.tax_total?.toFixed(2)}</span>
            </div>
            {props?.order?.tip > 0 &&
                <div className={styles.fees}>
                    <span>Tip</span>
                    <span>${props?.order?.tip?.toFixed(2)}</span>
                </div>
            }
            {props.order?.price_adjustments?.length > 0 ? // if there are price adjustments, show them
                props.order.price_adjustments.map((s, i)=>{ 
                    // if we select another payment method, and the cash discount was already store in price_adjustments, we remove it from the total (the api call will also remove once this one is sent)
                    if (props.totals.just_cash === false && +s.price_adjustment_type_id === 3) {
                        subtotal -= s.amount;
                        total -= s.amount;
                        balance -= s.amount;
                        return null;
                    }

                    if (Math.abs(+s.amount).toFixed(2) <= 0) return null;

                    return (
                        <div key={`price-adjustment-${i}`} className={styles.fees} data-cy="checkout-totals-price-adjustment">
                            <span>{s.price_adjustment_type_name}</span>
                            <span>
                                {s.amount > 0 ? "" : "-"}
                                ${Math.abs(+s.amount).toFixed(2)}
                            </span>
                        </div>
                    );
                })
            : props.totals.just_cash && Math.abs(props?.totals?.admin_fee?.toFixed(2)) > 0 && // if there are no price adjustments, we check our own calculated admin fee that we know, love, trust, cherish and adore
                <div className={styles.fees} data-cy="checkout-totals-cash-discount">
                    <span>Cash Discount</span>
                    <span>-${Math.abs(props?.totals?.admin_fee?.toFixed(2))}</span>
                </div>
            }
            {props?.order?.total_discount> 0 &&
                <div className={styles.fees} data-cy="checkout-totals-coupons">
                    <span>Applied Coupons</span>
                    <span>-${props.order?.total_discount?.toFixed(2)}</span>
                </div>
            }
            {props?.totals?.paid > 0 &&
                <>
                    <div className={styles.fees} data-cy="checkout-totals-payments">
                        <span>Payments</span>
                        <span>${props?.totals?.paid?.toFixed(2)}</span>
                    </div>
                    {paymentAggregates.map((s,i)=>(
                        <div key={`payment-aggregate-${i}`} className={styles.aggregate}>
                            <span>{s.transaction_payment_method_name}</span>
                            <span>${s.amount?.toFixed(2)}</span>
                        </div>
                    ))}
                </>
            }
            <div className={styles.total} data-cy="checkout-totals-total">
                <h3>Total</h3>
                <h3>${/*(+props.totals.total + props?.totals?.admin_fee)*/total?.toFixed(2)}</h3>
            </div>
            {+props.totals.to_be_paid > 0 &&
                <hr/>
            }
            {props.payments?.map((s,i)=>(
                <div key={`payment-row-${i}`} className={styles.fees} data-cy={`checkout-totals-payment-${i}`}>
                    <span>{s.payment_method_name}</span>
                    <span>${s?.amount?.toFixed(2)}</span>
                </div>
            ))}
            {paymentsSeperate?.giftCards?.array?.length > 0 && //even if the amount is 0, should show that for reference, so they know it is now a 0 balance card  
                //instead of the amount being charged, the payment object has the total balance on the giftcard
                <div className={styles.subtotal} data-cy="checkout-totals-gift-cards">
                    <span>Gift Card Balance Remaining</span>
                    <span>${gCBalance.toFixed(2)}</span>    
                </div>
            }
            {+localChange > 0 &&
                <div className={styles.subtotal} data-cy="checkout-totals-change">
                <span>Change</span>
                    <span>${localChange.toFixed(2)}</span>
                </div>
            }
            {+props.order.order_status_id !==2 && +props.totals.to_be_paid.toFixed(2) <= +props.totals.balance.toFixed(2) &&
                <div className={`${styles.subtotal} error-text`} data-cy="checkout-totals-outstanding">
                    <span>Pending Balance</span>
                    <span>${/*(+props.totals.balance + props?.totals?.admin_fee - +props.totals.to_be_paid)*/Math.abs(balance).toFixed(2)}</span>
                </div>
            }
            {(+props.order.order_status_id === 2 || 
                +props.order.order_status_id === 3 || 
                +props.order.order_status_id === 4 || 
                (+props.order.order_status_id <= 1 && props.order?.transactions?.filter(a => 
                    +a.transaction_status_id===7 
                    && +a.transaction_type_id !== 2 
                    && +a.transaction_type_id !== 3
                    && +a.amount - +a.amount_refunded > 0).length>0
                )) &&
                <div className={`${styles.subtotal} justify-content-center`} data-cy="checkout-totals-order-status">
                    <span className={`${styles.paid} ${+props.order.order_status_id <= 1 ? styles.partial : ""}`}>
                        {props.order.order_status_id === 2 && "Paid"}
                        {props.order.order_status_id === 3 && "Cancelled"}
                        {props.order.order_status_id === 4 && "Refunded"}
                        {props.order.order_status_id === 1 && "Partially Paid"}
                    </span>
                </div>
            }
            <Transactions {...props} />
        </div>
    );
}
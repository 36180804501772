import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Tabs from './Tabs';

import Users from '../../../api/Users';
import Groups from '../../../api/Groups';

import './FamilyGroup.scss';

export const FamilyGroup = (props) => {
    
    const mountedRef =useRef(false);
    const family = useSelector(state=>state.family.all_family_no_dupes)
    const [groups, setGroups] = useState();
    const [loadingFamily, setLoadingFamily] = useState(true);
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [loading, setLoading] = useState(true);

    const getGroups=useCallback(async()=>{
        try{
            let confirmed_groups;
            let response = await Users.groups({id: props?.user?.id})
            if(!response.errors && response.data){
                confirmed_groups = response?.data?.filter(user_group => user_group.group_member_status_id===2);
                if(mountedRef.current) setGroups(confirmed_groups);
            }

        }catch(ex){console.error(ex);}
    },[props.user]);

    useEffect(()=>{
        mountedRef.current = true

        return()=> mountedRef.current =false
    },[])

    useEffect(() => {
        if(props.user) {
            getGroups();
        }
    },[props.user, getGroups])

    useEffect(()=>{
        if(family) setLoadingFamily(false)
    }, [family])

    useEffect(()=>{
        if(groups) setLoadingGroups(false)
    },[groups])

    useEffect(() => {
        setLoading(!(!loadingFamily && !loadingGroups));
    },[loadingFamily, loadingGroups])
    
    if(loading) return(
        <SkeletonTheme color="#e0e0e0">
            <div className="family-home-skele-wrapper">
                <div>
                    <Skeleton circle={true} height={50} width={50} />
                    <Skeleton height={12} width={60} />
                </div>
                <div>
                    <Skeleton circle={true} height={50} width={50} />
                    <Skeleton height={12} width={60} />
                </div>
                <div>
                    <Skeleton circle={true} height={50} width={50} />
                    <Skeleton height={12} width={60} />
                </div>
                <div>
                    <Skeleton circle={true} height={50} width={50} />
                    <Skeleton height={12} width={60} />
                </div>
            </div>
            </SkeletonTheme>
    )

    return (
        <React.Fragment>
            <div className="">
                {!loading ?
                    <Tabs user={props.user} includes={
                        [
                            { id: 1, hash: "families", text: "My Family", component: "Families", data: family },
                            { id: 2, hash: "groups", text: "My Groups", component: "Groups", data: groups }
                        ]
                    } default={ family?.length > 0 ? 1 : 2 } />
                : null}
            </div>
        </React.Fragment>
    );
}
import React,{useRef} from 'react';
import {Group, Text} from 'react-konva';
import Shape from './Shapes';

const Layer = (props) => {
	const ref = useRef();

	const shape=props.shape || "rectangle";
	let stroke_color="#bdbdbd";
	let fill_color=props.fill || "#e0e0e0";
	let text_color=props.color || "#212121";
	let opacity=props.opacity || 1;

	// unavailable
	if (props.status===3){
		fill_color="#d32f2f";
		stroke_color="#c62828";
		text_color="#ffffff";
	}

	// unclickable
	if (!props.selectable) {
		fill_color="#eceff1";
		stroke_color="#b0bec5";
		text_color="#757575";		
	}

	// transparent
	if (props.transparent) {
		opacity=0.5;
		fill_color="#cccccc";
		stroke_color="#6f6f6f";
	}

	// selected
	if (props.selected.some(row=>row.id===props.id)) {
		fill_color="#536dfe"; 
		stroke_color="#3d5afe";
		text_color="#ffffff";
	}

  	return (
		<Group>
			<Shape
				type={props.location_shape_blob && 1===2 ? "image" : shape }
				blob={props.location_shape_blob}
				width={parseFloat(props.width) || 100}
				height={parseFloat(props.height) || 100}
				y={parseFloat(props.top) || 0}
				x={parseFloat(props.left) || 0}
				fill={fill_color}
				opacity={opacity}
				stroke={1}
				strokeColor={stroke_color}
				location_id={props.id}
				onClick={props.onClick}
				onSelect={props.onSelect}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
				onMouseOver={(e)=>{
					/*const stage = e.target.getStage();
					const mousePos = stage.getPointerPosition();
					console.log(mousePos);
					console.log(ref.current)
					/*tooltip.position({
					  x: mousePos.x + 5,
					  y: mousePos.y + 5,
					});
					tooltip.show();*/
				}}
			/>
			<Text
				ref={ref}
				fontSize={12}
				text={props.name}
				y={parseFloat(props.top || 0)+15}
				x={parseFloat(props.left|| 0)}
				wrap="char"
				align="center"
				width={parseFloat(props.width)}
				fill={text_color}
				fontFamily= "'Roboto'"
				visible={props.location_shape_blob && 1===2 ? false : true}
			/>
			{props.status===3 &&
				<Text
					fontSize={12}
					text="Unavailable"
					y={parseFloat(props.top || 0)+40}
					x={parseFloat(props.left || 0)}
					wrap="char"
					align="center"
					width={parseFloat(props.width)}
					fill="#ffffff"
					fontFamily="'Roboto'"
				/>
			}
		</Group>
  	);
}

export default Layer;
import * as actionTypes from '../actions/actionTypes';

const initialState= {
    displayMode: "desktop",
    currentWebsite: null,
    currentWebsiteTheme: null,
    currentWebsiteCss: [],
    currentPage: null,
    currentPageProps: null,
    currentElement: null,
    currentElementIndex: null,
    elements: [],
    builderItems: [],
};

const cmsReducer = (state = initialState, action)=>{
    //let new_elements = [...state.elements || []];
    switch(action.type){
        // set the current selected website
        case actionTypes.CMS_DISPLAY_MODE:
            return{
                ...state,
                displayMode: action.mode
            }

        // set the current selected website
        case actionTypes.CMS_CURRENT_WEBSITE:
            return{
                ...state,
                currentWebsite: action.website_id
            }

        // set the theme for the current selected website
        case actionTypes.CMS_CURRENT_WEBSITE_THEME:
            return{
                ...state,
                currentWebsiteTheme: action.theme
            }

        // set the css for the current selected website
        case actionTypes.CMS_CURRENT_WEBSITE_CSS:
            return{
                ...state,
                currentWebsiteCss: action.css
            }

        // set the current selected page
        case actionTypes.CMS_CURRENT_PAGE:
            return{
                ...state,
                currentPage: action.page_id
            }

        // set the current selected page props
        case actionTypes.CMS_CURRENT_PAGE_PROPS:
            return{
                ...state,
                currentPageProps: action.page_props
            }
            
        // set the current selected element for manipulation
        case actionTypes.CMS_CURRENT_ELEMENT:
            return{
                ...state,
                currentElement: action.element_id
            }

        /*
        // add an element to the cms structure
        case actionTypes.CMS_ADD_ELEMENT:
            const idx_add = new_elements.findIndex(a=>a && a.id===action.element.id);
            if (idx_add>=0){
                if (action.indexToReplace && action.newIndex){
                    const item = new_elements[action.indexToReplace];
                    item.parent_id = action.element.parent_id;
                    const newer_elements = [...new_elements];

                    if (action.indexToReplace < action.newIndex){
                        const prevItem = newer_elements.splice(action.newIndex, 0, item);
                        newer_elements.splice(action.indexToReplace, 1, prevItem[0]);
                    } else {
                        const prevItem = newer_elements.splice(action.indexToReplace, 1);
                        newer_elements.splice(action.newIndex, 0, prevItem[0]);
                    }
                    new_elements = [...newer_elements];
                } else {
                    new_elements[idx_add] = {...action.element};
                    new_elements.sort((a, b)=>a.index-b.index);
                }
            } else {
                if (action.newIndex){
                    if (action.newIndex > new_elements.length) action.newIndex = new_elements.length;
                    new_elements.splice(action.newIndex, 0, {...action.element})
                } else new_elements.push({...action.element});
            }

            // loop through the elements and group them by parent
            let idx=0;
            const _groupElements = (elements, parent_id=0)=>{
                const grouped_elements = [];
                elements.filter(a=>a && a.parent_id===parent_id).forEach(element => {
                    element.index = idx;
                    idx++;
                    if (element?.[element.element_id]) {
                        if (element[element.element_id]?.props?.value || element[element.element_id]?.props?.innerText)
                            element[element.element_id].innerText = element[element.element_id].props.value || element[element.element_id].props.innerText;
                        element[element.element_id].content = _groupElements(elements, element.id);
                    } 
                    grouped_elements.push(element);
                });
                return grouped_elements;
            }
            _groupElements(new_elements);

            new_elements.sort((a, b)=>a.index-b.index);
            return{
                ...state,
                elements: [...new_elements]
            }

        // remove an element from the cms structure
        case actionTypes.CMS_REMOVE_ELEMENT:
            const idx_remove = new_elements.findIndex(element=>element && element.id===action.element.id);
            if (idx_remove!==-1) new_elements.splice(idx_remove, 1);

            // loop through the elements and update the index
            new_elements.forEach((element, index)=>{
                if (element.element_id.toLowerCase() === "layout"){ // if its a layout make sure we have the right amount of elements so we can update the structure in case a column was removed
                    if (element?.properties?.find(p => p.name === "layout-type")?.value?.toLowerCase() === "row"){
                        const _children = new_elements.filter(a=>a && a.parent_id===element.id) || [];
                        const _layout = element?.properties?.findIndex(p => p.name === "custom-layout");
                        if (_layout>-1){
                            element.properties[_layout].value = _children.length || "";
                            element[element.element_id].props["custom-layout"] = _children.length || "";
                            element[element.element_id].content = _children;
                        }
                    }
                }
                if (element.element_id.toLowerCase() === "wizard"){
                    const _children = new_elements.filter(a=>a && a.parent_id===element.id) || [];
                    const _layout = element?.properties?.findIndex(p => p.name === "custom-steps");
                    if (_layout>-1){
                        element.properties[_layout].value = _children.length || "";
                        element[element.element_id].props["custom-steps"] = _children.length || "";
                        element[element.element_id].content = _children;
                    }
                }
                element.index = index;
            });

            return{
                ...state,
                elements: [...new_elements]
            }

        // reset the cms structure
        case actionTypes.CMS_RESET:
            return{
                ...state,
                elements: action.elements
            }
        */
        
        // store element index
        case actionTypes.CMS_CURRENT_ELEMENT_INDEX:
            return{
                ...state,
                currentElementIndex: action.index
            }

        // store cms builder items for drag, drop, reorder
        case actionTypes.CMS_BUILDER_ITEMS:
            return{
                ...state,
                builderItems: action.builderItems
            }            

        default:
            return state;
    }
}

export default cmsReducer;
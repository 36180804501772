import React, { useState, useEffect, useCallback, useRef } from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { differenceInMinutes } from 'date-fns';

import usePrevious from '../CustomHooks';
import MenuAPI from '../../../api/Menu';

import './SideBar.scss';

import { authUserHasModuleAccess } from "../../../utils/auth";
import { remove } from 'immutable';
const WIDGET_MODULE_ID = 190;

const TIME_BETWEEN_CHECKS = 15; //in minutes
const MILLISECOND_CHECK = 300000 //because of the async nature of EVERYTHING, we probably don't want to to do the exact same interval that we want to refresh on.  

const SideBar = (props) => {

    const userId = props?.userInfo?.user?.profile?.id || null
    const history = useHistory();
    const windowSize = window.innerWidth;
    const [items, setItems]=useState(MenuAPI.getSideBar());
    const [ noOrphanFolder, setNoOrphanFolder ]=useState([])
    const { collapseSidebar, collapsed, toggled, toggleSidebar } = useProSidebar();
    const previousId = usePrevious(userId);
    const [userHasModulePermission, setUserHasModulePermission] = useState(false);

    const getFreshMenuItems = useCallback(async(localUser)=>{
        try{
            let response = await MenuAPI.getFullMenu();
            if (response.status === 200 && response.data){
                if (localUser.menus?.[0]){
                    localUser.menus[0].sidebar = response.data?.[0].sidebar;
                    let sorted = response.data[0].sidebar.sort((a,b)=>(a.sort_order < b.sort_order ? -1 : a.sort_order > b.sort_order));
                    setItems(sorted);
                    localStorage.setItem("menu_time", new Date());
                }
            }
            if (response.errors || response.status !== 200){
                //if there's an error, we don't want to pop the error catcher, just reset and try again later
                console.error(response.errors) 
                localStorage.setItem("menu_time", new Date())
            }
        }catch(ex){ 
            console.error(ex)
        }
    },[]);

    const checkPermission = useCallback(async () => {
        try {
            let response = await authUserHasModuleAccess(WIDGET_MODULE_ID);
            setUserHasModulePermission(response);
        } catch (error) { console.error(error) }
    },[])

    const checkTime=useCallback(()=>{
        let menuTime = localStorage.getItem("menu_time");
        let difference;
        if(menuTime){
            difference = differenceInMinutes(new Date(), new Date(menuTime));
        }
        if(difference >= TIME_BETWEEN_CHECKS){
            let localUser = JSON.parse(localStorage.getItem('user'));
            if(localUser) getFreshMenuItems(localUser)
        }
    },[getFreshMenuItems])

    useEffect(()=>{
        if(userId) checkPermission();
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[checkPermission])

    useEffect(()=>{
        localStorage.setItem("menu_time", new Date())
        const timerCheck = setInterval(checkTime, MILLISECOND_CHECK);
        
        return()=>{
            clearInterval(timerCheck)
        }
    },[checkTime])

    useEffect(()=>{
        if(userId !== previousId){
            toggleSidebar(true);
            let localUser = JSON.parse(localStorage.getItem('user'));
            if(windowSize <= 992) toggleSidebar(false);
            if(localUser) getFreshMenuItems(localUser)
            checkPermission();
        }else if (!userId){
            setItems([])
            toggleSidebar(false)
        }
    }, [userId, previousId, getFreshMenuItems, toggleSidebar, checkPermission, windowSize]);

    //to remove empty folders
    useEffect(()=>{
        // const handleChildren=(item)=>{
        //     if(item.module_type_id !== 4) return item;
        //     else if(item.module_type_id === 4 && !item.children) return;
        //     else if(item.module_type_id === 4 && item.children) {
        //         let newChildren = handleChildren(item.children[0]);
        //         if(newChildren) return item;
        //         else return;
        //     };
        // }

        const checkChildren=(item)=>{
            if(item.module_type_id !== 4) return item;
            else if(item.module_type_id === 4 && !item.children) return;
            else if(item.module_type_id ===4 && item.children){
                let children = removeEmptyFolders(item.children);
                item.children = children;
                if(item.children.length > 0) return item
                else return;
            }
        }
        
        const removeEmptyFolders=(itemsToCheck)=>{
            let newItems = []
            for(let i = 0; i < itemsToCheck.length; i++){
                let isOkay = checkChildren(itemsToCheck[i])
                if(isOkay) newItems.push(isOkay)
            }
            return newItems 
        }    
        
        if(items){
            let newItems = removeEmptyFolders(items);
            if(newItems) setNoOrphanFolder(newItems);
        }
    },[items])

    //for the menu to create more children
    const recursiveMap = (item) =>{
        return(
            <SubMenu 
                key={`sub-item-${item.id}`}
                data-cy={`sub-menu-item-${item.name}-${item.id}`}
                icon={item.icon ? <i className={`${item.icon}`} /> : <span>{item.name[0]}</span>} 
                label={item.name}
            >
                {item.children.map((child)=>(
                    !child.children ?
                        <MenuItem 
                            key={`sub-menu-item-${child.id}`}
                            data-cy={`menu-item-${child.name}-${child.id}`}
                            icon={child.icon ? <i className={`${child.icon}`} /> : null}
                            component={
                                child.hasOwnProperty("url") && child.url !== null && child.url.startsWith("/p/") ? 
                                    <Link to={`${child.url}`} /> 
                                : 
                                    <Link to={{pathname: child.url }} component={RedirectComponent}/> 
                                }
                            className={history.location.pathname === child.path ? "active" : ""}
                        >
                            {child.name}
                        </MenuItem>    
                    :
                        recursiveMap(child)
                ))}
            </SubMenu>
        )
    }

    if (!userHasModulePermission) return <></>;

    return(
        <div id="side-bar-menu" className={`sidebar-wrapper-thing ${toggled ? collapsed ? "collapsed-min-width" :  "sidebar-min-width" : "" } ${props.logged_in ? '' : 'hide'}`}>
            {!props.logged_in ? 
                <></>
            
            :
                <Sidebar 
                    width="250px" 
                    customBreakPoint="992px" 
                    className="sidebar-wrapper"
                >
                    <Menu >
                        <MenuItem 
                            icon={collapsed ? 
                                <i className="far fa-chevron-double-right" />
                            :
                                <i className="far fa-chevron-double-left" />
                            }
                            onClick={()=>collapseSidebar()}
                        >
                            {collapsed ? "Expand" : "Collapse"}
                        </MenuItem>
                        {noOrphanFolder?.map((item)=>(
                            
                            !item.children ?   
                                <MenuItem 
                                    key={`menu-item-${item.id}`}
                                    data-cy={`menu-item-${item.name}-${item.id}`}
                                    icon={item.icon ? <i className={`${item.icon}`} /> : <span>{item.name[0]}</span>} 
                                    component={item.hasOwnProperty("url") && item.url !== null && item.url.startsWith("/p/") ? 
                                    <Link to={`${item.url}`} /> 
                                    : 
                                        <Link to={{pathname: item.url}} component={RedirectComponent}/> 
                                    } 
                                    className={history.location.pathname === item.path ? "active" : ""}
                                >
                                    {item.name}
                                </MenuItem>
                            :
                                //if there are chilren, go here
                                recursiveMap(item)                            
                        ))}
                    </Menu>
                </Sidebar>
            }
        </div>
    )
}

//necessary for the external links
const RedirectComponent = React.forwardRef(({navigate, ...props}, ref)=>{

    return (
        <a {...props} >{props.children} </a>
    )
});

export default SideBar;

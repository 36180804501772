import React,{ useState} from 'react';
import {Personal} from './Personal';
import {CityBlocks} from './CityBlocks';
import {Family} from './Family';
import './Tabs.css';

export const Tabs = props => {

    // selected tab
    const [selectedTab, setSelectedTab] = useState(<Personal key="personal" user_id={props.includes[0].user_id} />);

    // load content based on the tab selected
    const tabClickHandler = (e) =>{
        switch(e.target.hash){
            default:
            case "#personal":
                setSelectedTab(<Personal key="personal" />);
                break;
            case "#cityblocks":
                setSelectedTab(<CityBlocks key="city" />);
                break;
            case "#family":
                setSelectedTab(<Family key="family" />);
                break;
        }        
    }
    
  

    return (
        <React.Fragment>
            {/* remove this comment when enabling tabs
            <div>
                <Nav defaultActiveKey={`#${props.includes?.[0].hash}`} as="ul">
                    {props.includes.map((item,i)=> (
                        <Nav.Item key={item.hash+"-"+i} as="li">
                            <Nav.Link href={`#${item.hash}`} onClick={(event)=>tabClickHandler(event)}>
                                {item.text}
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </div>
            */}
            <div>
                {selectedTab}
            </div>
        </React.Fragment>
    );
}
import React, { useState,useEffect,useCallback,Suspense} from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Container, Button } from 'react-bootstrap';

import './MultiStep.scss';

//This component is used in: 
//src\containers\POS\Payment\SplitPayment\MultiUserMultiItems\MultiUserMultiItems.js
//src\containers\POS\Payment\SplitPayment\MultiUserSingleItem\MultiUserSingleItem.js
//src\containers\POS\Payment\SplitPayment\SingleUserSplit\SingleUserSplit.js
//src\containers\Event\CreateWizard\CreateWizard.js
//src\containers\Service\CreateWizard\CreateWizard.js

const MultiStep = ({
    steps=[], 
    goTo=null, 
    onSubmit=()=>{}, 
    showNavigation=true, 
    showNumberNav=false, 
    onChangeStep=()=>{return true}, 
    stepOrder=null, 
    saveStep=()=>{}, 
    hideButtons=false, 
    disableSubmit=false, 
    disableAll=false,
    hideBack=false, 
    lastStepName="Save",
    beforeNextContent = null,
    afterBackContent = null,     
    ...props}) => {

    const [stepList, setStepList] = useState(steps);
    const [currentStep, setCurrentStep] = useState(0);
    const [pagesVisited, setPagesVisited] = useState([]);

    const [navPagePart, setNavPagePart] = useState();
    const [pagePart,setPagePart]=useState(
        <Suspense fallback={             
            <SkeletonTheme color="#e0e0e0">
                <Skeleton height={30} style={{marginBottom:"1rem"}} />
                <Skeleton height={12} count={5} />
            </SkeletonTheme>
        }>            
        </Suspense>
    );

    const goToStep = useCallback((name) => {
        let index = stepList.findIndex(step => step.name.toLowerCase()===name.toLowerCase());
        if (index>-1) setCurrentStep(index);
        saveStep(name);
    },[stepList, saveStep]);

    const onClickLink = useCallback(index => {
        if(onChangeStep(steps[currentStep].name)) {
            setCurrentStep(index);
            saveStep(steps[currentStep].name);
        }
    //adding save step here when it isn't passed in from a parent component causes an infinite loop
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[steps, currentStep, onChangeStep]);    
    // },[steps, currentStep, onChangeStep, saveStep]);    

    useEffect(()=>{
        if(goTo && stepList) {
            // go to the step with the specified name
            goToStep(goTo);
        }
    },[goTo, stepList, goToStep]);



    useEffect(() => {
        if(steps[currentStep]) {
            let component = steps[currentStep].component;

            if(showNavigation) {
                setNavPagePart(
                    <ol className="step-bar">
                        {stepList.map((step, index) => (
                            <li
                                key={step.name}
                                value={index}
                                className={`${index===currentStep ? "doing" : ""} ${index<currentStep ? "done" : ""} ${pagesVisited.includes(index) ? "visited" : ""} ${index>currentStep ? "todo" : ""}`}
                                onClick={() => { if (pagesVisited.includes(index)) onClickLink(index) }}
                            >
                                <em>{showNumberNav ? index+1 : step.name}</em>
                            </li>
                        ))}
                    </ol>
                );
            }

            setPagePart(
                <Suspense fallback={             
                    <SkeletonTheme color="#e0e0e0">
                        <Skeleton height={30} style={{marginBottom:"1rem"}} />
                        <Skeleton height={12} count={5} />
                    </SkeletonTheme>
                }>
                    {component}
                </Suspense>
            );
        }
    },[stepList, currentStep, onClickLink, pagesVisited, showNavigation, showNumberNav, steps]);

    useEffect(() => {
        if(Array.isArray(pagesVisited) && !pagesVisited.includes(currentStep)) {
            let arr = pagesVisited;
            arr.push(currentStep)
            setPagesVisited(arr);
        }
    },[currentStep,pagesVisited]);



    const onClickBack = event => {
        if (isFirstStep()) {
            // the back button should be hidden on the first step, but even if it's not do nothing
        } else if(stepOrder) {
            // find index of current step name in the list, go to the next item
            let index = stepOrder.findIndex(name => steps[currentStep].name===name);
            if (index>0) {
                goToStep(stepOrder[index-1]);
            }
        } else {
            setCurrentStep(currentStep-1);
            saveStep(steps[currentStep].name);
        }
    };

    const onClickNext = event => {
        if(onChangeStep(steps[currentStep].name)) { // validation
            if(isLastStep()) {
                onSubmit();
            } else if(stepOrder) {
                // if there's a listed order, check the list to find the next step
                let index = stepOrder.findIndex(name => steps[currentStep].name===name);
                if (index < stepOrder.length-1) {
                    goToStep(stepOrder[index+1]);
                }
            } else {
                setCurrentStep(currentStep+1);
                saveStep(steps[currentStep].name);
            }
        }
    };

    const isFirstStep = () => {
        if (stepOrder) {
            return stepOrder.findIndex(name => steps[currentStep].name===name) === 0;
        }
        return currentStep === 0;
    }
    const isLastStep = () => {
        if (stepOrder) {
            return stepOrder.findIndex(name => steps[currentStep].name===name) === stepOrder.length-1;
        }
        return currentStep === stepList.length-1;
    }
    
    return (
        <Container fluid className="multi-step">
            {showNavigation ? navPagePart : ""}

            {pagePart}

            {!hideButtons &&
                <>
                    <hr/>
                    <Container fluid className="button-row ">
                        {!hideBack && 
                            <div>
                                <Button variant="light" data-cy="multistep-back" className={isFirstStep() ? "hidden" : ""} disabled={disableAll} onClick={onClickBack}>
                                    <i className={`far fa-arrow-left`}></i>Back
                                </Button>
                                {afterBackContent}
                            </div>
                        }
                        <div>
                            {beforeNextContent}
                            <Button
                                variant="primary"
                                data-cy="multistep-next"
                                className={isLastStep() ? "submit" : ""}
                                disabled={isLastStep() && (disableSubmit || disableAll)}
                                onClick={isLastStep() ? onSubmit : onClickNext}
                            >
                                {isLastStep() ? lastStepName : "Next"}
                                <i className={`mx-2 mr-0 far fa-arrow-right ${isLastStep() ? "hidden" : ""}`}/>
                            </Button>
                        </div>
                    </Container>
                </>
            }
        </Container>
    );
}
export default MultiStep;

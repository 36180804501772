import React, {useState, useEffect, useCallback} from 'react';
import {Container, Card, Accordion, Row, Col, Form, InputGroup } from 'react-bootstrap';

import Stack from '../../../../../../components/common/Stack';
import styles from '../../Wizard.module.scss';

export const Item = (props) => {
    const {itemUpdate} = props;
    
    const [accordionToggle, setAccordionToggle] = useState(false);
    const [totalRefund, setTotalRefund] = useState(0);
    const [refundAmount, setRefundAmount] = useState();
    const [checked, setChecked] = useState({
        selected: true,
        full: !props?.data?.refund_type ? true : props?.data?.refund_type === "full amount",
        custom: props?.data?.refund_type === "custom amount" || false
    });

    const getTotalRefund = useCallback(()=>{
        let _refund = 0;
        if (checked.full) _refund = +props.transaction.amount;
        else if (checked.custom) _refund = +refundAmount;

        setTotalRefund((+_refund || 0).toFixed(2));        
        itemUpdate({transaction: props.transaction, checked: checked, refund: _refund});
    },[checked, refundAmount, itemUpdate, props.transaction]);

    const toggleCheckHandler = useCallback((e)=>{
        e.preventDefault();
        e.stopPropagation();
        
        setChecked(prev => {
            let _checked = !prev.selected;

            if (!_checked) {
                setRefundAmount("");
                return {selected: _checked, full: false, custom: false};
            } else {
                return {selected: _checked, full: true, custom: false};
            }
        });
    }, []);

    const checkHandler = useCallback(e => {
        const _values = {selected: true, full: false, custom: false};
        _values[e.target.name] = e.target.checked;
        if (!_values.full && !_values.custom) _values.selected = false;
        if (e.target.name !== "custom") setRefundAmount("");
        setChecked(prev => ({...prev, ..._values}));
    }, []);

    const checkTotal = useCallback(e => {
        const _amount = e.target.value || 0;
        checkHandler({target: {name: "custom", checked: +_amount > 0}});
        if (+_amount > +props.transaction.amount) setRefundAmount(+props.transaction.amount);
        else setRefundAmount(+_amount);
    }, [checkHandler, props.transaction.amount]);

    useEffect(()=>{
        getTotalRefund();
    },[getTotalRefund, checked]);

    if (!props.transaction) return null;


    const transaction_name = `${
        props.transaction.transaction_payment_method_id === 1 ?
            `
            ${props.transaction?.cc_type || "Card"} 
            ${props.transaction?.cc_number ? ` ending in ${props.transaction.cc_number}` : ""}
            `
        :
            `
            ${props.transaction?.transaction_payment_method_name || "Payment"}
            ${props.transaction.transaction_response?.check_number ? ` #${props.transaction.transaction_response.check_number }` : ""}
            `
    }`;

    return (
        <>
            <Accordion.Toggle id={"refund-accordion-toggle"} as={Card.Header} eventKey={props.index} onClick={e=>{setAccordionToggle(!accordionToggle)}}>
                <Row className={`${styles.item} ${accordionToggle ? styles.active : ''}`}>
                    <Col sm={12} lg={9} className="py-2 position-relative">
                        <i className={`${styles["item-check"]} far fa-${checked?.selected ? "check-square" : "square"}`} onClick={toggleCheckHandler} />
                        <Stack direction="horizontal" spacing={2}>
                            <i className={`ms-5 me-4 far fa-chevron-${accordionToggle ? "up" : "down"}`}/>
                            <div> 
                                <span className="bold">{transaction_name}</span><br/>
                                <span className="small">Max Refund: ${+props.transaction.amount.toFixed(2)}</span>
                            </div>
                        </Stack>
                    </Col>
                    <Col sm={12} lg={3} className="py-2 text-right">
                        Refund for this Payment:<br/>
                        <span className="bold">${totalRefund}</span>
                    </Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.index}>
                <Container className="mt-2">
                    <Row className="ms-3">
                        <Col sm={12} lg={4}>
                            <div className={styles["info-pair"]}>
                                <span>
                                    <Form.Check 
                                        id="refund-full"
                                        name="full"
                                        type="checkbox" 
                                        value={+props.transaction.amount}
                                        checked={checked.full}
                                        onChange={checkHandler}
                                        className="ps-0"
                                    /> 
                                    <label htmlFor="refund-full">Total Amount:</label>
                                </span>
                                <span className="text-right">${+props.transaction.amount.toFixed(2)}</span>
                            </div>
                            <Form.Group controlId="refund-custom-input" className={`${styles["colspan"]}`}>
                                <Form.Label>Custom Refund Amount</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control 
                                        type="number" 
                                        name="refund-custom-input" 
                                        min={0}
                                        max={+props.transaction.amount}
                                        step={0.1}
                                        value={refundAmount} 
                                        onChange={checkTotal}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr/>
                </Container>
            </Accordion.Collapse>
        </>
    );
}
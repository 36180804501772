import React, { useState, useEffect } from 'react';
import { Container, Row, Col, InputGroup, Form } from 'react-bootstrap';

//Pos.refund - amount, transaction_id, payment_type, register

export const AmountRefund = ({
    registers=[],
    selectedRegister="",
    setSelectedRegister=()=>{console.log("No function passed in for `setSelectedRegister`")},
    fullOrder={},
    setInvalidTotal=()=>{console.log("No function passed in for `setInvalidTotal`")},
    setRefundAmount=()=>{console.log("No function passed in for `setRefundAmount`")},
    ...props
}) => {

    const [totalAllowed, setTotalAllowed]=useState(0);

    useEffect(()=>{
       if(fullOrder) {
        setTotalAllowed(fullOrder?.total_price)
    }
    },[fullOrder])

    const handleAmountChange=(e)=>{
        e.preventDefault();
        if(+e.target.value > +totalAllowed){
            setRefundAmount(0)
            setInvalidTotal(true)
        }else if (e.target.value <= totalAllowed) {
            setRefundAmount(+e.target.value)
            setInvalidTotal(false)
        }else {
            setRefundAmount(0)
            setInvalidTotal(false)
        }
    }

    return (
        <Container fluid>
            <h5 className="title">Refund amount up to: ${totalAllowed?.toFixed(2)}</h5>
            <Row>
                <Col sm={12} lg={3}>
                    <Form.Group controlId="refund-amount">
                        <Form.Label>Refund Amount</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="number" name="amount" min="0" step="0.1" onChange={handleAmountChange} />
                        </InputGroup>
                    </Form.Group>
                </Col>
                <Col sm={12} lg={3}>
                    <Form.Group controlId="refundRegister">
                        <Form.Label>Refund Register</Form.Label>
                        <Form.Control as="select" custom name="refundRegister" required>
                            <option value="">No Register</option>
                            {registers?.map(register=>(
                                <option key={`register-${register.id}`} value={register.id}>{register.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}

export default AmountRefund
import React, { useState, useEffect } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { format } from 'date-fns';

import { useRoleCheck } from '../../../../components/common/CustomHooks';
import { authUserHasModuleAccess } from "../../../../utils/auth";
import APIUsers from '../../../../api/Users';

const VIEW_MODULE_ID = 318;
export const GiftCards = (props) => {

    const [loading,setLoading]=useState(true);
    const [error, setError]=useState();
    const [userHasModulePermission, setUserHasModulePermission] = useState(false);
    const [giftCards, setGiftCards] = useState([]);

    useEffect(() => {
        const checkPermission = async () => {
            setLoading(true);
            try {
                const res = await authUserHasModuleAccess(VIEW_MODULE_ID, props.user_id);
                setUserHasModulePermission(res[VIEW_MODULE_ID]);
            } catch (error) { 
                console.error(error);
            }
            setLoading(false);
        }

        const getGiftCards = async () => {
            setLoading(true);
            try {
                const res2 = await APIUsers.getGiftCards({ user_id: props.user_id });
                if (res2.data) {
                    setGiftCards(res2.data);
                }
            } catch(error) {
                console.error(error);
            };
            setLoading(false);
        }

        if (props.user_id) {
            checkPermission();
            getGiftCards();
        }
        
        return () => {
            setLoading(false);
            setGiftCards([]);
        }        
    },[props.user_id]);

    
    if (loading) return (
        <SkeletonTheme color="#e0e0e0">
            <div className="mt-3">
                <Skeleton height={28} width={200}/>
                <Skeleton height={16} count={4} />
            </div>
        </SkeletonTheme>
    )

    return (
        <Container fluid>
            <h4 className="section-title">Purchased Gift Cards</h4>
            <hr/>
            {!userHasModulePermission ? <p>You do not have permission to view this user's gift cards.</p> :
                <Row>
                    {giftCards?.length===0 ?
                        <p className="text-center">No gift card purchases to display.</p>
                    : giftCards.map(gc => (
                        <Col xs={12} key={gc.id}>
                            <Card className="standout mb-3">
                                <Card.Body className="p-0 mb-2">
                                    <div><span className="bold">Recipient Name:</span> {gc.recipient_full_name}</div>
                                    <div><span className="bold">Email:</span> {gc.recipient_email}</div>
                                    <div><span className="bold">Delivery Date:</span> {format(new Date(gc.delivery_date), "M/d/yy ")}</div>
                                    <div><span className="bold">Message:</span> {gc.recipient_message}</div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            }
        </Container>
    );
};
import React, { useEffect } from 'react'
import Table from 'react-bootstrap/Table'

/**Component for display most order details */
export const DisplayOrdersTable = (props) => {

    const eachItem = (item, isAddon) =>{
        return(
            <tr data-cy="each-order-item">
                {/*props.isAdmin && ((isAddon && item.final_price) || !isAddon) ?
                    <td>
                        <input type="checkbox"
                            disabled={item.disabled} 
                            id={`cb-items-${item.id}`}
                            name={item.product_name} 
                            value={item} 
                            onChange={(e)=>{props.handleCheckBox(item, e)}} 
                        />
                    </td>
                :<td/>*/}
                <td/>
                <td>{item.product_id}</td>
                <td data-cy="item-name">
                    {!isAddon ?
                        <span>
                            {item.product_name} 
                            {item?.variant_name?.toLowerCase()==="default" ? 
                                null 
                            : 
                                <span>- {item.variant_name}</span>
                            }
                        </span>
                    :
                        <span>
                            <span className="bold">ADDON - </span>
                            {item.product_name}
                        </span>
                    }
                </td>
                {item?.base_price ?
                    <td>${item.base_price?.toFixed(2)}</td>
                    :
                    <td>$0.00</td>
                }
                {item?.final_price ?
                    <td>${item?.final_price?.toFixed(2)}</td>
                    :
                    <td>$0.00</td>
                }
                {item?.tax ?
                    <td>${item?.tax?.toFixed(2)}</td>
                    :
                    <td>$0.00</td>
                }
                {item?.shipping ?
                    <td>${item.shipping?.toFixed(2)}</td>
                    :
                    <td>$0.00</td>
                }
                <td data-cy="item-quantity">{item.quantity}</td>
            </tr> 
        )
    }

  return (
    <Table striped className={"mt-0"}>
        <thead>
            <tr>
                <th></th>
                <th>Id</th>
                <th>Name</th>
                <th>Base Price</th>
                <th>Final Price</th>
                <th>Tax</th>
                <th>Shipping</th>
                <th>Quantity</th>
            </tr>
        </thead>
        <tbody>
        {props.currentOrder.items?.map((item,i )=>(
            <React.Fragment key={`uo-table-items${i}`}>
                {eachItem(item, false)}
                {item?.addons?.map((addon, i)=>(
                    <React.Fragment key={`uo-table-addons${i}`} >
                        {eachItem(addon, true)}
                    </React.Fragment>
                ))}
            </React.Fragment>
            
        ))}
        </tbody>
    </Table>
  )
}



import React,{useState,useEffect,useCallback} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import {confirm} from '../../../../components/Confirmation';
import Pos from '../../../../api/Pos';
import * as actions from '../../../../store/actions';

import '../../Preview/Preview.scss';
import './AddonPreview.scss';

export const AddonPreview = (props) => {

    const {register_id, handleClose} = props;

    const dispatch = useDispatch();

    const selected_tab=useSelector(state => state.pos[register_id].tab);
    const order=useSelector(state => state.pos[register_id].order);
    const order_status=useSelector(state => state.pos[register_id].order_status);

    const aitems = useSelector(state => state.pos[register_id].items);    
    const customer = useSelector(state => state.pos[register_id].user);
    const activeProduct = useSelector(state => state.pos[register_id].item); 
    const company=useSelector(state=>state.company);    

    const [updateTrigger,setUpdateTrigger]=useState(0);
    const [filteredItems, setFilteredItems] = useState([]);
    const [afterDelete, setAfterDelete] = useState(false);

    //const GUEST_USER_ID = 7;
    const GUEST_USER_ID = company?.config?.guest_user_id || null;

    const confirmDeleteHandler = useCallback(
        (props) => {
            confirm(props.text,{
                title:"Whoa!",
                okText:"Yes",
                cancelText:"No",
                cancelButtonStyle:"light"
            }).then(result =>{
                if (result===true) props.click();
            });
        },[]
    );

    useEffect(() => {
        const mainActiveProduct = activeProduct?.type===0 ? activeProduct : null;
        if (mainActiveProduct) {
            // filters out any item that isn't part of this product group (parent & children)
            let filteredItems = aitems.filter(item => (
                mainActiveProduct.id===item.id || 
                mainActiveProduct.product_id===item.id || 
                mainActiveProduct.id===item.parent_id || 
                mainActiveProduct.product_id===item.parent_id
            ));
            // if there are no items left in the group after deleting items close the window
            if (afterDelete && filteredItems.length===0) {
                handleClose();
            } else {
                setFilteredItems(filteredItems);
            }
        } else {
            setFilteredItems([]);
        }
    },[aitems, activeProduct, afterDelete, handleClose]);

    useEffect(()=>{
        if (updateTrigger===1){
            const updateItems = async()=>{
                Pos.local.save(register_id && `POS-${register_id}`, aitems);

                // updates db
                let all_items=[];
                aitems.forEach(item=>{
                    if (item.type!==2){
                        let all_addons=[];
                        if (item.addons){
                            item.addons.forEach(addon=>{
                                all_addons.push({
                                    order_item_id: addon.id || null,
                                    id: addon.id || null,
                                    variant_id:addon.variant_id,
                                    qty:addon.qty,
                                });
                            });
                        }
                        all_items.push({
                            order_item_id: item.id || null,
                            id: item.id || null,
                            variant_id:item.variant_id,
                            qty:item.qty,
                            addons:all_addons,
                            event: item.event
                        });
                    }
                });

                await Pos.order.update({
                    register_id: register_id,
                    order_id: order,
                    items: all_items,
                    user_id: customer?.id || GUEST_USER_ID,
                    order_status_id: order_status,
                    tip: 0
                }).then(async res=>{
                    if (!res.errors){
                        let new_tab={...selected_tab};
                        new_tab.items=[...aitems];
                        await Pos.local.update(`POS-NUMTABS-${register_id}`,new_tab);
                                
                    }
                });
                setUpdateTrigger(0);
            }
            updateItems();
        }
    },[updateTrigger,aitems,register_id,selected_tab,order,customer,order_status,GUEST_USER_ID]);


    return (
        <section className="preview-container addon-preview">
            <div className="preview-addons-grid">
                {filteredItems.map((item,i) => {

                    let isAddon = item.parent_id && item.parent_id!==item.product_id;

                    return (
                        <div key={`addon-preview-${i}`} className={`row-wrapper ${isAddon && 'no-top-border'}`}>
                            <div className='item-name'>
                                {isAddon && 
                                    <span className="addon-dot"><i className="far fa-circle"></i></span>
                                }
                                {item.product_name}
                                {item.variant_id && item.parent_id===item.id &&
                                    <>
                                        {" "}-{" "}<span>{item.variant_name}</span>
                                    </>
                                }
                            </div>
                            {/* <td  className="setwi2">
                                {+item.qty>1 && 
                                    <span>({(item.qty).toFixed(0)}){" "}</span>
                                }
                            </td> */}
                            <div className='product-buttons'>
                                <Button variant="light" className="btn-remove" data-cy="delete-prod-btn" data-id={item.id} data-variant={item.variant_id} data-hash={item.hash} data-type={item.type}
                                    onClick={(e) => {
                                        if (+item.qty-1<=0){
                                            confirmDeleteHandler({
                                                text:`Are you sure you want to delete this line?`,
                                                click:()=>{
                                                    dispatch(actions.removePOSItem(e.target.dataset, register_id));
                                                    setAfterDelete(true);
                                                }
                                            });
                                        } else {
                                            Pos.local.update(register_id && `POS-${register_id}`, {...item,qty:item.qty-1}).then(a=>{
                                                dispatch(actions.updateQty(e.target.dataset,+item.qty-1, register_id));
                                            });
                                        }
                                    }}
                                >
                                    <i className="far fa-minus" data-order_item_id={item.id} data-id={item.id} data-variant={item.variant_id} data-hash={item.hash} data-type={item.type} data-tip="Update Quantity"></i>
                                </Button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
}
import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';

export const SplitOrderConfirmation = (props) => {
  const {selectedUser, setPrint} = props;
  const splitPaymentStore = useSelector(state=>state.splitPayments);

  useEffect(()=>{
    if(splitPaymentStore && splitPaymentStore.cashSuccess===true && splitPaymentStore.creditCardSuccess===true && splitPaymentStore.submitting===false){
      setPrint(true);
    }
  },[splitPaymentStore,setPrint]);

  return (
    <>
    <div className="split-payment-confirmation">
      <h4>Thank you! This order has been completed!  </h4>
    </div>
    </>
  )
}

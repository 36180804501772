import React, { useState, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import { Spinner, Container, Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import ReactToPrint from 'react-to-print';
import ErrorCatcher from '../../../components/common/ErrorCatcher';
import Transactions from '../../../api/Transactions';

import "./PosReport.scss"

/**Needs params
 * @param props.registerId
 */
export const PosReports = (props) => {

    const mountedRef = useRef();
    const [error, setError]=useState();
    const [isDownloading, setIsDownlaoding]=useState(false);
    const [reportType, setReportType]=useState("cash");
    const [reportLink, setReportLink]=useState();
    const [date, setDate]=useState();
    const register_num = useSelector(state => state.pos.register)
    let startDate;
    let endDate;

    //First Load
    useEffect(()=>{
        mountedRef.current = true

        return()=>{
        mountedRef.current = false
        setIsDownlaoding(false)
        }
    },[]);

    /**Adds time to the dates (so that start of day is 0:0:00 and end of day is 23:59:59) and converts to ISO for db */
    const addTime=(date, h, m, s, ms)=>{
        let when = date;
        when.setHours(h,m,s,ms)
        return date.toISOString()
    }

    const getReport=async()=>{
        let response;
        setIsDownlaoding(true);
        setError();

        if(reportType ==="cash"){
        try{
            response = await Transactions.Reports.getCash({
                start_datetime: startDate,
                end_datetime: endDate,
                register_id: parseInt(register_num)
            })
        }catch(ex) {
            setError(<ErrorCatcher error={ex} />)
        }
        }
        if(reportType ==="sales"){
        try{
            response = await Transactions.Reports.getProducts({
                start_datetime: startDate,
                end_datetime: endDate,
            })
        }
        catch(ex){
            setError(<ErrorCatcher error={ex} />)
        }
        }
        if(mountedRef.current === true){
        if(response.data) setReportLink(response.data)
        if(response.errors) setError(<ErrorCatcher error={response.errors} />)
            setIsDownlaoding(false)
        }
    }

    const convertTime=()=>{
        if(date==="today"){
            endDate=addTime(new Date(), 23, 59,59,59);
            startDate= addTime(new Date(),0,0,0,0 );
        }
        if(date==="yesterday"){
        let yesterday= Date.now() + -1*24*3600*1000
        endDate=addTime(new Date(yesterday), 23,59,59,59)
        startDate=addTime(new Date(yesterday), 0,0,0,0)
        }
    }

    const reportFormSubmission=(e)=>{
        e.preventDefault()
        convertTime()
        getReport()
    }

    return (
        <Container className="pos-reports wizard">
            <Form onSubmit={reportFormSubmission}>
                <div className="today-yes-btns">
                    <span>
                        <Form.Label>Report Date</Form.Label>
                    </span>
                    <span>
                        <Form.Check 
                            type="radio"
                            id="report-date-yesterday"
                            name="report-date"
                            onChange={()=>setDate("yesterday")}
                            label="Yesterday"
                            value={"Yesterday"}
                            checked={date==="yesterday"}
                            className="form-radio"
                        />
                        <Form.Check 
                            type="radio"
                            id="report-date-today"
                            name="report-date"
                            onChange={()=>setDate("today")}
                            label="Today"
                            value={"Today"}
                            checked={date==="today"}
                            className="form-radio"
                        />
                    </span>
                </div>
                <div className="select-type">
                    <Form.Label>Report Type</Form.Label>
                    <Form.Control custom as="select" name="report_type" value={reportType} onChange={e=>setReportType(e.target.value)}>
                        <option value="cash">Download Register Cash</option>
                        <option value="sales">Download Product Sales</option>
                    </Form.Control>
                    <br />
                    <Button type="submit" variant="primary" className={`w-100 ${isDownloading?"loading":""}`} disabled={isDownloading}>Get Report</Button>
                    {reportLink && isDownloading===false &&
                        <Button type="button"variant="secondary" disabled={isDownloading} href={reportLink.uri} download className="rep-pos-a-tag w-100">
                            {reportLink.uri.includes("cash") && <span>Download Cash Report</span>}
                            {reportLink.uri.includes("product") && <span>Download Product Sales Report</span>}
                        </Button>
                    }
                </div>
            </Form>
        {/* {reportLink &&
            <iframe
            style={{width: "800px"}}
            src={reportLink}
            name="pos-report"
            />
        } */}
        {/* <ReactToPrint
            documentTitle="Report from POS"
            trigger={()=>
            <button>
                Print Copy
            </button>
            }
            content={()=>reportLink.uri}
        /> */}
        </Container>
    )
}


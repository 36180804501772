import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Container, Row, Col, InputGroup, Form, Card} from 'react-bootstrap';

import styles from '../../Tip/Tip.module.scss';

const paymentMethodName = "Manager Discount";

export const ManagerDiscount = (props) => {
    const {onPaymentUpdate, onPaymentMethodChange} = props;

    const ref = useRef();
    const firstLoad = useRef(true);
    
    const [managerDiscounts, setManagerDiscounts] = useState([]);
    const [discountData, setDiscountData] = useState({});
    const [error, setError] = useState();
    const [validated, setValidated] = useState(false);
    const [fields, setFields] = useState([
        {username: false},
        {password: false}, 
        {amount: false}
    ]);

    const validateFields=useCallback((field, status) => {
        setFields(prev=>{
            let _fields = [...prev];

            // load prefilled fields
            if (ref.current){
                _fields.forEach(f=>{
                    const _key = Object.keys(f)[0];
                    const _field = ref.current.querySelector(`input[name='${_key}']`);
                    if (_field){
                        f[_key] = !!_field.value;
                    }
                });
            }
    
            switch(field){
                case "username":
                case "password":
                case "amount":
                    const idx2 = _fields.findIndex(a=>Object.keys(a)[0]===field);
                    if (idx2>-1) _fields[idx2][field] = !!status; // status holds the value of the field, but we need to convert it to a boolean
                    break;
                default:
                    break;
            }

            // build the error message if the field is falsy
            const _error = {};
            _fields.forEach(f=>{
                const _key = Object.keys(f)[0];
                if (!f[_key]) _error[_key] = `${_key.charAt(0).toUpperCase()}${_key.slice(1)} is required`;
            });
            setError(_error);

            const is_valid=_fields.filter(f=>!f[Object.keys(f)[0]]).length===0;
            setValidated(is_valid);
            return _fields;    
        });
    },[]);

    const changeHandler = useCallback((e, field) => {
        e.preventDefault();
        setDiscountData({...discountData, [field]: e.target.value});
        validateFields(field, e.target.value);
    }, [discountData, validateFields]);

    const processManagerDiscount = useCallback(e => {
        if (onPaymentUpdate) {
            onPaymentUpdate(props.payment_method_id, [{
                payment_method_name: `${paymentMethodName}`,
                ...discountData,
            }]);
        }
    }, [onPaymentUpdate, props.payment_method_id, discountData]);

    useEffect(() => {
        if (validated){
            processManagerDiscount();
        }
    },[processManagerDiscount, validated, props.totals.estimated_balance]);


    useEffect(() => {
        if (props.payments && firstLoad.current){
            const payment = props.payments.filter(a=>a.payment_method_id === props.payment_method_id);
            if (payment.length > 0) setManagerDiscounts(payment.map((a, i)=>({id: i+1, ...a})));
        }
    }, [props.payments, props.payment_method_id]);

    useEffect(() => {
        if (firstLoad.current) {
            onPaymentMethodChange(props.payment_method_id);
        }
    }, [props.payment_method_id, onPaymentMethodChange]);

    useEffect(() => {

        if (ref.current) {
            ref.current.querySelector('input[name="username"]').focus();
        }

        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }

        return () => {
            setManagerDiscounts([]);
            setError(null);
        }
    }, []);

    return (
        <Container fluid data-cy="checkout-managerDiscount-payment">
            <Row>
                <Col sm={12} lg="6">
                    <Row ref={ref}>
                        <Col sm={12}>
                            <Form.Group controlId="username">
                                <Form.Label>Username</Form.Label>
                                <InputGroup>
                                    <Form.Control type="password" name="username" disabled={props.submitting} autoComplete="one-time-code" onChange={e => changeHandler(e, "username")} />
                                </InputGroup>
                                {error?.username && <div className="error-text mb-3">{error.username}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <InputGroup>
                                    <Form.Control type="password" name="password" disabled={props.submitting} autoComplete="one-time-code" onChange={e => changeHandler(e, "password")} />
                                </InputGroup>
                                {error?.password && <div className="error-text mb-3">{error.password}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm={12}>
                            <Form.Group controlId="amount">
                                <Form.Label>Discount Amount</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control 
                                        type="number" 
                                        min={0} 
                                        name="amount" 
                                        defaultValue={0} 
                                        disabled={props.submitting}
                                        onChange={e => changeHandler(e, "amount")}
                                    />
                                </InputGroup>
                                {error?.amount && <div className="error-text mb-3">{error.amount}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} lg="6">
                    {managerDiscounts?.map((discount, i) => (
                        <Card className={styles.wrapper} key={`managerdiscountc-${i}`}>
                            <Card.Body>
                                <div className={styles.title}>Transaction #{discount.transaction_id}</div>
                                <p>
                                    Amount: <span className="bold">${discount.amount.toFixed(2)}</span>
                                </p>
                            </Card.Body>
                        </Card>
                    ))}
                </Col>
            </Row>            
        </Container>
    );
}
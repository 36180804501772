import React from 'react';
import {Rectangle} from './Rectangle';
import {Circle} from './Circle';
import {Image} from './Image';

export const Shapes = (props) => {
	switch(props.type){
	  	case "circle":
			return <Circle 
				width={props.width} 
				    height={props.height} 
				    x={props.x} 
				    y={props.y} 
				    fill={props.fill}
                    opacity={props.opacity}
				    stroke={props.stroke}
				    strokeColor={props.strokeColor}
				    onSelect={props.onSelect}
				    onClick={props.onClick}
				    onMouseEnter={props.onMouseEnter}
				    onMouseLeave={props.onMouseLeave}
			    />
	    case "image":
		    return <Image
                width={props.width} 
                height={props.height}
                x={props.x} 
                y={props.y} 
                fill={props.fill}
                opacity={props.opacity}
                blob={props.blob}
                onSelect={props.onSelect}
                onClick={props.onClick}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
            />
	    case "rectangle":
        default:
		    return <Rectangle 
                width={props.width} 
                height={props.height} 
                x={props.x} 
                y={props.y}
                fill={props.fill}
                opacity={props.opacity}
                stroke={props.stroke}
                strokeColor={props.strokeColor}
                onSelect={props.onSelect}
                onClick={props.onClick}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
            />
	}
}
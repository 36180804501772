import Request from './Api';

const Statuses={
    getStatuses: async (props) => {
        return(
            Request({
                url: "/order/status",
                method: "GET",
                data: props
            })
        )
    },
    //despite the name of the endpoint, this will not just "log" but update the status if allowed by associated parent status
    logStatus: async (props) => { 
        return(
            Request({
                url: "/order/log_status",
                method: "POST",
                data: props
            })
        )
    }
}

const Order = {
    Statuses
}

export default Order;
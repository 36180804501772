export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REGISTRATION_REDIRECT = "AUTH_REGISTRATION_REDIRECT";
export const AUTH_FROM_SIGNUP = "AUTH_FROM_SIGNUP";
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_SET = 'AUTH_SET';

export const SET_ALL_FAMILY_NO_DUPES = "SET_ALL_FAMILY_NO_DUPES";
export const SET_ALL_FAMILY_MEMBERS = "SET_ALL_FAMILY_MEMBERS";
export const SET_ALL_FAMILY_GROUPS = "SET_ALL_FAMILY_GROUPS";
export const RESET_FAMILY ="RESET_FAMILY";

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const LOAD_TIMELINE = 'LOAD_TIMELINE';
export const SELECT_MAP_ROOT = 'SELECT_MAP_ROOT';
export const SELECT_MAP_ITEMS = 'SELECT_MAP_ITEMS';
export const DESELECT_MAP_ITEMS = 'DESELECT_MAP_ITEMS';
export const EVENT_WIZARD_STEP = 'EVENT_WIZARD_STEP';
export const EVENT_TYPE = 'EVENT_TYPE';
export const EVENT_ADD_ATTENDEE = 'EVENT_ADD_ATTENDEE';
export const EVENT_REMOVE_ATTENDEE = 'EVENT_REMOVE_ATTENDEE';
export const EVENT_SAVE_SPOT = 'EVENT_SAVE_SPOT';

export const SET_ADD_ONS = 'SET_ADD_ONS';
export const SET_ADD_ON = 'SET_ADD_ON';
export const ADD_ADD_ON = 'ADD_ADD_ON';
export const REMOVE_ADD_ON = 'REMOVE_ADD_ON';

export const POS_ADD_ITEM = 'POS_ADD_ITEM';
export const POS_ADD_ITEMS_MANY = 'POS_ADD_ITEMS_MANY';
export const POS_REMOVE_ITEM = 'POS_REMOVE_ITEM';
export const POS_UPDATE_QTY = 'POS_UPDATE_QTY';
export const POS_ACTIVE_ITEM = 'POS_ACTIVE_ITEM';
export const POS_ACTIVE_CATEGORY = 'POS_ACTIVE_CATEGORY';
export const POS_ACTIVE_TYPE = 'POS_ACTIVE_TYPE';
export const POS_ACTIVE_REGISTER = 'POS_ACTIVE_REGISTER';
export const POS_ACTIVE_TAB = 'POS_ACTIVE_TAB';
export const POS_ACTIVE_OPTION = 'POS_ACTIVE_OPTION';
export const POS_RESET = 'POS_RESET';
export const POS_RESET_ITEMS = 'POS_RESET_ITEMS';
export const POS_SET_USER = 'POS_SET_USER';
export const POS_ORDER = 'POS_ORDER';
export const POS_ORDER_ALL = 'POS_ORDER_ALL';
export const POS_ORDER_STATUS = 'POS_ORDER_STATUS';
export const POS_ORDER_IMPORTED = 'POS_ORDER_IMPORTED';
export const POS_TRANSACTION = 'POS_TRANSACTION';
export const POS_TRIGGER_PICKER = 'POS_TRIGGER_PICKER';
export const POS_COUPONS_SELECTED = 'POS_COUPONS_SELECTED';
export const POS_UPDATE_TRIGGER = 'POS_UPDATE_TRIGGER';
export const POS_COUPON_CODES = 'POS_COUPON_CODES';
export const POS_TIP = 'POS_TIP';
export const SET_PRE_CART_ITEM = 'SET_PRE_CART_ITEM';
export const RESET_PRE_CART_ITEMS = 'RESET_PRE_CART_ITEMS';
export const POS_SET_NEW_ITEMS = 'POS_SET_NEW_ITEMS';
export const POS_SET_CART_ITEMS = 'POS_SET_CART_ITEMS';
export const POS_SET_SHOW_EVENT_REGISTER = 'POS_SET_SHOW_EVENT_REGISTER';
export const POS_SET_CART_ANIMATION = 'POS_SET_CART_ANIMATION';
export const POS_SET_AVAILABLE_TOKENS = 'POS_SET_AVAILABLE_TOKENS';
export const POS_SET_REGISTER_INFO = 'POS_SET_REGISTER_INFO';
export const POS_SET_FINISHED_LOADING_ALL = 'POS_SET_FINISHED_LOADING_ALL';
export const POS_SET_FINISHED_LOADING_CUSTOMER = 'POS_SET_FINISHED_LOADING_CUSTOMER';
export const POS_INIT_REGISTER = 'POS_INIT_REGISTER';
export const POS_SET_UPDATED = 'POS_SET_UPDATED';
export const POS_SET_REGISTER_GROUP = "POS_SET_REGISTER_GROUP";

export const PRODUCT_ADD_VARIANT = 'PRODUCT_ADD_VARIANT';
export const PRODUCT_REMOVE_VARIANT = 'PRODUCT_REMOVE_VARIANT';
export const PRODUCT_REMOVE_VARIANT_CATEGORY = 'PRODUCT_REMOVE_VARIANT_CATEGORY';
export const PRODUCT_REMOVE_VARIANT_ADDON = 'PRODUCT_REMOVE_VARIANT_ADDON';
export const PRODUCT_SELECT_VARIANT = 'PRODUCT_SELECT_VARIANT';
export const PRODUCT_EDIT_VARIANT = 'PRODUCT_EDIT_VARIANT';

export const SET_NEW_EVENT_USER_ROLE = 'SET_NEW_EVENT_USER_ROLE';
export const SET_NEW_EVENT_USER_STATUS = 'SET_NEW_EVENT_USER_STATUS';

export const SET_COUPON = 'SET_COUPON';
export const SET_PARAMS = 'SET_PARAMS';
export const SET_PARAM_INIT_DATA = 'SET_PARAM_INIT_DATA';
export const SET_PARAM_MULTI_INPUT_DATA = 'SET_PARAM_MULTI_INPUT_DATA';
export const SET_CONDITIONS = 'SET_CONDITIONS';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_ADDITIONAL_ERRORS = 'SET_ADDITIONAL_ERRORS';
export const RESET_ERRORS = 'RESET_ERRORS';
export const UPDATE_EVENTS = 'UPDATE_EVENTS';
export const RESET_EVENTS = 'RESET_EVENTS';

export const EVENTWIZARD_SET_CURRENT_EVENT = 'EVENTWIZARD_SET_CURRENT_EVENT';
export const EVENTWIZARD_SET_CURRENT_EVENT_RECURRING = 'EVENTWIZARD_SET_CURRENT_EVENT_RECURRING';
export const EVENTWIZARD_SET_CURRENT_EVENT_ADD_CHILD = 'EVENTWIZARD_SET_CURRENT_EVENT_ADD_CHILD';
export const EVENTWIZARD_SET_MASTER_EVENT = 'EVENTWIZARD_SET_MASTER_EVENT';
export const EVENTWIZARD_SET_ERRORS = 'EVENTWIZARD_SET_ERRORS';
export const EVENTWIZARD_RESET_ERRORS = 'EVENTWIZARD_RESET_ERRORS';
export const EVENTWIZARD_SET_CURRENT_STEP = 'EVENTWIZARD_SET_CURRENT_STEP';
export const EVENTWIZARD_SET_LOAD_DATA = 'EVENTWIZARD_SET_LOAD_DATA';
export const EVENTWIZARD_SET_CURRENT_POINTER = 'EVENTWIZARD_SET_CURRENT_POINTER';
export const EVENTWIZARD_SET_CURRENT_POINTER_PARENT = 'EVENTWIZARD_SET_CURRENT_POINTER_PARENT';
export const EVENTWIZARD_SET_RESPONSE = 'EVENTWIZARD_SET_RESPONSE';
export const EVENTWIZARD_DELETE_BY_POINTER = 'EVENTWIZARD_DELETE_BY_POINTER';
export const EVENTWIZARD_ADD_CHILD_BY_POINTER = 'EVENTWIZARD_ADD_CHILD_BY_POINTER';

export const SERVICEWIZARD_SET_CURRENT_SERVICE = 'SERVICEWIZARD_SET_CURRENT_SERVICE';
export const SERVICEWIZARD_SET_ERRORS = 'SERVICEWIZARD_SET_ERRORS';
export const SERVICEWIZARD_RESET_ERRORS = 'SERVICEWIZARD_RESET_ERRORS';
export const SERVICEWIZARD_SET_TOKEN_DATA = 'SERVICEWIZARD_SET_TOKEN_DATA';
export const SERVICEWIZARD_SET_LOCATION_DATA = 'SERVICEWIZARD_SET_LOCATION_DATA';

export const SERVICEBOOKING_SET_CURRENT_BOOKING = 'SERVICEBOOKING_SET_CURRENT_BOOKING';
export const SERVICEBOOKING_RESET_CURRENT_BOOKING = 'SERVICEBOOKING_RESET_CURRENT_BOOKING';
export const SERVICEBOOKING_SET_ERRORS = 'SERVICEBOOKING_SET_ERRORS';
export const SERVICEBOOKING_RESET_ERRORS = 'SERVICEBOOKING_RESET_ERRORS';
export const SERVICEBOOKING_SET_SEARCH_PARAMS = 'SERVICEBOOKING_SET_SEARCH_PARAMS';
export const SERVICEBOOKING_SET_SEARCH_RESULTS = 'SERVICEBOOKING_SET_SEARCH_RESULTS';
export const SERVICEBOOKING_SET_SELECTED_SLOTS = 'SERVICEBOOKING_SET_SELECTED_SLOTS';

export const SET_SPLIT_PAYMENT_ERRORS = 'SET_SPLIT_PAYMENT_ERRORS';
export const SET_SPLIT_PAYMENT_CASH_SUCCESS = 'SET_SPLIT_PAYMENT_CASH_SUCCESS';
export const SET_SPLIT_PAYMENT_CC_SUCCESS = 'SET_SPLIT_PAYMENT_CC_SUCCESS';
export const SET_SPLIT_PAYMENT_SUBMITTING = 'SET_SPLIT_PAYMENT_SUBMITTING';
export const SET_SPLIT_PAYMENT_REQUIRES_USER = "SET_SPLIT_PAYMENT_REQUIRES_USER";
export const SET_SPLIT_PAYMENT_REMAINDER = 'SET_SPLIT_PAYMENT_REMAINDER';
export const SET_SPLIT_PAYMENT_CASH_VALUE = 'SET_SPLIT_PAYMENT_CASH_VALUE';
export const SET_SPLIT_PAYMENT_FORUSER = "SET_SPLIT_PAYMENT_FORUSER";
export const SET_SPLIT_PAYMENT_PAYEES = "SET_SPLIT_PAYMENT_PAYEES";
export const SET_SPLIT_PAYMENT_ALL_PAID = "SET_SPLIT_PAYMENT_ALL_PAID";
export const SET_SPLIT_PAYMENT_ALL_ITEMS= "SET_SPLIT_PAYMENT_ALL_ITEMS";
export const RESET_SPLIT_PAYMENT_STATE = 'RESET_SPLIT_PAYMENT_STATE';
export const SET_ORDERS_HAVE_BEEN_SPLIT = 'SET_ORDERS_HAVE_BEEN_SPLIT';
export const SET_SPLIT_PAYMENT_ALL_ORDERS = 'SET_SPLIT_PAYMENT_ALL_ORDERS';
export const SET_SPLIT_PAYMENT_SPLIT_TYPE = 'SET_SPLIT_PAYMENT_SPLIT_TYPE';
export const SET_SPLIT_PAYMENT_ORIGINAL_ITEMS="SET_SPLIT_PAYMENT_ORIGINAL_ITEMS"

export const PATRONCART_SET_MODIFIED_ORDER = 'PATRONCART_SET_MODIFIED_ORDER';
export const PATRONCART_SET_USER_DETAILS = 'PATRONCART_SET_USER_DETAILS';
export const PATRONCART_RESET = 'PATRONCART_RESET';

export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const SET_COMPANY_ID = 'SET_COMPANY_ID';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_COMPANY_CONFIG = 'SET_COMPANY_CONFIG';

export const CMS_CURRENT_WEBSITE = 'CMS_CURRENT_WEBSITE';
export const CMS_CURRENT_WEBSITE_THEME = 'CMS_CURRENT_WEBSITE_THEME';
export const CMS_CURRENT_WEBSITE_CSS = 'CMS_CURRENT_WEBSITE_CSS';
export const CMS_CURRENT_PAGE = 'CMS_CURRENT_PAGE';
export const CMS_CURRENT_PAGE_PROPS = 'CMS_CURRENT_PAGE_PROPS';
export const CMS_CURRENT_ELEMENT = 'CMS_CURRENT_ELEMENT';
export const CMS_ADD_ELEMENT = 'CMS_ELEMENTS';
export const CMS_REMOVE_ELEMENT = 'CMS_REMOVE_ELEMENT';
export const CMS_RESET = 'CMS_RESET';
export const CMS_DISPLAY_MODE = 'CMS_DISPLAY_MODE';
export const CMS_CURRENT_ELEMENT_INDEX = 'CMS_CURRENT_ELEMENT_INDEX';
export const CMS_BUILDER_ITEMS = 'CMS_BUILDER_ITEMS';

export const THEME_LOGO = 'THEME_LOGO';
export const THEME_FAVICON = 'THEME_FAVICON';
export const THEME_VARIABLES = 'THEME_VARIABLES';
export const THEME_CSS = 'THEME_CSS';

export const SET_AVAILABLE_FEATURES = 'SET_AVAILABLE_FEATURES';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { Card } from 'react-bootstrap';

import { getRegisterInfo } from '../../../utils/thunks';

export const Banner = ( props ) => {
	let dispatch = useDispatch();

    const registerInfo = useSelector(state => state.pos[props.register_id].registerInfo);

    useEffect(() => {
        dispatch(getRegisterInfo(props.register_id));
    },[dispatch, props.register_id]);

    if (!registerInfo) return <></>;

    return (
        <div className="description-banner">
            <h4>{registerInfo.name}</h4>
            {!!registerInfo.description && 
                <div>{registerInfo.description}</div>
            }
            {!!registerInfo.pickup_location && 
                <div><span className="title">Pickup Location:</span> {registerInfo.pickup_location}</div>
            }
            <hr/>
        </div>
    )
}
import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

import Products from '../../../../api/Products';
import * as actions from '../../../../store/actions';

import usePrevious from '../../../../components/common/CustomHooks'
import "./Categories.scss";

export const Categories = (props) => {
    const dispatch = useDispatch();

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [defaultCategories, setDefaultCategories] = useState([]);
    const [activeCategory,setActiveCategory] = useState(useSelector(state => state.pos[props.register_id].category));
    const oldActiveCategory = usePrevious(activeCategory);

	useEffect(() => {
        const _getCategories = async () => {
            if (props.category_id){
                let categories = props.category_id;
                if (!Array.isArray(props.category_id)) categories=[props.category_id];

                const res = await Products.Categories.get({id:categories});
                if (res.data?.[0]?.children){
                    res.data[0].children.forEach((_, i) => {
                        res.data[0].children[i].parent = true;
                    });
                    setCategories(res.data[0].children);
                    setDefaultCategories(res.data[0].children);
                }
            }
        }

        if (props.category_id) _getCategories();

        /*if (Array.isArray(props.category_id)){
            if (mounted){
                props.category_id.forEach(cat=>{
                    Products.Categories.get({id:cat}).then(response => {
                        console.log("data", response.data)
                        if (response.data){
                            //setCategories(prev=>[...prev,{...response.data[0],parent:true}])
                            if (response.data?.[0]?.children){
                                response.data[0].children.forEach(i=>setCategories(prev=>[...prev,{...i,parent:true}]));
                            }
                        }
                    }).catch(e => console.error(e));
                });
            }
        }*/

	}, [props.category_id]);

    useEffect(()=>{
        return ()=>{
            setCategories([]);
            setSubCategories([]);
            setDefaultCategories([]);
        }
    },[]);


    const categoryClickHandler = async (id) =>{
        dispatch(actions.activeCategory(id, props.register_id));
        setActiveCategory(id);
        if (!id){
            setCategories(defaultCategories);
            setSubCategories([]);
        } else if (id !==oldActiveCategory){
            let subcats=subCategories;
            if (categories.filter(a=>a.id===id)?.[0]?.parent) subcats=[];
            const response = await Products.Categories.get({id});
            if (response.data?.[0]?.children){
                subcats.push(...response.data[0].children.filter(child=>child.add_on_only===0))
            }
            setSubCategories(subcats);
        }
    }

    return (
        <>
            <div className="category-buttons">
                {categories && categories?.length>0 &&
                    <Button as="a" size="sm" variant={(!activeCategory || activeCategory===-1)?"primary":"secondary"} data-cy="btn-category-all" className="btn-category" onClick={()=>categoryClickHandler(0)}>
                        All Categories
                    </Button>
                }
                {categories && categories?.map((item,i)=> (
                    <Button as="a" size="sm" variant={+activeCategory===+item.id?"primary":"secondary"} data-cy="btn-category" className="btn-category" key={item.id+"-"+i} onClick={()=>categoryClickHandler(item.id)}>
                        {item.name}
                    </Button>
                ))}
            </div>
            {subCategories?.length>0 &&
                <div className="category-buttons subcategory">
                    <div className="subcategory-marker"><i className="far fa-angle-right"></i></div>
                    {subCategories?.map((item,i)=> (
                        <Button as="a" size="sm" variant={+activeCategory===+item.id?"primary":"secondary"} data-cy="btn-category-sub" className="btn-category" key={item.id+"-"+i} onClick={()=>categoryClickHandler(item.id)}>
                            {item.name}
                        </Button>
                    ))}
                </div>
            }
        </>
    );
}
import React, {useEffect, useState, useRef, useCallback} from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'

import { authCheck } from "../../../../utils/auth";
import Toast from '../../../../components/Toast'
import ErrorCatcher from '../../../../components/common/ErrorCatcher'
import '../Refund.scss'
import Pos from '../../../../api/Pos'
import { transaction } from '../../../../store/actions'

/** Confirms the amount, who, and transaction ID before sending to the API.
 * @param items props.items - those being refunded
 * @param int props.totalRefund
 * @param transaction (needs to include .user_fname (or user.first_name), .user_lname (or user.last_name), .id)
 * @param int props.registerId
 * @param string props.paymentMethod
 * @param bool props.refundAdmin
 * @param function props.setError
 * @param function props.setSuccess
 * @param function props.close
 */
export const ConfirmRefund = (props) => {

    const history = useHistory()
    let user = authCheck(history);

    const mountedRef = useRef()
    const itemAmount = props.items.length
    const [ disabled, setDisabled ]=useState(false);
    const [ usersName, setUsersName ]=useState("");
    const [ orderId, setOrderId ]=useState();
    const [ userId, setUserId ]=useState();
    const [ registerId, setRegisterId ]=useState();

    //setting for mount/unmount
    useEffect(()=>{
        mountedRef.current = true

        return()=>{
            mountedRef.current=false
            setDisabled(false)
        }
    },[])

    useEffect(()=>{
        const determineRegisterId=()=>{
            if(props.registerId && mountedRef.current) setRegisterId(parseInt(props.registerId))
            else setRegisterId(null)
        }

        const setProperties=()=>{
            if(mountedRef.current){
                setUsersName(`${props.fullOrder.user_fname} ${props.fullOrder.user_lname}`)
                setUserId(props.fullOrder.user_id)
                setOrderId(props.fullOrder.id)
            }
        }    

        if(props.totalRefund <=0 && mountedRef.current){
            setDisabled(true);
        }
        if(props.transaction){
            setProperties();
            determineRegisterId();
        }
    },[props]);

    const sendRefundCall=async()=>{
        if(props.amountRefund){
            handleAmountRefund()
        }
        else{
            let modifiedItems = modifyItems();
            try{
                let response=await Pos.addRefund({
                    order_id: orderId,
                    user_id: userId, 
                    register_id: parseInt(registerId),
                    admin_auth_id: user.profile.id,
                    admin_fee_refunded: props.refundAdmin,
                    items:modifiedItems,
                })
                if(!response.errors){
                    props.setSuccess(<Toast>"Refund has been applied successfully"</Toast>)
                }
                if(response.errors){
                    props.setError(<ErrorCatcher error={response.errors} />)
                }
            }
            catch(ex){
                console.error(ex)
                props.setError(<ErrorCatcher error={ex} />)
            }
        }
        props.handleConfirmClose()
        props.cleanUp()
    }

    /** Modifying the item to meet the endpoint needs */
    const modifyItems=()=>{
        let tempItems = [];
        for(let i = 0; i<props.items.length; i++){
            let temp={
                item_id: props.items[i].id,
                total_refund_amount: +props.items[i].total_refund_amount,
                shipping_refunded_amount: props.items[i].shipping,
                tax: props.items[i].tax,
                refund_memo: props.items[i].memo,
            }
            tempItems.push(temp)
        }
        return tempItems
    }

    const handleAmountRefund=async()=>{
        try{
            let response = await Pos.payment.refund({
                amount: props.totalRefund,
                transaction_id: props?.transactionId,
                payment_type: props.paymentMethod,
                register: props?.registerId,
            })
            if(response.status===200 && !response.errors){
                props.setSuccess(<Toast>"Refund has been applied successfully"</Toast>)
            }
            if(response.errors){
                props.setError(<ErrorCatcher error={response.errors} />)
            }
        }catch(ex){
            console.error(ex)
        }
        props.handleConfirmClose()
        props.cleanUp()
    }



    return (
        <div className="confirm-refund-card">
            {props && 
            <>
                <p>
                    {props.amountRefund ?
                        <span>
                            Are you sure you want to refund ${props.totalRefund.toFixed(2)} for {usersName}?
                        </span>
                    :
                        <span>
                            Are you sure you want to refund {itemAmount} item{itemAmount > 1 ? <span>s</span>:null} totaling ${props.totalRefund.toFixed(2)} for {usersName}?
                        </span>
                    }
                </p>
                <p>
                    Once this refund is complete, this transaction will be unable to process a refund on any other items. 
                </p>
                <p> 
                    Refunds can take up to 7 days to process in the bank's systems.     
                </p>
            </>
            }
            {disabled ? 
                <span className="ml-3 mr-3 ref-error-msg">"You have entered an invalid refund amount.  Please adjust the refund."</span> 
                : null
            }
            <div className="d-flex justify-content-center"> 
            <Button 
                disabled={disabled}
                className="confirm-refund-btns cr-btn "
                onClick={sendRefundCall}
            >
                Confirm Refund
            </Button>
        </div>
        </div>
    )
}

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton, { LoadingSkeleton, SkeletonTheme } from 'react-loading-skeleton';
import { Container, Modal, Button } from 'react-bootstrap';

import Table from '../../../../components/common/Table';
import UserCreateEdit from '../../../../components/UserCreateEdit';
import { useRoleCheck} from '../../../../components/common/CustomHooks';
import { UserGroups } from '../Groups/Groups';
import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import { setFamily } from '../../../../utils/thunks';

import Users from '../../../../api/Users';
import Groups from '../../../../api/Groups';

import '../../Subscriptions/Subscriptions.css';
import Stack from '../../../../components/common/Stack';

export const Family = (props) => {

    // this isn't helpful because it gives us the account role, not the user's role within the family
    // it does give the account role - so admin/staff can still be discerned from patron so they can have easy access to things like family edit as well
    let role = useRoleCheck();

    const mountedRef = useRef(false);
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);

    const [loading,setLoading]=useState(true);
    const [refresh, setRefresh] = useState(0);
    const [familyGroups,setFamilyGroups]=useState([]);
    const [invitedFamilyGroups, setInvitedFamilyGroups] = useState([]);
    const [familyGroupIds, setFamilyGroupIds]=useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [familyMemberDisplay, setFamilyMemberDisplay] = useState({});     // object containing any family member display info we want printed on the screen
    const [familyGroupRoles, setFamilyGroupRoles] = useState();
    const [error, setError] = useState();

    /**Getting the Ids of family(or families) */
    const getFamilyIds = useCallback(async() => {
        let familyGroup=[];
        if (mountedRef.current === true) setLoading(true);
        try{
            let response = await Users.groups({
                id: props.user_id
            });
            if(response.data && mountedRef.current === true) {
                response.data.forEach(group => {
                    if(group.group_type_id===4 && [1,2].includes(group.group_member_status_id)){    // family groups where the user has a status of confirmed or invited
                        familyGroup.push({
                            id: group.group_id,
                            role: group.group_member_role_id,
                            is_admin: group.is_admin,
                            group_member_status_id: group.group_member_status_id
                        });
                    }
                    return
                });
                setFamilyGroupIds(familyGroup);
            }
        }catch(ex){
            console.error(ex)
        }
        if(familyGroup.length===0) setLoading(false)
    },[props.user_id]);

    /**Taking the member role from the other call so it can be added to the family object */
    const addInfoToFamily = useCallback((tempGroup, familyId) => {
        let familyGroup = familyGroupIds.find(element => element.id === familyId);
        if (familyGroup) {
            tempGroup.role = familyGroup.role;
            tempGroup.is_admin = familyGroup.is_admin;
        }
        return tempGroup;
    },[familyGroupIds]);    

    /**Get family details such as members */
    const getFamilies = useCallback(() => {
        let listConfirmedGroups = [];
        let listInvitedGroups = [];
        familyGroupIds.forEach(async (family) => {
            try {
                let response=await Groups.get({id:family.id});
                let tempGroup = response.data[0]; //to temp for modification
                let tempMembers = tempGroup.group_members.filter(member => member.group_member_status_id===2);
                if (!familyGroupRoles) setFamilyGroupRoles(tempGroup.group_member_roles);
                tempGroup.group_members = tempMembers;
                addGroupId(tempGroup.group_members, family.id); //add group Id to each member
                tempGroup = addInfoToFamily(tempGroup, family.id); //Add current user's role to family object
                if (family.group_member_status_id===2) {
                    setFamilyGroups(prev => [...prev, tempGroup]);
                }
                setLoading(false);
            } catch(ex){
                console.error(ex);
                setLoading(false);
            }
        })
    },[familyGroupIds, familyGroupRoles, addInfoToFamily]);

    // first load, get family members from api
    useEffect(() => {
        mountedRef.current = true;

        if(props.user_id) getFamilyIds();

        // cancel stuff when component unmounts
        return () => {
            mountedRef.current = false;
            setFamilyGroups([]);
            setLoading(false);
            setModalContent();
            setShowModal(false);
        }        
    },[props.user_id, refresh, getFamilyIds]);

    useEffect(()=>{
        if (familyGroupIds?.length>0 && familyGroups?.length===0){
            getFamilies();
        }
    }, [familyGroupIds,familyGroups,getFamilies]);

    /** Function for updating redux if the logged in user is the one making changes to families */
    const updateReduxFamily=useCallback(()=>{
        if(user?.id === props?.user_id){
            dispatch(setFamily())
        }
    },[dispatch, user?.id, props?.user_id]);

    const refreshPage = useCallback(() => {
        setRefresh(prev => prev+1);
    },[]);

    const modalSubmitHandler = useCallback((e) => {
        setShowModal(false);
        refreshPage();
    },[refreshPage]);

    const modalCloseHandler = (e) => {
        setShowModal(false);
    }

    const createEditUserHandler = useCallback((group_id, user_id=null, role_id=null) => {
        if (familyGroupRoles) {
            setModalContent(
                <UserCreateEdit
                    showCloseBtn={true}
                    onClose={modalCloseHandler}
                    afterSubmit={modalSubmitHandler}
                    user_id={user_id}
                    group_id={group_id}
                    role_id={role_id}
                    groupRoles={familyGroupRoles}
                />);
            setShowModal(true);
        }
    },[familyGroupRoles,modalSubmitHandler]);

    const createFamilyGroupHandler = useCallback(() => {
        // send call to create group endpoint
        Groups.create({
            name: (props.user?.last_name || "") + ' Family',
            description: 'Family Group',
            group_type_id: 4, // family
            group_status_id: 1, // active   
            group_member_role_id: 10, // Parent
            user_id: props.user_id
        }).then(response => {
            if (response.errors) {
                setError(<ErrorCatcher error={response.errors[0]} />)
            } else if (response.data) {
                updateReduxFamily()
                refreshPage();
            }
        }).catch(error => {
            console.error(error);
        });
    },[props.user_id, props.user, refreshPage]);


    const columns = React.useMemo(
        () => [{
            id: 'table',
            columns: [
                {
                    Header: 'Full Name',
                    id: 'full_name',
                    className: "align-middle",
                    accessor: d => (
                        <div>
                            {d.first_name} {d.last_name}
                        </div>
                    ),
                },
                {
                    Header: 'Relationship',
                    id: 'group_member_role',
                    className: "align-middle",
                    accessor: "group_member_role_name"
                },
            ],
        }],[]
    );

    const adminColumns = React.useMemo(
        () => [{
            id: 'table',
            columns: [
                {
                    Header: 'Full Name',
                    id: 'full_name',
                    className: "align-middle",
                    accessor: familyMember => (
                        <div>
                            {familyMember.first_name} {familyMember.last_name}
                        </div>
                    ),
                },
                {
                    Header: 'Relationship',
                    id: 'group_member_role',
                    className: "align-middle",
                    accessor: "group_member_role_name"
                },
                {
                    Header: '',
                    id: 'id',
                    accessor: familyMember => (
                        <i 
                            style={{fontSize: "1rem"}}
                            className="far fa-edit cp" 
                            onClick={() => createEditUserHandler(familyMember.group_id, familyMember.user_id, familyMember.group_member_role_id) }    
                        />
                    )
                }
            ],
        }],[createEditUserHandler]
    );
 
    // Yes we are packaging up what we want to display for each group and sending it off to Groups.js
    // This should probably be re-written later breaking down to different components - at the same time we remove the <Table />
    useEffect(() => {
        let groupMemberList = {};
        familyGroups?.forEach(family => {
            groupMemberList[family.id] = (
                <div className="mt-1" key={`family-members-${family.id}`} data-cy="family-card">
                    <Table 
                        searchBar={false}
                        columns={(role.id<6 || family.is_admin) ? adminColumns : columns} 
                        data={family.group_members} 
                        {...props}
                    />
                    {(family.role !== 11 || role.id < 6) &&
                        <Button className="btn-primary m-0 mt-2" onClick={() => createEditUserHandler(family.id)}>
                            <i className="far fa-plus-circle"></i>
                            Add Family Member
                        </Button>
                    }
                </div>
            );
        });
        setFamilyMemberDisplay(groupMemberList);
    }, [familyGroups, props, role, adminColumns, columns, createEditUserHandler]);



    /**Adds group id to each member */
    const addGroupId=(members, id)=>{
        members.forEach((member)=>{
            member.group_id = id
        })
    }

    return (
        <Container fluid data-cy="profile-family-group" className="profile-family">
            <Modal show={showModal} onHide={modalCloseHandler} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Family Member
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalContent}
                </Modal.Body>
            </Modal>

            {error}

                <h4 className="section-title order-2 order-lg-1" data-cy="profile-section-title">Family</h4>
                <hr />
                <div className="ms-sm-auto d-flex justify-content-end order-1 order-lg-2">
                    {!familyGroups.length && !invitedFamilyGroups.length &&
                        <Button onClick={createFamilyGroupHandler} className="btn-primary ms-sm-auto">
                            Create Family Group
                        </Button>
                    }
                </div>

            {loading &&
                <SkeletonTheme color="#e0e0e0">
                    <div className="mt-3" data-cy="loading-skeleton-div">
                        <Skeleton height={30} count={4} />
                    </div>
                </SkeletonTheme>
            }

            {!loading &&
                <>
                    {!familyGroups.length && !invitedFamilyGroups.length &&
                        <div className="mt-3">
                            <p className="text-center" data-cy="profile-family-none">No Family Group</p>
                        </div>
                    }

                    {!!familyGroups.length &&
                        <UserGroups
                            user={props.user}
                            user_id={props.user_id}
                            currentUserRoleId={props.currentUserRoleId}
                            familyOnly={true}
                            groupMembersDisplay={familyMemberDisplay}
                            groupMembersRefresh={refreshPage}
                        />
                    }

                </>
            }
        </Container>
    );
}
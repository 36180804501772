import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns'

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ErrorCatcher from '../../../components/common/ErrorCatcher/ErrorCatcher';
import { DobDatePicker } from '../../../components/DatePicker';

import Users from '../../../api/Users';
import * as actions from '../../../store/actions';
import { FileURLContext } from '../../../contexts/FileURLContext';
import { loadCompanyConfig } from '../../../utils/thunks';

import '../Login/Login.scss';

const Register = (props) => { 
    const imageURL = useContext(FileURLContext);
    const logged=props.logged;

    let history = useHistory();
    let dispatch = useDispatch(); 

    const companyConfig = useSelector(state => state.company.config);
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [dobValue, setDobValue] = useState();
    const [passOne, setPassOne] = useState();
    const [passTwo, setPassTwo] = useState();
    const [infoObj, setInfoObj] = useState();
    const [email, setEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [success, setSuccess] = useState(false);
    const [code, setCode] = useState();
    const [loading, setLoading]=useState(true);

    
    //On first load, tell Redux that user is at registration page for possible future redirect
    useEffect(()=>{
        dispatch(actions.fromSignUp({from_signup: true}))
    },[dispatch]);

    useEffect(()=>{
        let mounted = true

        if(!companyConfig) dispatch(loadCompanyConfig());


        return()=>{
            mounted = false
        }
    },[dispatch, companyConfig]);

    const appendInfo = (key, val) => {
        setInfoObj(prevState => prevState.append(key, val));
    }

    // get props from base state
    useEffect(() => {
        setError();
        if (props.logged_in) history.push("/p/");
        if (props.error) setError(<ErrorCatcher>{props.error}</ErrorCatcher>);
        setSubmitting(props.loading);
        if (props.authcode) setSuccess(true);
        setCode(new URLSearchParams(window.location.search).get("code"));
    }, [props.error, props.loading, props.logged_in, history, props.authcode]);

    // form submission
    const submitHandler = async (e) => {
        const form = e.currentTarget;

        e.preventDefault();

        setSubmitting(true);
        setError();

        //octal codes on ASCII table in order as below @ : ; < = > ? {space} ! " # $ % & ' ( ) * + , / [ \ ] ^ ` { | } ~
        //Any of these symbols present in the username will throw an error
        const usernameTestRegex=/[\100\72-\77\40-\54\57\133-\136\140\173-\176]/.test(form.username.value); 

        //regex for email validation
        //email address must match this in order to be valid - a false will throw an error
        const emailTestRegex=/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/gi.test(form.email.value);

        if(usernameTestRegex) {
            setError(<ErrorCatcher warning={true} title="Hey!" error='Sorry, you cannot use special symbols in user names.' />);
            setSubmitting(false);
            return;
        }
        if(!emailTestRegex) {
            setError(<ErrorCatcher warning={true} title="Hey!" error='Email must be an valid email address.' />);
            setSubmitting(false);
            return;
        }
        else if (passOne && passTwo && passOne!==passTwo){
            setError(<ErrorCatcher warning={true} title="Hey!" error="Those passwords don't match" />);
            setSubmitting(false);
            return;
        }
        else setValidated(true);

        if (form.checkValidity()) {

            const formData = new FormData(e.target);
            if(companyConfig?.requires_dob){
                formData.append(
                    "dob", format(dobValue, "yyyy-MM-dd")
                );
            }

            //make sure the username && email address is unique, too, before sending it off
            let userNameUnique = await checkUsername(form.username.value);
            let emailUnique = await checkEmail(form.email.value);
            if(userNameUnique && emailUnique) setInfoObj(formData); //pass on to submit waiver handler

            setSubmitting(false);
            setValidated(false);
            // submitWaiverHandler(e);
            
        } else setSubmitting(false);
    };

    const checkUsername=async(userName)=>{
        try{
            let response = await Users.checkUsername({username: userName});
            if(response.data[0].available === true) return true;
            else if (response.data[0].available === false){
                setError(<ErrorCatcher warning={true} title="Hey!" error="That username already taken.  Please try another" />);
                return false;
            } 
        }catch(ex){
            console.error(ex);
        }
    }

    const checkEmail=async(email)=>{
        try{
            let response = await Users.checkEmail({email: email});
            if (response.data[0].available === true) return true;
            else if (response.data[0].available === false){
                setError(<ErrorCatcher warning={true} title="Hey!" error="An account already exists with that email. You can create accounts for family members with the same email address by logging in and looking under 'My Profile > Family Members'. If you have forgotten your password please use the Forgot your Password link." />);
                return false;
            } 
        }catch(ex){
            console.error(ex);
        }
    }

        // verification code
        const VerifyHandler = async (e) => {
            const form = e.currentTarget;

            e.preventDefault();
            e.stopPropagation();

            setSubmitting(true);
            setError();

            if (form.checkValidity() === true) {
                const formData = new FormData(e.target);
                const formDataObj = Object.fromEntries(formData.entries());

                await Users.verify({formDataObj})
                .then( response => {    
                    if (!response.errors) { // successful
                        
                        // automatically log the user with the returned token
                        Users.get({id:response.data})
                        .then((user)=>{
                            if (user.data.profile) {
                                localStorage.setItem('user',JSON.stringify(user.data));
                                logged({logged_in:true,...user.data});
                                history.push("/p/");
                            } else history.push("/p/");
                        }).catch((error)=>{
                            setError(<ErrorCatcher>{error.message}</ErrorCatcher>);
                            setSubmitting(false);
                        });

                    } else {
                        setSubmitting(false);
                        setError(<ErrorCatcher error={response.errors} />);
                    } 
                }).catch( e => {
                    setSubmitting(false);
                    setError(<ErrorCatcher error={e} />);
                });
            } else setSubmitting(false);
        };


    useEffect(()=>{
        const submitWaiverHandler = async () => {

            setValidated(true);
            setSubmitting(true);
    
            setError();
    
            const formDataObj = Object.fromEntries(infoObj);
    
            await Users.create(formDataObj)
            .then( response =>{    
                if (!response.errors) { // successful registration
                    setValidated(false);
                    setSubmitting(false);
                    setSuccess(true);
                } else {
                    setSubmitting(false);
                    setError(<ErrorCatcher error={response.errors} />);
                }
            }).catch( e => {
                setSubmitting(false);
                setError(<ErrorCatcher error={e} />);
            });
            
        }
    
        if(infoObj) submitWaiverHandler();
    },[infoObj]);

    if (props.logged_in) history.push("/p/home");

    // show the form
    return (
        <div className="login-register">
            {error}
            {imageURL.backgroundImage?.endsWith(".mp4") &&
                <video autoPlay muted loop id="bgVideo">
                    <source type="video/mp4" src={imageURL.backgroundImage} />
                </video>
            }
            <section className="banner-wrapp login-banner" style={!imageURL.backgroundImage?.endsWith(".mp4") ? {backgroundImage:`url(${imageURL.backgroundImage})`} : null}>
            <div className="l-con-wrap container">
            <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                <div className="banner-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="hid-mob">
                                    <h4 className="dash-title-login">{imageURL.companyName}</h4>
                                    <h1 className="portal-he">Portal SignUp</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                <div className="auth-form-wrap">
                <div className="logo"></div>

                {success &&
                    <React.Fragment>
                        {/* Uncomment when registration auth codes are added */}
                        {/* <h1>Almost done!</h1>
                        <p>
                            { email ?
                            "We just sent an email to " + email + " with an account activation link. Please click it or enter the validation code below."
                            : "Please enter the registration code that was sent to your email." }
                        </p>
                        <Form inline className="my-5" noValidate validated={validated} onSubmit={VerifyHandler}>
                            <Form.Label htmlFor="code">
                                Code
                            </Form.Label>
                            <Form.Control required type="text" className="mx-2" name="code" id="code" placeholder="Enter code here" value={code} />
                            <Button type="submit" disabled={submitting} className={`${submitting?" submitting":""}`}>Send</Button>
                        </Form>
                        <p>Already have an active account? <Link to="/p/">Sign in here</Link></p> */}
                        <h1>Thank you!</h1>
                        <p>
                            You've successfully signed up with {imageURL.companyName}. You should receive a welcome email{email ? " at " + email + " " : " "}shortly.
                        </p>
                        <Button variant="primary" onClick={() => history.push("")}>Back to Login</Button>
                    </React.Fragment>
                }
                {!success &&
                    <>
                    <Form noValidate validated={validated} onSubmit={submitHandler}>
                        <div className="text-align-sign">Already have an account? <Link to="/p/">Sign in here</Link></div>
                        <h4 className="text-align-sign register-title">Sign Up for An Account</h4>
                        <div className="text-align-sign">Enter parent/guardian information here. You can add child accounts later.</div>
                        <br />
                        <Row>
                            <Col lg="4">
                            <Form.Row>
                                <Form.Group controlId="first_name" className="ani-input" >
                                    <Form.Control required type="text" name="first_name" placeholder="First Name" />
                                </Form.Group>
                            </Form.Row>
                            </Col>
                            <Col lg="4">
                            <Form.Row>
                                <Form.Group controlId="middle_name" className="ani-input" >
                                    <Form.Control type="text" name="middle_name" placeholder="Middle Name" />
                                </Form.Group>
                            </Form.Row>
                            </Col>
                            <Col lg="4">
                            <Form.Row>
                                <Form.Group controlId="last_name" className="ani-input" >
                                    <Form.Control required type="text" name="last_name" placeholder="Last Name" />
                                </Form.Group>
                            </Form.Row>
                            </Col>
                            <Col lg="6">
                            <Form.Row>
                                <Form.Group controlId="email" className="ani-input" >
                                    <Form.Control required type="email" name="email" placeholder="Email" 
                                        onChange={e => {setEmail(e.target.value)}}
                                    />
                                </Form.Group>
                            </Form.Row>
                            </Col>
                            <Col lg="6">
                            <Form.Row>
                                <Form.Group controlId="mobile_phone" className="ani-input" >
                                    <Form.Control type="tel" name="mobile_phone" placeholder="Mobile Phone" />
                                </Form.Group>
                            </Form.Row>
                            </Col>
                                <Col lg="6">
                                    <Form.Row>
                                        <Form.Group controlId="dob" className="ani-input" >
                                            <DobDatePicker
                                                selected={dobValue}
                                                onChange={date => setDobValue(date)}
                                                disabled={submitting}
                                                placeholderText="Date of Birth"
                                                required={(companyConfig?.requires_dob===true || companyConfig?.requires_dob==="true")}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Col>
                            <Col lg="6"></Col>
                            <Col lg="4">
                            <Form.Row>
                                <Form.Group controlId="username" className="ani-input">
                                    <Form.Control required type="text" name="username" placeholder="User Name" onChange={(e)=>setUserName(e.target.value)} autoComplete="one-time-code"/>
                                </Form.Group>
                            </Form.Row>
                            </Col>
                            <Col lg="4">
                            <Form.Row>
                                <Form.Group controlId="password" className="ani-input">
                                    <Form.Control required type="password" name="password" placeholder="Password"  onChange={(e)=>setPassOne(e.target.value)} autoComplete="one-time-code" />
                                </Form.Group>
                            </Form.Row>
                            </Col>
                            <Col lg="4">
                            <Form.Row>
                                <Form.Group controlId="password_confirmation" className="ani-input" >
                                    <Form.Control required type="password" name="password_confirmation" placeholder="Confirm Password"  onChange={(e)=>setPassTwo(e.target.value)} autoComplete="one-time-code" />
                                </Form.Group>
                            </Form.Row>
                            </Col>
                        </Row>
                        <div className="text-align-sign">
                            <Button variant="primary" type="submit" disabled={submitting} className={`${submitting?" submitting":""}`}>Sign Up</Button>
                        </div>
                    </Form>
                    </>
                }
                </div>
            </div>
            </div>
            </div>
            </section>
        </div>
    );
 
}

export default Register;
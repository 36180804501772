import * as actionTypes from './actionTypes';

export const setCurrentEventWizard = (event) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_CURRENT_EVENT,
        event: event
    };
};

export const setCurrentEventRecurring = (recurring) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_CURRENT_EVENT_RECURRING,
        recurring: recurring
    };
};

export const setCurrentEventAddChild = (event) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_CURRENT_EVENT_ADD_CHILD,
        event: event
    };
};

export const setMasterEventWizard = (event) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_MASTER_EVENT,
        event: event
    };
};

export const setEventWizardErrors = (errors) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_ERRORS,
        errors: errors
    };
};

export const resetEventWizardErrors = (errors) => {
    return {
        type: actionTypes.EVENTWIZARD_RESET_ERRORS,
        errors: errors
    };
};

export const setEventWizardCurrentStep = (step) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_CURRENT_STEP,
        step: step
    };
};

export const setEventWizardLoadData = (data) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_LOAD_DATA,
        data: data
    };
};

export const setEventWizardPointer = (current_pointer) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_CURRENT_POINTER,
        current_pointer: current_pointer
    };
};

export const setEventWizardPointerParent = (levels) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_CURRENT_POINTER_PARENT,
        levels: levels
    };
};

export const setEventWizardResponse = (response_object) => {
    return {
        type: actionTypes.EVENTWIZARD_SET_RESPONSE,
        response_object: response_object
    };
};

export const deleteEventWizardChild = (pointer) => {
    return {
        type: actionTypes.EVENTWIZARD_DELETE_BY_POINTER,
        pointer: pointer
    };
};

export const addEventWizardChild = (pointer, obj) => {
    return {
        type: actionTypes.EVENTWIZARD_ADD_CHILD_BY_POINTER,
        data: {pointer: pointer, obj: obj}
    };
};
// take a user_id and generate a url that we will use across sites for QR code generation
export function generateUserQRCode (user_id, url=window.location.href) {
    return url.split('/')[2] + '/' + user_id;
}

// given a whole url, return the base company url
export const getBaseCompanyOfUrl = (url) => {
    // normal domains will be split into 3 or 4 parts, localhost will be split into 2 parts
    let splitData = url.split('/');
    let fullDomain = splitData.length>=3 ? splitData[2] : splitData[0];
    if (fullDomain.slice(0, fullDomain.indexOf(':'))==='localhost') {
        return 'localhost';
    }
    let splitDomain = fullDomain.split('.');
    // if the domain is just 1 part, then return that part, otherwise return the last 2 parts (ex: impactathleticsny.com)
    let baseDomain = splitDomain.length===1 ? splitDomain : splitDomain.slice(splitDomain.length-2, splitDomain.length);
    return baseDomain.join('.');
}
import React, { useState } from 'react';
import { Nav, Spinner } from 'react-bootstrap';
import { Families } from './Families';
import { Groups } from './Groups';
import './Tabs.scss';

export const Tabs = (props) => {
    const defaultHash = `#${props.includes?.[props.default - 1].hash}`;
    const [selectedTab, setSelectedTab] = useState(props.default === 2 ? <Groups key="groups" data={props.includes[1].data} /> : <Families key="families" data={props.includes[0].data} />);

    // load content based on the tab selected
    const tabClickHandler = (e) =>{
        switch(e.target.hash){
            case "#groups":
                setSelectedTab(<Groups key="groups" data={props.includes[1].data} />);
                break;
            default:
            case "#families":
                setSelectedTab(<Families key="families" data={props.includes[0].data} />);
                break;
        }        
    }

    return (
        <React.Fragment>
            <div>
                { defaultHash ?
                    <Nav defaultActiveKey={defaultHash} as="ul" data-cy="home-group-tabs" className="tabs">
                        {props.includes.map((item,i)=> (
                            <Nav.Item key={item.hash+"-"+i} as="li">
                                <Nav.Link href={`#${item.hash}`} onClick={ e => tabClickHandler(e) }>
                                    {item.text}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                : <Spinner animation="border" variant="secondary" />
                }
            </div>
            <div className="fam-group-tab-wrapper">
                {selectedTab}
            </div>
        </React.Fragment>
    );
}
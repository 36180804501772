import React, {useState} from 'react';
import {Accordion, Container, Row, Col, Form, InputGroup} from 'react-bootstrap';

import ItemRefund from './ItemRefund';
import Tooltip from '../../../../components/common/Tooltip';

export const AdvancedRefund = (props) => {
    const {handleCustomAdmin} = props;

    const [customAdminFee, setCustomAdminFee] = useState(props?.customAdminFee || 0);

    const customAdminFeeChangeHandler = (e) => {
        let num = +e.target.value || 0;
        if (+num > +props?.adminFee) {
            num = +props.adminFee;
            e.target.value = num.toFixed(2);
        }
        setCustomAdminFee(num);
        handleCustomAdmin(e);
    }

    return (
        <Container fluid>
            <h5 className="title">
                Maximum Allowed Refund: ${(props?.shippingTotal + props?.maxTotal + props?.adminFee).toFixed(2)}                    
                <Tooltip direction="bottom" text="This is the highest amount that can be refunded on this transaction.">
                    <i className="far fa-question-circle ms-1"/>
                </Tooltip>
            </h5>
            {props?.adminFee > 0 &&
                <>
                    <hr />
                    <Row>
                        <Col sm={12} lg={3}>
                            <Form.Group controlId="admin-fee-custom">
                                <Form.Label>
                                    Admin Fee Refund Amount
                                    <Tooltip direction="bottom" text="Admin fee is the fee associated with credit card transactions.  Should be included in a full refund.">
                                        <i className="far fa-question-circle ms-1"/>
                                    </Tooltip>
                                </Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control 
                                        type="number" 
                                        name="admin-fee-custom" 
                                        min="0"
                                        max={props?.adminFee}
                                        disabled={props?.adminFeeChecked === true}
                                        value={customAdminFee} 
                                        onChange={customAdminFeeChangeHandler}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Check 
                                className="ps-0 form-switch"
                                id="admin-check"
                                name="admin-check"
                                type="checkbox" 
                                label={`Refund Full Admin Fee: $${props?.adminFee.toString().includes(".") ? `${props?.adminFee.toFixed(2)}` : `${props?.adminFee}.00`}`}
                                onChange={e => {
                                    customAdminFeeChangeHandler({target: {value: e.target.checked ? props?.adminFee.toFixed(2) : 0}});
                                }}
                                checked={props?.adminFeeChecked}
                            />
                        </Col>
                    </Row>
                </>
            }
            <hr />
            <Row>
                <Col sm={12}>
                    <InputGroup className="align-items-center">
                        <Form.Check 
                            id="refund-eveything"
                            name="refund-eveything"
                            type="checkbox" 
                            className="form-switch mt-0"
                            label={`Refund All Items (Full Price, Tax, and Shipping)`}
                            value={props?.fullCheck}
                            onChange={()=>props?.setFullCheck(!props?.fullCheck)}
                        />
                        <InputGroup.Append>
                            <Tooltip direction="right" text="If this option is selected, everything else will become unselectable and the maximum refund will be issued for all selected items.  If you wish to include a memo, do so before selecting this option">
                                <i className="far fa-question-circle ms-1" />
                            </Tooltip>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            {/*
            <Row>
                <Col sm={12}>
                    <span className="bold">Items Selected For Refund 
                        <Tooltip direction="right" text="Each item includes the maximum amount you can refund based on purchase price (including tax).  Items have checks to help with calculations if need be.">
                            <i className="far fa-question-circle ms-1" />
                        </Tooltip>
                    </span>
                </Col>
            </Row>
            */}
            <Row>
                <Col sm={12}>
                    {props?.items?.map((item, i)=>(
                        <React.Fragment key={`refund-items-${i}`}>
                            <Accordion>
                                <ItemRefund 
                                    key={`refund-item-${item.id}`} 
                                    fullCheck={props?.fullCheck}
                                    item={item} 
                                    addRefundItem={props?.addRefundItem}
                                    i={i+1}
                                    resetConfirm={props?.setConfirm}
                                    disableRefund={props?.setInvalidTotal}
                                />
                            </Accordion>
                        </React.Fragment>
                    ))}
                </Col>
            </Row>
            <div className="refund-grid-row">
                <h6>
                    <label className="refund-label" htmlFor="payment-type">Payment Method</label>
                    <Tooltip direction="right" text="Refund method will be the same as the original transaction.">
                        <i className="far fa-question-circle" />
                    </Tooltip>
                </h6>
                <h6>
                    <span className="refund-input-dd bold" name="payment-type">{props?.transaction?.payment_method}</span>
                </h6>
            </div>
            <div className="refund-grid-row">
                <h6>
                    <label className="refund-label" htmlFor="refundRegister">Select a Refund Register</label>
                    <Tooltip direction="right" text="Select the register the refund is occuring at.">
                        <i className="far fa-question-circle" />
                    </Tooltip>
                </h6>
                <h6>
                    <select 
                        className="refund-input-dd" 
                        name="refundRegister"
                        onChange={(e)=>{props?.setSelectedRegister(e.target.value)}}
                        value={props?.selectedRegister}
                        required
                        >
                        <option value="">No Register</option>
                        {props?.registers?.map((register, i)=>(
                            <option key={`dd-reg-[${i}]${register.id}`} value={register.id}>{register.name}</option>
                        ))}
                    </select>
                </h6>
            </div>
        </Container>
    );
}
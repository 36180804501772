import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import {Container, Card, Accordion, Row, Col, Form, InputGroup } from 'react-bootstrap';

import Stack from '../../../../../../components/common/Stack';
import styles from '../../Wizard.module.scss';

export const Item = (props) => {
    const {itemUpdate} = props;

    const memoRef = useRef();
    
    const [accordionToggle, setAccordionToggle] = useState(false);
    const [totalRefund, setTotalRefund] = useState(0);
    const [refundAmount, setRefundAmount] = useState();
    const [checked, setChecked] = useState({
        selected: true,
        full: !props?.data?.refund_type ? true : props?.data?.refund_type === "full amount",
        half: props?.data?.refund_type === "half amount" || false,
        shipping: props?.data?.refund_type === "shipping" || false,
        tax: props?.data?.refund_type === "tax" || false,
        custom: props?.data?.refund_type === "custom amount" || false,
        return: props?.data?.refund_type === "return" || true,
    });

    const priceTotal = useMemo(() => +props?.item?.price || 0, [props?.item?.price]);
    const taxTotal = useMemo(() => +props?.item?.tax || 0, [props?.item?.tax]);
    const shippingTotal = useMemo(() => +props?.item?.shipping || 0, [props?.item?.shipping]);
    const priceAdjustments = useMemo(() => {
        if (!props?.item?.price_adjustments) return 0;
        return props?.item?.price_adjustments.reduce((acc, a) => {
            return acc + +a.amount;
        }, 0);
    }, [props?.item?.price_adjustments]);

    const getTotalRefund = useCallback(()=>{
        let _refund = 0;
        if (checked.full) _refund = +priceTotal + +taxTotal + +shippingTotal + +priceAdjustments;
        else if (checked.half) _refund = (+priceTotal + +taxTotal + +shippingTotal + +priceAdjustments)/2;
        else if (checked.shipping) _refund = +shippingTotal;
        else if (checked.tax) _refund = +taxTotal;
        else if (checked.custom) _refund = +refundAmount;

        setTotalRefund((+_refund || 0).toFixed(2));
        itemUpdate({
            item: props.item, 
            checked: checked, 
            refund: _refund,
            return: checked.return, 
            shipping: checked.shipping || checked.full ? shippingTotal : (checked.half ? shippingTotal/2 : 0),
            tax: checked.tax || checked.full ? taxTotal : (checked.half ? taxTotal/2 : 0),
            price: checked.full ? priceTotal : (checked.half ? priceTotal/2 : 0),
            memo: memoRef?.current?.value || ""
        });
    },[priceTotal, taxTotal, shippingTotal, checked, refundAmount, itemUpdate, priceAdjustments, props.item]);

    const toggleCheckHandler = useCallback((e)=>{
        e.preventDefault();
        e.stopPropagation();
        
        setChecked(prev => {
            let _checked = !prev.selected;

            if (!_checked) {
                setRefundAmount("");
                return {selected: _checked, full: false, half: false, shipping: false, tax: false, custom: false, return: true};
            } else {
                return {selected: _checked, full: true, half: false, shipping: false, tax: false, custom: false, return: true};
            }
        });
    }, []);

    const checkHandler = useCallback(e => {
        const _values = {selected: true, half: false, full: false, shipping: false, tax: false, custom: false};
        _values[e.target.name] = e.target.checked;
        if (!_values.half && !_values.full && !_values.shipping && !_values.tax && !_values.custom) _values.selected = false;
        if (e.target.name !== "custom") setRefundAmount("");
        setChecked(prev => ({...prev, ..._values}));
    }, []);

    const checkTotal = useCallback(e => {
        const _amount = e.target.value || 0;
        checkHandler({target: {name: "custom", checked: +_amount > 0}});
        if (+_amount > +priceTotal + +taxTotal + +shippingTotal) setRefundAmount(+priceTotal + +taxTotal + +shippingTotal);
        else setRefundAmount(+_amount);
    }, [checkHandler, priceTotal, taxTotal, shippingTotal]);

    useEffect(()=>{
        getTotalRefund();
    },[getTotalRefund, checked]);

    if (!props.item) return null;

    return (
        <>
            <Accordion.Toggle id={"refund-accordion-toggle"} as={Card.Header} eventKey={props.index} onClick={e=>{setAccordionToggle(!accordionToggle)}} data-cy="advanded-refund-item" >
                <Row className={`${styles.item} ${accordionToggle ? styles.active : ''}`}>
                    <Col sm={12} lg={9} className="py-2 position-relative">
                        <i className={`${styles["item-check"]} far fa-${checked?.selected ? "check-square" : "square"}`} onClick={toggleCheckHandler} data-cy="select-item-check-item-refund" />
                        <Stack direction="horizontal" spacing={2}>
                            <i className={`ms-5 me-4 far fa-chevron-${accordionToggle ? "up" : "down"}`}/>
                            <div> 
                                <span className="bold">{props.item.product_name.substring(0, 20)}{props.item.product_name.length > 30 && "..."}</span><br/>
                                <span className="small" data-cy="adv-refund-items-max-refund">Max Refund: ${(priceTotal + taxTotal + shippingTotal + priceAdjustments).toFixed(2)}</span>
                            </div>
                        </Stack>
                    </Col>
                    <Col sm={12} lg={3} className="py-2 text-right">
                        Refund for this Item:<br/>
                        <span className="bold">${totalRefund}</span>
                    </Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.index}>
                <Container className="mt-2">
                    <Row className="ms-3">
                        <Col sm={12} lg={4}>
                            <div className={styles["info-pair"]}>
                                <span>
                                    <Form.Check 
                                        id="refund-full"
                                        name="full"
                                        type="checkbox" 
                                        value={priceTotal + taxTotal + shippingTotal + priceAdjustments}
                                        checked={checked.full}
                                        onChange={checkHandler}
                                        className="ps-0"
                                    /> 
                                    <label htmlFor="refund-full">Total Price:</label>
                                </span>
                                <span className="text-right">${(priceTotal + taxTotal + shippingTotal + priceAdjustments).toFixed(2)}</span>
                            </div>
                            <div className={styles["info-pair"]}>
                                <span>
                                    <Form.Check 
                                        id="refund-half"
                                        name="half"
                                        type="checkbox" 
                                        value={((priceTotal + taxTotal + shippingTotal + priceAdjustments) / 2).toFixed(2)} 
                                        checked={checked.half}
                                        onChange={checkHandler}
                                        className="ps-0"
                                    /> 
                                    <label htmlFor="refund-half">Half Price:</label>
                                </span>
                                <span className="text-right">${((priceTotal + taxTotal + shippingTotal + priceAdjustments) / 2).toFixed(2)}</span>
                            </div>
                            {+props.item?.shipping > 0 &&
                                <div className={styles["info-pair"]}>
                                    <span>
                                        <Form.Check 
                                            id="shipping-check"
                                            name="shipping"
                                            type="checkbox" 
                                            value={props.item.shipping}
                                            checked={checked.shipping}
                                            onChange={checkHandler}
                                            className="ps-0"
                                        />
                                        <label htmlFor="shipping-check">Shipping Only:</label>
                                    </span>
                                    <span className="text-right">${(props.item.shipping || 0).toFixed(2)}</span>
                                </div>
                            }
                            {+props.item?.tax > 0 &&
                                <div className={styles["info-pair"]}>
                                    <span>
                                        <Form.Check 
                                            id="tax-check"
                                            name="tax"
                                            type="checkbox" 
                                            value={props.item.tax}
                                            checked={checked.tax}
                                            onChange={checkHandler}
                                            className="ps-0"
                                        />
                                        <label htmlFor="tax-check">Tax Only:</label>
                                    </span>
                                    <span className="text-right">${(props.item.tax || 0).toFixed(2)}</span>
                                </div>
                            }
                        </Col>
                        <Col sm={12} lg={4}>
                            <Form.Group controlId="refund-custom-input" className={`${styles["colspan"]}`}>
                                <Form.Label>Custom Refund Amount</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control 
                                        type="number" 
                                        name="refund-custom-input" 
                                        min={0}
                                        max={priceTotal + taxTotal + shippingTotal}
                                        step={0.1}
                                        value={refundAmount} 
                                        onChange={checkTotal}
                                    />
                                </InputGroup>
                            </Form.Group>
                            {/*
                            <Form.Group controlId="refund-memo">
                                <Form.Label>Refund Memo</Form.Label>
                                <Form.Control ref={memoRef} type="text" name="refund-memo" onBlur={getTotalRefund} />
                            </Form.Group>
                            */}
                        </Col>
                        <Col sm={12} lg={4}>
                            <Form.Check 
                                id="return-check"
                                name="return"
                                type="checkbox" 
                                value={props.item.return}
                                checked={checked.return}
                                onChange={e=> setChecked(prev=>({...prev, return: e.target.checked}))}
                                label="This is an item / service return"
                                className="ps-0 form-switch"
                            />
                        </Col>
                    </Row>
                    <hr/>
                </Container>
            </Accordion.Collapse>
        </>
    );
}
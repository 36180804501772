import React, {useState, useEffect, useRef, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SkeletonTheme } from 'react-loading-skeleton';
import { Skeleton } from '@material-ui/lab';
import { Button, Card } from 'react-bootstrap'

import './splitPayment.scss'

import { updateCart } from '../../../../utils/thunks'
import * as actions from '../../../../store/actions'
import usePrevious from '../../../../components/common/CustomHooks';

import NewUserTypeahead from '../../../../components/Typeahead/NewUserTypeahead';
import OrderSummary from './OrderSummary';
import SingleUserSplit from './SingleUserSplit'
import MultiUserSingleItem from './MultiUserSingleItem';
import MultiUserMultiItems from './MultiUserMultiItems';

export const SplitPayment = (props) => {

    const setType=props.setType;

    const dispatch=useDispatch();

    const mountedRef=useRef(false);
    const currentOrder=useSelector(state=>state.pos[props.register].orderAll);
    const selectedUser=useSelector(state=>state.pos[props.register].user);
    const [requiresUser, setRequiresUser]=useState(true);
    const [hasUser, setHasUser]=useState(false);
    const [selectedType, setSelectedType]=useState(null);
    const oldUser = usePrevious(selectedUser);


//#region useCallback

      /**Types that require user:
     * 1-subscription -- 3-digital -- 4-service -- 5-class/event -- 6-bundle -- 7-rental -- 9-token
     */
       const checkForUserRequired=useCallback(()=>{
        let requireUsersTypes=[1,3,4,5,6,7,9]
        let requires = currentOrder?.items?.some(item=>requireUsersTypes.includes(item.product_type_id));
        if(requires===false) setHasUser(true)
        setRequiresUser(requires);
        dispatch(actions.setSplitPaymentRequiresUser(requires));
    },[currentOrder, dispatch]);

//#endregion useCallback

//#region useEffect
    //first load
    useEffect(()=>{
        mountedRef.current =true 
        
        dispatch(actions.resetSplitPayments()); //resets redux to initial state before beginning the process
        
        return()=>{
            mountedRef.current=false;
            setRequiresUser(true);
            setSelectedType(null);
        }
    },[dispatch]);
    
    //After the order has loaded, checks to see if it has any required items and needs a user selected.
    useEffect(()=>{
        if(mountedRef.current && currentOrder && currentOrder.user_fname==="Guest" && currentOrder.user_lname==="Account"){
            checkForUserRequired();
        } 
    },[checkForUserRequired, currentOrder]);

    //Checks whether or not the user has changed
    useEffect(()=>{
        if(mountedRef.current && selectedUser && selectedUser !==oldUser){
            checkForUserRequired()
        }
        if(mountedRef.current && selectedUser) setHasUser(true);
    },[selectedUser, checkForUserRequired, oldUser]);

    //sets type for the parent component (Buttons)
    useEffect(()=>{
        if(selectedType) setType(selectedType);
    },[selectedType, setType]);

//#endregion useEffect

    //to pass to the typeahead to set the selected user
    const passUserSelection=(selection)=>{
        if(selection.length > 0){
            dispatch(actions.selectCustomer(selection[0], props.register));
            dispatch(updateCart(props.register_id));
        }
    }

    
    return (
        <>
            {/* <div>
                <h5>
                    Split Order
                    {selectedType==="SingleCashCC" || selectedType==="MultipleSingle" &&
                        <>
                            {currentOrder && <span> #{currentOrder.id}</span>}
                            {selectedUser && <span> for {selectedUser.first_name} {selectedUser.last_name}</span>}
                        </>
                    }
                </h5>
            </div> */}
        {(!currentOrder || currentOrder?.total_price === 0) &&
            <>
                You need to start an order with a total greater than 0 to be able to split it!
            </>
        }
        {
            currentOrder && currentOrder?.total_price > 0 && selectedType === null &&
            <>
                {hasUser ? 
                    <>
                        <OrderSummary 
                            currentOrder={currentOrder}
                            currentUser={selectedUser}
                        />
                        <h5>What kind of split payment is being completed for this order?</h5>
                        <div className="sp-type">
                            <Card>
                                <h5><i className="far fa-money-check-alt" /></h5>
                                <p>This transaction type allows for one user to make a payment with both cash and a credit/debit card.  Ensure All discounts and changes are already applied.</p>
                                <Button onClick={()=>setSelectedType("SingleCashCC")}>Single User</Button>
                            </Card>
                            <Card>
                                <fieldset>
                                    <h5><i className="far fa-users" /></h5>
                                    <p>This transaction type allows for multiple users to split a purchase by amount and can be used to purchase something FOR a different user, such as grandparents splitting a price for a grandchild.  Apply coupons and all items before beginning.</p>
                                    <Button onClick={()=>setSelectedType("MultipleSingle")}>Multiple Users, for Another</Button>
                                </fieldset>
                            </Card>
                            <Card>
                                <fieldset disabled style={{opacity: 0.5}}>
                                    <h5><i className="far fa-users-class" /> Coming Soon</h5>
                                    <p>This transaction type allows to split the bill between multiple people by items or amount.</p>
                                    <Button onClick={()=>setSelectedType("MultipleMany")}>Multiple Users, Multiple Orders</Button>
                                </fieldset>
                            </Card>
                        </div>
                    </>
                    :
                    <>
                        <p>An item selected in the order requires a user to be selected.</p>
                        <NewUserTypeahead passSelection={passUserSelection} multiple={false}/>
                    </>
                }
            </>
        }
            {selectedType === "SingleCashCC" &&
                <SingleUserSplit 
                    onClose={props.onClose} 
                    cancelSplit={props.cancelSplit}
                    terminalDeviceId={props.terminalDeviceId} 
                    register={props.register}
                />
            }
            {selectedType === "MultipleSingle" &&
                <MultiUserSingleItem 
                    onClose={props.onClose}
                    cancelSplit={props.cancelSplit} 
                    terminalDeviceId={props.terminalDeviceId} 
                    register={props.register} 
                />
            }
            {selectedType==="MultipleMany" &&
                <MultiUserMultiItems 
                    onClose={props.onClose} 
                    cancelSplit={props.cancelSplit} 
                    terminalDeviceId={props.terminalDeviceId} 
                    register={props.register} 
                />
            }
        </>
    )
}

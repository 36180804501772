import React,{useEffect,useState} from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import format from "date-fns/format";

import './List.css';

import Events from "../../../../api/Events";
import Locations from "../../../../api/Locations";
import RegEventListCards from '../ListCards'
import RegisterCards from '../RegisterCards'

//called from the following components:
// src\containers\Home\Home.js
// src\containers\POS\Events\Register.js

export const List = (props) => {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [locations, setLocations] = useState([]);
    const EVENT_STATUS_ID = 2;
    let isChild = props.isChild ? 1 : 0;
    let maxRecords=props.home ? 10 : 40 ;

    useEffect( () => {
        const _getEvents=async()=>{
            try{
                setLoading(true);
                let response = await Events.getSimple({max_records: maxRecords, event_status_id: EVENT_STATUS_ID, start_datetime: new Date(), is_child: isChild, parent_id: props.parent_id})
                setLoading(false);
                if(response.data){
                    let parentId;
                    if (props.parent_id) parentId = props.parent_id;
                    else parentId = null;
                    setEvents(response.data?.events.filter(event => event.parent_id === parentId && new Date(event.end_datetime) > new Date()));
                }
            }catch(ex){console.error(ex)}
        }

        const _getLocations=async()=>{
            try{
                let response = await Locations.get()
                if(response.data) setLocations(response.data)
            }catch(ex){console.error(ex)}
        }

        _getEvents();
        _getLocations();
        
    },[props.parent_id, maxRecords, isChild]);

    useEffect( () => {
        return () => {
            setEvents([]);
            setLocations([]);
        }
    }, []);

    return (
        <>
            {props.home ? 
                <RegEventListCards events={events}/>  
                :
                <RegisterCards events={events} locations={locations} parent={props.parent_id} />
            }
        </>
    );
}
import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Form, InputGroup} from 'react-bootstrap';

import styles from '../Wizard.module.scss';

export const ByAmount = (props) => {
    const {stepUpdate} = props;

    const [amount, setAmount] = useState((+props?.stepData?.amount || +props?.maxTotal || 0).toFixed(2));

    const changeHandler = e => {
        let _amount = +e.target.value || 0;
        if (_amount > +props?.maxTotal) _amount = +props?.maxTotal.toFixed(2);
        if (!_amount) _amount = "";
        setAmount(_amount);
    }

    useEffect(()=>{
        stepUpdate(props.stepId, {amount});
    },[stepUpdate, props.stepId, amount]);    

    return (
        <Container fluid>
            <h4 className={`${styles.title}`}>How much do you want to refund?</h4>
            <span className={styles.subtitle}>Up to ${(props?.maxTotal || 0).toFixed(2)}</span>
            <hr/>
            <Row>
                <Col sm={12} lg={3}>
                    <Form.Group controlId="refund-amount">
                        <Form.Label>Refund Amount</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="number" name="amount" min={0} max={props?.maxTotal || 0} step={0.1} value={amount} onChange={changeHandler} />
                        </InputGroup>
                    </Form.Group>
                    <Form.Check 
                        className="ps-0 form-switch"
                        id="admin-check"
                        name="admin-check"
                        type="checkbox" 
                        label={`Full Amount of $${props?.maxTotal.toFixed(2)}`}
                        onChange={e => {
                            changeHandler({target: {value: e.target.checked ? props?.maxTotal.toFixed(2) : 0}});
                        }}
                        checked={+amount === +props?.maxTotal}
                    />
                </Col>
            </Row>
        </Container>
    );
}
import React, {useState, useEffect, useContext} from 'react';
import { getPageById, parseCmsComponent, randomUUID } from '../../../../../utils/cms';
import { FileURLContext } from '../../../../../contexts/FileURLContext';    

const WizardBlock = React.forwardRef((props, _) => {
    const company_context = useContext(FileURLContext);

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [page, setPage] = useState();

    useEffect(() => {
        const _getPageById = async () => {
            if (props.wizard_id){
                const res=await getPageById({id: props.wizard_id});
                if (res){
                    let _props = {
                        id: props.is_preview ? `preview-${randomUUID()}` :undefined,
                        page_id: props.is_preview ? "preview" : props.page_id,
                        onClick: preview_click,
                        is_preview: props.is_preview,
                        wizard_page_id: props.page_id
                    };

                    const _page = await parseCmsComponent(res[0].content, company_context, _props);
                    setPage(_page);
                } 
            }
        }
        _getPageById();
    },[props.wizard_id, props.id, props.page_id, props.is_preview, preview_click, company_context]);


    useEffect(() => {
        return () => {
            setPage(null);
        }
    },[]);


    if (!props.wizard_id){
        return <div className="empty-contentblock" onClick={preview_click} />
    }

    return page || <div/>;
});

export default WizardBlock;
import React, { useContext} from 'react';
import { useHistory } from "react-router-dom";

import { FileURLContext } from '../../../../contexts/FileURLContext';

import "./Groups.scss";

export const Groups = props => {

    const imageURL = useContext(FileURLContext);

    let history = useHistory();
  
    return (
    <>
        <div className="tab-group-wrapper">
            {!props.data.length>0 && <span>No groups to display</span>}
            {props.data?.map((group) => (
                <div
                    className="cp"
                    data-cy="home-groups"
                    key={group.group_id}
                    onClick={()=>history.push({
                        pathname:`/p/my/groups/${group.group_id}`,
                        //setting history states to make accurate breadcrumbs from whence a user came
                        state: {
                        fromPath: history.location,
                        fromName: "Home"
                        }
                    })}
                >
                    <img
                        src={imageURL.logoNoText}
                        alt="family profile"
                        className="family-prof"
                    />
                    <span className="group-name">{group.name}</span>
                </div>
            ))}
        </div>
    </>
    );
}
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import {Container,Row,Col,Modal,Button,Card} from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Toast from '../../../../components/Toast';
import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import { authCheck } from "../../../../utils/auth";
import { authUserHasModuleAccess } from '../../../../utils/auth';
import GroupTypeahead from '../../../../components/Typeahead/GroupTypeahead';

import Users from '../../../../api/Users';
import Groups from '../../../../api/Groups';

import './Groups.scss';

import Stack from '../../../../components/common/Stack';

const EDIT_GROUPS_MODULE_ID = 103;

const RemoveFromGroupModal = ({ show, onHide, onCancel, onConfirm, user, selectedGroup, cancelInvite=false }) => {

    let text = {
        title: "Leave Group",
        body: `Are you sure you want to leave ${selectedGroup?.name || 'this group'}?`,
        cancelButton: "No",
        confirmButton: "Yes"
    }
    if (cancelInvite) text = {
        title: "Cancel Invitation",
        body: `Are you sure you want to cancel the invitation to ${selectedGroup?.name || 'this group'}?`,
        cancelButton: "No",
        confirmButton: "Yes"
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{text.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text.body}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    {text.cancelButton}
                </Button>
                <Button
                    variant="danger"
                    onClick={onConfirm}
                >
                    {text.confirmButton}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

// groupMembersDisplay is an object whose keys are group_ids and the content of each is react elements to display for that group
// it is used specificially for Family, in order to get the family members to display underneath each family group
export const UserGroups = ({ user, currentUserRoleId, familyOnly=false, groupMembersDisplay=null, groupMembersRefresh=()=>{}, goTo=()=>{} }) => {

    let history = useHistory();
    let authUser = authCheck(history);

    const [userGroups, setUserGroups] = useState();
    const [selectedGroup, setSelectedGroup] = useState();
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const [authPermission, setAuthPermission] = useState(false);
    const [inviteGroupId, setInviteGroupId]=useState(null);

    const handleCloseRemoveModal = () => setShowRemoveModal(false);

    const handleShowRemoveModal = (group) => {
        setSelectedGroup(group);
        setShowRemoveModal(true);
    }

    const refreshAll = useCallback(() => {
        setRefresh(prev => prev+1);
        groupMembersRefresh();
    },[groupMembersRefresh]);

    const removeUser = useCallback(() => {
        if (selectedGroup) {
            Groups.delete_member({
                group_id: selectedGroup.group_id,
                user_id: user.id,
            })
            .then((response) => {
                if (!response.errors) {
                    setSuccess(<Toast key={`toast-remove-${selectedGroup.group_id}`}>{`Successfully removed ${selectedGroup.name}!`}</Toast>);
                    refreshAll();
                } else {
                    throw response.errors;
                }
            })
            .catch((e) => console.error(e));
        }
    },[user, selectedGroup, refreshAll]);

    const confirmUser = useCallback((group) => {
        if(group?.group_id && user?.id) {
            Groups.accept_invitation({ group_id: group.group_id, user_id: user.id})
            .then( response => {
                if(!response.errors) {
                    setSuccess(<Toast key={`toast-group-${group.group_id}`}>{`Successfully joined ${group.name}!`}</Toast>);
                    refreshAll();
                } else {
                    setError(<ErrorCatcher error={response.errors[0]} />)
                }
            }).catch(e => console.error(e));
        }
    },[user,refreshAll]);

    const memberStatus = [
        { name: "Confirmed", id: 2 },
        { name: "Invited", id: 1 },
        { name: "Suspended", id: 3 },
        { name: "Banned", id: 4 },
        { name: "Pending", id: 5 },
    ];

    useEffect(() => {
        let mounted = true;

        Users.groups({id: user.id})
        .then(response => {
            if(mounted) {
                let data = response.data;
                if (familyOnly) data = data.filter(group => group.group_type_id===4);
                setUserGroups(data);
            }
        }).catch(e => console.error(e));

        return () => {
            mounted = false;
        }
	}, [user, refresh, familyOnly]);

    useEffect(()=>{
        const getModuleAccess = async()=>{
            try{
                let response = await authUserHasModuleAccess(EDIT_GROUPS_MODULE_ID);
                setAuthPermission(response)
            }catch(ex){
                console.error(ex);
            }
        }
        getModuleAccess();
    },[])

    const detailsButtonHandler = useCallback((group) => {
        if (group.group_type_id===4 && history.location.hash !== "#Family") {
            goTo('Family');
        } else {
            history.push({
                pathname: `${authPermission[EDIT_GROUPS_MODULE_ID] ? '/p/my' : '/p'}/groups/${group.group_id}`,
                state: {
                    fromPath: history.location,
                    fromName: "Profile",
                    fromUser: `${user?.first_name} ${user?.last_name}`
                }
            })
        }
    },[history, user, goTo, authPermission]);


    const addToGroup = async(statusId)=>{
        const groupData={
            group_id: inviteGroupId,
            user_ids: user.id,
            status_id: statusId
        }
        try{
            let response = await Groups.invite(groupData)
            if(response.status === 200){
                setSuccess(`User has been ${statusId===1 ? 'invited' : 'added'} to the group!`);
                setInviteGroupId(null);
                refreshAll();
            }else{
                setError(<ErrorCatcher error={"There was a problem adding the user to the group" + response.errors[0]} />)
            }
        }catch(ex){
            console.error(ex)
        }
    }

    return (
        <Container fluid className="user-groups">
            {success}
            {error}

            {!familyOnly &&
                <>
                    <h4 className="section-title">Groups</h4>
                    <hr />
                    {authPermission[EDIT_GROUPS_MODULE_ID] &&
                        <>
                            <p>
                                Add this user to a group:
                                <GroupTypeahead 
                                    multiple={false}
                                    async={false}
                                    passSelection={(selection)=>setInviteGroupId(selection[0]?.id)}
                                />
                            </p>
                            <div className="d-flex justify-content-end">
                                <fieldset disabled={!inviteGroupId}>
                                    <Button onClick={()=>addToGroup(2)}>Add</Button>
                                    <Button onClick={()=>addToGroup(1)}>Invite</Button>
                                </fieldset>
                            </div>
                        </>
                    }
                </>
            }

            {userGroups &&
                <Row data-cy="group-row">
                {userGroups.map((group) => {

                    let status = memberStatus.find(s => s.id === group.group_member_status_id);

                    return (
                        <Col xs={12} key={group.group_id}>
                            <Card className="standout mb-3">
                                <Card.Title>{group.name}</Card.Title>
                                <Card.Body className="p-0 mb-2">
                                    <div><span className="bold">Type:</span> {group.group_type_name}</div>
                                    <div><span className="bold">Role:</span> {group.group_member_role}</div>
                                    <div><span className="bold">Status:</span> {status?.name}</div>
                                </Card.Body>
                                <Card.Footer>
                                    <Stack direction="horizontal" gap={2}>
                                    {((authPermission[EDIT_GROUPS_MODULE_ID]) //give permission to see it always if the user can edit all groups 
                                        || ((group.group_member_role !== "Child" || group.group_member_role_id !==11 ) && history.location.hash !=="#Family")) &&
                                    <Button
                                        className="btn-primary"
                                        onClick={() => detailsButtonHandler(group)}
                                    >
                                        <i className="far fa-external-link" /> Details
                                    </Button>
                                    }

                                    {status.id===1 && 
                                        <>
                                            <Button
                                                className="btn-primary"
                                                onClick={() => confirmUser(group)}
                                            >
                                                Accept Invitation
                                            </Button>
                                        </>
                                    }

                                    {/* Making sure children in a family group cannot remove themselves from the group
                                    {(group.group_member_role_id!==11 || group.group_type_id!==4) && 
                                        <Button
                                            className="btn-danger"
                                            onClick={() => handleShowRemoveModal(group)}
                                        >
                                            <i className="far fa-external-link" /> Details
                                        </Button>
                                    } */}

                                        {/* Making sure children in a family group cannot remove themselves from the group and that other family doesn't have auth access and see double*/}
                                        {(!authPermission[EDIT_GROUPS_MODULE_ID] && authUser.profile.id === user.id && (!group.group_member_role_id!==11 || group.group_type_id!==4)) && 
                                            <Button
                                                className="btn-danger"
                                                onClick={() => handleShowRemoveModal(group)}
                                            >
                                                <i className="far fa-times"></i> {status.id===1 ? 'Decline' : 'Leave'}
                                            </Button>
                                        }
                                        {authPermission[EDIT_GROUPS_MODULE_ID] && authUser.profile.id !== user.id && //make sure its always visible to the who can edit groups
                                            <Button
                                                className="btn-danger"
                                                onClick={() => handleShowRemoveModal(group)}
                                            >
                                                <i className="far fa-times"></i> Remove
                                            </Button>
                                        }
                                    </Stack>
                                </Card.Footer>

                                {/* if group member data was passed in display it in the group's card */}
                                {groupMembersDisplay && groupMembersDisplay[group.group_id] &&
                                    <>{groupMembersDisplay[group.group_id]}</>
                                }
                            </Card>
                        </Col>
                    );
                })}
                </Row>
            }

            {userGroups?.length===0 &&
                <p className="text-center">No groups to display.</p>
            }

            <RemoveFromGroupModal
                show={showRemoveModal}
                onHide={handleCloseRemoveModal}
                onCancel={handleCloseRemoveModal}
                onConfirm={() => {
                    removeUser();
                    handleCloseRemoveModal();
                }}
                cancelInvite={selectedGroup?.group_member_status_id===1}
                user={user}
                selectedGroup={selectedGroup}
            />

        </Container>
    );
}
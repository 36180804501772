import React from 'react';
import {format} from 'date-fns';

import styles from '../FullPageReceipt.module.scss';

/**Needs the following props 
 * @param userDetails -user details including f, l, and m name, address1, address2, city, state, zip
 * @param order (needs order id, and price values)
 * @param items (pass in the items seperate, in case they need to be such as a parent/child order)
 * @param transaction
 * @param paymentMethod
 * @param company
 * @param companyLogo
 * @param parentOrder
*/
export const Transaction = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <div className={styles["fp-receipt-wrapper"]}>
                <div className={styles["flex-wrapper-company"]}>
                    <img src={props.companyLogo} alt="" className={styles["company-logo"]} />
                    <div className={styles["right-address"]}>
                        <h1 className={styles["fp-larger"]}>{props.company?.name}</h1>
                        {props.company?.address_street}
                        <br/>
                        {props.company?.address_street2 &&
                            <>
                                {props.company?.address_street2}
                                <br/>
                            </> 
                        }
                        {props.company?.address_city}, {props.company?.address_state} {props.company?.address_postcode}
                        <br />
                        {props.company?.primary_number}
                        <br />
                        {props.company?.email}
                        <br/><br/>
                        <span className={styles.bold}>{props.transaction.transaction_type_name}</span>
                        <br/>
                        <span className={styles.bold}>Transaction # {props.transaction?.id}</span>
                        <br/>
                        {props.transaction.transaction_response?.transactionid && 
                            <>
                                <span className={styles.bold}>Gateway Transaction # {props.transaction.transaction_response.transactionid}</span>
                                <br/>
                            </>
                        }
                        {format(new Date(props.transaction.date), "MM-dd-yyy h:mm aa")}
                        <br/><br/>

                        {+props.transaction.transaction_payment_method_id === 5 &&
                            <>
                                <span className={styles.bold}>Manager Discount: ${props.transaction.amount.toFixed(2)}</span>
                                <br/>
                            </>
                        }
                        {+props.transaction.transaction_payment_method_id !== 5 &&
                            <>
                                Paid With: {props.transaction.transaction_payment_method_name}&nbsp;
                                {props.transaction.transaction_response?.check_number && 
                                    <>#{props.transaction.transaction_response.check_number}</>
                                }
                                {props.transaction.transaction_response?.check_name && 
                                    <>
                                        <br/>
                                        From: {props.transaction.transaction_response.check_name}
                                    </>
                                }
                                {props.transaction.cc_number && `${props.transaction.cc_type} ending in ${props.transaction.cc_number}`}
                                <br/><br/>
                                <span className={styles.bold}>Total Payment: ${props.transaction.amount.toFixed(2)}</span>
                                <br/>
                                {props.transaction?.change > 0 && 
                                    <>
                                        <span className={styles.bold}>Cash Tendered: ${props.transaction.transaction_response.amount.toFixed(2)}</span>
                                        <br/>
                                        <span className={styles.bold}>Change: ${props.transaction.transaction_response.change.toFixed(2)}</span>
                                    </>
                                }
                            </>
                        }
                        <br/>
                        <span className={styles.bold}>{props.transaction.transaction_status_name}</span>
                        <br/>
                    </div>
                </div>
            </div>
            {props.extraContent && props.extraContent}
        </div>
    );
});
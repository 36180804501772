import React, {useState, useEffect, useMemo} from 'react';
import {Row, Col, Card} from 'react-bootstrap';

import APIGroups from '../../../../../api/Groups';
import { randomUUID } from '../../../../../utils/cms';

const GroupPage = (props) => {
    let dummyData = useMemo(() => [], []);
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.is_preview){
            if (!dummyData.length){
                let _members=[];
                for (let i=0;i<10;i++){
                    _members.push({
                        id: i+1,
                        first_name: `Member`,
                        last_name: `#${i+1}`,
                        group_member_role_name: "Position Played",
                        image: null
                    });
                }
                dummyData.push({
                    id: 1,
                    name: `Group Name`,
                    description: "Placeholder description",
                    group_members: _members
                });
            }
        }
    }, [props.is_preview, dummyData]);

    useEffect(() => {        
        const _loadGroup = async (id) => {
            if (id){
                const response = await APIGroups.publicGet({filters:{group_id:id}});
                //console.log("GroupPage", response)
                if (response?.data?.groups){
                    setData(response.data.groups);
                }
            }
        }

        if (props?.data?.length) setData(props.data);
        else {
            if (props.is_preview) setData(dummyData);
            else _loadGroup(props?.group_id);
        }

    }, [props?.data, props?.group_id, props.is_preview, dummyData]);

    useEffect(() => {
        return () => {
            setData([]);
        }
    }, []);

    return (
        <div className={`group_page_${props.page_id} ${props.className || ""}`} style={props?.style || null} onClick={preview_click}>
           {data.map((item, i) => (
                <Card key={`group-peep-${i}-${randomUUID}`}>
                    <Card.Body>
                    <Row key={`group-page-row-${item.id}-${i}`} className={`row_${props.page_id}`}>
                        <Col sm={12} className={`col_${props.page_id}`}>
                            <h1>{item.name}</h1>
                            <p>{item.description}</p>
                        </Col>
                    </Row>
                    <Row className={`row_${props.page_id} mt-5`}>
                        {!item.group_members.length && <Col sm={12} className={`col_${props.page_id}`}>No members found.</Col>}
                        {item.group_members.map((member, i) => (
                            <Col sm={12} lg={4} key={`group-page-member-${member.id}-${i}`} className={`col_${props.page_id} mb-3`}>
                                <h5>{member.first_name} {member.last_name}</h5>
                                <label>{member.group_member_role_name}</label>
                            </Col>
                        ))}
                    </Row>
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
}

export default GroupPage;
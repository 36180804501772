import React, { useState, useEffect } from 'react';
import { format, formatISO } from 'date-fns'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom';

import Events from '../../../../api/Events';
import '../UpcomingSchedule.css'

export const Personal = (props) => {

    const history = useHistory()
    const [loading,setLoading]=useState(true);
    const [events,setEvents]=useState([]);
    const [ modalToggle, setModalToggle ]=useState(false);
    const [ activeEvent, setActiveEvent ]=useState();
    const today = new Date();

    // first load, get events from api
    useEffect(() => {
        let mounted = true;
        
        setLoading(true);
        Events.getForUser({
            user_id: props.user_id,
            start_datetime: formatISO(new Date()),
            max_records: 8,
            sort_col: "start_datetime",
            sort_direction: "ASC",
            event_status_id: [2],   // only confirmed events
        }).then(response => {
            if(mounted) {
                if (response.data) {
                    setEvents(response.data.events);
                }
                setLoading(false);
            }
        }).catch(e => console.error(e));

        // cancel stuff when component unmounts
        return () => {
            mounted = false;
            setLoading(false);
            setEvents([]);
            setModalToggle(false)
            setActiveEvent([])
        }        
    },[props.user_id]);

    const modalToggleOnClick=()=>{
        setModalToggle(!modalToggle)
    }

    const rowOnClick=(event)=>{
        setActiveEvent(event);
        setModalToggle(true);
    }

    return (
        <>
            {events?.length<=0 && 
                <div>No registered events</div>
            }
            {events?.length > 0 ? events.map( (event,i) => 
                <div key={`personal-upcoming-${i}`} className={Math.abs(new Date(event.start_datetime).getTime() - new Date().getTime())/60000<=59?"org-desc":"blue-desc"}>
                    <Row onClick={()=>rowOnClick(event)} className="ue-cursor-pointer">
                        <Col md="5" className="pd-10">
                            <span className="tabs-she-text">
                                {event.start_datetime === today ? 
                                    <span> Today</span> 
                                : 
                                    <span>{format(new Date(event.start_datetime), "MM/dd/yy")} -- </span>
                                } 
                                {format(new Date(event.start_datetime), "hh:mm aa")} - {format(new Date(event.end_datetime), "hh:mm aa")}
                            </span>
                        </Col>
                        <Col md="7" className="pd-10"><span className="tabs-she-text">{event.name}</span></Col>
                    </Row>
                </div>
                ) : null}
            <br></br>
        
            {activeEvent &&
                <Modal show={modalToggle} onHide={modalToggleOnClick}>
                        <Modal.Header closeButton>
                            <Modal.Title>Event Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>{activeEvent.start_datetime && <span>{format(new Date(activeEvent?.start_datetime), "MM/dd")}</span>}  -  {activeEvent.end_datetime && <span>{format(new Date(activeEvent?.end_datetime), "MM/dd")}</span>}</h4>
                            <label className="form-label">Name</label><p>{activeEvent?.name}</p>
                            <label className="form-label">Type</label><p>{activeEvent?.event_type_name}</p>
                            <label className="form-label">Description</label><p dangerouslySetInnerHTML={{__html: activeEvent?.description}} />
                            <Modal.Footer>
                                <Button variant="secondary" onClick={modalToggleOnClick}>Close</Button>
                            </Modal.Footer>
                        </Modal.Body>
                </Modal>
            }
            
        </>
    )
}
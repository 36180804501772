import React, {useState, useCallback, useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {format} from 'date-fns';
import {Row, Col, Card, Button, Modal, Table} from 'react-bootstrap';    

import EventDetail from './EventDetail';
import { toSlug } from '../../../../../utils/cms';
import APIEvents from '../../../../../api/Events';

import styles from './EventsView.module.scss';

const EventCard = (props) => (
    <>
        {props.data?.map((item,i)=>(
            <Col key={`event-${item.id}-${i}`} className={`col_${props.page_id}`} sm={12} lg={4} xl={3}>
                <Card className={`card_${props.page_id} ${props?.className || ""}`}>
                    {item.image &&
                        <Card.Img href="#!" onClick={(e)=>props.onClick(e,item)} variant="top" src={item.image || props.company_context.logoNoText} className={`card-img-top_${props.page_id}`} style={{objectFit:"cover",aspectRatio:" 1 / 1"}} alt={item.name} />
                    }
                    <Card.Body className={`card-body_${props.page_id}`}>
                        <Card.Title className={`card-title_${props.page_id}`}>{item.name}</Card.Title>
                        <Card.Text className={`card-text_${props.page_id}`}>
                            {format(new Date(item.start_datetime), "yyyy/MM/dd HH:mm")!==format(new Date(item.end_datetime), "yyyy/MM/dd HH:mm") && "From: "}
                            {format(new Date(item.start_datetime), "MMM dd h:mm a")}
                        </Card.Text>
                        {format(new Date(item.start_datetime), "yyyy/MM/dd HH:mm")!==format(new Date(item.end_datetime), "yyyy/MM/dd HH:mm") && 
                            <Card.Text className={`card-text_${props.page_id}`}>To: {format(new Date(item.end_datetime), "MMM dd h:mm a")}</Card.Text>
                        }
                        <Card.Text className={`card-text_${props.page_id}`}>{item.location_name}</Card.Text>
                        <Card.Text className={`card-text_${props.page_id}`}>{item.short_description}</Card.Text>
                        <br/>
                        <Button href="#!" onClick={(e)=>props.onClick(e,item)} className={`btn_${props.page_id}`}>View</Button>
                    </Card.Body>
                </Card>            
            </Col>
        ))}
    </>
);

const EventTable = (props) => (
    <Table className={`table_${props.page_id}`}>
        <thead className={`thead_${props.page_id}`}>
            <tr className={`tr_${props.page_id}`}>
                <th className={`th_${props.page_id}`}>Date</th>
                <th className={`th_${props.page_id}`}>Name</th>
                <th className={`th_${props.page_id}`}>Location</th>
                <th className={`th_${props.page_id}`}></th>
            </tr>
        </thead>
        <tbody className={`tbody_${props.page_id}`}>
            {props.data?.map((item,i)=>(
                <tr key={`event-tb-${item.id}-${i}`} className={`tr_${props.page_id}`}>
                    <td className={`td_${props.page_id}`} style={{width:"1%",whiteSpace:"nowrap"}}>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column me-3">
                                <h5>{format(new Date(item.start_datetime), "MMM dd")}</h5>
                                <div>
                                    {(!item.is_meta || item.is_meta!==1) &&
                                        <>
                                            <span className="bold">{format(new Date(item.start_datetime), "h:mm a")}</span>                                    
                                            {format(new Date(item.start_datetime), "yyyy/MM/dd")===format(new Date(item.end_datetime), "yyyy/MM/dd") &&
                                                format(new Date(item.start_datetime), "HH:mm")!==format(new Date(item.end_datetime), "HH:mm") &&
                                                    <span className="bold">{format(new Date(item.end_datetime), " - h:mm a")}</span>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            {format(new Date(item.start_datetime), "yyyy/MM/dd")!==format(new Date(item.end_datetime), "yyyy/MM/dd") && 
                                <>
                                    /
                                    <div className="d-flex flex-column mx-3">
                                        <h5>{format(new Date(item.end_datetime), "MMM dd")}</h5>
                                        {(!item.is_meta || item.is_meta!==1) &&
                                            <span className="bold">{format(new Date(item.end_datetime), "h:mm a")}</span>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </td>
                    <td className={`td_${props.page_id}`}>{item.name}</td>
                    <td className={`td_${props.page_id}`}>{item.location_name}</td>
                    <td className={`td_${props.page_id}`}>
                        <Button href="#!" onClick={(e)=>props.onClick(e,item)} className={`btn_${props.page_id}`}>View</Button>
                    </td>
                </tr>
            ))}
        </tbody>
    </Table>
);

const EventsView = (props) => {
    const history = useHistory();

    let dummyData = useMemo(() => [], []);

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const user = useSelector(state => state.auth.user);    
    const {data: _data, event_types, placeholder_images, show_images} = props;

    const [data, setData] = useState([]);
    const [myData, setMyData] = useState([]);
    const [eventId, setEventId] = useState();
    const [showModal, setShowModal] = useState(false);

    const toggleModal = useCallback((event) => {
        const _toggle = !showModal;

        if (_toggle) history.push(`${window.location.pathname}#${event?.id}~${toSlug(event?.name || eventId.name || "event")}`);
        else history.push(`${window.location.pathname}`);

        setShowModal(_toggle);
    }, [showModal, eventId, history]);

    useEffect(() => {
        if (props.is_preview && !dummyData.length){
            for (let i=0;i<5;i++){
                dummyData.push({
                    id: i+1,
                    name: `Event ${i+1}`,
                    start_datetime: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
                    end_datetime: new Date(new Date().getTime() + (Math.floor(Math.random() * 14) + 2) * 24 * 60 * 60 * 1000),
                    description: "Placeholder description",
                    location_name: "Location 1",
                    image: null
                });            
            }
        }
    }, [props.is_preview, dummyData]);  

    /* listen for hashtag and load the appropiate event */
    useEffect(() => {
        const _hashSearch = (hash) => {
            const hashtag = hash.replace("#","").split("~");
            if (!eventId || hashtag.length > 0){
                let _event = null;
                if (hashtag?.[0] && eventId?.id !== hashtag?.[0]) _event = data.find(item => item.id === parseInt(hashtag[0]));
                else if (hashtag?.[1] && eventId?.name !== hashtag?.[1]) _event = data.find(item => toSlug(item.name) === hashtag[1]);
                if (_event) {
                    setEventId(_event);
                    setShowModal(true);
                }
            }
        }

        const loc = window.location.hash;
        if (loc) _hashSearch(loc);
        const unlisten = history.listen((location, action) => {
            if (!location.hash) setShowModal(false);
            else _hashSearch(location.hash);
        });
    
        return () => {
            unlisten();
        };
    }, [history, eventId, data]);
        
    useEffect(() => {
        return () => {
            setData([]);
            setMyData([]);
            setEventId(null);
            setShowModal(false);
        }
    }, []);   
        
    useEffect(() => {
        // formats the data for the view
        const _formatData = async (data) => {
            if (data?.events.length>0){
                if (show_images===true && placeholder_images){
                    const images=await _loadPlaceholderImages();
                    if (images){
                        images.forEach((item,i)=>{
                            if (data.events?.[i] && !data.events[i]?.image) data.events[i].image=item.src.large;
                        });
                    }
                }
                if (typeof show_images === 'boolean' && show_images===false){
                    data.events.forEach((_,i)=>{data.events[i].image=null;});
                }
            }
            return data;
        }
        
        // grabs a placeholder image from pexels
        const _loadPlaceholderImages = async () => {
            return null;
            /*
            try{
                const response = await fetch(`https://api.pexels.com/v1/search?query=${placeholder_images}`, {
                    method: "GET",
                    headers: {Authorization: process.env.REACT_APP_PEXELS_API_KEY}
                });
                const _data = await response.json();
                if (_data?.photos) return _data.photos;
            } catch (e){
                console.log(e);
            }
            return null;
            */
        }
        
        // load the events
        const _loadEvents = async (user_id) => {
            try{
                let params={
                    start_datetime: "now",
                    sort_col: "start_datetime",
                    sort_direction: "asc",
                    event_types: event_types || null,
                    include_users: 1
                }
                if (user_id) params.user_id=user_id;
                
                const response = await APIEvents.publicGet(params);
                if (response?.data?.events.length>0){
                    const __data = await _formatData(response.data);
                    if (__data.events){
                        setData(__data.events);
                        if (user?.profile?.id){
                            setMyData(__data.events.filter(a => a.users?.some(b => b.id === user.profile.id)));
                        }
                    }
                }
            } catch (e){
                console.log(e);
            }
        }

        if (!data.length){
            if (_data) setData(_data);
            else {
                if (props.is_preview) _formatData({events:dummyData}).then(_data=>{
                    if (user?.profile?.id) setMyData(_data.events);
                    setData(_data.events)
                });
                else {
                    _loadEvents();
                    //if (user?.profile?.id) _loadEvents(user?.profile?.id);
                }
            }
        }
    }, [_data, data, event_types, placeholder_images, show_images, user, props.is_preview, dummyData]);


    const clickHandler = (e, event) => {
        if (!props.is_preview){
            e.preventDefault();
            setEventId(event);
            toggleModal(event);
        }
    }

    const closeModalHandler=()=>{
        toggleModal();
    }

    return (
        <div className={`events-view_${props.page_id} ${props.className || ""}`} style={props?.style || null} onClick={preview_click}>
                    {myData?.length>0 &&
                        <div className={`my-events_${props.page_id}`}>
                            <Row className={`row_${props.page_id}`}>
                                <Col className={`col_${props.page_id}`} sm={12}>
                                    <h1>My Events</h1>
                                </Col>
                            </Row>
                            <Row className={`row_${props.page_id}`}>
                                {props.type==="table" && <EventTable {...props} data={myData} onClick={clickHandler}/>}
                                {(!props?.type || props.type!=="table") && <EventCard {...props} data={myData} onClick={clickHandler}/>}
                            </Row>
                        </div>
                    }

                    <div className={`all-events_${props.page_id}`}>
                        <Row className={`row_${props.page_id}`}>
                            <Col className={`col_${props.page_id}`} sm={12}>
                                <h1>All Events</h1>
                            </Col>
                        </Row>
                        <Row className={`row_${props.page_id}`}>
                            {props.type==="table" && <EventTable {...props} data={data} onClick={clickHandler}/>}
                            {(!props?.type || props.type!=="table") && <EventCard {...props} data={data} onClick={clickHandler}/>}
                        </Row>
                    </div>

                    {eventId &&
                        <Modal show={showModal} onHide={closeModalHandler} size={"md"} backdrop="static" dialogClassName={styles.modal} className={styles.modal}>
                            <EventDetail {...props} data={[eventId]} close={toggleModal} />
                        </Modal>
                    }
        </div>
    );
}

export default EventsView;
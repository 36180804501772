import Request from './Api';

const setFinal =(props)=>{
    return(
        Request({
            url: '/subscriptions/set_final_bill_date',
            method: "POST",
            data: props
        })
    )
}

const setRestart=(props)=>{
    return(
        Request({
            url:"/subscriptions/set_restart_bill_date",
            method: "POST",
            data: props
        })
    )
}

const SubscriptionApi = {
    setFinal, setRestart,
}

export default SubscriptionApi;
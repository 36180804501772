import React from 'react';
import {Row,Col,Modal} from 'react-bootstrap';

import ProductCard from '../Products';

const Variants = (props) => {

    // only display variants that are active
    let variants = props.activeProduct?.product_variants || null;

    // for patron facing carts show only the first variant - enable multi variants later
    if (variants && props.patron_layout) variants = variants.slice(0,1);

    return (
        <Modal className="pos-modal pos-p-modal variants-modal" backdropClassName="pos-p-backdrop" show={props.show} onHide={props.hide} size="lg" backdrop="static" data-cy="variant-picker-modal">
            <Modal.Header closeButton={true}>
                <Modal.Title data-cy="variant-picker-modal-title">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row data-cy="variant-picker-wrap">
                    <Col className="pos-products">
                        {variants?.map((item,j)=>(
                            <ProductCard
                                key={`item-pckr-1-${j}`}
                                item={item}
                                name={item.name || "Default"} 
                                events={props?.activeProduct?.events || null}
                                price={item.activation_fee > 0 ? item.activation_fee : item.price}
                                isActivationFee={+item.activation_fee > 0}
                                type={1}
                                click={props.click}
                                // active={+props.activeProduct.id===+props.item.id && +props.activeProduct.type===+1}
                                services={props?.productType==="services"}
                            />
                        ))}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default Variants;
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

/** Table skeleton will put a little loading skeleton into each column of a table, as designated by the number in the props passed to it.
 * <tr> is set here but this component needs to be set in a <tbody>.  Loading state to be handled by parent component
 * the number of rows is determinded by passing a second number in, else is 1
 */
export const TableSkeleton = ({columns, rows = 1}) => {

    return (
        <>
            {Array(rows).fill("row").map((val, i)=>(
                <tr key={`ls-${val}-${i}`}>                          
                    {Array(columns).fill("col").map((val, j)=>(
                        <td key={`ls-${val}-${j}`}>
                            <SkeletonTheme color="#e0e0e0">
                                <Skeleton height={16}   />
                            </SkeletonTheme>
                        </td>        
                    ))}
                </tr>
            ))}
        </>
    )
}

import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {Row, Col, Modal, Button, Form} from 'react-bootstrap';
import DatePicker from 'react-datepicker';

import styles from './Recipient.module.scss';

export const Recipient = (props) => {
    const {click} = props;

    const [deliveryDate, setDeliveryDate] = useState(new Date());
    const [activeRecipient, setActiveRecipient] = useState();
    const [recipientName, setRecipientName] = useState();
    const [recipientEmail, setRecipientEmail] = useState();
    const [recipientMessage, setRecipientMessage] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        if (recipientName && recipientEmail && deliveryDate) {
            setActiveRecipient({
                full_name: recipientName,
                email: recipientEmail,
                delivery_date: deliveryDate,
                message: recipientMessage,
            });
        } else setActiveRecipient(null);
    }, [recipientName, recipientEmail, deliveryDate, recipientMessage]);


    const clickHandler = (recipient) => {
        const _error = {};
        if (!recipient) return;
        if (!recipient.full_name) _error.full_name = "Recipient name is required";
        if (!recipient.email) _error.email = "Recipient email is required";
        else if (recipient.email){
            const email = recipient.email;
            const re = /\S+@\S+\.\S+/;
            if (!re.test(email)) _error.email = "Invalid email address";
        }
        if (!recipient.delivery_date) _error.delivery_date = "Delivery date is required";

        if (Object.keys(_error).length > 0) setError(_error);
        else {
            setError(null);
            if (props?.activeProduct?.variant_id && props?.activeProduct?.product_variants?.length>0){
                const _item = props.activeProduct.product_variants.filter(a=>a.id===props.activeProduct.variant_id)[0];
                _item.giftcard = {...recipient}
                click(_item, 4);
            }
        }
    }

    
    //if (!props?.type?.includes(12)) return null;

    return (
        <Modal className="pos-modal pos-p-modal events-modal" backdropClassName="pos-p-backdrop" show={props.show} onHide={props.hide} size="lg"  backdrop="static" data-cy="recipient-picker-modal">
            <Modal.Header closeButton={true}>
                <Modal.Title data-cy="event-picker-modal-title">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row data-cy="giftcard-picker-wrap">
                    <Col>
                        <Form.Group controlId="recipient_name">
                            <Form.Label>Recipient Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" onChange={e=>setRecipientName(e.target.value)} />
                            {error && error?.full_name && <div className="error-text mb-3">{error.full_name}</div>}
                        </Form.Group>
                        <Form.Group controlId="recipient_email">
                            <Form.Label>Recipient Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={e=>setRecipientEmail(e.target.value)} />
                            {error && error?.email && <div className="error-text mb-3">{error.email}</div>}
                        </Form.Group>
                        <Form.Group controlId="delivery_date">
                            <Form.Label>Delivery Date</Form.Label>
                            <br/>
                            <DatePicker
                                popperClassName={styles.picker}
                                dateFormat="MM/dd/yyyy"
                                minDate={new Date()}
                                maxDate={new Date(new Date().getFullYear()+1,12,31)}
                                showMonthDropdown
                                showYearDropdown
                                selected={deliveryDate}
                                onChange={date => setDeliveryDate(date)}
                                withPortal
                                customInput={
                                    <Button variant="light" className="datepicker-calendar" type="button">{format(new Date(deliveryDate), "MM/dd/yyyy")}</Button>
                                }
                            />
                            {error && error?.delivery_date && <div className="error-text mb-3">{error.delivery_date}</div>}
                        </Form.Group>
                        <Form.Group controlId="recipient_message">
                            <Form.Label>Message</Form.Label>
                            <Form.Control type="text" placeholder="Enter message" onChange={e=>setRecipientMessage(e.target.value)} />
                            {error && error?.message && <div className="error-text mb-3">{error.message}</div>}
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Body className="p-0">
                <Button className="pos-button-checkout m-0" disabled={!activeRecipient} onClick={()=>clickHandler(activeRecipient)}>Add to cart</Button>
            </Modal.Body>
        </Modal>
    );
}
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Button, Col, Row, Modal, Card }from 'react-bootstrap';

import './Notes.css'
import NoteModal from './NoteModal/NoteModal';
import Toast from '../../../../components/Toast';
import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import Stack from '../../../../components/common/Stack';
import { authCheck } from "../../../../utils/auth";
import { useRoleCheck } from'../../../../components/common/CustomHooks'

import NoteApi from '../../../../api/Notes';

const Notes = (props) => {
    let history = useHistory();
    //let user; //Viewing User, whereas props.user is user whose page is active
    const user = useSelector(state => state.auth.user);

    const mountedRef = useRef(false)
    const [ loading, setLoading ]=useState(true);
    const [ editHideShow, setEditHideShow ]=useState(false);
    const [ notes, setNotes ]=useState([]);
    const [ notesLoaded, setNotesLoaded]=useState(false);
    const [ activeNote, setActiveNote]=useState();
    const [ success, setSuccess ]=useState(null);
    const [ error, setError ]=useState(null);
    const [ confirmHideShow, setConfirmHideShow ]=useState(false);
    const [ confirmDelete, setConfirmDelete ]=useState(false);
    const [ isPatron, setIsPatron ]=useState(null);
    const userRole=useRoleCheck();


//#region CRUD

const determineViewability=useCallback(data=>{
    let filtered=[]
    // let deleted=[] //For "deleted" notes if needed in the future
    if(isPatron===false){
        data.forEach((each)=>{
            if(each.status ===1){ //Viewable by 'all' - the person the note is about,  author, admin, staff, IT
                if((userRole.id >0 && userRole.id <5) || user.profile?.id === props.user_id || user.profile?.id===each.author_user_id ){  //Author, the user, admins, staff
                    filtered.push(each)
                }
            }
            else if(each.status===2){ //viewable by admin+
                if((userRole.id >0 && userRole.id < 5) || user.profile?.id===each.author_user_id) filtered.push(each)
            }
            else if(each.status===3){ //viewable by author only
                if(user.profile?.id===each.author_user_id) filtered.push(each)
            }
            else if(each.status===5){//viewable by staff+
                if((userRole.id>0 && userRole.id<6) || user.profile?.id===each.author_user_id) filtered.push(each)
            }
            else if(each.status===6){ //viewable by the patron.  On this page, it's the same as all
                if((userRole.id>0 && userRole.id<4) || user.profile?.id===each.author_user_id) filtered.push(each);
            }
            // if(each.data[0].status===4){
            //     deleted.push(each)
            // } //For "deleted" notes if needed in the future
        })
    }else if(isPatron){
        data.forEach((each)=>{
            if(each.status ===1){ //Viewable by 'all' - the person the note is about,  author, admin, staff, IT
                if((userRole.id >0 && userRole.id <5) || user.profile?.id === props.user_id || user.profile?.id===each.author_user_id ){  //Author, the user, admins, staff
                    filtered.push(each)
                }
            }
            else if(each.status===3){ //viewable by author only
                if(user.profile?.id===each.author_user_id) filtered.push(each)
            }
        })
    }
    return filtered
},[isPatron,props.user_id,user,userRole.id]);

const getNotes=useCallback(async()=>{
    try{
        let filtered =[]
        let edited =[]
        // let response = await NoteApi.get() //Turn on for test call
        let response = await NoteApi.get({userId:props.user_id})
        if(response.data){
            let viewable=determineViewability(response.data)
            filtered.push(viewable)
            filtered[0].forEach((each)=>{
                let humanTime = convertTime(each.created_at)
                each.dateTime = humanTime
                let viewable = convertStatus(each.status)
                each.viewableBy = viewable
                edited.push(each)
            })
            setNotes(edited)
        }
        if(response.errors){
            if(response.errors[0] !== "User has no notes"){
                setError(<ErrorCatcher error={response.errors}/>)
            }
        }
    }
    catch(ex){
        console.error("Error getting notes: ", ex)
    }
    setError(null);
    setNotesLoaded(true);
    setLoading(false)
},[determineViewability, props.user_id]);

const deleteNote=useCallback(async()=>{
    const status={
        note: activeNote.note,
        id: activeNote.id,
        status: 4
    }
    if(confirmDelete === true && activeNote){
        try{
            let response= await NoteApi.edit(status)
            // let response= await NoteApi.edit()//Test
            if(!response.errors){
                deleteSuccess();
                getNotes();
            }else{
                setConfirmHideShow(false)
                setError(<ErrorCatcher error={response.errors}/>)
            }
        }catch(ex){
            setConfirmHideShow(false)
            setError(<ErrorCatcher error={ex}/>)
        }
        setConfirmDelete(false)
    }
},[activeNote, confirmDelete, getNotes]);

//#endregion CRUD


    useEffect(()=>{
        const checkPatron=()=>{
            let roleArray = []
            let patron = false;
            user?.roles && user.roles.forEach(role=>{
                roleArray.push(role.id)
            })
            if(user.profile?.id === props.user.id) patron = true
            else{
                if(user?.roles?.length === 1 && user?.roles[0].id === 7 ) patron = true
                else{
                    //staff roles that can view notes
                    if(roleArray.includes(3) || roleArray.includes(4) || roleArray.includes(5)) patron = false
                }
            }
            //even if SB is on their own profile, they can view notes
            if(roleArray.includes(1) || roleArray.includes(2)) patron = false
            setIsPatron(patron)
        }

        checkPatron();
    },[props.user.id,user]);

    useEffect(()=>{
        if(props.user_id && isPatron!==null){
            getNotes();
        }
    },[props.user_id, isPatron, getNotes])

    useEffect(()=>{
        if(isPatron !==null && !activeNote) getNotes();
    }, [isPatron, getNotes, activeNote])


    useEffect(()=>{
        if(confirmDelete){
            deleteNote();
        }
    }, [confirmDelete,deleteNote]);

//#region Handlers
    const editOnClick=(note)=>{
        setActiveNote(note);
        editModalHSOnClick();
    }

    const removeOnClick=(note)=>{
        setActiveNote(note);
        confirmModalHSOnClick();
    }

    const confirmDeleteYNonClick=(yn)=>{
        if(yn === "yes"){
            setConfirmDelete(true)
        }
        else{
            setConfirmDelete(false)
            setConfirmHideShow(false)
        }
    }

    const editModalHSOnClick =()=>{
        setEditHideShow(!editHideShow)
    }

    const confirmModalHSOnClick=(yn)=>{
        setConfirmHideShow(!confirmHideShow)
    }

    const ascendOnClick=()=>{
        let ascend = notes.slice().sort(function compare(a,b){return new Date(a.created_at) - new Date(b.created_at)}) //ascend notes          
        setNotes(ascend)
    }

    const descendOnClick=()=>{
        let descend = notes.slice().sort(function compare(a,b){return new Date(b.created_at) - new Date(a.created_at)}) //descend notes
        setNotes(descend)    
    }

//#endregion Handlers

//#region Utility Functions

    const createEditSuccess=(msg)=>{
        setEditHideShow(false)
        setSuccess(<Toast> {msg}</Toast>)
        setActiveNote()
        getNotes();
    }

    const deleteSuccess=()=>{
        setConfirmHideShow(false);
        setSuccess(<Toast>You deleted your note successfully!</Toast>)
    }

    const convertTime=(data)=>{
       let computerTime = Date.parse(data);
       let humanDate = new Date(computerTime).toLocaleString(
        "en-US",{
            month: "2-digit",
            day: "2-digit",
            year: "2-digit"});
        let humanTime = new Date(computerTime).toLocaleString(
            "en-US",{
                hour:"2-digit",
                minute:"2-digit",
                hour12: true,
            })
        return {humanDate, humanTime}
    }

    const convertStatus=(data)=>{
        let text;
        switch(data){
            case 1:
                text= "All";
                break;
            case 2: 
                text="Admin";
                break;
            case 3:
                text="Author";
                break;
            case 5:
                text="Staff"
                break;
            case 6:
                text="IT/Support"
                break;
            default:
                return text=""
        }
        return text
    }

//#endregion Utility Function 
    if(loading) return(
        <SkeletonTheme color="#e0e0e0">
            <div className="mt-3 text-center" data-cy="loading-skeleton-div">
                <Skeleton height={28} width={200}/>
                <Skeleton height={16} count={4} />
            </div>
        </SkeletonTheme>
    ) 

    return (
        <>
        <Container fluid>
                <h4 className="section-title order-2 order-lg-1">Notes</h4>
                <hr/>
                <div className="ms-sm-auto d-flex justify-content-end order-1 order-lg-2 mb-2">
                    {isPatron ? null : <Button onClick={editModalHSOnClick} className="btn-primary ms-sm-auto" data-cy="new-note-btn">New Note</Button>}
                </div>
            {success}
            <Row>
                <Col sm={6}>
                    <>
                    {notesLoaded && notes.length>0 ?
                        <>
                            <span>Sort Notes by Date</span>
                            <br />
                            <i className="far fa-chevron-double-up mr-4" onClick={ascendOnClick} data-cy="note-asc-btn"></i>
                            <i className="far fa-chevron-double-down"onClick={descendOnClick} data-cy="note-desc-btn"></i>
                            <br />
                        </>
                            :
                            <span></span>
                    }
                    </>
                </Col>
                <Col sm={6} className={`d-flex justify-content-end`}>                    
                    <Modal show={editHideShow} onHide={editModalHSOnClick} size="lg" data-cy="note-edit-modal">
                        <Modal.Header closeButton>
                            <h5>Note Content</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <NoteModal convertStatus={convertStatus} createEditSuccess={createEditSuccess} closeModal={editModalHSOnClick} user={user} id={props.user_id} note={activeNote}/>
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
            <Row>
                <Col>
                {notesLoaded && 
                <>
                    <div data-cy="note-map-div">
                        {notes.length > 0 ? 
                            <>
                                {notes.map((note)=>{
                                    return(
                                        <React.Fragment key={note.id}>
                                            <Card className="mt-1 mt-1">
                                                <Row>
                                                    <Col>
                                                        <p>
                                                            {note.dateTime.humanDate}
                                                            <br/>{note.dateTime.humanTime}
                                                            <br/>by {note.author_first_name} {note.author_last_name}
                                                            <br/>viewable by {note.viewableBy}
                                                        </p>
                                                    </Col>
                                                    <Col>
                                                        <p>{note.note}</p>
                                                    </Col>
                                                </Row> 
                                                <Row className="d-flex justify-space-between">
                                                    {note?.author_user_id === user?.profile?.id ? <Button onClick={()=>editOnClick(note)}>Edit</Button> : null}
                                                    {(userRole.id < 5 || userRole.id===1) && <Button variant="danger" onClick={()=>removeOnClick(note)}>Delete</Button>}
                                                </Row>
                                            </Card>
                                        </React.Fragment>
                                    );
                                })}
                            </>
                        :
                            <p> There are currently no notes for this user.</p>
                        }
                    </div>
                </>
                }
                </Col>
            </Row>
            <Modal show={confirmHideShow} onHide={confirmModalHSOnClick} >
                <Modal.Body>
                    <h5>Are you sure you want to delete this note {activeNote ? <span>by {activeNote.author_first_name} {activeNote.author_last_name} </span> : <span> : </span>}</h5>
                    <p>{activeNote && activeNote.note}</p>
                    <Button onClick={()=>confirmDeleteYNonClick("yes")}>Yes</Button>
                    <Button variant="secondary" onClick={()=>confirmDeleteYNonClick("no")}>No</Button>
                </Modal.Body>
            </Modal>
        </Container>
        {error}
        </>
    )
}
export default Notes;
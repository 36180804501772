import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';

import ErrorCatcher from '../common/ErrorCatcher';

import './CheckAppVersion.scss';

const versionFile = require("../../version-number.txt");

const DELAY_MINUTES = 15;

/** Pop up modal that advises users that they are using an older version of the site and need to reload
*/
export const CheckAppVersion = () => {

    const history = useHistory();

    const [show, setShow] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [delayUntil, setDelayUntil] = useState(false);


    const handleRefresh = () => {
        setShow(false);
        window.location.reload(false);
    }

    const handleDelay = () => {
        // this code will create a new date object DELAY_MINUTES minutes from now
        let date = new Date();
        date.setMinutes(date.getMinutes() + DELAY_MINUTES);
        setDelayUntil(date);
        setShow(false);
    }

    const checkVersion = useCallback(() => {

        // this is the version number from the .env file which I believe only changes at build - check to see if this is true
        // let currentVersion = process.env.REACT_APP_VERSION; 

        fetch(versionFile)
        .then((response) => response.text())
        .then(text => {
            let currentVersion = text;

            if (currentVersion) {

                // check delay timer, if it's expired then erase it and trigger the prompt again
                if (delayUntil && delayUntil < new Date()) {
                    setDelayUntil(false);
                }

                // if there's no delay timer active then check the version number
                if (!delayUntil) {
                    if (!localStorage.getItem("version") || firstLoad){
                        localStorage.setItem("version", currentVersion);
                    } else if (String(localStorage.getItem("version"))!==String(currentVersion)) {
                        setShow(true);
                    }
                }
            }

            setFirstLoad(false);
        });

    },[delayUntil, firstLoad]);

    useEffect(() => {
        // check version number on every router location change
        const unlisten = history.listen(checkVersion);

        return () => unlisten();
    }, [history, checkVersion]);

    const handleCloseModal = () => {
        setShow(false);
    }

    return (
    <>
        <Modal centered backdrop="static" size={'xl'} show={show} onHide={handleCloseModal}>
            <Modal.Header closeButton />
            <Modal.Body className="version-prompt">
                <div>
                    A new version is available.  Please refresh the page to get the latest version.
                </div>
                
                <div className="buttons">
                    <Button variant="primary" className="now-btn" onClick={handleRefresh}>
                        Refresh Now!
                    </Button>
                    <Button variant="secondary" className="later-btn" onClick={handleDelay}>
                        I'll do it later
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    </>
    )

}
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Card, Button } from "react-bootstrap";
import { Buffer } from 'buffer';
import SubHeader from "../../../components/common/SubHeader";
import Groups from "../../../api/Groups";

import "./AcceptInvitation.scss";

export const AcceptInvitation = props => {
    let history = useHistory();

    const [msg, setMsg] = useState("Processing invitation...");
    const [userID, setUserID] = useState();
    const [groupID, setGroupID] = useState();
    const [ready, setReady] = useState(false);
    const [timer, setTimer] = useState(1000);

    const authUser = useSelector(state => state.auth.user);

    // delay to avoid duplicate call
    // fixing whatever is triggering the duplicate call would be much better
    useEffect(() => {
        setTimer(setTimeout(() => setReady(true), 500));

        return () => clearTimeout(timer);
    //having the timer as a dependcy here creates an endless loop
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // get group and user IDs from query string, get group from API
    useEffect(() => {
        let mounted = true;

        if(ready) {
            let queryStr = new URLSearchParams(window.location.search);
            let group_id = Buffer.from(queryStr.get("g"), "base64").toString();
            let user_id = Buffer.from(queryStr.get("u"), "base64").toString();

            if(group_id && user_id) {
                setUserID(user_id);
                setGroupID(group_id);
            }
        }
        
        return () => mounted = false;
    },[ready])

    // once group and user ID are set, accept the invitation
    useEffect(() => {
        let mounted = true;

        if(groupID && userID) {
            Groups.accept_invitation({ group_id: groupID, user_id: userID})
            .then( response => {
                if(mounted) {
                    if(!response.errors) {
                        if (response.data?.user && response.data?.group) {
                            setMsg(`${response.data.user.first_name} successfully joined ${response.data.group.name}!`);
                        } else {
                            setMsg(`Successfully joined group!`);
                        }
                    } else if (response.errors) {
                        setMsg("Error: " + response.errors[0]);
                    } else {
                        setMsg("Error: " + response);
                    }
                }
            }).catch(e => console.error(e));
        }

        return () => mounted = false;
    },[groupID, userID]);

    return (
        <div>
            <SubHeader items={[
                { linkAs: Link, linkProps: { to: "/p/" }, text: "Home" }
            ]} />
            <section className="banner-wrapp bg-banner">
                <Card className="content-card">
                    {msg}
                    <Button className="mr-t20" onClick={() => history.push("/p/")}>{authUser ? 'Ok' : 'Login'}</Button>
                </Card>
            </section>
        </div>
    )
}
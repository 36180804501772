import React, { useState, useEffect, useRef } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { format } from 'date-fns';
import Spinner from '../../../../components/Spinner';
import { authUserHasModuleAccessMany } from "../../../../utils/auth";

import UsersAPI from '../../../../api/Users';

const VIEW_MODULE_ID = 184; //"View a User's Checkins"

export const CheckinHistory = (props) => {

    const urlRef = useRef();
    const mountedRef = useRef(false);
                
    const [ error, setError ] = useState();
    const [ checkins, setCheckins ] = useState();
    const [ loading, setLoading ] = useState(true);
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);

    //First load
    useEffect(()=>{
        mountedRef.current = true;

        loadHistory(props.user_id);
        
        return()=> mountedRef.current = false;
    },[props.user_id]);

    // check if auth user has permission to view this page for this user
    useEffect(() => {
        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccessMany([VIEW_MODULE_ID], props.user_id);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }
        checkPermission();
    }, [props.user_id]);

    const loadHistory = async (user_id) => {
        // send the user_id to get all checkins
        await UsersAPI.Checkin.get({
            user_id: user_id,
        }).then(async response => {
            if (!response.errors) {
                setCheckins(response.data);
            } else {
                console.error(response.errors);
            }
        }).catch(e => console.error(e));
        setLoading(false);
    }

    if(loading) return(
        <SkeletonTheme color="#e0e0e0">
            <div className="mt-3 text-center" data-cy="loading-skeleton-div">
                <Skeleton height={28} width={200}/>
                <Skeleton height={16} count={4} />
            </div>
        </SkeletonTheme>
    )
    
    return (
        <Container className="checkin">
        <h4 className="section-title">Check In History</h4>
        <hr/>
        {userHasModulePermission===null ?
            <><Spinner /></>
        :
        !userHasModulePermission[VIEW_MODULE_ID] ?
            <p>You do not have permission to view this user's checkin history.</p>
        :
            <Row>
                <Col sm={12}>
                    {error && <p>{error}</p>}

                    {checkins && checkins.length===0 && 
                        <p>No checkins found for this user</p>
                    }
                    
                    {props?.user && checkins &&
                    <div className="checkin-grid">
                        {checkins.map(checkin => 
                            <React.Fragment key={`checkin-${checkin.checkin_at}`}>
                                <div>{format(new Date(checkin.checkin_at), "MM/dd/yyyy")} @ {format(new Date(checkin.checkin_at), "hh:mm aa")}</div>
                            </React.Fragment>
                        )}
                    </div>
                    }
                </Col>
            </Row>
        }
      </Container>
    
  )
}
import React,{useState, useEffect, useCallback} from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import FormGroupText from '../FormGroupText';
import FormGroupButton from '../FormGroupButton';
import Forgot from '../Forgot';
import ResetPassword from '../ResetPassword';
import { attemptAuth, logout } from '../../../../../utils/thunks';

const Login =  React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }
    
    const user = useSelector(state => state.auth.user);
    const [error, setError] = useState();
    const [validated, setValidated] = useState(false);    
    const [submitting, setSubmitting] = useState(false);
    const [body, setBody] = useState();

    useEffect(() => {
        return () => {
            setError(null);
            setValidated(false);
            setSubmitting(false);
            setBody(null);
        }
    }, []);

    const forgotHandler = (e, type) => {
        e.preventDefault();
        e.stopPropagation();

        setBody(
            <Forgot type={type} close={()=>setBody(null)} />
        );
    }

    const resetHandler = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        setBody(
            <ResetPassword close={()=>setBody(null)} />
        );
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSubmitting(true);
        setValidated(true);

        window.history.pushState({}, document.title, window.location.pathname);

        const form = e.currentTarget;
        if (form.checkValidity() === true) {

            const formData = new FormData(form);
            formData.append("rememberme", 1); // remove when checkbox is added
            const formDataObj = Object.fromEntries(formData.entries());
            let path = props.redirect_to || null;

            dispatch(attemptAuth(formDataObj.username,formDataObj.password,formDataObj.rememberme, path));
        }
        setSubmitting(false);
    }

    const logoutHandler = useCallback((e) => {
        dispatch(logout(history, false));
    },[dispatch, history]);

    useEffect(() => {
        if (window.location.search.includes("reset-password")){
            resetHandler({preventDefault:()=>{}, stopPropagation:()=>{}});
        }
    }, [resetHandler]);

    if (user?.profile?.id && !props.is_preview){
        return (
            <>
                <h1>Oops!</h1>
                <p>You're already logged in.</p>
                <p>
                    <a href="/">Go Back Home</a><br/>
                    <a href="#!" onClick={logoutHandler}>Log Out</a>
                </p>
            </>
        );
    }

    if (body) return body;

    return (
        <Form
            ref={ref}
            onSubmit={submitHandler} 
            className={`login_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' ')}
            style={props.style} 
            onClick={preview_click}
            noValidate 
            validated={validated}>
            <Row>
                <Col sm={12}>
                    <FormGroupText page_id={props.page_id} name="username" type="text" label="User" required feedback="Please enter your user name" disabled={submitting} />
                </Col>
                <Col sm={12}>
                    <FormGroupText page_id={props.page_id} name="password" type="password" label="Password" required feedback="Please enter your password" disabled={submitting} />
                </Col>
                <Col sm={12} md={true}>
                    <FormGroupButton page_id={props.page_id} label="Sign In" disabled={submitting} click={props.is_preview?"Button":"Submit Form"} />
                </Col>
                <Col sm={12} md={"auto"}>
                    <a href="#!" onClick={e=>forgotHandler(e)}>Forgot your password?</a><br/>
                    <a href="#!" onClick={e=>forgotHandler(e, 1)}>Forgot your user name?</a>
                </Col>
                {error && 
                    <Col sm={12} className="error-text-ctr">
                        {error}
                    </Col>
                }
            </Row>
        </Form>
    );
});

export default Login;
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { randomUUID, checkBoolean, localGroup, toKebabCase } from '../../../../../utils/cms';

const FormGroupText =  React.forwardRef((props, ref) => {
    const {onChange, onBlur} = props;
    const bkRef = useRef();

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [value, setValue] = useState(props?.value || "");

    useEffect(() => {
        if (props?.value) setValue(props.value);
    }, [props?.value]);

    useEffect(() => {
        if (props.id && bkRef.current){
            localGroup(bkRef.current.querySelector("input"), props.id);
        }
    }, [bkRef, props.id]);

    useEffect(() => {
        return () => {
            //setValue("");
        }
    }, []);

    const validateField = useCallback((e) => {
        if (props?.pattern){
            let _pattern, _length;
            let re = new RegExp(/\{(\d+)\}/);
            const found = props.pattern.match(re);
            if (found && found.length>1) {
                _length = found[1].replace("{", "").replace("}", "");
                _pattern = props.pattern.replace(re, "");
                if (isNaN(_length)) {
                    _length = null;
                    _pattern = props.pattern;
                }
            } else _pattern = props.pattern;
            re = new RegExp(_pattern);
            if (!re.test(e.target.value)) return false;
            if (_length && e.target.value.length>_length) return false;
        }
        return true;
    }, [props.pattern]);

    const changeHandler = useCallback((e) => {
        if (!validateField(e)) return;
        setValue(e.target.value);
        localGroup(e.target, props.id);
        if (onChange) onChange(e);
    }, [props.id, validateField, onChange]);

    const blurHandler = useCallback((e) => {
        if (!validateField(e)) return;
        setValue(e.target.value);
        localGroup(e.target, props.id);
        if (onBlur) onBlur(e);
    }, [props.id, validateField, onBlur]);

    const _disabled = checkBoolean(props?.disabled) || false;
    const _readonly = checkBoolean(props?.readonly) || false;
    const _required = checkBoolean(props?.required) || false;

    return (
        <Form.Group 
            ref={ref || bkRef} 
            className={`form-group-text_${props.page_id} ${props.className || ""}`} 
            style={props.style}
            controlId={`${toKebabCase(props?.name) || toKebabCase(props?.label)}-${randomUUID()}`}
            onClick={preview_click}

            >
                <Form.Label>{props?.label || ""}</Form.Label>
                <InputGroup hasValidation>
                    {props?.prepend && (
                        <InputGroup.Prepend>
                            <InputGroup.Text>{props.prepend}</InputGroup.Text>
                        </InputGroup.Prepend>
                    )}
                    <Form.Control 
                        ref={props.inputRef || null}
                        type={props?.type || "text"} 
                        name={props?.name || toKebabCase(props?.label) || randomUUID()} 
                        value={value} 
                        onChange={changeHandler}
                        onBlur={blurHandler} 
                        placeholder={props?.placeholder || ""} 
                        disabled={_disabled}
                        readOnly={_readonly}
                        required={_required}
                        isInvalid={props?.isInvalid || false}
                        maxLength={props?.maxLength || null}
                        //pattern={props?.pattern || null}
                    />
                    {props?.append && (
                        <InputGroup.Append>
                            {typeof props.append === "object" && props.append}
                            {typeof props.append === "string" &&
                                <InputGroup.Text>{props.append}</InputGroup.Text>
                            }
                        </InputGroup.Append>
                    )}
                    <Form.Control.Feedback type="invalid">
                        {props?.feedback || "This field is required"}
                    </Form.Control.Feedback>
                </InputGroup>
        </Form.Group>
    );
});

export default FormGroupText;
import React, { useState, useEffect, useContext, createRef, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';

import SingleUpload from '../../../components/Uploader';
import ErrorCatcher from '../../../components/common/ErrorCatcher';
import { FileURLContext } from '../../../contexts/FileURLContext';
import Users from '../../../api/Users';

export const ProfileCard = (props) => {
    //let history = useHistory();

    const imageURL = useContext(FileURLContext);
    const nopic = imageURL.noPic;
    const mountedRef = useRef(false)
    const dzRef = createRef();

    const [user, setUser] = useState();
    const [profilePic, setProfilePic] = useState();
    const [errors, setErrors] = useState();

    useEffect(()=>{
        mountedRef.current = true

        return () =>{
            mountedRef.current = false
            setProfilePic();
        } 
    },[])

    useEffect(() => {
        if(props.user && mountedRef.current) {
            setUser(props.user);
            props.user.hasOwnProperty('profile_img_path') ? setProfilePic(props.user.profile_img_path) : setProfilePic(nopic);
        }
    },[props, nopic]);

    const onSendUpload=async(imgData)=>{
        setErrors();
        if(imgData){
            imgData.append('user_id', user.id);
            imgData.append('type', 2);
            imgData.append('description', 'Profile Picture');
            try{
                let response = await Users.upload(imgData)
                if(response.errors && response.status !== 200){
                    setErrors(<ErrorCatcher error={response.errors} />)
                }
            }catch(ex){console.error(ex)}
        }
    }

    return (
        <React.Fragment>
            {errors}
            <div className="profile-card">
                {props.components && props.components.map((component,i) => (
                    <Row key={`component.${i}`}>
                        <Col>
                            {component}
                        </Col>
                    </Row>
                ))}
                <Row>
                    <Col md="12" className="d-flex justify-content-center">
                        <div style={window.innerWidth >= 400 ? {width: "350px"} : {width: "200px"}}>
                            <SingleUpload 
                                DZRef={dzRef}
                                type="image/*, .heic, .heif"
                                previewHeight={window.innerWidth >= 400 ? "350px" : "200px"}
                                previewPadding="1rem"
                                backgroundSize="contain"
                                previewSrc={profilePic}
                                onSend={onSendUpload}
                            >
                            </SingleUpload>
                        </div>
                    </Col>
                    <Col md="12">
                        <h5 className="profile-name">{user?.first_name} {user?.last_name}</h5>
                    </Col>
                    <Col md="12">
                        <label className="f-bld">Email: &nbsp;</label>
                        <span className="label-desc">{user?.email}</span>
                    </Col>
                    <Col md="12">
                        <label className="f-bld">Mobile Number: &nbsp;</label>
                        <span className="label-desc">{user?.mobile_phone}</span>                         
                    </Col>
                    <Col md="12">
                        <div align="center">
                            <Button className="btn btn-profile" href="/p/profile">Manage Profile</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}
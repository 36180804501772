import React, {useState, useEffect, useRef, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap';

import '../../../../../../../src/assets/css/scss/pages.scss'
import ErrorCatcher from '../../../../../../components/common/ErrorCatcher';
import Toast from '../../../../../../components/Toast';
import CCPayment  from '../../../CCPayment';
import * as actions from '../../../../../../store/actions'
import { refreshOrder } from '../../../../../../utils/thunks';
import { checkTransaction } from '../../../../../../utils/checkTransactionStatus';
import ScanCard from '../../../ScanCard';


export const SplitPaymentCC = (props) => {

    // destructure props
    const { partialPaid, onClose, register, terminalDeviceId, currentOrder,adjustPayeeStatus,amount,payee,type,selectedUser,goTo} = props;

    const dispatch = useDispatch();

    const mountedRef = useRef(false);
    const successMsg="Credit Card portion was submitted."
    const splitPaymentStore = useSelector(state=> state.splitPayments);
    const [allPaid, setAllPaid]=useState(useSelector(state=> state.splitPayments.creditCardSuccess));
    const [error, setError]=useState();
    const [success, setSuccess]=useState();
    const [submitting, setSubmitting]=useState();
    const [userDetails, setUserDetails]=useState();
    const [transactionId, setTransactionId]=useState();
    const [transactionStatus, setTransactionStatus]=useState();
    const [disableSubmit, setDisableSubmit]=useState(false);
    const [memo, setMemo]=useState("");
    const [localOrder, setLocalOrder]=useState() //the order for this component

    const intervalTime = 5000;

     //checks for any reason to disable the submit button;
     const checkDisableSubmit=useCallback(()=>{
        let disabled = false;
        if(submitting) disabled=true;
        if(splitPaymentStore.creditCardSuccess) disabled=true;
        if(props.type==="multiSingleSplit" && props.payee.paid) disabled=true
        if(mountedRef.current) setDisableSubmit(disabled)
    //cannot include props.payee.paid in the dependency array because it doesn't always exist
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[submitting, splitPaymentStore, props.type]);

//#region useEffect
    //First load
    useEffect(()=>{
        mountedRef.current=true;

        return()=>{
            mountedRef.current = false;
        }
    },[]);

    useEffect(()=>{
        if(splitPaymentStore.allOrders.length > 0){
            let card = splitPaymentStore.allOrders.filter((order)=>{
                if(order?.memo ==="Card Portion") return order;
                return null;
            })
            setLocalOrder(card[0])
        }
        else setLocalOrder(props.currentOrder) 

        if(splitPaymentStore.creditCardSucces) setAllPaid(true);
    },[props.currentOrder, splitPaymentStore]);

    //sets success if the user comes back from the next step
    useEffect(()=>{
        if(splitPaymentStore.creditCardSucces) setSuccess(true);
    },[splitPaymentStore])

    useEffect(()=>{
        checkDisableSubmit();
    }, [checkDisableSubmit]);

    //to handle after a transaction status has been updated/changed
    const transactionFollowUp=useCallback((status)=>{
        if(!status.errors && status===7){
            setSuccess(true)
            if(props.type==="multiSingle" || props.type==="multiMany") adjustPayeeStatus("success", "credit card", props.payee, transactionId, null)
            if(props.type==="singleUserSplit") dispatch(actions.setSplitPaymentCCSuccess(true));
            setTransactionStatus(status.transactionStatus)
            dispatch(refreshOrder(props.register));
        }else{
            dispatch(actions.setSplitPaymentCCSuccess(false))
            setTransactionStatus(status.transactionStatus)
            setError(<ErrorCatcher error={status.error} />)
        }
    },[props.type, props.payee, transactionId, dispatch,adjustPayeeStatus, props.register]);

    useEffect(()=>{
        const interval=setInterval(()=>{
            if(mountedRef.current && transactionId && transactionStatus){
                let transStatus=checkTransaction(transactionStatus, transactionId);
                transactionFollowUp(transStatus);
            } 
        }, intervalTime);


        return()=> clearInterval(interval)
    },[transactionId, transactionStatus, transactionFollowUp]);

    useEffect(()=>{
        if(transactionStatus===7 && mountedRef.current) {
            setSuccess(<Toast>{successMsg}</Toast>)
            setAllPaid(true)
        };
    },[transactionStatus]);

    //after success is changed for any reason, sets this state that disables the fields
    useEffect(()=>{
        if(success) setAllPaid(true)
    },[success]);

//#endregion useEffect

//#region functions

    //passed into the payment component to handle the initial response
    const paymentFollowUp=(response)=>{
        if(!response.errors){
            let transactionId=response.data[0].transaction_id
            dispatch(refreshOrder(props.register))
                .then(()=>{
                    setSubmitting(false);
                    setTransactionId(transactionId);
                    setTransactionStatus(response.data[0].transaction_status_id)
                })
        }
        if(response.errors){
            setError(<ErrorCatcher error={response.errors} />)
            setTransactionStatus(8)
        }
    }



    //a function to bypass the cc payment
    const bypassCC=()=>{
        setSubmitting(false)
        setSuccess(true)
        dispatch(actions.setSplitPaymentSubmitting(false));
        if(props.type==="singleUserSplit"){
            dispatch(actions.setSplitPaymentCCSuccess(true));
        }
        if(props.type==="multiSingle" || props.type==="multiMany"){
            props.adjustPayeeStatus("success", "credit card", props.payee, 1234, null);
        }
        props.goTo('Confirmation');
    }
//#endregion functions


  return (
    <>
        <div className="split-payment-single-cc">
            <div className="d-flex justify-content-between">
                <h5>Credit Card Payment</h5>
                <ScanCard 
                    terminalDeviceId={props.terminalDeviceId}
                    orderId={props.currentOrder ? props.currentOrder.id : null}
                    // userId={props.selectedUser.id}
                    user={props.selectedUser}
                    type={props.type}
                    adjustPayeeStatus={adjustPayeeStatus}
                />
                <Button type="button" onClick={()=>props.onClose()} variant="light">Cancel Split Payment</Button>
            </div>
            <br />
            {props.type==="singleUserSplit" ?
                <>
                    <h5>Remaining Balance for Card: ${localOrder?.total_price?.toFixed(2)}</h5> 
                    {/*<h6>Admin Fee: ${localOrder?.admin_fee_total?.toFixed(2)}</h6>*/}
                </> 
            : null
            }
            {props.type==="multiSingle" ?
                <>
                    <h5>Payment for {props?.payee?.identifier}</h5>
                    <h5>Payment Amount: ${props?.amount}</h5>
                </>
            :null
            }
            <br />
            <fieldset disabled={allPaid}>
                <input className="sp-memo" name="memo" value={memo} onChange={e => setMemo(e.target.value)} placeholder={"Memo"} />
                <CCPayment 
                    disabled={allPaid}
                    order={localOrder} 
                    passUser={setUserDetails} 
                    register_id={+props.currentOrder?.register_id} 
                    user={props.selectedUser}
                    paymentMethod={"SplitPayment"}
                    bypassCC={bypassCC}
                    memo={memo}
                    paymentFollowUp={paymentFollowUp}
                />
                <br />
                <div className="sp-error">
                    <p>{splitPaymentStore.errors?.ccSubmitting}</p>
                    <p>{splitPaymentStore.errors?.ccNonSuccess}</p>
                </div>
                <div className="sp-success">
                    {splitPaymentStore.creditCardSuccess && 
                    <span>{successMsg}</span>
                    }
                </div>
                {error}
                {success}
            </fieldset>
        </div>
    </>
  )
}

import React, {useEffect, useState} from 'react';
import {Form, Container, Row, Col} from 'react-bootstrap';

export const CustomFields = (props) => {
    const [fieldValues, setFieldValues] = useState({});

    const changeHandler = (e) => {
        const newValues = {...fieldValues};
        const id = e.target.name.split(".");
        newValues[id[1]] = e.target.value;
        setFieldValues(newValues);
        props.onChange(newValues);
    }

    if (!props.custom_fields || !props.member) return null;
    return (
        <Container fluid>
            <Row>
                <Col>
                    <h4>Custom Fields for {props.member.first_name}</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.custom_fields.filter(a=>a.placeholder_text).map((item, i) =>(
                        <Form.Group controlId={`${item.name}_${props.member.id}`} key={`pos-custom-field-group-${props.id}-${props.member}-${item.id}-${i}`}>
                            <Form.Label>{item.placeholder_text}</Form.Label>
                            {item.custom_field_type_id === 2 &&
                                <Form.Control 
                                    as="select" 
                                    size="lg"
                                    custom 
                                    name={`custom.${item.name}.${props.member.id}`} 
                                    placeholder={item.placeholder_text} 
                                    required={item.required?true:undefined} 
                                    onChange={changeHandler} 
                                    onBlur={changeHandler}
                                    //value={props.stepValues?.customFields?.[props.member.id]?.find(f => `${f.custom_field_id}` === `${item.name}`)?.value || ""} 
                                >
                                    <option key={`custom-select-${item.id}-${props.member.id}-x`} value="" /*onClick={clickHandler}*/></option>
                                    {item?.options && item?.options?.map((option, i) => (
                                        <option key={`custom-select-${item.id}-${props.member.id}-${i}`} value={option.value} /*onClick={clickHandler}*/>{option.text}</option>
                                    ))}
                                </Form.Control>
                            }
                            {item.custom_field_type_id === 1 &&
                                <Form.Control 
                                    type="text" 
                                    size="lg"
                                    required={item.required?true:undefined} 
                                    name={`custom.${item.name}.${props.member.id}`}
                                    placeholder={item.placeholder_text}                                
                                    //defaultValue={props.stepValues?.customFields?.[props.member.id]?.find(f => `${f.custom_field_id}` === `${item.name}`)?.value || ""} 
                                    onChange={changeHandler} 
                                    onBlur={changeHandler} 
                                />
                            }
                        </Form.Group>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import QRCode from "react-qr-code";

import './DisplayQRCode.scss';

export const DisplayQRCode = ({ user_id }) => {

    const [show, setShow] = useState(false);
    const [pagePart, setPagePart] = useState();
    const [codeWidth, setCodeWidth] = useState(256);

    useEffect(() => {
        let maxCodeWidth = 280;
        let margin = 70;
        setCodeWidth((window.innerWidth - margin) > maxCodeWidth ? maxCodeWidth : window.innerWidth - margin);
    },[]);

    const handleOpenQRDisplay = () => {
        setShow(true);
    }

    const handleCloseQRDisplay = () => {
        setShow(false);
    }

    if (user_id) return (
        <>
            <div className="float-right qr-code-icon">
                <Button onClick={handleOpenQRDisplay}><i className="far fa-qrcode"></i></Button>
            </div>

            <Modal centered show={show} className="qr-code-display" onHide={handleCloseQRDisplay} animation={false} fullscreen={'xl-down'} size={'xl'}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <QRCode value={`${window.location.href}users/${user_id}`} size={codeWidth} />
                </Modal.Body>
            </Modal>
        </>
    )

    return (
        <>
        </>
    );    
}
export const htmlProperties = [
    {
        id: 999,
        name: "value",
        description: "The text / html value of the element",
        display_name: "Text / HTML",
        type: "textarea",
        default_value: "",
        elements: ["*"],
        prop_type: "value",
    },
];

export const logicProperties = [
    {
        id: 555,
        name: "logic",
        description: "Display logic for the element",
        display_name: "Conditional Logic",
        type: "textarea",
        default_value: "",
        elements: ["*"],
        hidden: true,
        prop_type: "logic",
    },
];

export const basicProperties = [
    {
        name: "color",
        description: "Sets the color of the text for the element",
        display_name: "Text Color",
        type: "color",
        default_value: "#000000",
        elements: ["*"],
        group: "Text",
        prop_type: "basic",
    },
    {
        name: "font-weight",
        description: "How thick or thin the text should be displayed",
        display_name: "Thickness",
        type: "range",
        min: 300,
        max: 900,
        step: 100,
        default_value: "400",
        elements: ["*"],
        group: "Text",
        prop_type: "basic",
    },
    {
        name: "font-size",
        description: "Sets the size of the text",
        display_name: "Size",
        type: "select",
        options: [
            {value: "0.55rem", text: "Extra Small"},
            {value: "0.75rem", text: "Small"},
            {value: "1rem", text: "Normal"},
            {value: "1.5rem", text: "Large"},
            {value: "2rem", text: "XL"},
            {value: "3rem", text: "XXL"},
        ],
        elements: ["*"],
        group: "Text",
        prop_type: "basic",
    },
    {
        name: "background-type",
        description: "Sets the background type for the element",
        display_name: "Background Type",
        type: "select",
        options: ["basic","gradient"],
        value: "basic",
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
    },
    {
        name: "background-color",
        description: "Sets the background color of the element",
        display_name: "Color",
        type: "color",
        default_value: "#ffffff",
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        condition: {
            name: "background-type",
            value: "basic",
        },
    },
    {
        name: "background-image",
        description: "Sets the background image of the element",
        display_name: "Image",
        type: "text",
        value: "",
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        condition: {
            name: "background-type",
            value: "basic",
        },
        media: true
    },
    {
        name: "background-position",
        description: "Sets the starting position of a background image",
        display_name: "Position",
        type: "select",
        value: "center center",
        options: ["center center","center left","center right","top center","top left","top right","bottom center","bottom left","bottom right"],
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        condition: {
            name: "background-type",
            value: "basic",
        },
    },
    {
        name: "background-repeat",
        description: "Sets if/how a background image will be repeated",
        display_name: "Repeat",
        type: "select",
        value: "no-repeat",
        options: ["no-repeat","repeat","repeat-x","repeat-y"],
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        condition: {
            name: "background-type",
            value: "basic",
        },
    },
    {
        name: "background-size",
        description: "Sets the size of the background image",
        display_name: "Size",
        type: "select",
        value: "cover",
        options: ["auto","cover","contain"],
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        condition: {
            name: "background-type",
            value: "basic",
        },
    },
    {
        name: "background-attachment",
        description: "Sets whether a background image is fixed or scrolls with the rest of the page",
        display_name: "Attachment",
        type: "select",
        value: "scroll",
        options: ["scroll","fixed"],
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        condition: {
            name: "background-type",
            value: "basic",
        },
    },
    {
        name: "background-blend-mode",
        description: "Sets the blend mode of the element",
        display_name: "Blend Mode",
        type: "select",
        options: [
            {value: "normal", text: "Normal"},
            {value: "multiply", text: "Multiply"},
            {value: "screen", text: "Screen"},
            {value: "overlay", text: "Overlay"},
            {value: "darken", text: "Darken"},
            {value: "lighten", text: "Lighten"},
            {value: "color-dodge", text: "Color Dodge"},
            {value: "color-burn", text: "Color Burn"},
            {value: "hard-light", text: "Hard Light"},
            {value: "soft-light", text: "Soft Light"},
            {value: "difference", text: "Difference"},
            {value: "exclusion", text: "Exclusion"},
            {value: "hue", text: "Hue"},
            {value: "saturation", text: "Saturation"},
            {value: "color", text: "Color"},
            {value: "luminosity", text: "Luminosity"},            
        ],
        value: "normal",
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        condition: {
            name: "background-type",
            value: "basic",
        },
    },    
    {
        name: "background-image",
        type: "custom",
        description: "Sets the gradient background of the element",
        display_name: "Gradient",
        value: "",
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        condition: {
            name: "background-type",
            value: ["gradient"]
        },
        source:{
            component: "containers/Cms/Components/Properties/Gradient",
            props: {
                gradient: "{value}", // this is the value from up there... because its dinamic, we wrap it in curly braces so it grabs the value from the json
                selection: "method:selection" // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas
            },
        },
    },
    

    // Border
    {
        name: "border",
        description: "Sets the border of the element",
        display_name: "Border",
        type: "custom",
        value: "",
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        source:{
            component: "containers/Cms/Components/Properties/Border",
            props: {
                border: "{value}", // this is the value from up there... because its dinamic, we wrap it in curly braces so it grabs the value from the json
                selection: "method:selection", // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas
            },
        },
    },
    {
        name: "border-radius",
        description: "Sets the border radius of the element",
        display_name: "Border Radius",
        type: "custom",
        value: "",
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        source:{
            component: "containers/Cms/Components/Properties/BorderRadius",
            props: {
                borderRadius: "{value}", // this is the value from up there... because its dinamic, we wrap it in curly braces so it grabs the value from the json
                selection: "method:selection", // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas
            },
        },
    },    
    {
        name: "box-shadow",
        description: "Adds a shadow to the element",
        display_name: "Shadow",
        type: "custom",
        value: "",
        elements: ["*"],
        group: "Background",
        prop_type: "basic",
        source:{
            component: "containers/Cms/Components/Properties/Shadow",
            props: {
                shadow: "{value}", // this is the value from up there... because its dinamic, we wrap it in curly braces so it grabs the value from the json
                selection: "method:selection", // this is the result returned by the component, so we tell it its a method (so that deep cloning will carry it over), and the expected return values separated by commas
            },
        },
    },

    // Margins and Padding
    {
        name: "margin",
        description: "Sets the margin of the element",
        display_name: "Margin",
        type: "text",
        default_value: "0px",
        value: "0px",
        elements: ["*"],
        group: "Margins and Padding",
        prop_type: "basic",
    },
    {
        name: "padding",
        description: "Sets the padding of the element",
        display_name: "Padding",
        type: "text",
        default_value: "0px",
        value: "0px",
        elements: ["*"],
        group: "Margins and Padding",
        prop_type: "basic",
    },        
        
];

const properties =[
    {
        name: "classes",
        description: "A space separated list of CSS classes to add to the element",
        display_name: "CSS Classes",
        type: "text",
        default_value: "",
        elements: ["*"],
        group: "Styles",
        prop_type: "classes",
    },
    {
        name: "css",
        description: "CSS code to add to the element",
        display_name: "Custom CSS Code",
        type: "textarea",
        default_value: "",
        elements: ["*"],
        group: "Styles",
        prop_type: "css",
    },
    {
        name: "background",
        description: "Sets the background of an element",
        display_name: "Background",
        type: "color",
        default_value: "",
        elements: ["*"],
        group: "Background",
        prop_type: "styles",
    },
/*    
    {
        name: "background-color",
        description: "Sets the background color of an element",
        display_name: "Background Color",
        type: "color",
        default_value: "#ffffff",
        elements: ["*"],
        group: "Background",
        prop_type: "styles"
    },
    {
        name: "background-image",
        description: "Sets the background image of an element",
        display_name: "Background Image",
        type: "text",
        default_value: "",
        elements: ["*"],
        group: "Background",
        prop_type: "styles"
    },
    {
        name: "background-position",
        description: "Sets the starting position of a background image",
        display_name: "Background Position",
        type: "select",
        default_value: "left top",
        options: [
            "left top",
            "left center",
            "left bottom",
            "right top",
            "right center",
            "right bottom",
            "center top",
            "center center",
            "center bottom"
        ],
        elements: ["*"],
        group: "Background",
        prop_type: "styles"
    },
    {
        name: "background-repeat",
        description: "Sets if/how a background image will be repeated",
        display_name: "Background Repeat",
        type: "select",
        default_value: "repeat",
        options: [
            "repeat",
            "repeat-x",
            "repeat-y",
            "no-repeat",
            "space",
            "round"
        ],
        elements: ["*"],
        group: "Background",
        prop_type: "styles",
    },
*/    
    {
        name: "background-size",
        description: "Sets the size of the background image",
        display_name: "Background Size",
        type: "select",
        default_value: "auto",
        options: [
            "auto",
            "cover",
            "contain"
        ],
        elements: ["*"],
        group: "Background",
        prop_type: "styles",
    },
    {
        name: "border",
        description: "Sets all the border properties in one declaration",
        display_name: "Border",
        type: "border",
        default_value: "1px solid #000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
/*    
    {
        name: "border-bottom",
        description: "Sets all the bottom border properties in one declaration",
        display_name: "Border Bottom",
        type: "border",
        default_value: "1px solid #000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-bottom-color",
        description: "Sets the color of the bottom border",
        display_name: "Border Bottom Color",
        type: "color",
        default_value: "#000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-bottom-style",
        description: "Sets the style of the bottom border",
        display_name: "Border Bottom Style",
        type: "select",
        default_value: "solid",
        options: [
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ],
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-bottom-width",
        description: "Sets the width of the bottom border",
        display_name: "Border Bottom Width",
        type: "size",
        default_value: "1px",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
*/
    {
        name: "border-collapse",
        description: "Sets whether table borders should collapse into a single border or be separated",
        display_name: "Border Collapse",
        type: "select",
        default_value: "separate",
        options: [
            "collapse",
            "separate"
        ],
        elements: ["table"],
        group: "Border",
        prop_type: "styles",
    },
/*    
    {
        name: "border-color",
        description: "Sets the color of the four borders",
        display_name: "Border Color",
        type: "color",
        default_value: "#000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-left",
        description: "Sets all the left border properties in one declaration",
        display_name: "Border Left",
        type: "border",
        default_value: "1px solid #000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-left-color",
        description: "Sets the color of the left border",
        display_name: "Border Left Color",
        type: "color",
        default_value: "#000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-left-style",
        description: "Sets the style of the left border",
        display_name: "Border Left Style",
        type: "select",
        default_value: "solid",
        options: [
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ],
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-left-width",
        description: "Sets the width of the left border",
        display_name: "Border Left Width",
        type: "size",
        default_value: "1px",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
*/    
    {
        name: "border-radius",
        description: "Sets the radius of the element's four corners",
        display_name: "Border Radius",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
/*    
    {
        name: "border-right",
        description: "Sets all the right border properties in one declaration",
        display_name: "Border Right",
        type: "border",
        default_value: "1px solid #000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-right-color",
        description: "Sets the color of the right border",
        display_name: "Border Right Color",
        type: "color",
        default_value: "#000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-right-style",
        description: "Sets the style of the right border",
        display_name: "Border Right Style",
        type: "select",
        default_value: "solid",
        options: [
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ],
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-right-width",
        description: "Sets the width of the right border",
        display_name: "Border Right Width",
        type: "size",
        default_value: "1px",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
*/
    {
        name: "border-spacing",
        description: "Sets the distance between the borders of adjacent cells",
        display_name: "Border Spacing",
        type: "size",
        default_value: "0px",
        elements: ["table"],
        group: "Border",
        prop_type: "styles",
    },
/*    
    {
        name: "border-style",
        description: "Sets the style of the four borders",
        display_name: "Border Style",
        type: "select",
        default_value: "solid",
        options: [
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ],
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-top",
        description: "Sets all the top border properties in one declaration",
        display_name: "Border Top",
        type: "border",
        default_value: "1px solid #000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-top-color",
        description: "Sets the color of the top border",
        display_name: "Border Top Color",
        type: "color",
        default_value: "#000000",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-top-style",
        description: "Sets the style of the top border",
        display_name: "Border Top Style",
        type: "select",
        default_value: "solid",
        options: [
            "none",
            "hidden",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ],
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-top-width",
        description: "Sets the width of the top border",
        display_name: "Border Top Width",
        type: "size",
        default_value: "1px",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
    {
        name: "border-width",
        description: "Sets the width of the four borders",
        display_name: "Border Width",
        type: "size",
        default_value: "1px",
        elements: ["*"],
        group: "Border",
        prop_type: "styles",
    },
*/    
    {
        name: "bottom",
        description: "Sets the element's bottom margin edge to a unit above/below the bottom edge of the element's containing block",
        display_name: "Bottom",
        type: "size",
        default_value: "auto",
        elements: ["*"],
        group: "Positioning",
        prop_type: "styles",
    },
    {
        name: "box-shadow",
        description: "Attaches one or more shadows to an element",
        display_name: "Box Shadow",
        type: "shadow",
        default_value: "0px 0px 0px 0px #000000",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "caption-side",
        description: "Sets the placement of a table caption",
        display_name: "Caption Side",
        type: "select",
        default_value: "top",
        options: [
            "top",
            "bottom"
        ],
        elements: ["table-caption"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "clear",
        description: "Specifies which sides of an element where other floating elements are not allowed to float",
        display_name: "Clear",
        type: "select",
        default_value: "none",
        options: [
            "none",
            "left",
            "right",
            "both"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "clip",
        description: "Clips an absolutely positioned element",
        display_name: "Clip",
        type: "size",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "color",
        description: "Sets the color of text",
        display_name: "Color",
        type: "color",
        default_value: "#000000",
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "content",
        description: "Used with the :before and :after pseudo-elements, to insert generated content",
        display_name: "Content",
        type: "text",
        default_value: "normal",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "cursor",
        description: "Specifies the mouse cursor to be displayed when pointing over an element",
        display_name: "Cursor",
        type: "select",
        default_value: "auto",
        options: [
            "auto",
            "crosshair",
            "default",
            "pointer",
            "move",
            "e-resize",
            "ne-resize",
            "nw-resize",
            "n-resize",
            "se-resize",
            "sw-resize",
            "s-resize",
            "w-resize",
            "text",
            "wait",
            "help",
            "progress"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "direction",
        description: "Specifies the text direction/writing direction",
        display_name: "Direction",
        type: "select",
        default_value: "ltr",
        options: [
            "ltr",
            "rtl"
        ],
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "display",
        description: "Specifies the type of rendering box used for an element",
        display_name: "Display",
        type: "select",
        default_value: "inline",
        options: [
            "inline",
            "block",
            "list-item",
            "run-in",
            "inline-block",
            "table",
            "inline-table",
            "table-row-group",
            "table-header-group",
            "table-footer-group",
            "table-row",
            "table-column-group",
            "table-column",
            "table-cell",
            "table-caption",
            "none",
            "flex",
            "inline-flex",
            "grid",
            "inline-grid"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "flex-direction",
        description: "Specifies the direction of the flexible items",
        display_name: "Flex Direction",
        type: "select",
        default_value: "row",
        options: [
            "row",
            "row-reverse",
            "column",
            "column-reverse"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "flex-wrap",
        description: "Specifies whether the flexible items should wrap or not",
        display_name: "Flex Wrap",
        type: "select",
        default_value: "nowrap",
        options: [
            "nowrap",
            "wrap",
            "wrap-reverse"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "flex-flow",
        description: "A shorthand property for the flex-direction and the flex-wrap properties",
        display_name: "Flex Flow",
        type: "text",
        default_value: "row nowrap",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "flex-grow",
        description: "Specifies how much the item will grow relative to the rest",
        display_name: "Flex Grow",
        type: "text",
        default_value: "0",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "flex-shrink",
        description: "Specifies how the item will shrink relative to the rest",
        display_name: "Flex Shrink",
        type: "text",
        default_value: "1",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "flex-basis",
        description: "Specifies the initial length of a flexible item",
        display_name: "Flex Basis",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
/*    
    {
        name: "flex",
        description: "A shorthand property for the flex-grow, flex-shrink and the flex-basis properties",
        display_name: "Flex",
        type: "text",
        default_value: "0 1 auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
*/    
    {
        name: "justify-content",
        description: "Specifies the alignment along the main axis",
        display_name: "Justify Content",
        type: "select",
        default_value: "flex-start",
        options: [
            "flex-start",
            "flex-end",
            "center",
            "space-between",
            "space-around",
            "space-evenly"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "align-items",
        description: "Specifies the alignment along the cross axis",
        display_name: "Align Items",
        type: "select",
        default_value: "stretch",
        options: [
            "stretch",
            "flex-start",
            "flex-end",
            "center",
            "baseline"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "align-content",
        description: "Specifies the alignment along the cross axis when there is extra space in the cross-axis",
        display_name: "Align Content",
        type: "select",
        default_value: "stretch",
        options: [
            "stretch",
            "flex-start",
            "flex-end",
            "center",
            "space-between",
            "space-around"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "align-self",
        description: "Specifies the alignment for selected items inside a flexible container",
        display_name: "Align Self",
        type: "select",
        default_value: "auto",
        options: [
            "auto",
            "stretch",
            "flex-start",
            "flex-end",
            "center",
            "baseline"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "order",
        description: "Specifies the order of the flexible item, relative to the rest",
        display_name: "Order",
        type: "text",
        default_value: "0",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["flex","inline-flex"]
        }
    },
    {
        name: "grid-template-columns",
        description: "Specifies the columns of the grid with a space-separated list of values",
        display_name: "Grid Template Columns",
        type: "text",
        default_value: "none",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-template-rows",
        description: "Specifies the rows of the grid with a space-separated list of values",
        display_name: "Grid Template Rows",
        type: "text",
        default_value: "none",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-template-areas",
        description: "Specifies how to display columns and rows, using named grid items",
        display_name: "Grid Template Areas",
        type: "text",
        default_value: "none",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-template",
        description: "A shorthand property for the grid-template-rows, grid-template-columns and the grid-template-areas properties",
        display_name: "Grid Template",
        type: "text",
        default_value: "none",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-column-gap",
        description: "Specifies the size of the gap between columns",
        display_name: "Grid Column Gap",
        type: "text",
        default_value: "0",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-row-gap",
        description: "Specifies the size of the gap between rows",
        display_name: "Grid Row Gap",
        type: "text",
        default_value: "0",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-gap",
        description: "A shorthand property for the grid-row-gap and grid-column-gap properties",
        display_name: "Grid Gap",
        type: "text",
        default_value: "0",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-auto-columns",
        description: "Specifies the size of an implicitly-created column grid line",
        display_name: "Grid Auto Columns",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-auto-rows",
        description: "Specifies the size of an implicitly-created row grid line",
        display_name: "Grid Auto Rows",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-auto-flow",
        description: "Specifies how auto-placed items are inserted in the grid",
        display_name: "Grid Auto Flow",
        type: "select",
        default_value: "row",
        options: [
            "row",
            "column",
            "row dense",
            "column dense"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid",
        description: "A shorthand property for the grid-template-rows, grid-template-columns, grid-template-areas, grid-auto-rows, grid-auto-columns and the grid-auto-flow properties",
        display_name: "Grid",
        type: "text",
        default_value: "none",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-column-start",
        description: "Specifies where to start the item in the column",
        display_name: "Grid Column Start",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-column-end",
        description: "Specifies where to end the item in the column",
        display_name: "Grid Column End",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-column",
        description: "A shorthand property for the grid-column-start and the grid-column-end properties",
        display_name: "Grid Column",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-row-start",
        description: "Specifies where to start the item in the row",
        display_name: "Grid Row Start",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-row-end",
        description: "Specifies where to end the item in the row",
        display_name: "Grid Row End",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-row",
        description: "A shorthand property for the grid-row-start and the grid-row-end properties",
        display_name: "Grid Row",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "grid-area",
        description: "A shorthand property for the grid-row-start, grid-column-start, grid-row-end and the grid-column-end properties",
        display_name: "Grid Area",
        type: "text",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["grid","inline-grid"]
        }
    },
    {
        name: "empty-cells",
        description: "Sets whether or not to display borders and background on empty cells in a table",
        display_name: "Empty Cells",
        type: "select",
        default_value: "show",
        options: [
            "show",
            "hide"
        ],
        elements: ["table"],
        group: "Box",
        prop_type: "styles",
        condition:{
            name: "display",
            value: ["table"]
        }
    },
    {
        name: "float",
        description: "Specifies whether or not a box should float",
        display_name: "Float",
        type: "select",
        default_value: "none",
        options: [
            "none",
            "left",
            "right"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",            
    },
    {
        name: "font-family",
        description: "Specifies the font family for text",
        display_name: "Font Family",
        type: "select",
        options: ["{company_context}.primaryFontFamily", "{company_context}.secondaryFontFamily", "inherit", "initial", "revert", "revert-layer", "unset"],
        default_value: "inherit",
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "font-size",
        description: "Specifies the font size of text",
        display_name: "Font Size",
        type: "size",
        default_value: "16px",
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "font-style",
        description: "Specifies the font style for text",
        display_name: "Font Style",
        type: "select",
        default_value: "normal",
        options: [
            "normal",
            "italic",
            "oblique"
        ],
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "font-variant",
        description: "Specifies whether or not a text should be displayed in a small-caps font",
        display_name: "Font Variant",
        type: "select",
        default_value: "normal",
        options: [
            "normal",
            "small-caps"
        ],
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "font-weight",
        description: "Specifies the weight of a font",
        display_name: "Font Weight",
        type: "select",
        default_value: "normal",
        options: [
            "normal",
            "bold",
            "bolder",
            "lighter",
            "100",
            "200",
            "300",
            "400",
            "500",
            "600",
            "700",
            "800",
            "900"
        ],
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "height",
        description: "Sets the height of an element",
        display_name: "Height",
        type: "size",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "left",
        description: "Specifies the left position of a positioned element",
        display_name: "Left",
        type: "size",
        default_value: "auto",
        elements: ["*"],
        group: "Positioning",
        prop_type: "styles",
    },
    {
        name: "letter-spacing",
        description: "Increases or decreases the space between characters in a text",
        display_name: "Letter Spacing",
        type: "size",
        default_value: "normal",
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "line-height",
        description: "Sets the line height",
        display_name: "Line Height",
        type: "size",
        default_value: "normal",
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "list-style",
        description: "Shorthand property for setting all the list style properties at once",
        display_name: "List Style",
        type: "text",
        default_value: "disc outside none",
        elements: ["*"],
        group: "List",
        prop_type: "styles",
    },
    {
        name: "list-style-image",
        description: "Specifies an image as the list-item marker",
        display_name: "List Style Image",
        type: "text",
        default_value: "none",
        elements: ["*"],
        group: "List",
        prop_type: "styles",
    },
    {
        name: "list-style-position",
        description: "Specifies the position of the list-item markers (bullet points)",
        display_name: "List Style Position",
        type: "select",
        default_value: "outside",
        options: [
            "outside",
            "inside"
        ],
        elements: ["*"],
        group: "List",
        prop_type: "styles",
    },
    {
        name: "list-style-type",
        description: "Specifies the type of list-item marker",
        display_name: "List Style Type",
        type: "select",
        default_value: "disc",
        options: [
            "disc",
            "circle",
            "square",
            "decimal",
            "decimal-leading-zero",
            "lower-roman",
            "upper-roman",
            "lower-greek",
            "lower-latin",
            "upper-latin",
            "armenian",
            "georgian",
            "lower-alpha",
            "upper-alpha",
            "none"
        ],
        elements: ["*"],
        group: "List",
        prop_type: "styles",
    },
    {
        name: "margin",
        description: "Shorthand property for setting all the margin properties at once",
        display_name: "Margin",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
/*    
    {
        name: "margin-bottom",
        description: "Sets the bottom margin of an element",
        display_name: "Margin Bottom",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "margin-left",
        description: "Sets the left margin of an element",
        display_name: "Margin Left",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "margin-right",
        description: "Sets the right margin of an element",
        display_name: "Margin Right",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "margin-top",
        description: "Sets the top margin of an element",
        display_name: "Margin Top",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
*/    
    {
        name: "max-height",
        description: "Sets the maximum height of an element",
        display_name: "Max Height",
        type: "size",
        default_value: "none",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "max-width",
        description: "Sets the maximum width of an element",
        display_name: "Max Width",
        type: "size",
        default_value: "none",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "min-height",
        display_name: "Min Height",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "min-width",
        display_name: "Min Width",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "opacity",
        description: "Sets the opacity level for an element",
        display_name: "Opacity",
        type: "size",
        default_value: "1",
        elements: ["*"],
        group: "Visual",
        prop_type: "styles",
    },
    {
        name: "outline",
        description: "Shorthand property for setting all the outline properties at once",
        display_name: "Outline",
        type: "text",
        default_value: "rgb(0, 0, 0) none 0px",
        elements: ["*"],
        group: "Visual",
        prop_type: "styles",
    },
/*    
    {
        name: "outline-color",
        description: "Sets the color of an outline",
        display_name: "Outline Color",
        type: "color",
        default_value: "rgb(0, 0, 0)",
        elements: ["*"],
        group: "Visual",
        prop_type: "styles",
    },
    {
        name: "outline-style",
        description: "Sets the style of an outline",
        display_name: "Outline Style",
        type: "select",
        default_value: "none",
        options: [
            "none",
            "dotted",
            "dashed",
            "solid",
            "double",
            "groove",
            "ridge",
            "inset",
            "outset"
        ],
        elements: ["*"],
        group: "Visual",
        prop_type: "styles",
    },
    {
        name: "outline-width",
        description: "Sets the width of an outline",
        display_name: "Outline Width",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Visual",
        prop_type: "styles",
    },
*/    
    {
        name: "overflow",
        description: "Specifies what happens if content overflows an element's box",
        display_name: "Overflow",
        type: "select",
        default_value: "visible",
        options: [
            "visible",
            "hidden",
            "scroll",
            "auto"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "overflow-x",
        description: "Specifies what should happen if content overflows the left/right edges of an element's content area",
        display_name: "Overflow X",
        type: "select",
        default_value: "visible",
        options: [
            "visible",
            "hidden",
            "scroll",
            "auto"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "overflow-y",
        description: "Specifies what should happen if content overflows the top/bottom edges of an element's content area",
        display_name: "Overflow Y",
        type: "select",
        default_value: "visible",
        options: [
            "visible",
            "hidden",
            "scroll",
            "auto"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "padding",
        description: "Shorthand property for setting all the padding properties at once",
        display_name: "Padding",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
/*    
    {
        name: "padding-bottom",
        description: "Sets the bottom padding of an element",
        display_name: "Padding Bottom",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "padding-left",
        description: "Sets the left padding of an element",
        display_name: "Padding Left",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "padding-right",
        description: "Sets the right padding of an element",
        display_name: "Padding Right",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "padding-top",
        description: "Sets the top padding of an element",
        display_name: "Padding Top",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
*/    
    {
        name: "page-break-after",
        description: "Sets the page-breaking behavior after an element",
        display_name: "Page Break After",
        type: "select",
        default_value: "auto",
        options: [
            "auto",
            "always",
            "avoid",
            "left",
            "right"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "page-break-before",
        description: "Sets the page-breaking behavior before an element",
        display_name: "Page Break Before",
        type: "select",
        default_value: "auto",
        options: [
            "auto",
            "always",
            "avoid",
            "left",
            "right"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "page-break-inside",
        description: "Sets the page-breaking behavior inside an element",
        display_name: "Page Break Inside",
        type: "select",
        default_value: "auto",
        options: [
            "auto",
            "avoid"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "position",
        description: "Specifies the type of positioning method used for an element (static, relative, absolute or fixed)",
        display_name: "Position",
        type: "select",
        default_value: "static",
        options: [
            "static",
            "relative",
            "absolute",
            "fixed"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "right",
        description: "Specifies the right position of a positioned element",
        display_name: "Right",
        type: "size",
        default_value: "auto",
        elements: ["*"],
        group: "Positioning",
        prop_type: "styles",
    },
    {
        name: "table-layout",
        description: "Specifies the algorithm used to lay out table cells, rows, and columns",
        display_name: "Table Layout",
        type: "select",
        default_value: "auto",
        options: [
            "auto",
            "fixed"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "text-align",
        description: "Specifies the horizontal alignment of text in an element",
        display_name: "Text Align",
        type: "select",
        default_value: "left",
        options: [
            "left",
            "right",
            "center",
            "justify"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "text-decoration",
        description: "Specifies the decoration added to text",
        display_name: "Text Decoration",
        type: "select",
        default_value: "none",
        options: [
            "none",
            "underline",
            "overline",
            "line-through",
            "blink"
        ],
        elements: ["*"],
        group: "Text",
        prop_type: "styles",
    },
    {
        name: "text-indent",
        description: "Specifies the indentation of the first line in a text-block",
        display_name: "Text Indent",
        type: "size",
        default_value: "0px",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "text-transform",
        description: "Controls the capitalization of text",
        display_name: "Text Transform",
        type: "select",
        default_value: "none",
        options: [
            "none",
            "capitalize",
            "uppercase",
            "lowercase"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "top",
        description: "Specifies the top position of a positioned element",
        display_name: "Top",
        type: "size",
        default_value: "auto",
        elements: ["*"],
        group: "Positioning",
        prop_type: "styles",
    },
    {
        name: "vertical-align",
        description: "Specifies the vertical alignment of an element",
        display_name: "Vertical Align",
        type: "select",
        default_value: "baseline",
        options: [
            "baseline",
            "sub",
            "super",
            "top",
            "text-top",
            "middle",
            "bottom",
            "text-bottom"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "visibility",
        description: "Specifies whether or not an element is visible",
        display_name: "Visibility",
        type: "select",
        default_value: "visible",
        options: [
            "visible",
            "hidden"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "white-space",
        description: "Specifies how white-space inside an element is handled",
        display_name: "White Space",
        type: "select",
        default_value: "normal",
        options: [
            "normal",
            "pre",
            "nowrap",
            "pre-wrap",
            "pre-line"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "width",
        description: "Sets the width of an element",
        display_name: "Width",
        type: "size",
        default_value: "auto",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "word-spacing",
        description: "Increases or decreases the white space between words",
        display_name: "Word Spacing",
        type: "size",
        default_value: "normal",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "z-index",
        description: "Specifies the stack order of an element",
        display_name: "Z-Index",
        type: "integer",
        default_value: "auto",
        elements: ["*"],
        group: "Positioning",
        prop_type: "styles",
    },
    {
        name: "zoom",
        description: "Specifies the zoom factor for an element",
        display_name: "Zoom",
        type: "integer",
        default_value: "1",
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "background-attachment",
        description: "Sets whether a background image scrolls with the rest of the page, or is fixed",
        display_name: "Background Attachment",
        type: "select",
        default_value: "scroll",
        options: [
            "scroll",
            "fixed"
        ],
        elements: ["*"],
        group: "Background",
        prop_type: "styles",
    },
    {
        name: "aspect-ratio",
        description: "Specifies the aspect ratio of an element",
        display_name: "Aspect Ratio",
        type: "select",
        default_value: "auto",
        options: [
            "auto",
            "1/1",
            "4/3",
            "16/9",
            "21/9"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
    {
        name: "object-fit",
        description: "Specifies how the contents of a replaced element should be fitted to the box established by its used height and width",
        display_name: "Object Fit",
        type: "select",
        default_value: "fill",
        options: [
            "fill",
            "contain",
            "cover",
            "none",
            "scale-down"
        ],
        elements: ["*"],
        group: "Box",
        prop_type: "styles",
    },
]

// group properties by group name and sort them by display name
const groupedProperties = [];
const seen = new Set();
[...basicProperties, ...properties].forEach((property, i) => {
    if (!property.id) property.id = i + 1;
    if (property.group){
        if (!groupedProperties[property.group]) groupedProperties[property.group] = [];
        if (!seen.has(property.name)) {
            groupedProperties[property.group].push(property);
            seen.add(property.name);
        }
    } else if (!seen.has(property.name)) {
        groupedProperties.push(property);
        seen.add(property.name);
    }
});


Object.keys(groupedProperties).forEach(groupName => {
    if (isNaN(groupName)){
        groupedProperties[groupName].sort((a, b) => {
            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;
        });
    } 
});

// move grouped elements to root
Object.keys(groupedProperties).forEach(groupName => {
    if (isNaN(groupName)){
        groupedProperties.push(...groupedProperties[groupName]);
        delete groupedProperties[groupName];
    }
});

// I know this is messed up, I will deal with it later ;)

// export properties
const cssProperties = groupedProperties;

export default cssProperties;
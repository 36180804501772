import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';

const Footer = (props) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [links, setLinks] = useState([]);
    const [socials, setSocials] = useState([]);

    useEffect(() => {
        return () => {
            setLinks([]);
            setSocials([]);
        }
    }, []);

    useEffect(() => {
        if (props?.links){
            let _array = props.links;
            if (typeof _array === 'string') _array=JSON.parse(props.links);
            if (Array.isArray(_array)){
                // group menu items by group name
                let _menu=[];
                _array.forEach(item=>{
                    if (item.group){
                        let _group=_menu.find(g=>g.name===item.group);
                        if (!_group){
                            _group={name:item.group,items:[]};
                            _menu.push(_group);
                        }
                        _group.items.push(item);
                    }else{
                        _menu.push(item);
                    }
                });
                setLinks(_menu);
            }
        }
    }, [props?.links]);

    useEffect(() => {
        if (props?.socials){
            let _array=props.socials;
            if (typeof _array === 'string') _array=JSON.parse(props.socials);
            let _socials=[];
            if (Array.isArray(_array)){
                _array.forEach(item=>{
                    const [type, url] = item.split('|');
                    switch (type) {
                        case 'facebook':
                            _socials.push({name:'Facebook',url,icon:'fab fa-facebook-f',color:'#3b5998'});
                            break;
                        case 'twitter':
                            _socials.push({name:'Twitter',url,icon:'fab fa-twitter',color:'#1da1f2'});
                            break;
                        case 'instagram':
                            _socials.push({name:'Instagram',url,icon:'fab fa-instagram',color:'#e1306c'});
                            break;
                        case 'linkedin':
                            _socials.push({name:'LinkedIn',url,icon:'fab fa-linkedin-in',color:'#0077b5'});
                            break;
                        case 'youtube':
                            _socials.push({name:'YouTube',url,icon:'fab fa-youtube',color:'#ff0000'});
                            break;
                        case 'pinterest':
                            _socials.push({name:'Pinterest',url,icon:'fab fa-pinterest-p',color:'#bd081c'});
                            break;
                        case 'tumblr':
                            _socials.push({name:'Tumblr',url,icon:'fab fa-tumblr',color:'#35465c'});
                            break;
                        case 'reddit':
                            _socials.push({name:'Reddit',url,icon:'fab fa-reddit-alien',color:'#ff4500'});
                            break;
                        case 'snapchat':
                            _socials.push({name:'Snapchat',url,icon:'fab fa-snapchat-ghost',color:'#fffc00'});
                            break;
                        case 'whatsapp':
                            _socials.push({name:'WhatsApp',url,icon:'fab fa-whatsapp',color:'#25d366'});
                            break;
                        case 'telegram':
                            _socials.push({name:'Telegram',url,icon:'fab fa-telegram-plane',color:'#0088cc'});
                            break;
                        case 'viber':
                            _socials.push({name:'Viber',url,icon:'fab fa-viber',color:'#665CAC'});
                            break;
                        case 'skype':
                            _socials.push({name:'Skype',url,icon:'fab fa-skype',color:'#00aff0'});
                            break;
                        case 'wechat':
                            _socials.push({name:'WeChat',url,icon:'fab fa-weixin',color:'#7bb32e'});
                            break;
                        case 'line':
                            _socials.push({name:'Line',url,icon:'fab fa-line',color:'#00c300'});
                            break;
                        case 'email':
                            _socials.push({name:'Email',url,icon:'fas fa-envelope',color:'#000000'});
                            break;
                        case 'phone':
                            _socials.push({name:'Phone',url,icon:'fas fa-phone',color:'#000000'});
                            break;
                        case 'sms':
                            _socials.push({name:'SMS',url,icon:'fas fa-comment',color:'#000000'});
                            break;
                        default:
                            break;
                    }
                });
            }
            setSocials(_socials);
        }
    }, [props?.socials]);

    return (
        <footer className={`footer_${props.page_id} ${props?.className || ""}`} style={props?.style || null} onClick={preview_click}>
            <Container className={`container_${props.page_id} mt-5`}>
                <Row className={`row_${props.page_id}`}>
                    <Col sm={12} lg={3} className={`col_${props.page_id}`}>
                        <img src={props.company_context.logo} alt={props.company_context.name} className={`footer-logo_${props.page_id}`} /><br/>
                        <p>{props.text}</p>
                        {socials.map((item,i)=>{
                            return (
                                <a href={item.url} key={`social-lnk-${i}`} target="_blank" rel="noopener noreferrer" className={`footer-social-link_${props.page_id}`}>
                                    <i className={`fab_${props.page_id} ${item.icon}`} style={{backgroundColor:item.color}}></i>
                                </a>
                            );
                        })}
                    </Col>
                    <Col sm={12} lg={6} className={`col_${props.page_id}`}>
                        <Row className={`row_${props.page_id}`}>
                            {links.map((item,i)=>{
                                if (item.items){
                                    return (
                                        <Col sm={12} lg={6} className={`col_${props.page_id}`} key={`footer-menu-item-${item.name}-${i}`}>
                                            <h5 className={`footer-contact-title_${props.page_id}`}>{item.name}</h5>
                                            <ul className={`footer-links_${props.page_id}`}>
                                                {item.items.map((link,j)=>{
                                                    return (
                                                        <li key={`footer-menu-item-link-${link.name}-${i}-${j}`} className={`footer-menu-item-link_${props.page_id}`}>
                                                            <a href={link.url}>{link.name}</a>
                                                        </li>
                                                    );
                                                }
                                                )}
                                            </ul>
                                        </Col>
                                    );
                                }else{
                                    return (
                                        <>
                                            <a key={`footer-menu-item-${item.name}-${i}`} className={`footer-menu-item_${props.page_id}`} href={item.url}>{item.name}</a><br/>
                                        </>
                                    );
                                }
                            })}
                        </Row>
                    </Col>
                    <Col sm={12} lg={3} className={`col_${props.page_id}`}>
                        {props.address || props.phone ? <h5 className={`footer-contact-title_${props.page_id}`}>Contact</h5> : null}
                        {props.address && <p>{props.address}</p>}
                        {props.phone && <p>{props.phone}</p>}
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
import React, {useState, useEffect} from 'react';
import {ButtonGroup, Button} from 'react-bootstrap';

import {confirm} from '../../../../components/Confirmation';

import Cash from './Cash';
import GiftCard from './GiftCard';
import Check from './Check';
import Terminal from './Terminal';
import Card from './Card';
import ManagerDiscount from './ManagerDiscount';

import styles from './PaymentType.module.scss';

const paymentTypes = [
    {id: "TERMINAL", name: "Scan Card"},
    {id: 1, name: "Enter Card"},
    {id: 2, name: "Cash"},
    {id: 3, name: "Check"},
    {id: 4, name: "Gift Card"},
    {id: 5, name: "Manager Discount"},
];

export const PaymentType = (props) => {
    const {onPaymentMethodChange, setPayments, setSuccess, setForEnableCompleteOrder} = props;
    const [paymentMethodId, setPaymentMethodId] = useState();

    const clickHandler = async (e, id) => {
        e.preventDefault();
        e.stopPropagation();

        if (id === 1 || id === 5 || id === "TERMINAL") {
            if (props?.payments?.length > 0){
                const result = await confirm(`Changing to this payment method will remove any other methods already added.`, {
                    title: `Payment Method Change`,
                    okText: "Ok",
                    cancelText: "Cancel",
                    cancelButtonStyle: "primary",
                    okButtonStyle: "light"
                });
                if (!result) return;
            }
        }

        setPaymentMethodId(id);

        if (id === 2) setForEnableCompleteOrder(true);
        else setForEnableCompleteOrder(false);
        setPayments(prev=>{
            let _prev = [...prev];
            if (id === 1 || id === 5 || id === "TERMINAL") _prev = [];
            else _prev = _prev.filter(a => (a.payment_method_id !== "TERMINAL" && a.payment_method_id !== 1 && a.payment_method_id !== 5) || a?.transaction_id);
            return [..._prev];
        });

        //if (calculateTotals) {
            //calculateTotals({value: +id === 2});
        //}
    }

    // removes pending terminal and cc payments if a different method is selected (because they have to be processed individually one by one and not in this batch)
    /*useEffect(()=>{
        if (paymentMethodId === 1 || paymentMethodId === 5 || paymentMethodId === "TERMINAL") {
            return;
        }
        if (paymentMethodId === 2) setForEnableCompleteOrder(true);
        else setForEnableCompleteOrder(false);
        setPayments(prev=>{
            let _prev = [...prev];
            _prev = _prev.filter(a => (a.payment_method_id !== "TERMINAL" && a.payment_method_id !== 1 && a.payment_method_id !== 5) || a?.transaction_id);
            return [..._prev];
        });
    }, [paymentMethodId, setPayments, setForEnableCompleteOrder]);*/

    useEffect(()=>{
        if (props.success || !props.selectedPaymentMethod) {
            setSuccess(false);
            setPaymentMethodId(null);
        }
    }, [props.success, props.selectedPaymentMethod, setSuccess]);

    return (
        <>
            <label className="form-label">Payment Type</label>
            <div className={styles["button-group"]}>
                {paymentTypes.map((a,i)=>(
                    <Button 
                        key={`payment-type-${i}`} 
                        variant="light" 
                        className={a.id === paymentMethodId ? "active pay-btn" : "pay-btn"} 
                        disabled={props.submitting || props.order.order_status_id === 2 || props.order.order_status_id === 3}
                        onClick={(e)=>clickHandler(e, a.id)}
                        data-cy={`payment-type-${a.id}`}
                    >
                        {a.name}
                    </Button>
                ))}
            </div>

            <div className={styles.wrapper}>
                {paymentMethodId === 1 && // credit card
                    <Card {...props} payment_method_id={paymentMethodId} onPaymentMethodChange={onPaymentMethodChange} />
                }
                {paymentMethodId === 2 && // cash
                    <Cash {...props} payment_method_id={paymentMethodId} onPaymentMethodChange={onPaymentMethodChange} />
                }
                {paymentMethodId === 3 && // check
                    <Check {...props} payment_method_id={paymentMethodId} onPaymentMethodChange={onPaymentMethodChange} />
                }
                {paymentMethodId === 4 && // gift card
                    <GiftCard {...props} payment_method_id={paymentMethodId} onPaymentMethodChange={onPaymentMethodChange} />
                }
                {paymentMethodId === 5 && // admin auth / manager discount
                    <ManagerDiscount {...props} payment_method_id={paymentMethodId} onPaymentMethodChange={onPaymentMethodChange} />
                }
                {paymentMethodId === "TERMINAL" && 
                    <Terminal {...props} payment_method_id={paymentMethodId} onPaymentMethodChange={onPaymentMethodChange} />
                }
            </div>
        </>
    );
}
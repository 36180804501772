import React, {useState, useEffect} from 'react';
import {Container, Row, Col, Form} from 'react-bootstrap';

import styles from '../Wizard.module.scss';

export const Memo = (props) => {
    const {stepUpdate} = props;

    const [memo, setMemo] = useState(props?.stepData?.memo || "");

    const changeHandler = e => setMemo(e.target.value);

    useEffect(()=>{
        stepUpdate(props.stepId, {memo});
    },[stepUpdate, props.stepId, memo]);

    return (
        <Container fluid>
            <h4 className={`${styles.title}`}>What is the reason for the refund?</h4>
            <hr/>
            <Row>
                <Col>
                    <Form.Group controlId="refund-memo">
                        <Form.Label>Memo</Form.Label>
                        <Form.Control type="text" name="amount" value={memo} onChange={changeHandler} />
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    );
}
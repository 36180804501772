import React from 'react';
import {format} from 'date-fns';

import styles from '../../FullPageReceipt.module.scss';

export const Items = (props) => {
    const is_ticket = props?.format === "ticket";
    const is_kitchen = props?.format === "kitchen";
    if (!props.order || !props.items?.length > 0) return null;
    
    return (
        <div className={`${styles["flex-wrapper-items"]}`}>
            {/*!is_ticket &&*/
                <div className={`${styles["fp-item-headers"]} ${styles.ticket}`}>
                    {props?.headers?.map((each, i)=> (
                        <span key={`rec-table-headers${i}`} className={i===0?styles["main-item"]:styles.amount}>
                            {each}
                        </span>
                    ))}
                </div>
            }
            <div className={styles["fp-items"]}>
                {/*is_ticket &&
                    <div className={styles["item-wrapper"]}>
                        <div className={`${styles.bold} ${styles["item-map-ticket"]}`}>Items:</div>
                    </div>
                */}
                {props?.items?.map((item)=>(
                    <React.Fragment key={`rec-table-items-${item.id}`}>
                        <div className={styles["item-wrapper"]}>
                            <div className={`${is_ticket ? styles["item-map-ticket"] : styles["item-map"]}`}>                                
                                <span className={`${styles["item-name"]}`}> {/*/*is_ticket && "w-auto"*/}
                                    {item.product_name}
                                    {item.variant_id 
                                        && (item.parent_id===item.product_id || !item.parent_id)
                                        && item.variant_name.trim().toLowerCase()!==item.product_name.trim().toLowerCase()
                                        && item.variant_name.trim().toLowerCase()!=="default"
                                        && item.variant_name.trim().toLowerCase()!=="" &&
                                            <span>
                                                {` - ${item.variant_name}`} 
                                            </span>
                                    }                                    
                                    {/*is_ticket && 
                                        <>
                                            {` x `}
                                            {+item?.final_price < +item?.base_price  &&
                                                <span className={`${styles.strike} me-1`}>${item?.base_price?.toFixed(2)}</span>
                                            }
                                            ${item?.final_price?.toFixed(2)}
                                        </>
                                    */}
                                </span>
                                {props.type==="invoice" &&
                                    <>
                                        {item.token_total ? 
                                            <span className={styles.amount}>${item?.base_price?.toFixed(2)} x {item?.token_total}</span>
                                        : 
                                            <span className={styles.amount}>${item?.base_price?.toFixed(2)}</span>
                                        }
                                        {+item?.final_price < +item?.base_price  &&
                                            <span className={styles.amount}>${Math.abs(+item?.final_price - +item?.base_price)?.toFixed(2)}</span>
                                        }
                                        <span className={styles.amount}>${item?.final_price?.toFixed(2)}</span>
                                    </>
                                }
                                {props.type==="receipt" && /*!is_ticket &&*/
                                    <>
                                        <span className={styles.amount}>
                                            {+item?.final_price < +item?.base_price  &&
                                                <span className={`${styles.strike} me-1`}>${item?.base_price?.toFixed(2)}</span>
                                            }
                                            ${item?.final_price?.toFixed(2)}
                                        </span>
                                    </>
                                }
                            </div>
                            {!is_kitchen &&
                                (item.product_description || item?.total_refunded_amount || item?.refund_memo) &&                                
                                    <div className={`${styles["fp-desc"]}`}> {/*is_ticket && "w-100 text-center"*/}
                                        {(item?.total_refunded_amount || item?.refund_memo) &&
                                            "*REFUNDED ITEM* "
                                        }
                                        {item.product_description}
                                    </div>
                            }
                            {item?.addons?.length > 0 && item.addons.map((addon, i)=>(
                                <div key={`addon-line-${i}`} className={`${is_ticket ? styles["item-map-ticket"] : styles["item-map"]}`}>
                                    <span className={`${styles["item-name"]} ${styles.indent}`}>• {addon.product_name}</span>
                                    {!is_kitchen &&
                                        <>
                                            <span className={styles.amount}>${+addon?.base_price?.toFixed(2) || "0.00"}</span>
                                            <span className={styles.amount}>${+addon?.final_price?.toFixed(2) || "0.00"}</span>
                                        </>
                                    }
                                </div>
                            ))}
                            {!is_kitchen && item.event && 
                                <>
                                    <div className={`${is_ticket ? styles["item-map-ticket"] : styles["item-map"]}`}>
                                        <span className={`${styles["item-name"]} ${styles.indent}`}> {/*is_ticket ? "w-100 text-center" : styles.indent*/}
                                            For {item.event.for_user_name}
                                        </span>
                                    </div>
                                    {item.event?.custom_fields && Object.keys(item.event.custom_fields).map((key,i)=>{
                                        if (item.event?.custom_field_definition){
                                            let fieldDefinition = item.event.custom_field_definition.find(field=>field.name===key);
                                            if (fieldDefinition){
                                                return (
                                                    <div key={`custom-field-${i}`} className={`${is_ticket ? styles["item-map-ticket"] : styles["item-map"]}`}>
                                                        <span className={`${styles["item-name"]} ${styles.indent}`}> {/*is_ticket ? "w-100 text-center" : styles.indent*/}
                                                            • {fieldDefinition.placeholder_text}: {item.event.custom_fields[key]}
                                                        </span>
                                                    </div>
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                </>
                            }
                            {!is_kitchen && item.giftcard &&
                                <div className={`${is_ticket ? styles["item-map-ticket"] : styles["item-map"]}`}>
                                    <span className={`${styles["item-name"]} ${styles.indent}`}> {/*${is_ticket ? "w-100 text-center" : styles.indent}*/}
                                        For {item.giftcard.full_name}<br/>
                                        {item.giftcard.email}<br/>
                                        Deliver on 
                                        {format(new Date(item.giftcard.delivery_date), "MM-dd-yyyy")}
                                    </span>
                                </div>
                            }
                            {!is_kitchen && item?.bill_interval && item?.interval_quantity &&
                                <div className={`${is_ticket ? styles["item-map-ticket"] : styles["item-map"]}`}>
                                    <span className={`${styles.bold} ${styles.indent}`}> {/*${is_ticket ? "w-100 text-center" : styles.indent}*/}
                                        {+item.activation_fee>0 && `+$${item.price} `}
                                        {item?.bill_interval ==="m" && item.interval_quantity ? `Billed every ${item.interval_quantity} month${item.interval_quantity > 1 ? "s" : ""} ${item.bill_num_times ? (item.bill_num_times > 1 ? `${item.bill_num_times} times in total` : "") : "until cancelled"}` : ""}
                                        {item?.bill_interval ==="y" ? `${item.bill_num_times ? "Billed once" : "Billed annually" }` : ""}
                                    </span>
                                </div>
                            }
                            {!is_kitchen && item?.subscription_type_name && item?.subscription_type_name && 
                                <div className={`${is_ticket ? styles["item-map-ticket"] : styles["item-map"]}`}>
                                    <span className={`${styles.bold} ${styles.indent}`}> {/*${is_ticket ? "w-100 text-center" : styles.indent}*/}
                                        {item.subscription_type_name} Subscription 
                                        {item.hasOwnProperty("subscription_max_users") && item?.subscription_max_users > 1 &&
                                            <>
                                            {" "} ({item.subscription_max_users} Users Max)
                                            </>
                                        }
                                    </span>
                                </div>
                            }
                        </div>
                        <hr className={styles.divider}/>
                    </React.Fragment>
                ))}
                {!is_kitchen && props.order?.payment_method_id===7 &&
                    <>
                        <span>Token Redemption</span>
                        <span>{props.order?.memo}</span>
                    </>
                }
            </div>
        </div>
    );
}
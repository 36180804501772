import React, { useState, useEffect, useRef } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button } from 'react-bootstrap';
import { Modal, Card, Tabs, Tab } from 'react-bootstrap';
import { format } from 'date-fns';

import Users from '../../../api/Users';

import './Wallet.css';

export const Wallet = props => {
    const mountedRef = useRef(false);

	const [balance, setBalance] = useState(0);
	const [tokens, setTokens] = useState([]);
	const [show, setShow] = useState(false);
    const [tabKey, setTabKey] = useState('available');
    const [pagePart, setPagePart] = useState();
    const [numTokensDisplay, setNumTokensDisplay] = useState();

	const showModal = () => {
		setShow(true);
	}

	const closeModal = () => {
		setShow(false);
	}

	useEffect(() => {
		mountedRef.current = true;

		return () => mountedRef.current = false;
	},[])

	useEffect(() => {
        Users.wallet({user_id: props.user.id})
        .then( response => {
            if(mountedRef.current) {
                if(response.data) {
                    setBalance(response.data[0].balance);
                }
            }
        }).catch(e => console.error(e));
	},[props.user])

    useEffect(() => {
        setTokens();
        let params = {
            user_id: props.user.id,
        };
        if (tabKey==="redeemed") {
            params.include_redeemed = 2;
            params.include_expired = 1;
        } else if (tabKey==="expired") {
            params.include_redeemed = 0;
            params.include_expired = 2;
        } else {
            params.include_redeemed = 0;
            params.include_expired = 0;
        }

        Users.tokens(params)
        .then( response => {
            if(mountedRef.current) {
                if(response.data && Array.isArray(response.data)) {
					setTokens(response.data);
                    if (tabKey==="available") setNumTokensDisplay(response.data.length);
                }
                else if(!Array.isArray(response.data)){
                    //Even though this is the same state as original,
                    //need to set it to trigger the loading skeleton to disappear
                    setTokens([]) 
                } 
            }
        }).catch(e => console.error(e));
    },[props.user, tabKey]);

    useEffect(() => {
        if (tokens) {
            let expiredWording = tabKey==="expired" ? "Expired on " :"Expires on ";
            setPagePart(
                <>
                    {!tokens.length>0 && <p>No tokens to display</p>}
                    {tokens?.map((token, i) =>
                        <div className="token-row" key={`token-pos-${token.id}`}>
                            <div><span className="bold">{token.product_name}</span></div>
                            {tabKey==="redeemed" ?
                                <div>{token.use_date ? "Redeemed on " + format(new Date(token.use_date), "MM/dd/yyyy") : ""}</div>
                            :
                                <div>{token.exp_date ? expiredWording + format(new Date(token.exp_date), "MM/dd/yyyy") : "No expiration"}</div>
                            }
                        </div>
                    )}
                </>
            );
        } else {
            setPagePart(
                <SkeletonTheme color="#e0e0e0">
                    <Skeleton height={12} count={2} />
                </SkeletonTheme>
            );
        }
    },[tokens, tabKey]);

	return (
		<React.Fragment>
			<div>
				<div data-cy="home-wallet">
					{/* <span className="prof-amount">${balance.toFixed(2)}</span> */}
					<span className={props.compact ? '' : 'prof-card-title'}>{numTokensDisplay ? numTokensDisplay : 0} available tokens</span>
                    <br />
				</div>
				<div><Button className="btn btn-profile btn-secondary" onClick={showModal}>More Info</Button></div>
			</div>
			<Modal className="wallet-modal" show={show} onHide={closeModal} size={"m"} centered data-cy="wallet-modal">
				<Modal.Header closeButton>
					<Modal.Title>Wallet - Tokens</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* <div className="mr-b20"><h4>Balance: ${(+balance).toFixed(2)}</h4></div> */}
					{/* <div className="mr-b20"><h4>Tokens:</h4></div> */}
                    <Tabs
                        id="tokens-tab"
                        activeKey={tabKey}
                        onSelect={(k) => setTabKey(k)}
                        className="mb-3 tabs"
                    >
                        <Tab eventKey="available" title="Available" data-cy="wallet-available-tab">
                            {pagePart}
                        </Tab>
                        <Tab eventKey="redeemed" title="Redeemed" data-cy="wallet-redeemed-tab" >
                            {pagePart}
                        </Tab>
                        <Tab eventKey="expired" title="Expired" data-cy="wallet-expired-tab">
                            {pagePart}
                        </Tab>
                    </Tabs>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}
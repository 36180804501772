import React, {useState, useEffect, useCallback, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner, Form, Container, Row, Col, InputGroup, Card } from 'react-bootstrap'
import * as actions from '../../../../../store/actions'
import Pos from '../../../../../api/Pos';
import APIGiftCards from '../../../../../api/GiftCards';
import usePrevious from '../../../../../components/common/CustomHooks';
import '../splitPayment.scss'
import { updateCart } from '../../../../../../src/utils/thunks';
import { cancelSplit } from '../SplitPaymentUtils';

import styles from './CashCardSplit.module.scss';

export const CashCardSplit = (props) => {
    const { onClose, register, type, currentOrder, selectedUser } = props;

    const dispatch = useDispatch();

    const gcRef = useRef();
    const mountedRef = useRef(false);

    const posOrder =useSelector(state=>state.pos[register].orderAll);
    const reduxErrors = useSelector(state=> state.splitPayments.errors);
    const splitPaymentSuccess = useSelector(state=> state.splitPayments.ordersSplit);
    const cashPaid=useSelector(state => state.splitPayments.cashSuccess)
    const totalAmount = (posOrder?.subtotal_price + posOrder?.tax_total)
    const allOrders=useSelector(state=> state.splitPayments.allOrders);
    const adminFeeRate=useSelector(state=>state.pos.adminfee_rate)
    const [alreadySplit, setAlreadySplit]=useState(false);
    const [localError, setLocalError]=useState();
    const [submitting, setSubmitting]=useState(false);
    const [cashAmount, setCashAmount]=useState(0.00); //how much is cash
    const [cardAmount, setCardAmount]=useState(0.00); //how much will go on card
    const [giftCardAmount, setGiftCardAmount]=useState(0.00); //how much will go on gift card
    const [giftCards, setGiftCards] = useState([]); //gift cards used for payment
    const [split, setSplit]=useState(); //the response from splitting the orders with the ids
    const [disabled, setDisabled]=useState(true)
    const oldCard = usePrevious(cardAmount);
    const oldCash = usePrevious(cashAmount);
    const oldGiftCard = usePrevious(giftCardAmount);

//#region useCallback
    //after the order has been successfully split, gets the orders for the next steps
    const getSplits=useCallback(async()=>{
        if(split){
            let orders = []
            for(let i = 0;i<split.split_order_ids.length; i++){
                try{
                    let response = await Pos.order.get({id: split.split_order_ids[i]})
                    if(!response.errors && mountedRef.current){
                        orders.push(response.data)
                    }
                }catch(ex){console.error(ex)}
            }
            setSubmitting(false);
            dispatch(actions.setSplitPaymentSubmitting(false));
            dispatch(actions.setSplitPaymentAllOrders(orders));
        }
    },[split, dispatch]);

    //if the back button is used or the modal is loaded and a split already exists
    const checkForSplits=useCallback(async()=>{
        if(!split){
            let tempOrders = []
            let cash;
            let card;
            if(posOrder?.split_order_ids?.length && allOrders.length > 0 ){
                setAlreadySplit(true)
                tempOrders = allOrders
                tempOrders.forEach((order)=>{
                    if(order.memo==="Cash Portion") cash = (order.subtotal_price+order.tax_total).toFixed(2)
                    else if(order.memo==="Card Portion") card = (order.subtotal_price+order.tax_total).toFixed(2)
                })
            } 
            else if(posOrder.split_order_ids.length > 0 && allOrders.length === 0){
                setAlreadySplit(true)
                await posOrder.split_order_ids.forEach(async(id)=>{
                    try{
                        let response = await Pos.order.get({id: id})
                        if(response.status===200 && mountedRef.current){
                            tempOrders.push(response.data)
                            if(response.data.memo ==="Cash Portion") cash = (response.data.subtotal_price+response.data.tax_total)
                            if(response.data.memo==="Card Portion") card =(response.data.subtotal_price+response.data.tax_total)
                        }else if(response.errors && mountedRef.current){
                            onClose()
                        }
                    }catch(ex){console.error(ex)}
                })
            }
            if(tempOrders.length >0){
                setCashAmount(cash);
                setCardAmount(card);
                dispatch(actions.setSplitPaymentAllOrders(tempOrders));
                dispatch(actions.setSplitPaymentSubmitting(false));
                dispatch(actions.setOrdersHaveBeenSplit(true));
                if(!cashPaid){
                    dispatch(actions.setSplitPaymentCashValue(cash));
                    dispatch(actions.setSplitPaymentRemaining(+card));
                }
            }
        }
    //adding onClose causes an infinite loop when coming back to this component from the next step
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[posOrder, allOrders, dispatch, split, cashPaid]);
//#endregion useCallback

//#region useEffect

    useEffect(()=>{
        mountedRef.current = true

        return()=>{
            mountedRef.current = false
        }
    },[]);

    useEffect(()=>{
        if(mountedRef) checkForSplits()
    },[checkForSplits]);

    useEffect(()=>{
        if(mountedRef) getSplits();
    },[getSplits]);

    useEffect(()=>{
        setLocalError();
        let disabled = false;
        if (submitting) disabled = true;
        if (cashAmount ===0 || cashAmount === "") disabled = true;
        if (cardAmount ===0 || cardAmount === "") disabled = true;
        if (giftCards.length <= 0) disabled = true;
        if (splitPaymentSuccess) disabled = true;
        setDisabled(disabled);
    },[submitting, cashAmount, cardAmount, giftCards, splitPaymentSuccess]);
    
    //change remainder based on amounts input
    useEffect(()=>{
        if(+cashAmount !== +oldCard && +cardAmount === +oldCard){
            if(alreadySplit){
                cancelSplit(posOrder.id);
                setAlreadySplit(false);
                dispatch(actions.setOrdersHaveBeenSplit(false));
                setSplit();
            }
            let remainder = totalAmount - (+cashAmount || 0)
            let fixed = remainder.toFixed(2)
            setCardAmount(+fixed)
        }
    // oldCard, cardAmount, oldCash, totalAmount, alreadySplit, cancelSplit are all there just to check what their current status is
    // NONE of these should trigger the useEffect.  Doing so BREAKS split payments
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[cashAmount, dispatch]);

    useEffect(()=>{
        if(cardAmount !== +oldCard && +cashAmount === +oldCash){
            if(alreadySplit) {
                cancelSplit(posOrder.id);
                setAlreadySplit(false);
                dispatch(actions.setOrdersHaveBeenSplit(false));
                setSplit();
            }
            let remainder = totalAmount - (+cardAmount || 0)
            let fixed = remainder.toFixed(2)
            setCashAmount(+fixed)
        }
    // oldCard, cardAmount, oldCash, totalAmount, alreadySplit, cancelSplit are all there just to check what their current status is
    // NONE of these should trigger the useEffect.  Doing so BREAKS split payments
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[cardAmount, dispatch]);

//#endregion useEffect

    const splitTheOrders=async()=>{
        setSubmitting(true)
        dispatch(actions.setSplitPaymentRemaining(cardAmount))
        dispatch(actions.setSplitPaymentCashValue(cashAmount))
        dispatch(actions.setSplitPaymentSubmitting(true))

        const epsilon = 0.01; // floating point math is hard ----- the +.01 and -.01 give bad JS math some wiggle room to still "equal" the same thing
        if(cashAmount >0 && cardAmount>0 && (+cashAmount + +cardAmount) <= (currentOrder.subtotal_price + currentOrder.tax_total + epsilon) && (+cashAmount + +cardAmount) >= (currentOrder.subtotal_price + currentOrder.tax_total - epsilon)){
            try{
                let response = await Pos.payment.split_payment({
                    order_id: currentOrder.id,
                    type: "amounts",
                    amounts: [
                        {
                            amount: cashAmount,
                            user_id: currentOrder.user_id,
                            memo: "Cash Portion"
                        },
                        {
                            amount: cardAmount,
                            user_id: currentOrder.user_id,
                            memo: "Card Portion"
                        }
                    ]
                })
                if(response.errors){
                    setLocalError(`There was an issue splitting the order. ${response.errors}. Please try again.`)
                    setSubmitting(false)
                    dispatch(actions.setSplitPaymentSubmitting(false))  
                } 
                else if(!response.errors){
                    setSplit(response.data)
                    dispatch(actions.setOrdersHaveBeenSplit(true))
                    dispatch(updateCart(register))
                }                    
            }catch(ex){
                console.error(ex)
            }
        }else{
            setSubmitting(false)
            dispatch(actions.setSplitPaymentSubmitting(false))
            setLocalError("Please make sure the two values add up to the total price.")
        }
    }

    const giftCardLookupHandler = useCallback(async e => {
        e.preventDefault();

        const code = gcRef?.current?.value;
        console.log(code)
        if (!code) return;
        
        const res = await APIGiftCards.get({card_code: code, with_balance: 1});
        if (res?.errors) setLocalError(res.errors);
        else if (res?.data?.giftcards?.data.length) {
            setLocalError(null);
            let _total = 0;
            setGiftCards(prev=>{
                const _prev = [...prev];
                if (!_prev.find(a=>a.id===res.data.giftcards.data[0].id)) _prev.push(res.data.giftcards.data[0]);
                _total = _prev.reduce((a,b)=>a+b.current_balance, 0);
                return [..._prev];
            });
            setGiftCardAmount(_total);
        }
        gcRef.current.value = "";
    }, []);    

    return (
        <Container fluid className="text-center">
            <h4 className="card-title">How would the customer split ${totalAmount?.toFixed(2)}?</h4>
            <span>This total does not include the cash discount as it's a percentage based on cash only payments.</span>
            <fieldset disabled={cashPaid} className={styles.wrapper} style={{maxWidth:"350px"}}>
                <Form.Group controlId="cashAmount" as={Row} style={{width:"100%"}}>
                    <Col sm="3">
                        <Form.Label>Cash</Form.Label>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="number" placeholder="$0.00" name="cashAmount" value={cashAmount} onChange={(e)=>setCashAmount(e.target.value)} />
                            <Form.Text className="text-muted">
                                - {adminFeeRate}% cash discount (approximately ${(cardAmount * (adminFeeRate/100)).toFixed(2)})
                            </Form.Text>                            
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group controlId="cardAmount" as={Row} style={{width:"100%"}}>
                    <Col sm="3">
                        <Form.Label>Card</Form.Label>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="number" placeholder="$0.00" name="cardAmount" value={cardAmount} onChange={(e)=>setCardAmount(e.target.value)} />
                            {/*<Form.Text className="text-muted">
                                + {adminFeeRate}% admin fee (approximately ${(cardAmount * (adminFeeRate/100)).toFixed(2)})
                            </Form.Text>*/}
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group controlId="giftCardAmount" as={Row} style={{width:"100%"}}>
                    <Col sm="3">
                        <Form.Label>Gift Card</Form.Label>
                    </Col>
                    <Col>
                        <InputGroup>
                            <Form.Control ref={gcRef} type="text" placeholder="Gift Card Code" />
                            <InputGroup.Append>
                                <Button variant="light" onClick={giftCardLookupHandler}>Check</Button>
                            </InputGroup.Append>

                            {giftCards?.map((giftCard, i) => (
                                <Form.Text key={`pos-giftc-${giftCard.id}-${i}`} className="text-muted w-100">
                                    <Card className={styles["gc-wrapper"]}>
                                        <Card.Body>
                                            {giftCard.card_code}<br/>
                                            Balance: <span className="bold">${giftCard.current_balance.toFixed(2)}</span>
                                        </Card.Body>
                                        <Card.Body className={`text-link ${styles.toolbar}`}>
                                            <Button variant="link" href="#!" /*onClick={e=>removeHandler(e, giftCard.id)}*/>Remove</Button>
                                        </Card.Body>
                                    </Card>
                                </Form.Text>
                            ))}

                        </InputGroup>
                    </Col>
                </Form.Group>
            </fieldset>
            <Row>
                <Col>
                    <Button type="button" onClick={splitTheOrders} disabled={disabled}>Confirm Amounts</Button>
                </Col>
            </Row>
            <Row>
                <Col className="sp-error">
                    {localError &&
                        <p>{localError}</p>
                    }
                    {reduxErrors.notSplit &&
                        <p>{reduxErrors.notSplit}</p>
                    }
                    {reduxErrors.notEqual &&
                        <p>{reduxErrors.notEqual}</p>
                    }
                    {reduxErrors.notCreated &&
                        <p>{reduxErrors.notCreated}</p>
                    }
                </Col>
            </Row>
            <Row>
                <Col className="sp-success">
                    <p>
                        {cashPaid ?
                            <span>Part of the payment has already been complete.</span>
                        :
                            <>
                                {splitPaymentSuccess ? 
                                    <span>You may continue to the next step.</span>
                                    :
                                    <span>Please divide up your order accordingly.</span>
                                }
                            </>
                        }      
                    </p>
                    {submitting && 
                        <div className="text-company-secondary sp-center-text">
                            <Spinner animation="grow" variant="info" /> 
                            <p>
                                Orders are being created
                            </p>
                        </div>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default CashCardSplit;

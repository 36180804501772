import React, {useState, useEffect, useMemo} from 'react';
//import { useHistory } from "react-router-dom";
import {format} from 'date-fns';
import {Row, Col, Card, Button, Modal} from 'react-bootstrap';

import { randomUUID } from '../../../../../utils/cms';
import EventDetail from './EventDetail';
import APIEvents from '../../../../../api/Events';

const EventHeading = (props) => {
    return (
        <>
            {props?.title && 
                <Col sm={12} className={`col_${props.page_id} title-col_${props.page_id} flex-column`}>
                    {props?.subtitle && <span>{props.subtitle}</span>}
                    <h1>{props.title}</h1>
                </Col>
            }
            {!props.data && <p>Loading...</p>}
            {props.data && props.data.length<=0 &&
                <Col sm={12} className={`col_${props.page_id} no-data-col_${props.page_id}`}>
                    <p>No upcoming events.</p>
                </Col>
            }
        </>
    );
}

const HorizontalLayout = (props) => {
    return (
        <>
            {props.data && props.data.map((item, i) => (
                <Col sm={12} lg={+props?.limit===1?12:4} key={`event-card-horizontal-${item.id}-${i}-${randomUUID()}`} className={`col_${props.page_id}`}>
                    <Card className={`card_${props.page_id}`}>
                        <Row>
                            {item.image &&
                                <Col sm={12} lg={6} className={`col_${props.page_id} ${props?.order==="inverse"?"order-2":""}`}>
                                    <Card.Img variant="top" src={item.image || props.company_context.logoNoText} className={`card-img-top_${props.page_id}`} style={{objectFit:"cover",aspectRatio:" 1 / 1"}} alt={item.name} />
                                </Col>
                            }
                            <Col sm={12} lg={item.image?6:12} className={`col_${props.page_id} ${props?.order==="inverse"?"order-1":""}`}>
                                <Card.Body className={`card-body_${props.page_id}`}>
                                    <Card.Title className={`card-title_${props.page_id}`}>{item.name}</Card.Title>
                                    <Card.Text className={`card-text_${props.page_id}`}>{format(new Date(item.start_datetime), "MMM dd h:mm a")}</Card.Text>
                                    <Card.Text className={`card-text_${props.page_id}`}>{item.location_name}</Card.Text>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            ))}
        </>
    );
}

const VeticalLayout = (props) => {
    return (
        <>
            {props.data && props.data.map((item, i) => (
                <Col sm={12} lg={+props?.limit===1?12:4} key={`event-card-vertical-${item.id}-${i}-${randomUUID()}`} className={`col_${props.page_id}`}>
                    <Card className={`card_${props.page_id}`}>
                        {item.image &&
                            <Card.Img variant="top" src={item.image || props.company_context.logoNoText} className={`card-img-top_${props.page_id}`} style={{objectFit:"cover",aspectRatio:" 1 / 1"}} alt={item.name} />
                        }
                        <Card.Body className={`card-body_${props.page_id}`}>
                            <Card.Title className={`card-title_${props.page_id}`}>{item.name}</Card.Title>
                            <Card.Text className={`card-text_${props.page_id}`}>{format(new Date(item.start_datetime), "MMM dd h:mm a")}</Card.Text>
                            <Card.Text className={`card-text_${props.page_id}`}>{item.location_name}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </>
    );
}

const UpcomingEvents = (props) => {
    //const history = useHistory();
    let dummyData = useMemo(() => [], []);
    const {data: _data , event_types, limit, placeholder_images, show_images} = props;
    const [data, setData] = useState([]);
    const [eventId, setEventId] = useState();
    const [showModal, setShowModal] = useState(false);

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    useEffect(() => {
        if (props.is_preview){
            if (!dummyData.length){
                for (let i=0;i<(+limit || 3);i++){
                    dummyData.push({
                        id: i+1,
                        name: `Event ${i+1}`,
                        start_datetime: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
                        end_datetime: new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000),
                        description: "Placeholder description",
                        location_name: "Location 1",
                        image: null
                    });
                }
            }
        }
    }, [props.is_preview, limit, dummyData]);

    useEffect(() => {
        return () => {
            setData([]);
            setEventId(null);
            setShowModal(false);
        }
    }, []);   
        
    useEffect(() => {
        // grab placeholder images from pexels
        const _loadPlaceholderImages = async () => {
            try{
                const response = await fetch(`https://api.pexels.com/v1/search?query=${placeholder_images}&per_page=${limit || 3}`, {
                    method: "GET",
                    headers: {Authorization: process.env.REACT_APP_PEXELS_API_KEY}
                });
                const data = await response.json();
                if (data?.photos) return data.photos;
            } catch (e){
                console.log(e);
            }
            return null;
        }

        // formats the data for the view
        const _formatData = async (data) => {
            if (data?.events.length>0){
                if (placeholder_images && show_images){
                    const images=await _loadPlaceholderImages();
                    if (images){
                        images.forEach((item,i)=>{
                            if (data.events?.[i] && !data.events[i]?.image) data.events[i].image=item.src.large;
                        });
                    } else data.events.forEach((_,i)=>data.events[i].image="https://via.placeholder.com/250");
                }
                if (!show_images){
                    data.events.forEach((_,i)=>{data.events[i].image=null;});
                }
            }
            return data;
        }
        
        // load the events
        const _loadEvents = async () => {
            try{
                const response = await APIEvents.publicGet({
                    start_datetime: "now",
                    max_records: limit || 3,
                    sort_col: "start_datetime",
                    sort_direction: "asc",
                    event_types: event_types || null
                });
                if (response?.data?.events.length>0){
                    const _data = await _formatData(response.data);
                    setData(_data.events);
                }
            } catch (e){
                console.log(e);
            }
        }

        if (_data) setData(_data);
        else {
            if (props.is_preview) _formatData({events:dummyData}).then(_data=>setData(_data.events));
            else _loadEvents();
        }
    }, [_data, limit, event_types, placeholder_images, show_images, props.is_preview, dummyData]);


    const clickHandler = (e) => {
        if (!props.is_preview){
            if (limit===1) {
                setEventId(data?.[0]?.id);
                setShowModal(true);
            } else {
                window.location.href = `${window.location.origin.toString()}/view-events`;
            }
        }
    }

    const closeModalHandler=()=>{
        setShowModal(false);
    }

    return (
        <div className={`upcoming-events_${props.page_id} ${props.className || ""}`} style={props?.style || null} onClick={preview_click} key={randomUUID()}>
            <Row className={`row_${props.page_id} w-100`}>
                <EventHeading {...props} data={data} />

                
                { /* VERICAL LAYOUT */
                    (!props.layout || props?.layout==="vertical") && 
                        <VeticalLayout {...props} data={data} />
                }

                { /* HORIZONTAL LAYOUT */            
                    props?.layout==="horizontal" &&
                        <Col sm={12} lg={props?.title?12:6} className={`col_${props.page_id}`}>
                            <Row className={`row_${props.page_id}`}>
                                <HorizontalLayout {...props} data={data} />
                            </Row>
                            {data?.length>0 &&
                                <Row className={`row_${props.page_id}`}>
                                    <Col className={`col_${props.page_id}`}>
                                        <Button href="#!" onClick={clickHandler} className={`btn_${props.page_id}`}>View {limit>1 && "All"}</Button>
                                    </Col>
                                </Row>
                            }
                        </Col>
                }
            </Row>
            {(!props.layout || props?.layout==="vertical") && data?.length>0 &&
                <Row className={`row_${props.page_id}`}>
                    <Col className={`col_${props.page_id}`}>
                        <Button href="#!" onClick={clickHandler} className={`btn_${props.page_id}`}>View {limit>1 && "All"}</Button>
                    </Col>
                </Row>
            }

            {eventId &&
                <Modal show={showModal} onHide={closeModalHandler} size={"m"} centered>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <EventDetail {...props} id={eventId} />
                    </Modal.Body>
                </Modal>
            }

        </div>
    );
}

export default UpcomingEvents;
import Request from './Api';


// let notes = {} //Turn on for test

//read/get
const get = async(props)=>{
    // if (["localhost", "127.0.0.1", ""].includes(window.location.hostname)){
    //     let mockData = await test();
    //     if (!props)notes=mockData;
    //     else{
    //         mockData.forEach((data, i, test)=>{
    //             if(props.id){
    //                 if (data.id ===parseInt(props.id)){
    //                     notes=test[i];
    //                     return false;
    //                 }
    //             }
    //         })
    //     }
    // } //Set-up for test data
    return(
        Request({
            url: "/user/notes/"+props.userId,
            method: "GET", 
            // test: {data:notes}  //Turn on for test
        })
    );
}

//create
const create = async(props)=>{

    // let mockdata= {data: null, errors:["note is required", "user_id is required."]}; //error-turn on for test
    // let mockdata= {"errors":null,"data":[{"id":4}]} //success-turn on for test

    return(
        Request({
            url: "user/notes/add",
            method: "POST",
            data: {...props},
            // test: mockdata //Turn on for test
        })
    )
}

//update
const edit = async(props)=>{

    //  let mockdata= {data: null, errors:["note is required", "user_id is required."]}; //error
    //  let mockdata= {"errors":null,"data":[{"id":4}]} //success
     
     return(
         Request({
             url: "user/notes/edit",
             method: "POST", 
             data: props,
            //  test: mockdata
            })
            )
        }
        
        //delete
const remove = async(props)=>{

        // let mockdata= {data: null, errors:["id is required."]}; //error -turn on for test 
        // let mockdata= {"errors":null,"data":[{"id":4}]} //success - turn on for test

    return(
        Request({
            url: "/notes/delete",
            method: "POST",
            data: {props},
            // test: mockdata //- turn on for test
        })
    )
}

const test =async()=>{
    return new Promise((resolve)=>{
        let dummyData=[
                {
                    "id":1, //note id
                    "author_user_id":1338,
                    "author_first_name":"Geralt",
                    "author_last_name":"of Rivia",
                    "note":"He heard singing.  He didn’t understand the words; he couldn’t even identify the language.  He didn’t need to – the witcher felt and understood the very nature, the essence, of this quiet, piercing singing which flowed through the veins in a wave of nauseous, overpowering menace.",
                    "status":1,
                    "created_at":"2022-01-06T19:52:33.000000Z"
                },
                {
                    "id":2, //note id
                    "author_user_id": 1337,
                    "author_first_name": "Joe",
                    "author_last_name": "Bishop",
                    "note": "But you’re not that smart, I mean, your species is responsible for Windows Vista.” “Vist- that was a long time ago!” “It’s still an insult to computers across the galaxy.",
                    "status": 2,
                    "created_at": "2022-01-01T19:54:33.000000Z"
                },
                {
                    "id":3, //note id
                    "author_user_id": 1338,
                    "author_first_name": "Skippy",
                    "author_last_name": "The Magnificent",
                    "note": "Also, I had to remember that word ‘committee’ had two Ms, two Ts and two Es in only nine letters, which told me that a committee was not a good way to do anything efficiently",
                    "status": 3,
                    "created_at": "2022-01-03T19:54:33.000000Z"
                },
        
                {
                    "id":4, //note id
                    "author_user_id": 1337,
                    "author_first_name": "Atticus",
                    "author_last_name": "O'Sullivan",
                    "note": "Like many silly codes of bravery and manliness, the meat of my father’s instruction on how to die well can be distilled to a simple slogan: Die angry at maximum volume. (Dying silently is out of the question; the world’s last Druid should not go gentle into that good night.)",
                    "status": 2,
                    "created_at": "2021-12-06T19:54:33.000000Z"
                },
                {
                    "id":5, //note id
                    "author_user_id": 1337,
                    "author_first_name": "Locke",
                    "author_last_name": "Lamora",
                    "note": "We’re a different sort of thief here, Lamora. Deception and misdirection are our tools. We don’t believe in hard work when a false face and a good line of bull can do so much more.",
                    "status": 4,
                    "created_at": "2022-01-05T19:54:33.000000Z"
                },
                {
                    "id":6, //note id
                    "author_user_id": 1338,
                    "author_first_name": "Durian",
                    "author_last_name": "Millpot",
                    "note": "The toast always lands butter side down.",
                    "status": 2,
                    "created_at": "2021-12-12T19:54:33.000000Z"
                },
                {
                    "id":7, //note id
                    "author_user_id": 1337,
                    "author_first_name": "Durian",
                    "author_last_name": "Millpot",
                    "note": "The toast always lands butter side down.",
                    "status": 3,
                    "created_at": "2022-01-04T19:54:33.000000Z"
                },
                {
                    "id":8, //note id
                    "author_user_id": 1337,
                    "author_first_name": "Durian",
                    "author_last_name": "Millpot",
                    "note": "Different Note",
                    "status": 3,
                    "created_at": "2022-01-06T19:54:33.000000Z"
                },
                {
                    "id":9, //note id
                    "author_user_id": 1337,
                    "author_first_name": "Durian",
                    "author_last_name": "Millpot",
                    "note": "A note about toast",
                    "status": 3,
                    "created_at": "2022-01-02T19:54:33.000000Z"
                }

        ];
        resolve(dummyData);
    });
}

const NoteApi = {
    get, create, edit, remove //CRUD
}

export default NoteApi
import React,{useEffect,useState,useRef,Suspense} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button } from 'react-bootstrap';
// import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import Canvas from '../../../components/Canvas';

import LocationsAPI from '../../../api/Locations';
import Pos from '../../../api/Pos';
import {stringToUUID} from '../../../api/Common';

import * as actions from '../../../store/actions';

import './Locations.css';

export const Locations = (props) => {
    const dispatch = useDispatch();

    const ref = useRef(null);
    const selected_location=useSelector(state => state.map.selected_items);
    const active_register=useSelector(state => state.pos.register);
    const [tables,setTables]=useState();
    const [show,setShow]=useState(true);

    let clicked=false;

    const clickHandler = (item) => {
        dispatch(actions.reset());
        dispatch(actions.selectedItems(null));
        dispatch(actions.selectedItems(
            {...item?.[0],
                ...{booking: 
                    {
                        event_name: "",
                        event_description: "",
                        start_datetime: null,
                        end_datetime: null,
                    }
                }
            }
        ));

        if (!clicked){
            // saves the tab to local storage
            let tab={
                hash: stringToUUID(Math.random()),
                id: item?.[0]?.id,
                name: item?.[0]?.name,
            };

            let found=0;
            Pos.local.get(`POS-TAB-${active_register}`).then(res=>{
                if (res){
                    if (!Array.isArray(res)) res=[res];
                    res.forEach(i=>{
                        if (i.id===item?.[0]?.id) {
                            found=1;
                            tab=i;

                            // updates redux
                            if (i.items){
                                i.items.forEach(j=>{
                                    dispatch(actions.addPOSItem(j));
                                })
                            }
                            
                        }
                    });
                }

                if (found===1){
                    Pos.local.update(`POS-TAB-${active_register}`,tab).then(a=>{
                        setShow(false);
                    });
                } else {
                    Pos.local.append(`POS-TAB-${active_register}`,tab).then(a=>{
                        setShow(false);
                    });
                }
    
                if (props.onClick) props.onClick();                
            });


        }
    }

	useEffect(() => {
        let mounted = true;

        // remove tables:yes and replace with category for restaurant tables
        LocationsAPI.get({tables:"yes"}).then(response=> {
            if(mounted) {
                setTables(response.data[0].children);
            }
        }).catch(e => console.error(e));

        return () => {
            mounted = false;
        }

	}, []);
    
    if (!props.show && selected_location && selected_location.length) return null;

    if (!tables){
        return (
            <Suspense fallback={
                <SkeletonTheme color="#e0e0e0">
                    <Skeleton height={30} style={{marginBottom:"1rem"}} />
                    <Skeleton height={12} count={5} />
                </SkeletonTheme>
            }>
            </Suspense>
        );
    } else {
        return (
            <div ref={ref} style={{display:show?"block":"none"}}>
                {/* <TransformWrapper maxScale={1.5} minScale={0.75}>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <div>
                            <div className="tools">
                                <Button onClick={() => zoomIn()}>
                                    <i className="far fa-search-plus" />
                                </Button>
                                <Button onClick={() => zoomOut()}>
                                    <i className="far fa-search-minus" />
                                </Button>
                                <Button onClick={() => resetTransform()}>Reset</Button>
                            </div>
                            <TransformComponent>
                                <Canvas items={tables} clickHandler={clickHandler} />
                            </TransformComponent>
                        </div>
                    )}
                </TransformWrapper> */}
            </div>
        );
    }
}
import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';

import SignUpFamily from '../../../SignUpFamily';
import {Member} from './Member';

import APIUser from '../../../../../../../api/Users';
import APIGroups from '../../../../../../../api/Groups';
import APIEvents from '../../../../../../../api/Events';

export const Step2 = (props) => {
    const {disableNext, saveStepValues} = props;
    const user = useSelector(state => state.auth.user.profile);

    const [loading, setLoading] = useState(false);
    const [familyMembers, setFamilyMembers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showAddFamily, setShowAddFamily] = useState(props?.showForm ? true : false);
    const [familyGroupId, setFamilyGroupId] = useState();
    const [customFields, setCustomFields] = useState([]);

    useEffect(() => {
        if (props.stepValues?.selectedUsers) setSelectedUsers(props.stepValues.selectedUsers.map(a=>+a.id));
        //else setSelectedUsers([user.id]);
    }, [props.stepValues]);

    useEffect(() => {
        const _loadFamilyMembers = async (user_id) => {
            setLoading(true);
            let members = [];
            if (user_id){
                const res = await APIUser.get({id: user_id});
                if (res?.data){
                    let _families = res.data[0].family_groups || [];
                    let _groupId = res.data[0]?.family_groups?.[0]?.id || null;
                    if (!_groupId){
                        const res2 = await APIGroups.create({name: `${res.data[0]?.last_name} Family`, group_type_id: 4, group_status_id: 1, group_member_role_id: 1});
                        if (res2?.data?.[0]?.id) {
                            _groupId = res2.data[0].id;
                            _families = [{id: _groupId}];
                        }
                    }
                    setFamilyGroupId(_groupId);
                    if (_families.length>0 && _families[0].id){
                        const family = _families[0];
                    //for (const family of _families){
                        const res2 = await APIGroups.get({id:family.id});
                        if (res2?.data?.length>0){
                            for (const member of res2.data[0].group_members.filter(a=>+a.group_member_status_id===2 && a.first_name && a.last_name)){
                                let disabled = false, msg = "";
                                if (!props?.users){
                                    const res3=await APIEvents.publicGet({id: props.id, user_id: member.user_id});
                                    if (res3.data.events.length>0) disabled=true;
                                } else if (props.users.findIndex(a=>+a.id===+member.user_id)>-1) disabled=true;

                                if (disabled) msg = "Already registered";

                                // check if the users dob is between the min and max age
                                if (props.max_age || props.min_age){
                                    if (member.dob){
                                        const dob = new Date(member.dob);
                                        const today = new Date();
                                        let age = today.getFullYear() - dob.getFullYear();
                                        const m = today.getMonth() - dob.getMonth();
                                        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) age--;
                                        if (props.max_age && age>props.max_age) disabled=true;
                                        if (props.min_age && age<props.min_age) disabled=true;
                                    } else disabled = true;

                                    if (disabled && !msg) msg = "Does not meet age requirement";
                                }

                                members.push({
                                    id: member.user_id,
                                    first_name: member.first_name,
                                    last_name: member.last_name,
                                    group_member_role: member.group_member_role_name,
                                    disabled: disabled,
                                    message: msg
                                });
                            }
                        }
                    }
                } else {
                    members.push({
                        id: user_id,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        group_member_role: "Me",
                        disabled: false
                    });
                }
            }
            members.sort((a,b)=>{
                if (a.disabled && !b.disabled) return 1;
                if (!a.disabled && b.disabled) return -1;
                return 0;
            });
            setFamilyMembers(members);
            setLoading(false);
        }

        if (user.id){
            //setSelectedUsers(prev=>[...prev,user.id]);
            _loadFamilyMembers(user.id);
        }
    }, [user, props.id, props?.users, props?.max_age, props?.min_age, showAddFamily]);

    useEffect(() => {
        if (familyMembers.length>0){
            setSelectedUsers(prev=>{
                let newSelectedUsers = [...prev];
                if (prev.length>0){
                    for (const member of familyMembers){
                        if (member.disabled && newSelectedUsers.includes(member.id)){
                            newSelectedUsers = newSelectedUsers.filter(a=>+a!==+member.id);
                        }
                    }
                }
                return newSelectedUsers;
            });
        }
    }, [familyMembers]);

    useEffect(() => {        
        const _loadCustomFields = async () => {
            if (props?.id){
                const response2 = await APIEvents.get_custom_fields({event_id: props.id});
                if (response2?.data) setCustomFields(response2.data.filter(a=>a.placeholder_text));
            }
        }
        _loadCustomFields();
    }, [props?.id]);    

    useEffect(() => {
        if (selectedUsers.length<=0) {
            disableNext(true);
        }
    }, [selectedUsers, disableNext]);

    useEffect(() => {
        return () => {
            setFamilyMembers([]);
            setFamilyGroupId(null);
            setSelectedUsers([]);
            setLoading(false);
            setShowAddFamily(false);
            setCustomFields([]);
        }
    }, []);

    // check if the next button should be disabled based on the custom fields
    const checkIfDisabled = (user = null, defaultValue=true) => {
        if (user && !Array.isArray(user)) user = [user];
        if (!user) user = selectedUsers.map(a=>a.id);

        let _disabledNext = defaultValue;
        if (customFields.length>0) _disabledNext = true;

        const fields = customFields.filter(a=>a.required);
        if (fields.length>0){
            user.forEach(user_id => {
                fields.forEach(item =>{
                    if (props?.stepValues?.customFields){
                        if (props.stepValues.customFields?.[user_id]) {
                            if (props.stepValues.customFields[user_id].filter(a=>`${a.custom_field_id}`===`${item.name}` && a.value).length>0) _disabledNext=false;
                            else {
                                _disabledNext=true;
                                return false;
                            }
                        }
                    }
                });
            });
        } else _disabledNext=false;
        return _disabledNext;
    }

    const memberClickHandler = e => {
        e.preventDefault();
        e.stopPropagation();

        let _disabledNext = true, newSelectedUsers = [...selectedUsers];

        if (e.target.checked){
            if (!newSelectedUsers.includes(+e.target.value)) newSelectedUsers.push(+e.target.value);
        } 
        else newSelectedUsers = newSelectedUsers.filter(a => +a !== +e.target.value);

        const _selected = [];
        newSelectedUsers.forEach((user, i) => {
            const newSelectdUserData = familyMembers.filter(a => +a.id === +user);
            if (newSelectdUserData.length > 0) _selected.push({id: user, ...newSelectdUserData[0]});
        });

        if (newSelectedUsers.length > 0) _disabledNext = false;
        else _disabledNext = true;

        _selected.forEach(member => {
            _disabledNext = checkIfDisabled(member.id, _disabledNext);
        });

        setSelectedUsers(newSelectedUsers);
        saveStepValues({selectedUsers: _selected});
        disableNext(_disabledNext);
    }
    
    const toggleFamilyHandler = e => {
        const _showAddFamily = !showAddFamily;
        let _disabledNext = true;
        if (_showAddFamily) _disabledNext=true;
        else _disabledNext = checkIfDisabled();
        disableNext(_disabledNext);
        setShowAddFamily(_showAddFamily);
    }

    const customFieldChangeHandler = (e, user_id) => {
        const {name, value} = e.target;
        const required = e.target?.required || false;

        const newFieldValues = {...props?.stepValues?.customFields || {}};
        if (user_id){
            const _field = {
                id: user_id,
                custom_field_id: name.replace('custom_', '').replace(`_${user_id}`, ''),
                custom_field_label: e.target.getAttribute('placeholder'),
                value: value,
                label: e.target?.options?.[e.target?.selectedIndex]?.text || value,
                required: required,
            }

            if (!newFieldValues[user_id]) newFieldValues[user_id] = [];
            const _idx =newFieldValues[user_id].findIndex(a=>a.custom_field_id===_field.custom_field_id)
            if (_idx>=0) newFieldValues[user_id][_idx] = _field;
            else newFieldValues[user_id].push(_field);

            const _disabledNext = checkIfDisabled(user_id);
            disableNext(_disabledNext);
        }        
        saveStepValues({customFields: newFieldValues});
    }

    return (
        <>
            <div>
                <p>
                    <label className="form-label">Who is attending the event?</label>
                </p>
                {props.registration_msg &&
                    <div dangerouslySetInnerHTML={{__html:props.registration_msg}} />
                }
                {!props.registration_msg &&
                    <p className="bold">
                        Please fill out the following form and answer all questions.
                    </p>
                }
                
                {familyMembers && familyMembers.filter(a=>a.disabled).length>0 &&
                    <>
                        <label className="form-label">People that cannot be added to the event:</label>
                        <ul>
                            {familyMembers.filter(a=>a.disabled).map((member, i) => (
                                <li key={`family-member-disabled-${i}`}>
                                    {member.first_name} {member.last_name} ({+member.id === +user.id ? "Me" : member.group_member_role}) -- {member.message}
                                </li>
                            ))}
                        </ul>
                    </>
                }

                <Button variant="light" onClick={toggleFamilyHandler} disabled={loading || showAddFamily}>Add Family Member</Button>
                <br/>

                {showAddFamily && familyGroupId &&
                    <div className="mb-3">
                        <SignUpFamily {...props} callback={toggleFamilyHandler} /*forceMobile*/ group_id={familyGroupId} success_title="Welcome to the family!" />
                        <a href="#!" onClick={toggleFamilyHandler} style={{display:"block",textAlign:"end"}}>Close</a>
                    </div>
                }

                {familyMembers && familyMembers.filter(a=>!a.disabled).map((member, i) => (
                    <Member 
                        key={`family-member-${i}`}
                        {...props}
                        member={member}
                        selected={selectedUsers.includes(+member.id)}
                        click={memberClickHandler}
                        customFields={customFields}
                        customFieldChange={customFieldChangeHandler}
                    />
                ))}
                {loading && <p>Loading ...</p>}
            </div>

        </>
    );
}
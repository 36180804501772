import React from 'react';
import BootstrapSpinner from 'react-bootstrap/Spinner';

import './Spinner.scss';

export const Spinner = () => {

    return (
        <>
            <BootstrapSpinner animation="border" variant="secondary" />
        </>
    )
}

import React, { useState, useEffect } from 'react';
import { format, addMinutes } from 'date-fns';

const Confirmation = ({ event=null, booking=null }) => {

    const [pagePart, setPagePart] = useState();

    useEffect(() => {
        if (booking) {
            setPagePart(
                <>
                    <div className="mb-3 mt-3 confirmation-display highlight">
                        Your service booking has been confirmed!<br />
                    </div>
                    <div className="summary-grid mb-4">
                        <div className="summary-header">Service:</div>
                        <div>{booking.service_name}</div>
                        
                        <div className="summary-header">Location:</div>
                        <div>{booking.location_name}</div>
                        
                        <div className="summary-header">Date:</div>
                        <div>{booking.timeslot_start && format(booking.timeslot_start, "EEEE MMM d, yyyy")}</div>
                        
                        <div className="summary-header">Time:</div>
                        <div>{booking.timeslot_start && format(booking.timeslot_start, "h:mm a")} {booking.timeslot_end && format(addMinutes(booking.timeslot_end,1), "- h:mm a")}</div>

                    </div>
                </>
            );
        }
        if (event) {
            setPagePart(
                <>
                    <div className="mb-2 mt-3 confirmation-display highlight">
                        Your event registration has been confirmed!<br />
                    </div>
                    {/* Event Details here */}
                </>
            );
        }
    },[booking, event]);

    return (
        <div className="cart-purchase-summary">
            {pagePart}
        </div>
    );
}

export default Confirmation;
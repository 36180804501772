//import { startOfDay } from 'date-fns';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    current_service: {
        name: '',
        description: '',
        selected_token: []
    },
    errors: {},
    token_data: [],
    location_data: {}
};

const serviceReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SERVICEWIZARD_SET_CURRENT_SERVICE:
            return {
                ...state,
                current_service: { ...state.current_service,  ...action.service}
            }
        case actionTypes.SERVICEWIZARD_SET_ERRORS:
            return {
                ...state,
                errors: {...action.errors}
            }
        case actionTypes.SERVICEWIZARD_RESET_ERRORS:
            return {
                ...state,
                errors: {}
            }
        case actionTypes.SERVICEWIZARD_SET_TOKEN_DATA:
            return {
                ...state,
                token_data: action.token_data
            }
        case actionTypes.SERVICEWIZARD_SET_LOCATION_DATA:
            return {
                ...state,
                location_data: action.location_data
            }
        default:
            return state;
    }
};

export default serviceReducer;
import React, {useState, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Row, Col, Button } from 'react-bootstrap';

import { Details, Step1, Step2, Step3, Step4 } from './Steps';

import APIPOS from '../../../../../api/Pos';
import { loadOrderIntoRedux } from '../../../../../utils/thunks';
import * as actions from '../../../../../store/actions';

import styles from './EventWizardFull.module.scss';

export const EventWizardFull = (props) => {    
    const dispatch = useDispatch();

    const user = useSelector(state => state.auth.user);
    let activeRegister = useSelector(state=>state.pos.register);
    if (props.register_id) activeRegister = props.register_id;

    const [error, setError] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const [disableBack, setDisableBack] = useState(false);

    const [step, setStep] = useState();
    const [referrerStep, setReferrerStep] = useState(); 
    const [component, setComponent] = useState();
    const [stepValues, setStepValues] = useState({});
    const [localOrderId, setLocalOrderId] = useState(0);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const [paymentFields, setPaymentFields] = useState();

    const saveStepValues = useCallback((values) => {
        let _stepstuff = {...stepValues, ...values};
        setStepValues(_stepstuff);
    }, [stepValues]);

    const goToStepHandler = useCallback((e, _step=null, referrer=null) => {
        e.preventDefault();
        e.stopPropagation();

        if (_step) setStep(_step);
        else setStep(step + 1);
        
        if (referrer) setReferrerStep(referrer);
    }, [step]);

    const submitHandler = useCallback(async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSubmitting(true);

        if (step === 3){
            if (stepValues?.collectJS && !stepValues?.token){
                setDisableNext(true);
                setDisableBack(true);

                await stepValues.collectJS.startPaymentRequest();
                const tokenres = await stepValues.collectJS.tokenPromise;
                if (tokenres.token) saveStepValues({token: tokenres.token, submit: true});
            }
            if (paymentFields) saveStepValues({paymentFields: paymentFields});

            // if its a free event there is no variant id
            if (!stepValues?.variant_id) saveStepValues({token: "FREE", submit: true});
        }
    }, [step, stepValues, saveStepValues, paymentFields]);

    useEffect(() => {
        if (stepValues?.token && step===3 && stepValues?.submit){
            saveStepValues({submit: false});
            setStep(4);
        }
    }, [stepValues, step, saveStepValues]);

    // loads the user's latest order from the api into redux
    useEffect(() => {
        if (!localOrderId && !isFirstLoad && !props.is_preview && user?.profile?.id){
            try{
                APIPOS.order.latestOpen({ user_id: user.profile.id, register_id: activeRegister }).then( async response => {
                    if (!response.errors && response.data) {
                        if (response.data.order_status_id === 1) {
                            dispatch(loadOrderIntoRedux(response.data, activeRegister));
                            setLocalOrderId(response.data.id);
                        } else {
                            APIPOS.local.remove(`POS-${activeRegister}-order-id`);
                            dispatch(actions.setPCReset());
                            dispatch(actions.reset());
                        }
                    } else if (response.errors) {
                        console.error(response.errors);
                    }
                });
            } catch(e){
                console.error(e);
            }
            setIsFirstLoad(true);
        }
    }, [localOrderId, isFirstLoad, user?.profile, props.is_preview, activeRegister, dispatch]);    

    useEffect(() => {
        if (stepValues?.token && step===3){
            setDisableBack(false);
            setDisableNext(false);
            //setStep(4);
        }
    }, [stepValues?.token, step, setDisableBack, setDisableNext]);

    useEffect(() => {
        if (step===1) setDisableNext(true);

        const _props = {
            submitting: submitting,
            setSubmitting: setSubmitting,
            goToStep: goToStepHandler,
            disableNext: setDisableNext, // sets the state to disable the next button, in case we need to wait for an async call to finish, or a validation to pass
            disableBack: setDisableBack,
            referrerStep: referrerStep, // keeps track if the next or back button was pressed, in case we need to skip a step we either go to the previous or to the next of the skipped step
            currentStep: step, // keeps track of the current step
            saveStepValues: saveStepValues,
            stepValues: stepValues,
            register_id: activeRegister,
            ...props
        }

        switch(step){
            case 4:
                setComponent(<Step4 {..._props} />);
                break;
            case 3:
                setComponent(<Step3 {..._props} savePaymentFields={setPaymentFields} />);
                break;
            case 2:
                setComponent(<Step2 {..._props} />);
                break;
            case "done":
            case 1:
            default:
                setComponent(<Step1 {..._props} />);
                break;
        }
    }, [step, submitting, goToStepHandler, props, activeRegister, referrerStep, saveStepValues, stepValues]);


    useEffect(() => {
        return () => {
            setStep(null);
            setComponent(null);
            setError(null);
            setSubmitting(false);
            setDisableNext(false);
            setDisableBack(false);
            setStepValues({});
            setLocalOrderId(0);
            setIsFirstLoad(false);
            setPaymentFields(null);
        }
    }, []);    


    if (!component) return null;

    return (
        <div className={styles.wrapper}>
            <div>
                <Card>
                    <h5 className="title">{props.name}</h5>
                    <Row>
                        <Col sm={12} lg={true} className={`${styles.details} ${step?styles.divider:null}`}>
                            <Details
                                submitting={submitting}
                                goToStep={goToStepHandler}
                                disableNext={setDisableNext}
                                disableBack={setDisableBack}
                                referrerStep={referrerStep}
                                currentStep={step}
                                saveStepValues={saveStepValues}
                                stepValues={stepValues}
                                {...props}
                            />
                        </Col>
                        {props.requires_registration === 1 &&
                            <Col sm={12} lg={true}>
                                {component}
                                {error && <div className="error-text-ctr">{error}</div>}
                            </Col>
                        }
                    </Row>
                </Card>
            </div>
            <div className={`${styles.toolbar} modal-footer`}>
                {props.close && <Button variant="light" disabled={props.submitting} onClick={props.close} className="me-auto">Close</Button>}
                {step &&
                    <>
                        {step > 1 && step <= 4 && <Button variant="light" disabled={props.submitting || disableBack} onClick={e=>goToStepHandler(e, step-1, "back")}>Back</Button>}
                        {step < 3 && <Button variant="primary" disabled={props.submitting || disableNext} onClick={e=>goToStepHandler(e, step+1, "next")}>Continue</Button>}
                        {step === 3 && <Button variant="primary" disabled={props.submitting || disableNext} type="button" onClick={submitHandler} id="payButton">Register</Button>}
                    </>
                }
            </div>
        </div>
    );
}

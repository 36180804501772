import * as actionTypes from '../actions/actionTypes';

const initialState={
    features: null
}

const permissionsReducer = (state = initialState, action)=>{
    switch (action.type){
        case actionTypes.SET_AVAILABLE_FEATURES:
            return{
                ...state,
                features: action.availableFeatures
            }
        default: 
            return state;
    }
}

export default permissionsReducer;
import * as actionTypes from './actionTypes';
import Users from '../../api/Users';

// the login method of attemptAuth is now in thunks.js

export const authSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user: user,
        token: user.token
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        let user = localStorage.getItem('user');
        if (!user) {
            // dispatch(logout());
        } else {
            user=JSON.parse(user);
            Users.get({id:user.profile.id})
            .then(response => {
                if (response.data) {
                    let userObj = {};
                    userObj.token = user.token;
                    userObj.profile = response.data[0];
                    userObj.roles = user.roles;
                    userObj.groups = user?.groups || [];
                    userObj.menus = user.menus;
                    dispatch(authSuccess(userObj));
                }
            }).catch(error => {
                dispatch(logout());
                dispatch(authFail(error.message));
            });            
        }
    };
};

export const setAuthRegistrationRedirect = (path) => {
    return {
        type: actionTypes.AUTH_REGISTRATION_REDIRECT,
        path: path
    };
};

export const fromSignUp=(from_signup)=>{
    return{
        type: actionTypes.AUTH_FROM_SIGNUP,
        from_signup: from_signup
    }
}

// used to reset auth to initial state and save the new info (used when reloading from localStorage)
export const resetAuth = (authState) => {
    return {
        type: actionTypes.AUTH_RESET,
        authState: authState
    };
}

// used to update one piece of auth, refreshing with new info
export const setAuth = (authState) => {
    return {
        type: actionTypes.AUTH_SET,
        authState: authState
    };
}
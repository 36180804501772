import * as actionTypes from './actionTypes';

//so we need to do a little research before committing to a long term solution here.
//it is possible to call the store within an action, however...
//the CREATOR of Redux says that's a 'no-no' but....
//the CURRENT maintainer of Redux says even though it's an anti-pattern, 
//there are instances where it's necesasary and that's why the tools exist for it.
//However, the current maintainer may be referencing TOOLKIT, as that's the more current popular version of Redux
//This was just the differing opinions at a very quick glance, hence more research required
//so this is a BANDAID!!! GOTTA READ STUFF but it's 2AM on a Friday!

let DEFAULT_REGISTER;
/*let companyId = JSON.parse(localStorage.getItem('user'))?.company_id;
if(companyId === 2) DEFAULT_REGISTER = 7;
if(companyId === 5) DEFAULT_REGISTER = 10;*/

/*import store from '../../redux-store';    
const companyConfig = store.getState().company;
//if (companyConfig?.default_patron_register) DEFAULT_REGISTER = companyConfig.default_register;*/

//END BANDAID
//We should label our tech debt as BANDAID so that later, we can just search the term and find it ALL....

export const setPOSItem = (items,type, register_id) => {
    return {
        type: type,
        items: items,
        register_id: register_id
    };
};

export const addPOSItem = (items, register_id) => {
    return dispatch => {
        dispatch(setPOSItem(items,actionTypes.POS_ADD_ITEM, register_id));
    };
};

export const addPOSItemsBulk = (items, register_id) => {
    return dispatch => {
        dispatch(setPOSItem(items, actionTypes.POS_ADD_ITEMS_MANY, register_id));
    };
};

export const removePOSItem = (id, register_id) => {
    return dispatch => {
        dispatch(setPOSItem(id,actionTypes.POS_REMOVE_ITEM, register_id));
    };
};

export const setActiveItem = (item, register_id) => {
    return {
        type: actionTypes.POS_ACTIVE_ITEM,
        item: item,
        register_id: register_id
    };
};

export const activeItem = (item, register_id) => {
    return dispatch => {
        dispatch(setActiveItem(item, register_id));
    };
};

export const setActiveCategory = (category, register_id) => {
    return {
        type: actionTypes.POS_ACTIVE_CATEGORY,
        category: category,
        register_id: register_id
    };
};

export const activeType = (type, register_id) => {
    return {
        type: actionTypes.POS_ACTIVE_TYPE,
        product_type: type,
        register_id: register_id
    };
};

export const activeCategory = (category, register_id) => {
    return dispatch => {
        dispatch(setActiveCategory(category, register_id));
    };
};

export const setActiveRegister = (register) => {
    return {
        type: actionTypes.POS_ACTIVE_REGISTER,
        register: register
    };
};

export const initRegister = (register) => {
    return {
        type: actionTypes.POS_INIT_REGISTER,
        register: register
    }
}

export const activeRegister = (register) => {
    return dispatch => {
        dispatch(setActiveRegister(register));
    };
};

export const setActiveTab = (tab, register_id) => {
    return {
        type: actionTypes.POS_ACTIVE_TAB,
        tab: tab,
        register_id: register_id
    };
};

export const activeTab = (tab, register_id) => {
    return dispatch => {
        dispatch(setActiveTab(tab, register_id));
    };
};

export const setOrder = (order_id, register_id) => {
    return {
        type: actionTypes.POS_ORDER,
        order_id: order_id,
        register_id: register_id
    };
};

export const order = (order_id, register_id) => {
    return dispatch => {
        dispatch(setOrder(order_id, register_id));
    };
};

export const setOrderAll = (order, register_id) => {
    return {
        type: actionTypes.POS_ORDER_ALL,
        order: order,
        register_id: register_id
    };
};

export const orderAll = (order, register_id) => {
    return dispatch => {
        dispatch(setOrderAll(order, register_id));
    };
};

export const setOrderStatus = (status, register_id) => {
    return {
        type: actionTypes.POS_ORDER_STATUS,
        order_status: status,
        register_id: register_id
    };
};

export const transaction = (transaction, register_id) => {
    return dispatch => {
        dispatch(setTransaction(transaction, register_id));
    };
};

export const setTransaction = (transaction, register_id) => {
    return {
        type: actionTypes.POS_TRANSACTION,
        transaction: transaction,
        register_id: register_id
    };
};

export const orderStatus = (status, register_id) => {
    return dispatch => {
        dispatch(setOrderStatus(status, register_id));
    };
};

export const setOrderImported = (imported, register_id) => {
    return {
        type: actionTypes.POS_ORDER_IMPORTED,
        order_imported: imported,
        register_id: register_id
    };
};

export const orderImported = (imported, register_id) => {
    return dispatch => {
        dispatch(setOrderImported(imported, register_id));
    };
};

export const setActiveOption = (option, register_id) => {
    return {
        type: actionTypes.POS_ACTIVE_OPTION,
        option: option,
        register_id: register_id
    };
};

export const activeOption = (option, register_id) => {
    return dispatch => {
        dispatch(setActiveOption(option, register_id));
    };
};

export const setItemQty = (item,qty, register_id) => {
    return {
        type: actionTypes.POS_UPDATE_QTY,
        item: item,
        qty: qty,
        register_id: register_id
    };
};

export const updateQty = (item,qty, register_id) => {
    return dispatch => {
        dispatch(setItemQty(item,qty, register_id));
    };
};


export const reset = (register_id) => {
    return {
        type: actionTypes.POS_RESET,
        register_id: register_id
    };
};

export const setUser = (user, register_id) => {
    return {
        type: actionTypes.POS_SET_USER,
        user: user,
        register_id: register_id
    }
}

export const selectCustomer = (user, register_id) => {
    return dispatch => {
        dispatch(setUser(user, register_id));
    }
}

export const resetCustomer = (register_id) => {
    return dispatch => {
        dispatch(setUser(null, register_id));
    }
}

export const resetItems = (register_id) => {
    return dispatch => {
        dispatch(setPOSItem(null,actionTypes.POS_RESET_ITEMS, register_id));
    }
}

export const triggerPicker = (picker, register_id) => {
    return {
        type: actionTypes.POS_TRIGGER_PICKER,
        picker: picker,
        register_id: register_id
    };
};

export const setCouponsSelected = (coupons_selected, register_id) => {
    return {
        type: actionTypes.POS_COUPONS_SELECTED,
        coupons_selected: coupons_selected,
        register_id: register_id
    }
}

export const setUpdated = (updated, register_id) => {
    return {
        type: actionTypes.POS_SET_UPDATED,
        register_id: register_id,
        updated: updated
    }
}

export const setUpdateTrigger = (updateTrigger, register_id) => {
    return {
        type: actionTypes.POS_UPDATE_TRIGGER,
        updateTrigger: updateTrigger,
        register_id: register_id
    }
}

export const setCouponCodes = (coupon_codes, register_id) => {
    return {
        type: actionTypes.POS_COUPON_CODES,
        coupon_codes: coupon_codes,
        register_id: register_id
    }
}

export const setTip = (tip, register_id) => {
    return {
        type: actionTypes.POS_TIP,
        tip: tip,
        register_id: register_id
    }
}

export const setPreCartItem = (pre_items, register_id) => {
    return {
        type: actionTypes.SET_PRE_CART_ITEM,
        pre_items: pre_items,
        register_id: register_id
    };
};

export const resetPreCartItems = (register_id) => {
    return {
        type: actionTypes.RESET_PRE_CART_ITEMS,
        register_id: register_id
    };
}

export const setNewItems = (new_items, register_id) => {
    return {
        type: actionTypes.POS_SET_NEW_ITEMS,
        new_items: new_items,
        register_id: register_id
    }
}

export const setCartItems = (items, order, register_id) => {
    return {
        type: actionTypes.POS_SET_CART_ITEMS,
        items: items,
        orderAll: order,
        register_id: register_id
    }
}

export const setShowEventRegister = (show, register_id) => {
    return {
        type: actionTypes.POS_SET_SHOW_EVENT_REGISTER,
        show: show,
        register_id: register_id
    }
}

export const showEventRegister = (register_id) => {
    return dispatch => {
        dispatch(setShowEventRegister(true, register_id));
    }
}

export const hideEventRegister = (register_id) => {
    return dispatch => {
        dispatch(setShowEventRegister(false, register_id));
    }
}

export const setCartAnimation = (play, register_id) => {
    return {
        type: actionTypes.POS_SET_CART_ANIMATION,
        play: play,
        register_id: register_id
    }
}

export const playCartAnimation = (register_id) => {
    return dispatch => {
        dispatch(setCartAnimation(Math.random(), register_id));
    }
}

export const endCartAnimation = (register_id) => {
    return dispatch => {
        dispatch(setCartAnimation(0, register_id))
    }
}

export const setPosAvailableTokens = (tokens) => {
    return {
        type: actionTypes.POS_SET_AVAILABLE_TOKENS,
        tokens: tokens
    }
}

export const setRegisterInfo = (registerInfo, register_id) => {
    return {
        type: actionTypes.POS_SET_REGISTER_INFO,
        registerInfo: registerInfo,
        register_id: register_id
    }
}

export const setFinishedLoadingAll = (finished_loading_all, register_id) => {
    return {
        type: actionTypes.POS_SET_FINISHED_LOADING_ALL,
        finished_loading_all: finished_loading_all,
        register_id: register_id
    }
}

export const setFinishedLoadingCustomer = (finished_loading_customer, register_id) => {
    return {
        type: actionTypes.POS_SET_FINISHED_LOADING_CUSTOMER,
        finished_loading_customer: finished_loading_customer,
        register_id: register_id
    }
}

export const setRegisterGroup = (register_group, register_id) => {
    return{
        type: actionTypes.POS_SET_REGISTER_GROUP,
        register_group: register_group,
        register_id: register_id
    }
}
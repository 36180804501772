import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

/** 
 * @param {string} orderType - The type of order that was successful (pickup, delivery, etc).  Should be worded in a way that it 
 * 
 */
export const SuccessfulOrder =({orderType=" ", orderNumber, ...props})=>{
    return(
        <div>
            <h4 className="section-title">Successful Order </h4>
            <p>
                Your {orderType}order has been successfully placed.
            </p>
            {orderNumber &&
                <p>
                    You may check the status of your order by visiting the order page, <Link to={`/p/order/${orderNumber}`}>here.  </Link>
                </p>         
            }
            <p>
                You do not have to keep this window open - you may close and start a new order or you may navigate to any other page.
            </p>
        </div>
    )
}
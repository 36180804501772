import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './Overlay.module.scss';

export const Overlay = (props) => {

    let classes = `${props?.classes || ""} `;
    if (props?.horizontal) classes += `${styles["horizontal"]} `;
    if (props?.vertical) classes += `${styles["vertical"]} `;
    if (props?.center) classes += `${styles["center"]} `;
    if (props?.left) classes += `${styles["left"]} `;
    if (props?.right) classes += `${styles["right"]} `;
    if (props?.bottom) classes += `${styles["bottom"]} `;
    if (props?.inset) classes += `${styles["inset"]} `;
    if (props?.color) classes += `${styles[props.color]} `;

    return (
        <div className={`${styles["overlay"]} ${classes.trim()}`}>
            <ul>
                <OverlayTrigger placement="bottom" overlay={<Tooltip>Move</Tooltip>}>
                    <li>
                        <i className={styles["move"]}/>
                    </li>
                </OverlayTrigger>

                {props.add &&
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Add</Tooltip>}>
                        <li>
                            <i className={styles["add"]} onClick={(e)=>props.add(e,props.id)} />
                        </li>
                    </OverlayTrigger>
                }
                {props.duplicate &&
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Duplicate</Tooltip>}>
                        <li>
                            <i className={styles["duplicate"]} onClick={(e)=>props.duplicate(e,props.id)} />
                        </li>
                    </OverlayTrigger>
                }
                {props.delete &&
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>Remove</Tooltip>}>
                        <li>
                            <i className={styles["delete"]} onClick={(e)=>props.delete(e,props.id)} />
                        </li>
                    </OverlayTrigger>
                }
            </ul>
        </div>
    );
}
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Container, Button, Modal} from 'react-bootstrap';

import ConfirmRefund from './ConfirmRefund';
import AdvancedRefund from './AdvancedRefund';
import AmountRefund from './AmountRefund';

import APIRegisters from '../../../api/Registers';

import './Refund.scss';

/** Component for issuing refunds from orders.
 * @param int props.maxTotal
 * @param array props.items (with .name and .price and .shipping )
 * @param object props.transaction
 * @param int props.adminFee
 * @param int props.paymentRegister
 * @param function props.setError
 * @param function props.setSuccess
 * @param function props.cleanUp
 * @param int props.orderLength
 */
export const Refund = ({modalView=false, ...props}) => {

    const mountedRef = useRef();
    const [ invalidTotal, setInvalidTotal ]=useState(false); //Toggle to disable refund button based on invalid amount applied
    const [ noRegister, setNoRegister ]=useState(false); //Toggle to disable refund based on having no register selected
    const [ disableRefund, setDisableRefund ]=useState(true); //Will toggle true if invalidTotal or noRegister are true
    const [ refundAmount, setRefundAmount ]=useState(0); //Totals without admin fee applied
    const [ shippingTotal, setShippingTotal ]=useState(0); //calculated by adding the total available shipping 
    const [ fullRefund, setFullRefund ]=useState(0); //The amount send to confirmation to refund
    const [ customAdminFee, setCustomAdminFee ]=useState(0);
    const [ adminFeeChecked, setAdminFeeChecked ]=useState(false);
    const [ adminFeeDisable, setAdminFeeDisable ]=useState(false);
    const [ registers, setRegisters ]=useState([]); //For dropdown selection
    const [ selectedRegister, setSelectedRegister ]=useState(props.paymentRegister || ""); 
    const [ confirm, setConfirm ]=useState(false);
    const [ refundItems, setRefundItems ]=useState([]) //Items to be refunded
    const [ fullCheck, setFullCheck ]=useState(false);
    const [ amountOrAdvanced, setAmountOrAdvanced ]=useState(false); //Toggle between amount and advanced refund
    const [ firstLoad, setFirstLoad]=useState(true);

    //set initial load of amount refund vs itemized refund helper; can be changed after load
    useEffect(()=>{
        let amount = false;
        if (props.amountRefund) amount = true;
        if (props.items.length === 0) amount = true;
        setAmountOrAdvanced(amount)
    },[props.amountRefund, props.items])
    
    useEffect(()=>{
        if (mountedRef.current===true){
            if (noRegister === false && invalidTotal ===false) setDisableRefund(false);
            if (noRegister === true || invalidTotal === true) setDisableRefund(true)
        }
    }, [noRegister, invalidTotal])

    /**Pass to each item to add the refund value to the total */
    const addRefundItem = (item)=>{
        let items = refundItems
        let includes = false;
        let index;
        for(let i = 0; i < items.length; i ++){
            if (items[i].item_id === item.item_id){
                includes = true;
                index=i;
            }
        }

        if (item.total_refund_amount > 0 && includes === false) items.push(item);
        if (item.total_refund_amount >0 && includes === true) items.splice(index,1, item);
        if ((item.total_refund_amount === 0 || !item.total_refund_amount) && includes === true) items.splice(index,1);
        setRefundItems(items);
        calculateRefund();
    }

    /**Check admin box is all items were selected on the previous screen */
    const initialAdminCheck = useCallback(()=>{
        if (firstLoad){
            setAdminFeeChecked(props.orderLength === props.items.length && mountedRef.current === true);
            if (props.adminFee === 0) {
                setAdminFeeChecked(false);
                setAdminFeeDisable(true);
            }
            setFirstLoad(false);
        }
    },[props.orderLength, props.items.length, props.adminFee, firstLoad]);

    /**Caculate the refund granted from all items */
    const calculateRefund = useCallback(()=>{
        let total=0;
        refundItems.forEach((item)=>{
            total += parseFloat(item.total_refund_amount);
            if (item.tax_refunded) total += parseFloat(item.tax);
            if (item.shipping_refunded) total += parseFloat(item.shipping);
        })
        setRefundAmount(total);
    },[refundItems]);

    /**Takes the shipping cost of each item, if it has it, and adds it together to add for maximum refund amount */
    const calculateShippingRefund = useCallback(()=>{
        let shippingTotal = 0
        for(let i = 0; i < props.items.length; i++){
            if (props.items[i].shipping){
                let itemShipping = props.items[i].shipping
                shippingTotal = shippingTotal + itemShipping
            }
        }
        if(mountedRef.current===true) setShippingTotal(shippingTotal);
    },[props.items]);

    /**Add or subtract admin fee as clicked */
    const addAdminFee = useCallback(()=>{
        let temp = parseFloat(refundAmount);
        if(adminFeeChecked === true) {
            temp += props.adminFee
            setCustomAdminFee(parseFloat(props.adminFee))
        }
        if(adminFeeChecked === false && refundAmount >0 && customAdminFee===0) {
            temp = parseFloat(refundAmount)
        }
        if(customAdminFee > 0 && adminFeeChecked === false) temp+=parseFloat(customAdminFee)
        if(mountedRef.current === true) setFullRefund(temp)
    },[adminFeeChecked, refundAmount, props.adminFee, customAdminFee]);

    
    /**Get registers for DD */
    const getRegisters = useCallback(async ()=>{
        if (registers.length > 0) return;
        try{
            let response = await APIRegisters.get()
            if(mountedRef.current === true){
                setRegisters(response.data);
            }
        } catch(ex){
            console.error(ex);
        }
    },[registers]);

    //first load
    useEffect(()=>{
        mountedRef.current = true

        if (props.paymentRegister && mountedRef.current === true) setDisableRefund(false)
        getRegisters();
        calculateShippingRefund()
        initialAdminCheck();

        return()=>{
            mountedRef.current = false
            setConfirm(false);
            setRefundItems([]);
            setRefundAmount(0);
        }
    },[props.paymentRegister, props, calculateShippingRefund, initialAdminCheck, getRegisters]);    

    //If any changes are made after clicking confirm, confirm will reset and user will have to click again to ensure changes are "saved"
    useEffect(()=>{
        if(mountedRef.current === true){
            setConfirm(false);
        }
    },[selectedRegister, adminFeeChecked, refundItems]);

    useEffect(()=>{
        if ((adminFeeChecked || customAdminFee) && !isNaN(refundAmount)) addAdminFee();
        else if (!isNaN(refundAmount) && !fullCheck) setFullRefund(refundAmount);
    },[adminFeeChecked, refundAmount, customAdminFee, fullCheck, addAdminFee]);

    useEffect(()=>{ 
        if(mountedRef.current){
            if (fullCheck === true) setFullRefund(props.maxTotal);
            else calculateRefund();
        }
    }, [fullCheck, props.maxTotal, calculateRefund]);

    useEffect(()=>{ //Checking to ensure a register is selected
        if (mountedRef.current === true){
            if (selectedRegister==="") setNoRegister(true);
            else setNoRegister(false);
        }
    }, [selectedRegister]);
    
    const handleCustomAdmin=(e)=>{
        let num = +e.target.value || 0;
        if (+num > +props.adminFee) num = +props.adminFee;
        setCustomAdminFee(num);
        setAdminFeeChecked(num > 0);
    }

    const handleConfirmClose = () => setConfirm(false);

    return (
        <Container fluid>
            {props.items.length > 0 &&
                <Button variant="secondary" className="mb-4" onClick={()=>{setAmountOrAdvanced(!amountOrAdvanced)}}>
                    {amountOrAdvanced ? "Itemized Refund Helper" : "Amount Refund"}
                </Button>
            }
            {amountOrAdvanced ? 
                <AmountRefund 
                    registers={registers}
                    selectedRegister={selectedRegister}
                    setSelectedRegister={setSelectedRegister}
                    fullOrder={props.fullOrder}
                    setInvalidTotal={setInvalidTotal}
                    setRefundAmount={setFullRefund}
                />
                :
                <AdvancedRefund 
                    shippingTotal={shippingTotal}
                    maxTotal={props.maxTotal}
                    adminFee={props.adminFee}
                    adminFeeDisable={adminFeeDisable}
                    adminFeeChecked={adminFeeChecked}
                    setAdminFeeChecked={setAdminFeeChecked}
                    customAdminFee={customAdminFee}
                    fullCheck={fullCheck}
                    setFullCheck={setFullCheck}
                    items={props.items}
                    addRefundItem={addRefundItem}
                    setConfirm={setConfirm}
                    setInvalidTotal={setInvalidTotal}
                    setSelectedRegister={setSelectedRegister}
                    selectedRegister={selectedRegister}
                    registers={registers}
                    handleCustomAdmin={handleCustomAdmin}
                    modalView={modalView}
                />
            }
        
            <Button variant="primary" type="submit" disabled={disableRefund} onClick={()=>{setConfirm(true)}}>
                Refund ${fullRefund?.toFixed(2)}
            </Button>
            <br />
            {disableRefund ? <span className="error-text ml-3 mt-2">Please select a register and a total amount less than ${(props.maxTotal).toFixed(2)} </span> : null }

            <Modal size="xl" show={confirm} onHide={handleConfirmClose} backdrop="static" dialogClassName="confirm-modal" backdropClassName='confirm-modal-backdrop'>
                <Modal.Header closeButton/>
                <Modal.Body>
                    <ConfirmRefund 
                        amountRefund={amountOrAdvanced || refundItems?.length===0 ? true: false }
                        transactionId={props.transactionId}
                        handleConfirmClose={handleConfirmClose}
                        items={refundItems}
                        totalRefund={fullRefund}
                        transaction={props.transaction}
                        registerId={selectedRegister}
                        paymentMethod={props.payment_method}
                        refundAdmin={customAdminFee}
                        setError={props.setError}
                        setSuccess={props.setSuccess}
                        cleanUp={props.cleanUp}
                        fullOrder={props.fullOrder}
                    />
                </Modal.Body>
            </Modal>      
        </Container>
    );
}
import React from 'react'
import Table from 'react-bootstrap/Table'

export const MobileDisplayOrdersTable = (props) => {

    const eachItem = (item, isAddon) =>{
        return(
            <>
                <tr style={{borderTop: "solid 2px"}}>
                    {/*props.isAdmin ?
                        <td>
                            <input type="checkbox"
                                disabled={item.disabled} 
                                id={`cb-items-${item.id}`}
                                name={item.product_name} 
                                value={item} 
                                onChange={(e)=>{props.handleCheckBox(item, e)}} 
                            />
                        </td>
                    :<td/>*/}
                    <td/>
                    <td className="pl-4">{item.product_id}</td>
                </tr>
                <tr>
                    <th>Name</th>
                        {!isAddon ?
                            <td className="pl-4">
                                {item.product_name}
                                {item?.variant_name?.toLowerCase()==="default" ? 
                                null 
                                : 
                                    <span>- {item.variant_name}</span>
                                }
                            </td>
                            :
                            <td>
                                <span className="bold">ADDON - </span>
                                {item.product_name}
                            </td>
                        }
                </tr>
                <tr>
                    <th>Base Price</th>
                    {item?.base_price ?
                        <td>${item.base_price?.toFixed(2)}</td>
                        :
                        <td>$0.00</td>
                    }
                </tr>
                <tr>
                    <th>Final Price</th>
                    {item?.final_price ?
                        <td>${item?.final_price?.toFixed(2)}</td>
                        :
                        <td>$0.00</td>
                    }
                </tr>
                <tr>
                    <th>Tax</th>
                    {item?.tax ?
                        <td>${item?.tax?.toFixed(2)}</td>
                        :
                        <td>$0.00</td>
                    }
                </tr>
                {/* <tr>
                    <th>Shipping</th>
                    {item?.shipping ?
                        <td>${item.shipping?.toFixed(2)}</td>
                        :
                        <td>$0.00</td>
                    }
                </tr> */}
                <tr>
                    <th>Quantity</th>
                    <td className="pl-4">{item.quantity}</td>
                </tr>
            </>
        )
    }

    return (
        <Table responsive="true" striped >
            
            {props.currentOrder.items?.map((item,i )=>(
                <tbody key={`uo-table-items${i}`}>
                    {eachItem(item, false)}
                    {item?.addons?.map((addon, i)=>(
                        <React.Fragment key={`uo-table-addons${i}`}>
                            {eachItem(addon, true)}
                        </React.Fragment>
                    ))}
                </tbody>
            ))}
        </Table>
    )
}

export default MobileDisplayOrdersTable;

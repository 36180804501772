import * as actionTypes from './actionTypes';

export const ThemeSetLogo=(url)=>{
    return{
        type: actionTypes.THEME_LOGO,
        logo: url
    };
};

export const ThemeSetFavIcon=(url)=>{
    return{
        type: actionTypes.THEME_FAVICON,
        favicon: url
    };
};

export const ThemeSetCSS=(css)=>{
    return{
        type: actionTypes.THEME_CSS,
        css: css
    };
};

export const ThemeSetVariables=(vars)=>{
    return{
        type: actionTypes.THEME_VARIABLES,
        vars: vars
    };
};
import React, {useState, useEffect, useCallback} from 'react';
import {Container, Accordion} from 'react-bootstrap';

import Item from './Item';

import styles from '../Wizard.module.scss';

export const ByTransaction = (props) => {
    const {stepUpdate} = props;

    const [transactions, setTransactions] = useState(props?.stepData?.transactions || []);

    const itemUpdateHandler = useCallback(data => {
        setTransactions(prev => {
            let _items = [...prev];
            if (+data.refund <= 0 || data.checked.selected === false) _items = _items.filter(a => +a.id !== +data.transaction.id);
            else {
                let _item = {
                    id: data.transaction.id,
                    transaction_payment_method_id: data.transaction.transaction_payment_method_id,
                    transaction_payment_method_name: data.transaction.transaction_payment_method_name,
                    transaction_type_id: data.transaction.transaction_type_id,
                    transaction_response: data.transaction.transaction_response,
                    date: data.transaction.transaction_date,
                    amount: data.transaction.amount,
                    cc_number: data.transaction.cc_number,
                    cc_type: data.transaction.cc_type,
                    refund_amount: data.refund,
                    refund_type: data.checked.full ? "full amount" : "custom amount",
                }

                let _idx = _items.findIndex(item => item.id === data.transaction.id);
                if (_idx > -1) _items[_idx] = _item;
                else _items.push(_item);
            }
            return _items;
        });
    },[]);

    useEffect(()=>{
        stepUpdate(props.stepId, {transactions});
    },[stepUpdate, props.stepId, transactions]);

    return (
        <Container fluid>
            <h4 className={`${styles.title}`}>Select the payments you wish to refund?</h4>
            <hr/>
            {props?.order?.transactions?.filter(a => 
                +a.transaction_status_id===7
                //all transaction types are currently being misaccredited, so have to add payment method as well
                && +a.transaction_type_id !== 2 //auth 
                && +a?.transaction_payment_method_id !== 5 //auth
                && +a.transaction_type_id !== 3 
                && +a.amount - +a.amount_refunded > 0
            ).map((item,i)=>(
                <React.Fragment key={`refund-transactions-${i}`}>
                    <Accordion className="mx-3">
                        <Item 
                            key={`refund-transaction-${item.id}`} 
                            {...props}
                            transaction={item}
                            index={i+1}
                            data={transactions.find(a => +a.id === +item.id)}
                            itemUpdate={itemUpdateHandler}
                        />
                    </Accordion>
                </React.Fragment>
            ))}            
        </Container>
    );
}
import * as actionTypes from './actionTypes';

export const loadTimelineStart = (items) => {
    return {
        type: actionTypes.LOAD_TIMELINE,
        items: items
    };
};

export const loadTimeline = (items) => {
    return dispatch => {
        dispatch(loadTimelineStart(items));
    };
};

export const setSelectedRoots = (items) => {
    return {
        type: actionTypes.SELECT_MAP_ROOT,
        selected_roots: items
    };
};

export const selectedRoots = (items) => {
    return dispatch => {
        dispatch(setSelectedRoots(items));
    };
};

export const setSelectedItems = (items) => {
    return {
        type: actionTypes.SELECT_MAP_ITEMS,
        selected_items: items
    };
};

export const selectedItems = (items) => {
    return dispatch => {
        dispatch(setSelectedItems(items));
    };
};

export const deselectItem = (item) => {
    return {
        type: actionTypes.DESELECT_MAP_ITEMS,
        selected_item: item
    };
};

export const resetSelectedItems = () => {
    return dispatch => {
        dispatch(setSelectedItems(null));
    };
};

export const setCurrentStep = (step) => {
    return {
        type: actionTypes.EVENT_WIZARD_STEP,
        step: step
    };
};

export const currentStep = (item) => {
    return dispatch => {
        dispatch(setCurrentStep(item));
    };
};

export const setEventType = (type) => {
    return {
        type: actionTypes.EVENT_TYPE,
        event_type: type
    };
};

export const eventType = (type) => {
    return dispatch => {
        dispatch(setEventType(type));
    };
};

export const setAttendee = (attendees,type) => {
    return {
        type: type,
        attendees: attendees
    };
};

export const addAttendee = (attendees) => {
    return dispatch => {
        dispatch(setAttendee(attendees,actionTypes.EVENT_ADD_ATTENDEE));
    };
};

export const removeAttendee = (id) => {
    return dispatch => {
        dispatch(setAttendee(id,actionTypes.EVENT_REMOVE_ATTENDEE));
    };
};

export const setSpot = (spot) => {
    return {
        type: actionTypes.EVENT_SAVE_SPOT,
        spot: spot,
    };
};

export const saveSpot = (spot) => {
    return dispatch => {
        dispatch(setSpot(spot));
    };
};
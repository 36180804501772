import React, { useState, useEffect, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Buttons from './Buttons';

// buttons will be an array of strings corresponding to the different tabs available in Buttons.js
// the string can contain just the slug such as "orders", which will display the default text for that tab
// or the string can contain the slug and an override for the text displayed "orders:Search"
export const PosHeader = ({ name="", buttons=["orders","reports"], registerId, ...props }) => {

    const [buttonProps, setButtonProps] = useState();

    useEffect(() => {
        let explodedButtonProps = buttons.map(string => {
            let split = string.split(':');
            return { hash: split[0], text: split[1] || null };
        });
        setButtonProps(explodedButtonProps);
    },[buttons]);

    return (
        <div className="pos-header">
            <a href="/p/" className="btn btn-back m-0 px-0">
                <i className="far fa-arrow-circle-left" />
            </a>
            {name &&
                <h2 className="pos-name">{name}</h2>
            }
            {buttonProps && 
                <Buttons 
                    includes={buttonProps}
                    show={1}
                    register_id={registerId}
                    {...props}
                />
            }
        </div>
    )

}
import React, { useState, useContext, useEffect, useCallback } from 'react';
import {Navbar,Image,Container,Row,Col,OverlayTrigger,Popover,ListGroup} from 'react-bootstrap';
import { useHistory,withRouter,Link } from "react-router-dom";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Tutorials from '../../Tutorials';

import styles from './SubHeader.module.scss';

/* 
    This component should be used at the top of every page section (e.g. Location Dashboard, 
        Location Details, etc.)
    The props should be passed like so:
    <SubHeader items={items} pageName={pageName} subtitle={subtitle} />
    where items is an array of objects like so:
    [
        {linkAs:Link,linkProps:{to:"/p/home"},text:"Home"},
        {linkAs:Link,linkProps:{to:"/p/locations/dashboard"},text:"Location Dashboard"},
        {text:"New Location"}
    ]
    and tutorials is an array of objects like so:
        tutorials={[
                { tutorialSection: "Groups", subSection: "Create", allSectionTutorial: false, basicInfo: false}
        ]}
*/


const SubHeader = (props) => {
    return (
        <Row className="header">
            <Row>
                <Col>
                    <h1>{props.pageName}</h1>
                    <h3>{props.subtitle}</h3>
                </Col>
            </Row>
            <Col>
                <Breadcrumb>
                    {props.items.map((item,index)=>{
                        if (item.linkAs && item.linkProps && item.text){
                            return <Breadcrumb.Item key={index} linkAs={item.linkAs} linkProps={item.linkProps} data-cy={`breadcrumb-${item.text}`}>{item.text}</Breadcrumb.Item>
                        } else if (item.text){
                            return <Breadcrumb.Item key={index} active data-cy={`breadcrumb-${item.text}`}>{item.text}</Breadcrumb.Item>
                        }
                        return null;
                    })}
                </Breadcrumb>
            </Col>
            <Col className={styles.tutorials}>
            {/* Loop through the tutorials array and pass the props to the Tutorials component */}

            {props.tutorials && props.tutorials.length>0 && props.tutorials.map((tutorial,index)=>{
                return (
                    <Tutorials 
                        key={index} 
                        tutorialSection={tutorial.tutorialSection} 
                        subSection={tutorial.subSection} 
                        allSectionTutorial={tutorial.allSectionTutorial} 
                        basicInfo={tutorial.basicInfo} 
                        navigationTutorial={tutorial.navigationTutorial} />
                )})
            }
            </Col>
        </Row>
       
    );
};

export default withRouter(SubHeader);
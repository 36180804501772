import React, {useState, useEffect, useRef, useCallback} from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import Toast from '../../../components/Toast';
import ErrorCatcher from '../../../components/common/ErrorCatcher';

import Notifications from '../../../api/Notifications';
import { UpdateChannels } from './UpdateChannels/UpdateChannels';
import Stack from '../../../components/common/Stack';
import Spinner from '../../../components/Spinner';
import { authUserHasModuleAccessMany } from "../../../utils/auth";

import './NotificationSettings.scss';

const MODULE_ID = 80;

//current notification types : Receipt (1), Event Notification (2)
//current channel types: Text(1), Push Notification(2), Email (3)
//Only one that works right now is text but is set up to accomodate push and email and can easily have more added.
//To add new channels and types, they need to be added to the "EXISTING_CHANNELS" and "existtingTypes" arrays.
//Eventually, the types will need to have their own endpoint but only so many different types of channels are possible.
//I don't think there will be any need to change any of the data for the endpoints as it should be the same process regardless of types added

const EXISTING_CHANNELS=[
    {channelId: 1, name: "Text Message", mediaType:"phone"},
  //   {channelId: 2, name: "Push Notification", mediaType:"phone"},
  //   {channelId: 3, name: "Email", mediaType:"email"},
];
const EXISTING_TYPES=[
    {typeId: 1, name:"Receipt"},
    {typeId: 2, name:"Event Notification"}
];

export const NotificationSettings = (props) => {

    const mountedRef = useRef(false);
    const [loading, setLoading]=useState(true);
    const [error, setError]=useState();
    const [success, setSuccess]=useState();
    const [showEdits, setShowEdits]=useState(false);
    const [email, setEmail]=useState(props.user.email || "");
    const [phone, setPhone]=useState(props.user.mobile_phone || "");
    const [notificationChannels, setNotificationChannels]=useState();
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);


//#region useCallback
    const addChannels=useCallback((tempChannels)=>{
        let tempIdentifier;
        let channelsHad =[]
        tempChannels.forEach((channel)=>{
            channelsHad.push(channel.channelId)
        })
        //adding channels that the user doesn't have yet.
        EXISTING_CHANNELS.forEach((channel)=>{
        if(!channelsHad.includes(channel.channelId)){
            if(channel.mediaType==="phone") tempIdentifier=phone;
            else if(channel.mediaType==="email") tempIdentifier=email;
            channel.identifier = tempIdentifier;
            channel.types=[]
            tempChannels.push(channel);
        }
        })
    },[email, phone]);

    const addTypes=useCallback((tempChannels)=>{
        tempChannels.forEach((channel)=>{
        let typesHad=[];
        channel.types.forEach((type)=>{
            if(type.status===null) type.status=0
            type.new=false
            typesHad.push(type.typeId)
        })
        EXISTING_TYPES.forEach((type)=>{
            if(!typesHad.includes(type.typeId)) {
            type.status=0;
            type.new=true;
            channel.types.push(type);
            }
        })
        })
    },[]);

    const sortNotifications=useCallback((data)=>{
        let tempChannels = []
        let tempIds=[]
        data.forEach((data)=>{
            if(tempIds.includes(data.user_channel_type_id)){
                let matchedChannel = tempChannels.find((channel)=>channel.channelId === data.user_channel_type_id)
                let tempType = {
                    typeId: data.user_notification_type_id,
                    name: data.user_notification_type_name,
                    status: data.status
                }
                matchedChannel.types.push(tempType)
            }else{
                let channel={
                    identifier: data.name,
                    id: data.id,
                    channelId: data.user_channel_type_id,
                    name: data.user_channel_type_name,
                    types: [
                    {typeId: data.user_notification_type_id,
                    name: data.user_notification_type_name,
                    status: data.status}
                    ]
                }
                tempIds.push(data.user_channel_type_id);
                tempChannels.push(channel)
            }
        })
        addChannels(tempChannels);
        addTypes(tempChannels)
        return tempChannels
    },[addChannels, addTypes]);


    const setAllNew=useCallback((newPhone, newEmail)=>{
        let tempChannels = [];
        let tempIdentifier;
        EXISTING_CHANNELS.forEach((channel)=>{
            if(channel.mediaType==="phone") tempIdentifier=newPhone;
            else if(channel.mediaType==="email") tempIdentifier=newEmail;
            let newChannel = {
                identifier: tempIdentifier,
                id: null,
                channelId: channel.channelId,
                name : channel.name,
                types: []
            }
            EXISTING_TYPES.forEach((type)=>{
                let newType={
                    typeId: type.typeId,
                    name: type.name,
                    status: 0,
                    new: true
                }
                newChannel.types.push(newType);
            })
            tempChannels.push(newChannel);
        })
        setNotificationChannels(tempChannels);
    },[]);

  const getUserNotificationSettings=useCallback(async(newPhone, newEmail)=>{
    if(props.user_id){
      try{
        let response = await Notifications.Channel.getByUser({id:props.user_id})
        if(!response.errors && mountedRef.current){
          let sorted = sortNotifications(response.data.notification_channels)
          setNotificationChannels(sorted);
        } else if(response.errors[0]==="User has no notification channels") {
            if(newPhone || newEmail) setAllNew(newPhone, newEmail);
            else setAllNew(phone, email)
        }else if(response.errors.length > 1) setError(<ErrorCatcher error={response.error} />)
      }catch(ex){console.error(ex)}
      setLoading(false);
    }
  },[props, email, phone, setAllNew, sortNotifications]);

//#endregion

//#region useEffect
    //First load
    useEffect(()=>{
        mountedRef.current = true

        return()=>{
            mountedRef.current = false
        }
    },[])

    // check if auth user has permission to view this page for this user
    useEffect(() => {
        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccessMany([MODULE_ID], props.user_id);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }
        checkPermission();
    }, [props.user_id]);

    useEffect(()=>{
        if(props.user_id && mountedRef.current && !showEdits) getUserNotificationSettings()
    },[getUserNotificationSettings, props.user_id, email, phone, showEdits]);
//#endregion


    const updateSetting=async(channelId, stat, channelName, isNew, notiType, chanType)=>{
        setSuccess();
        setError();
        if(isNew==="true") addNewChannel(stat, notiType, chanType, channelName)
        else editChannel(channelId, stat, channelName)
    };

    const editChannel=async(channelId, stat, channelName)=>{
        try{
        let response = await Notifications.Channel.update({
            id: channelId,
            user_id: props.user_id,
            status: stat
        })
        if(!response.errors && !response.message){
            setSuccess(<Toast>Setting Changed for {channelName} successfully</Toast>);
            getUserNotificationSettings();
        }else if (response.errors || response.message){
            setError(<ErrorCatcher error={response.errors || response.message} />)
        }
        }catch(ex){console.error(ex)}
    }

    const addNewChannel=async(stat, notiType, chanType, channelName)=>{
        try{
        let response = await Notifications.Channel.add({
            user_id:props.user_id,
            status: stat,
            notification_type: notiType,
            channel_type: chanType
        })
        if(!response.errors && !response.message){
            setSuccess(<Toast>Setting Changed for {channelName} successfully</Toast>);
            getUserNotificationSettings();
        }else if(response.errors || response.message){
            setError(<ErrorCatcher error={response.errors || response.message} />)
        }
        }catch(ex){console.error(ex)}
    }

    const successError=(response)=>{
        if(response.success){
        setSuccess(<Toast>{response.success}</Toast>)
        }else if(response.error){
        setError(<ErrorCatcher error={response.error} />)
        }
    }

    if(loading) return(
        <SkeletonTheme color="#e0e0e0">
        <div className="mt-3 text-center">
            <Skeleton height={28} width={200}/>
            <Skeleton height={16} count={4} />
        </div>
        </SkeletonTheme>
    )

  return (
    <Container className="notification-settings-wrapper">
        <h4 className="section-title order-2 order-lg-1">Notification Settings</h4>
        <hr/>
        {userHasModulePermission===null ?
            <><Spinner /></>
        :
        !userHasModulePermission[MODULE_ID] ?
            <p>You do not have permission to change this user's notification settings.</p>
        :
        <>
            <div className="ms-sm-auto d-flex justify-content-end order-1 order-lg-2 mb-2">
                <Button type="button" className="btn-primary ms-sm-auto" onClick={()=>{setShowEdits(!showEdits)}}>{showEdits ? <span>Back</span> : <span>Change Contact Info</span>}</Button>
            </div>
            {showEdits ?
            <>
                <Row>
                    <Col sm={12}>
                        <UpdateChannels
                            getUserNotificationSettings={getUserNotificationSettings}
                            setNoReload={props.setNoReload}
                            triggerUserReset={props.triggerUserReset}
                            userId={props.user.id}
                            email={email}
                            phone={phone}
                            setPhone={setPhone}
                            setEmail={setEmail}
                            close={()=>setShowEdits(false)}
                            successError={successError}
                        />
                    </Col>
                </Row>
            </>
            :
            <>
                <Row>
                    <Col sm={12}>
                        <p>
                            To receive text notification, you must reply <span className="bold">START</span> in response to a text notification received.  
                            Reply <span className="bold">STOP</span> at any time or select "None" on this page to turn off notifications.  
                        </p>
                            {notificationChannels && !loading &&
                            notificationChannels.map((channel,i)=>(
                                <React.Fragment key={`notification-channel-${channel.id}${i}`}>
                                <Card>
                                    <Card.Title>{channel.name}: {channel.identifier}</Card.Title>
                                    <Card.Body>
                                    {/* <p>
                                    <span className="notification-type">{" "}</span>
                                    <span>
                                        <label htmlFor={`all${channel.name}`}>All</label>
                                        {" "}
                                        <input type="radio" name={`all${channel.name}`} />
                                    </span>
                                    <span>
                                        <label htmlFor={`no${channel.name}`}>None</label>
                                        {" "}
                                        <input type="radio" name={`no${channel.name}`} />
                                    </span>
                                    </p> */}
                                    <>
                                    {channel?.types?.map((type,j)=>(
                                        <React.Fragment key={`notification-type-${type.id}${i}${j}`}>
                                            <p>
                                            <span className="notification-type">{type.name}</span>
                                            <span>
                                                <label htmlFor={`text${type.name}${i}${j}`}>All</label>
                                                {" "}
                                                <input 
                                                type="radio" 
                                                data-channel={channel.id} 
                                                data-status={1} 
                                                data-chan_name={channel.identifier}
                                                data-is_new={type.new}
                                                data-noti_type={type.typeId}
                                                data-chan_type={channel.channelId}
                                                name={`texting${type.name}${i}${j}`} 
                                                id={`text${type.name}${i}${j}`} 
                                                defaultChecked={type.status}
                                                onChange={(e)=>{
                                                    document.getElementById(e.target.id).checked = true
                                                    type.status = 1
                                                    updateSetting(
                                                    e.target.dataset.channel, 
                                                    e.target.dataset.status, 
                                                    e.target.dataset.chan_name, 
                                                    e.target.dataset.is_new,
                                                    e.target.dataset.noti_type,
                                                    e.target.dataset.chan_type,
                                                    )
                                                }
                                                }
                                                />
                                            </span>
                                            <span>
                                                <label htmlFor={`noText${type.name}${i}${j}`}>None</label>
                                                {" "}
                                                <input 
                                                type ="radio" 
                                                data-channel={channel.id} 
                                                data-status={0}
                                                data-chan_name={channel.identifier}
                                                data-is_new={type.new}
                                                data-noti_type={type.typeId}
                                                data-chan_type={channel.channelId}
                                                name={`texting${type.name}${i}${j}`} 
                                                id={`noText${type.name}${i}${j}`} 
                                                defaultChecked={type.status===0}
                                                onChange={(e)=>{
                                                    document.getElementById(e.target.id).checked = true
                                                    notificationChannels[i].types[j].status= 0
                                                    updateSetting(
                                                    e.target.dataset.channel, 
                                                    e.target.dataset.status, 
                                                    e.target.dataset.chan_name, 
                                                    e.target.dataset.is_new,
                                                    e.target.dataset.noti_type,
                                                    e.target.dataset.chan_type,
                                                    )  
                                                }
                                                }
                                                />
                                            </span>
                                            </p>
                                        </React.Fragment>
                                        ))
                                    }
                                </>
                                </Card.Body>
                                </Card>
                                </React.Fragment>
                            ))
                            }  
                    </Col>
                </Row>
            </>
            }
        </>
        }
      {error}
      {success}
    </Container>
  )
}

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { format, formatISO, startOfDay } from 'date-fns';
import Pagination from '../Pagination';
import * as actions from '../../../store/actions';

import UsersAPI from '../../../api/Users';
import ErrorAPI from '../../../api/Error';

import './CheckinUserList.scss';
import { convertJSONtoCSV } from '../CSVConverter/CSVFunctions';

const iconWaiverTrue = (<i className="far fa-check-circle"></i>);
const iconWaiverFalse = (<i className="far fa-times-circle"></i>);

export const CheckinUserList = ({ paginated=false, numShown=6, todayOnly=true, onClick=null, dateTimeFormat="h:mm", refreshMilliseconds=2000, register_id=null, showAllButton=true }) => {
    const dispatch = useDispatch();
    const mountedRef = useRef(false);
    let history = useHistory();

    const [recentCheckins, setRecentCheckins] = useState(null);
    const [loadInProgress, setLoadInProgress] = useState(false);
    const [pagePart, setPagePart] = useState();
    const [pagePartModal, setPagePartModal] = useState();
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(numShown);
    const [page, setPage] = useState(1);

    const timerRef = useRef();

    useEffect(() => {
        timerRef.current = setInterval(() => {
            if (!loadInProgress) {
                setRefresh(refresh => refresh+1);
            }
        }, 3000);
        return () => {
            clearInterval(timerRef.current);
        }
    },[loadInProgress]);

    useEffect(() => {
        mountedRef.current = true;

        const loadRecentCheckins = async () => {
            let options = {
                sort_dir: "DESC",
                limit: numShown,
            };
            if (todayOnly) options['start_datetime'] = formatISO(startOfDay(new Date()));
            if (paginated) options = {...options, ...{
                paginated: 1,
                page_no: page
            }}

            UsersAPI.Checkin.getAll(options).then(response => {
                if (!response.errors && response.data) {
                    if (mountedRef.current) {
                        if (paginated) {
                            setRecentCheckins(response.data.checkins);
                            // setItemsPerPage(response.data.page_record_count);
                            setTotalItems(response.data.total_record_count);
                        } else {
                            setRecentCheckins(response.data);  
                        }
                        setLoadInProgress(false);
                    }
                } else {
                    setLoadInProgress(false);
                    console.error(response.errors);
                }
            }).catch(e => console.error(e));
        }

        if (!loadInProgress) {
            setLoadInProgress(true);
            loadRecentCheckins();
        }

        return () => {
            mountedRef.current = false;
        }
    //loadInProgress is conditional only and will create an endless loop if included in the dependency array
    //eslint-disable-next-line react-hooks/exhaustive-deps 
    },[paginated, numShown, todayOnly, page, refresh]);

    const loadUser = useCallback(async (user_id) => {
        await UsersAPI.get({
            id: user_id
        }).then(async response => {
            if (!response.errors && response.data.length>=1) {
                dispatch(actions.selectCustomer(response.data[0], register_id));
            } else {
                console.error(response.errors);
            }
        }).catch(e => console.error(e));
    },[register_id, dispatch]);

    const handleSelectUserButton =  useCallback(async (event) => {
        let user_id = isNaN(parseInt(event)) ? event.target.value : event;
        if (onClick) {
            onClick(user_id);
        } else if (register_id) {
            await loadUser(user_id);
        } else {
            history.push(`/p/users/${user_id}`);
        }
    },[register_id, onClick, loadUser, history]);

    const handleOpenModal = useCallback((event) => {
        setShowModal(true);
    },[]);

    const handleSelectUserInModal = useCallback((user_id) => {
        setShowModal(false);
        handleSelectUserButton(user_id);
    },[handleSelectUserButton]);

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    },[]);

    const downloadCSV = ()=>{
        let converted = convertJSONtoCSV(recentCheckins, null, true);
        
    }

    useEffect(() => {
        if (!recentCheckins) {
            setPagePart(
                <Button>
                    loading...
                </Button>
            );
        } else if (recentCheckins.length===0) {
            setPagePart(
                <Button>
                    No check-ins {todayOnly ? ' yet today' : ' for this time period'}.
                </Button>
            );
        } else {
            setPagePart(
                <>
                {showAllButton &&
                    <Button onClick={handleOpenModal} key={`checkin-all`} className="view-all">
                        <i className="far fa-list-ul"></i>
                    </Button>
                }
                {recentCheckins?.map((checkin) => {
                    let timeDisplay = format(new Date(checkin.checkin_at), dateTimeFormat);
                    return (
                        <Button value={checkin.user_id} onClick={handleSelectUserButton} key={`checkin-${checkin.user_id}-${checkin.checkin_at}`}>
                            {checkin.has_signed_waiver===1 ? iconWaiverTrue : iconWaiverFalse}
                            {`${timeDisplay} ${checkin.first_name} ${checkin.last_name}`}
                        </Button>
                    )
                })}
                    {paginated && 
                        <div className="d-flex justify-content-between">
                            <div>
                                <button className="basic-button" onClick={downloadCSV} disabled={recentCheckins?.length === 0}>Download Checkins</button>
                            </div>
                            {itemsPerPage && totalItems &&    
                                <div className="pagination">
                                    <Pagination
                                        itemsCount={totalItems}
                                        itemsPerPage={numShown}
                                        currentPage={page}
                                        setCurrentPage={setPage}
                                        alwaysShown={false}
                                    />
                                </div>
                            }
                        </div>
                    }
                </>
            );
        }
    },[recentCheckins, dateTimeFormat, paginated, todayOnly, totalItems, itemsPerPage, page, handleOpenModal, handleSelectUserButton, showAllButton, numShown]);

    return (
        <>
            {showAllButton &&
                <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    size="lg"
                    className="recent-checkins-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Today's Checkins</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CheckinUserList
                            paginated={true}
                            numShown={15}
                            dateTimeFormat="MM/dd/yyyy hh:mm aa -"
                            refreshMilliseconds={10000}
                            onClick={handleSelectUserInModal}
                            showAllButton={false}
                        />
                    </Modal.Body>
                </Modal>
            }
            <div className="recent-checkins-list" data-cy="checkin-user-list">
                {pagePart}
            </div>
        </>
    );
}
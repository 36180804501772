import React,{useState,useEffect,useCallback} from 'react';
import { useDispatch } from 'react-redux';
import {Stage,Layer as Lay} from 'react-konva';
import Layer from './Layer';

import * as actions from '../../store/actions';

import './canvas.css';

export const Canvas = ({items , clickHandler}) => {
	const dispatch = useDispatch();
	
	const [canvasItems,setCanvasItems]=useState(items);		// items to draw
	const [selected,setSelected]=useState([]);			// selected items
	const [triggerClick,setTriggerClick]=useState(false);	// triggers the click handler sent in props with the selected items
	const [layers,setLayers]=useState();
	//const [size, setSize] = useState({ width: 900, height: 450 });
	
	const selectHandler = item => {
		//console.log(item);
	}

	const itemClickHandler = useCallback(item => {
		let adding = false;
		// status 3 is unavailable, change to actual value when connected to api
		if (item.status!==3 && item.selectable) {
			// if already in the selected list, remove it
			if (selected.some(row => row.id===item.id)){
				//dispatch(actions.deselectItem(item)); //bugged
				dispatch(actions.resetSelectedItems());
				setSelected(prevState => [...prevState.filter(i=>i.id!==item.id)]);
			} else {
				// if not in the selected list, add it
				if (Array.isArray(item.children) && !item.children.length){
					adding = true;
					if (item.selectMultiple) setSelected(prevState=>[...prevState,item]);
					else {
						dispatch(actions.resetSelectedItems());
						setSelected([item]);
					};
				}
			}			
			if (Array.isArray(item.children) && item.children.length){
				setSelected([]); // resets selected
				dispatch(actions.resetSelectedItems());
				dispatch(actions.selectedRoots(item));
				let items = item.children.sort( (a,b) => a["id"] - b["id"] );
				items.forEach( item => {
					if (item.name.indexOf("Volleyball Court") >= 0) item.transparent = true;
				}); // bad but quick solution for volleyball courts over basketball courts
				setCanvasItems(items);
			} else if (adding) {
				dispatch(actions.selectedItems(
					{...item,
						...{booking: 
							{
								event_name: "",
								event_description: "",
								start_datetime: null,
								end_datetime: null,
							}
						}
					}
				));
			}
			setTriggerClick(true);
			if (clickHandler) clickHandler([item]);
		}
	},[selected, clickHandler, dispatch]);

	const mouseEnterHandler = (e,item) => {
		const container = e.target.getStage().container();
		if (item.status===3 || !item.selectable) container.style.cursor = "not-allowed";
		else container.style.cursor = "pointer";
		
	}

	const mouseLeaveHandler = (e) => {
		const container = e.target.getStage().container();
		container.style.cursor = "default";
	}

    useEffect(() => {
		let newLayers=[];
		canvasItems?.map((item,i) => {
			if(!item.selectable) {
				item.name="";
			}
			return newLayers.push(
				<Layer {...item}
					key={`layer-${i}`}
					selected={selected}
					onClick={()=>{itemClickHandler(item)}}
					onSelect={()=>{selectHandler(item)}}
					onMouseEnter={(e)=>{mouseEnterHandler(e,item)}}
					onMouseLeave={(e)=>{mouseLeaveHandler(e)}}
				/>
			);
		});
		setLayers(newLayers);
   },[canvasItems,selected,itemClickHandler]);

	useEffect(() => {
		if (triggerClick) {
			setTriggerClick(false);
			clickHandler(selected);
		}	
    },[triggerClick,clickHandler,selected]);

	/*useEffect(() => {
		const checkSize = () => {
			setSize({
				width: window.innerWidth,
				height: 450
			});
		};
	  
		window.addEventListener('resize', checkSize);
		return () => window.removeEventListener('resize', checkSize);
	  
	}, []);	*/

	//const scale = Math.ceil(size.width / 1100);

  	return (
		<React.Fragment>
			{/*scaleX={scale} scaleY={scale}*/}
			<Stage width={900} height={450}>
				<Lay>
					{layers}
				</Lay>
			</Stage>
		</React.Fragment>
  	);  
}


import Request from './Api';

const get = async (props) => {
    return (
        Request({
            url: "/routes",
            data: props,
            method: "GET",
        })
    );
}

const Routes = {
    get
}
  
export default Routes;

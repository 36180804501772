import React from 'react';
import { randomUUID } from '../../../../../utils/cms';

const Html = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const _props={
        ref: ref, 
        id: props.is_preview ? `preview-${randomUUID()}` : props.id,
        className: `html_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' '),
        style: props?.style || null,
        onClick: preview_click
    }

    let content = props?.html || `<p>Add something <b>COOL</b> here...</p>`;
    let scripts = content.match(/<script.*?>[\s\S]*?<\/script>/g);
    if (scripts){
        scripts.forEach(script=>{
            content = content.replace(script, '');
            /*let _code = script.replace(/<script.*?>/g, '').replace(/<\/script>/g, '');
            let s = document.createElement('script');
            s.type = 'text/javascript';
            s.defer = true;
            s.innerHTML = _code;
            document.body.appendChild(s);*/
        });
    }

    return <div {..._props} dangerouslySetInnerHTML={{__html: content}}/>;
});

export default Html;
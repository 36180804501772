import React,{useContext} from 'react';
import { useHistory } from "react-router-dom";
import { FileURLContext } from '../../../contexts/FileURLContext';

import styles from './NoMatch.module.scss';

export const NoMatch = (props) => {
    const history = useHistory();
    const imageURL = useContext(FileURLContext);
    const qs = new URLSearchParams(history.location.search);

    const path = history.location.pathname;

    if (path && (path === "/" || path === "/home") && !path.startsWith("/p/")){
        // add the p to the path and the query string
        history.push({
            pathname: `/p${path}`,
            search: qs?qs.toString():null,
        });
    }

    const clickHandler = (e) => {
        e.preventDefault();
        history.go(-1); 
        //history.go(-2); // go back 2 steps in case we added the p
    }

    return (
        <div className={`d-flex justify-content-center align-items-center h-100 ${styles.wrapper}`}>
            <div className="row">
                <div className="col-auto">
                    <img src={`${imageURL.lost}`} alt="404" className="img-fluid" />
                </div>
                <div className="col">
                    <div className="hid-mob rel-title">
                        <br/><br/>
                        <h1 className="portal-he">Looks like you're lost.</h1>
                        <p>Not to worry, you can always go back!!!</p>
                        <p><a className="btn btn-primary" href="#!" onClick={clickHandler}>Go Back</a></p>
                    </div>
                </div>
            </div>
        </div>
	// <Container className="lost h-100 pr-5">
	// 	<div>
	// 		<h1>Looks like you're lost.</h1>
	// 		<p>Not to worry, you can always go back!!!</p>
	// 		<p><Link className="btn btn-primary" to="/">Go Back</Link></p>
	// 	</div>
	// </Container>
)};
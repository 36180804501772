import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { format } from 'date-fns';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { authCheck } from "../../utils/auth";
import ErrorCatcher from '../../components/common/ErrorCatcher';
import Toast from '../../components/Toast';
import { DobDatePicker } from '../../components/DatePicker';
import { authUserHasModuleAccessMany } from "../../utils/auth";
import { autoGeneratePasswordHandler } from '../../utils/validation';
import { loadCompanyConfig, setFamily } from '../../utils/thunks';

import Users from '../../api/Users';
import Groups from '../../api/Groups';
import Config from '../../api/Config';

import './UserCreateEdit.scss';

const VIEW_MODULE_ID = 74;
const EDIT_MODULE_ID = 204;
const EDIT_ROLE_MODULE_ID = 271;

const UserFormInput = ({ name, title, user, type="text", onChange=()=>{}, validationErrors=null, required=false, disabled=false, btn=null }) => {
    let defaultValue = user ? user[name] : '';
    let error = validationErrors && validationErrors[name] ? validationErrors[name] : false;

    return (
        <Form.Group controlId={name}>
            <Form.Label>{title}{required ? <span className="required-star">{" "}*</span> : null}</Form.Label>
            <div className="d-flex">
                <Form.Control required={required} type={type} name={name} defaultValue={defaultValue} isInvalid={!!error} onChange={onChange} disabled={disabled} autoComplete="one-time-code"/>
                {btn && btn}
            </div>
            <Form.Control.Feedback type="invalid">{error || 'Required'}</Form.Control.Feedback>
        </Form.Group>
    )
}

const DobPicker = ({ name, title, dobValue, onChange=()=>{}, validationErrors=null, required=false }) => {
    
    let error = validationErrors && validationErrors[name] ? validationErrors[name] : false;

    return (
        <Form.Group controlId={name} className="role-col">
            <Form.Label>
                {title}
                {required ? <span className={"required-star"}>{" "}*</span> : "" }
            </Form.Label>
            <DobDatePicker
                required={required}
                selected={dobValue}
                onChange={onChange}
            />
            {!!error &&
                <div class="invalid-feedback" style={{'display': 'block'}}>{error}</div>
            }
        </Form.Group>
    )
}

const GroupRoleSelect = ({ roles, value, placeholder="Select...", onChange=()=>{}, name="group_member_role_id", title="Group Role", required=true, showBlankOption=true, validationErrors=null }) => {

    const [displayRoles, setDisplayRoles] = useState(roles);
    const [displayValue, setDisplayValue] = useState(value);
    const [error, setError] = useState();

    // add a blank option at the top
    useEffect(() => {
        if (showBlankOption) setDisplayRoles([{id: -1, name: placeholder}, ...roles]);
    },[roles, showBlankOption, placeholder]);

    useEffect(() => {
        setDisplayValue(value || -1);    // allows null to be passed in
    },[value]);

    const handleValueChange = useCallback((e) => {
        let val = e.target.value;
        onChange(val===-1 ? null : val); // convert 0 back to null for no result selected
    },[onChange]);

    useEffect(() => {
        if (validationErrors && validationErrors[name]) {
            setError(validationErrors[name]);
        } else {
            setError();
        }
    },[validationErrors, name]);

    return (
        <Form.Group controlId={name}>
            <Form.Label>{title}</Form.Label>
            {!(roles) ?
                <SkeletonTheme color="#e0e0e0">
                    <div data-cy="loading-skeleton-div" style={{marginTop: "-2px"}}>
                        <Skeleton height={40} count={1} />
                    </div>
                </SkeletonTheme>
            :
                <>
                    <Form.Control required={required} custom as="select" name="group_member_role_id" isInvalid={!!error} value={displayValue} onChange={handleValueChange}>
                        {displayRoles.map(role => (
                            <option key={`family-role-${role.id}`} value={role.id}>{role.name}</option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{error || 'Required'}</Form.Control.Feedback>
                </>
            }
        </Form.Group>
    );
}

export const UserCreateEdit = ({ user=null, user_id=null, role_id=null, group_id=null, afterSubmit=()=>{}, groupRoles=null, onClose=()=>{}, showCloseBtn=false }) => {
    let history = useHistory();

    let storedUser = authCheck(history);
    let dispatch = useDispatch();

    const mountedRef = useRef(false);

    const companyConfig = useSelector(state => state.company.config);
    
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [usernameError, setUsernameError] = useState();
    const [hideForm, setHideForm] = useState(false);
    const [groupRolesList, setGroupRolesList] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingRoles, setLoadingRoles] = useState(true);
    const [roleList, setRoleList]=useState(null);
    const [selectedRole, setSelectedRole]=useState(7) //default to patron

    const [userLatest, setUserLatest] = useState();
    const [emailLatest, setEmailLatest] = useState();
    const [dobValue, setDobValue] = useState(null);
    const [currentRelationshipValue, setCurrentRelationshipValue] = useState();
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);

    useEffect(()=> {
        mountedRef.current = true;

        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccessMany([VIEW_MODULE_ID, EDIT_MODULE_ID, EDIT_ROLE_MODULE_ID]);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }

        const getAllRoles = async ()=>{
            try{
                let response = await Users.Roles.getAll();
                if(response.status === 200){
                    setLoadingRoles(false);
                    setRoleList(response.data.roles) //roles come back from the back already filtered by what 
                }else setGroupRolesList([])
            }catch(ex){console.error(ex)}
        }

        if(!companyConfig) dispatch(loadCompanyConfig());    
        checkPermission();
        getAllRoles();

        return () => mountedRef.current = false;
    },[dispatch, companyConfig]);

    const addToValidationErrors = (name, error) => {
        setValidationErrors(prev => ({...prev, [name]: error}));
    }

    useEffect(() => {
        if (userLatest) {
            var date = userLatest?.dob ? new Date(userLatest.dob) : null;
            if (date) date.setHours(date.getHours()+12);
            setDobValue(date);
        }
    },[userLatest]);

    useEffect(() => {
        setCurrentRelationshipValue(role_id);
    },[role_id]);

	useEffect(() => {
        if (!userLatest && user) {
            setUserLatest(user);
            setLoading(false);
        } else if (!userLatest && user_id) {
            try {
                Users.get({id: user_id})
                .then(response => {
                    if(mountedRef.current && response.errors) {
                        setError(response.errors);
                        setHideForm(true);
                    } else if (mountedRef.current) {
                        setUserLatest(response.data[0]);
                    }
                    setLoading(false);
                }).catch(e => console.error(e)); 
            } catch(error){}
        } else {
            setLoading(false);
        }
	}, [user_id, user, userLatest]);

    useEffect(() => {
        // family component will pass in groupRoles, but if the component doesn't have it then look it up
        if (groupRoles) {
            setGroupRolesList(groupRoles);
            setLoadingRoles(false);
        } else if (group_id) {
            try {
                Groups.get({id: group_id})
                .then(response => {
                    if(mountedRef.current && response.errors) {
                        setError(response.errors);
                        setHideForm(true);
                    } else if (mountedRef.current) {
                        setGroupRolesList(response.data[0].group_member_roles);
                    }
                    setLoadingRoles(false);
                }).catch(e => console.error(e)); 
            } catch(error){console.error(error)}
        }
    },[groupRoles, group_id]);


    const currentRelationshipHandler = (value) => {
        setCurrentRelationshipValue(value);
        resetErrors();
    }

    const validateDob = useCallback(() => {
        let dobConfig = companyConfig?.requires_dob;
        if(dobConfig === "false" || dobConfig===false || !dobConfig) return true; //override the check if the dob is not required
        else if (dobValue) {
            return true;
        } else {
            addToValidationErrors('dob', "DOB required");
            setSubmitting(false);
            return false;
        }
    },[dobValue, companyConfig]);

    const validateGroupRole = useCallback(() => {
        if ((currentRelationshipValue && currentRelationshipValue>=0) || !group_id) {
            return true;
        } else {
            addToValidationErrors('group_member_role_id', "Group role required");
            setSubmitting(false);
            return false;
        }
    },[currentRelationshipValue, group_id]);

    const validateEmail = useCallback((new_email) => {
        if (!new_email) {
            addToValidationErrors('email', "Email is required");
            setSubmitting(false);
            return false;
        }
        //regex for email validation
        //email address must match this in order to be valid - a false will throw an error
        const emailTestRegex=/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/gi.test(new_email);
        if(!emailTestRegex) {
            addToValidationErrors('email', "Email must be a valid email address.");
            setSubmitting(false);
            return false;
        }

        return true;
    },[]);

    const validatePassword = useCallback((password, confirmPass)=>{
        if(password !== confirmPass){
            addToValidationErrors('password', "Passwords must match");
            setSubmitting(false);
            return false;
        }
        return true
    },[])

    const validateUsername = useCallback(async (new_username) => {
        try {
            if (!new_username) {
                addToValidationErrors('username', "Username required");
                setSubmitting(false);
                return false;
            }
            //octal codes on ASCII table in order as below @ : ; < = > ? {space} ! " # $ % & ' ( ) * + , . / [ \ ] ^ ` { | } ~
            //Any of these symbols present in the username will throw an error
            const testRegEx=/[\100\72-\77\40-\54\56-\57\133-\136\140\173-\176]/.test(new_username); 
            if(testRegEx) {
                addToValidationErrors('username', "Usernames cannot contain symbols.");
                setSubmitting(false);
                return false;
            }

            if(!userLatest || (userLatest && userLatest.username !== new_username)) {
                // write to console that this step is happening
                console.log("checking username availability");
                let resp = await Users.checkUsername({username: new_username});
                if(resp.data && resp.data[0] && resp.data[0].available === false) {
                    // write to console that this step is happening
                    console.log("username is unavailable");
                    addToValidationErrors('username', "Username is unavailable. Please pick another.");
                    setSubmitting(false);
                    return false;
                } else {
                    // write to console that this step is happening
                    console.log("username is available");
                    setUsernameError();
                    return true;
                }   
            } else {
                setUsernameError();
                return true;
            }
        } catch (error) {
            console.error(error);
            setError(<ErrorCatcher error={error} />);
            return false;
        }
    },[userLatest]);


    const handleAfterSubmit = useCallback((id=null) => {
        if (userLatest?.id === storedUser?.profile.id) {
            // TODO: call thunk to refresh the user info in redux and localStorage
        }
        dispatch(setFamily()) //update family after adding a new user, in case it's a family user
        if(id) afterSubmit(id);
        else afterSubmit();
    }, [userLatest, storedUser, afterSubmit]);


    const resetErrors = () => {
        setValidationErrors();
        setValidated(false);
        setError(null);
    }

    const updateUser = useCallback(async (formDataObj) => {
        let id = formDataObj.id;
        let responseUser;
        let responseGroupMember;

        if (formDataObj.id) responseUser = await Users.update(formDataObj);
        else responseUser = await Users.create(formDataObj);

        if (responseUser && !responseUser?.errors) {
            if (group_id && currentRelationshipValue && !id) {
                if(responseUser?.data?.id) {
                    if(!id) id = responseUser?.data?.id
                    responseGroupMember = await Groups.invite({             // create/add
                        group_id: group_id,
                        user_ids: parseInt(id),
                        member_role_id: parseInt(currentRelationshipValue),
                        status_id: 2        // straight to confirmed status
                    });
                }
            } else if(group_id && currentRelationshipValue && id) {     //edit
                responseGroupMember = await Groups.edit_member({
                    group_id: group_id,
                    user_id: parseInt(id),
                    member_role_id: parseInt(currentRelationshipValue)
                });
            }
            if(responseUser?.data?.id && !user_id){
                await Users.Roles.edit({ user_id : responseUser.data.id, roles: [selectedRole]})
            }
            if (responseGroupMember?.errors) {
                setError(<ErrorCatcher error={responseGroupMember.errors} />);
            } else {
                setSubmitting(false);
                setValidated(false);
                // TO DO: add different wording here for various scenarios: profile, user, family member, group member
                setSuccess(<Toast>User saved successfully!</Toast>);
                handleAfterSubmit(id);
            }
        } else if(responseUser.errors){
            if (responseUser.errors.validation) {
                setValidationErrors(responseUser.errors.validation);
            } else {
                setError(<ErrorCatcher error={responseUser.errors} />);
            }
            setSubmitting(false);
        } else {
            setSubmitting(false);
            setError(<ErrorCatcher error={"Error updating user."} />);
        }
    }, [group_id, currentRelationshipValue, handleAfterSubmit, selectedRole, user_id]);

    const validate = useCallback(async (form) => {
        let validUsername = await validateUsername(form.username.value);
        // write to console that this step is happening and the result
        console.log("validUsername", validUsername);
        let validEmail = validateEmail(form.email.value);
        // write to console that this step is happening and the result
        console.log("validEmail", validEmail);
        let validDob = validateDob();
        // write to console that this step is happening and the result
        console.log("validDob", validDob);
        let validGroupRole = validateGroupRole();
        // write to console that this step is happening and the result
        console.log("validGroupRole", validGroupRole);
        let validPassword;

        //there won't be a userId if it's a new user and the password field isn't present on editing users
        if(!user_id) {
            validPassword = validatePassword(form.password.value, form.password_confirmation.value);
            // write to console that this step is happening and the result
            console.log("validPassword", validPassword);
        }
        else validPassword = true;
        let validForm = form.checkValidity();
        if(validForm && validUsername && validDob && validGroupRole && validEmail && validPassword) setValidated(true);
        else setValidated(false);

        return (validForm && validUsername && validDob && validGroupRole && validEmail && validPassword);
    }, [validateUsername, validateDob, validateGroupRole, validateEmail, validatePassword, user_id]);

    const handleViewPassword=()=>{
        let passDoc = document.getElementById("password")
        let passConfDoc = document.getElementById("password_confirmation")

        passDoc.type === "password" ? passDoc.type = "text" : passDoc.type = "password"
        passConfDoc.type ==="password" ? passConfDoc.type = "text" : passConfDoc.type = "password"
    }

    const generateRandomPassword =()=>{
        let randomPassword = autoGeneratePasswordHandler();
        document.getElementById("password").value = randomPassword;
        document.getElementById("password_confirmation").value = randomPassword;
    }

    // form submission
    const submitHandler = useCallback(async (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();
        
        setValidationErrors();
        setSubmitting(true);
        setError(null);
        setSuccess(null);

        let localValidated = await validate(form);
        if(!localValidated){
            setSubmitting(false);
            let localValidationErrors = Object.values(validationErrors);
            setError(<ErrorCatcher error={localValidationErrors} />)
        }
        else if (localValidated) {
            const formData = new FormData(e.target);
               
            let id = userLatest?.id || user_id || null;
            if(id) formData.append("id", parseInt(id));

            if(dobValue) formData.append("dob", format(dobValue, "yyy-MM-dd"));
            let formDataObj = Object.fromEntries(formData.entries());
            
            updateUser(formDataObj);

        } else {
            setSubmitting(false);
        }
    }, [user_id, userLatest, dobValue, updateUser, validate,  validationErrors]);

    let allowedToEdit = (userHasModulePermission && userHasModulePermission[EDIT_MODULE_ID]===true) || false;
    
    // write companyConfig?.requires_dob to console
    //console.log("companyConfig?.requires_dob", companyConfig?.requires_dob);
    // output companyConfig to console
    //console.log("companyConfig", companyConfig);

    return (
        <div className="user-create-edit-form">
            {success}
            <Form  validated={validated} onSubmit={submitHandler} id='create-edit-form'>
                {loading || (user_id && !userLatest) || !userHasModulePermission ?
                    <div className="flex-center">
                        <Spinner animation="border" variant="secondary" />
                    </div>
                :
                !userHasModulePermission[VIEW_MODULE_ID] ?
                    <p>You do not have permission to view this user's profile.</p>
                :
                <>
                    <Form.Row className={hideForm ? 'hidden' : ''}>
                        <div className="input-width-2">
                            <UserFormInput
                                name="email"
                                title="E-mail"
                                type="email"
                                user={userLatest}
                                validationErrors={validationErrors}
                                onChange={resetErrors}
                                disabled={!allowedToEdit}
                                required
                            />
                        </div>
                        <div className="input-width-2">
                            <UserFormInput
                                name="username"
                                title="Username"
                                user={userLatest}
                                validationErrors={validationErrors}
                                onChange={resetErrors}
                                disabled={!allowedToEdit}
                                required
                            />
                        </div>
                    </Form.Row>
                    
                    {!user_id &&
                        <Form.Row className={hideForm ? 'hidden' : ''}>
                            <div className="input-width-2">
                                <UserFormInput
                                    name="password"
                                    title="Password"
                                    type="password"
                                    user={userLatest}
                                    validationErrors={validationErrors}
                                    onChange={resetErrors}
                                    disabled={!allowedToEdit}
                                    required
                                    btn={<Button variant="outline-link m-0" onClick={handleViewPassword}><i className="far fa-eye" /></Button>}
                                />
                            </div>
                            <div className="input-width-2">
                                <UserFormInput
                                    name="password_confirmation"
                                    title="Password Confirmation"
                                    type="password"
                                    user={userLatest}
                                    validationErrors={validationErrors}
                                    onChange={resetErrors}
                                    disabled={!allowedToEdit}
                                    required
                                    btn={<Button variant="outline-link m-0" onClick={generateRandomPassword}><i className="far fa-comment-alt-dots" /></Button>}
                                />
                                
                            </div>
                        </Form.Row>
                    }
                    <hr/>
                    <Form.Row className={hideForm ? 'hidden' : ''}>
                        <div className="input-width-3a">
                            <UserFormInput
                                name="first_name"
                                title="First Name"
                                user={userLatest}
                                validationErrors={validationErrors}
                                onChange={resetErrors}
                                disabled={!allowedToEdit}
                                required
                            />
                        </div>
                        <div className="input-width-3b">
                            <UserFormInput
                                name="middle_name"
                                title="Middle Name"
                                user={userLatest}
                                validationErrors={validationErrors}
                                onChange={resetErrors}
                                disabled={!allowedToEdit}
                            />
                        </div>
                        <div className="input-width-3a">
                            <UserFormInput
                                name="last_name"
                                title="Last Name"
                                user={userLatest}
                                validationErrors={validationErrors}
                                onChange={resetErrors}
                                disabled={!allowedToEdit}
                                required
                            />
                        </div>
                    </Form.Row>

                    <Form.Row className={hideForm ? 'hidden' : ''}>
                        <div className="input-width-2">
                            <UserFormInput
                                name="mobile_phone"
                                title="Mobile Phone"
                                type="tel"
                                user={userLatest}
                                validationErrors={validationErrors}
                                onChange={resetErrors}
                                disabled={!allowedToEdit}
                            />
                        </div>
                        <div className="input-width-2">
                            <UserFormInput
                                name="phone_phone"
                                title="Home Phone"
                                type="tel"
                                user={userLatest}
                                validationErrors={validationErrors}
                                onChange={resetErrors}
                                disabled={!allowedToEdit}
                            />
                        </div>
                    </Form.Row>
                    <Form.Row className={hideForm ? 'hidden' : ''}>
                        <div className="input-width-2">
                            <DobPicker
                                name="dob"
                                title="Date of Birth"
                                dobValue={dobValue}
                                onChange={date => setDobValue(date)}
                                validationErrors={validationErrors}
                                disabled={!allowedToEdit}
                                required={(companyConfig?.requires_dob===true || companyConfig?.requires_dob==="true")}
                            />
                        </div>            
                        <div className="input-width-2">
                            {group_id &&
                                <GroupRoleSelect
                                    roles={groupRolesList}
                                    title="Group Role"
                                    value={currentRelationshipValue}
                                    onChange={currentRelationshipHandler}
                                    validationErrors={validationErrors}
                                    showBlankOption={!userLatest}
                                    disabled={!allowedToEdit}
                                />
                            }
                        </div>
                    </Form.Row>
                    {userHasModulePermission[EDIT_ROLE_MODULE_ID]===true && !loadingRoles && !user_id &&
                        <div className="role-col">
                            <label>
                                User Role
                            </label>
                            <select onChange={(e)=>setSelectedRole(e.target.value)}>
                                {roleList?.map(role=>(
                                    <option selected={selectedRole ? true : false} value={role.id}>
                                        {role.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    }
                </>
                }
                {error &&
                    <div className="error-text">{error}</div>
                }
                <div className="flex mt-2">
                    <Button variant="primary" type="submit" disabled={submitting}
                        className={`${submitting?" submitting":""} ${loading || !allowedToEdit || ((user_id || user) && !userLatest) ? ' hidden' : 'blurp'}`}
                    >Save</Button>
                    {showCloseBtn &&
                        <Button variant="secondary" type="button" disabled={submitting} onClick={onClose} className={`${submitting?" submitting":""}`}>Close</Button>
                    }
                </div>
            </Form>
        </div>
    );
}
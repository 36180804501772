import React,{useEffect, useState, useCallback, useContext } from 'react';
import { useParams,useLocation, useHistory, Link  } from "react-router-dom";
import { useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {Container,Row,Col,Card,ListGroup} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import SubHeader from '../../../components/common/SubHeader';
import Waiver from '../Waiver/';
import ProfileCard from '../Profile/Card';
//Side nav imports
import NotificationSettings from '../NotificationSettings';
import CheckinHistory from '../Profile/CheckinHistory';
import UsersWaivers from '../Waiver/UsersWaivers';
import Notes from '../Profile/Notes';
import ResetPassword from '../Profile/ResetPassword';
import Events from '../Profile/Events';
import Family from '../Profile/Family';
import OutstandingCharges from '../Profile/OutstandingCharges';
import Subscriptions from '../Profile/Subscriptions';
import Groups from '../Profile/Groups';
import Settings from '../Profile/Settings';
import GiftCards from '../Profile/GiftCards';
import UserCreateEdit from '../../../components/UserCreateEdit';
import PrintQRCode from '../../../components/PrintQRCode';
import Spinner from '../../../components/Spinner';

import usePrevious from '../../../components/common/CustomHooks'
import Users from '../../../api/Users';
import { FileURLContext } from '../../../contexts/FileURLContext';

import './Details.scss';
import { authCheck } from '../../../utils/auth';
import Tutorials from '../../../components/Tutorials';
import { authUserHasModuleAccessMany } from "../../../utils/auth";
import { setErrors } from '../../../store/actions';

// list all of the tabs and their modules
// right now component doesn't do anything yet, that should be used in the future to simplify the loading
// any link can use :id as a placeholder for the user id - it's the only variable allowed in URLs right now, it's getting manually replaced with the actual user id
const allTabs = [
    { displayName: 'Profile', hash: 'Profile', moduleId: 100, component: ProfileCard, link: null, icon: "far fa-user" },
    { displayName: 'Groups', hash: 'Groups', moduleId: 106, component: Groups, link: null, icon: "far fa-users-class" },
    { displayName: 'Notes', hash: 'Notes', moduleId: 202, component: Notes, link: null, icon: "far fa-user-edit" },
    { displayName: 'Family Members', hash: 'Family', moduleId: 207, component: Family, link: null, icon: "far fa-users" },
    { displayName: 'Account Settings', hash: 'Settings', moduleId: 201, component: Settings, link: null, icon: "far fa-cog" },
    { displayName: 'Purchased Gift Cards', hash: 'GiftCards', moduleId: 318, component: GiftCards, link: null, icon: "far fa-gift-card" },
    { displayName: 'Subscriptions', hash: 'Subscriptions', moduleId: 75, component: Subscriptions, link: null, icon: "far fa-money-check-alt" },
    { displayName: 'Outstanding Charges', hash: 'Outstanding', moduleId: 76, component: OutstandingCharges, link: null, icon: "far fa-money-bill-alt" },
    { displayName: 'Event Schedule', hash: 'Schedule', moduleId: 77, component: Events, link: null, icon: "far fa-calendar" },
    { displayName: 'Reset Password', hash: 'Password', moduleId: 78, component: ResetPassword, link: null, icon: "far fa-key" },
    { displayName: 'Documents', hash: 'Documents', moduleId: 79, component: UsersWaivers, link: null, icon: "far fa-file-alt" },
    { displayName: 'Notifications', hash: 'Notifications', moduleId: 80, component: NotificationSettings, link: null, icon: "far fa-bell" },
    { displayName: 'Check-in History', hash: 'Checkin', moduleId: 184, component: CheckinHistory, link: null, icon: "far fa-check-circle" },
    { displayName: 'Transactions', hash: 'Transactions', moduleId: 73, component: null, link: `/p/transactions/:id`, icon: "far fa-list-alt" },
    { displayName: 'Orders', hash: 'Orders', moduleId: 325, component:null, link: `/p/all-orders/:id`,  icon: "far fa-receipt"},
    { displayName: 'Print QR Label', hash: 'PrintQR', moduleId: 203, component: PrintQRCode, link: null, icon: "far fa-qrcode" }
]

/**Can take in parameter to load profile rather than params
 * 
 * @param {int} props.posId - used for loading the details page outside of passing user parameters in
 */
const Details = (props) => {    
    const location = useLocation();
    const history = useHistory();
    const screenSize = window.innerWidth
    
    let { id } = useParams();
    if (props.posId) {
        id = props.posId;
    }

    const imageURL = useContext(FileURLContext);
    const companyConfig = useSelector(state => state.company?.config);
    const featuresAvailable = useSelector(state => state.permissions?.features)

    const [currentUserInfo, setCurrentUserInfo]=useState()
    const [userInfo,setUserInfo]=useState(); //user of the current page
    const [currentUserRoleId, setCurrentUserRoleId] = useState(null); //gets the role of the current user
    const [canEdit, setCanEdit] = useState();
    const [refresh, setRefresh]=useState(0);
    const [waiverStatus, setWaiverStatus]=useState();
    const [noReload, setNoReload]=useState(false);
    const [currentHash, setCurrentHash] = useState(location.hash);
    const [triggerPrintQRCode, setTriggerPrintQRCode] = useState(0);
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);

    const handleOpenQRCode = () => {
        setTriggerPrintQRCode(prev => prev + 1);
    }

    useEffect(()=>{
        const checkPermission = async () => {
            try {
                // let moduleIds = [...allTabs.map(tab =>tab.moduleId).filter(id => id !== null), ...tempAdminTabs.map(tab=>tab.moduleId).filter(id => id !== null)];
                let moduleIds = allTabs.map(tab => tab.moduleId).filter(id => id !== null);
                moduleIds.push(4) //adding the module id for userdashboard for the breadcrumbs
                let response = await authUserHasModuleAccessMany(moduleIds, id);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }
        checkPermission();
    },[id])

    useEffect(()=>{
        let tempInfo=authCheck(history);
        setCurrentUserInfo(tempInfo.profile) 
    },[history])
    
	useEffect(() => {
        const _getUser = async () => {
            const res = await Users.get({id:id});
            if (res.errors) {
                setErrors(res.errors);
            } else
            if (res.data){
                setUserInfo(res.data[0]);
            }
        }

        if (id) { //Checking against the old values prevents an extra render and extra API call.  

            _getUser();

            // get lowest role ID of currently logged-in user
            // lower role ID has greater permissions
            let currentUserRoles = JSON.parse(localStorage.getItem("user")).roles;
            setCurrentUserRoleId(Math.min(...currentUserRoles.map( role => role.id )));
        }

	}, [id, location.pathname, refresh]);

    /**To reset the profile after a profile edit in the case of updating a user and not being able to see the changes after refresh */
    const triggerUserReset=useCallback(()=>{
        setRefresh(prev => prev+1);
    },[]);

    const changeTabHandler = useCallback((tabname) => {
        history.push(location.pathname + '#' + tabname);
    }, [location,history]);    

    const loadProfilePartHandler = useCallback(() => {
        if (userInfo) {
            let component;
            switch (currentHash){
                /*case "Social":
                    component=<Social user={userInfo} user_id={userInfo.id} referer={location.pathname} />;
                    break;
                case "Permissions":
                    component=<Permissions user={userInfo} user_id={userInfo.id} referer={location.pathname} />;
                    break;
                case "Roles":
                    component=<Roles user={userInfo} user_id={userInfo.id} referer={location.pathname} />;
                    break;*/
                case "Settings":
                    component=<Settings user={userInfo} user_id={userInfo.id} referer={location.pathname} />;
                    break;
                case "Groups":
                    component=<Groups user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} currentUserRoleId={currentUserRoleId} goTo={changeTabHandler} />;
                    break;
                case "Subscriptions":
                    component=<Subscriptions user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} />;
                    break;
                case "GiftCards":
                    component=<GiftCards user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} currentUserRoleId={currentUserRoleId} />;
                    break;
                case "Outstanding":
                    component=<OutstandingCharges user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} />;
                    break;
                case "Family":
                    component=<Family user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} currentUserRoleId={currentUserRoleId} />;
                    break;
                case "Schedule":
                    component=<Events user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} />;
                    break;
                case "Password":
                    component=<ResetPassword user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} />;
                    break;
                // case "Transactions":
                //     component=<Transactions user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} />;
                //     break;
                case "Notes":
                    component=<Notes user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} />;
                    break;
                case "Documents":
                    component=<UsersWaivers user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} />;
                    break;
                case "Checkin":
                    component=<CheckinHistory user={userInfo} user_id={userInfo.id} referer={location.pathname} can_edit={canEdit} />;
                    break;
                case "Notifications":
                    component=<NotificationSettings user={userInfo} user_id={userInfo.id} triggerUserReset={triggerUserReset} setNoReload={setNoReload} />;
                    break;
                case "BasicInfo":
                default:
                    component = (<>
                        <h4 className="mb-3 section-title">User Details</h4>
                        <hr/>
                        <UserCreateEdit user={userInfo} user_id={userInfo.id} referer={location.pathname} currentUserRoleId={currentUserRoleId} afterSubmit={triggerUserReset} />
                    </>);
                    break;
            }
            setProfilePart(component);
        }
    },[userInfo, currentHash, triggerUserReset, setNoReload, canEdit, currentUserRoleId, changeTabHandler, location.pathname]);


    //noReload is intentionally left off as a dependency.  If it's going to update, 
    //it should check for noreload(to update the user/sidebar from somewhere other than the basic info form), but not be triggered by it.  
    //After this has run through once, properly, it can change it back to have no effects elsewhere.
    useEffect(() => {
        if(userInfo && !noReload) {
            loadProfilePartHandler();
        }
        if(noReload) setNoReload(false)
    },[userInfo,loadProfilePartHandler, noReload]);

    useEffect(() => {
        if(userInfo && currentUserRoleId) {
            let lowestId = Math.min(...userInfo.roles.map(role => role.id));
            if(currentUserRoleId === 1 || currentUserRoleId < lowestId) setCanEdit(true);
            else setCanEdit(false);
        }
    },[userInfo, currentUserRoleId])

    const waiverReset=()=>{
        setWaiverStatus(1);
    }

    const [profilePart,setProfilePart]=useState(            
        <SkeletonTheme color="#e0e0e0">
            <Skeleton height={30} style={{marginBottom:"1rem"}} />
            <Skeleton height={12} count={5} />
        </SkeletonTheme>
    );

    useEffect(() => {
        setCurrentHash(location.hash?.substring(1));

        const unlisten = history.listen((loc) => { 
           setCurrentHash(loc.hash?.substring(1));
        })

        return unlisten;
     },[history,location.hash])

    useEffect(() => {
        loadProfilePartHandler(currentHash);
    },[currentHash,loadProfilePartHandler]);

    if (userHasModulePermission!==null && !userHasModulePermission[100]) {
        return (
            <Container fluid data-cy="user-profile-container" className="user-profile-container">
                <Card className="content-card">
                    <p>You do not have permission to view this user's profile.</p>
                </Card>
            </Container>
        )
    } else if (!userInfo) return (
        <Container fluid>
            <Row>
                <Col lg="4" className="text-center">
                    <SkeletonTheme color="#e0e0e0">
                        <Skeleton circle={true} height={100} width={100} style={{marginBottom:"1rem"}} />
                        <Skeleton height={12} count={5} />
                        <Skeleton height={12} style={{marginTop:"2rem"}} />
                        <Skeleton height={12} count={5} />
                    </SkeletonTheme>
                </Col>
                <Col lg="8">
                    <SkeletonTheme color="#e0e0e0">
                        <Skeleton height={30} style={{marginBottom:"1rem"}} />
                        <Skeleton height={12} count={5} />
                        <Skeleton height={30} style={{marginBottom:"1rem",marginTop:"2rem"}} />
                        <Skeleton height={12} count={10} />
                    </SkeletonTheme>
                </Col>
            </Row>
        </Container>
    );

    // // smash the two lists together
    // let masterTabsList = null;
    // if (currentUserRoleId <= 5) {
    //     masterTabsList = [...allTabs, ...tempAdminTabs];
    // } else {
    //     masterTabsList = allTabs;
    // }

    // create the breadcrumbs array
    const breadcrumbs = [
        { linkAs: Link, linkProps: { to: "/p/home" }, text: "Home" }
    ];

    // if user has permissions for the user dashboard, add that to the breadcrumbs
    if (userHasModulePermission && userHasModulePermission[4]) {
        breadcrumbs.push({ linkAs: Link, linkProps: { to: "/p/users/dashboard" }, text: "User Dashboard" });
    }

    // add the current user's name
    breadcrumbs.push({ text: `${userInfo.first_name} ${userInfo.last_name}` });

    //create tutorials array
    const tutorials = [
        { tutorialSection:"User Profile", allSectionTutorial: currentHash ? false : true, subSection: currentHash ? currentHash : null, basicInfo: currentHash ? false : true, navigationTutorial: false }
    ];

    return ( userInfo &&
        <Container fluid data-cy="user-profile-container" className="user-profile-container">
                {!props.posId &&
                    <SubHeader items={breadcrumbs} tutorials={tutorials} />
                    
                }
                {imageURL && companyConfig?.requires_waiver &&
                    <Row>
                        <Col sm="12">
                            <Waiver    
                                reset={waiverReset}
                                waiverStatus={waiverStatus}
                                user={userInfo} 
                                hide={true}
                                btntxt={
                                    currentUserInfo.id === userInfo.id ?
                                        "Sign Your Waiver"
                                    :
                                        "Sign User's Waiver"
                                } 
                                msg={
                                    currentUserInfo.id === userInfo.id ?
                                        "You do not have an electronic waiver on file - please click to sign."
                                    :
                                        `${userInfo.first_name} ${userInfo.last_name} does not have an electronic waiver on file.`
                                }
                            />  
                        </Col>
                    </Row>
                }
                <div className="content-card">
                    <Row className="main-profile-body">
                        <Col className="profile-card-col">
                            <ProfileCard 
                                user_id={id} 
                                user={userInfo} 
                                currentUserRoleId={currentUserRoleId} 
                                refresh={refresh} 
                                featuresAvailable={featuresAvailable}
                            />
                            {featuresAvailable && featuresAvailable[17] && //checkin feature
                                <PrintQRCode user={userInfo} triggerOpen={triggerPrintQRCode} hideButton={true} />
                            }
                        </Col>
                        <Col>
                            <Card>
                                <Row>
                                    <Col lg="auto" xlg={3} className="order-1 order-lg-2">
                                        <ListGroup className="profileMenu" variant="flush">
                                            {allTabs.map(tab => {
                                                if (tab.moduleId===null || (userHasModulePermission && userHasModulePermission[tab.moduleId])) {
                                                    let href = '#'+tab.hash;
                                                    let clickAction = () => { changeTabHandler(tab.hash) };
                                                    if (tab.link !== null) {
                                                        href = tab.link.replace(':id', id);
                                                        clickAction = () => { history.push(href, {from: `user profile id:${id}`}) };
                                                    }
                                                    // this is not the most elegant solution, but it works for now
                                                    // can't add this into the definition of the constant because the function is not defined yet
                                                    if (tab.hash === 'PrintQR') {
                                                        clickAction = handleOpenQRCode;
                                                    }
                                                    return (
                                                        <ListGroup.Item action key={`profile-tab-${tab.hash}`}
                                                            href={href}
                                                            onClick={clickAction}
                                                            className={currentHash===tab.hash ? "profile-menu-active" : null}
                                                            data-cy={tab.hash}
                                                        >
                                                            <span className="li-icon"><i className={tab.icon} data-tip={tab.displayName} /></span>
                                                            <span className="li-text">{tab.displayName}</span>
                                                        </ListGroup.Item>
                                                    )
                                                } else {
                                                    return <></>;
                                                }
                                            })}                                             
                                        </ListGroup>
                                    </Col>
                                    <Col xlg={9} className="order-2 order-lg-1">
                                        {profilePart /*this is where the magic is happening :-O */ }
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>
                {screenSize <=1200 && <ReactTooltip globalEventOff="click" effect="solid" backgroundColor="#262D33" arrowColor="#262D33" /> }
        </Container>
    );
}

export default Details;
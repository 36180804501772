import * as actionTypes from '../actions/actionTypes';

const initialState = { 
        token: null,
        user: null,
        error: null,
        logged_in: false,
        redirect_path: '/',
        registration_redirect: null,
        from_signup: false
    };
  
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                logged_in: false,
                loading: false,
            };
        case actionTypes.REGISTER_FAIL:
            return {
                ...state,
                logged_in: false,
                error: action.error,
            };
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                logged_in: true,
                loading: false,
                user: action.user,
                registration_redirect:null,
                from_signup:false
            };
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                logged_in: false,
                user: null,
                loading: false,
                error: action.error,
            };
        case actionTypes.AUTH_LOGOUT:
            return {
                ...initialState
            };
        case actionTypes.SET_AUTH_REDIRECT_PATH: 
            return {
                ...state,
                redirect_path: action.path,
            };
        case actionTypes.AUTH_REGISTRATION_REDIRECT:
            return{
                ...state,
                registration_redirect: action.path,
            };
        case actionTypes.AUTH_FROM_SIGNUP:
            return{
                ...state,
                from_signup: action.from_signup
            };
        case actionTypes.AUTH_RESET:
            return {
                ...initialState,
                ...action.authState
            }
        case actionTypes.AUTH_SET:
            return {
                ...state,
                ...action.authState
            }
        default:
            return state;
    }
}

export default authReducer;
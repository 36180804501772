import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import { useRoleCheck } from '../../../../components/common/CustomHooks'
import { authCheck } from "../../../../utils/auth";
import Table from '../../../../components/common/Table';
import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import Spinner from '../../../../components/Spinner';

import Users from '../../../../api/Users';
import Products from '../../../../api/Products';
import { addToCart } from '../../../../utils/thunks';
import { authUserHasModuleAccessMany } from "../../../../utils/auth";

import '../../Subscriptions/Subscriptions.css';

const VIEW_MODULE_ID = 76;
const RECORD_PAYMENT_MODULE_ID = 215;

export const OutstandingCharges = (props) => {

    let history = useHistory();
    let user = authCheck(history);

    //props.user for the user who's profile is being viewed
    const mountedRef=useRef();
    const dispatch = useDispatch();
    const role = useRoleCheck();
    const defaultPatronRegisterId = useSelector((state)=>state.pos.register)
    const items = useSelector((state)=>state.pos[defaultPatronRegisterId].items)
    const [loading,setLoading]=useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [charges,setCharges]=useState([]);
    const [isPatron, setIsPatron]=useState();
    const [activeCharge, setActiveCharge]=useState();
    const [showModal, setShowModal]=useState(false);
    const [isDuplicate, setIsDuplicate]=useState(false);
    const [eventId, setEventId]=useState();
    const [includeFamily, setIncludeFamily]=useState(1);
    const [myAccount, setMyAccount]=useState(false);
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);

    const getOutstandingCharges=useCallback(async()=>{
        if(props.user_id){
            let familyCharges=[];
            try{
                let response = await Users.getOutstandingCharges({
                    id: props.user_id,
                    include_family: includeFamily
                });
                if(!response.errors && mountedRef.current) {
                    response.data.outstanding_charges.forEach((charge)=>{
                        charge.family = props.user.first_name;
                        charge.familyId = props.user.id;
                        familyCharges.push(charge)
                    })
                }
                if(!response.errors && includeFamily ===1 && mountedRef.current){
                    response.data.family.forEach((member)=>{
                        member.outstanding_charges.forEach((charge)=>{
                            charge.family = member.first_name
                            charge.familyId = member.id
                            familyCharges.push(charge)
                        })
                    })
                } 
                setCharges(familyCharges)
                if(response.errors) setError(<ErrorCatcher error={response.errors} />)
            }catch(ex){console.error(ex)}
            setLoading(false)
        }
    },[includeFamily, props.user_id, props.user.first_name, props.user.id]);

    // first load
    useEffect(() => {
        mountedRef.current=true

        // cancel stuff when component unmounts
        return () => {
            mountedRef.current = false;
            setLoading(false);
            setCharges([]);
        }        
    },[]);

    // check if auth user has permission to view this page for this user
    useEffect(() => {
        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccessMany([VIEW_MODULE_ID, RECORD_PAYMENT_MODULE_ID], props.user_id);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }
        checkPermission();
    }, [props.user_id]);

    useEffect(()=>{
        if(props.user_id && mountedRef.current) {
            getOutstandingCharges();
        }
    },[props.user_id, getOutstandingCharges]);

    useEffect(()=>{
        if(role && role.id > 5){
            setIsPatron(true);
        }else setIsPatron(false);
        //If staff member is on their own profile, treat it as a patron page (unless they are Siteboss admins)
        if(role.id > 2 && user.profile.id === props.user.id) {
            setIsPatron(true);
            setMyAccount(true);
        } 
    },[role,props.user.id, user.profile.id]);

    useEffect(()=>{
        if(items && activeCharge){
            let match = false;
            items.forEach((item)=>{
                if(item.product_id === activeCharge.id) match = true
            })
            if(mountedRef.current){
                setIsDuplicate(match)
            } 
        }
    }, [showModal, items, activeCharge]);

    const columns = React.useMemo(() => [{
        id: 'table',
        columns: [
            {
                Header: 'Description',
                id: 'event_name',
                className: "align-middle",
                accessor: d => (
                    <div>
                        {d.event_name} {(myAccount && d.family === props.user.first_name) ? <span>(for me)</span>:<span>(for {d.family})</span>}
                    </div>
                ),
            },
            {
                Header: 'Cost',
                id: 'amount',
                className: "align-middle",
                accessor: d => (
                    <div>
                        {d.product_variants.map((v,i)=>(
                            <span key={`prod-variant-price=${i}`}>${parseFloat(v.price).toFixed(2)}</span>
                        ))}
                    </div>
                ),
            },
            {
                Header: <><span className="mobile-hidden">Requires </span><span>Membership</span></>,
                id: 'membership',
                className: "align-middle",
                accessor: d => (
                    <div>
                        {d.requires_membership === 1 ? "Yes" : "No"}
                    </div>
                ),
            },
            {
                Header: '',
                id: 'id',
                className: "d-flex align-self-start",
                accessor: d=>(
                    <Button key={`cart-icon-oc-${d.product_id}`} onClick={e=>getOutstandingProduct(d.product_id, d.event_id, d.familyId)}>
                        <i className="far fa-cart-plus"/> 
                        <span className="mobile-hidden">
                            Add To Cart
                        </span>
                    </Button>
                )
            }
        ],
    }],[myAccount, props.user.first_name]);

    const staffColumns = React.useMemo(
        () => [{
            id: 'table',
            columns: [
                {
                    Header: 'Description',
                    id: 'event_name',
                    className: "align-middle",
                    accessor: d => (
                        <div>
                            {d.event_name} {d.family && <span>(for {d.family})</span>}
                        </div>
                    ),
                },
                {
                    Header: 'Amount',
                    id: 'amount',
                    className: "align-middle",
                    accessor: d => (
                        <div>
                            {d.product_variants.map((v,i)=>(
                                <span key={`prod-variant-${i}`}>${parseFloat(v.price).toFixed(2)}</span>
                            ))}
                        </div>
                    ),
                },
                {
                    Header: <><span className="mobile-hidden">Requires </span><span>Membership</span></>,
                    id: 'membership',
                    className: "align-middle",
                    accessor: d => (
                        <div>
                            {d.requires_membership === 1 ? "Yes" : "No"}
                        </div>
                    ),
                },
                /*{
                    id: 'id',
                    show: false,
                    url:"/users/:id",
                    show:false,
                },*/
            ],
        }],[]
    );

    const addToCartHandler=async()=>{
       dispatch(
           addToCart([
               {
                   category_id: activeCharge.categories[0]?.id,
                   discounts: 0,
                   hash: null,
                   id: activeCharge.id,
                   product_type_id: activeCharge.product_type_id,
                   is_taxable: activeCharge.is_taxable,
                   original_price: +activeCharge.product_variants[0].price,
                   parent_id: null,
                   product_name: activeCharge.name,
                   product_price: +activeCharge.product_variants[0].price,
                   qty: 1,
                   type: 1,
                   variant_id: activeCharge.product_variants[0].id,
                   variant_name: activeCharge.product_variants[0].name,
                   event: {
                       event_id: eventId,
                       for_user_id: activeCharge.familyId
                   },
               },
           ])
       )
       if(mountedRef.current === true){
           setShowModal(false)
       }
    }

    const getOutstandingProduct=async(itemId, eventId, family)=>{
        try{
            let response=await Products.get({ id: itemId})
            if(mountedRef.current === true && !response.errors) {
                let product = response.data.products[0];
                product.familyId  =  family;
                setActiveCharge(response.data.products[0]);
                setEventId(eventId);
                setShowModal(true);
            }
            else setError(<ErrorCatcher error={response.errors} />)
        }
        catch(ex){
            setError(<ErrorCatcher error={ex} />)
        }
    }

    if(loading){
        return(
            <SkeletonTheme color="#e0e0e0">
                <div className="mt-3" data-cy="loading-skeleton-div">
                    <Skeleton height={28} width={200}/>
                    <Skeleton height={16} count={4} />
                </div>
            </SkeletonTheme>
        )
    }
    
    return (
        <Container fluid>
            <h4 className="section-title">Outstanding Charges</h4>
            <hr/>
            {userHasModulePermission===null ?
                <><Spinner /></>
            :
            !userHasModulePermission[VIEW_MODULE_ID] ?
                <p>You do not have permission to view this user's outstanding charges.</p>
            :
            <>
                <Row>
                    <Col>
                        {!charges?.length && !loading &&
                            <p className="text-center">No outstanding charges</p>
                        }
                        {charges?.length>0 && !loading &&
                            <div className={`${loading?" loading":""}`}>
                                {isPatron ? <Table columns={columns} data={charges} {...props} /> : <Table columns={staffColumns} data={charges} {...props} />}
                                {/*userHasModulePermission[RECORD_PAYMENT_MODULE_ID] &&
                                    <Button variant="primary" className={`mt-3 btn-pay${submitting?" submitting":""}`} onClick={() => history.push("/p/pay?user=" + props.user_id)}>Record Payment</Button>
                                */}
                                {error}
                                {success}
                            </div>
                        }
                    </Col>
                </Row>
                <br />
                <Row>
                    {!loading && 
                        <ToggleButtonGroup type="radio" name="family-y-n" defaultValue={includeFamily} onChange={e=>setIncludeFamily(e)}>
                            <ToggleButton className="family-toggle-btn" variant="secondary" value={1}>Family Charges</ToggleButton>
                            <ToggleButton className="family-toggle-btn" variant="secondary" value={0}>{myAccount ? <span>My Charges</span>: <span>{props?.user?.first_name}</span>}</ToggleButton>
                        </ToggleButtonGroup>
                    }
                </Row>
                <Modal centered show={showModal} onHide={e=>setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{isDuplicate ? "Oops":"Add to Cart" }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {activeCharge && 
                        <>
                            <p>
                                {activeCharge.name}: ${activeCharge.product_variants[0].price}
                            </p>
                            <p>
                                {activeCharge.description} 
                            </p>
                            <p>
                            {isDuplicate ? 

                                <span style={{color: "red", fontWeight: "700"}}>This item is already in your cart!</span>
                            :
                                <>
                                    <Button onClick={addToCartHandler}>Add charge to Cart?</Button>
                                </>
                            }
                            </p>
                        </>
                        }
                    </Modal.Body>
                </Modal>
            </>
            }
        </Container>
    );
}
import * as actionTypes from '../actions/actionTypes';

const initialState = { 
    current: {
        params: {}
    },
    errors: {},
    conditions: [],
    param_init_data: {},
    param_multi_input_data: {},
};
  
const couponReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_COUPON:
            return {
                ...state,
                current: {...state.current, ...action.payload},
            };
        case actionTypes.SET_PARAMS:
            return {
                ...state,
                current: {
                    ...state.current,
                    params: {...state.current.params, ...action.payload},
                }
            };
        case actionTypes.SET_PARAM_INIT_DATA:
            return {
                ...state,
                param_init_data: action.payload,
            };
        case actionTypes.SET_PARAM_MULTI_INPUT_DATA:
            return {
                ...state,
                param_multi_input_data: {...state.param_multi_input_data, ...action.payload},
            };
        case actionTypes.SET_CONDITIONS:
            return {
                ...state,
                conditions: action.payload,
            };
        case actionTypes.SET_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case actionTypes.SET_ADDITIONAL_ERRORS:
            return {
                ...state,
                errors: {...state.errors, ...action.payload},
            };
        case actionTypes.RESET_ERRORS:
            return {
                ...state,
                errors: {},
            };
        default:
            return state;
    }
}

export default couponReducer;
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';

import LayoutStructure from '../../../../../Components/Properties/LayoutStructure';
import Overlay from '../../../Overlay';

import { cmsComponentList, toCamelCase, injectCss, randomUUID } from '../../../../../../../utils/cms';
import * as actions from '../../../../../../../store/actions';

export const Preview = React.forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const cmsSelector = useSelector(state => state.cms);
    const cmsSelectorElements = useSelector(state => state.cmsElements.present);

    const [extraElements, setExtraElements] = useState();

    const rowSelectionHandler = useCallback((selection, id) => {
        if (!id) id = props.id;
        if (cmsSelectorElements.elements){
            let _currentElement = cmsSelectorElements.elements.filter(el => el && el?.id === id);
            if (_currentElement.length > 0) {
                _currentElement[0]?.properties?.filter(b=>b.name === "custom-layout")?.forEach(_prop => {
                    _prop.value = selection;
                    _prop.changed = true;
                });
                dispatch(actions.CMSAddElement({...JSON.parse(JSON.stringify(_currentElement[0]))}));
                dispatch(actions.CMSSetCurrentElement(id));
            }
        }
    },[cmsSelectorElements.elements, props.id, dispatch]);

    const addColumnHandler = useCallback((e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if (cmsSelectorElements.elements){
            let _currentElement = cmsSelectorElements.elements.filter(el => el && el?.id === id);
            if (_currentElement.length > 0) {
                const parent_id = _currentElement[0]?.parent_id;
                if (parent_id){
                    let _parentElement = cmsSelectorElements.elements.filter(el => el && el?.id === parent_id);
                    if (_parentElement.length > 0) {
                        const _children = _parentElement[0]?.[_parentElement[0]?.element_id]?.content?.length || 0;
                        if (_children && _children < 6) {
                            rowSelectionHandler(_children + 1, _parentElement[0].id);
                        }
                    }
                }
            }
        }
    },[cmsSelectorElements.elements, rowSelectionHandler]);

    const duplicateHandler = useCallback((e, id) => {
        e.preventDefault();
        e.stopPropagation();
        if (cmsSelectorElements.elements){
            let _currentElement = cmsSelectorElements.elements.filter(el => el && el?.id === id);
            if (_currentElement.length > 0) {
                dispatch(actions.CMSCopyElement(id));
            }
        }
    },[dispatch, cmsSelectorElements.elements]);
 

    useEffect(() => {
        if (props.element_type==="row" && React.Children.toArray(props.children).filter(child => !child.props.accept_type).length<=0){
            setExtraElements(            
                <Container className="empty-options text-center py-5" style={{maxWidth:"400px",position:"relative",zIndex:999}}>
                    <p>SELECT LAYOUT STRUCTURE</p>
                    <LayoutStructure selection={(s)=>rowSelectionHandler(s)} />
                </Container>
            );
        }
    },[props.element_type, props.children, rowSelectionHandler]);

    useEffect(()=>{
        return () => {
            setExtraElements(null);
        }
    },[]);    

    let PreviewComponent = props.element_id || "div";
    let _style={}, _classes="", _css="";
    let _additional_props={
        id: `${props?.properties?.id || props.id}-${randomUUID()}`,
        page_id: "preview",
        onClick: props?.onClick || null,
        "data-handler-id": props["data-handler-id"]
    };

    // load the component from the cmsComponentList
    if (PreviewComponent[0] === PreviewComponent[0].toUpperCase()) {
        const cmp = cmsComponentList().filter(item=>item.component_name===PreviewComponent);
        if (cmp.length) {
            PreviewComponent = cmp[0].component;
            _additional_props={  
                ..._additional_props,
                company_context: props.company_context, 
                is_preview: props.is_preview,
                can_drop: cmp[0].can_drop || false,
                apply_styles: cmp[0].apply_styles!==undefined ? cmp[0].apply_styles : true,
            };
        } 
    }

    let is_text = false, is_void = false, value = null;
    if (_additional_props?.innerText){ // determine if its a text element and get the innerText
        is_text = true;
        value = _additional_props.innerText;
    }

    // determine if its a void element
    if (PreviewComponent === "img" || PreviewComponent === "input" || PreviewComponent === "br" || PreviewComponent === "hr") {
        is_void = true;
        is_text=false;
    }

    // filter out dropboxes from the children because these are not part of the page, just droppable areas
    const _children = React.Children.toArray(props.children).filter(child => !child.props.accept_type);

    // we need to add a class to empty elements that hold children to make them visible
    if ((props.element_id.toLowerCase() === "section" || 
            props.element_id.toLowerCase()  === "div" || 
            props.element_id.toLowerCase()  === "layout" || 
            props.element_id.toLowerCase()  === "wizard" || 
            props.element_id.toLowerCase()  === "wizardStep" || 
            props.element_id.toLowerCase()  === "slider" || 
            props.element_id.toLowerCase()  === "slide" || 
            props.element_id.toLowerCase()  === "form") && 
            !_children.length /*&& props.id!=="div-dustbin"*/){
        _classes+="empty-dustbin ";
    }

    // ultimate source of thruth is the properties array since that is what we can change in the toolbar
    props.properties.filter(a=>a?.changed && (a?.value || typeof a?.value === "boolean")).forEach(a => {
        let _value = a.value;
        if (a.prop_type==="styles" || a.prop_type==="basic"){
            if ((a.name === "background-image" || a.name === "list-style-image" || a.name === "border-image" || a.name === "mask-image" || a.name === "mask-box-image" || a.name === "content") 
                    && _value.indexOf("url(") === -1 && _value.indexOf("http")>=0) _value = `url(${_value})`;
            _style[toCamelCase(a.name.trim())]=_value;
        } else if (a.prop_type === "classes"){
            _value.split(" ").forEach(_class => {
                _classes+=`${_class.trim()} ${_class.trim()}_preview `;
            });
            //_classes+=`${_value.trim()} `;
        } 
        else if (a.prop_type === "value"){
            is_text = true;
            value = _value.trim();
        } else if (a.prop_type === "css" || a.name === "css") {
            _additional_props.page_id = props.id; // this will link the injected custom css to the element
            _css += _value.trim()+" ";
        } else _additional_props[a.name.trim()] = _value;
    });

    // inject the custom css
    if (_css) injectCss(_css.trim(),props.id);


    // if its a text element, we need to use dangerouslySetInnerHTML in case people want to use html tags
    if (is_text){
        return (<PreviewComponent 
            {..._additional_props}
            ref = {ref}
            style = {{..._style,...props.style}}
            className = {`${props.isContentBlock?"":"preview-element-x text-element"} ${cmsSelector.currentElement === props.id ? "active-element" : ""} ${props.className || ""} ${_classes}`.trim().replace(/\s+/g,' ')}
            dangerouslySetInnerHTML={{__html: value}}
        />);
    }

    // if its a void element, we need to use React.createElement
    if (is_void){
        return React.createElement(PreviewComponent, {ref, ..._additional_props, style:{..._style,...props.style}, className:`${(props.isContentBlock || props.element_type==="content-block") ?"":"preview-element-x"} ${cmsSelector.currentElement === props.id ? "active-element" : ""} ${props.className || ""} ${_classes}`.trim().replace(/\s+/g,' ')});
    }

    return (
        <PreviewComponent 
            ref={ref} 
            {..._additional_props} 
            style={{..._style,...props.style}} 
            className={`
                ${props.isContentBlock?"":"preview-element-x"} 
                ${cmsSelector.currentElement === props.id ? "active-element" : ""} 
                ${props.className || ""} 
                ${_classes} 
                ${props.element_type === "container" ? "x-primary" : props.element_type === "column" ? "x-secondary" : props.element_type === "content-block" ? "x-default" : ""}`.trim().replace(/\s+/g,' ')
            }>
            {props.id!=="div-dustbin" && props.element_type!== "row" && props.index!==0 &&
                <Overlay 
                    inset
                    horizontal
                    center = {props.element_type === "container" || undefined}
                    top = {props.element_type === "content-block" || undefined}
                    right = {props.element_type === "content-block" || undefined}
                    bottom = {props.element_type === "column" || undefined}
                    left = {props.element_type === "column" || undefined}
                    color = {props.element_type === "container" ? "primary" : props.element_type === "column" ? "secondary" : "default"}
                    classes = {`element-overlay`}
                    id = {props.id}
                    delete = {(e)=>props.remove(e, props.id)}
                    add = {props.element_type === "column" ? (e, id)=> addColumnHandler(e, id) : undefined}
                    duplicate = {(props.element_type === "column" || props.element_type === "container" )? (e, id)=> duplicateHandler(e, id) : undefined}
                />
            }
            {(!props?.children || !props.children.length) && <>{value}</>}
            {props?.children}
            {extraElements}
        </PreviewComponent>
    );
});
import React, {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';

import CCPayment from '../../CCPayment';

export const Payment = (props) => {
    const {goToStep, saveStepValues, savePaymentFields, disableNext} = props;
    const user = useSelector(state => state.auth.user.profile);
    
    const [loading, setLoading] = useState(true);
    const [collectJS, setCollectJS] = useState();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [validated, setValidated] = useState();

    useEffect(() => {
        if (props.stepValues?.selectedUsers) setSelectedUsers(props.stepValues.selectedUsers);
        else setSelectedUsers([{id: user.id, first_name: user.first_name, last_name: user.last_name}]);
    }, [user, props.stepValues]);

    useEffect(() => {
        if (!props.price){
            goToStep({
                preventDefault: () => {},
                stopPropagation: () => {},
            }, props.referrerStep === "back" ? 2 : 4);
        } else setLoading(false);
    }, [ goToStep, props.referrerStep, props.price]);

    useEffect(() => {
        if (!collectJS || !validated) {
            disableNext(true);
        } else if (collectJS && validated) disableNext(false);
    }, [collectJS, validated, disableNext]);    

    useEffect(() => {
        return () => {
            setLoading(false);
            setCollectJS(null);
            setSelectedUsers([]);
        }
    }, []);

    const validationHandler = useCallback(response => {
        if (response) setValidated(true);
        else setValidated(false);
    }, []);

    const collectJsHandler = useCallback(response => {
        if (response.isToken && response.token) {
            saveStepValues({token: response.token});
        }
        if (!collectJS && response.collectJS){
            setCollectJS(response);
            saveStepValues({collectJS: response.collectJS});
        } else if (!response.collectJS) {
            savePaymentFields(response);
        }
    }, [saveStepValues, collectJS, savePaymentFields]);

    if (loading || !selectedUsers.length) return (<p>Loading...</p>);

    return (
        <div>
            <p>
                <label className="form-label">Payment information:</label>
            </p>
            <CCPayment {...props} forceMobile hideButton callback={collectJsHandler} validationCallback={validationHandler} price={+props.price*selectedUsers.length} />
        </div>
    );
}
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Row, Col, Container, Card, Form, InputGroup, Button, Image} from 'react-bootstrap';

import WebcamCapture from '../../../../../components/WebcamCapture';

import styles from "../GiftCard/GiftCard.module.scss";

const paymentMethodName = "Check";

export const Check = (props) => {
    const {onPaymentUpdate, onPaymentMethodChange} = props;

    const firstLoad = useRef(true);
    const ref = useRef();

    const user = useSelector(state=>state.pos[props.register_id]?.user);

    const [totalPayment, setTotalPayment] = useState(0);
    const [checks, setChecks] = useState([]);
    const [capturedImage, setCapturedImage] = useState();
    const [error, setError] = useState({});

    const addHandler = useCallback(e => {
        e.preventDefault();

        const _check = {};
        const _errors = {};        
        const inputs = ref.current.querySelectorAll('input');

        for (const input of inputs){
            if (input.name === "check_name" && !input.value) _errors.check_name = "Name is required";
            if (input.name === "check_number" && !input.value) _errors.check_number = "Check # is required";
            if (input.name === "amount" && (!input.value || +input.value === 0)) _errors.amount = "Amount is required";

            if (Object.keys(_errors).length > 0) break;
            
            _check[input.name] = input.name === "amount" ? (+input.value || 0) : input.value;
        }

        if (Object.keys(_errors).length > 0) setError(_errors);
        else {
            if (capturedImage) _check.image = capturedImage;
            setError({});
            setCapturedImage(null);
            setChecks(prev=>{
                const _prev = [...prev];
                _prev.push({id: _prev.length + 1, ..._check});
                return [..._prev];
            });
            const inputs = ref.current.querySelectorAll('input');
            inputs.forEach(input=>{
                let val = "";
                if (input.name === "check_name"){
                    val = ((user?.first_name || "")+" "+(user?.last_name || "")).trim();
                }
                input.value = val;
                input.defaultValue = val;
            });
        }
    }, [capturedImage, user?.first_name, user?.last_name]);

    const removeHandler = useCallback((e, id) => {
        e.preventDefault();
        setChecks(prev=>{
            const _prev = [...prev];
            const index = _prev.findIndex(a=>+a.id===+id);
            if (index>=0) _prev.splice(index, 1);
            return [..._prev];
        });
    }, []);

    const captureHandler = useCallback(data => {
        if (data) setCapturedImage(data);
    }, []);

    useEffect(()=>{
        if (props?.totals){
            setTotalPayment(props.totals.estimated_balance || 0);
        }
    }, [props.totals]);

    useEffect(()=>{
        if (props.payments && firstLoad.current){
            const payment = props.payments.filter(a=>a.payment_method_id === props.payment_method_id);
            if (payment.length > 0) setChecks(payment.map((a, i)=>({id: i+1, ...a})));
        }
    }, [props.payments, props.payment_method_id, props.totals]);

    useEffect(() => {
        const _data = [];
        if (checks.length > 0){
            checks.forEach(c=>{
                _data.push({
                    amount: +c.amount,
                    payment_method_name: `${paymentMethodName} #${c.check_number}`, 
                    check_name: c.check_name, 
                    check_number: c.check_number,
                    image: c?.image || null
                });
            });
        }
        onPaymentUpdate(props.payment_method_id, _data);
    }, [props.payment_method_id, checks, onPaymentUpdate]);

    useEffect(()=>{
        if (firstLoad.current) {
            onPaymentMethodChange(props.payment_method_id);
        }
    }, [props.payment_method_id, onPaymentMethodChange]);

    useEffect(()=>{

        if (ref.current) {
            ref.current.querySelector('input[name="check_number"]').focus();
        }

        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }

        return () => {
            setChecks([]);
            setError({});
            setCapturedImage(null);
        }
    }, []);

    return (
        <Container fluid data-cy="checkout-check-payment">
            <Row>
                <Col sm={12} lg="6" ref={ref}>
                    <Form.Group controlId="check_number">
                        <Form.Label>Check #</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" name="check_number" defaultValue="" disabled={props.submitting} />                        
                        </InputGroup>
                        {error?.check_number && <div className="error-text mb-3" data-cy="error-check-number">{error.check_number}</div>}
                    </Form.Group>
                    <Form.Group controlId="check_name">
                        <Form.Label>Name</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" name="check_name" defaultValue={((user?.first_name || "")+" "+(user?.last_name || "")).trim()} disabled={props.submitting} />                        
                        </InputGroup>
                        {error?.check_name && <div className="error-text mb-3" data-cy="error-check-name">{error.check_name}</div>}
                    </Form.Group>
                    <Form.Group controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>$</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="number" min={0} name="amount" value={totalPayment || 0} onChange={e=>setTotalPayment(e.target.value)} disabled={props.submitting} />
                        </InputGroup>
                        {error?.amount && <div className="error-text mb-3">{error.amount}</div>}
                    </Form.Group>
                    {!capturedImage && <WebcamCapture onCapture={captureHandler} height={150} />}
                    {capturedImage && 
                        <Container fluid>
                            <Image src={capturedImage} alt="Check" fluid />
                            <Button variant="light" className="w-100" disabled={props.submitting} onClick={e=>setCapturedImage(null)}>Retake</Button>
                        </Container>
                    }
                    <br/>
                    <Button variant="light" className="w-100" disabled={props.submitting} onClick={addHandler} data-cy="add-check-button">Add</Button>
                </Col>
                <Col sm={12} lg="6">
                    {checks?.map((check, i) => (
                        <Card className={styles.wrapper} key={`checkc-${i}`}>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <div className={styles.title}>Check #{check.check_number}</div>
                                        <p>
                                            {check.check_name}<br/>
                                            Amount: <span className="bold">${check.amount.toFixed(2)}</span>
                                        </p>
                                        <Button variant="light" disabled={props.submitting} onClick={e=>removeHandler(e, check.id)}>Remove</Button>
                                    </Col>
                                    {check?.image && 
                                    <Col>
                                        <Image src={check.image} alt={`Check #${check.check_number}`} thumbnail fluid />
                                    </Col>
                                }
                                </Row>
                            </Card.Body>
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}
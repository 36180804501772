import * as actionTypes from './actionTypes';
export const setCompanyDetails=(companyDetails)=>{
    return{
        type: actionTypes.SET_COMPANY_DETAILS,
        companyDetails: companyDetails
    };
};

export const setCompanyId=(id)=>{
    return{
        type: actionTypes.SET_COMPANY_ID,
        id: id
    };
};

export const setCompanyName=(name)=>{
    return{
        type: actionTypes.SET_COMPANY_NAME,
        name: name
    };
};

export const setCompanyConfig=(config)=>{
    return{
        type: actionTypes.SET_COMPANY_CONFIG,
        config: config
    };
};
import React from 'react';
import { randomUUID } from '../../../../../utils/cms';

const Heading = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    let Component = "h1";
    switch(props?.type){
        case "XL":
        case "h2":
            Component = "h2";
            break;
        case "Large":
        case "h3":
            Component = "h3";
            break;
        case "Medium":
        case "h4":
            Component = "h4";
            break;
        case "Small":
        case "h5":
            Component = "h5";
            break;
        case "Normal":
        case "h6":
            Component = "h6";
            break;
        case "XXL":
        case "h1":
        default:
            Component = "h1";
            break;
    }
    const _props={
        ref: ref, 
        id: props.is_preview ? `preview-${randomUUID()}` : props?.id,
        className: `heading_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' '),
        style: props?.style || null,
        onClick: preview_click
    }
    
    return <Component {..._props}>{props?.text || `Heading ${Component.substring(1)}`}</Component>;
});

export default Heading;
import React, {useState, useEffect, useRef, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'

import NewUserTypeahead from '../../../../../../components/Typeahead/NewUserTypeahead';
import AssignPayees from '../AssignPayees'
import AssignPaymentAmounts from '../AssignPaymentAmounts'

import * as actions from '../../../../../../store/actions'
import { updateCart } from '../../../../../../utils/thunks'
import usePrevious from '../../../../../../components/common/CustomHooks'

//const GUEST_USER_ID = 7;
export const SelectUsers = (props) => {

    // destructured props
    const {register,type,currentOrder,cancelSplit}=props;

    const dispatch = useDispatch();
    const mountedRef = useRef(false);
    const requiresUser=useSelector(state=>state.splitPayments.requiresUser);
    const spErrors=useSelector(state=>state.splitPayments.errors);
    const payees = useSelector(state=>state.splitPayments.payees);
    const allPaid=useSelector(state=>state.splitPayments.allPaid);
    
    const company=useSelector(state=>state.company);    
    const GUEST_USER_ID = company?.config?.guest_user_id || null;

    const [forUser, setForUser]=useState(useSelector(state=>state.pos[props.register].user));
    const [fieldsetDisable, setFieldsetDisable]=useState(false)
    const [firstLoad, setFirstLoad]=useState(true);
    const [forUserCorrect, setForUserCorrect]=useState(true);
    const [numberOfPayees, setNumberOfPayees]=useState("");
    const [allPayees, setAllPayees]=useState();
    const oldNumberOfPayees = usePrevious(numberOfPayees);

//#region useCallback
    const createAllPayees=useCallback(()=>{
        if(oldNumberOfPayees !== undefined && oldNumberOfPayees !=="" && oldNumberOfPayees !== numberOfPayees){
            let allPayees=[]
            if(props.type==="multiMany" && forUser){
                allPayees.push({payeeOrder: 1, identifier: forUser.first_name + forUser.last_name, id: forUser.id})
                for(let i = 1; i<numberOfPayees; i++){
                    allPayees.push({payeeOrder: i+1, identifier: `Guest ${i+1}`, id:7})
                }
            }else{
                for(let i = 0; i < numberOfPayees; i++){
                    allPayees.push({payeeOrder: i+1, identifier: `Guest ${i+1}`, id:7})
                }
            }
            sortPayees(allPayees)
            setAllPayees(allPayees);
        }else if(oldNumberOfPayees === undefined){
            setAllPayees(payees);
            setNumberOfPayees(payees.length);
        }else if(payees.length > 1 && numberOfPayees !== payees.length){
            setNumberOfPayees(payees.length);
            cancelSplit(null, true, false);
        }
    },[numberOfPayees,cancelSplit,props.type,forUser,oldNumberOfPayees,payees]);

//#endregion useCallback

//#region useEffect
    //first load
    useEffect(()=>{
        mountedRef.current = true

        return()=>{
            mountedRef.current = false
        }
    },[]);

    useEffect(()=>{
        if(payees) setNumberOfPayees(payees.length);
    },[payees])


    useEffect(()=>{
        if(allPaid === "Yes" || allPaid === "Partial") setFieldsetDisable(true);
    },[allPaid])

    useEffect(()=>{
       createAllPayees();
    }, [createAllPayees]);

    useEffect(()=>{
        if(mountedRef.current && allPayees?.length >1){
            sortPayees(allPayees);
            dispatch(actions.setSplitPaymentPayees(allPayees));
        } 
    },[allPayees, dispatch]);

//#endregion useEffect


    //to pass to the typeahead to set the selected user
    const passUserSelection=(selection)=>{
        if(selection.length >0){
            dispatch(actions.selectCustomer(selection[0], props.register))
            dispatch(updateCart(props.register))
            setForUserCorrect(true);
            setForUser(selection[0])
        }
    }

    //passed to each of the payee assignments
    const assignPayeeDetails=(payee)=>{
        if(!payee.id) payee.id = GUEST_USER_ID;
        let temp = allPayees;
        for(let i =0; i <temp.length; i++){
            if(payee.payeeOrder === temp[i].payeeOrder){
                temp[i]=payee
            }
        }
        sortPayees(temp);
        if(mountedRef.current) setAllPayees(temp);
    };

    //because the payees are frequently looped and modified and reassembled, a function to keep them in the proper order for display purposes. 
    const sortPayees=async(payeeType)=>{
        if(payeeType && payeeType.length>0){
            payeeType.sort((a,b)=>{
                if(a.payeeOrder < b.payeeOrder) return -1
                if(a.payeeOrder > b.payeeOrder) return 1
                else return 0;
            });
        }
    }

  return (
    <>
        <fieldset disabled={fieldsetDisable}>
            <div>
                {props.type==="multiSingle" && (requiresUser || forUser?.is_guest===0) ?
                <>
                    <p>Is this the user the order is <span className="bold">for</span>?
                        <p>
                            <span className="bold">Name: {' '}</span>
                            <span>    
                                {forUser?.first_name} {forUser?.last_name}
                            </span>
                        <br />
                            <span className="bold">Username: {" "}</span>
                            <span>
                                {forUser?.username}
                            </span>
                        </p>
                    </p>
                    <p>
                        <span>
                            <Button 
                                type="button" 
                                onClick={()=>setForUserCorrect(true)} 
                                variant={forUserCorrect ? "secondary" : "primary"}
                                >
                                Yes
                            </Button>
                        </span>
                        <span>
                            <Button 
                                type="button" 
                                onClick={()=>setForUserCorrect(false)} 
                                variant={forUserCorrect ? "primary" : "secondary"} 
                                >
                                No
                            </Button>
                        </span>
                        {forUserCorrect && 
                            forUserCorrect ?
                                null
                            :
                                <div className="u-typeahead-wrapper">
                                    <NewUserTypeahead passSelection={passUserSelection} multiple={false} />
                                </div>
                        }
                    </p>
                </>
                :
                    null
                }
            </div>
            {forUserCorrect ? 
                <div>
                    <p>How many payees are splitting this order?</p>
                    <input type="number" defaultValue={numberOfPayees} onChange={(e)=>setNumberOfPayees(+e.target.value)}/>
                </div>
            :
                null
            }
            {numberOfPayees > 1 &&
                <>
                    {allPayees?.map((payee)=>(
                        <React.Fragment key={`payee-assignment-${payee.payeeOrder}`}>
                            <AssignPayees 
                                setFirstLoad={setFirstLoad} 
                                firstLoad={firstLoad} 
                                forUser={forUser} 
                                setForUser={setForUser}
                                payee={payee} 
                                assignPayeeDetails={assignPayeeDetails} 
                                type={props.type}
                            />
                        </React.Fragment>
                    ))}
                </>
            }
            <div className="sp-error">
                <br />
                <span>{spErrors?.numberOfPayees}</span>
                <span>{spErrors?.namePayees}</span>
            </div>
        </fieldset>
    </>
  )
}

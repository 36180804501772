import React from 'react';
import CryptoJS from 'crypto-js';
import {stringToUUID} from '../api/Common';
import store from '../redux-store';
import APICms from '../api/Cms';
import APIThemes from '../api/Themes';
import APIUsers from '../api/Users';

import blocks from '../containers/Cms/Builder/blocks';
import {default as _cssProperties, htmlProperties as _htmlProperties, logicProperties as _logicProperties} from '../containers/Cms/Builder/cssProperties';
import Components from '../containers/Cms/Components';


export const moveBuilderItems = (items, dragIndex, hoverIndex) => {
    const dragItem = items[dragIndex];
    if (dragItem) {
        const stateCopy = [...items];
        const prevItem = stateCopy.splice(hoverIndex, 1, dragItem);
        stateCopy.splice(dragIndex, 1, prevItem[0]);
        return stateCopy;
    }
    return items;
}

export const getPageById = async (props, cache=true) => {

    const condition=props;
    //if (typeof props==="string") condition={id:props}; // for backward compatibility, sowy :'(
    //else  condition=props;
    const hash=CryptoJS.MD5(JSON.stringify(condition)).toString();

    if (condition){
        let _pages={};
        // get from local storage cache first
        if (cache){
            _pages=JSON.parse(localStorage.getItem(`cms_blocks`)) || {};
            if (_pages[`b_${hash}`]) return _pages[`b_${hash}`];
        }

        const res=await APICms.pages.get(condition);
        if (res.data?.length){
            if (res.data?.[0]?.content) {
                // save to local storage cache
                _pages[`b_${hash}`]=res.data;
                localStorage.setItem(`cms_blocks`,JSON.stringify(_pages));
                return res.data;
            } else {
                // get from history if nothing published
                try{
                    const res2=await APICms.pages.history.get({id:"latest", page_id: res.data[0].id});
                    if (res2 && res2?.data?.length>0){
                        let _content;
                        //if (res2.data[0].content) _content = res2.data;
                        // we need to merge the page data with the history data because some needed fields are missing in the history data
                        _content = res2.data.map((item,i) => {
                            return {...item.page,...item};
                        });

                        // save to local storage cache
                        _pages[`b_${hash}`]=_content;
                        localStorage.setItem(`cms_blocks`,JSON.stringify(_pages));

                        return _content;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }
    return null;
}

export const setHeader = (header, items=null) => {
    let head = {};
    if (header) {
        if (header?.title) head.title = header.title;
        if (header?.description) head.description = header.description;
        if (header?.keywords) head.keywords = header.keywords;
        if (header?.page_type) head.page_type = header.page_type;
        if (header?.page_type_name) head.page_type_name = header.page_type_name;
        if (header?.config) head.config = header.config;
        if (header?.themes) head.themes = header.themes;
        if (header?.css) head.css = header.css;
        if (header?.js) head.js = header.js;
        if (header?.slug) head.slug = header.slug;
        if (header?.restricted_access) head.restricted_access = header.restricted_access;
        if (header?.redirect_to) head.redirect_to = header.redirect_to;
        if (header?.restricted_groups) head.restricted_groups = header.restricted_groups;
        if (header?.restricted_roles) head.restricted_roles = header.restricted_roles;
        if (header?.redirect_to) head.redirect_to = header.redirect_to;
        if (header?.page_id) head.page_id = header.page_id;
    }
    if (items) head = {...head, ...items};
    return head;
}

// get all the custom props from form items in the page to use in logic boxes
export const getFormCustomProps = async (items=[], pageFactor) => {
    if (!items.length){
        // get all items from local storage
        const _cms = JSON.parse(localStorage.getItem(`cms_${pageFactor}`)) || {};
        if (_cms?.elements) {
            items = await formatCMSFromJson(_cms.elements);
        }
    }

    let _items = [];

    items.forEach(item => {
        if ((item.element_id.includes("Form") || item.element_id.includes("WizardStep") || item.element_id.includes("Slide")) && item.element_id!=="FormBlock" && item.element_id!=="FormGroupOptions" && item.element_id!=="FormGroupButton"){
            let _props = {};
            if (item[item.element_id].props){

                // loop through the object and get the custom props
                Object.keys(item[item.element_id].props).forEach(key => {
                    if (key!=="css" && key!=="classes" && key!=="style" && key!=="logic"){
                        _props[key] = item[item.element_id].props[key];
                    }
                });

                // add the value for text, selects and textareas /* TO-DO: add checkbox/radio support */
                if (item.element_id==="FormGroupText" || item.element_id==="FormGroupSelect" || item.element_id==="FormGroupTextarea") {
                    _props.value = "";
                }

                if (Object.keys(_props).length){                
                    const pos = items.filter(a=>a.parent_id===item.parent_id).findIndex(a=>a.id===item.id);
                    _items.push({
                        id: item.id,
                        element_id: item.element_id,
                        display_name: `${item.element_id}${pos >= 0 ? `-${pos+1}` : ""}`,
                        properties: _props
                    });
                }
            }
        }
    });

    /*
    // removes hidden props that peeps are not supposed to see
    [..._cssProperties, ..._htmlProperties].filter(a=>a?.hidden).forEach(prop => {
        _items.forEach(item => {
           delete item.properties?.[prop.name];
        });
    });
    */

    return _items;
}

export const duplicateElementLogic = async (original_element) =>{
    if (!original_element) return null;

    let copy = JSON.parse(JSON.stringify(original_element));
    copy.id = `${copy.element_id}-${randomUUID()}`;
    copy[copy.element_id].id = copy.id;

    const updateIds = children => {
        if (children && children.length > 0){
            children.forEach(child => {
                const key = Object.keys(child);
                child[key[0]].id = `${key[0]}-${randomUUID()}`;
                //child.id = `${key[0]}-${randomUUID()}`;
                updateIds(child[key[0]]?.content);
            });
        }
    }
    updateIds(copy?.[copy.element_id]?.content);

    let _obj = await formatCMSFromJson([copy]);
    if (_obj && _obj.length){
        _obj[0].parent_id = original_element.parent_id;
    }

    return _obj;
}

// this one is used to programatically add elements to a container element. eg: adding columns to a row, or adding rows to a container, or adding steps to a wizard
export const subElementLogic = async (container_element, container_children = [], container_value_field = "custom-layout", subelement_type= "Layout", subelement_props=[], newIndex) =>{
    const _layout = container_element?.properties?.findIndex(p => p.name === container_value_field);
    let _children = JSON.parse(JSON.stringify(container_children || []));
    let added_elements = [], removed_elements = [];

    if (_layout>=0) {
        let _realvalue = container_element?.properties[_layout].value || 0;
        if (Array.isArray(_realvalue)) _realvalue = +_realvalue[0];
        
        //if (_children.length > _realvalue) _realvalue = _children.length;  // if it has more children than the value count, set the value to the children count
        if (_realvalue < 0) _realvalue = 0;  // if it's less than 0, set it to 0

        container_element.properties[_layout].value = _realvalue || "";
        container_element[container_element.element_id].props[container_value_field] = _realvalue || "";

        // get the element to add
        const _cl = cmsComponentList().filter(a=>a.component_name === subelement_type);

        if (_cl.length){
            let _props = {};
            _cl[0].props.forEach(p => _props[p.name]=p.value || "");

            let _obj = await formatCMSFromJson([{
                [_cl[0].component_name]:{
                    id: `${toKebabCase(_cl[0].component_name)}-${randomUUID()}`,
                    parent_id: container_element.id,
                    props: {..._props, ...subelement_props.reduce((a,b)=>({...a,[b.name]:b.value}),{})},
                    content: []
                }
            }]);

            if (_obj){
                // add the sub elements
                const _v = +_realvalue - +_children.length;
                if (_realvalue === 0){
                    // delete all children
                    removed_elements = [..._children];
                    _children=[];
                } else {
                    if (_v < 0){ // remove children
                        removed_elements = _children.splice(_v,Math.abs(_v));
                    } else { // add children
                        for (let j=0; j<_v; j++){
                            const props = JSON.parse(JSON.stringify(_obj[0].properties));
                            const obj={
                                ..._obj[0],
                                id: `${subelement_type}-${j+1}-${randomUUID()}`,
                                parent_id: container_element.id,
                                index: +(newIndex || container_element?.index || 0) + j,
                                properties: [...props].map(p => {
                                    if (subelement_props){
                                        subelement_props.forEach(prop => {
                                            if (p.name === prop.name){
                                                let _val = prop.value;
                                                if (_val && isNaN(_val)){
                                                    if (_val.includes("{counter}")) _val = _val.replace("{counter}",j+_children.length+1);
                                                }
                                                if (!isNaN(_val)) _val = +_val;
                                                p.value = _val;
                                            } 
                                        });
                                    }
                                    return p;
                                }),
                                content: [] //..._obj[0].content
                            }
                            added_elements.push(obj);
                        }
                    }
                }
            }
            container_element.content = [..._children, ...added_elements];
        }
    }

    if (!added_elements.length && !removed_elements.length) return null;
    return {element: container_element, added: added_elements, removed: removed_elements};
}

// create or update groups of input elements to local storage so we can send them to the server or use them in other places
export const localGroup = (element, id, prev = []) => {
    // go up the dom tree to find the wizard or form element
    let obj = element;
    let _content = {};
    let _checkboxes = [...prev];
    while (obj) {
        if (obj.id){
            const [element_type, _] = obj.id.split("-",1);
            if (element_type.toLowerCase() === "wizardstep" || element_type.toLowerCase() === "form"){
                
                let name = "_", value;                
                if (element?.type?.toLowerCase() === "checkbox" || element?.type?.toLowerCase() === "radio") { // check if the element is a checkbox or radio
                    if (element?.name){
                        if (element.name.substring(0,6)==="______"){
                            let _name=element.name.substring(6).split("-");
                            name = _name?.[0] || "_";
                        }
                    }
                    value = `${element.value}`.trim();

                    if (element.checked){
                        if (element?.type?.toLowerCase() === "checkbox"){
                            if (![..._checkboxes].includes(value)) _checkboxes.push(value);
                            value = _checkboxes;
                        }
                    } else {
                        if (element?.type?.toLowerCase() === "checkbox") value = [..._checkboxes].filter(a=>a!==value);
                        else value = undefined;
                    }
                } else {
                    name = element?.name || "_";
                    value = `${element.value}`.trim();
                }
                
                _content[obj.id] = {[id]: {[name]: value}};

                if (element_type.toLowerCase() === "form") break;
            }
            if (element_type.toLowerCase() === "wizard"){
                _content = {[obj.id]: _content};
                break;
            }
        }
        obj = obj.parentNode;
    }

    const key = Object.keys(_content)?.[0];
    if (key){
        let _ls = localStorage.getItem(`_G_${key}`);
        if (!_ls) _ls = _content[key];
        else {
            _ls = JSON.parse(_ls);
            const key2 = Object.keys(_content[key])[0];
            _ls[key2] = {..._ls[key2], ..._content[key][key2]}
        }
        localStorage.setItem(`_G_${key}`, JSON.stringify(_ls));
    }
}

// create an if statement from a logic box
const makeStatement = (items) => {
    let statement = items.map((item, i) => {
        let ret = "";
        if (item.type === 0){
            ret = `(${makeStatement(item.children)})`;
            //if (item.children[item.children.length-1].type===1){
                ret += ` ${item.children[item.children.length-1].andor} `;
            //}
        } else {
            if (item.variable){
                let value= item.value;
                if (isNaN(value)) value = "`"+value+"`";
                if (!value) value = "''";

                ret = `${item.variable} ${item.operator} ${value}`;
                if (i < items.length-1){
                    ret += ` ${item.andor} `;
                }
            }
        }
        return ret;
    }).join(``);

    statement = statement.replace(/\)\s*(AND|OR)\s*\)/g, '))');
    statement = statement.replace(/\s*(AND|OR)\s*$/g, '');
    return statement;            
}


const evaluateItem = (item, value) => {
    let _res = false;
    switch(item.operator){
        case "=":
            _res = value === item.value;
            break;
        case "!=":
            _res = value !== item.value;
            break;
        case ">":
            _res = value > item.value;
            break;
        case ">=":
            _res = value >= item.value;
            break;
        case "<":
            _res = value < item.value;
            break;
        case "<=":
            _res = value <= item.value;
            break;
        case "contains":
            _res = value.includes(item.value);
            break;
        case "not contains":
            _res = !value.includes(item.value);
            break;
        default:
            _res = false;
    }

    return _res;
}

// evaluate an statement from a logic box
export const checkStatement = (items, data) => {
    const _flatten = (tree, parent_id = null) => {
        const result = [];

        if (!Array.isArray(tree)){
            if (tree.content) tree = tree.content;
            else return result;
        }
      
        tree.forEach((node) => {
            const type = Object.keys(node)[0];
            const { props = {}, content = [] } = node[type];

            result.push({
                id: node[type].id,
                parent_id,
                ...props
            });
      
            result.push(..._flatten(content, node[type].id ));
        });
      
        return result;
    }

    const page = _flatten(data);
    let _items = [];
    let _has_listener = false;

    items.forEach(item => {
        // if its a group, recurse
        if (item.type === 0){
            _items.push({
                res: checkStatement(item.children),
                andor: item.children.filter(a=> a.type===1).map(a=> a.andor).pop()
            });
        } else {
            if (item.variable){

                // get the object and variable
                const [obj, variable] = item.variable.split(".");
                page.filter(a=> a.id === obj).forEach(a => {
                    let _res = false;
                    if (variable === "value"){

                        /*document.addEventListener("change", e => {
                            if (e.target.matches(`#${obj}`)) {
                                _res = evaluateItem(item, e.target.value);                                
                            }
                        });*/

                        _res = obj;
                        _has_listener = true;
                    } else _res = evaluateItem(item, a[variable]);
                    
                    _items.push(
                        {
                            res: _has_listener? false : _res,
                            listener: _has_listener ? _res : undefined,
                            andor: item.andor,
                            item: item,
                        }
                    )
                });
            }
        }
    });

    if (_has_listener) return _items;
    return evaluateStatement(_items);
}

const evaluateStatement = (items) => {
    let ret = false;

    if (!items.length) return ret;

    items.forEach((_item,i)=>{
        if (i===0) ret = _item.res;
        else {
            if (items[i-1].andor==="AND") ret = ret && _item.res;
            if (items[i-1].andor==="OR") ret = ret || _item.res;
        }
    });

    return ret;
}

export const formatCMSFromJson = async (cms_elements, headers={}) => {
    let new_elements=[];
    
    // check if the page type is a css or js so we return a special object
    if (+headers?.config?.is_code===1) {
        if (Array.isArray(cms_elements)) return cms_elements;
        return [cms_elements];
    }
    
    // if there's a theme included, inject the variables
    if (headers?.theme){
        injectSASSVars(headers.theme);
    }

    // if there's a link to a content block of type css, inject it
    if (headers?.css){
        const res=await getPageById({id: headers.css.id});
        if (res) injectCss(res?.[0]?.content, "preview");
    }

    // add components to the list of blocks, this list will be used to add elements to the page
    let cmp=[...blocks];
    cmsComponentList().forEach((component,i) => {
        let _props=[...JSON.parse(JSON.stringify(component.props))] || [];
        _props.forEach(prop => prop.changed = true); // mark all default props as changed so they get passed to the page even if empty
        
        if (component.apply_styles) _props=[...JSON.parse(JSON.stringify(_props)),...JSON.parse(JSON.stringify(_cssProperties))];

        cmp.push({
            id: `${component.name}`,
            name: component.name,
            component_name: component.component_name,
            class: toKebabCase(component.component_name),
            tooltip: component.tooltip,
            properties: [...JSON.parse(JSON.stringify(_props))],
            found: false,
            can_drop: component?.can_drop || false,
            apply_styles: component?.apply_styles!==undefined ? component.apply_styles : true,
        });
    });

    // converts the props object which has classes, styles and custom props, to a single name/value array
    const _propsToProperties= (props, element_id=null) => {
        let _newprops = [];

        if (props) {
            let _p=JSON.parse(JSON.stringify(props));

            if (!_p?.style) _p.style="";
            if (!_p?.classes) _p.classes="";
            if (!_p?.css) _p.css="";
            if (!_p?.logic) _p.logic={};
    
            Object.keys({..._p}).forEach(key => {
                if (_p[key]){
                    switch(key){
                        case "style":
                            _p[key].split(";").forEach(property => {
                                //let [name,value] = property.split(":",2);
                                const _pty = property.split(":");
                                let name = _pty.shift();
                                let value = _pty.join(':');
                                if (name && value){
                                    name=name.trim();
                                    value=value.trim();
                                    _newprops.push({name, value, changed: true});
                                }
                            });
                            break;
                        default:
                            _newprops.push({name: key, value: _p[key], changed: true}); // changed is true because we want to save the custom property
                            break;
                    }
                }
            });
            
            if (!_newprops.filter(a=>a.name==="classes").length) _newprops.push({name: "classes", value: "", changed: true});
            if (!_newprops.filter(a=>a.name==="logic").length) _newprops.push({name: "logic", value: {}, changed: true});
        }

        return JSON.parse(JSON.stringify(_newprops));
    }

    let _allpropsobj={};

    // recursively grab nested elements and adds them to the list of new_elements with all properties
    const _findElements = async (elements,parent_id=0,flag="") => {
        if (elements){
            for (let element of [...elements]){
                if (element){
                    let id = 0, elem, skip=false;
                    const key = Object.keys(element)[0];
                    const index = cmp.findIndex(cmp => cmp.component_name === `${key}` && !cmp.found);

                    if (index > -1) {
                        elem={...element}
                        elem.can_drop = cmp[index]?.can_drop || false;
                        elem.apply_styles = cmp[index]?.apply_styles!==undefined ? cmp[index]?.apply_styles : true;
                        elem.properties=[...JSON.parse(JSON.stringify(cmp[index]?.properties || [])) || []];
                    } else {
                        if (key==="contentBlock"){
                            // if its a content block, load the content and put it in the list
                            const res = await getPageById({id: element[key].id});
                            if (res){
                                /*let content=res?.[0]?.content;
                                if (Array.isArray(content)) content[0].display_name=res?.[0]?.title;
                                else {
                                    content.display_name=res?.[0]?.title;
                                    content=[{...content}];
                                }
                                elem=[...content];
                                */
                                if (Array.isArray(res?.[0]?.content)) elem=[...res?.[0]?.content];
                                else elem=[{...res?.[0]?.content}];

                                await _findElements(elem,parent_id,`contentBlock:${element[key].id}`);
                                skip=true; // we don't go any further because we've already execute the recursive function for the content of the content block
                            }
                        } else {
                            elem={...element}
                            elem.properties=[];
                        }
                    }

                    const eid=element?.[key]?.id || (`${key || elem.id }-${randomUUID()}`);

                    if (!skip){ // not dynamic content, carry on little soldier
                        let _newprops=JSON.parse(JSON.stringify(_propsToProperties(element[key]?.props, eid)));
                        if (_newprops.length>0) {
                            /**/
                            _newprops.forEach((newprop, i) => {
                                let _index = -1;
                                elem?.properties?.forEach((_prop, j) => {
                                    if (_prop.name === newprop.name){
                                        _index = j;
                                        _prop.value = newprop.value;
                                        _prop.changed = newprop?.changed || undefined;
                                    }
                                });
                                if (_index === -1) {
                                    let prop = {...newprop};
                                    prop.value = newprop.value;
                                    prop.changed = newprop?.changed || undefined;
                                    elem.properties.push(prop);
                                }
                                /*
                                if (_index > -1) {
                                    elem.properties[_index].value = newprop.value;
                                    elem.properties[_index].changed = newprop?.changed || undefined;
                                } else {
                                    let prop = {...newprop};
                                    prop.value = newprop.value;
                                    prop.changed = newprop?.changed || undefined;
                                    elem.properties.push(prop);
                                }
                                */
                            });
                            /**/
                            /*                            
                            let found=false;
                            elem?.properties?.forEach((prop,i) => {
                                const _index = _newprops.findIndex(_prop => _prop.name === prop.name);
                                if (_index > -1) {
                                    //prop={...prop,..._newprops[_index]};
                                    prop.value = _newprops[_index].value;
                                    prop.changed = _newprops[_index]?.changed || undefined;
                                    found=true;
                                }
                            });

                            if (!found) {
                                elem.properties=[...elem.properties,..._newprops];
                            }
                            */
                        }

                        _allpropsobj[eid]=JSON.parse(JSON.stringify(_cssProperties));

                        //console.log(key,_allpropsobj[eid])
                        
                        // if its a text element, add the text to the properties
                        if (elem[key]?.innerText){
                            let _htmlprops=JSON.parse(JSON.stringify(_htmlProperties));
                            _htmlprops.forEach((a,i)=>{
                                if (a.prop_type==="value") {
                                    _htmlprops[i].value=elem[key].innerText;
                                    _htmlprops[i].changed=true;
                                }
                            });
                            _allpropsobj[eid]=[...JSON.parse(JSON.stringify(_htmlprops)),..._allpropsobj[eid]];
                        }

                        if (elem[key]?.props?.logic){
                            let _logicprops=JSON.parse(JSON.stringify(_logicProperties));
                            _logicprops.forEach((a,i)=>{
                                _logicprops[i].value=elem[key]?.props.logic;
                                _logicprops[i].changed=true;
                            });
                            _allpropsobj[eid]=[...JSON.parse(JSON.stringify(_logicprops)),..._allpropsobj[eid]];
                        }

                        elem?.properties?.forEach((prop,i) => {
                            prop.id=i+700; // reset the id of the property to the index in the array to fit the new properties we merged
                            let _index = -1;
                            _allpropsobj[eid].forEach((_prop, j) => {
                                if (_prop.name === prop.name){
                                    _index = j;
                                    _prop.value = prop.value;
                                    _prop.changed = prop?.changed || undefined;
                                }
                            });
                            if (_index === -1) {
                                _allpropsobj[eid].splice(i,0,{...prop}); // insert at the i index in the _allprops array because these are custom properties, so its better to display them at the top in the order they are defined
                            }

                            /*
                            const _index = _allpropsobj[eid].findIndex(_prop => _prop.name === prop.name);
                            prop.id=i+700; // reset the id of the property to the index in the array to fit the new properties we merged
                            if (_index > -1) {
                                 if (!_allpropsobj[eid][_index].id) _allpropsobj[eid][_index].id = prop.id;
                                _allpropsobj[eid][_index].value = prop.value;
                                _allpropsobj[eid][_index].changed = prop?.changed || undefined;
                            } else {
                                _allpropsobj[eid].splice(i,0,{...prop}); // insert at the i index in the _allprops array because these are custom properties, so its better to display them at the top in the order they are defined
                            }
                            */
                        });

                        _allpropsobj[eid] = _allpropsobj[eid].filter(a=> a.changed);

                        let new_comp={...elem};
                        //console.log(new_comp, elem)
                        new_comp.element_id = key || elem.id.explode("-")[0];
                        new_comp.id = eid;
                        new_comp.parent_id = parent_id;
                        new_comp.found = true;
                        new_comp.can_drop = elem.can_drop || false;
                        new_comp.apply_styles = elem.apply_styles!==undefined ? elem.apply_styles : true;
                        new_comp.properties = [...JSON.parse(JSON.stringify(_allpropsobj[eid]))];
                        new_comp.flag = undefined;
                        if (flag) new_comp.flag = flag;
                        id = new_comp.id;
                        new_elements.push(new_comp);
                        delete _allpropsobj[eid];

                        if (element[key]?.content?.length>0) await _findElements(element[key].content,id,flag);
                    }
                }
            }
        }
    }

    await _findElements([...cms_elements]);

    return JSON.parse(JSON.stringify(new_elements));
}


// group elements by parent_id
export const groupElementsbyParentId = (array, group="parent_id", page_id="preview") => {
    
    const _group = (array, group) => {
        const new_array = [...array].reduce((r, a) => {
            if (a?.properties){
                // get classes property in its own variable
                const _classes = a.properties.filter(property => property.name==="classes").map(property => (property.value) ? `${property.value}` : null).join(" ");

                // get styles property in its own variable
                const _style = a.properties.filter(property => property.name==="style").map(property => property.value).join(";");

                // get css code
                const _css = a.properties.filter(property => property.name==="css").map(property => property.value).join(" ");

                // get non-css properties that have changed
                const _props = a.properties.filter(property => property.changed && property.name!=="value" && property.name!=="logic" && (!JSON.parse(JSON.stringify(_cssProperties)).map(cssProperty => cssProperty.name).includes(property.name))).reduce((obj, property) => {
                    obj[property.name] = property.value;
                    return obj;
                }, {}) || null;

                // get the non-css property that has name "value", we will set this to innerText
                const value = a.properties.filter(property => property.changed && property.name==="value").map(property => property.value).join(" ");

                // get the non-css property that has name "logic"
                const logic = a.properties.filter(property => property.changed && property.name==="logic").map(property => property.value)?.[0] || {};

                // get css properties that have changed
                const styles = a.properties.filter(property => property.changed && JSON.parse(JSON.stringify(_cssProperties)).map(cssProperty => cssProperty.name!=="classes" && cssProperty.name!=="css"?cssProperty.name:null).includes(property.name)).reduce((obj, property) => {
                    obj[property.name] = property.value;
                    return obj;
                }, {}) || {};

                const b={
                    id: a.id,
                    parent_id: a.parent_id,
                    name: a.name || a.element_id,
                    index: a.index || 0,
                    style: _style+Object.keys(styles).map(key => `${key}: ${styles[key]}`).join(";"),
                    classes: _classes.trim(),
                    css: _css,
                    logic: logic,
                    props: _props,
                }

                if (a.flag) b.flag = a.flag;
                if (value) b.innerText = value;
                else if (a[a.element_id]?.innerText) b.innerText = a[a.element_id].innerText;

                if (group){
                    if (a[group]) r[a[group]] = [...r[a[group]] || [], b];
                    else r.push(b);
                } else r.push(b);
            }
            return r;
        }, []);

        return new_array;
    }

    const _addChildren = (parent) => {
        if (elements[parent.id]){
            parent.children = elements[parent.id];
            parent.children.forEach(child => {
                _addChildren(child);
            });
        }
    }

    array = JSON.parse(JSON.stringify(array));

    const elements = _group(array, group);
    elements.forEach(element => {
        _addChildren(element);
    });

    Object.keys(elements).forEach(key => {
        if (isNaN(key)) delete elements[key];
    });

    return elements;
}

// format an array of elements to the format that the cms expects
export const formatJsonFromCMS = (elements) => {
    if (elements){
        const new_elements = elements.map(element => {
            let new_element = {};
            if (element.flag /*|| element.component_name==="ContentBlock"*/){ // elaborate on the commented contentblock thing later, so we can turn those items into contentBlocks:{id:x}
                // if its a flagged element (imported from another cms page), we store it as the object its coming from (usually a contentblock)
                const flag=element.flag.split(":");
                new_element = {
                    [flag[0]]: {
                        id: flag[1]
                    }
                }
            } else {
                // if its a regular item, we store it normally
                const eid=element.id || `${element.name}-${randomUUID()}`;
                let _props = {};
                if (Array.isArray(element.props)){
                    _props = [...element.props].reduce((obj, property) => {
                        obj[property.name] = property.value;
                        return obj;
                    }, {}) || {};
                } else _props = element.props;

                new_element = {
                    [element?.component_name || element.name]: {
                        id: eid,
                        props: {
                            /*id: eid,*/
                            style: element.style,
                            classes: element.classes,
                            css: element.css,
                            logic: element.logic,
                            ..._props
                        },
                    }
                }
                if (element.innerText) new_element[element.name].innerText = element.innerText;
                if (element?.children?.length) new_element[element.name].content = formatJsonFromCMS(element.children);
            }
            return new_element;
        });
        return new_elements;
    }
}     


export const cmsComponentList = () => {
    return Object.keys(Components).map(key => ({
        ...Components[key],
        //name: Components[key].name, 
        //component_name: Components[key].component_name, 
        tooltip: Components[key].tooltip || "", 
        //component: Components[key].component,
        props: Components[key].props || [],
        //apply_styles: Components[key].apply_styles || true,
        can_drop: Components[key]?.can_drop || false,
        list: Components[key].list || "Components",
    }));
}

export const randomUUID = () => {
    return stringToUUID(Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
}

export const toSlug = (string) => {
    if (!string) return string;    
    let str=string.replace(/[^a-zA-Z0-9 ]/g, "").replace(/ /g, "-").toLowerCase();
    if (!str) str=string;
    return str;
}

export const toCamelCase = (string) => {
    if (!string) return string;
    let str=string.replace(/-([a-z])/g, g => g[1].toUpperCase());
    if (!str) str=string;
    return str;
}

export const toKebabCase = (string) => {
    if (!string) return string;
    let str=string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    if (!str) str=string;
    return str;
}

export const toNormalCase = (string) => {
    if (!string) return string;
    
    // from camelCase to normal case
    let str=string.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
    if (!str) str=string;

    // from kebab-case to normal case
    str=str.replace(/-/g, ' ');
    if (!str) str=string;

    // capitalize first letter of each word
    str=str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    if (!str) str=string;

    // remove underscores and dollar signs
    str=str.replace(/[_$]/g, '');

    return str;
}

export const snapToGrid = (x, y) => {
    const snappedX = Math.round(x / 32) * 32;
    const snappedY = Math.round(y / 32) * 32;
    return [snappedX, snappedY];
}

export const resetSASSVars = () => {
    let storedTheme = JSON.parse(localStorage.getItem('theme'));
    if (storedTheme){
        const companyTheme=APIThemes.parse(storedTheme.content);
        if (companyTheme?.variables) injectSASSVars(companyTheme.variables);
    }
}

export const injectSASSVars = (vars) => {
    const _findVariable = (str) => {
        const regex = /\$[^\s,;)]+/g;
        let result = str.replace(regex, function(match) {
            const variableName = match;
            let variableValue = vars.filter(v2=>v2.name===variableName)?.[0]?.value;
            if (variableValue){
                if (variableValue.match(regex)) {
                    variableValue = _findVariable(variableValue);
                }
            } else variableValue=variableName;
            return variableValue;
        });
        return result;
    }

    // get the logo from the media library
    let company_logo = null;
    const state = store.getState();
    if (state?.theme?.logo) {
        company_logo = state?.theme?.logo; // for now it just gets the first logo it finds, should be fixed when theme editor is ready
        if (company_logo) company_logo = company_logo.replace(/['"]+/g, '').replace(/ /g, '');
    }

    let logoNoText,logoHeader,logo,backgroundImage,backgroundEvents,primaryColor,secondaryColor,tertiaryColor,backgroundColor,primaryFontFamily,secondaryFontFamily;
    if (vars){
        vars.forEach(v=>{

            // yikes
            if (v.name === "$logo-url") return;
            if (v.name === "$logo-filter" && company_logo && state?.company?.id!==1) return; // if the logo is set, and the company is not the default company, don't change the filter
            // end of yikes

            v.value=_findVariable(v.value);
            if (v.name==="$fonts"){
                let fonts = v.value.replace(/\(/g, '{').replace(/\)/g, '}').replace(/'([^']+)':/g, '"$1":').replace(/'([^']+)'/g, '"$1"');
                fonts = JSON.parse(fonts);
                // loop through fonts and inject them into the document
                Object.keys(fonts).forEach(k=>{
                    if (fonts[k]){
                        let link = document.getElementById(`font-${k}`);
                        if (link) link.remove();
                        let [font,weight] = fonts[k].split(":");
                        weight = weight.replace(/,/g, ';');
                        link = document.createElement('link');
                        link.id = `font-${k}`;
                        link.rel = "stylesheet";
                        link.href = `https://fonts.googleapis.com/css2?family=${font.replace(/^"(.*)"$/, '$1').replace(/'/g, '').replace(/ /g, '+')}:wght@${weight}&display=swap`;
                        document.head.appendChild(link);
                        if (k==="primary") primaryFontFamily=font;
                        if (k==="secondary") secondaryFontFamily=font;
                    }
                });
            } else {
                let val = v.value;
                if (v.name==="$logoNoText") {
                    if (company_logo) val = company_logo;
                    logoNoText=val;
                    document.documentElement.style.setProperty(`--${v.name.substr(1)}-url`, `url(${val})`);
                }
                if (v.name==="$logoHeader") {
                    if (company_logo) val = company_logo;
                    logoHeader=val;
                    document.documentElement.style.setProperty(`--${v.name.substr(1)}-url`, `url(${val})`);
                }
                if (v.name==="$logo") {
                    if (company_logo) val = company_logo;
                    logo=val;
                    document.documentElement.style.setProperty(`--${v.name.substr(1)}-url`, `url(${val})`);
                }
                if (v.name==="$background-image") {
                    backgroundImage=val;
                    document.documentElement.style.setProperty(`--${v.name.substr(1)}-url`, `url(${val})`);
                }
                if (v.name==="$backgroundEvents") {
                    backgroundEvents=val;
                    document.documentElement.style.setProperty(`--${v.name.substr(1)}-url`, `url(${val})`);
                }
                if (v.name==="$background-color") backgroundColor=val;
                if (v.name==="$primary-color") primaryColor=val;
                if (v.name==="$secondary-color") secondaryColor=val;
                if (v.name==="$tertiary-color") tertiaryColor=val;

                document.documentElement.style.setProperty(`--${v.name.substr(1)}`, val);

                
            }
        });
    }
    return {logoNoText,logoHeader,logo,backgroundImage,backgroundEvents,primaryColor,secondaryColor,tertiaryColor,backgroundColor,primaryFontFamily,secondaryFontFamily};
}

export const injectCss= (css, id=null) => {
    let css_id="_"+(id || randomUUID());
    if (css) {
        // check if a style with the same id already exists
        let css_style=document.getElementById(`css${css_id}`);
        if (css_style) css_style.innerHTML=_cssFormat(css, css_id);
        else {
            css_style=document.createElement('style');
            css_style.id=`css${css_id}`;
            css_style.innerHTML=_cssFormat(css, css_id);
            document.head.appendChild(css_style);
        }
    } else {
        // remove the style tag with the given id
        let css_style=document.getElementById(`css${css_id}`);
        if (css_style) css_style.remove();
    }
    return css_id;
}

const _cssFormat = (css, new_id) => {
    new_id = ""; // lets remove that nonsense
    if (css){
        css=css.replace(/\.(-?[_a-zA-Z]+[_a-zA-Z0-9-]*)(?![^{]*\})/g, `.$1${new_id}`); // append the new_id to every css class
        // append !important to every css property value if its not already there
        css=css.replace(/:([^;]*)(;|$)/g, (match, p1, p2) => {
            if (p1.indexOf("!important")===-1 && p1.substring(0,2)!=="//") return `:${p1} !important${p2}`;
            else return match;
        });
        //css=css.replace(/:([^;]+);/g, ":$1 !important;");
        return css;
    }
    return null;
}

export const removeCss = (id) => {
    if (id==="all"){
        // remove all style tags that have an id
        let css_styles=document.querySelectorAll('style[id]');
        css_styles.forEach(css_style=>css_style.remove());
    } else {
        if (Array.isArray(id)) id.forEach(i=>removeCss(i));
        else {
            let css_style=document.getElementById(id);
            if (css_style) css_style.remove();
        }
    }
}

export const emptyPage = (config) => {
    // Code has no empty page, its just... code...
    if (+config?.is_code===1) return "";

    // Wizard default empty page
    if (+config?.is_wizard===1){
        return {
            Wizard: {id: `Wizard-${randomUUID()}`, steps: 1, props: {style: "display:block;", wizard_buttons: "Next & Previous", "custom-steps": 1}, content: [{
                WizardStep: {id: `WizardStep-1-${randomUUID()}`, props: {step_number: 1, step_title: "Step 1"}}
            }]}
        };
    } else {
        // Form default empty page
        if (+config?.is_form===1) return {Form: {id: `Form-${randomUUID()}`, props: {style: "display:block;"}, content: []}};    
    }
    
    // Everything else's default empty page
    return {div: {id: "div-dustbin", props: {style: "display:block;"}, content: []}};
}

export const preparePropForElement = (prop, _props) => {    
    if (prop.value){
        //prop.value = prop.value.trim();
        switch(true){
            case prop.name==="css":
                _props.css=prop.value;
                break;
            case prop.name==="classes":
                _props.className+=" "+prop.value;
                break;
            case prop.name==="d":
            case prop.name==="viewBox":
            case prop.name==="fill":
            case prop.name==="stroke":
            case prop.name==="strokeWidth":
            case prop.name==="strokeLinecap":
            case prop.name==="strokeLinejoin":
            case prop.name==="strokeDasharray":
            case prop.name==="strokeDashoffset":
            case prop.name==="transform":
            case prop.name==="x":
            case prop.name==="y":
            case prop.name==="width":
            case prop.name==="height":
            case prop.name==="xmlns":
            case prop.name==="xmlnsXlink":
            case prop.name==="xlinkHref":
            case prop.name==="preserveAspectRatio":
            case prop.name==="version":
            case prop.name==="enableBackground":
            case prop.name==="xmlSpace":
            case prop.name==="xmlLang":
            case prop.name==="xmlBase":
            case prop.name==="xmlNs":
            case prop.name==="xmlNsXlink":
            case prop.name==="xmlNsSvg":
            case prop.name==="src":
            case prop.name==="alt":
            case prop.name==="title":
            case prop.name==="loading":
            case prop.name==="decoding":
            case prop.name==="crossOrigin":
            case prop.name==="useMap":
            case prop.name==="isMap":
            case prop.name==="srcSet":
            case prop.name==="sizes":
            case prop.name==="href":
            case prop.name==="target":
            case prop.name==="download":
            case prop.name==="rel":
            case prop.name==="hrefLang":
            case prop.name==="type":
            case prop.name==="media":
            case prop.name==="referrerPolicy":
            case prop.name==="ping":
            case prop.name==="shape":
            case prop.name==="coords":
            case prop.name==="placeholder":
            case prop.name==="disabled":
            case prop.name==="readOnly":
            case prop.name==="required":
            case prop.name==="minLength":
            case prop.name==="maxLength":
            case prop.name==="min":
            case prop.name==="max":
            case prop.name==="step":
            case prop.name==="pattern":
            case prop.name==="size":
            case prop.name==="autoComplete":
            case prop.name==="autoFocus":
            case prop.name==="multiple":
            case prop.name==="form":
            case prop.name==="formAction":
            case prop.name==="formEncType":
            case prop.name==="formMethod":
            case prop.name==="formNoValidate":
            case prop.name==="formTarget":
            case prop.name==="inputMode":
            case prop.name==="accept":
            case prop.name==="capture":
            case prop.name==="checked":
            case prop.name==="defaultChecked":
            case prop.name==="rows":
            case prop.name==="cols":
            case prop.name==="wrap":
            case prop.name==="dirName":
            case prop.name==="selectionDirection":
            case prop.name==="selectionStart":
            case prop.name==="selectionEnd":
            case prop.name==="spellCheck":
            case prop.name==="label":
            case prop.name==="selected":
            case prop.name==="acceptCharset":
            case prop.name==="action":
            case prop.name==="encType":
            case prop.name==="method":
            case prop.name==="noValidate":
            case prop.name==="htmlFor":
            case prop.name==="accessKey":
            case prop.name==="autoPlay":
            case prop.name==="controls":
            case prop.name==="loop":
            case prop.name==="muted":
            case prop.name==="preload":
            case prop.name==="playbackRate":
            case prop.name==="defaultMuted":
            case prop.name==="volume":
            case prop.name==="currentTime":
            case prop.name==="buffered":
            case prop.name==="seeking":
            case prop.name==="seekable":
            case prop.name==="ended":
            case prop.name==="autoplay":
            case prop.name==="poster":
            case prop.name==="playsInline":
            case prop.name==="srcDoc":
            case prop.name==="sandbox":
            case prop.name==="seamless":
            case prop.name==="allowFullscreen":
            case prop.name==="allowPaymentRequest":
            case prop.name==="allow":
            case prop.name==="allowUserMedia":
            case prop.name==="allowPopupsToEscapeSandbox":                
                _props[prop.name]=prop.value;
                break;
            default:
                if (JSON.parse(JSON.stringify(_cssProperties)).filter(a=>a.name===prop.name).length) _props.style[toCamelCase(prop.name)]=prop.value;
                else _props[prop.name]=prop.value;
                break;
        }
    }
    return _props;
}

// flatten the json page format to a simple parent child array
export const flatten = (arr, parent_id = null) => {
    return arr.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        const value = item[key];
        const id = value.id || randomUUID();
        const content = value.content ? flatten(value.content, id) : null;
        acc.push({ id, name: key, description: key, parent_id, children: content });
        return acc;
    }, []);
}

// convert a value to a boolean
export const checkBoolean = (value) => {
    let _value = value;
    if (typeof value === "string"){
        if (isNaN(value)) _value = value === "on" ? true : false;
        else _value = +value ? true : false;
    } else if (typeof value === "number"){
        _value = value ? true : false;
    }   
    return _value;
}

// create a hash for a given data object
export const generateHash = (data) => {
    const salt = 'sITeB0zzs%UltR@-53CrE7-(sA1t*aNd*pePPaH)!';
    const hash = CryptoJS.SHA256(salt + JSON.stringify(data));
    return hash.toString(CryptoJS.enc.Hex);
}


/*
TEMP DOC:
---------
-props.page_id: random uuid for the page
-props.company_context: company context from the FileURLContext
-props.children: inner html for the component if present
-props.style: inline styles for the component if set in the definition
-props.clasName: css classes only for the component if set in the definition

When assigning the css classes for each component, the page id will be appended to every class name, 
so in order to reference them from within the component you must add the class name + page id, like this:
    <Row className={`row_${props.page_id}`}>
        <Col className={`col col_${props.page_id}`}/>
    </Row>

You can use {page_id} in the definition under "css" to reference the page id, like this: 
    {
        "div":{
            "props":{
                "classes":"my-custom-class_{page_id}",
                "css":".my-custom-class{background-color: red;}"
            }
        }
    }
*/

export const parseCmsComponent = async (data, company_context, props={}) => {
    let ojson = typeof data==="string" ? JSON.parse(data) : data;
    const page_id=props.page_id || stringToUUID(Math.random());
    let promises=[];
    let scripts=[];

    if (props?.css_ids?.length){
        let _css="";
        for (const c of props.css_ids){
            const res=await getPageById({id:c});
            if (res?.[0]?.content){
                _css += res[0].content;
            }
        }
        if (_css) injectCss(_css, page_id);
    }

    const readData = (obj, ele=[]) => {
        if (obj){
            if (Array.isArray(obj)){
                obj.forEach(o=>{
                    ele.push(loopObject(o));
                })
            } else ele=loopObject(obj);
            return ele;
        }
        return null;
    }

    const _getContentBlock = async (id) => {
        const res = await getPageById({id:id});
        if (res?.[0]?.content){
            let cb=res[0].content;
            if (typeof cb==="string") cb=JSON.parse(cb);
            return cb;
        }
        return null;
    }


    /* parse html into our special json format */
    const _htmlParser = (html) => {
        html=`<div>${html}</div>`;

        let parser = new DOMParser();
        let doc = parser.parseFromString(html, "text/html");
        let body = doc.body;
        let json = [];

        const _loop = (node, json) => {
            if (node.tagName && node.tagName.toLowerCase()==="script"){ // check if the node is a script tag
                scripts.push(node.textContent);
            } else {
                if (node.nodeType===3){
                    json.push({
                        span:{
                            innerText:node.textContent
                        }
                    });
                } else {
                    let new_ele= {
                        [node.tagName.toLowerCase()]:{}
                    };
                    if (node.attributes.length>0){
                        new_ele[node.tagName.toLowerCase()].props={};
                        for (let i=0; i<node.attributes.length; i++){
                            let attr = node.attributes[i], attr_name;
                            if (attr.name==="class" || attr.name==="className") attr_name="classes";
                            else attr_name=attr.name;
                            new_ele[node.tagName.toLowerCase()].props[attr_name]=attr.value;
                        }
                    }
                    if (node.childNodes.length>0){
                        new_ele[node.tagName.toLowerCase()].content=[];
                        for (let i=0; i<node.childNodes.length; i++){
                            let child = node.childNodes[i];
                            _loop(child, new_ele[node.tagName.toLowerCase()].content);
                        }
                    }
                    json.push(new_ele);
                }
            }
        }

        _loop(body.childNodes[0], json);

        if (json?.[0]?.div?.content) json=json[0].div.content;

        return json;
    }

    const loopObject = async (obj) => {
        let ele, logic_promises = [];
        for (let [key, value] of Object.entries(obj)) {
            if (key==="contentBlock" && value?.id){
                let cbobj = await _getContentBlock(value.id);
                if (cbobj){
                    if (Array.isArray(cbobj)) cbobj={div:{content:cbobj}} // group the items in a div if theres more than one
                    return readData(cbobj);
                } else return null;
            }
            
            let logic_action;
            let aprops={key:stringToUUID(Math.random())};
            if (value?.id) aprops.id=value.id;
            if (value.props){

                // formats commom props for React.createElement
                let classes=[], style={}, css="", logic="";
                
                value.props.page_id=page_id;

                if (value.props?.classes) classes=value.props.classes.split(/;|\s/);

                if (value.props?.css) css=value.props.css;
                if (value.props?.logic){
                    if (value.props.logic?.condition && !props.id.includes("preview")){
                        logic = checkStatement(value.props.logic.condition, ojson);

                        if (Array.isArray(logic)){ // if the logic is an array, it means that there's an event listener in the condition
                            logic_action = ["wait", value.props.logic.onTrue, value.props.logic.onFalse, logic];
                        } else {
                            if (logic===true) logic_action = value.props.logic.onTrue; // a string like "show" or "hide" or "disable"
                            else if (logic===false) logic_action = value.props.logic.onFalse;
                        }
                    }
                }

                // format inline styles
                if (value.props.style) {
                    if (typeof value.props.style === "object") style=value.props.style;
                    else {
                        let astyles=value.props.style.split(';');
                        if (astyles.length>0){
                            astyles.forEach(s=>{
                                if (s){
                                    const astyle = s.split(":");
                                    let _name = astyle.shift();
                                    let _value = astyle.join(':');
                                    if (astyle.length>0){
                                        _value = _value.trim();
                                        _name = _name.trim();
                                        if ((_name === "background-image" || _name === "list-style-image" || _name === "border-image" || _name === "mask-image" 
                                                || _name === "mask-box-image" || _name === "content") && _value.indexOf("url(") === -1 && _value.indexOf("http")>=0){
                                            _value = `url(${_value})`;
                                        } 
                                        style = {...style,[toCamelCase(_name.trim())]: _value};
                                    }
                                }
                            });
                        }
                    }
                }
                if (classes) {
                    //classes=classes.map(c=>c.replace(/{page_id}/g,page_id));
                    value.props.className=classes.map(a=>a ? `${a} ${a}_${page_id}` : null).join(' ').trim();
                    delete value.props.classes;
                }
                //if (css) value.props.css=css;
                if (style) value.props.style=style; 

                // inject css into the head of the document
                if (css) {
                    let css_id = injectCss(css);
                    value.props.page_id=css_id;
                    aprops.css_id=css_id; // keep track of the css id so we can do what we need to do later
                }
                
                // inject company context into the props if the element is a component
                if (company_context && key[0]===key[0].toUpperCase()) {
                    value.props.company_context=company_context;
                }

                aprops={...aprops, ...value.props};
            }

            //aprops={...aprops, ...props};
            aprops={...props, ...aprops};
            if (props.id) aprops.id=props.id; // override the id if it was passed in, for example as a query param

            // if logic_action is an array, it means that we need to evaluate the condition again since it may depend on a listener
            if (Array.isArray(logic_action)){
                if (logic_action[0]==="wait"){

                    // we use this lil guy here to find the element inside nested objects, which are grouped elements like forms with its fields or wizards with its steps and fields
                    const _findItem = (ele, obj) => {
                        if (obj[ele]) return obj[ele];
                        else {
                            const _keys = Object.keys(obj);
                            for (let i=0; i<_keys.length; i++){
                                if (typeof obj[_keys[i]] === "object"){
                                    const _res = _findItem(ele, obj[_keys[i]]);
                                    if (_res) return _res;
                                }
                            }
                        }
                    }

                    const _logicp = (aprops, logic_action) => {
                        return new Promise(resolve => {
                            const observer = new MutationObserver(mutations => {
                                const element = document.querySelector(`#${aprops.id}`);
                                if (element) {
                                    observer.disconnect();

                                    document.addEventListener("change", e => {
                                        logic_action[3]?.forEach((_item, i) =>{
                                            if (_item.listener){
                                                const objs = Object.keys(localStorage).filter(k=>k.startsWith("_G_")); // these are the grouped elements in localstorage
                                                if (objs.length>0){
                                                    objs.forEach(o=>{
                                                        let _obj = localStorage.getItem(o);
                                                        if (_obj) {
                                                            _obj = JSON.parse(_obj);
                                                            const _item_res = _findItem(_item.listener, _obj);
                                                            if (_item_res) {
                                                                const _key = Object.keys(_item_res)[0];
                                                                _item.res = evaluateItem(_item.item, _item_res[_key]);
                                                                logic_action[3][i] = _item;
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        });
                                        
                                        logic_action[3]?.forEach((_item, i) =>{
                                            if (_item.listener){
                                                if (e.target.matches(`#${_item.listener}`)) {
                                                    const _logic_result = evaluateStatement(logic_action[3]);
                                                    let res;
                                                    if (_logic_result === true) res = logic_action[1];
                                                    else if (_logic_result === false) res = logic_action[2];

                                                    switch (res){
                                                        case "show":
                                                            element.classList.remove("d-none");
                                                            break;
                                                        case "hide":
                                                            element.classList.add("d-none");
                                                            break;
                                                        case "disable":
                                                            element.disabled=true;
                                                            break;
                                                        case "enable":
                                                            element.disabled=false;
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                    resolve(element);
                                                }
                                            }
                                        });
                                    });
                                }
                            });
                            observer.observe(document.documentElement, { childList: true, subtree: true });
                        });
                    }

                    const logicp = _logicp(aprops, logic_action);
                    logic_promises.push(logicp);
                    logic_action = false;
                    aprops = {...aprops, className: `${aprops.className} d-none`.trim()}
                }
            }

            if (!logic_action || logic_action === "show"){
                ele = new Promise((resolve, reject) => {
                    let content = value?.innerText || readData(value?.content);

                    if (typeof content==="string"){
                        content=content.replace(/(?:\r\n|\r|\n)/g, '<br />');
                        if (/<[a-z][\s\S]*>/i.test(content)){
                            const j=_htmlParser(content);
                            if (j) content=readData(j);
                        }
                    }

                    if (content instanceof Promise){
                        content.then(res=>{
                            resolve(React.createElement(Components?.[key]?.component || key || "div", {...aprops}, res));
                        });
                    } else if (Array.isArray(content)){
                        Promise.all(content).then(res=>{
                            resolve(React.createElement(Components?.[key]?.component || key || "div", {...aprops}, res));
                        });
                    } else{
                        resolve(React.createElement(Components?.[key]?.component || key || "div", {...aprops}, content));
                    } 
                });

                if (logic_promises.length>0) ele.then(res=> Promise.all(logic_promises));
                promises.push(ele);
            }
        }

        return ele;
    }
    
    /*
    if (ojson?.css?.id){
        const res = await getPageById({id:ojson.css.id,page_type_id:7});
        if (res){
            const css=res[0].content;
            if (css){
                injectCss(css, page_id);
            }
        }
    }
    */

    if (ojson?.js?.id){
        const res = await getPageById({id:ojson.js.id,page_type_id:8});
        if (res){
            let js=res[0].content;
            if (js){
                js=js.replace(/<script>/g,'');
                scripts.push({id: res[0].id, content: js});
            }
        }
    }

    let html;
    if (ojson?.content) html = readData(ojson.content);
    else html = readData(ojson);
    
    if (!Array.isArray(html)) html=[html];
    html = await Promise.all(html);

    if (scripts.length>0){
        scripts.forEach(s=>{
            let script = document.getElementById(`js_${s.id}`);
            if (script) script.remove();
            script=document.createElement('script');
            script.id=`js_${s.id}`;
            script.innerHTML=s.content;
            document.body.appendChild(script);
        });
    }

    return html;
}

export const sassToJson = (sass_obj) => {
    let json={variables:[]};

    Object.keys(sass_obj).forEach(k=>{
        let v=sass_obj[k];
        let compiledValue=v;
        let a=v.split(' ');
        if (a.length>1){
            a.forEach((v,i)=>{
                if (sass_obj[v]){
                    compiledValue=compiledValue.replace(v,sass_obj[v]);

                    if (compiledValue.indexOf('+')>-1 || compiledValue.indexOf('-')>-1 || compiledValue.indexOf('*')>-1 || compiledValue.indexOf('/')>-1){
                        
                        let operator=compiledValue.match(/[+\-*/]/);
                        if (operator) operator=operator[0];

                        let b=compiledValue.split(/[+\-*/]/);
                        if (b.length>1){
                            b=b.map(v=>v.trim().replace(/['"]+/g, ''));
                    
                            if (b.some(v=>isNaN(v))){
                                compiledValue=b.join(operator==='+'?'':operator);
                            } else{
                                compiledValue=b.reduce((a, b) =>{
                                    if (operator==='+') return a + b;
                                    if (operator==='-') return a - b;
                                    if (operator==='*') return a * b;
                                    if (operator==='/') return a / b;
                                    return a;
                                });
                            }
                        }
                    }
                }
            });
        }
        json.variables.push({name:k,value:v,compiledValue});
    });

    return json;
}

export function formatFileSize(bytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let size = bytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }

    return size.toFixed(2) + units[unitIndex];
}
import React from 'react';
import {format} from 'date-fns';

import styles from '../../FullPageReceipt.module.scss';

/**Needs the following props 
 * @param order (needs order id, and price values)
*/
export const PaymentHistory = (props) => {
    const is_ticket = props?.format === "ticket";    
    if (props.order?.transactions?.length <= 1 && !props.order?.split_orders?.length) return null;    

    return (
        <div className={`${styles["flex-wrapper-items"]} ${is_ticket ? "w-100 mt-2" : "mt-4"}`}>
            {!is_ticket &&
                <>
                    <h2 className={styles["fp-larger"]}>Payment History</h2>
                    <div className={styles["fp-item-headers"]} style={{marginTop:0}}>
                        <span style={{width:"75px"}}>#</span>
                        <span style={{width:"100px"}}>Date</span>
                        <span className={styles["main-item"]}>Payment Type</span>
                        <span className={styles.amount}>Amount</span>
                    </div>
                </>
            }
            <div className={`${styles["fp-items"]}`}>
                {is_ticket &&
                    <div className={styles["item-wrapper"]}>
                        <div className={`${styles.bold} ${styles["item-map-ticket"]}`}>Payment History:</div>
                    </div>
                }
                {props.order?.transactions?.filter(a=>+a.transaction_status_id!==8)?.map((transaction, i)=>(
                    <div className={styles["item-wrapper"]} key={`rec-table-payment-${i}`}>
                        <div className={`${is_ticket ? styles["item-map-ticket"] : styles["item-map"]}`}>
                            <span style={{width: /*is_ticket? "100%" :*/"75px"}} className={is_ticket ? "text-center" : undefined}>
                                {/*is_ticket && "Transaction #"*/}{transaction.id}
                            </span>
                            <span style={{width: /*is_ticket? "100%" :*/"100px"}} className={is_ticket ? "text-center" : undefined}>{format(new Date(transaction.date), "MM-dd-yyy")}</span>
                            <span className={`${styles["item-name"]} ${is_ticket ? "w-100" : ""}`}>
                                {+transaction.transaction_payment_method_id === 1 
                                    ?
                                        <>
                                            {transaction.cc_type && transaction.cc_number 
                                                ? `${transaction.cc_type} ending in ${transaction.cc_number}. `
                                                : transaction.transaction_payment_method_name
                                            }
                                            {transaction.transaction_status_id === 8 && ` (${transaction.transaction_status_name})`}
                                        </>
                                    : transaction.transaction_payment_method_name
                                }
                                {+transaction.transaction_payment_method_id === 3 &&
                                    <>
                                        {transaction.transaction_response?.check_number && ` #${transaction.transaction_response.check_number}`}
                                        {transaction.transaction_response?.check_name && 
                                            <>
                                                {is_ticket ? <br/> : ` / `}
                                                {transaction.transaction_response.check_name}
                                            </>
                                        }
                                    </>
                                } 
                                {+transaction.transaction_payment_method_id === 2 &&
                                    <>
                                        {is_ticket && <br/>}
                                        {` `}
                                        {transaction.transaction_response?.amount && `Tendered: $${+transaction.transaction_response.amount.toFixed(2)}`}
                                        {transaction.transaction_response?.change && ` / Change: $${+transaction.transaction_response.change.toFixed(2)}`}
                                        {``}
                                    </>
                                }
                            </span>
                            <span className={/*is_ticket? "w-100 text-center" :*/ styles.amount}>
                                {/*is_ticket && "Transaction Amount: "*/}
                                {transaction.transaction_status_id !== 7 && ` (${transaction.transaction_status_name}) `}
                                ${transaction.amount.toFixed(2)}
                            </span>
                            {/*is_ticket && <hr className={styles.divider}/>*/}
                        </div>
                    </div>
                ))}
                {props.order?.split_orders?.length > 0 && props.order?.split_orders.map((splitOrder, j)=>(
                    <>
                        {splitOrder?.transactions?.filter(a=>+a.transaction_status_id!==8)?.map((transaction, i)=>(
                            <div className={styles["item-wrapper"]} key={`rec-table-payment-${j}-${i}`}>
                                <div className={styles["item-map"]}>
                                    <span style={{width:"75px"}}>{transaction.id}</span>
                                    <span style={{width:"100px"}}>{format(new Date(transaction.date), "MM-dd-yyy")}</span>
                                    <span className={styles["item-name"]}>
                                        {+transaction.transaction_payment_method_id === 1
                                            ?
                                                <>
                                                    {transaction.cc_type && transaction.cc_number 
                                                        ? `${transaction.cc_type} ending in ${transaction.cc_number}. `
                                                        : transaction.transaction_payment_method_name
                                                    }
                                                    {transaction.transaction_status_id === 8 && ` (${transaction.transaction_status_name})`}
                                                </>
                                            : transaction.transaction_payment_method_name
                                        }
                                        {+transaction.transaction_payment_method_id === 3 &&
                                            <>
                                                {transaction.transaction_response?.check_number && ` #${transaction.transaction_response.check_number}`}
                                                {transaction.transaction_response?.check_name && ` / ${transaction.transaction_response.check_name}`}
                                            </>
                                        } 
                                        {+transaction.transaction_payment_method_id === 2 &&
                                            <>
                                                {`(`}
                                                {transaction.transaction_response?.amount && `Tendered: $${transaction.transaction_response.amount.toFixed(2)}`}
                                                {transaction.transaction_response?.change && ` / Change: $${transaction.transaction_response.change.toFixed(2)}`}
                                                {`)`}
                                            </>
                                        }
                                        <br/>
                                        Paid by {splitOrder.user.first_name} {splitOrder.user.last_name} ({splitOrder.user.username}). Order #{splitOrder.id}
                                    </span>
                                    <span className={styles.amount}>${transaction.amount.toFixed(2)}</span>
                                </div>
                            </div>
                        ))}
                    </>
                ))}
            </div>
        </div>
    );
}
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import * as actions from '../../../../../../store/actions';
import {Coupons as CouponPicker} from '../../../../Preview/Coupons'
import SplitPaymentCC from '../../PaymentMethods/SplitPaymentCC';
import SplitPaymentCash from '../../PaymentMethods/SplitPaymentCash';
import { refreshOrder } from '../../../../../../utils/thunks';
import '../../../SplitPayment/splitPayment.scss';

export const ProcessEachPayment = (props) => {
  
  // destructured props
  const {goTo,partialPaid,onClose,register,type,terminalDeviceId}=props;

  const dispatch=useDispatch();
  const mountedRef=useRef(false);
  const reduxOrder = useSelector(state=>state.pos[props.register].currentOrder);
  const payees = useSelector(state=>state.splitPayments.payees);
  const [success, setSuccess]=useState("");
  const [currentOrder, setCurrentOrder]=useState();
  const [activePayee, setActivePayee]=useState();
  const [paymentType, setPaymentType]=useState();
  const [allPaid, setAllPaid]=useState(false);
  const [couponModalShow, setCouponModalShow]=useState(false);

//#region useCallback
  const changeToNextPayee=useCallback(()=>{
    if(activePayee?.paid){
      payees.forEach((payee)=>{
        if(payee.paid===false) {
          setActivePayee(payee);
          setPaymentType();
        }
      })
    }
  },[activePayee, payees]);

  const checkAllPaid=useCallback(()=>{
    let totalPayees = payees.length;
    let paid = 0;
    payees.forEach((payee)=>{
      if(payee.paid) paid++
    })
    if(totalPayees === paid){
      setAllPaid(true);
      goTo('Confirmation');
    }
    else {
      changeToNextPayee()
    }
  },[payees,goTo,changeToNextPayee]);
//#endregion useCallback

//region useEffect
  useEffect(()=>{
    mountedRef.current = true

    return()=>{
      mountedRef.current = false
    }
  },[])

  useEffect(()=>{
    if(mountedRef.current) checkAllPaid()
  },[checkAllPaid]);

  useEffect(()=>{
    if(allPaid) {
      setSuccess("All patrons in this split payment have had successful transactions.")
      dispatch(actions.setSplitPaymentAllPaid("Yes"));
    }
  },[allPaid, dispatch]);

  useEffect(()=>{
    if(activePayee && activePayee.hasOwnProperty("order")) setCurrentOrder(activePayee.order);
    else setCurrentOrder(reduxOrder);
  },[activePayee, reduxOrder]);
//#endregion useEffect

  const adjustPayeeStatus=async(paymentStatus, type, payee, transactionId, cashResponse)=>{
    let temp=[];
    payees.forEach((person)=>{
      if(person.payeeOrder===payee.payeeOrder){
        person.order = payee.order;
        person.paid = true;
        person.paymentStatus = paymentStatus;
        person.paymentType = type;
        person.transactionId = transactionId;
        person.cash = cashResponse;
        temp.push(person)
        setActivePayee(person)
      }else temp.push(person)
    })
    dispatch(actions.setSplitPaymentPayees(temp));
  }

  return (
    <>
      <div>
        {payees?.map((payee)=>(
          <div key={`select-spmusi-${payee?.payeeOrder}`}>
            <p>
              {activePayee?.identifier === payee?.identifier ? <i className="far fa-chevron-double-right text-company-primary" /> : null}
              {" "}
              <span>
                {payee.amount ? 
                  <span>Start ${payee?.amount?.toFixed(2)} Payment for: {" "}</span>
                :
                  <span>Start ${(+payee?.order?.subtotal_price + +payee?.order?.tax_total).toFixed(2)} Payment for: {" "}</span>
                }
                <Button type="button" disabled={payee.paid ? true : false} onClick={()=>setActivePayee(payee)} active={activePayee?.payeeOrder === payee?.payeeOrder ? true : false}>{payee?.identifier}</Button>
                {payee?.cash ? 
                  <>
                    <br/>
                    <span className="sp-success">Please issue ${payee?.cash?.change_amount?.toFixed(2)} in change.</span>
                  </>
                  :
                  null
                }
                {(payee?.paymentType==="credit card" && payee?.paid) ?
                  <>
                    <br />
                    <span className="sp-success">Credit card payment made successfully.</span>
                  </>
                :
                  null
                }
              </span>
            </p>
          </div>
        ))}
        {activePayee ? 
          <>
            <fieldset disabled={activePayee.paid ? true : false}>
              <Button type="button" onClick={()=>setPaymentType("cash")}>Cash Payment</Button>
              <Button type="button" onClick={()=>setPaymentType("card")}>Credit Card Payment</Button>

              {/* coupons do not currently support working by amount as many split payments would need */}
              {/* <Button type="button" className="bordered-neutral-btn" onClick={()=>setCouponModalShow(true)}>Apply Coupons</Button> */}
              
              <br />
              {" "}
            </fieldset>
          </>
          :
          null
        }
        <br />
        {paymentType==="cash" ?
          <>
            {props.type === "multiMany" &&
              <p>
                <span className="bold">Tax:</span> ${currentOrder?.tax_total.toFixed(2)}{" "} + {" "}
                <span style={{textDecoration: "line-through"}} className="bold">Admin Fee:</span> ${currentOrder?.admin_fee_total.toFixed(2)}{" "} + {" "}
                <span className="bold">Subtotal:</span> ${currentOrder?.subtotal_price.toFixed(2)}{" "} = {" "}
                <span className="bold">Total:</span> ${(+currentOrder?.total_price - +currentOrder?.admin_fee_total).toFixed(2)}
              </p>
            }
            <SplitPaymentCash 
              partialPaid={props.partialPaid}
              onClose={props.onClose}
              adjustPayeeStatus={adjustPayeeStatus} 
              currentOrder={activePayee.order} 
              payee={activePayee} 
              amount={activePayee.amount} 
              type={props.type}
            />
          </>
        :
          null
        }
        {paymentType==="card" ?
        <>
          <br />
          {props.type==="multiMany" &&
            <p>
              <span className="bold">Tax:</span> ${currentOrder?.tax_total.toFixed(2)}{" "} + {" "}
              <span className="bold">Admin Fee:</span> ${currentOrder?.admin_fee_total.toFixed(2)}{" "} + {" "}
              <span className="bold">Subtotal:</span> ${currentOrder?.subtotal_price.toFixed(2)}{" "} = {" "}
              <span className="bold">Total:</span> ${currentOrder?.total_price.toFixed(2)}{" "}
            </p>
          }
            <SplitPaymentCC
              partialPaid={props.partialPaid}
              onClose={props.onClose}
              register={props.register}   
              terminalDeviceId={props.terminalDeviceId}
              currentOrder={currentOrder}
              adjustPayeeStatus={adjustPayeeStatus} 
              amount={activePayee.amount} 
              payee={activePayee} 
              type={props.type}
            />
        </>
          :null
        }
        <CouponPicker
          register_id={props.register}
          show={couponModalShow} 
          hide={()=>setCouponModalShow(false)}
          title="Available Discounts"
        />
        <div className="sp-success">
          {success}
        </div>
      </div>
    </>
  )
}

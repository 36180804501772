import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import { useHistory } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {Nav, Navbar, NavDropdown, Modal} from 'react-bootstrap';
import ApiGroups from '../../../../../api/Groups';
import Login from '../Login';
import { randomUUID } from '../../../../../utils/cms';

import * as actions from '../../../../../store/actions';

const Menu = React.forwardRef((props, ref) => {
    const navRef=useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const user = useSelector(state => state.auth.user);
    //const [menu, setMenu] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    
    const clickHandler = useCallback(e => {
        e.preventDefault();
        const auth = e.target.getAttribute("data-auth");

        if (auth==="true" &&  e.target.href.indexOf("/login") && !user?.profile?.id){
            setShowLogin(true);
            return;
        } else if (e.target.href && (e.target.href.includes("/logout") || e.target.href.includes("/signout"))){
            e.stopPropagation();
            localStorage.clear();
            dispatch(actions.logout());
            dispatch(actions.resetFamily());
            history.go(0);
            return;
        } else {
            window.location.href=e.target.href;
        }
    }, [user, dispatch, history]);


    const _renderItem = useCallback((Component, item) =>{
        return (
            <Component 
                key={`menu-item-${item.key}`} 
                href={props.is_preview?"#!":item.url} 
                onClick={props.is_preview?null:clickHandler} 
                data-auth={item.login ? true : false} 
                className={item.classes}>
                    {item.icon}
                    {item.name}
            </Component>
        );
    },[props.is_preview, clickHandler]);


    const menu = useMemo(() => {
        const _setupClasses = (classes) => {
            let _classes="";
            if (classes){
                let aclasses=classes.split(';');
                aclasses?.forEach(c=>{
                    if (c){
                        let n=c.trim()+"_"+props.page_id;
                        _classes+=" "+c+" "+n; // duplicate the class name in case the original is a bootstrap class, the duplicate will be used for the custom css
                    }
                });
            }
            return _classes;
        }

        const _loadItems = (_items, is_child = false) => {
            if (_items.length===0) return null;
            
            let __items = [];
            //const flatItems = _items.flatMap(_item => Array.isArray(_item)? _item.map(a => a) : _item);

            let i = 0;
            for (const item of _items) {
                const _classes=_setupClasses(item.classes);
                const _item = {
                    key: i,
                    name: item[0].value,
                    url: item[1].value || "#!",
                    icon: item[2].value ? <i className={`${item[2].value} me-2`}/> : null,
                    login: item[3]?.checked ? 1 : 0,
                    api: item[4]?.value ? item[4].value : null,
                };

                let _children = item.filter(a=>a.children);

                if (_item?.name?.includes("{user.name}")){
                    if (_item.login && user?.profile?.id){
                        _item.name = _item.name.replace("{user.name}",user.profile.first_name);
                        _item.icon = <img src={props.company_context.noPic} alt={user.profile.name} style={{width:"28px",height:"28px",borderRadius:"50%",margin:"auto 5px auto 1rem"}}/>

                        const logout = [{ id: randomUUID(), value: "Sign Out"}, { id: randomUUID(), value: "/signout"}, {id: randomUUID(), value: "fas fa-sign-out"}, {id: randomUUID(), value: 0}]; 
                        if (_children.length > 0) _children[0].children.push(logout);
                        else _children = [{id: randomUUID(), children: [logout] }];

                    } else if (_item.login && !user?.profile?.id){
                        _item.name = "Sign In";
                        _item.login = 2;
                        _item.url = "/login";
                        _children = [];
                    }
                }

                if (_children.length>0){
                    _children = _loadItems(_children[0].children, true);
                    __items.push(
                        <NavDropdown 
                            key={`menu-item-${_item.key}-${i}-${randomUUID()}`} 
                            id={`menu-item-${_item.key}-${i}`} 
                            className={`nav-dropdown_${props.page_id} nav-link_${props.page_id} nav-dropdown-item_${props.page_id} ${_classes}`.trim().replace(/\s+/g,' ')} 
                            title={
                                <>
                                    {_item.icon}
                                    {_item.name}
                                </>
                            }>
                                {_children}
                        </NavDropdown>
                    );
                } else if (!_item.login || _item.login===2 || (_item.login ===1 && user?.profile?.id)) {
                    __items.push(
                        _renderItem(is_child ? NavDropdown.Item : Nav.Link, {
                            ..._item, 
                            key:`menu-item-${_item.key}-${randomUUID()}`, 
                            classes: `${is_child ? `nav-dropdown-item_${props.page_id}` : "mr-2"} nav-link_${props.page_id} ${_classes}`
                        })
                    );
                }
                i++;
            }
            return __items;
        }

        return _loadItems(menuItems);

    }, [menuItems, props.page_id, props.company_context.noPic, user?.profile, _renderItem]);


    useEffect(() => {
        const _format = (item) => {
            return [
                { id: randomUUID(), value: item.name || "" },   // name
                { id: randomUUID(), value: item.url || "" },    // url
                { id: randomUUID(), value: item.icon || "" },   // icon
                { id: randomUUID(), checked: item.login || 0 }, // login
                { id: randomUUID(), value: item.api || ""},     // api
            ];
        }

        const _loadFromAPI = async (api) => {
            const res = [];
            if (api){
                const [api_call, params] = api.split("|");
                let api_params={};
                if (params){
                    const _params = params.split(",");
                    _params.forEach(p=>{
                        const [key,value] = p.split(":");
                        api_params[key] = value;
                    });
                }

                try {
                    if (props.is_preview){
                        for (let i=0;i<3;i++){
                            const __item = _format({name: "Group "+(i+1), url: "#!"});
                            setMenuItems(prev => [...prev, __item]);
                        }
                    } else {
                        switch(api_call){
                            case "group/list":
                                const res = await ApiGroups.publicGet({filters:api_params});
                                if (res?.data?.groups){
                                    res.data.groups.forEach(g=>{
                                        const __item = _format({name: g.name, url: "/group/"+g.id});
                                        setMenuItems(prev => [...prev, __item]);
                                    });
                                }
                                break;
                            default:
                                break;
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            return res;
        }

        if (props?.menu){ // this is for backwards compatibility with the old menu format
            props.menu.forEach((item) => {
                if (item?.api) _loadFromAPI(item.api);
                else {
                    const __item=_format(item);
                    setMenuItems(prev => [...prev, __item]);
                }
            });
        } else if (props?.items) setMenuItems([...props.items]);
    }, [props.is_preview, props?.menu, props.items]);


    useEffect(() => {
        return () => {
            setShowLogin(false);
        }
    }, []);

    return (
        <Navbar 
            ref={ref || navRef} 
            expand={props?.menu_collapse_on==="always" ? true : props?.menu_collapse_on==="never" ? false : (props?.menu_collapse_on || "lg")} 
            onClick={preview_click} 
            style={{
                margin: props?.menu_alignment?.toLowerCase()==="center"?"auto":null, 
                marginLeft: props?.menu_alignment?.toLowerCase()==="right"?"auto":null, 
                marginRight: props?.menu_alignment?.toLowerCase()==="left"?"auto":null, 
                ...(props?.style || {})
            }} 
            className={`menu_${props.page_id} ${props.className || ""}`}
        >
            <Navbar.Toggle aria-controls={`responsive-navbar-nav-${props.page_id}`} />
            <Navbar.Collapse className={`navbar-collapse_${props.page_id}`} key={`menu-collapse-${props.page_id}-${randomUUID()}`.trim().replace(/\s+/g,' ')} id={`responsive-navbar-nav-${props.page_id}`}>
                {menu && menu?.length>0 &&
                    <Nav key={`menu-nav-${props.page_id}-${randomUUID()}`} className={`w-100 navbar-nav_${props.page_id} 
                            ${props?.menu_alignment?.toLowerCase()==="center" 
                                ? "justify-content-center" 
                                : props?.menu_alignment?.toLowerCase()==="right" 
                                    ? "justify-content-end"
                                    : ""}
                        `.trim().replace(/\s+/g,' ')}
                    >
                        {menu}
                    </Nav>
                }
            </Navbar.Collapse>
                <Modal show={showLogin} onHide={()=>setShowLogin(false)} centered>
                <Modal.Header closeButton />
                <Modal.Body>
                    <Login />
                </Modal.Body>
            </Modal>
        </Navbar>
    );
});

export default Menu;
import React,{useEffect, useState, useCallback, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import sha256 from 'js-sha256';
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import ErrorCatcher from "../../../../components/common/ErrorCatcher";
import { Children as ChildrenForm } from '../Form';
import { updateAuthUser } from "../../../../utils/thunks";
//import {Group as GroupForm} from '../Form';

import ListChildEvents from '../List';
import { authUserHasModuleAccess } from "../../../../utils/auth";

import Events from "../../../../api/Events";
import Products from "../../../../api/Products";
import Locations from "../../../../api/Locations";
import ImageViewer from "../../../../components/common/ImageViewer";

// event Statuses
// Pending = 1
// Confirmed = 2
// Postponed = 3
// Cancelled = 4
// Reserved/Private = 5
// Archived = 6
// In Cart = 7
// Expired = 8

const WIDGET_MODULE_ID = 319; // register for public events

export const Event = (props) => {

    let history = useHistory();
    const dispatch=useDispatch();
    let user = localStorage.getItem("user");
    const _user = useSelector(state => state.auth.user);
    
    const [id, setId] =useState();
    const mountedRef= useRef(false);
    const [loading,setLoading] = useState(true);
    const [event,setEvent] = useState();
    const [childEvents, setChildEvents] = useState([]);
    const [childIds, setChildIds]=useState([]);
    const [eventType, setEventType] = useState();
    const [hashMatches, setHashMatches] = useState(false);
    const [price, setPrice] = useState(0);
    const [date, setDate] = useState();
    const [location, setLocation] = useState();
    const [error, setError]=useState();
    const [userHasModulePermission, setUserHasModulePermission] = useState(false);


    const getEvent=useCallback(async()=>{
        try{
            let response = await Events.getSingle({id: id})
            if(mountedRef.current && response.data){
                setEvent(response.data[0])
            }else if(mountedRef.current && response.errors){
                setError(<ErrorCatcher error={response.errors} />)
            }
        }catch(ex){console.error(ex)}
    },[id]);

    const getChildEvents=useCallback(async()=>{
        try{
            let response = await Events.getSimple()
            if(mountedRef.current && response.data){
                setChildEvents(response.data?.events?.filter(event=> parseInt(event.parent_id)===parseInt(id)));
            }else if(mountedRef.current && response.errors){
                setError(<ErrorCatcher error={response.errors} />)
            }
        }catch(ex){console.error(ex)}
        finally{setLoading(false)}
    },[id]);

    const getEventTypes=useCallback(async()=>{
        try{
            let response = await Events.Types.get({company_id: _user.company_id})
            if(mountedRef.current && response.data){
                setEventType(response.data.find(type => parseInt(type.id)=== parseInt(event.event_type_id)))
            }else if (mountedRef.current && response.errors){
                setError(<ErrorCatcher error={response.errors} />)
            }
        }catch(ex){console.error(ex)}
    },[event,_user.company_id]);

    const getLocation=useCallback(async()=>{
        try{
            let response = await Locations.get({id: event.location_id});
            if(mountedRef.current && response.data) setLocation(response.data[0].name)
            else if(mountedRef.current && response.errors) setError(<ErrorCatcher error={response.errors} />)
        }catch(ex){console.error(ex)}
    },[event]);


    useEffect(()=>{
        mountedRef.current = true;

        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccess(WIDGET_MODULE_ID);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }
        checkPermission();
        
        return()=> {
            mountedRef.current=false
            setEvent();
            setChildEvents([]);
        }
    },[]);

    useEffect(()=>{
        if(props.id){
            if(props.id.includes('code')){
                let tempId = props.id.split('?code=')
                setId(tempId[0])
            }else setId(props.id)
        }
    },[props.id])

    //update the user because when coming directly from a link and logging in that way, there is no family data because it just uses the login call data instead.
    useEffect(()=>{
        //checking whether we need to update or not
        if(!_user?.profile?.family_groups){
            dispatch(updateAuthUser());
        }
    },[dispatch, _user]);

    useEffect(() => {
        
        if(event) {
            if(props.id.includes("code")){
                let hash = props.id.split('?code=')
                // let hash = new URLSearchParams(window.location.search).get("event").get("code");
                if (sha256(`${event.id}-${event.event_type_id}-pistachiooOO`) === hash[1]) setHashMatches(true);
            }
        }
    },[event, props.id]);

    useEffect(()=>{
        if(id && (!event || event.id !== +id)){
            setLoading(true);
            getEvent();
        };
    },[getEvent, id, event]);

    useEffect(() => {
        if(event) {
            getEventTypes();
            let startFormatted = format(new Date(event?.start_datetime), "ccc MM/dd/yyyy");
            let endFormatted = format(new Date(event?.end_datetime), "ccc MM/dd/yyyy");
            if(startFormatted === endFormatted) {
                startFormatted = format(new Date(event?.start_datetime), "ccc MM/dd/yyyy hh:mm aa");
                endFormatted = format(new Date(event?.end_datetime), "hh:mm aa");
            }
            setDate(startFormatted + " - " + endFormatted);

            if(event.location_id) getLocation();
            getChildEvents();
        }

    },[event, getChildEvents, getEventTypes, getLocation]);

    return (
        <React.Fragment>
            <CssBaseline />
            <section className={`im-about-wrap${loading ? " loading" : ""}`}>
                <Card>
                    {!loading && event &&
                        <div style={{ maxWidth: "100%" }}>
                            <Row>
                                <Col sm="12" md="6">
                                        {(event?.event_status_id === 1 || event?.event_status_id === 3 || event?.event_status_id === 4 || event?.event_status_id === 6 || event?.event_status_id===8) && 
                                            <h6>At this time, registration for this event is not available due to its {event?.event_status_name?.toLowerCase()} status.  If you have any questions, don't hesitate to reach out.</h6>
                                        }
                                        <h2 data-cy="register-event-name">{event?.name}</h2>
                                        {event?.event_status_id === 5 && 
                                            <h6>This is a private event {!hashMatches && " and can only be registered for via a direct invitation"}.</h6>
                                        }
                                        <p dangerouslySetInnerHTML={{__html: event?.description}}/>
                                        {event?.image &&
                                            <Col sm="12" md="6" className="mt-5">
                                                <ImageViewer 
                                                    images={event?.image} 
                                                    eventName={event?.name} 
                                                    thumbLimit={4}
                                                />
                                            </Col>
                                        }
                                        <p>
                                            <span data-cy="event-when">
                                                <label className="form-label" >When:</label><br/>
                                                {date}
                                                <br/>
                                            </span>
                                            {location &&
                                                <span data-cy="event-where">
                                                    <label className="form-label">Where:</label><br/>
                                                    {location}
                                                    <br/>
                                                </span>
                                            }
                                            {event?.product_price > 0 &&
                                                <span data-cy="event-fee">
                                                    <label className="form-label">Event Fee:</label><br/> 
                                                    ${event?.product_price}
                                                </span>
                                            } 
                                            {(event?.min_age > 0 || event?.max_age > 0) &&
                                                <span data-cy="event-ages">
                                                    <label className="form-label">Age Limit:</label><br/>
                                                    {event.min_age > 0 && event.max_age > 0 && 
                                                        `Participant must be between ${event?.min_age} - ${event?.max_age} years old to register`
                                                    }{event.min_age > 0  && !event.max_age &&
                                                        `Participant must be at least ${event?.min_age} years old to register`
                                                    }{!event.min_age && event.max_age > 0 &&
                                                        `Participant must be no more than ${event?.max_age} years old to register`
                                                    }
                                                </span>
                                            }
                                            {!event?.requires_registration && !childEvents?.length && (
                                                <span data-cy="event-registration">
                                                    <p>No registration required</p>
                                                    <Button variant="light" onClick={() => history.goBack()}>Back</Button>
                                                </span>
                                            )}
                                        </p>
                                    </Col>
                            </Row>
                            <Row>
                                <Col>
                                        {event.requires_membership === 1 && !childEvents?.length && (
                                            <>
                                                {_user.company_id ===2 ? 
                                                        ""
                                                    :
                                                    <p>
                                                        <span className="bold">Requires membership to attend</span> - Ask at the front desk for more details
                                                    </p>
                                                }
                                            </>
                                        )}
                                </Col>
                                
                            </Row>
                        </div>
                    }
                    <Row>
                        {!loading && event?.requires_registration === 1 && userHasModulePermission[WIDGET_MODULE_ID] &&
                        // (eventType?.individual_invites===1 || eventType?.group_invites) && //this is causing a strange 0 appearing in the form rather than using it as a check - I don't think group/individual invites are currently being used
                        (event?.event_status_id === 2 || (event?.event_status_id === 5 && hashMatches)) &&
                            <Col sm="12" lg="6">
                                <section className="membership-wrap">
                                    <Container>
                                        {eventType?.individual_invites ? (
                                            <ChildrenForm
                                                event_id={event?.id}
                                                event_parent_id={event?.parent_id}
                                                eventAges={{min: event?.min_age, max: event?.max_age}}
                                            />
                                        ) : null}
                                    </Container>
                                </section>
                            </Col>
                        }
                        {!userHasModulePermission[WIDGET_MODULE_ID] &&
                            <Col sm="12" lg="6">
                                You do not have permission to register for events.
                            </Col>
                        }
                    </Row>
                    {!loading && childEvents?.length>0 &&
                        <Row className="membership-wrap mt-3">
                            <Col sm="12">
                                <h5 className="dash-title pt-3" data-cy="register-event-title">Sessions</h5>
                                <ListChildEvents parent_id={event?.id} isChild={true} />
                            </Col>
                        </Row>
                    }
                    {error}
                </Card>
            </section>
        </React.Fragment>
    );
}
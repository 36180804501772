import React,{useState, useEffect} from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import FormGroupText from '../FormGroupText/FormGroupText';
import FormGroupButton from '../FormGroupButton';

import APIUsers from '../../../../../api/Users';

const ResetPassword =  React.forwardRef((props, ref) => {
    const {close} = props;

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }
    
    const user = useSelector(state => state.auth.user);
    const [error, setError] = useState();
    const [validated, setValidated] = useState(false);    
    const [submitting, setSubmitting] = useState(false);
    const [body, setBody]=useState();

    const onboardingFormat = props?.onboarding || false;

    useEffect(() => {
        return () => {
            setError(null);
            setValidated(false);
            setSubmitting(false);
            setBody(null);
        }
    }, []);

    const closeHandler = () => {
        if (close) close();
        else setBody(null);
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        
        setValidated(true);
        setError(null);

        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            const formData = new FormData(e.target);
            formData.append("token", new URLSearchParams(window.location.search).get("guid"));
            const formDataObj = Object.fromEntries(formData.entries());
            setSubmitting(true);
            try {
                const response = await APIUsers.resetPassword(formDataObj);
                if (!response.errors){
                    setBody(
                        <>
                            <h1>Success!</h1>
                            <p>Your password has been reset.</p>
                            <p><FormGroupButton page_id={props.page_id} label="Back to Sign In" disabled={submitting} click={closeHandler} /></p>
                        </>
                    );
                } else {
                    setError(response.errors);
                }
            } catch(error) {
                setError(error);
            } finally {
                setSubmitting(false);
            }
        }
    }

    if (user?.profile?.id && !props.is_preview && !onboardingFormat){
        return null;
    }

    if (body) return body;

    return (
        <Form
            ref={ref}
            onSubmit={submitHandler} 
            className={`resetpassword_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' ')}
            style={props.style} 
            onClick={preview_click}
            noValidate 
            validated={validated}>
            <Row>
                <Col sm={12}>
                    <FormGroupText id="password" page_id={props.page_id} name="password" type="password" label="Enter new password" required disabled={submitting} />
                </Col>
                <Col sm={12}>
                    <FormGroupText id="password2" page_id={props.page_id} name="password_confirmation" type="password" label="Re-type new password" required disabled={submitting} />
                </Col>
                <Col sm={12} md={true}>
                    <FormGroupButton page_id={props.page_id} label="Send" disabled={submitting} click={props.is_preview?"Button":"Submit Form"} />
                    <FormGroupButton page_id={props.page_id} label="Back to Sign In" disabled={submitting} click={closeHandler} />
                </Col>
                {error && 
                    <Col sm={12} className="error-text-ctr">
                        {error}
                    </Col>
                }
            </Row>
        </Form>
    );
});

export default ResetPassword;
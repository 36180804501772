import React, {useState, useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import { randomUUID } from '../../../../../utils/cms';

const Wizard =  React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [step, setStep] = useState(1);
    const [children, setChildren] = useState();

    useEffect(() => {
        const _ch = React.Children.toArray(props.children).filter(child => child.props?.step_number || child.props?.element_id?.toLowerCase() === "wizardstep") || [];
        if (step < 1) setStep(1);
        if (_ch.length && step > _ch.length) setStep(_ch.length);
        if (_ch.length) setChildren(_ch.map((child, i) => React.cloneElement(child, {current_step: step, selected_step: i+1 })) || null);
    }, [props.children, step]);

    useEffect(() => {
        return () => {
            setStep(1);
            setChildren(null);
        }
    }, []);

    if (!children) return null;

    return (
        <div id={props.is_preview ? `preview-${randomUUID()}`: props?.id} ref={ref} className={`wizard_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' ')} style={props.style} onClick={preview_click}>
            {children}
            <Row className="my-3">
                <Col>
                    {props.wizard_buttons==="Next & Previous" &&
                        <>
                            <Button onClick={()=>setStep(step-1)} disabled={step <= 1}>Back</Button>
                            <Button onClick={()=>setStep(step+1)} disabled={step >= children.length}>Next</Button>
                        </>
                    }
                    {props.wizard_buttons==="Step Numbers" &&
                        <>
                            {Array.from(Array(children.length).keys()).map((_,i)=>(
                                <Button onClick={()=>setStep(i+1)}>{i+1}</Button>
                            ))}
                        </>
                    }
                    {props.wizard_buttons==="Step Labels" &&
                        <>
                            {children.map((a,i)=>(
                                <Button onClick={()=>setStep(i+1)}>{a?.props?.properties?.filter(b=>b.name==="step_title")?.[0]?.value || `Step ${i+1}`}</Button>
                            ))}
                        </>
                    }
                </Col>
            </Row>
        </div>
    );
/*
    return (
        <>
            {!props?.modal && <>{Component}</>}
            {props?.modal &&
                <Modal show={props?.modal?true:false} centered>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        {Component}
                    </Modal.Body>
                </Modal>
            }
        </>
    );
*/    
});

export default Wizard;
import React from 'react';

import styles from './Anchor.module.scss';
const Anchor = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    let _props={
        ref: ref, 
        className: `Anchor_${props.page_id} ${props.className || ""} ${props.is_preview?styles.anchor:""}`.trim().replace(/\s+/g,' '),
        onClick: preview_click,
        style: {margin: 0, padding: 0, background: "transparent", boxShadow: "none"}
    }

    if (props.is_preview){
        _props.style = {..._props.style, height: "20px", width: "100%", display:"block"};
    } else {
        _props.style = {..._props.style, border: 0};
    }
    
    return <div {..._props} id={props.anchor_id} />;
});

export default Anchor;
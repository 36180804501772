import React, {useState, useEffect, useCallback, useRef} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import * as actions from '../../../../../../store/actions';

import Pos from '../../../../../../api/Pos';
import {createOrder} from '../../SplitPaymentUtils';
import usePrevious from '../../../../../../components/common/CustomHooks';
import ProcessEachPayment from '../../MultiUserSingleItem/ProcessEachPayment';
import ErrorCatcher from '../../../../../../components/common/ErrorCatcher';
import { updateCart } from '../../../../../../utils/thunks';

export const Payments = (props) => {

    const { onClose, alreadySplit, register, splitType, terminalDeviceId, type, goTo} = props;

    const mountedRef = useRef();
    const dispatch=useDispatch();
    const currentOrder=useSelector(state=>state.pos[props.register].orderAll);
    const payees=useSelector(state=>state.splitPayments.payees);
    const spErrors=useSelector(state=>state.splitPayments.errors);
    const allPaid=useSelector(state=>state.splitPayments.allPaid);
    const ordersSplit =useSelector(state=>state.splitPayments.ordersSplit)
    const [error, setError]=useState();
    const [activePayee, setActivePayee]=useState(payees[0]);
    const [haveAll, setHaveAll]=useState(false);
    const oldActivePayee=usePrevious(activePayee)


//#region Callbacks    

    const assignPayeeToOrder=useCallback(async(orders)=>{
        let allOkay=true;
        for(let i = 0; i<orders?.split_order_ids?.length; i++){
            try{
                let response = await Pos.order.get({id: orders.split_order_ids[i]})
                if(!response.errors){
                    await payees.forEach((payee)=>{
                        if(payee.identifier === response.data.memo){
                            payee.order = response.data
                        }
                    })
                }else if(response.errors){
                    console.error(response.errors)
                    allOkay = false
                }
            }catch(ex){
                console.error(ex)
            }
        }
        if(!allOkay){
            setHaveAll(false);
            dispatch(actions.setOrdersHaveBeenSplit(false))
        }
    },[payees, dispatch]);

    /**Check that all payees have an order */
    const checkAllHaveOrders=useCallback(()=>{
        let total = payees.length;
        let i = 0;
        payees.forEach((payee)=>{
            if(payee.hasOwnProperty("order")) i++
        })
        if(i === total) setHaveAll(true)
        else{setHaveAll(false)}
    },[payees]);

    const checkAllPaid=useCallback(()=>{
        if(allPaid !=="Yes"){
            let total = payees.length;
            let i = 0;
            payees.forEach((payee)=>{
                if(payee.paid === true) i++;
            })
            if(i === total && total !==0) {
                dispatch(actions.setSplitPaymentAllPaid("Yes"));
                goTo('Confirmation');
            }
            if(i > 0 && i < total) {
                dispatch(actions.setSplitPaymentAllPaid("Partial"));
            }
            if(i === 0){
                dispatch(actions.setSplitPaymentAllPaid("No"));
            }
        }
    },[payees, allPaid, dispatch, goTo]);

    const createOrders=useCallback(async()=>{
        if(!haveAll && allPaid === 'No' && !ordersSplit){
            let temp = await createOrder(currentOrder, payees, props.splitType);
            if(temp.error){
                setError(<ErrorCatcher error={temp.error} />)
            }
            if(temp){
                //get updated order for redux with the split payment ids
                try{
                    dispatch(actions.setOrdersHaveBeenSplit(true))
                    let response = await Pos.order.get({
                        id: currentOrder.id
                    })
                    if(!response.errors){
                        dispatch(actions.orderAll(response.data, props.register))
                        setHaveAll(true);
                        await assignPayeeToOrder(temp);
                    }else if (response.errors){
                        setError(<ErrorCatcher error={response.errors} />)
                    }
                }catch(ex){console.error(ex)}
            };
        }
    },[currentOrder, payees, haveAll, ordersSplit, allPaid, props.splitType, props.register, dispatch, assignPayeeToOrder]);

//#endregion Callbacks

//#region useEffects
    //first load
    useEffect(()=>{
        mountedRef.current = true;

        return()=>mountedRef.current = false;
    },[])

    //sets the payee that's active and sees if everyone has already paid or not
    useEffect(()=>{
        if(activePayee !== oldActivePayee && mountedRef.current) setActivePayee(payees[0])
        if(mountedRef.current){
            checkAllHaveOrders();
            checkAllPaid();
        } 
        if(props.alreadySplit){
            setHaveAll(true);
            dispatch(actions.setOrdersHaveBeenSplit(true));
        }
    //including checkAllPaid as a dependency causes another infinite loop
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[payees, props.alreadySplit, activePayee, oldActivePayee, dispatch, checkAllHaveOrders]);

    //splitting the order based upon the current order
    useEffect(()=>{
        if(activePayee && !haveAll && !props.alreadySplit && mountedRef.current) {
            createOrders();
        }
    },[activePayee, createOrders, haveAll, props.alreadySplit]);

    //After orders are split successfully, dispatch everything
    useEffect(()=>{
        if(haveAll && mountedRef.current) {
            let allOrders=[]
            payees.forEach((payee)=>{
                allOrders.push(payee.order)
            })
            dispatch(actions.setSplitPaymentPayees(payees)); 
            
            //using these as a way to see in previous steps if the orders exist or not to determine redraw from the DB.  This will go back to [] if any changes are made in the previous steps
            dispatch(actions.setSplitPaymentAllOrders(allOrders)); 
        }
    },[haveAll, payees, dispatch]);

//#endregion useEffects




  return (
    <>
        {haveAll ? 
            <ProcessEachPayment 
                goTo={props.goTo}
                partialPaid={allPaid} 
                onClose={props.onClose} 
                register={props.register} 
                type={props.type} 
                terminalDeviceId={props.terminalDeviceId}
            />
            :
            <>
                <Spinner animation="border" /> 
                {" "} 
                Creating Orders...
            </>
        }
        <div className="sp-error">
            <span>{spErrors?.notAllPaid}</span>
        </div>
        {error}
    </>
  )
}

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useLocation, Link } from "react-router-dom";
import { Breadcrumb, Card, Container } from 'react-bootstrap';

// register_id, checkout, events all auto generated
// showBreadcrumb and showBanner both default to TRUE, but can be turned off in the register_definition
export const PatronCartHeader = ({ register_id, checkout, events, showBreadcrumb=true, showBanner=true }) => {

    let location = useLocation();
	let dispatch = useDispatch();

    const registerInfo = useSelector(state => state.pos[register_id].registerInfo);

    const [breadcrumbPagePart, setBreadcrumbPagePart] = useState();

    useEffect(() => {
        let registerName = registerInfo?.name || "Shop";
        let bread = <></>;
        if (registerInfo) {
            if (checkout) {
                bread = (<>
                    {registerInfo?.url &&
                        <Breadcrumb.Item linkAs={Link} linkProps={{to:registerInfo.url}}>{registerName}</Breadcrumb.Item>
                    }
                    <Breadcrumb.Item active>Checkout</Breadcrumb.Item>
                </>);
            } else if (location.search) {
                bread = (<>
                    <Breadcrumb.Item linkAs={Link} linkProps={{to:"/p/event-register"}}>
                        Upcoming Events
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Event Details</Breadcrumb.Item>
                </>);
            } else {
                bread = (<>
                    <Breadcrumb.Item active>
                        { events ? `Upcoming Events` : registerInfo.name }
                    </Breadcrumb.Item>
                </>);
            }
        }
        setBreadcrumbPagePart(
            <Breadcrumb>
                <Breadcrumb.Item linkAs={Link} linkProps={{to:"/p/home"}} data-cy="pc-header-home-bc">Home</Breadcrumb.Item>
                {bread}
            </Breadcrumb>
        );
    },[location, registerInfo, register_id, checkout, events]);

    return (
        <section className="site-col">
            {showBreadcrumb &&
                <div className="header">
                    {breadcrumbPagePart}
                </div>
            }

            {registerInfo && showBanner &&
                <Card className="description-banner content-card">
                    <h4>{registerInfo.name}</h4>
                    {!!registerInfo.description && 
                        <div>{registerInfo.description}</div>
                    }
                    {!!registerInfo.pickup_location && 
                        <div><span className="title">Pickup Location:</span> {registerInfo.pickup_location}</div>
                    }
                    <hr/>
                </Card>
            }
        </section>
    )
}
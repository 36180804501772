import React, {useEffect} from 'react';

export const Details = (props) => {
    const {goToStep} = props;

    useEffect(() => {
        if (props?.requires_registration === 1 && !props.currentStep){
            goToStep({
                stopPropagation: () => {},
                preventDefault: () => {}
            }, 1);
        }
    }, [props?.requires_registration, props.currentStep, goToStep]);

    return (
        <>
            {props.image &&
                <img src={props.image} alt={props.title} className="img-fluid event-image" />
            }
            <div dangerouslySetInnerHTML={{__html:props.description || props.short_description}} />
            <div>
                <p>
                    <label className="form-label">When</label><br/>
                    {props.date}
                </p>
                {props.location_name &&
                    <p>
                        <label className="form-label">Where</label><br/>
                        {props.location_name}
                    </p>
                }
                {(props.min_age || props.max_age) &&
                    <p>
                        <label className="form-label">Age Requirement</label><br/>
                        {
                            (props.min_age > 0 && !props.max_age ? `${props.min_age} years old and up` : "") +
                            (!props.min_age && props.max_age > 0 ? `Up to ${props.max_age} years old` : "") + 
                            (props.min_age > 0 && props.max_age > 0 ? `${props.min_age} to ${props.max_age} years old` : "")
                        }
                    </p>
                }
                {props.default_variant_price && 
                    <p>
                        <label className="form-label">{props.variants.length>1?"Starting From":"Event Fee"}</label><br/> 
                        ${props.default_variant_price}
                    </p>
                }
                <p>
                    {props.requires_registration !==1 && <span className="bold">No registration required</span> }
                    {props.requires_membership === 1 && <span className="bold">Requires membership to attend</span>}
                </p>
            </div>
        </>
    );
}

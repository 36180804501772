import React,{useState, useEffect, useCallback} from 'react';
import { Row, Col ,Button } from 'react-bootstrap';

const svgWidth = 100;
const svgHeight = 50;
const svgGap = 1.5;

const LayoutStructure = React.forwardRef((props, _) => {
    const {selection, columns} = props;
    const [svg, setSvg] = useState([]);

    /* crazy stuff for later
    let increment = 0, gap = 2;
    for (let j = i - 1; j >= 0; j--) {
        let x = 100 - Math.round(100/i) * j - j;
        d += `M${x},0V50H${Math.round(100/i) * increment + increment + gap}V0Z `;
        increment++;
    }*/

    const clickHandler = useCallback(item => {
        selection(item);
    },[selection]);

    useEffect(() => {
        let from =1, to = 6;
        if (columns){
            from = columns;
            to = columns;
        }

        let _svg=[];
        for (let i = from; i <= to; i++){
            let gaps = i - 1;
            let width = (svgWidth - (gaps * svgGap) )/ i;
            const _paths=[];
            for (let j=0;j<i;j++){
                _paths.push(<rect key={`svg-path-${j}`} x={j * (width + svgGap)} y="0" width={width} height={svgHeight} />);
            }
            _svg.push(
                <svg key={`svg-${i}`} className="layout-structure-svg" width={svgWidth} height={svgHeight} onClick={() => clickHandler(i)}>
                    {_paths}
                </svg>
            );
        }    
        setSvg(_svg);
    },[columns, clickHandler]);

    useEffect(() => {   
        return () => {
            setSvg([]);
        }
    }, []);

    return (
        <Row noGutters>
            {columns && +columns>1 &&
                <Col sm="auto" className="d-flex align-items-center pe-0 py-0">
                    <Button className="btn rounded my-auto me-0" variant="light" href="#!" onClick={() => clickHandler(+columns - 1)}><i className="far fa-minus m-0"/></Button>
                </Col>
            }
            {svg.map((item,i) => (
                <Col sm={12} lg={4} key={`layout-structure-${i}`} className="d-flex justify-content-center py-1">
                    {item}
                </Col>
            ))}
            {columns && +columns<12 &&
                <Col sm="auto" className="d-flex align-items-center p-0">
                    <Button className="btn rounded my-auto mx-0" variant="light" href="#!" onClick={() => clickHandler(+columns + 1)}><i className="far fa-plus m-0"/></Button>
                </Col>
            }
        </Row>
    );
});

export default LayoutStructure;
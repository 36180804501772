import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {format} from 'date-fns';
import {Row, Col, Modal, Button} from 'react-bootstrap';

import Card from '../../Products/Card';

import APIGroups from '../../../../../api/Groups';
import APIEvents from '../../../../../api/Events';

import CustomFields from './CustomFields';

export const Events = (props) => {
    const {click} = props;
    const event = props?.activeProduct?.events?.[0]; // only one event per product for now
    const selectedUser = useSelector(state => state?.pos?.[props.register_id]?.user) || null;

    const [date, setDate] = useState();
    const [familyMembers, setFamilyMembers] = useState();
    const [showCustomFields, setShowCustomFields] = useState(false);
    const [customFieldsDisabled, setCustomFieldsDisabled] = useState(true);
    const [activeAttendee, setActiveAttendee] = useState();

    useEffect(() => {
        const _getFamilyMembers = async (familyId) => {
            const res2 = await APIGroups.get({id: familyId});
            const members = [];
            if (res2?.data?.length>0){
                const _validMembers = res2.data[0].group_members.filter(a=>+a.group_member_status_id===2 && a.first_name && a.last_name);
                let attendees = [];
                const res3 = await APIEvents.publicGet({id: event.id, attendees: _validMembers.map(a=>a.user_id), include_attendees: 1});
                if (res3.data?.events?.[0]?.users) attendees = res3.data.events[0].users?.map(a=>a.id) || [];

                for (const member of _validMembers){
                    let disabled = false, msg = "";

                    if (attendees.includes(member.user_id)){
                        disabled = true;
                        msg = "Already registered";
                    }

                    // check if the users dob is between the min and max age
                    if (event.max_age || event.min_age){
                        if (member.dob){
                            const dob = new Date(member.dob);
                            const today = new Date();
                            let age = today.getFullYear() - dob.getFullYear();
                            const m = today.getMonth() - dob.getMonth();
                            if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) age--;
                            if (event.max_age && age>event.max_age) disabled=true;
                            if (event.min_age && age<event.min_age) disabled=true;
                        } else disabled = true;

                        if (disabled && !msg) msg = "Does not meet age requirement";
                    }

                    members.push({
                        id: member.user_id,
                        first_name: member.first_name,
                        last_name: member.last_name,
                        group_member_role: member.group_member_role_name,
                        disabled: disabled,
                        message: msg
                    });
                }

                if (members.length>0) setFamilyMembers(members);
            }
        }

        if (selectedUser?.family_groups?.length>0 && event) _getFamilyMembers(selectedUser.family_groups[0].id);
        else {
            setFamilyMembers([]);
            setShowCustomFields(false);
            setActiveAttendee(null);
        }
    }, [selectedUser, event]);


    useEffect(() => {
        if (event){
            let startFormatted = format(new Date(event?.start_datetime), "ccc MM/dd/yyyy");
            let endFormatted = format(new Date(event?.end_datetime), "ccc MM/dd/yyyy");
            if (startFormatted === endFormatted) {
                const startFormatted2 = format(new Date(event?.start_datetime), "hh:mm aa");
                endFormatted = format(new Date(event?.end_datetime), "hh:mm aa");
                setDate(`${startFormatted} ${startFormatted2} - ${endFormatted}`);
            } else {
                startFormatted = format(new Date(event?.start_datetime), "ccc MM/dd/yyyy hh:mm aa");
                endFormatted = format(new Date(event?.end_datetime), "ccc MM/dd/yyyy hh:mm aa");
                setDate(`From ${startFormatted} to ${endFormatted}`);
            }

            if (!event.custom_fields) setCustomFieldsDisabled(false);
        }
    }, [event]);

    const customFieldChangeHandler = (values) => {
        if (event.custom_fields){
            let disabled = false;
            for (const field of event.custom_fields){
                if (field.required && !values?.[field.name]){
                    disabled = true;
                    break;
                }
            }
            setCustomFieldsDisabled(disabled);

            if (!disabled){
                setActiveAttendee({
                    ...activeAttendee,
                    custom_fields: values
                });
            }
        } else setCustomFieldsDisabled(false);

    }

    const clickHandler = (member) => {
        setActiveAttendee(member);
        let _addProduct = false;
        if (event?.custom_fields?.length>0){ 
            if (!showCustomFields) setShowCustomFields(true);
            else {
                _addProduct = true;
                setShowCustomFields(false);
            }
        } else _addProduct = true;

        if (_addProduct){
            if (props?.activeProduct?.variant_id && props?.activeProduct?.product_variants?.length>0){
                const _item = props.activeProduct.product_variants.filter(a=>a.id===props.activeProduct.variant_id)[0];
                _item.event = {
                    event_id: event.id,
                    for_user_id: member.id,
                }
                if (member.custom_fields) _item.event.custom_fields = member.custom_fields;
                click(_item, 3);
            }
        }
    }
    
    if (!event) return null;

    return (
        <Modal className="pos-modal pos-p-modal events-modal" backdropClassName="pos-p-backdrop" show={props.show} onHide={props.hide} size="lg"  backdrop="static" data-cy="event-picker-modal">
            <Modal.Header closeButton={true}>
                <Modal.Title data-cy="event-picker-modal-title">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row data-cy="event-picker-wrap">
                    <Col>
                        <h4 className="card-title">{event.name}</h4>
                        <div className="product-description">
                            <div className="small">
                                {event.short_description &&
                                    <p>{event.short_description}</p>
                                }
                                <p>{date} {event?.location?.name ? `at ${event.location.name}` : ""}</p>
                            </div>
                            {(event.min_age || event.max_age) &&
                                <p>
                                    <span className="bold">Age Requirement</span><br/>
                                    {
                                        (event.min_age > 0 && !event.max_age ? `${event.min_age} years old and up` : "") +
                                        (!event.min_age && event.max_age > 0 ? `Up to ${event.max_age} years old` : "") + 
                                        (event.min_age > 0 && event.max_age > 0 ? `${event.min_age} to ${event.max_age} years old` : "")
                                    }
                                </p>
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <div className="pos-products">
                        {familyMembers && familyMembers.map((member, i) => {
                            return (
                                <Card
                                    disabled={member.disabled || !selectedUser}
                                    key={`member-${event}-${i}`}
                                    title={`${member.first_name} ${member.last_name}`}
                                    onClick={()=>clickHandler(member)}
                                >
                                    <div className="subscription-text">
                                        <p>
                                            {member.group_member_role}
                                            {member.message &&
                                                <>
                                                    <br/>
                                                    {member.message}
                                                </>
                                            }
                                        </p>
                                    </div>
                                </Card>
                            );
                        })}
                        {!selectedUser &&
                            <p className="error-text">You must select a user before adding an event to the order!</p>
                        }
                    </div>
                </Row>

                {showCustomFields &&
                    <Modal show={showCustomFields} onHide={()=>setShowCustomFields(false)} size="md" className="pos-p-modal" backdropClassName="pos-p-backdrop">
                        <Modal.Header closeButton={true}>
                            <Modal.Title>Custom Fields</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CustomFields {...event} member={activeAttendee} onChange={customFieldChangeHandler} />
                        </Modal.Body>
                        <Modal.Body className="p-0">
                            <Button className="pos-button-checkout m-0" disabled={customFieldsDisabled || !activeAttendee} onClick={()=>clickHandler(activeAttendee)}>Add to cart</Button>
                        </Modal.Body>
                    </Modal>
                }
            </Modal.Body>
        </Modal>
    );
}
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Accordion, Card, Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import Tooltip from '../../../../../components/common/Tooltip';
import Stack from '../../../../../components/common/Stack';

import styles from './ItemRefund.module.scss';

/**Component that handles each item being adjusted for refund amount 
 * @param props.item - the item being refunded.  Includes price and shipping
 * @param addRefundItem - function to pass item and its value back up to parent component
 * @param i - for the accordion.  Start at 1, rather than 0.  Starting at zero causes the first collapse to not function properly
 * @param resetConfirm - function that toggles the confirm to reset with any changes
 * @param disableRefund - function that disables the refund button in the event of an invalid total
 * 
*/
export const ItemRefund = (props) => {
    const {item, addRefundItem, i, resetConfirm, disableRefund, fullCheck} = props;

    const mountedRef = useRef(false);
    const [ refundAmount, setRefundAmount ] = useState(0);
    const [ totalRefund, setTotalRefund ] = useState(0); //this is for the sake of display - so the user knows the total with the base price, shipping, and tax of each item
    const [ index, setIndex ] = useState();
    const [ maxRefund, setMaxRefund ] = useState();
    const [ shippingTotal, setShippingTotal ] = useState(0);
    const [ taxTotal, setTaxTotal] = useState(0);
    const [ memo, setMemo ] = useState("");
    const [ accordionToggle, setAccordionToggle ] = useState(true);
    const [ checked, setChecked ] = useState({
        half: false,
        full: false,
        shipping: false,
        tax: false,
        remove: false, //Item would be removed it amount 0, but for users and clarification, add remove check mark
        custom: false, //Not for check box, but to check if custom amount was entered or not
    });

    /** Makes sure to set shipping value to that of the item if it has one, 0 if not */
    const calculateShipping=useCallback(()=>{
        if (!props.item.shipping && mountedRef.current === true){
            setShippingTotal(0);
            setChecked(prev => ({...prev, shipping: false}));
        } else setShippingTotal(props.item.shipping);
    },[props.item.shipping]);

    /**Handles the refund total based on checked boxes and user input */
    const applyShippingAndTax=useCallback(()=>{
        let refund;
        if (mountedRef.current && !checked.custom){
            refund = 0;
            if (checked.shipping) refund += shippingTotal;
            if (checked.tax) refund += taxTotal;
            if (checked.full) refund += parseFloat(item.price);
            else if (checked.half) refund += parseFloat(item.price/2);
            setRefundAmount(0)
        } else if (mountedRef.current && checked.custom && refundAmount){
            refund = parseFloat(refundAmount);
            if (checked.shipping) refund += shippingTotal;
            if (checked.tax) refund += taxTotal;
        }
        if (refund === undefined || refund === null || isNaN(refund) )refund = 0
        setTotalRefund(refund.toFixed(2))
    },[checked, item, shippingTotal, taxTotal, refundAmount]); 

    const calculateTax=useCallback(()=>{
        if (!item.tax && mountedRef.current){
            setTaxTotal(0);
            setChecked(prev => ({...prev, tax: false}));
        } else setTaxTotal(item.tax)
    },[item.tax]);

    /**When "Refund Everything" is checked, this properly sets the item to send for the refund.   */
    const resetItemsForFull=useCallback(()=>{
        setRefundAmount(maxRefund);
        setChecked(prev => ({...prev, half: false, full:false, shipping: false, tax: false, custom:false, remove:false}));
        addRefundItem({
            id: item.id,
            item_id: item.item_id,
            total_refund_amount: maxRefund,
            shipping_refunded: true,
            tax_refunded: true,
            price: item.price,
            name: item.name,
            shipping: item.shipping,
            tax: item.tax,
            memo: memo,
            key: item.key,
        });
    },[maxRefund, memo, item, addRefundItem]);  

    /**In case props aren't loaded on mount, things dependent on props */
    useEffect(()=>{
        mountedRef.current = true

        calculateShipping();
        calculateTax();

        if (item && mountedRef.current === true) {
            setMaxRefund((parseFloat(item.price) + (item.shipping ? item.shipping : 0)));
            setIndex(i);
            if (fullCheck) resetItemsForFull();
        }
        
        return() => mountedRef.current = false
    },[i, fullCheck, item, calculateShipping, calculateTax, resetItemsForFull]);


    /**When things are checked, apply values to refundAmount */
    useEffect(()=>{
        mountedRef.current = true
    
        applyShippingAndTax();
        
        return()=>{
            mountedRef.current=false;
        }
    }, [checked, applyShippingAndTax])

        
    /**Creating the item for passing up to the parent component */
    useEffect(()=>{
        if(mountedRef.current === true){
            resetConfirm(false);
            let confirmedAmount;
            if(checked.half) confirmedAmount = parseFloat(item.price/2);
            if(checked.full) confirmedAmount = parseFloat(item.price);
            if(checked.custom) confirmedAmount = parseFloat(refundAmount);
            addRefundItem({
                id: item.id,
                item_id: item.item_id,
                total_refund_amount: confirmedAmount,
                shipping_refunded: checked.shipping,
                tax_refunded: checked.tax,
                price: item.price,
                name: item.name,
                shipping: item.shipping,
                tax: item.tax,
                memo: memo,
                key: item.key,
            });
        }
    },[refundAmount, memo, checked, item, resetConfirm, addRefundItem]);

    const checkHandler = useCallback(e => {
        const _values = {half: false, full: false, shipping: false, tax: false, remove: false,  custom: false};
        _values[e.target.name] = e.target.checked;
        setChecked(_values);
    }, []);

    const checkTotal = useCallback(e => {
        const tax = +item.tax || 0;
        const shipping = +item.shipping || 0;
        const price = +item.price || 0;
        checkHandler({target: {name: "custom", checked: +e.target.value > 0}});
        if (+e.target.value > price + tax + shipping) setRefundAmount(price + tax + shipping);
        else setRefundAmount(e.target.value);
    }, [item, checkHandler]);

    return (
        <>
            <Accordion.Toggle id={"refund-accordion-toggle"} as={Card.Header} eventKey={index} onClick={e=>{setAccordionToggle(!accordionToggle)}}>
                <Row>
                    <Col sm={12} lg={10} className="py-2">
                        <Stack direction="horizontal" spacing={2}>
                            <i className={`ms-1 me-4 far fa-chevron-${accordionToggle ? "up" : "down"}`}/>
                            <div> 
                                <span className="bold">{item.name.substring(0, 20)}{item.name.length > 30 && "..."}</span><br/>
                                <span className="small">Max Refund: ${(maxRefund + taxTotal + shippingTotal).toFixed(2)}</span>
                            </div>
                        </Stack>
                    </Col>
                    <Col sm={12} lg={2} className="py-2 text-right">
                        Refund for this item:<br/>
                        ${totalRefund}
                    </Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index}>
                <Container className="mt-2">
                    <Row className="ms-3">
                        <Col sm={12} lg={6}>
                            <div className={styles["info-pair"]}>
                                <span>Total Price:</span>
                                <span className="text-right">${item.price}</span>
                            </div>
                            {item.shipping > 0 &&
                                <div className={styles["info-pair"]}>
                                    <span>Shipping:</span>
                                    <span className="text-right">${(item?.shipping || 0).toFixed(2)}</span>
                                </div>
                            }
                            <div className={styles["info-pair"]}>
                                <span>Tax:</span>
                                <span className="text-right">${(item.tax || 0).toFixed(2)}</span>
                            </div>
                            <div className={styles["info-pair"]}>
                                <div>
                                    Remove Item
                                    <Tooltip direction="right" text="If you change your mind about refunding an individual item, you may mark this box to remove it from the refund.">
                                        <i className="far fa-question-circle ms-1" />
                                    </Tooltip>
                                </div>
                                <div className="text-right">
                                    <Form.Check 
                                        id="remove-item"
                                        name="remove"
                                        type="checkbox" 
                                        value={checked.remove}
                                        checked={checked.remove}
                                        onChange={checkHandler}
                                    />
                                </div>
                            </div>
                            <Form.Group controlId="refund-memo">
                                <Form.Label>Refund Memo</Form.Label>
                                <Form.Control type="text" name="refund-memo" value={memo} onChange={(e)=>setMemo(e.target.value)}/>
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <div className={styles["info-pair"]}>
                                <div>Refund Full Amount</div>
                                <div className="text-right">
                                    <Form.Check 
                                        id="refund-full"
                                        name="full"
                                        type="checkbox" 
                                        value={checked.price}
                                        checked={checked.full}
                                        //disabled={item.full} 
                                        onChange={checkHandler}
                                    />
                                </div>
                            </div>
                            <div className={styles["info-pair"]}>
                                <div>Refund Half Amount</div>
                                <div className="text-right">
                                    <Form.Check 
                                        id="refund-half"
                                        name="half"
                                        type="checkbox" 
                                        value={Math.round((item.price/2)*100)/100} 
                                        checked={checked.half}
                                        //disabled={item.half} 
                                        onChange={checkHandler}
                                    />
                                </div>
                            </div>
                            {item.shipping > 0 &&
                                <div className={styles["info-pair"]}>
                                    <div>Refund Shipping</div>
                                    <div className="text-right">
                                        <Form.Check 
                                            id="shipping-check"
                                            name="shipping"
                                            type="checkbox" 
                                            value={checked.shipping}
                                            checked={checked.shipping}
                                            //disabled={item.shipping} 
                                            onChange={checkHandler}
                                        />
                                    </div>
                                </div>
                            }
                            <div className={styles["info-pair"]}>
                                <div>Refund Tax</div>
                                <div className="text-right">
                                    <Form.Check 
                                        id="tax-check"
                                        name="tax"
                                        type="checkbox" 
                                        value={checked.tax}
                                        checked={checked.tax}
                                        disabled={!item.tax} 
                                        onChange={checkHandler}
                                    />
                                </div>
                            </div>
                            <Form.Group controlId="refund-custom-input" className={`${styles["colspan"]}`}>
                                <Form.Label>Custom Refund Amount</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control 
                                        type="number" 
                                        name="refund-custom-input" 
                                        min="0"
                                        max={item.price + item.tax + item.shipping}
                                        value={refundAmount} 
                                        onChange={checkTotal}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Accordion.Collapse>
            <hr/>
        </>
    )
}
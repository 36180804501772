import React, {useState, useEffect} from 'react';
import {Container, Button} from 'react-bootstrap';

import styles from '../Wizard.module.scss';

export const ByItem = (props) => {
    const {stepUpdate} = props;
    const [selected, setSelected] = useState(props?.stepData?.all_items || 1);

    const clickHandler = all_items => setSelected(all_items);

    useEffect(()=>{
        stepUpdate(props.stepId, {all_items: selected});
    },[stepUpdate, props.stepId, selected]);        
    
    return (
        <Container fluid>
            <h4 className={`${styles.title}`}>Do you want to refund all items or specific ones?</h4>
            <hr/>
            <Button variant={selected === 1 ? "primary" : "light"} size="lg" onClick={e=>clickHandler(1)}>All Items</Button>
            <Button variant={selected === 2 ? "primary" : "light"} size="lg" onClick={e=>clickHandler(2)}>Specific Items</Button>
        </Container>
    );
}
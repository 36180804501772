import React,{useState, useEffect} from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import FormGroupText from '../FormGroupText/FormGroupText';
import FormGroupButton from '../FormGroupButton';

import APIUsers from '../../../../../api/Users';

const Forgot =  React.forwardRef((props, ref) => {
    const {close} = props;

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }
    
    const user = useSelector(state => state.auth.user);
    const [error, setError] = useState();
    const [validated, setValidated] = useState(false);    
    const [submitting, setSubmitting] = useState(false);
    const [body, setBody]=useState();

    const onboardingFormat = props?.onboarding || false;

    useEffect(() => {
        return () => {
            setError(null);
            setValidated(false);
            setSubmitting(false);
            setBody(null);
        }
    }, []);

    const closeHandler = () => {
        if (close) close();
        else setBody(null);
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setSubmitting(true);
        setValidated(true);

        const form = e.currentTarget;
        if (form.checkValidity() === true) {
            const formData = new FormData(form);
            const formDataObj = Object.fromEntries(formData.entries());

            let res;
            if (props.type === 1) res = await APIUsers.forgotUser({email:formDataObj.email});
            else res = await APIUsers.forgotPassword({username:formDataObj.username});

            if (!res.errors){
                setBody(
                    <>
                        <h1>Check your email!</h1>
                        {props.type === 1 && <p>We just sent you an email with all user names registered under your email address.</p>}
                        {!props.type && <p>We just sent you an email with a password reset link for user <span className="bold">{formDataObj.username}</span>.</p>}
                        <p>Please follow the instructions in it to succesfully reset your password.</p>
                        <p><FormGroupButton page_id={props.page_id} label="Back to Sign In" disabled={submitting} click={closeHandler} /></p>
                    </>
                );
            } else setError(res.errors);
        }
        setSubmitting(false);
    }

    if (user?.profile?.id && !props.is_preview && !onboardingFormat){
        return null;
    }

    if (body) return body;

    return (
        <Form
            ref={ref}
            onSubmit={submitHandler} 
            className={`forgot_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' ')}
            style={props.style} 
            onClick={preview_click}
            noValidate 
            validated={validated}>
            <Row>
                <Col sm={12}>
                    { 
                        // forgot username
                        props.type === 1 &&
                            <FormGroupText page_id={props.page_id} name="email" type="email" label="Enter your email" required feedback="Please enter your email" disabled={submitting} />
                    }

                    {
                        // forgot password
                        !props.type && 
                            <FormGroupText page_id={props.page_id} name="username" type="text" label="Enter your user name" required feedback="Please enter your user name" disabled={submitting} />
                    }
                </Col>
                <Col sm={12} md={true}>
                    <FormGroupButton page_id={props.page_id} label="Send" disabled={submitting} click={props.is_preview?"Button":"Submit Form"} />
                    <FormGroupButton page_id={props.page_id} label="Back to Sign In" disabled={submitting} click={closeHandler} />
                </Col>
                {error && 
                    <Col sm={12} className="error-text-ctr">
                        {error}
                    </Col>
                }
            </Row>
        </Form>
    );
});

export default Forgot;
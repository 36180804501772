import React from 'react';
import {Rect} from 'react-konva';

export const Rectangle = (props) => {
  return (
        <Rect
          x={props.x}
          y={props.y}
          width={props.width}
          height={props.height}
          fill={props.fill}
          opacity={props.opacity}
          strokeWidth={props.stroke}
          stroke={props.strokeColor}
          onSelect={props.onSelect}
          onClick={props.onClick}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        />
  );  
}
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    user_event_role: { id: "", role_id: "" },
    user_event_status: { id: "", status_id: "" }
};

const eventReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_NEW_EVENT_USER_ROLE:
            return {
                ...state,
                user_event_role: action.user_event_role
            }
        case actionTypes.SET_NEW_EVENT_USER_STATUS:
            return {
                ...state,
                user_event_status: action.user_event_status
            }
        default:
            return state;
    }
};

export default eventReducer;
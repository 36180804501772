import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { startOfYear, subYears} from 'date-fns';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const INVALID_AFTER_DATE = startOfYear(subYears(new Date(), 1));    // start of previous calendar year - no babies on the site

/** DatePicker when uses for Date of Birth
 * @param {()} selected is the Date object of the dob
 * @param {()} onChange is the function to set the dobValue
 * @param {()} placeholderText is an optional override for the text displayed in the input
 * @param {()} disabled is the optional boolean to disable this input
*/
export const DobDatePicker = ({ selected, onChange, placeholderText="Select Date of Birth",required=true, disabled=false}) => {

    const [ error, setError ] = useState('');

    const onSelectDate = (date) => {
        if (date) {
            // don't allow dates from this year, babies are not signing up for this service
            if (date > INVALID_AFTER_DATE) {
                setError("Please enter a valid birth date.");
            } else {
                onChange(date);
                setError();
            }
        }
    }

    return (
        <>
            <DatePicker
                required={required}
                dateFormat="MM/dd/yyyy"
                minDate={new Date("01/01/1900")}
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown={true}
                selected={selected}
                onChange={onSelectDate}
                peekNextMonth={false}
                placeholderText={placeholderText}
                customInput={
                    <Form.Control type="text" name="dob" />
                }
                disabled={disabled}
                onFocus={() => setError()}
            />
            {error &&
                <span className="dob-error error-text">{error}</span>
            }
        </>
    );

}
import Request from './Api';
//import {stringToUUID} from './Common';

// get company(s)
const getall = async (props) => {
    return (
        Request({
            url: "/company/getall",
            data: props,
            //test: {data:company}  // send mock data to simulate an api call
        })
    );
}

const get = async (props) => {

    // use test data on localhost only
    //let company={};
    //if (["localhost", "127.0.0.1", ""].includes(window.location.hostname)){

        // gets mock data for testing - will be removed when api is ready
        //let mockdata = await test();

        /*if (!props) company=mockdata;
        else {
            mockdata.forEach((item,i,test) => {
                if (props.id) {
                    if (item.id===parseInt(props.id)){
                        company=test[i];
                        return false;
                    }
                }
            });
        }*/
    //}
    
    return (
        Request({
            url: "/company" + (!props ? "/get" : "/company/"+props.id),
            data: props,
            //test: {data:company}  // send mock data to simulate an api call
        })
    );
}

// create with wizard
const wizard = async (props) => {
    return (
        Request({
            url: "/company/wizard",
            data: props,
            method: "POST"
        })
    );
}

// create
const create = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Company Name is required", "Email is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/company/create",
            data: props,
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// update
const update = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Company Name is required", "Email is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/company/edit",
            data: props,
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// add user to company
const addUser = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["User id is required"]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/company/users/add",
            data: {props},
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// get users in company
const getUsers = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    /*let mockdata = {
        data:[
            {
                id: 1,
                first_name: "Tom",
                last_name: "Huerter",
                role_id: 1,
                role_name: "Admin"
            },
            {
                id: 2,
                first_name: "Joe",
                last_name: "Gero",
                role_id: 1,
                role_name: "Admin"
            },
            {
                id: 3,
                first_name: "Will",
                last_name: "Pedicone",
                role_id: 1,
                role_name: "Admin"
            },
            {
                id: 4,
                first_name: "David",
                last_name: "Oppenheim",
                role_id: 1,
                role_name: "Admin"
            },
            {
                id: 5,
                first_name: "Leo",
                last_name: "Castro",
                role_id: 1,
                role_name: "Admin"
            },
            {
                id: 6,
                first_name: "Raymond",
                last_name: "Elias",
                role_id: 1,
                role_name: "Admin"
            }
        ],
        errors:null
    };*/
    
    return (
        Request({
            url: "/company/users",
            data: props,
            //test: mockdata // send mock data to simulate an api call
        })
    );
}



// sets up mock data
/*const test = async() => {
    return new Promise((resolve, reject) => {
        let dummydata=[
            {
                id: 1,
                name: "Impact Athletics",
                owner_name: "Tom Huerter",
                email: "info@impactathleticsny.com",
                primary_number: "+1234567890",
                fax_number: "+1234567890",
                address_street: "Route 146",
                address_street2: "",
                address_city: "Halfmoon",
                address_state: "NY",
                address_postcode: "12065",
                api_key: stringToUUID("1"),
            },
            {
                id: 2,
                name: "WizardPig",
                owner_name: "David Oppenheim",
                email: "info@wizardpig.com",
                primary_number: "+1 413-650-9300",
                fax_number: "+1 413-650-9300",
                address_street: "115 East St.",
                address_street2: "",
                address_city: "Easthampton",
                address_state: "MA",
                address_postcode: "01027",
                api_key: stringToUUID("2")
            },
        ];

        let promises=[];
        for (let i=0;i<dummydata.length;i++){
            promises.push(getUsers()
            .then(users =>{
                dummydata[i].users=users.data;
            }).catch(e => console.error(e)));
        }

        Promise.all(promises)
        .then(response => {
            resolve(dummydata);
        }).catch(e => console.error(e));

    });
}*/

const getConfig = async (props) => {
    return (
        Request({
            url: "/company_config",
            data: props,
            method: "POST"
        })
    );    
};

const urls = async (props) =>{
    return(
        Request({
            url: "/company/urls",
            data: props,
            method: "POST"
        })
    )
}

const Companies = {
    get, getall, wizard, create, update, addUser, getUsers, getConfig, urls //, delete, etc. ...
}
  
export default Companies;
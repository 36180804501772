import React, {useState, useEffect, useCallback, useRef} from 'react';
import {Row, Col, Container, Card, Form, InputGroup, Button} from 'react-bootstrap';

import APIGiftCards from '../../../../../api/GiftCards';
import styles from "./GiftCard.module.scss";

const paymentMethodName = "Gift Card";

export const GiftCard = (props) => {
    const {onPaymentUpdate, onPaymentMethodChange} = props;

    const firstLoad = useRef(true);
    const gcRef = useRef();
    const ref = useRef();

    const [giftCards, setGiftCards] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();

    const checkHandler = useCallback(async e => {
        e.preventDefault();

        const code = gcRef?.current?.value;
        if (!code) return;

        setSubmitting(true);
        
        const res = await APIGiftCards.get({card_code: code, with_balance: 1});
        if (res?.errors) setError(res.errors);
        else if (res?.data?.giftcards?.data.length) {
            setError(null);
            setGiftCards(prev=>{
                const _prev = [...prev];
                if (!_prev.find(a=>+a.id===+res.data.giftcards.data[0].id)) _prev.push(res.data.giftcards.data[0]);
                return [..._prev];
            });
        }
        gcRef.current.value = "";
        gcRef.current.defaultValue = "";
        setSubmitting(false);
        ref.current.querySelector('input[name="card_code"]').focus();        
    }, []);

    const removeHandler = useCallback(async (e, id) => {
        e.preventDefault();
        setGiftCards(prev=>{
            const _prev = [...prev];
            const index = _prev.findIndex(a=>+a.id===+id);
            if (index>=0) _prev.splice(index, 1);
            return [..._prev];
        });
    }, []);

    const keyUpHandler = useCallback(e => {
        if (e.key === "Enter") {
            e.preventDefault();
            checkHandler(e);
            return false;
        }
    }, [checkHandler]);

    useEffect(()=>{
        if (props.payments && firstLoad.current){
            const payment = props.payments.filter(a=>a.payment_method_id === props.payment_method_id);
            if (payment.length > 0) setGiftCards(payment);
        }
    }, [props.payments, props.payment_method_id]);

    useEffect(() => {
        const _data = [];
        if (giftCards.length > 0){
            giftCards.forEach(g=>{
                _data.push({
                    payment_method_id: props.payment_method_id,
                    amount: +g.current_balance,
                    payment_method_name: `${paymentMethodName} ${g.card_code}`, 
                    ...g
                });
            });
        }
        onPaymentUpdate(props.payment_method_id, _data);
    }, [props.payment_method_id, giftCards, onPaymentUpdate]);

    useEffect(()=>{
        if (firstLoad.current) {
            onPaymentMethodChange(props.payment_method_id);
        }
    }, [props.payment_method_id, onPaymentMethodChange]);

    useEffect(()=>{
        if (gcRef.current) gcRef.current.focus();
        
        if (firstLoad.current) {
            firstLoad.current = false;    
            return;
        }

        return () => {
            setGiftCards([]);
            setSubmitting(false);
            setError(null);
        }
    }, []);


    return (
        <Container fluid data-cy="checkout-giftcard-payment">
            <Row>
                <Col sm={12} lg="6" ref={ref}>
                    <Form.Group controlId="card_code">
                        <Form.Label>Gift Card Code</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" name="card_code" ref={gcRef} defaultValue="" disabled={submitting || props.submitting} onKeyUp={keyUpHandler} />                        
                            <InputGroup.Append>
                                <Button variant="light" size="small" disabled={submitting || props.submitting} onClick={checkHandler}>
                                    <i className="far fa-check" />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form.Group>
                    {error && <div className="error-text mb-3">{error}</div>}
                </Col>
                <Col sm={12} lg="6">
                    {giftCards?.map((giftCard, i) => (
                        <Card className={styles.wrapper} key={`giftc-${giftCard.id}-${i}`}>
                            <Card.Body>
                                <div className={styles.title} data-cy="gift-card-code">{giftCard.card_code}</div>
                                <div>
                                    Balance: <span className="bold" data-cy="gift-card-balance">${giftCard.current_balance.toFixed(2)}</span>
                                </div>
                                <div className={`text-link ${styles.toolbar}`}>
                                    <Button variant="light" disabled={submitting || props.submitting} onClick={e=>removeHandler(e, giftCard.id)} data-cy="gift-card-remove-btn">Remove</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    ))}
                </Col>
            </Row>
        </Container>
    );
}
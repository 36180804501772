import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Row, Col, Container, Form, InputGroup} from 'react-bootstrap';

import CCFields from './CCFields';
import {getStyles} from './thunks';
import APIStates from '../../../../../api/States';

const paymentMethodName = "Credit Card";

export const Card = (props) => {
    const {onPaymentUpdate, onPaymentMethodChange} = props;

    const firstLoad = useRef(true);
    const billingRef = useRef();
    const ccRef = useRef();

    const user = useSelector(state=>state.pos[props.register_id].user);    

    const [totalPayment, setTotalPayment] = useState(0);  
    const [states, setStates] = useState();
    const [billData, setBillData] = useState();
    const [error, setError] = useState({});
    const [validated, setValidated] = useState(false);
    const [fields, setFields] = useState([
        {first_name: false},
        {last_name: false}, 
        {bill_address1: false}, 
        {bill_city: false}, 
        {bill_state: false}, 
        {bill_postalcode: false}, 
        {ccnumber: false}, 
        {ccexp: false}, 
        {cvv: false},
        {amount: false}
    ]);

    const processCreditCardEntry = useCallback((payment_token = null, amount = 0) => {
        if (onPaymentUpdate) {
            onPaymentUpdate(props.payment_method_id, [{
                collectJS: window.CollectJS,
                payment_token: payment_token,
                amount: +amount || 0,
                payment_method_name: `${paymentMethodName}`,
                ...billData
            }]);
        }
    },[onPaymentUpdate, billData, props.payment_method_id]);

    const validateFields=useCallback((field, status)=>{
        setFields(prev=>{
            let _fields = [...prev];

            // load prefilled fields
            if (billingRef.current && (field!=="ccnumber" && field!=="ccexp" && field!=="cvv")){
                _fields.forEach(f=>{
                    const _key = Object.keys(f)[0];
                    const _field = billingRef.current.querySelector(`input[name='${_key}']`);
                    if (_field){
                        f[_key] = !!_field.value;
                    }
                });
            }
    
            switch(field){
                case "ccnumber":
                case "ccexp":
                case "cvv":
                    const idx1 = _fields.findIndex(a=>Object.keys(a)[0]===field);
                    if (idx1>-1) _fields[idx1][field] = status; // this is returned by collectjs
                    break;
                case "first_name":
                case "last_name":
                case "bill_address1":
                case "bill_city":
                case "bill_state":
                case "bill_postalcode":
                case "amount":
                    const idx2 = _fields.findIndex(a=>Object.keys(a)[0]===field);
                    if (idx2>-1) _fields[idx2][field] = !!status; // status holds the value of the field, but we need to convert it to a boolean
                    break;
                default:
                    break;
            }
            const is_valid=_fields.filter(f=>!f[Object.keys(f)[0]]).length===0;
            setValidated(is_valid);
            return _fields;    
        });
    },[]);

    const changeHandler = useCallback((e, field) => {
        setBillData({...billData, [field]: e.target.value});
        validateFields(field, e.target.value);
        if (e.target.name === "amount") setTotalPayment(e.target.value);
    },[billData, validateFields]);

    useEffect(()=>{
        if (props?.totals && !totalPayment){
            setTotalPayment(props.totals.estimated_balance || 0);
        }
    }, [props.totals, totalPayment]);

    useEffect(()=>{
        if (user && !billData && firstLoad.current){
            const _billdata={};
            if (user.first_name) _billdata.first_name = user.first_name;
            if (user.last_name) _billdata.last_name = user.last_name;
            if (user.address1) _billdata.bill_address1 = user.address1;
            if (user.address2) _billdata.bill_address2 = user.address2;
            if (user.city) _billdata.bill_city = user.city;
            if (user.state) _billdata.bill_state = user.state;
            if (user.postal_code) _billdata.bill_postalcode = user.postal_code;
            setBillData(_billdata);
        }
    },[user, billData]);

    useEffect(()=>{
        if (validated){
            const price = billingRef.current.querySelector("input[name='amount']");
            if (price > totalPayment) price.value = totalPayment;
            processCreditCardEntry(null, +price.value || 0);
        }
    },[processCreditCardEntry, validated, totalPayment]);

    useEffect(()=>{
        if (firstLoad.current && window.CollectJS && ccRef.current && billingRef.current) {
            const css_styles = getStyles();

            const price = billingRef.current.querySelector("input[name='amount']");
            if (price > totalPayment) price.value = totalPayment;

            window.CollectJS.configure({
                variant: 'inline',
                styleSniffer: true,
                price: +price.value || undefined,
                currency: 'USD',
                country: 'US',
                callback: (token) => {
                    //processCreditCardEntry(token, price.value || 0);
                },
                validationCallback: (field, status)=>{
                    validateFields(field, status);
                },
                fieldsAvailableCallback: ()=>{
                    validateFields("ccnumber", false);
                    validateFields("cvv", false);
                    validateFields("ccexp", false);
                },                
                googleFont:css_styles.font,
                customCss: `{
                    "background-color": "${css_styles.backgroundColor}",
                    "color": "${css_styles.color}",
                    "font-family": "${css_styles.fontFamily}",
                    "font-size": "${css_styles.fontSize}",
                    "font-weight": "${css_styles.fontWeight}",
                    "line-height": "${css_styles.lineHeight}",
                    "border-width": "${css_styles.borderSize}",
                    "border-style": "${css_styles.borderStyle}",
                    "border-color": "${css_styles.borderColor}",
                    "border-radius": "${css_styles.borderRadius}",
                    "padding": "${css_styles.padding}",
                    "width": "${css_styles.width}",
                    "height": "auto !important"
                }`,
                fields: {
                    ccnumber: {
                        placeholder: 'CC Number',
                        selector: '#bill_ccnumber',
                        title: 'Credit Card Number',
                        enableCardBrandPreviews:false,
                    },
                    ccexp: {
                        placeholder: 'MM / YY',
                        selector: '#bill_ccexp',
                        title: 'Credit Card Expiration'
                    },
                    cvv: {
                        placeholder: 'CVV',
                        selector: '#bill_cvv',
                        title: 'CVV'
                    }
                }
            });
        }
    },[onPaymentUpdate, validateFields, totalPayment]);

    useEffect(()=>{
        if (firstLoad.current) {
            onPaymentMethodChange(props.payment_method_id);
            setStates(APIStates.get());
        }
    }, [props.payment_method_id, onPaymentMethodChange]);    

    useEffect(()=>{
        if (billingRef.current) {
            billingRef.current.querySelector('input[name="first_name"]').focus();
        }
        
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }
    }, []);

    return (
        <Container fluid data-cy="checkout-card-payment">
            <Row className="d-flex align-items-center">
                <Col sm={12} lg={12}>
                    <Row ref={billingRef}>
                        <Col sm={12} lg={6}>
                            <Form.Group controlId="first_name">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" name="first_name" defaultValue={billData?.first_name || ""} disabled={props.submitting} onChange={e => changeHandler(e, "first_name")} />
                                {error?.first_name && <div className="error-text mb-3">{error.first_name}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group controlId="last_name">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" name="last_name" defaultValue={billData?.last_name || ""} disabled={props.submitting} onChange={e => changeHandler(e, "last_name")} />
                                {error?.last_name && <div className="error-text mb-3">{error.last_name}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group controlId="bill_address1">
                                <Form.Label>Address Line #1</Form.Label>
                                <Form.Control type="text" name="bill_address1" defaultValue={billData?.address1 || ""} disabled={props.submitting} onChange={e => changeHandler(e, "bill_address1")} />
                                {error?.bill_address1 && <div className="error-text mb-3">{error.bill_address1}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group controlId="bill_address2">
                                <Form.Label>Address Line #2</Form.Label>
                                <Form.Control type="text" name="bill_address2" defaultValue={billData?.address2 || ""} disabled={props.submitting} />
                                {error?.bill_address2 && <div className="error-text mb-3">{error.bill_address2}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group controlId="bill_city">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" name="bill_city" defaultValue={billData?.city || ""} disabled={props.submitting} onChange={e => changeHandler(e, "bill_city")} />
                                {error?.bill_city && <div className="error-text mb-3">{error.bill_city}</div>}
                            </Form.Group>
                        </Col>                        
                        <Col sm={12} lg={6}>
                            <Form.Group controlId="bill_state">
                                <Form.Label>State</Form.Label>
                                <Form.Control as="select" custom aria-label="State" name="bill_state" defaultValue={billData?.state || ""} disabled={props.submitting} onChange={e => changeHandler(e, "bill_state")} >
                                    {states?.map(option => <option key={`bill_state-option-${option.id}`} value={option.short_name}>{option.name}</option>)}
                                </Form.Control>
                                {error?.bill_state && <div className="error-text mb-3">{error.bill_state}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group controlId="bill_postalcode">
                                <Form.Label>ZIP Code</Form.Label>
                                <Form.Control type="text" name="bill_postalcode" defaultValue={billData?.postal_code || ""} disabled={props.submitting} onChange={e => changeHandler(e, "bill_postalcode")} />
                                {error?.bill_postalcode && <div className="error-text mb-3">{error.bill_postalcode}</div>}
                            </Form.Group>
                        </Col>
                        <Col sm={12} lg={6}>
                            <Form.Group controlId="amount">
                                <Form.Label>Amount</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="number" min={0} name="amount" value={totalPayment || 0} disabled={props.submitting} onChange={e => changeHandler(e, "amount")} />
                                </InputGroup>
                                {error?.amount && <div className="error-text mb-3">{error.amount}</div>}
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} lg={12}>
                    <CCFields {...props} ref={ccRef}/>
                </Col>
            </Row>
        </Container>
    );
}
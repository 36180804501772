import React from 'react';
import { randomUUID } from '../../../../../utils/cms';

const Wysiwyg = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const _props={
        ref: ref, 
        id: props.is_preview ? `preview-${randomUUID()}` : props.id,
        className: `Wysiwyg_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' '),
        style: props?.style || null,
        onClick: preview_click
    }

    const content = props?.html || `<p>Add something <b>COOL</b> here...</p>`;

    return <div {..._props} dangerouslySetInnerHTML={{__html: content}}/>;
});

export default Wysiwyg;
import React, { useState, useEffect } from 'react'
import {useSelector} from 'react-redux'

import '../../../../../../src/assets/css/scss/pages.scss'

export const OrderSummary = (props) => {

    const splitPaymentErrors=useSelector(state => state.splitPayments.errors)
    const [summaryHideShow, setSummaryHideShow]=useState(false);

  return (
    <React.Fragment>
        <div className="split-payment-order-summary-page">
        {props?.type !== "multiMany" &&
            <p>Before beginning this process, ensure the order is complete and any coupons and adjustments have already been made.  Exiting this menu will result in the cancellation of a split payment and will require cash refunds to be conducted if cash payments were already made.  Card refunds will be executed automatically upon exit.  </p>
        }
            {props.currentOrder &&
            <div>
                <div>
                    <h4 onClick={()=>setSummaryHideShow(!summaryHideShow)}>
                        Order Summary
                        <i className="far fa-chevron-double-down text-company-primary" style={{paddingLeft: "1rem", fontSize:"1rem"}} />
                    </h4>

                    <h5>
                        <span>Total: ${props.currentOrder.total_price.toFixed(2)}</span>
                    </h5>
                    
                </div>
                {summaryHideShow &&
                <>
                    <div>
                    <h6>
                        <p className="sp-os-p1">- Subtotal: ${props.currentOrder.subtotal_price.toFixed(2)}</p>
                        {/*<p className="sp-os-p1">- Admin Fee Total: ${props.currentOrder.admin_fee_total.toFixed(2)}</p>*/}
                        <p className="sp-os-p1">- Tax: ${props.currentOrder.tax_total.toFixed(2)}</p>
                        {props.currentOrder?.price_adjustments?.map((adjustment, i)=>(
                             <p className="sp-os-p1" key={`pa-order-${i}`}>
                                - {adjustment.price_adjustment_type_name}: {adjustment.amount > 0 ? "" : "-"}${Math.abs(adjustment.amount).toFixed(2)}
                            </p>
                        ))}
                        <p className="sp-os-p1">- Total Coupons Applied: {props.currentOrder.coupons_applied.length}</p>
                        <span>
                            {props.currentOrder?.coupons_applied.map(coupon=>(
                                <p key={`sp-os-coupon-${coupon.id}`} className="sp-os-p2">{coupon.name}</p>
                                ))}
                        </span>
                        <p className="sp-os-p1">- Discounts: ${props.currentOrder.total_discount.toFixed(2)}</p> 
                    </h6>
                    </div>
                    <div>
                        <h5>Items:</h5>
                        {props.currentOrder.items?.map(item=>(
                            <React.Fragment key={`order-sum-id${item.id}`}>
                                <p className="sp-os-item">
                                    <span>{item.product_name}</span>
                                    <span>Final Price: ${item.final_price.toFixed(2)}</span>
                                    {item.tax ? <span>Tax: ${item.tax?.toFixed(2)}</span> : <span>Tax: $0.00</span>}
                                    <span>Number of Addons: {item.addons ? item.addons.length : 0 }</span>
                                </p>
                                <hr />
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="sp-error"> 
                        <span>{splitPaymentErrors?.noOrder}</span>
                        <span>{splitPaymentErrors?.noUser}</span>
                    </div>
                </>
                }
            </div>
            }
        </div>
    </React.Fragment>
  )
}

import React,{useState,useEffect} from 'react';
import { differenceInSeconds } from 'date-fns';
import { QrReader } from 'react-qr-reader';
import useSound from 'use-sound';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Products from '../../../../api/Products';
import beepSfx from './beep.mp3';

import './BarCode.css';

export const BarCode = (props) => {
    const click = props.click;

    const [barCodeData, setBarCodeData ]=useState();
    const [showBarCodeScan, setShowBarCodeScan] = useState(false);
    const [barCodeComponent,setBarCodeComponent]=useState();
    const [play] = useSound(beepSfx);

    const barCodeClickHandler=()=>{
        setShowBarCodeScan(!showBarCodeScan);
    }

    useEffect(()=>{
        if (showBarCodeScan){
            setBarCodeComponent(
                <QrReader
                    containerStyle={{ width: '300px', height: '230px' }}
                    onResult={(err, result) => {
                        if (result){
                            if (barCodeData?.timestamp){
                                if (differenceInSeconds(result.timestamp,barCodeData.timestamp)<=2){
                                    setBarCodeData(result.text);
                                }
                            } else setBarCodeData(result.text);
                        } 
                    }}
                />
            );
        } else setBarCodeComponent(null);
    },[showBarCodeScan,barCodeData]);


    useEffect(()=>{
        if (barCodeData){
            play();
            Products.get({ id: barCodeData })
            .then(response => {
                if (response.data){
                    click(response.data.products[0], 0);
                }
            }).catch(e =>console.error(e));
        }
    },[barCodeData,play,click]);

    useEffect(()=>{
        return ()=>{
            setBarCodeData(null);
            setShowBarCodeScan(false);
            setBarCodeComponent(null);
        }
    },[]);

    return (
        <Row>
            <Col lg="11">
                <Card style={{display:showBarCodeScan?"block":"none"}}>
                    <Row>
                        <Col sm="12" lg="8" className="barcode-scan">
                            {barCodeComponent}
                        </Col>
                        <Col sm="12" lg="4">
                            <h6 className="my-3">Place the item's QR / Barcode in front of the camera to scan it.</h6>
                            <p>{barCodeData || "Product Not Found"}</p>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col sm="12" lg="1" className="pl-0">
                <Button as="a" href="#!" size="sm" variant="light" className="btn-barcode"><i className="far fa-barcode-read" onClick={barCodeClickHandler} /></Button>
            </Col>
        </Row>
    );
}
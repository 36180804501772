import React, {useState, useEffect, useCallback, useRef} from 'react'
import { Button,Form } from 'react-bootstrap'

import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import Users from '../../../../api/Users';

//Called in src\containers\User\NotificationSettings\NotificationSettings.js

/**Props - 
 * @param email to prepopulate the field
 * @param phone to prepopulate the field
 * @param close to hide this component
 * @param userId for the update call
 * @param successError to pass to the parent with toast/error catcher so it's still visible even if this component isn't.
 */
export const UpdateChannels = (props) => {

    const mountedRef = useRef(false);
    let currentUser = JSON.parse(localStorage.getItem('user'))
    const [email, setEmail]=useState(props.email || "");
    const [phone, setPhone]=useState(props.phone || "");
    const [error, setError]=useState();

    useEffect(()=>{
        mountedRef.current = true;

        return()=> mountedRef.current = false;
    },[])

    const onSubmit=async(e)=>{
        e.preventDefault()
        
        let pass = checkRegEx();
        if(pass){
            try{
                let response = await Users.update({
                    id: props.userId,
                    mobile_phone: phone,
                    // email: email
                })
                if(!response.errors){
                    props.setEmail(email);
                    props.setPhone(phone);
                    if(props.userId === currentUser.profile.id) updateLocal() 
                    props.setNoReload(true);
                    props.triggerUserReset();
                    props.successError({success: "Update Successful"})
                    props.getUserNotificationSettings(phone, email)
                    props.close()
                }else if(response.errors){
                    props.successError({error: response.errors})
                }
            }catch(ex){
                console.error(ex)
            }
        }
    }

    const updateLocal=()=>{
        currentUser.profile.mobile_phone = phone
        currentUser.profile.email = email
        localStorage.setItem('user', JSON.stringify(currentUser))
    }

    const checkRegEx=()=>{
        let pass = true
        let error = ""
        //tests that phone number contains numbers ( ) - . +
        const phoneRegEx =/^[0-9\50\51\53\55\56]*$/.test(phone)
        //tests that email contains an @ symbol and a . 
        if(!email.includes("@") || !email.includes(".")){
            pass = false
            error += "You must include a valid email address."
        }if(!phoneRegEx){
            pass=false
            error += "You must include a valid telephone number."
        }
        if(error !== "") setError(<ErrorCatcher error={error} />)
        return pass;
    }

  return (
    <>
        <p className="mt-3">
            If you update the contact settings, you will have to sign back up for notifications.        
        </p>
        <Form onSubmit={onSubmit} className="update-channel-form">

            <Form.Group controlId="name">
                <Form.Label>Phone Number {props.phone ? `(Current Number) - ${props.phone}` : null} </Form.Label>
                <Form.Control required type="text" name="changeNumber" placeholder="+1 (000) 000-0000" onChange={(e)=>setPhone(e.target.value)} />
            </Form.Group>

            {/* <label htmlFor="changeEmail">
                <i className="far fa-envelope text-bc-blue" />
                {" "}
                Email
            </label>
            <input 
                className="focus-glow"
                name="changeEmail" 
                value={email}
                placeholder="E-mail Address" 
                onChange={(e)=>setEmail(e.target.value)}
            /> */}
            <br />
            <Button type="submit">
                Save Changes
            </Button>
        </Form>
        {error}
    </>
  )
}

import React, { useState, useEffect, useRef, useContext } from 'react'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Button } from 'react-bootstrap';

import { FileURLContext } from '../../../../contexts/FileURLContext';
import FullPageReceipt from '../FullPageReceipt';
import { useSelector } from 'react-redux';

//findMessageReciept will add the message to the receipt or invoice based on the register or company settings.  
//The register or register group needs to have the custom fields "receipt_message" and "receipt_message_location".  Company config also have these fields available in the company config.  
//The location can only accept "top" or "bottom".  If no value is provided, it will default to "top".  
//Order of priority is as follows - the individual register takes priority, then register groups will override the company config settings.  Company config settings will produve the message to ALL receipts and invoices.  

export const PrintFullPage = (props) => {
    const {onAfterPrint, onBeforePrint, onBeforeGetContent, onPrintError} = props;

    const promiseResolveRef = useRef();
    const printRef = useRef();
    const [userDetails, setUserDetails] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const [isPrinting, setIsPrinting] = useState(false);
    
    const company = useSelector(state=>state.company);
    const registerInfo = useSelector(state=>state.pos[props?.order?.register_id] || null);
    const imageURL = useContext(FileURLContext);
    const companyLogo = imageURL.logo;

    // const paymentMethod="Online Transaction";

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) promiseResolveRef.current();
    }, [isPrinting]);    

    useEffect(()=>{
        if(props.userDetails) setUserDetails(props.userDetails);
        if(props.order) {
            setPaymentMethod(props.order.payment_method)
        }
        if(userDetails && props.order && props.transSuccess && props.setPrinterHasProps) props.setPrinterHasProps(true);
    },[props, userDetails]);

    const handleOnAfterPrint = () => {
        setIsPrinting(false);
        if(onAfterPrint) onAfterPrint();
    }

    const findReceiptMessage=()=>{
        let message = {
            message: null,
            location: "top"
        };
        if(registerInfo?.registerInfo?.register_definition?.receipt_message) message.message = registerInfo.registerInfo.register_definition.receipt_message;
        else if (registerInfo?.register_group?.register_group_definition?.receipt_message) message.message = registerInfo?.register_group?.register_group_definition?.receipt_message;
        else if(company?.config?.receipt_message) message.message = company.config.receipt_message;
        if(registerInfo?.registerInfo?.register_definition?.receipt_message) message.location = registerInfo.registerInfo.register_definition.receipt_message_location || "top";
        else if(registerInfo?.register_group?.register_group_definition?.receipt_message_location) message.location = registerInfo?.register_group?.register_group_definition?.receipt_message_location || "top";
        else if(company?.config?.receipt_message_location) message.location = company.config.receipt_message_location || "top";
        
        return message;
    }

    const receiptMessage = findReceiptMessage();

    return (
        <>
            {!props.preview &&
                <ReactToPrint 
                    trigger={props.print ? undefined : ()=><Button variant="light">{props.btnText ? <span>{props.btnText}</span> : <span>Print Order</span>}</Button>}
                    documentTitle={`Order #${props.order.id} - ${company.name}`}
                    content={()=>printRef.current}
                    onAfterPrint={handleOnAfterPrint}
                    onBeforePrint={onBeforePrint || undefined}
                    onBeforeGetContent ={() => {
                        return new Promise((resolve) => {
                            promiseResolveRef.current = resolve;
                            setIsPrinting(true);
                            if (onBeforeGetContent) onBeforeGetContent();
                        });
                    }}
                    onPrintError={onPrintError || undefined}
                >
                    {props.print && printRef.current &&
                        <PrintContextConsumer>
                            {({ handlePrint }) => handlePrint(null, () => printRef.current)}
                        </PrintContextConsumer>
                    }
                </ReactToPrint>
            }
            <div style={{display: props.preview ? "block" : "none"}}>
                <FullPageReceipt
                    ref={printRef}
                    type={props.type || "invoice"}
                    company={company}
                    companyLogo={companyLogo}
                    paymentMethod={paymentMethod || null}
                    userDetails={userDetails || null}
                    order={props.order || null}
                    items={props.items || []}
                    transaction={props.transaction || null}
                    refunded={props.refunded || null}
                    partialRefund={props.partialRefund || null}
                    parentOrder={props.parentOrder || null}
                    extraContent={props.extraContent || null}
                    setPrinterHasProps={props.setPrinterHasProps || null}
                    receiptMessage={receiptMessage}
                />
            </div>
        </>
    );
}

import Request from './Api';

const get = (props) => {
    return (
        Request({
            url: props?.my?"/cms/my_theme":`/cms/theme${props?.id?"/"+props.id:""}`,
            data:props,
            method: "GET",
        })
    );
}

const parse = (content) => {
    let variables=[];
    let styles="";

    if (content?.variables){
        content.variables.forEach((v,i)=>{
            variables.push({"name":v.name,"value":v.compiledValue || v.value});
        });
    }
    if (content?.styles) styles=content.styles;

    return {variables:[...variables],styles:styles};
}

const Themes = {
    get, parse
}
  
export default Themes;
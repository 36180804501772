import React from 'react';
import {Form} from 'react-bootstrap';

export const CustomFields = props => {
    const {onChange} = props;

    const clickHandler = e => {
        //e.preventDefault();
        e.stopPropagation();
    }

    if (props.customFields.length===0 || !props?.member?.id) return null;

    return (
        <div>
            {props.customFields.filter(a=>a.placeholder_text).map((item, i) =>(
                <Form.Group controlId={`${item.name}_${props.member.id}`} key={`custom-field-group-${props.id}-${props.member.id}-${item.id}-${i}`}>
                    <Form.Label>{item.placeholder_text}</Form.Label>
                    {item.custom_field_type === "select" &&
                        <Form.Control 
                            as="select" 
                            custom name={`custom_${item.name}_${props.member.id}`} 
                            placeholder={item.placeholder_text} 
                            required={item.required?true:undefined} 
                            onChange={onChange} 
                            onBlur={onChange}
                            onClick={clickHandler}
                            value={props.stepValues?.customFields?.[props.member.id]?.find(f => `${f.custom_field_id}` === `${item.name}`)?.value || ""} 
                        >
                            <option key={`custom-select-${item.id}-${props.member.id}-x`} value="" onClick={clickHandler}></option>
                            {item.options.map((option, i) => (
                                <option key={`custom-select-${item.id}-${props.member.id}-${i}`} value={option.value} onClick={clickHandler}>{option.text}</option>
                            ))}
                        </Form.Control>
                    }
                    {item.custom_field_type === "input" &&
                        <Form.Control 
                            type="text" 
                            required={item.required?true:undefined} 
                            name={`custom_${item.name}_${props.member.id}`} 
                            placeholder={item.placeholder_text} 
                            defaultValue={props.stepValues?.customFields?.[props.member.id]?.find(f => `${f.custom_field_id}` === `${item.name}`)?.value || ""} 
                            onChange={onChange} 
                            onBlur={onChange} 
                            onClick={clickHandler}/>
                    }
                </Form.Group>
            ))}
        </div>
    );
}
import React from 'react';
import { Card as BSCard } from 'react-bootstrap';
import { randomUUID } from '../../../../../utils/cms';

const Card = React.forwardRef((props, ref) => {
    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const _props={
        ref: ref, 
        id: props.is_preview ? `preview-${randomUUID()}` : props.id,
        className: `Card_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' '),
        style: props?.style || null,
        onClick: preview_click
    }

    let title = props?.title || "";
    if (title.includes("\r\n") || title.includes("\r") || title.includes("\n")) {
        title = title.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
    let text = props?.text || "";
    if (text.includes("\r\n") || text.includes("\r") || text.includes("\n")) {
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
    
    return (
        <BSCard {..._props}>
            {props.image && <BSCard.Img variant="top" src={props.image} />}
            <BSCard.Body>
                {title && <BSCard.Title dangerouslySetInnerHTML={{__html: title}}/>}
                {text && <BSCard.Text dangerouslySetInnerHTML={{__html: text}}/>}
                {props.children}
            </BSCard.Body>
        </BSCard>
    );
});

export default Card;
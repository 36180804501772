import React,{useEffect,useState, useCallback, useRef} from 'react';
import { useDispatch } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import Users from '../../../../api/Users';

import * as actions from '../../../../store/actions';

import './OutstandingCharges.css';

export const OutstandingCharges = (props) => {
    const dispatch = useDispatch();
    const mountedRef=useRef(false)
    const [loading,setLoading]=useState(true);
    const [charges,setCharges]=useState([]);

    const getOutstanding=useCallback(async()=>{
        try{
            let response = await Users.getOutstandingCharges({
                id: props.user_id,
                include_family: 1
            });
            
            if(!response.errors && response?.data?.outstanding_charges){
                let outstanding = response.data.outstanding_charges;
                if(response.data.family.length>0){
                    for(let i =0; i < response.data.family.length; i++){
                        outstanding = [...outstanding, ...response.data.family[i].outstanding_charges]
                    }
                }
                if(mountedRef.current){
                    setCharges(outstanding);
                    setLoading(false);
                }
            }else if(response.errors){
                console.error(response.errors)
                setLoading(false)
            }
        }catch(ex){
            console.error(ex)
            setLoading(false)
        }
    },[props.user_id])

    useEffect(()=>{
        mountedRef.current=true

        return()=>mountedRef.current =false
    },[])

    useEffect(()=>{
        if(props.user_id && mountedRef.current) getOutstanding();
    },[getOutstanding, props.user_id]);

    const clickHandler=async (item)=>{
        if (item.id) {
            // do what? add item - use redux so that Items.js can process it properly
            // currently only works for one item, but saves as an array - to be expanded upon later
            dispatch(actions.setNewItems([item], parseInt(props.register_id)));
        }
    }

    if(loading) return(
        <Spinner animation="border" variant="secondary" />
    )

    return (
        <React.Fragment>
            {!charges.length &&
                <span>None</span>
            }
            {charges && charges?.map((charge,i)=>{
                let variants = charge.product_variants;
                variants.forEach(variant => {
                    if (variant.product_variant_id) variant.id = variant.product_variant_id;
                    if (variant.name) variant.name = variant.product_variant_name;
                });
                const item={
                    id:charge.product_id,
                    name:charge.product_name,
                    product_variants:charge.product_variants,
                    is_taxable:null,
                    track_inventory:null,
                    categories:[],
                    product_status_id: 1,
                    product_type_name:"",
                    sort_order: 0
                }
                return (
                    <Card key={`oustanding-pos-${i}`} data-cy={`outstanding-item-${charge.product_id}`} as="a" href="#!" className="info-container detail-card product" onClick={()=>clickHandler(item)}>
                        <div>{charge.event_name}</div>
                        <div>
                            {/* {charge.product_variants.map((v,j)=><span key={`ocsi-${j}`}>${parseFloat(v.price).toFixed(2)}</span>)} */}
                            {<span>${parseFloat(charge.product_variants[0].price).toFixed(2)}</span>}
                        </div>
                    </Card>
                );
            })}
        </React.Fragment>
    );    
}
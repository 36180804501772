import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { format } from 'date-fns';

import Users from '../../../../api/Users';
import Pos from '../../../../api/Pos';

import { SelectEvent } from './SelectEvent/SelectEvent';

import './Tokens.css';

export const Tokens = (props) => {
    const [loading, setLoading] = useState(true);
    const [tokens, setTokens] = useState([]);
    const [selectEvent, setSelectEvent] = useState();

    // first load, get tokens from api
    useEffect(() => {
        let mounted = true;

        setLoading(true);
        // get all tokens
        Users.tokens({user_id: props.user_id})
        .then(response => {
            if(mounted) {
                if (response.data){
                    setTokens(response.data);
                }
                setLoading(false);
            }
        }).catch((e) => {
            console.error(e)
            setLoading(false);
        });

        // cancel stuff when component unmounts
        return () => {
            mounted = false;
            setLoading(false);
            setTokens([]);
        }        
    },[props.user_id]);

    const clickHandler = item => {
        setSelectEvent(<SelectEvent user_id={props.user_id} token={item} show={true} />);
    }

    if(loading) return(
        <Spinner animation="border" variant="secondary" />
    )

    return (
        <React.Fragment>
            {!tokens.length &&
                <span>None</span>
            }
            {tokens && tokens.map((token,i)=>{
                const item={
                    id: token.id,
                    product_id: token.product_id,
                    name: token.product_name,
                    product_variants: [
                        {
                            id: token.product_variant_id,
                            name: token.product_variant_name
                        }
                    ],
                    is_taxable: null,
                    track_inventory: null,
                    categories: [],
                    product_status_id: 1,
                    product_type_name: "",
                    sort_order: 0,
                    exp_date: token.exp_date
                }
                return (
                    <Card key={`token-pos-${i}`} data-cy={`token-product-${token.product_id}`} as="a" href="#!" className="info-container detail-card product" onClick={ () => clickHandler(item) }>
                        <div><span className="bold">{token.product_name}</span></div>
                        <div>Expires on {format(new Date(token.exp_date), "MM/dd/yyyy")}</div>
                    </Card>
                );
            })}
            {selectEvent}
        </React.Fragment>
    );    
}
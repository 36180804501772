import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, InputGroup, Button } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input-field';

import * as actions from '../../../../../../store/actions'
import Toast from '../../../../../../components/Toast'
import ErrorCatcher from '../../../../../../components/common/ErrorCatcher';
import Pos from '../../../../../../api/Pos'
import '../../splitPayment.scss'
import { useCallback } from 'react';

/**@param {*} currentOrder */
export const SplitPaymentCash = (props) => {
    const dispatch = useDispatch();

    const mountedRef = useRef(false);
    const splitPaymentStore=useSelector(state=>state.splitPayments);
    const successMsg="Cash portion of the order was successfully recorded.";
    const [error, setError]=useState();
    const [success, setSuccess]=useState();
    const [submitting, setSubmitting]=useState(false);
    const [submitDisable, setSubmitDisable]=useState(true); //control for disabling the submit button
    const [cash, setCash]=useState(0);
    const [memo, setMemo]=useState("");
    const [tooHigh, setTooHigh]=useState(false);
    const [tooLow, setTooLow]=useState(false);
    const [changeOwed, setChangeOwed]=useState();
    const [localOrder, setLocalOrder]=useState();

    useEffect(()=>{
        mountedRef.current = true

        setSubmitting(false);

        return()=>{
        mountedRef.current = false;
        }
    },[])

    useEffect(()=>{
        if(splitPaymentStore.allOrders.length > 0 && props.type==="singleUserSplit"){
        let cashOrder = splitPaymentStore.allOrders.filter((order)=>{
            if(order.memo === "Cash Portion") return order;
            return null;
        })
        setLocalOrder(cashOrder[0])
        }
        else setLocalOrder(props.currentOrder)
    },[props.currentOrder, splitPaymentStore.allOrders, props.type]);
    
    useEffect(()=>{
        if(splitPaymentStore?.cashValue?.change_amount) setChangeOwed(splitPaymentStore.cashValue.change_amount)
    },[splitPaymentStore]);

    const checkDisableSubmit=useCallback(()=>{
        let disabled = false //starts false and any of the following will trigger the disable.
        if (cash===0) disabled=true;
        if (tooLow) disabled = true;
        if (submitting) disabled=true;
        if (splitPaymentStore.cashSuccess) disabled=true
        if ((props.type==="multiSingle" || props.type==="multiMany") && props.payee.paid) disabled=true
        if(mountedRef.current) setSubmitDisable(disabled)
    //props.payee.paid doesn't exist in each instance this component is called in.  Calling it as a dependency OOPSes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cash, tooLow, submitting, splitPaymentStore.cashSuccess, props.type]);

    //If there's a change to cash sets error
    useEffect(()=>{
        if(mountedRef.current){
        if(cash < (localOrder?.subtotal_price + localOrder?.tax_total)){
            setTooLow(true);
        } else setTooLow(false);
        checkDisableSubmit();
        }
    },[cash, localOrder, checkDisableSubmit]);

    //checks to see if the submit function is submitting for disbaling button
    useEffect(()=>{
        checkDisableSubmit();
    },[submitting, splitPaymentStore, success, tooHigh, tooLow, props, cash, checkDisableSubmit]);

    const submitCashPayment=async(e)=>{
        e.preventDefault();

        setError();
        setSuccess();
        setSubmitting(true);
        dispatch(actions.setSplitPaymentSubmitting(true))
        let newMemo = `${memo} ** Cash payment -- Split Child of ${localOrder.id}.**`
        try{
        let response = await Pos.payment.cash({
            "user_id": localOrder.user_id,
            "order_id": localOrder.id,
            "memo": newMemo,
            "location_id": localOrder.location_id,
            "tender_amount": cash
        })
        if(response.data && response.data[0].transaction_id){
            setSuccess(<Toast>{successMsg}</Toast>)
            setChangeOwed(response.data[0].change_amount)
            if(props.type==="singleUserSplit"){
                dispatch(actions.setSplitPaymentCashSuccess(true));
                dispatch(actions.setSplitPaymentCashValue({...response.data[0], amount: (localOrder.subtotal_price + localOrder.tax_total)}))
            }
            if(props.type==="multiSingle" || props.type==="multiMany"){
                props.adjustPayeeStatus("success", "cash", props.payee, response.data[0].transaction_id, response.data[0]);
            }
        } else if(response.errors){
            setError(<ErrorCatcher error={response.errors} />);
            if(props.type==="singleUserSplit") dispatch(actions.setSplitPaymentCashSuccess(false));
                if(props.type==="multiSingle"){
                props.adjustPayeeStatus("error", "cash", props.payee, response.data[0].transaction_id, response.data[0]);
                }
            }
        }catch(ex){console.error(ex)}
    dispatch(actions.setSplitPaymentSubmitting(false))
    setSubmitting(false);
    }

    const cashRemainderHandler=(e)=>{
        setCash(+e)
        setChangeOwed((+e - props?.currentOrder?.total_price));
    }

    return (
        
        <div className="split-payment-single-cash text">
            <>
                <div className="d-flex justify-content-between">
                    <h5>Cash Payment</h5>
                    <Button type="button" onClick={()=>props.onClose()} variant="light">Cancel Split Payment</Button>
                </div>
                <fieldset disabled={splitPaymentStore.cashSuccess}>
                    {props.type==="singleUserSplit" ?
                        <>
                        <h6>Total Amount: ${(localOrder?.subtotal_price + localOrder?.tax_total).toFixed(2)}</h6>
                        <p>
                            (Subtotal: ${localOrder?.subtotal_price?.toFixed(2)} + Tax: ${localOrder?.tax_total?.toFixed(2)})
                        </p>
                        <h6>Remaining Amount for Card: ${+splitPaymentStore?.remainder?.toFixed(2)}</h6>
                        <p>Please enter the amount of this order that will be paid in cash and complete the transaction before moving on to the credit card input.  The remaining amount will need to be processed with a credit card in the next step.</p>
                        </>
                        :
                        null
                    }
                    <>
                    {props.type!=="singleUserSplit" &&
                        <>
                            {props.type==="multiSingle" &&
                                <h5>Total Amount Due: ${props?.amount}</h5>
                            }
                        </>
                    }
                    </>
                    
                    <div className="sp-cash-form">
                        <Form onSubmit={submitCashPayment}>
                        <Form.Group>
                            <Form.Row>
                                <p>
                                    <Button className="pos-denom-btn" onClick={e=>cashRemainderHandler(1)} disabled={props.amount ? props?.amount > 1 ? true : false : (localOrder?.subtotal_price + localOrder?.tax_total) >1 ? true : false }>$1</Button>
                                    <Button className="pos-denom-btn" onClick={e=>cashRemainderHandler(5)} disabled={props.amount ? props?.amount > 5 ? true : false : (localOrder?.subtotal_price + localOrder?.tax_total) >5 ? true : false }>$5</Button>
                                    <Button className="pos-denom-btn" onClick={e=>cashRemainderHandler(10)} disabled={props.amount ? props?.amount > 10 ? true : false : (localOrder?.subtotal_price + localOrder?.tax_total) >10 ? true : false }>$10</Button>
                                    <Button className="pos-denom-btn" onClick={e=>cashRemainderHandler(20)} disabled={props.amount ? props?.amount > 20 ? true : false : (localOrder?.subtotal_price + localOrder?.tax_total) >20 ? true : false }>$20</Button>
                                    <Button className="pos-denom-btn" onClick={e=>cashRemainderHandler(50)} disabled={props.amount ? props?.amount > 50 ? true : false : (localOrder?.subtotal_price + localOrder?.tax_total) >50 ? true : false }>$50</Button>
                                    <Button className="pos-denom-btn" onClick={e=>cashRemainderHandler(100)} disabled={props.amount ? props?.amount > 100 ? true : false : (localOrder?.subtotal_price + localOrder?.tax_total) >100 ? true : false }>$100</Button>
                                </p>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text><small>$</small></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <span className="sp-cash-curreny">
                                        <CurrencyInput className="form-control" name="cash" onValueChange={cashRemainderHandler} value={cash} />
                                    </span>
                                </InputGroup>
                            </Form.Row>
                            <br/>
                            <Form.Row>
                                <Form.Label>Memo</Form.Label>
                                <Form.Control type="text" name="memo" value={memo} onChange={e => setMemo(e.target.value)} />
                            </Form.Row>
                        </Form.Group>
                        <Button type="submit" className="sp-cash-sub-btn" disabled={submitDisable}>Submit Cash Amount</Button>
                        <div className="sp-error">
                        {!splitPaymentStore.cashSuccess && 
                            <>
                                <p>{splitPaymentStore.errors?.cashSubmitting}</p>
                                <p>{splitPaymentStore.errors?.cashNonSuccess}</p>
                                <p>{tooLow ? "You've entered a value lower than the total amount.  Please ensure the whole amount is being paid." : null}</p>  
                            </>
                        }
                        </div>
                        <div className="sp-success">
                        {splitPaymentStore.cashSuccess && 
                            <>
                                {/* using in-line to override the default <p> size for this component */}
                                <p>{successMsg}</p>
                                {(changeOwed && changeOwed > 0) ?
                                    <p>Please issue ${changeOwed?.toFixed(2)} in change before proceeding.</p> 
                                    :
                                    <p>Exact cash was recorded.  No change to issue.</p>
                                }
                            </>
                        }
                        </div>
                        <br />
                        </Form>
                    </div>
                    {success}
                    {error}
                </fieldset>
            </>
        </div>
    )
}

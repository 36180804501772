import * as actionTypes from './actionTypes';

export const setServiceBooking = (booking) => {
    return {
        type: actionTypes.SERVICEBOOKING_SET_CURRENT_BOOKING,
        booking: booking
    };
};

export const resetServiceBooking = () => {
    return {
        type: actionTypes.SERVICEBOOKING_RESET_CURRENT_BOOKING
    };
};

export const setServiceBookingErrors = (errors) => {
    return {
        type: actionTypes.SERVICEBOOKING_SET_ERRORS,
        errors: errors
    };
};

export const resetServiceBookingErrors = () => {
    return {
        type: actionTypes.SERVICEBOOKING_RESET_ERRORS
    };
};

export const setServiceBookingSearchParams = (search_params) => {
    return {
        type: actionTypes.SERVICEBOOKING_SET_SEARCH_PARAMS,
        search_params: search_params
    };
};

export const setServiceBookingSearchResults = (search_results) => {
    return {
        type: actionTypes.SERVICEBOOKING_SET_SEARCH_RESULTS,
        search_results: search_results
    };
};

import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap';
import styles from '../OnlinePickup.module.scss'

export const ExpansionButtons =({
    auth, 
    largeBtns, 
    showItemDetails,
    setShowItemDetails,
    showFullOrder,
    setShowFullOrder,
    handleShowItems=null,
    handleShowOrder=null,
    getData=null,
    setRefresh=null,
    ...props
})=>{

    const handleRefresh = ()=>{
        getData();
        if(setRefresh) setRefresh(true);
    }

    return(
        <>
            {getData &&
                <Button className={`${largeBtns ? styles["large-btn"] : ""} ${styles["status-btn"]}`} onClick={handleRefresh}>
                    Refresh Order
                </Button>
            }
            {handleShowItems && 
                <Button className={`${largeBtns ? styles["large-btn"] : ""} ${styles["status-btn"]}`} onClick={handleShowItems}>
                    {showItemDetails ? "Hide" : "Show"} Order Items {" "}
                    {showItemDetails ? <i className="far fa-chevron-double-up" /> : <i className="far fa-chevron-double-down" />}
                </Button>
            }
            {auth && 
                <Button className={`${largeBtns ? styles["large-btn"] : ""} ${styles["status-btn"]}`} onClick={handleShowOrder}>
                    {showFullOrder ? "Hide" : "Show"} Full Order {" "}
                    {showFullOrder ? <i className="far fa-chevron-double-up" /> : <i className="far fa-chevron-double-down" />}
                </Button>
            }
        </>
    )
}
import React, {useState, useEffect, useCallback} from 'react';
import {Modal} from 'react-bootstrap';
import { randomUUID } from '../../../../../utils/cms';
import styles from './Gallery.module.scss';

const Gallery = React.forwardRef((props, ref) => {

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [images, setImages] = useState([]);
    const [currentImage, setCurrentImage] = useState({});
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props?.images?.length > 0) {
            /*props.images.map(image=>{
                try{
                    var img = new Image();
                    img.src = image.url;
                    if (img.width === 0) {
                        image.url = new URL("https://via.placeholder.com/250/000000/ffffff");
                    }
                }catch(e){
                    image.url = new URL("https://via.placeholder.com/250/000000/ffffff");
                }
                return image;
            });*/            
            setImages(props.images);
        }
    }, [props.images]);

    const nextImage = () => {
        if (currentImage < images.length - 1) {
            setCurrentImage(currentImage + 1);
        } else {
            setCurrentImage(0);
        }
    };

    const prevImage = () => {
        if (currentImage > 0) {
            setCurrentImage(currentImage - 1);
        } else {
            setCurrentImage(images.length - 1);
        }
    };

    useEffect(() => {
        return () => {
            setImages([]);
            setCurrentImage({});
            setShow(false);
        };
    }, []);

    const zoomHandler = useCallback((e, idx) => {
        e.preventDefault();
        if (props.click_expand){
            setCurrentImage({
                image: <img src={images?.[idx]?.url} alt={images?.[idx]?.title || ""} />,
                title: images?.[idx]?.title
            });
            setShow(true);
        } else setShow(false);
    }, [images, props.click_expand]);

    const _props={
        ref: ref,
        id: props.is_preview ? `preview-${randomUUID()}` : props.id,
        className: `${styles.grid} ${styles[`columns-sm-${props.sm_columns}`]} ${styles[`columns-md-${props.md_columns}`]} ${styles[`columns-lg-${props.lg_columns}`]} ${styles[`columns-xl-${props.xl_columns}`]} Gallery_${props.page_id} ${props.className || ""}`.trim().replace(/\s+/g,' '),
        style: {...props?.style || {}, columnGap: (props?.gap || 10)+"px", rowGap: (props?.gap || 10)+"px"},
        onClick: preview_click
    }

    /*
    return (
        <div className="gallery">
            <div className="gallery__image">
                <img src={images[currentImage].src} alt={images[currentImage].alt} />
            </div>
            <div className="gallery__controls">
                <button className="gallery__controls__prev" onClick={prevImage}>
                    <i className="far fa-chevron-left"></i>
                </button>
                <button className="gallery__controls__next" onClick={nextImage}>
                    <i className="far fa-chevron-right"></i>
                </button>
            </div>
        </div>
    );
    */

    return (
        <>
            <div {..._props}>
                {images.map((image, i) => (
                    <figure key={`gallery_item_${i}`} style={{marginBottom:(props?.gap || 10)+"px"}}>
                        <img src={image?.url} alt={image?.title || ""} className={props.type==="Grid"?styles.grid:null} style={{cursor:props.click_expand?"pointer":"initial",height:props.type==="Grid"?props.image_height+"px":undefined,width:props.type==="Grid"?props.image_width+"px":undefined}} onClick={(e)=>zoomHandler(e, i)} />
                        {image?.title && props.show_caption && <figcaption>{image?.title}</figcaption>}
                    </figure>
                ))}
            </div>
            <Modal show={show} onHide={()=>setShow(false)} size="lg" centered backdropClassName={styles.darker}>
                <Modal.Header closeButton />
                <Modal.Body className={styles["modal-body"]}>
                    {currentImage.image}
                </Modal.Body>
            </Modal>
        </>
    );
});

export default Gallery;
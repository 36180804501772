/** 
 * @param {Array} payments - array of payment methods (as objects) *required 
 * @note The objects within the payment array must include:
 *      @param {Number} payment_method_id - the id of the payment method 
 *      @param {Number} amount - the amount of the payment
 * @note function that takes the payment methods and returns giftcards seperate, 
 * primarily created so that gift cards don't end up as "change" */
export const giftCardsNoChange = (payments=[]) =>{
    let giftCards = [];
    let otherPayments = [];
    let gcAmount = 0;
    let otherAmount = 0;

    //if there are no giftcards, return
    if(!payments.some((payment)=>payment.payment_method_id === 4)){
        otherPayments = payments;
        otherAmount = payments.reduce((acc, payment)=>acc + payment.amount, 0);
    }
    
    //if there is only a giftcard, return
    else if(payments.length === 1 && payments[0].payment_method_id === 4){
        giftCards = payments;
        gcAmount = payments[0].amount;
    }
    else{
        for(let i = 0; i < payments.length; i++){
            if(payments[i].payment_method_id === 4){
                giftCards.push(payments[i]);
            }else otherPayments.push(payments[i]);
        }
    
        if(giftCards?.length > 0){
            giftCards.forEach((gc)=>{
                gcAmount += gc.amount;
            });
        };
    
        if(otherPayments?.length > 0){
            otherPayments.forEach((op)=>{
                otherAmount += op.amount;
            });
        }
    }

    return {
        giftCards: {
            array: giftCards,
            amount: gcAmount
        },
        otherPayments: {
            array: otherPayments,
            amount: otherAmount
        }
    }

}
    
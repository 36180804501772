import React from 'react';
import { useSelector } from 'react-redux';

import XRay from './XRay';
import Preview from './Preview';

export const Component = React.forwardRef(function Component(props, ref) {
    const cmsSelector = useSelector(state => state.cms);
    
    // the main dustbin where everything is layed out
    if (props?.workbench){
        return (
            <div {...props} ref={ref}>{props.children}</div>
        );
    }

    // x-ray mode
    if (cmsSelector.displayMode==="xray" || props.xray) {
        return (
            <XRay {...props} ref={ref}>{props.children}</XRay>
        );
    }

    // normal mode
    if (cmsSelector.displayMode!=="xray"){
        const newProps = {
            ...props,
            ...JSON.parse(JSON.stringify(props.properties || [])),
        }

        return (
            <Preview {...newProps} ref={ref}>{props.children}</Preview>
        )
    }

    return null;
});
import React, {useState, useEffect} from 'react';
import {Row,Col,Modal,Button} from 'react-bootstrap';

import AddonPreview from '../AddonPreview';
import ProductCard from '../Products';

import './Addons.scss';

const Addons = (props) => {

    const[showModal, setShowModal]=useState(false)

    useEffect(()=>{
        let mounted = true;

        if(props.show && !props.variantShow && mounted && props.items && Object.keys(props.items).length>0) setShowModal(true);
        else setShowModal(false);

        return()=>{
            mounted = false;
        }
    },[props.show, props.variantShow, props.items]);

    const onClickHandler =()=>{
        props.hide();
        setShowModal(false);
    }

    let mpitem=[];

    for (const key in props.items) {
        mpitem.push(
            <Col sm="12" className="variant_container" key={`col-addon-${key}`}>
                <h4 className="card-title">{key}</h4>
                {props.items[key].products.length===0 &&
                    <div className="no-addon-products">No Addon products in this category</div>
                }
                <Row>
                    {props.items[key].products.length > 0 && props.items[key].products && props.items[key].products.map((addon,j)=>(
                        <Col className="p-0" key={`item-addon-${key}-${j}`}>
                            <ProductCard
                                type={2}
                                item={addon}
                                click={props.click}
                                name={addon.name}
                                description={addon.description}
                                price={+addon.price || ""}
                                // active={+props.activeProduct?.id===+props.item.id && +props.activeProduct?.type===2}
                                services={props?.productType==="services"}
                            />
                        </Col>
                    ))}
                </Row>
            </Col>
        );
    }

    return (
        <Modal className="pos-modal pos-p-backdrop addons-modal" backdropClassName="pos-p-backdrop" show={showModal} onHide={props.hide} size="lg" backdrop="static">
            <Modal.Header closeButton={false}>
                <Modal.Title>{props.title}</Modal.Title>
                <Button data-cy="done-btn" onClick={onClickHandler} style={{marginLeft:"auto"}}>Done</Button>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="p-0">
                        {mpitem}
                    </Col>
                    <Col sm={4} className="p-0">
                        <AddonPreview register_id={props.register_id} handleClose={props.hide} />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default Addons;
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';
import {confirmable, createConfirmation} from 'react-confirm';

// This is a copy of react-bootstrap-confirmation that we can keep up to date and modify
// react-bootstrap-confirmation hasn't been updated in 2 years and needs to be removed from our dependencies
// Eventually this component can be re-written completely

import styles from './Confirmation.module.scss';

const Confirmation = ({show, proceed, dismiss, cancel, confirmation, title, okText, cancelText, thirdText, fourthText, okButtonStyle, cancelButtonStyle, thirdButtonStyle, fourthButtonStyle, timeout=null, ...options}) => {

    const header = title ? (
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
    ) : undefined;

    // if a timeout is passed in then cancel the modal after the timer
    useEffect(() => {
        let timer = null;
        if (timeout) {
            timer = setTimeout(() => {
                proceed(false);
            }, timeout);
            return () => clearTimeout(timer);
        }
    }, [timeout, proceed]);

    return (
        <Modal
            size="small"
            show={show}
            onHide={() => proceed(false)}
            backdrop="static"
            centered
            className={styles.modal}
            backdropClassName={styles.backdrop}
        >
            {header}
            <Modal.Body>
                <p>{confirmation}</p>
                <div className="button-row right mt-2">
                    {cancelText &&
                        <Button variant={cancelButtonStyle} onClick={() => proceed(false)} data-cy="confirmation-cancel-btn">
                            {cancelText}
                        </Button>
                    }
                    {thirdText &&
                        <Button variant={thirdButtonStyle || cancelButtonStyle} onClick={() => proceed(3)} data-cy="confirmation-third-btn">
                            {thirdText}
                        </Button>
                    }
                    {fourthText &&
                        <Button variant={fourthButtonStyle || thirdButtonStyle || cancelButtonStyle} onClick={() => proceed(4)} data-cy="confirmation-third-btn">
                            {fourthText}
                        </Button>
                    }
                    <Button variant={okButtonStyle} onClick={() => proceed(true)} data-cy="confirmation-proceed-btn">
                        {okText}
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

Confirmation.propTypes = {
    /** header title */
    title: PropTypes.string,
    confirmation: PropTypes.string, // arguments of your confirm function
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    thirdText: PropTypes.string,
    fourthText: PropTypes.string,
    okButtonStyle: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link']),
    cancelButtonStyle: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link']),
    thirdButtonStyle: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link']),
    fourthButtonStyle: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link']),
    show: PropTypes.bool, // from confirmable.
    proceed: PropTypes.func, // from confirmable.
    cancel: PropTypes.func, // from confirmable.
    dismiss: PropTypes.func, // from confirmable.
};

Confirmation.defaultProps = {
    title: undefined,
    confirmation: undefined,
    okText: 'OK',
    cancelText: 'Cancel',
    okButtonStyle: 'primary',
    cancelButtonStyle: 'secondary',
    show: undefined,
    proceed: undefined,
    cancel: undefined,
    dismiss: undefined,
};

const confirmLow = createConfirmation(confirmable(Confirmation));

export const confirm = (message, options = {}) => {
return confirmLow(Object.assign({confirmation: message}, options));
};


const Alert = ({show, proceed, dismiss, cancel, confirmation, title,
okText, okButtonStyle, ...options}) => {

    const header = title ? (
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
    ) : undefined;

    return (
        <Modal
            size="small"
            show={show}
            onHide={() => proceed()}
            keyboard={true}
            backdrop="static"
            centered
            className={styles.modal}
            backdropClassName={styles.backdrop}
        >
            {header}
            <Modal.Body>{confirmation}</Modal.Body>
            <Modal.Footer>
                <Button variant={okButtonStyle} onClick={() => proceed()}>
                    {okText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

Alert.propTypes = {
    /** header title */
    title: PropTypes.string,
    confirmation: PropTypes.string, // arguments of your confirm function
    okText: PropTypes.string,
    okButtonStyle: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger',
        'warning', 'info', 'light', 'dark', 'link']),
    show: PropTypes.bool, // from confirmable.
    proceed: PropTypes.func, // from confirmable.
    cancel: PropTypes.func, // from confirmable.
    dismiss: PropTypes.func, // from confirmable.
};

Alert.defaultProps = {
    title: undefined,
    confirmation: undefined,
    okText: 'OK',
    okButtonStyle: 'primary',
    cancelButtonStyle: 'light',
    show: undefined,
    proceed: undefined,
    cancel: undefined,
    dismiss: undefined,
};

const alertLow = createConfirmation(confirmable(Alert));

export const alert = (message, options = {}) => {
    return alertLow(Object.assign({confirmation: message}, options));
};

import React, { useEffect, useState, useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { isValid } from "date-fns";
import Datepicker from 'react-datepicker';
import { randomUUID, checkBoolean, localGroup, toKebabCase } from '../../../../../utils/cms';

const FormGroupDatePicker =  React.forwardRef((props, ref) => {
    const bkRef = useRef();

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [value, setValue] = useState(props?.value || "");
    const [isInvalid, setIsInvalid] = useState(false);
    const [feedback, setFeedback] = useState(props?.feedback || "This field is required");

    useEffect(() => {
        if (props.id && bkRef.current){
            localGroup(bkRef.current.querySelector("input"), props.id);
        }
    }, [bkRef, props.id]);

    useEffect(() => {
        if (props?.isInvalid) setIsInvalid(props.isInvalid);
    }, [props.isInvalid]);

    useEffect(() => {
        return () => {
            setValue("");
            setIsInvalid(false);
            setFeedback("This field is required");
        }
    }, []);

    const changeHandler = (date) => {
        setValue(date);
        localGroup({
            name: props?.name || props?.label || randomUUID(),
            value: date,
        }, props.id);


        if (!date && props?.required) setIsInvalid(true);
        if (!isValid(date)){
            setIsInvalid(true);
            setFeedback("Invalid date");
        } 

        if (props?.onChange){
            // create a fake event object to pass to the onChange handler
            const e = {
                preventDefault: () => {},
                stopPropagation: () => {},
                target: {
                    name: props?.name || props?.label || randomUUID(),
                    value: date,
                }
            }
            props.onChange(e);
        }
    }

    const _disabled = checkBoolean(props?.disabled) || false;
    const _readonly = checkBoolean(props?.readonly) || false;
    const _required = checkBoolean(props?.required) || false;

    return (
        <Form.Group 
            ref={ref || bkRef} 
            className={`form-group-datepicker_${props.page_id} ${props.className || ""}`} 
            style={props.style}
            controlId={`${toKebabCase(props?.name) || toKebabCase(props?.label)}-${randomUUID()}`}
            onClick={preview_click}>
                <Form.Label>{props?.label || ""}</Form.Label>
                <InputGroup hasValidation>
                    {props?.prepend && (
                        <InputGroup.Prepend>
                            <InputGroup.Text>{props.prepend}</InputGroup.Text>
                        </InputGroup.Prepend>
                    )}
                    <Datepicker 
                        ref={props.inputRef || null}
                        name={props?.name || props?.label || randomUUID()} 
                        placeholderText={props?.placeholder || ""} 
                        dateFormat="MM/dd/yyyy"
                        minDate={new Date(props.minDate || '01/01/1900')}
                        maxDate={new Date(props.maxDate || `12/31/${new Date().getFullYear()+100}`)}
                        selectsRange={props.selectRange || false}
                        showMonthDropdown
                        showYearDropdown
                        selected={value}
                        onChange={changeHandler}
                        className="form-control"
                        disabled={_disabled}
                        readOnly={_readonly}
                        required={_required}
                    />
                    {props?.append && (
                        <InputGroup.Append>
                            <InputGroup.Text>{props.append}</InputGroup.Text>
                        </InputGroup.Append>
                    )}
                    <Form.Control.Feedback type="invalid" style={{display:isInvalid?"block":"none"}}>
                        {feedback || <>This field is required</>}
                    </Form.Control.Feedback>
                </InputGroup>
        </Form.Group>
    );
});

export default FormGroupDatePicker;
import React,{useState, useEffect, useRef} from 'react';
import { Form } from 'react-bootstrap';
import { randomUUID, checkBoolean, localGroup, toKebabCase } from '../../../../../utils/cms';

const FormGroupSelect =  React.forwardRef((props, ref) => {
    const bkRef = useRef();

    // this should be in every component, its used to forward the click event to the builder if in preview mode
    let preview_click=null;
    if (props.is_preview && props.onClick){
        preview_click = props.onClick;
    }

    const [options, setOptions] = useState([]);
    const [value, setValue] = useState(props?.value || "");

    const changeHandler = (e) => {
        setValue(e.target.value);
        localGroup(e.target, props.id);
        if (props?.onChange) props.onChange(e);
    }

    const _disabled = checkBoolean(props?.disabled) || false;
    const _readonly = checkBoolean(props?.readonly) || false;
    const _required = checkBoolean(props?.required) || false;    

    useEffect(() => {
        if (props?.value) setValue(props.value);
    }, [props?.value]);

    useEffect(() => {
        if (props?.options && options.length === 0) {
            let _options = [];
            if (typeof props.options === "string") _options=props.options.split(",");
            else _options = props.options;
            setOptions(prev=>{
                if (prev.length === _options.length) return prev;
                return _options || [];
            });
        }
    }, [props.options, options]);

    useEffect(() => {
        if (props.id && bkRef.current){
            localGroup(bkRef.current.querySelector("select"), props.id);
        }
    }, [bkRef, props.id]);

    useEffect(() => {
        return () => {
            setOptions([]);
        }
    }, []);

    return (
        <Form.Group 
            ref={ref || bkRef} 
            className={`form-group-select_${props.page_id} ${props.className || ""}`} 
            style={props.style} 
            controlId={`${toKebabCase(props?.name) || toKebabCase(props?.label)}-${randomUUID()}`}
            onClick={preview_click}>
                <Form.Label>{props?.label || ""}</Form.Label>
                <Form.Control 
                    ref={props.inputRef || null}
                    as="select" 
                    custom 
                    aria-label={props?.placeholder || ""} 
                    name={props?.name || props?.label || randomUUID()} 
                    value={value} 
                    onChange={changeHandler}
                    disabled={_disabled} 
                    readOnly={_readonly} 
                    required={_required}
                    isInvalid={props?.isInvalid || false}
                >
                    {options.map(option => {
                        let [value, label] = option.split("|");
                        if (!label) label = value;
                        return (<option key={`option-${option}`} value={value}>{label}</option>);                    
                    })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    {props?.feedback || <>This field is required</>}
                </Form.Control.Feedback>
        </Form.Group>
    );
});

export default FormGroupSelect;
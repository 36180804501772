import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { Container, Col, Form, Button } from 'react-bootstrap';

import {confirm} from '../../../../components/Confirmation';
import { authCheck } from "../../../../utils/auth";
import ErrorCatcher from '../../../../components/common/ErrorCatcher';
import Toast from '../../../../components/Toast';
import Spinner from '../../../../components/Spinner';
import { authUserHasModuleAccessMany } from "../../../../utils/auth";

import Users from '../../../../api/Users';

import './Settings.scss';

const VIEW_ROLE_MODULE_ID = 270;
const EDIT_ROLE_MODULE_ID = 271;
const DEACTIVATE_MODULE_ID = 272;

export const Settings = (props) => {
    let history = useHistory();
    // const canEdit = props.can_edit;
    // const currentUserRoleId = props.current_user_role_id;

    const [roles, setRoles] = useState([]);
    const [userRole, setUserRole] = useState(null);
    const [initialUserRole, setInitialUserRole] = useState(null);
    const [userHasModulePermission, setUserHasModulePermission] = useState(null);

    let user;
    if (props.user) user=props.user;
    else {
        user = authCheck(history);
    }

    useEffect(() => {
        let mounted = true;

        const checkPermission = async () => {
            try {
                let response = await authUserHasModuleAccessMany([VIEW_ROLE_MODULE_ID, EDIT_ROLE_MODULE_ID, DEACTIVATE_MODULE_ID], user.id);
                setUserHasModulePermission(response);
            } catch (error) { console.error(error) }
        }
        checkPermission();

        Users.Roles.getAll()
        .then( response => {
            if (mounted) {
                let data;
                if(typeof(response.data) === "object") { //data should be an array: remove when api is fixed
                    data = response.data.roles;
                } else {
                    data = response.data;
                }
                // the backend is now filtering appropriately
                setRoles(data);
            }
        }).catch(e => console.error(e));

        Users.Roles.get({user_id: user.id})
        .then( response => {
            if (mounted) {
                if (response.data?.id) {
                    setUserRole(response.data.id);
                    setInitialUserRole(response.data.id);
                } else {
                    setError(<ErrorCatcher error={response.errors || "There has been an error retrieving the user's role."} />);
                }
            }
        }).catch(e => console.error(e));

        return () => mounted = false;
    },[user.id]);

    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    // form submission
    const submitHandler = async (e) => {
        const form = e.currentTarget;

        e.preventDefault();
        e.stopPropagation();

        setValidated(true);
        setSubmitting(true);
        setError(null);
        setSuccess(null);

        if (form.checkValidity() === true) {
            const formData = new FormData(e.target);
            formData.append("id", user.id);
            const formDataObj = Object.fromEntries(formData.entries());

            await Users.update(formDataObj)
            .then( async response => {
                if (!response.errors) {
                    if ( userRole !== initialUserRole) {
                        await Users.Roles.edit({ user_id: user.id, roles: [userRole]})
                        .then( response => {
                            if (response.errors) setError(<ErrorCatcher error={response.errors} />);
                        }).catch(e => {
                            setError(<ErrorCatcher error={e} />);
                            console.error(e);
                        })
                    }
                    setSubmitting(false);
                    if (!error) {
                        setValidated(false);
                        setSuccess(<Toast>Profile saved successfully!</Toast>);
                        history.push(props.referer || "/p/users/" + user.id); // pushes to profile again to avoid resubmission
                    }
                } else {
                    setSubmitting(false);
                    setError(<ErrorCatcher error={response.errors} />);
                } 
            }).catch( e => {
                setSubmitting(false);
                setError(<ErrorCatcher error={e} />);
            });
        } else setSubmitting(false);
    };

    // confirm deactivate account
    const confirmHandler = useCallback((props) => {
        const closeAccountHandler = async props => {
            await Users.remove({
                "id": user.id
            }).then( response => {
                if (!response.errors) {
                    setSubmitting(false);
                    setValidated(false);
                    setSuccess(<Toast>Profile saved successfully!</Toast>);
                    history.push("/p/users/dashboard"); // pushes to profile again to avoid resubmission
                } else {
                    setSubmitting(false);
                    setError(<ErrorCatcher error={response.errors} />);
                } 
            }).catch( e => {
                setSubmitting(false);
                setError(<ErrorCatcher error={e} />);
            });
        }    
    
        confirm("Are you sure you want to delete your account?",{
            title:"Whoa!",
            okText:"Yes",
            cancelText:"No",
            cancelButtonStyle:"light"
        }).then(result =>{
            if (result===true){
                confirm("Are you really sure?",{
                    title:"Whoa!",
                    okText:"Yes",
                    cancelText:"No",
                    cancelButtonStyle:"light"
                }).then(result2 =>{
                    if (result2===true){
                        closeAccountHandler();
                    }
                }).catch(e => console.error(e));
            } 
        }).catch(e => console.error(e));
    },[user.id, history]);

    return (
        <Container fluid>
            <h4 className="section-title">Account Settings</h4>

            {userHasModulePermission===null ?
                <><Spinner /></>
            :
            <>

                {success}
                <Form noValidate validated={validated} onSubmit={submitHandler} className="settings-wrapper">

                    {!userHasModulePermission[VIEW_ROLE_MODULE_ID] || !roles.find(role => role.id===userRole) ?
                        <p>You do not have permission to view this user's role.</p>
                    :
                    <>
                        <hr className="my-4" />
                        <h5>User Role</h5>

                        {!userHasModulePermission[EDIT_ROLE_MODULE_ID] ?
                            <>
                                <p className="bold">{userRole} - {roles.find(role => role.id===userRole)?.name}</p>
                                <p>You do not have permission to edit this user's role.</p>
                            </>
                        :
                        <>
                            <Form.Row>
                                <p>Assign a user role here.  Only one role may be active per user.</p>
                                <select
                                    onChange={(e)=>setUserRole(+e.target.value)}
                                    value={userRole}
                                >
                                    <option value="0" disabled>
                                        Select a Role
                                    </option>
                                    {roles.map(role =>(
                                        <option key={`role-select-dd-${role.id}`} value={role.id}>{role.name}</option>
                                    ))}
                                </select>
                            </Form.Row>
                            <Form.Row>
                                <Col sm="12" lg="4" className="my-3">
                                    <Button variant="primary" type="submit" disabled={submitting} className={`${submitting?" submitting":""}`}>Save</Button>
                                </Col>
                            </Form.Row>
                        </>
                        }
                    </>
                    }

                    {userHasModulePermission[DEACTIVATE_MODULE_ID] &&
                    <>
                        <hr className="my-4" />
                        <Form.Row>
                            <Col sm="12" className="my-3">
                                <Form.Row>
                                    <Col>
                                        <h5>Deactivate Account</h5>
                                        <p>
                                            All user names under your email will also be deactivated.
                                        </p>
                                    </Col>
                                    <Col sm="12" lg="4">
                                        <Button type="button" disabled={submitting} className={`btn-danger ${submitting?" submitting":""}`} onClick={confirmHandler}>Close Account</Button>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Form.Row>
                    </>
                    }

                </Form>
                {error}
            </>
            }
        </Container>
    );
}
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import Toast from '../Toast';

import LocationsAPI from '../../api/Locations';
import { generateUserQRCode } from '../../utils/urls';

import './PrintQRCode.scss';

export const PrintQRCode = ({ user, variant="primary", title="Print QR Code Label", hideButton=false, triggerOpen=0 }) => {

    const [stringToEncode, setStringToEncode] = useState(null);
    const [allLabelPrintLocations, setAllLabelPrintLocations] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [success, setSuccess] = useState(null);

    const modalOnClose = () => {
        setShowModal(false);
    }

    const modalOnOpen = () => {
        setShowModal(true);
    }

    const handleSelectPrinter = (event) => {
        let value = event.target.value;
        if (value === "") {
            value = null;
        }
        setSelectedLocation(value);
    };

    const submitPrint = useCallback(async () => {
        if (selectedLocation) {
            await LocationsAPI.printLocation.addToQueue({
                location_id: selectedLocation,
                print_data: {
                    valueToEncode: stringToEncode,
                    displayText: user.first_name + " " + user.last_name,
                }
            }).then(async response => {
                if (!response.errors) {
                    // nothing to do
                    setSuccess(<Toast>Print has been added to the queue</Toast>)
                    setShowModal(false);
                } else {
                    console.error(response.errors);
                }
            }).catch(e => console.error(e));
        }
    },[selectedLocation, stringToEncode, user]);

    useEffect(() => {
        if (triggerOpen>0) {
            modalOnOpen();
        }
    },[triggerOpen]);

    useEffect(() => {
        const getAllLocations = async () => {
            await LocationsAPI.get({
                printer_type: "qr_code"
            }).then(async response => {
                if (!response.errors) {
                    setAllLabelPrintLocations(response.data);
                    if (response.data?.length>0) {
                        setSelectedLocation(response.data[0].id);
                    }
                } else {
                    console.error(response.errors);
                }
            }).catch(e => console.error(e));
        }

        getAllLocations();
    },[]);

    useEffect(() => {
        if (user?.id) {
            setStringToEncode(generateUserQRCode(user.id));
        }
    },[user]);

    const selectPrinterDropdown = useMemo(() => {
        if (allLabelPrintLocations?.length > 0) {
            return (
                <>
                    <Form.Control as="select" name="location_id" value={selectedLocation} onChange={handleSelectPrinter}>
                        {allLabelPrintLocations.map(location => (
                            <option key={`location-${location.id}`} value={location.id}>{location.name}</option>
                        ))}
                    </Form.Control>
                </>
            );
        }
        return <></>;
    },[allLabelPrintLocations, selectedLocation]);

    // if (!selectedLocation) {
    //     return (
    //         <></>
    //     );
    // }

    return (
        <div className="button-print-qr-code">
            {success}

            <Modal show={showModal} onHide={modalOnClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Print QR Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {allLabelPrintLocations===null ?
                        <div className="loading">Loading...</div>
                    : allLabelPrintLocations.length === 0 ?
                        <>No QR code printers have been set up.</>
                    :
                        <>
                            <div>Select a printer to send to:</div>
                            {selectPrinterDropdown}
                        </>
                    }

                    <div className="button-row">
                        <Button variant="secondary" onClick={modalOnClose}>Cancel</Button>
                        {selectedLocation &&
                            <Button variant="primary" onClick={submitPrint}>Send to Printer</Button>
                        }
                    </div>
                </Modal.Body>
            </Modal>

            {!hideButton &&
                <Button
                    variant={variant}
                    onClick={modalOnOpen}
                >{title}</Button>
            }
        </div>
    )
}
import React, { useContext } from 'react';
import { FileURLContext } from '../../../../contexts/FileURLContext';

export const Families = props => {

    const imageURL = useContext(FileURLContext);
    

    return (
        <div className="home-family-wrapper">
            {!props.data.length>0 && <span>No family members added</span>}
            {props.data?.map( (member, i) =>{
                const profile_img=member.profile_img_path;
                return (
                    <div className="family-mem" key={i} data-cy="home-family-member">
                        <img src={member.profile_img_path ? profile_img : imageURL.noPic} alt="family profile" className="family-prof fam" /* onClick={() => history.push("/p/users/" + member.id)} */ />
                        <span>{member.first_name}</span>
                    </div>
                );
            })}
        </div>
    );
}
import { Wizard } from './Wizard';

import Summary from './Summary';
import Buttons from './Buttons';
import Step1 from './Step1';
import Register from './Register';
import ByAmount from './ByAmount';
import ByItem from './ByItem';
import ByTransaction from './ByTransaction';
import AdminFee from './AdminFee';
import Items from './Items';
import Memo from './Memo';
import Success from './Success';

export { Summary, Buttons, Step1, Register, ByAmount, ByItem, ByTransaction, AdminFee, Items, Memo, Success };
export default Wizard;